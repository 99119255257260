import { CircularProgress, Grid, Typography } from "@mui/material";
import { DialogWrapper } from "../../../../components/shared";
import { useEffect, useState } from "react";
import { getProductsPriceMarginByCustomerId } from "../../../../services/procurementService";

type PriceMarginModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  customer_id: number | string | null;
  product_id: number | string | null;
};

type MarginApiParamsType = {
  customer_id: number;
  id: number;
};
const PriceMarginModal = (props: PriceMarginModalProps) => {
  const { isOpen, onClose, customer_id, product_id } = props;
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [priceMargins, setPriceMargins] = useState<any>([]);

  useEffect(() => {
    if (customer_id && product_id) {
      setIsDialogLoading(true);
      getProductsPriceMarginByCustomerId({
        customer_id: customer_id,
        id: product_id,
      } as MarginApiParamsType)
        .then((res: any) => {
          if (res.data) {
            setIsDialogLoading(false);
            setPriceMargins((prevState: any) => {
              const newState: any = [];
              const { productdata } = res.data;
              if (productdata) {
                const keys = Object.keys(productdata);

                keys.forEach((ele: any) => {
                  newState.push({
                    supplier_name: productdata[ele]?.suppliername,
                    type: ele,
                    p_rate: productdata[ele]?.p_rate,
                    mrp: productdata[ele]?.mrp,
                    margin: productdata[ele]?.margin,
                  });
                });
              }

              return newState;
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsDialogLoading(false);
        });
    }
  }, [customer_id, product_id]);

  return (
    <DialogWrapper open={isOpen} onClose={onClose} title="Price Margin">
      {isDialogLoading ? (
        <Grid
          sx={{
            width: "100%",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            height: "20vh",
            background: "transparent",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {priceMargins?.map((data: any, index: any) => {
            return (
              <>
                {index === 0 && (
                  <Grid
                    key={index}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: "20px",
                      borderBottom: 1,
                      borderColor: "var(--table-border)",
                      pb: "10px",
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        width: { xs: "40%", md: "40%" },
                        textAlign: "left",
                      }}
                    >
                      Supplier Name
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        width: { xs: "15%", md: "15%" },
                        textAlign: "left",
                        borderColor: "var(--table-border)",
                      }}
                    >
                      Rating
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        width: { xs: "15%", md: "15%" },
                        textAlign: "left",
                        borderColor: "var(--table-border)",
                      }}
                    >
                      Ptr
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        width: { xs: "15%", md: "15%" },
                        textAlign: "left",
                        pl: "30px",
                      }}
                    >
                      Mrp
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        width: { xs: "15%", md: "15%" },
                        textAlign: "left",
                        pl: "30px",
                      }}
                    >
                      Margin
                    </Typography>
                  </Grid>
                )}
                <Grid
                  key={index}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: { xs: "row" },
                    alignItems: { xs: "center" },
                    justifyContent: "space-between",
                    mb: "10px",
                    pb: "10px",
                    borderBottom: 1,
                    borderColor: "var(--table-border)",
                  }}
                >
                  <Typography
                    sx={{
                      width: { xs: "40%", md: "40%" },
                      textAlign: "left",
                    }}
                  >
                    {data?.supplier_name}
                  </Typography>
                  <Typography
                    sx={{
                      width: { xs: "15%", md: "15%" },
                      textAlign: "left",
                      textTransform: "capitalize",
                    }}
                  >
                    {data?.type}
                  </Typography>
                  <Typography
                    sx={{
                      width: { xs: "15%", md: "15%" },
                      textAlign: "left",
                    }}
                  >
                    {data?.p_rate ? data?.p_rate : "-"}
                  </Typography>
                  <Typography
                    sx={{
                      width: { xs: "15%", md: "15%" },
                      textAlign: "left",
                      pl: "30px",
                    }}
                  >
                    {data?.mrp ? data?.mrp : "-"}
                  </Typography>
                  <Typography
                    sx={{
                      width: { xs: "15%", md: "15%" },
                      textAlign: "left",
                      pl: "30px",
                    }}
                  >
                    {data?.margin ? data?.margin : "-"}
                  </Typography>
                </Grid>
              </>
            );
          })}
        </>
      )}
    </DialogWrapper>
  );
};

export default PriceMarginModal;
