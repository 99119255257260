import React from "react";
import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../assets/icons";
import { Select, TextField } from "../../../components/basic";
import { goodsIssueReturnInfoConst } from "../../../constants/displayText";

type InfoType = {
  from_dept: string | number;
  to_dept: string | number;
  invoice_no: number | null;
};
type ListTypes = {
  id: number | string;
  type: string;
  name: string;
};
type InfoProps = {
  info: InfoType;
  setInfo: React.Dispatch<React.SetStateAction<InfoType>>;
  issuerList: ListTypes[];
  receiverList: ListTypes[];
  setIsSequenceWrapperOpen:React.Dispatch<React.SetStateAction<boolean>>;
  setIsManual:React.Dispatch<React.SetStateAction<any>>;
  isManualAdded:any;
  isManual:any;
  errors:any
  handleConfirmSequenceNoChange:()=>void;
  customSequence?:boolean;
};

const Info = React.memo(
  ({ info, setInfo, issuerList, receiverList,setIsSequenceWrapperOpen,
    setIsManual,
    isManualAdded,
    isManual,
    customSequence,
    handleConfirmSequenceNoChange }: InfoProps) => {
    const styles = {
      selectGrid: {
        width: "288px",
        height: "45px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      textFieldStyle: {
        width: "288px",
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };
    const { issuerName, receiverName, invoiceNo } = goodsIssueReturnInfoConst;

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setInfo((prev: InfoType) => ({
        ...prev,
        [name]: value,
      }));
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 5,
        }}
      >
        <Select
          value={info.from_dept}
          onChange={(e) => handleInputChange(e)}
          placeholder="Select Issuer"
          //list was pinned vice versa because for api condition
          options={receiverList}
          label={issuerName}
          name="from_dept"
          width="288px"
          sx={{
            ...styles.selectGrid,
          }}
        />

        <Select
          value={info.to_dept}
          onChange={handleInputChange}
          placeholder="Select Receiver"
          //list was pinned vice versa because for api condition
          options={issuerList}
          label={receiverName}
          name="to_dept"
          width="288px"
          sx={{
            ...styles.selectGrid,
          }}
        />

        {customSequence ? (<TextField
          value={info.invoice_no}
          label={invoiceNo}
          onChange={handleInputChange}
          onBlur={handleConfirmSequenceNoChange}
          endPlaceholderIcon={<SettingsIcon 
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsSequenceWrapperOpen(true);
            }}
          />}
          name="invoice_no"
          sx={{
            ...styles.textFieldStyle,
          }}
        />) : null}
      </Grid>
    );
  }
);

export default Info;
