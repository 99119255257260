import { Box, Grid } from "@mui/material";
import Info from "./Info";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CancelButton,
  PageLoader,
  SaveButton,
} from "../../../../components/basic";
import {
  getLabPurchaseOrderById,
  updateLabPurchaseOrderById,
} from "../../../../services/laboratoryService";
import moment from "moment";
import ProductTable from "./ProductTable";
import { setSnackBarSuccess } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

const ProductTableMemoized = React.memo(ProductTable);

const LabPurchaseOrderView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [info, setInfo] = useState<{
    po_no: string;
    supplier: string;
    date: any;
    status: string;
    products: any[];
  }>({
    po_no: "",
    supplier: "",
    date: null,
    status: "",
    products: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const handleInfoChange = useCallback((event: any) => {
    const { name, value } = event.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleChange = useCallback((event: any, index: number) => {
    const { name, value } = event.target;
    setInfo((prevState: any) => ({
      ...prevState,
      products: prevState?.products?.map((ele: any, i: number) => {
        if (index === i) {
          ele[name] = value;

          if (name === "ptr") {
            ele.value = value * ele?.indent_qty;
          }
        }

        return {
          ...ele,
        };
      }),
    }));
  }, []);

  const handleSaveData = () => {
    if (id) {
      setIsSaveLoading(true);
      updateLabPurchaseOrderById(id, {
        status: info.status,
        items: info?.products?.map((ele: any) => ({
          item_id: ele.id,
          ptr: ele.ptr,
          value: ele.value,
        })),
      })
        .then((res: any) => {
          dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
          navigate(-1);
          setIsSaveLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsSaveLoading(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getLabPurchaseOrderById(id)
        .then((res: any) => {
          if (res.data) {
            setInfo({
              po_no: res?.data?.sequence_no,
              supplier: res?.data?.supplier?.name,
              date: res?.data?.date_created
                ? moment(res?.data?.date_created).format(
                    "YYYY-MM-DD hh:mm:ss A"
                  )
                : null,
              status: res?.data?.status?.toString(),
              products: res?.data?.items,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Box>
          <Info info={info} onChange={handleInfoChange} />
          <ProductTableMemoized data={info.products} onChange={handleChange} />
          <Grid sx={{ m: "35px 0 45px 0" }}>
            <SaveButton
              loading={isSaveLoading}
              handleClick={handleSaveData}
              sx={{ width: "100px", height: "42px", mr: "20px" }}
              disabled={isSaveLoading}
            />
            <CancelButton
              sx={{ width: "100px", height: "42px" }}
              isDisabled={isSaveLoading}
            />
          </Grid>
        </Box>
      )}
    </>
  );
};

export default LabPurchaseOrderView;
