// import { jwtDecode } from "jwt-decode";
// import { authEndPoints } from "../constants/apiEndPoints";
// import ApiUtil from "../utils/ApiUtils";
// import axios, { AxiosError } from "axios";
// import { getCookie, removeCookie, setCookie } from "../utils/GeneralUtils";
// export const CLIENT_ID = '9b675e59-2b63-4e94-b415-47248112593e';
// export const ACCOUNT_BASE_URL = 'http://accounts.techbumbles.co.in:30030/api/';
// export const APP_BASE_URL = 'http://drcarrot.techbumbles.co.in:30031/api/v1/';
// interface Credentials {
//   refresh_token: string;
//   access_token: string;
// }

// interface Tokens {
//   access_token: string | undefined;
//   refresh_token: string | undefined;
// }

// interface VerifyOtpResponse {
//   tokens?: Tokens;
//   token?: string;
// }

// interface PasswordData {
//   password: string;
//   confirmPassword: string;
// }

// interface EmailCheckResponse {
//   exist?: boolean;
// }

// const storeAccessToken = (token: any): Promise<boolean | AxiosError> => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       await removeAccessToken();
//       const newAccessToken = token?.access_token;
//       const newRefreshToken = token?.refresh_token;
//       await setCookie("accessToken", JSON.stringify(newAccessToken));
//       await setCookie("refreshToken", JSON.stringify(newRefreshToken));

//       setTimeout(() => {
//         resolve(true);
//       }, 1000);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };
// const refreshTokenUpdate = (body: { refresh_token: string }): Promise<any> => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const response = (await ApiUtil.postWithoutToken(
//         authEndPoints.refreshToken,
//         body
//       )) as any;
//       if (response.data.refresh_token && response.data.access_token) {
//         storeAccessToken(response?.data);
//       }
//       resolve(response);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };
// const loginCheck = (): boolean => {
//   const credentials: any = getCookie("accessToken");
//   if (credentials) {
//     const { exp }: { exp: number } = jwtDecode(JSON.parse(credentials));
//     if (Date.now() <= exp * 1000) {
//       return true;
//     } else {
//       removeCookie("accessToken");
//       removeCookie("refreshToken");
//       return false;
//     }
//   }
//   return false;
// };
// const removeAccessToken = async (): Promise<void> => {
//   await new Promise((resolve, reject) => {
//     try {
//       localStorage.clear();
//       resolve(true);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// async function login(mobile: string, password: string): Promise<any> {
//   const loginResponse = { error: '', tenants: [] };
//   try {

//     const response: any = await ApiUtil.postWithoutToken(`${ACCOUNT_BASE_URL}login`,
//       {
//         mobile,
//         password,
//       },
//     )

//     if (response.success && response.data) {
//       await setCookie("accToken", JSON.stringify(response.data.access_token));
//       // Get the tenants
//       const resp: any = await axios.get(`${ACCOUNT_BASE_URL}get-user-organizations/${CLIENT_ID}`, {
//         headers: {
//           Authorization: `Bearer ${response.data.access_token}`,
//           Accept: 'application/json',
//         },
//       });

//       if (resp.status === 200 && resp.data.data) {
//         loginResponse.tenants = resp.data.data;
//         // Login with first tenant if the user has one tenant
//         if (resp.data.data.length === 1) {
//           await setCookie("xTenantId", JSON.stringify(resp.data.data[0].tenant_id));
//           const result = await axios.post(
//             `${APP_BASE_URL}tenant-login`,
//             {
//               mobile,
//               password: CLIENT_ID,
//               platform: 'web',
//             },
//             {
//               headers: {
//                 'X-Tenant': `${resp.data.data[0].tenant_id}`,
//                 'ACC-ATOKEN': `${response.data.access_token}`,
//                 'Client-ID': CLIENT_ID,
//                 Accept: 'application/json',
//               },
//             },
//           );
//           await storeAccessToken({ access_token: result.data.data.access_token, refresh_token: result.data.data.refresh_token } as Credentials);
//           return loginResponse;
//         }
//       }
//       return loginResponse;
//     }
//     return loginResponse;
//   } catch (error) {
//     console.log("Error during login:", error);
//     throw error;
//   }
// }

// async function tenantLogin(tenantId: string, params: any) {
//   const credentials: any = getCookie("accToken");
//   const response = { error: '', success: false };
//   let cred = credentials.substring(1, credentials.length - 1);
//   try {
//     const resp = await axios.post(
//       `${APP_BASE_URL}tenant-login`,
//       { mobile: params.mobile, password: CLIENT_ID, platform: 'web', client_id: CLIENT_ID },
//       {
//         headers: {
//           'X-Tenant': tenantId,
//           'ACC-ATOKEN': cred,
//           'Client-ID': CLIENT_ID,
//           Accept: 'application/json',
//         },
//       },
//     );
//     if (resp.data && resp.data.success) {
//       await storeAccessToken({ access_token: resp.data.data.access_token, refresh_token: resp.data.data.refresh_token } as Credentials);
//       response.success = true;
//     }
//     return response;
//   } catch (err: any) {
//     console.log('tenantLogin err', err.response);
//     response.error = err.message;
//     return response;
//   }
// }

// const sendOtp = async (value: string, type: string): Promise<unknown> =>
//   await ApiUtil.postWithoutToken("admin/auth/sendOtp", { value, type });

// const verifyOtp = async (
//   value: string,
//   type: string,
//   otp: string
// ): Promise<string | undefined> => {
//   const res = (await ApiUtil.postWithoutToken("admin/auth/verifyOtp", {
//     value,
//     type,
//     otp,
//   })) as VerifyOtpResponse; // Add a type assertion to cast the response to VerifyOtpResponse

//   // access and refresh tokens
//   if (res?.tokens) {
//     await storeAccessToken(res.tokens as Credentials);
//   }
//   // email token for otp
//   return res?.token;
// };

// const logout = (): void => {
//   removeCookie("refreshToken");
//   removeCookie("accessToken");
//   window.location.reload();
// };

// const logoutTokenError = (): void => {
//   void removeAccessToken();
//   localStorage.clear();
//   window.history.replaceState(null, "", authEndPoints.logout);
//   window.location.reload();
// };

// const emailCheck = async (
//   email: string
// ): Promise<EmailCheckResponse | unknown> => {
//   try {
//     return await ApiUtil.postWithoutToken("admin/auth/emailAlreadyExists", {
//       email,
//     });
//   } catch (error) {
//     throw new Error(`something went to wrong!. ${String(error)}`);
//   }
// };

// const emailDetails = async (email: string): Promise<unknown> => {
//   try {
//     return await ApiUtil.postWithoutToken("admin/auth/emailDetails", {
//       email,
//     });
//   } catch (error) {
//     throw new Error(`something went to wrong!. ${String(error)}`);
//   }
// };

// const forgotPassword = async (email: string): Promise<unknown> => {
//   try {
//     return await ApiUtil.postWithoutToken("admin/auth/forgotpassword", {
//       email,
//     });
//   } catch (error) {
//     throw new Error(`something went to wrong!. ${String(error)}`);
//   }
// };

// const updatePassword = async (
//   empId: string,
//   token: string,
//   data: PasswordData
// ): Promise<unknown> => {
//   try {
//     return await ApiUtil.postWithoutToken(
//       `admin/auth/updatepassword/${empId}/${token}`,
//       {
//         password: data?.password,
//         confirmPassword: data?.confirmPassword,
//       }
//     );
//   } catch (error) {
//     throw new Error(`something went to wrong!. ${String(error)}`);
//   }
// };
// const getAuthUserDetails = () => {
//   const user = JSON.parse(localStorage.getItem("userDetails") as string);
//   return user ? user : {};
// };
// export {
//   tenantLogin,
//   storeAccessToken,
//   removeAccessToken,
//   login,
//   sendOtp,
//   verifyOtp,
//   logout,
//   emailCheck,
//   emailDetails,
//   forgotPassword,
//   updatePassword,
//   logoutTokenError,
//   loginCheck,
//   refreshTokenUpdate,
//   getAuthUserDetails,
// };

// Multi Organization

import { jwtDecode } from "jwt-decode";
import { authEndPoints } from "../constants/apiEndPoints";
import ApiUtil from "../utils/ApiUtils";
import { AxiosError } from "axios";
import { decrypt, getCookie, removeCookie, setCookie } from "../utils/GeneralUtils";

interface Credentials {
  refresh_token: string;
  access_token: string;
}

interface Tokens {
  access_token: string | undefined;
  refresh_token: string | undefined;
}

interface VerifyOtpResponse {
  tokens?: Tokens;
  token?: string;
}

interface PasswordData {
  password: string;
  confirmPassword: string;
}

interface EmailCheckResponse {
  exist?: boolean;
}

const storeAccessToken = (token: any): Promise<boolean | AxiosError> => {
  return new Promise(async (resolve, reject) => {
    try {
      // await removeAccessToken();
      const newAccessToken = token?.access_token;
      const newRefreshToken = token?.refresh_token;
      await setCookie("accessToken", JSON.stringify(newAccessToken));
      await setCookie("refreshToken", JSON.stringify(newRefreshToken));

      setTimeout(() => {
        resolve(true);
      }, 1000);
    } catch (error) {
      reject(error);
    }
  });
};
const refreshTokenUpdate = (body: { refresh_token: string }): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = (await ApiUtil.postWithoutToken(
        authEndPoints.refreshToken,
        body
      )) as any;
      if (response.data.refresh_token && response.data.access_token) {
        storeAccessToken(response?.data);
      }
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
const loginCheck = (): boolean => {
  const credentials: any = getCookie("accessToken");
  if (credentials) {
    const { exp }: { exp: number } = jwtDecode(JSON.parse(credentials));
    if (Date.now() <= exp * 1000) {
      return true;
    } else {
      removeCookie("accessToken");
      removeCookie("refreshToken");
      return false;
    }
  }
  return false;
};
const removeAccessToken = async (): Promise<void> => {
  await new Promise((resolve, reject) => {
    try {
      localStorage.clear();
      resolve(true);
    } catch (error) {
      reject(error);
    }
  });
};

async function login(phonenumber: string, password: string): Promise<any> {
  try {
    const res = (await ApiUtil.postWithoutToken(authEndPoints.login, {
      mobile: phonenumber,
      password,
      platform: "web",
    })) as any;
    if (res.data) {
      const { access_token, refresh_token } = res.data;
      await storeAccessToken({ access_token, refresh_token } as Credentials);
    }
  } catch (error) {
    console.log("Error during login:", error);
    throw error;
  }
}

const sendOtp = async (value: string, type: string): Promise<unknown> =>
  await ApiUtil.postWithoutToken("admin/auth/sendOtp", { value, type });

const verifyOtp = async (
  value: string,
  type: string,
  otp: string
): Promise<string | undefined> => {
  const res = (await ApiUtil.postWithoutToken("admin/auth/verifyOtp", {
    value,
    type,
    otp,
  })) as VerifyOtpResponse; // Add a type assertion to cast the response to VerifyOtpResponse

  // access and refresh tokens
  if (res?.tokens) {
    await storeAccessToken(res.tokens as Credentials);
  }
  // email token for otp
  return res?.token;
};

const logout = (): void => {
  removeCookie("refreshToken");
  removeCookie("accessToken");
  window.location.reload();
  localStorage.clear();
};

const logoutTokenError = (): void => {
  void removeAccessToken();
  localStorage.clear();
  window.history.replaceState(null, "", authEndPoints.logout);
  window.location.reload();
};

const emailCheck = async (
  email: string
): Promise<EmailCheckResponse | unknown> => {
  try {
    return await ApiUtil.postWithoutToken("admin/auth/emailAlreadyExists", {
      email,
    });
  } catch (error) {
    throw new Error(`something went to wrong!. ${String(error)}`);
  }
};

const emailDetails = async (email: string): Promise<unknown> => {
  try {
    return await ApiUtil.postWithoutToken("admin/auth/emailDetails", {
      email,
    });
  } catch (error) {
    throw new Error(`something went to wrong!. ${String(error)}`);
  }
};

const forgotPassword = async (email: string): Promise<unknown> => {
  try {
    return await ApiUtil.postWithoutToken("admin/auth/forgotpassword", {
      email,
    });
  } catch (error) {
    throw new Error(`something went to wrong!. ${String(error)}`);
  }
};

const updatePassword = async (
  empId: string,
  token: string,
  data: PasswordData
): Promise<unknown> => {
  try {
    return await ApiUtil.postWithoutToken(
      `admin/auth/updatepassword/${empId}/${token}`,
      {
        password: data?.password,
        confirmPassword: data?.confirmPassword,
      }
    );
  } catch (error) {
    throw new Error(`something went to wrong!. ${String(error)}`);
  }
};

const getAuthUserDetails = () => {
  const user = localStorage.getItem("userDetails");
  if (!user) {
    return {};
  }

  const decryptedString = decrypt("user_detail_salt", user);

  try {
    return JSON.parse(decryptedString);
  } catch (e) {
    console.error("Failed to parse user details:", e);
    return {};
  }
};
const getCommonVariables = () => {
  const commonVariables = localStorage.getItem("commonVariables");
  if (!commonVariables) {
    return {};
  }

  const decryptedString = decrypt("common_variable_salt", commonVariables);
  try {
    return JSON.parse(decryptedString);
  } catch (e) {
    console.error("Failed to parse commonVariables:", e);
    return {};
  }
};
const getAppConfiguration = () => {
  const appConfiguration = localStorage.getItem("appConfiguration");
  if (!appConfiguration) {
    return {};
  }

  const decryptedString = decrypt("app_configuration_salt", appConfiguration);
  try {
    return JSON.parse(decryptedString);
  } catch (e) {
    console.error("Failed to parse appConfiguration:", e);
    return {};
  }
};
export {
  getCommonVariables, getAppConfiguration,
  storeAccessToken,
  removeAccessToken,
  login,
  sendOtp,
  verifyOtp,
  logout,
  emailCheck,
  emailDetails,
  forgotPassword,
  updatePassword,
  logoutTokenError,
  loginCheck,
  refreshTokenUpdate,
  getAuthUserDetails,
};
