import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Grid,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { EditIcon } from "../../../../../assets/icons";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import RoomTransferModal from "../../../List/RoomTransferModal";
import { RouteUrls } from "../../../../../constants/routes";
import DoctorsAdvice from "./History/DoctorsAdvice";
import IpHistory from "./History";
import PermissionUtils from "../../../../../utils/PermissionUtils";
import moment from "moment";

type PropsType = {
  ipAdmissionDetails: any;
  patientDetails: any;
  ipInformation: any;
};

const Overview = ({
  ipAdmissionDetails,
  patientDetails,
  ipInformation,
}: PropsType) => {
  const { inPatients, editUrl } = RouteUrls;
  const { can } = PermissionUtils();

  const [ward, setWard] = useState(null);

  const patientInformation = [
    {
      name: "Name",
      value: patientDetails.name,
    },
    {
      name: "Relation",
      value: patientDetails.relation,
    },
    {
      name: "UHID",
      value: patientDetails.UHID,
    },
    {
      name: "Mobile",
      value: patientDetails.mobile,
    },
    {
      name: "Age / Gender",
      value: `${patientDetails?.age || "-"} / ${patientDetails?.gender || "-"}`,
    },
    {
      name: "Address",
      value: patientDetails.address,
    },
  ];

  const ipDetails = [
    {
      name: "IP No",
      value: ipInformation.ipNo,
    },
    {
      name: "Reason",
      value: ipInformation.reason,
    },
    {
      name: "Doctor Name",
      value: ipInformation.doctorName,
    },
    {
      name: "Status",
      value: ipInformation.status,
    },
    {
      name: "Ward/Room",
      value: ward
        ? ward
        : `${ipInformation?.floorName ? ipInformation?.floorName : "-"} / ${
            ipInformation?.roomNo ? ipInformation?.roomNo : "-"
          }`,
    },
    {
      name: "Admission Date",
      value: ipInformation.admissionDate
        ? moment(ipInformation.admissionDate).format("DD/MM/YYYY hh:mm:ss A")
        : "-",
    },
    {
      name: "Attender / Mobile",
      value: ` ${
        ipInformation.attenderName ? ipInformation.attenderName : "-"
      } / ${ipInformation.attenderMobile ? ipInformation.attenderMobile : "-"}`,
    },
    {
      name: "Discharge Date",
      value: ipInformation.dischargeDate
        ? moment(ipInformation.dischargeDate).format("DD/MM/YYYY hh:mm:ss A")
        : "-",
    },
    {
      name: "Second Attender / Mobile",
      value: `${
        ipInformation.secondAttenderName
          ? ipInformation.secondAttenderName
          : "-"
      } / ${
        ipInformation.secondAttenderMobile
          ? ipInformation.secondAttenderMobile
          : "-"
      }`,
    },
    {
      name: "Health",
      value: ipInformation.dischargeCondition,
    },
  ];

  const vitalDetails = [
    { name: "Allergies", value: "Lactose Intolerance, Coeliac Disease" },
    { name: "Findings", value: "A fever is a body temperature above 100.4°F" },
    { name: "Symptoms", value: "Headache, Nausea" },
  ];

  const [isRoomTransferModelOpen, setIsRoomTransferModelOpen] =
    useState<any>(false);

  return (
    <Grid
      container
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
      }}
    >
      {isRoomTransferModelOpen && ipAdmissionDetails && (
        <RoomTransferModal
          isOpen={isRoomTransferModelOpen}
          onClose={() => setIsRoomTransferModelOpen(false)}
          ipAdmissionDetails={ipAdmissionDetails}
          setWard={setWard}
        />
      )}
      <Grid
        container
        sx={{
          width: { xs: "98%", lg: "49%" },
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FBFBFB",
          border: 1,
          borderColor: "greyScale.lighter",
          borderRadius: "5px",
          mt: { xs: "20px", lg: "0px" },
        }}
      >
        <Grid
          sx={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "0px 10px",
            borderBottom: 1,
            borderColor: "greyScale.lighter",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "primary.main",
            }}
          >
            Patient Information
          </Typography>

          {can("ip_room_transfer") ? (
            <IconButton
              disableFocusRipple={true}
              disableRipple={true}
              sx={{ display: "flex", alignItems: "center" }}
              onClick={() => setIsRoomTransferModelOpen(true)}
            >
              <Typography
                variant="h3"
                color="initial"
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "primary.main",
                }}
              >
                Room Transfer
              </Typography>
            </IconButton>
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sx={{
              width: {
                xs: "95%",
              },
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              m: "15px 0px 10px 0px",
            }}
          >
            {patientInformation.map((row: any, index: number) => {
              return (
                <Grid
                  key={index}
                  sx={{
                    width: {
                      xs: "100%",
                      md: "50%",
                      lg: "50%",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    color: "textPrimary.main",
                    mb: "23px",
                  }}
                  className="row"
                >
                  <Grid
                    sx={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      {row.name}
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      pl: 3,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "greyScale.main" }}>
                      {row.value ? row.value : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          width: { xs: "98%", lg: "49%" },
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FBFBFB",
          border: 1,
          borderColor: "greyScale.lighter",
          mt: { xs: "20px", lg: "0px" },
        }}
      >
        <Grid
          sx={{
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: "0px 10px",
            borderBottom: 1,
            borderColor: "greyScale.lighter",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "primary.main",
            }}
          >
            Ip Information
          </Typography>

          {can("ip_edit") ? (
            <Link
              to={`${inPatients}${editUrl}/${ipAdmissionDetails?.id}`}
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <IconButton
                disableFocusRipple={true}
                disableRipple={true}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <EditIcon />
                <Typography
                  variant="h3"
                  color="initial"
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "primary.main",
                  }}
                >
                  Edit
                </Typography>
              </IconButton>
            </Link>
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sx={{
              width: {
                xs: "95%",
              },
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              m: "15px 0px 10px 0px",
            }}
          >
            {ipDetails.map((row: any, index: number) => {
              return (
                <Grid
                  key={index}
                  sx={{
                    width: {
                      xs: "100%",
                      md: "50%",
                      lg: "50%",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    color: "textPrimary.main",
                    mb: "23px",
                  }}
                  className="row"
                >
                  <Grid
                    sx={{
                      width: "40%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      {row.name}
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      width: "60%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      pl: 3,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "greyScale.main" }}>
                      {row.value ? row.value : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      {/* developer comment */}
      {/* <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          m: "20px 0px",
        }}
      >
        {vitalDetails?.map((vital: any) => {
          return (
            <Grid
              sx={{
                width: "auto",
                minWidth: "30%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: "10px",
                border: 1,
                borderColor: "greyScale.lighter",
              }}
            >
              <Typography variant="h4" sx={{ color: "#747474" }}>
                {vital?.name}
              </Typography>
              <Typography variant="h4" sx={{ color: "#747474", mr: "5px" }}>
                :
              </Typography>
              <Typography
                sx={{ color: "#747474", fontSize: "14", fontWeight: "500" }}
              >
                {vital?.value}
              </Typography>
            </Grid>
          );
        })}
      </Grid> */}
      <IpHistory />
    </Grid>
  );
};

export default Overview;
