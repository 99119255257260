import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Tab,
  Typography,
  Tabs as Tabes,
} from "@mui/material";
import {
  TestAndScansIcon,
  AddFollowUpIcon,
  VitalsTrackerIcon,
  FollowUpHistoryIcon,
} from "../../../assets/icons";
import { DialogWrapper, Tabs } from "../../../components/shared";
import Diet from "./Tabs/Diet";
import Findings, { FollowUpDetailsType } from "./Tabs/Findings";
import Notes from "./Tabs/Notes";
import Suggestions from "./Tabs/Suggestions";
import Activities from "./Tabs/Activities";
import React, { useCallback, useEffect, useState } from "react";
import {
  getAllFollowupById,
  getAntenatalById,
  getFollowupById,
} from "../../../services/gynecologyService";
import FollowUpHistory from "./FollowUpHistory";
import TestAndScans from "./TestAndScans";
import DetailsPage from "./FollowUpHistory/Details";
import { useNavigate, useParams } from "react-router-dom";
import VitalsTracker from "./VitalsTracker";
import {
  FollowUpDetailsTypes,
  setFollowUpDetails,
} from "../../../redux/slices/gynecology";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type AntenatalTypes = {
  id: string | number | null;
  patient_id: string | number | null;
  date_of_first_visit: string | number | null;
  menstruation_cycle: string | number | null;
  cycle_duration: string | number | null;
  liquid_flow: string | number | null;
  obstetric: [
    {
      a: string | number | null;
      g: string | number | null;
      l: string | number | null;
      p: string | number | null;
    }
  ];
  lmp: string | number | null;
  edd: string | number | null;
  first_pregnancy: string | number | null;
  rch_id: string | number | null;
  maternal_history: string | number | null;
  history_of_disease: [];
  surgical_history: string | number | null;
  created_at: string | number | null;
  updated_at: string | number | null;
  deleted_at: string | number | null;
  created_by: string | number | null;
  updated_by: string | number | null;
  deleted_by: string | number | null;
};
export default function GynecologyView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lastFollowUpId } = useSelector(
    (state: RootState) => state.gynecology
  );
  const [value, setValue] = React.useState(0);
  const [isTestAndScansOpen, setIsTestAndScansOpen] = useState(false);
  const [isVitalsTrackerOpen, setIsVitalsTrackerOpen] = useState(false);
  const [isOpenFollowUpDetail, setIsOpenFollowUpDetail] = useState(false);
  const [isFollowUpHistoryOpen, setIsFollowUpHistoryOpen] = useState(false);
  const [isAllFollowUpHistoryOpen, setAllIsFollowUpHistoryOpen] =
    useState(false);
  const [isDetailPageLoading, setIsDetailPageLoading] = useState(false);
  const [lastFollowUpDetails, setLastFollowUpDetails] = useState<
    FollowUpDetailsType | any
  >([]);
  const [vitalsInfo, setVitalsInfo] = useState([]);
  const [allFollowUpDetail, setAllFollowUpDetail] = useState([]);
  const [followUpDetail, setFollowUpDetail] = useState<any>({});
  const [antenatalDetails, setAntenatalDetails] = useState<
    AntenatalTypes | any
  >({});
  const masterTabsList = [
    {
      children: <Findings lastFollowUpDetails={lastFollowUpDetails} />,
      label: "Findings",
    },
    {
      children: <Suggestions lastFollowUpDetails={lastFollowUpDetails} />,
      label: "Suggestions",
    },
    {
      children: <Diet lastFollowUpDetails={lastFollowUpDetails} />,
      label: "Diet",
    },
    {
      children: <Activities lastFollowUpDetails={lastFollowUpDetails} />,
      label: "Activities",
    },
    {
      children: <Notes lastFollowUpDetails={lastFollowUpDetails} />,
      label: "Notes",
    },
  ];

  const getFollowUpDetailsById = async () => {
    setAllIsFollowUpHistoryOpen(true);

    if (id) {
      await getAllFollowupById(id)
        .then((res: any) => {
          setAllFollowUpDetail(res.data);
        })
        .catch((err) => console.log(err));
    }

    setAllIsFollowUpHistoryOpen(false);
  };

  const followUpDetailById = async () => {
    await getFollowupById(lastFollowUpId as string)
      .then((res: any) => {
        setLastFollowUpDetails(res.data);
      })
      .catch((err) => console.log(err));
  };
  const handleVitalsDetails = (data: any) => {
    const formattedData = data?.vitals?.map(
      (
        ele: {
          vital_name: string;
          vital_id: number;
          vital_unit: string;
          vital_value: string;
        },
        idx: number
      ) => ({
        name: ele?.vital_name,
        label: ele?.vital_name,
        placeholder: "",
        value: ele?.vital_value,
        type: "textField",
        isFullWidth: true,
        disabled: true,
        flex: Number(idx) % 2 === 0 ? "flex-start" : "flex-end",
      })
    );
    setVitalsInfo(formattedData);
  };
  const getFollowUpListById = async (id: string | number) => {
    setIsDetailPageLoading(true);
    await getFollowupById(id)
      .then((res: any) => {
        setFollowUpDetail(res.data);
        handleVitalsDetails(res.data);
        setIsDetailPageLoading(false);
      })
      .catch((err) => console.log(err));
    setIsDetailPageLoading(false);
  };
  const handleFollowUpHistory = (id: string | number) => {
    setIsOpenFollowUpDetail(true);
    getFollowUpListById(id);
  };
  const [trimester, setTrimester] = useState<{
    week: string | number | null;
    days: string | number | null;
    type: string | number | null;
    percentage: string | number | null;
  }>({
    week: 0,
    days: 0,
    type: "",
    percentage: 0,
  });
  const handleCalculateTrimesterWeek = useCallback(() => {
    const startMs = new Date(antenatalDetails.lmp).getTime();
    const endMs = new Date(antenatalDetails.edd).getTime();
    const differenceMs = Math.abs(endMs - startMs);
    const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
    const weekPercentage =
      value === 0
        ? lastFollowUpDetails.trimester_week
        : Number(lastFollowUpDetails?.trimester_week) * 7;

    const daysPercentage =
      value === 0 ? Math.round(differenceDays / 7) : Math.round(differenceDays);

    setTrimester((prevState) => ({
      ...prevState,
      week: lastFollowUpDetails.trimester_week || 0,
      days: Math.round(lastFollowUpDetails.trimester_week * 7) || 0,
      type: value === 0 ? "Days" : "Weeks",
      percentage: (weekPercentage / daysPercentage) * 100,
    }));
  }, [antenatalDetails]);
  useEffect(() => {
    if ((antenatalDetails && lastFollowUpDetails) || value) {
      handleCalculateTrimesterWeek();
    }
  }, [antenatalDetails, lastFollowUpDetails, value]);

  const getAntenatalDetailsById = async () => {
    await getAntenatalById(id as string)
      .then((res: any) => {
        setAntenatalDetails(res.data);
      })
      .catch((err) => console.log(err));
  };
  const handleOpenAddFollowUp = () => {
    setIsFollowUpHistoryOpen(false);
  };
  const handleTestAndScansModal = () => {
    setIsTestAndScansOpen(!isTestAndScansOpen);
  };
  const handleVitalsTrackerModal = () => {
    setIsVitalsTrackerOpen(!isVitalsTrackerOpen);
  };
  const handleEditFollowup = (id: string | number, details: any) => {
    setIsOpenFollowUpDetail(false);
    setIsFollowUpHistoryOpen(false);
    dispatch(setFollowUpDetails(details as FollowUpDetailsTypes));
    navigate(`/gynecology/add-followup/${id}`);
  };

  const personalInfoList = [
    {
      name: "date_of_visit",
      label: "Date of Visit",
      placeholder: "",
      value: followUpDetail.date_of_visit,
      type: "date",
      isFullWidth: true,
      disabled: true,
      flex: "flex-start",
    },
    {
      name: "trimester_week",
      label: "Trimester Week",
      placeholder: "",
      value: followUpDetail.trimester_week,
      type: "textField",
      isFullWidth: true,
      disabled: true,
      flex: "flex-end",
    },
    ...vitalsInfo,
  ];
  const trimesterType: { [key: string]: string } = {
    "Third Trimester": "III",
    "Second Trimester": "II",
    "First Trimester": "I",
  };
  useEffect(() => {
    getFollowUpDetailsById();
    if (lastFollowUpId) {
      followUpDetailById();
    }
    getAntenatalDetailsById();
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box>
        <Grid container sx={{ fontFamily: "Inter" }}>
          <Grid item sm={6}>
            <Box
              sx={{
                backgroundColor: "#FFDEE3",
                borderRadius: "5px",
                width: "585px",
                height: "220px",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
              }}
            >
              <Grid container height={"100%"}>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    paddingLeft: "24px",
                    paddingBottom: "13px",
                  }}
                >
                  <Chip
                    label={`Trimester - ${
                      trimesterType[lastFollowUpDetails?.trimester] || "0"
                    }`}
                    sx={{
                      width: "127px",
                      backgroundColor: "white",
                      color: "#CE3F79",
                      border: "none",
                      borderRadius: "22px",
                      height: "40px",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  />
                </Grid>
                <Grid item sm={4} sx={{ textAlign: "center", margin: "auto" }}>
                  <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      variant="determinate" // Animated variant
                      thickness={4} // Adjust thickness as needed
                      sx={{
                        color: "#EA6EA1",
                        strokeLinecap: "round",
                        transform: "rotate(-90deg)",
                      }}
                      style={{ strokeLinecap: "round" }} // Add line cap at the end
                      value={trimester.percentage as number}
                      size={130}
                    />
                    <Box
                      sx={{
                        top: "50%",
                        left: "50%",
                        position: "absolute",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        color: "#191919", // Change the color of the text if needed
                      }}
                    >
                      <Typography
                        variant="caption"
                        component="div"
                        color="#191919"
                        fontSize={"45px"}
                        fontWeight={600}
                        lineHeight="1"
                      >
                        {value === 0 ? trimester.days : trimester.week}
                      </Typography>
                      <Typography
                        variant="caption"
                        component="div"
                        color="#191919"
                        fontSize={"10px"}
                      >
                        {trimester.type}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <Tabes
                    value={value}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="secondary tabs example"
                    sx={{
                      "& .MuiTabs-scroller": {
                        borderRadius: "50px",
                        backgroundColor: "#FFFFFF",
                      },
                      "& .MuiButtonBase-root": {
                        margin: "2px 4px 4px",
                        borderRadius: "50px",
                        fontSize: "12px",
                      },
                      "& .MuiTabs-indicator": {
                        display: "none",
                      },
                      "& .Mui-selected": {
                        color: "#FFFFFF !important",
                      },
                      width: "164px",
                      paddingRight: "24px",
                      paddingBottom: "13px",
                    }}
                  >
                    <Tab
                      value={0}
                      label="Days"
                      sx={{
                        minWidth: "49%",
                        backgroundColor: value === 0 ? "#121212 " : "",
                        color:
                          value === 0
                            ? "##FFFFFF !important"
                            : "#121212 !important",
                        fontWeight: value === 0 ? 700 : 600,
                      }}
                    />
                    <Tab
                      value={1}
                      label="Weeks"
                      sx={{
                        minWidth: "49%",
                        backgroundColor: value === 1 ? "#121212 " : "",
                        color:
                          value === 1
                            ? "##FFFFFF !important"
                            : "#121212 !important",
                        fontWeight: value === 1 ? 700 : 600,
                      }}
                    />
                  </Tabes>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            sm={6}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Grid container>
              <Grid item sm={6} sx={{ marginBottom: "12px" }}>
                <Box
                  sx={{
                    width: "315px",
                    height: "98px",
                    backgroundColor: "#ECF1FF",
                    borderRadius: "12px",
                    borderBottom: "6px solid #D7E1FE",
                    cursor: "pointer",
                  }}
                  onClick={() => handleTestAndScansModal()}
                >
                  <Box sx={{ display: "flex" }}>
                    <TestAndScansIcon style={{ padding: "27px 14px" }} />
                    <Box sx={{ lineHeight: "1.6", padding: "16px" }}>
                      <Box sx={{ fontSize: "16px", fontWeight: "600" }}>
                        Tests & Scans
                      </Box>
                      <Box sx={{ fontSize: "11px", fontWeight: "500" }}>
                        Last Test: Thyroid -40/199mg/dL
                      </Box>
                      <Box sx={{ fontSize: "11px", fontWeight: "500" }}>
                        12/02/2024 - 07:00 AM
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6} sx={{ marginBottom: "12px" }}>
                <Box
                  sx={{
                    width: "315px",
                    height: "98px",
                    backgroundColor: "#FFF7EC",
                    borderRadius: "12px",
                    borderBottom: "6px solid #FFE6C4",
                    cursor: "pointer",
                  }}
                  onClick={() => handleVitalsTrackerModal()}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box>
                      <VitalsTrackerIcon style={{ padding: "27px 14px" }} />
                    </Box>
                    <Box sx={{ lineHeight: "1.6", padding: "16px" }}>
                      <Box sx={{ fontSize: "16px", fontWeight: "600" }}>
                        Vitals Tracker
                      </Box>
                      <Box sx={{ fontSize: "11px", fontWeight: "500" }}>
                        Analyzing Test Results: Gain Insight into Your Heal
                        through Comparative Vitals Tracking
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{ marginBottom: "12px", cursor: "pointer" }}
              >
                <Box
                  sx={{
                    width: "315px",
                    height: "98px",
                    backgroundColor: "#F8ECFF",
                    borderRadius: "12px",
                    borderBottom: "6px solid #EED0FF",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/gynecology/add-followup`)}
                >
                  <Box sx={{ display: "flex" }}>
                    <AddFollowUpIcon style={{ padding: "27px 14px" }} />
                    <Box sx={{ lineHeight: "1.6", padding: "16px" }}>
                      <Box sx={{ fontSize: "16px", fontWeight: "600" }}>
                        Add Follow Up
                      </Box>
                      <Box sx={{ fontSize: "11px", fontWeight: "500" }}>
                        Last Visit Date: 25/12/2024
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6} sx={{ marginBottom: "12px" }}>
                <Box
                  sx={{
                    width: "315px",
                    height: "98px",
                    backgroundColor: "#E7FBF5",
                    borderRadius: "12px",
                    borderBottom: "6px solid #C0EFE1",
                  }}
                >
                  <Box
                    sx={{ display: "flex", cursor: "pointer" }}
                    onClick={() => setIsFollowUpHistoryOpen(true)}
                  >
                    <FollowUpHistoryIcon style={{ padding: "27px 14px" }} />
                    <Box sx={{ lineHeight: "1.6", padding: "16px" }}>
                      <Box sx={{ fontSize: "16px", fontWeight: "600" }}>
                        Follow-up History
                      </Box>
                      <Box sx={{ fontSize: "11px", fontWeight: "500" }}>
                        Patient History for Efficient Monitoring
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ fontSize: "18px", fontWeight: "600" }}>
          Last Date - 24/12/2024
        </Box>
        <Box
          sx={{ width: "100%", bgcolor: "background.paper", marginTop: "12px" }}
        >
          <Tabs
            data={masterTabsList}
            scrollButtons={true}
            sx={{
              "& .Mui-selected": {
                color: "#CE3F79 !important",
              },
              "& .MuiTab-root": {
                minWidth: "268px",
                fontWeight: 500,
                fontSize: "14px",
              },
              "& .MuiTabs-indicator": {
                backgroundColor: "#CE3F79",
              },
            }}
          />
        </Box>
      </Box>
      <DialogWrapper
        open={isFollowUpHistoryOpen}
        handleClick={() => setIsFollowUpHistoryOpen(!isFollowUpHistoryOpen)}
        onClose={() => setIsFollowUpHistoryOpen(!isFollowUpHistoryOpen)}
        title={"Follow Up History"}
        confirmText="OK"
      >
        {isAllFollowUpHistoryOpen ? (
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <FollowUpHistory
            followUpDetail={allFollowUpDetail}
            handleFollowUpHistory={handleFollowUpHistory}
            handleOpenAddFollowUp={handleOpenAddFollowUp}
          />
        )}
      </DialogWrapper>
      <DialogWrapper
        open={isTestAndScansOpen}
        handleClick={() => setIsTestAndScansOpen(!isTestAndScansOpen)}
        onClose={() => setIsTestAndScansOpen(!isTestAndScansOpen)}
        title={"Tests & Scans"}
        confirmText="OK"
      >
        <TestAndScans />
      </DialogWrapper>
      <DialogWrapper
        open={isVitalsTrackerOpen}
        handleClick={() => setIsVitalsTrackerOpen(!isVitalsTrackerOpen)}
        onClose={() => setIsVitalsTrackerOpen(!isVitalsTrackerOpen)}
        title={"Vitals Tracker"}
        confirmText="OK"
      >
        <VitalsTracker lastFollowUpDetails={lastFollowUpDetails} />
      </DialogWrapper>
      <DialogWrapper
        open={isOpenFollowUpDetail}
        handleClick={() => setIsOpenFollowUpDetail(!isOpenFollowUpDetail)}
        onClose={() => setIsOpenFollowUpDetail(!isOpenFollowUpDetail)}
        title={"Details Page"}
        confirmText="OK"
      >
        {isDetailPageLoading ? (
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <DetailsPage
              followUpDetail={followUpDetail}
              handleEditFollowup={handleEditFollowup}
              personalInfoList={personalInfoList}
            />
          </>
        )}
      </DialogWrapper>
    </>
  );
}
