const BASE_URL = process.env.REACT_APP_DR_CARROT_BASE_URL;
const API_PATH = "/api";
const v1 = "/v1";
const BASE_PATH = `${BASE_URL}${API_PATH}`;
export const appointmentEndPoints = {
  getAllAppointments: `${BASE_PATH}${v1}/appointments/list`, //Appointments
  createAppointment: `${BASE_PATH}${v1}/appointments/create`,
  getAllDoctorsName: `${BASE_PATH}${v1}/doctors`,
  getAllReferDoctorsName: `${BASE_PATH}${v1}/refer-doctor/all-refer-doctor`,
  getAllConsultationsName: `${BASE_PATH}${v1}/purposes`,
  getAllPatientsName: `${BASE_PATH}${v1}/patients`,
  getSlots: `${BASE_PATH}${v1}/appointments/slots`,
  getDoctorsAvailableDays: `${BASE_PATH}${v1}/appointments/available-days`,
  checkAppointmentStatus: `${BASE_PATH}${v1}/appointments/check`,
  getTokens: `${BASE_PATH}${v1}/appointments/available-token-days`,
  cancelAppointment: `${BASE_PATH}${v1}/appointments/cancel`,
  appointmentDetails: `${BASE_PATH}${v1}/appointments/details`,
  rescheduleAppointment: `${BASE_PATH}${v1}/appointments/reschedule `,
  trackingStatus: `${BASE_PATH}${v1}/appointments/tracking_status`,
  reviewAppointment: `${BASE_PATH}${v1}/appointments/review-appointment`,
  pxProductsList: `${BASE_PATH}${v1}/px-products/get-pxproducts`,
  productsList: `${BASE_PATH}${v1}/procurements/products`,
  prescriptionDetail: `${BASE_PATH}${v1}/prescriptions`,
  symptomsList: `${BASE_PATH}${v1}/symptoms/list`,
  prescriptionProductDelete: `${BASE_PATH}${v1}/prescriptions/product/delete`,
  prescriptionCreate: `${BASE_PATH}${v1}/prescriptions/add-prescription`,
  prescriptionProductUpdate: `${BASE_PATH}${v1}/prescriptions/update-prescription`,
  prescriptionDelete: `${BASE_PATH}${v1}/prescriptions/delete`,
  investigationList: `${BASE_PATH}${v1}/investigations/list`,
  testList: `${BASE_PATH}${v1}/billing/items/lab-test-items`,
  investigationTestCreate: `${BASE_PATH}${v1}/investigations/create`,
  investigationTestUpdate: `${BASE_PATH}${v1}/investigations/update`,
  investigationDelete: `${BASE_PATH}${v1}/investigations/delete`,
  investigationTestDelete: `${BASE_PATH}${v1}/investigations/item-delete`,
  invoiceNumber: `${BASE_PATH}${v1}/sequence`,
  getAllVitals: `${BASE_PATH}${v1}/vitals`,
  getDoctorVitalsDetails: `${BASE_PATH}${v1}/vitals?doctor_id=`,
  createVitals: `${BASE_PATH}${v1}/op-vitals`,
  getVitalsByAppointment: `${BASE_PATH}${v1}/op-vitals`,
  getMedicalRecordsByAppointment: `${BASE_PATH}${v1}/medical-records`,
  getMedicalReportsByAppointment: `${BASE_PATH}${v1}/medical-records/reports/`,
  createMedicalRecords: `${BASE_PATH}${v1}/medical-records/create`,
  deleteMedicalRecords: `${BASE_PATH}${v1}/medical-records/delete`,
  viewMedicalRecords: `${BASE_PATH}${v1}/medical-records/view`,
  updateMedicalRecords: `${BASE_PATH}${v1}/medical-records/update`,
  labTestUrl: `${BASE_PATH}${v1}/billing/patients-bills/items`,
  sequenceUrl: `${BASE_PATH}${v1}/sequence`,
  getAllCommonVariables: `${BASE_PATH}${v1}/common-variables`,
  commonAppConfiguration: `${BASE_PATH}${v1}/app-configuration`,
  sequenceUpdateUrl: `${BASE_PATH}${v1}/sequence`,
  getAllUnitsList: `${BASE_PATH}${v1}/procurements/units?active=1`,
  getPatientsLimit: `${BASE_PATH}${v1}/patients/limit`,
  getPrintNotesOpTypes: `${BASE_PATH}${v1}/print-notes?search=OP`,
  deleteAppointmentById: `${BASE_PATH}${v1}/appointments`,
};
export const userEndPoints = {
  getUserDetails: `${BASE_PATH}${v1}/user`,
};
export const authEndPoints = {
  login: `${BASE_PATH}${v1}/login`,
  logout: `${BASE_PATH}${v1}/logout`,
  refreshToken: `${BASE_PATH}${v1}/refresh-token`,
};
export const appointmentMastersUrl = {
  workingHoursList: `${BASE_PATH}${v1}/working-hours`,
  workingHoursCreate: `${BASE_PATH}${v1}/working-hours`,
  workingHoursEdit: `${BASE_PATH}${v1}/working-hours`,
  workingHoursUpdate: `${BASE_PATH}${v1}/working-hours`,
  workingHoursDelete: `${BASE_PATH}${v1}/working-hours/doctor`,
  vacationsList: `${BASE_PATH}${v1}/vacations`,
  vacationsCreate: `${BASE_PATH}${v1}/vacations`,
  vacationsEdit: `${BASE_PATH}${v1}/vacations`,
  vacationsUpdate: `${BASE_PATH}${v1}/vacations`,
  vacationsDelete: `${BASE_PATH}${v1}/vacations`,
  printNotesList: `${BASE_PATH}${v1}/print-notes`,
  printNotesCreate: `${BASE_PATH}${v1}/print-notes`,
  printNotesEdit: `${BASE_PATH}${v1}/print-notes`,
  printNotesUpdate: `${BASE_PATH}${v1}/print-notes`,
  printNotesDelete: `${BASE_PATH}${v1}/print-notes`,
  consultationsList: `${BASE_PATH}${v1}/purposes/list`,
  consultationCreate: `${BASE_PATH}${v1}/purposes/create`,
  consultationEdit: `${BASE_PATH}${v1}/purposes/edit`,
  consultationUpdate: `${BASE_PATH}${v1}/purposes/update`,
  consultationDelete: `${BASE_PATH}${v1}/purposes/delete`,
  productsList: `${BASE_PATH}${v1}/px-products`,
  productCreate: `${BASE_PATH}${v1}/px-products`,
  productEdit: `${BASE_PATH}${v1}/px-products`,
  productUpdate: `${BASE_PATH}${v1}/px-products`,
  productDelete: `${BASE_PATH}${v1}/px-products`,
  symptomsMasterList: `${BASE_PATH}${v1}/symptoms/list`,
  symptomsMasterCreate: `${BASE_PATH}${v1}/symptoms/create`,
  symptomsMasterEdit: `${BASE_PATH}${v1}/symptoms/edit`,
  symptomsMasterUpdate: `${BASE_PATH}${v1}/symptoms/update`,
  symptomsMasterDelete: `${BASE_PATH}${v1}/symptoms/delete`,
};
export const billingEndPoints = {
  getItemListUrl: `${BASE_PATH}${v1}/billing/items`,
  getDepartmentsUrl: `${BASE_PATH}${v1}/billing/departments`,
  getGroupNamesUrl: `${BASE_PATH}${v1}/billing/item-group`,
  getConsultantsListUrl: `${BASE_PATH}${v1}/doctors`,
  getCommonVariablesUrl: `${BASE_PATH}${v1}/common-variables`,
  getLabResultOptionsUrl: `${BASE_PATH}${v1}/billing/lab-result`,
  getMethodologyOptionsUrl: `${BASE_PATH}${v1}/billing/methodology`,
  getAgeGroupOptionsUrl: `${BASE_PATH}${v1}/billing/items/age-group`,
  billingItemUrl: `${BASE_PATH}${v1}/billing/items`,
  getItemsGroupListUrl: `${BASE_PATH}${v1}/billing/item-group`,
  itemGroupMasterUrl: `${BASE_PATH}${v1}/billing/item-group`,
  methodologyListUrl: `${BASE_PATH}${v1}/billing/methodology`,
  labResultsListUrl: `${BASE_PATH}${v1}/billing/lab-result`,
  getAllPatientsName: `${BASE_PATH}${v1}/patients`,
  getBillingDepartments: `${BASE_PATH}${v1}/billing/departments`,
  getPatientDetailsUrl: `${BASE_PATH}${v1}/patients/edit`,
  patientBillUrl: `${BASE_PATH}${v1}/billing/patients-bills`,
  packageItemsUrl: `${BASE_PATH}${v1}/billing/items/package-items`,
  payeeUrl: `${BASE_PATH}${v1}/payee`,
  sequenceUrl: `${BASE_PATH}${v1}/sequence`,
  referralDoctorUrl: `${BASE_PATH}${v1}/refer-doctor`,
  ipUrl: `${BASE_PATH}${v1}/inpatients/admissions/all-ip-no`,
  opUrl: `${BASE_PATH}${v1}/appointments/all-op-no`,
  billDraftUrl: `${BASE_PATH}${v1}/billing/bill-draft`,
  getSequenceNumber: `${BASE_PATH}${v1}/sequence`,
  updateSequenceNumber: `${BASE_PATH}${v1}/sequence`,
  patientExcessAndAdvanceUrl: `${BASE_PATH}${v1}/payments/fetch-advance-excess`,
  cardUrl: `${BASE_PATH}${v1}/payments/terms`,
  sequenceUpdateUrl: `${BASE_PATH}${v1}/sequence`,
};
export const mainCoreEndPoints = {
  createPatient: `${BASE_PATH}${v1}/patients/create`,
  getPatients: `${BASE_PATH}${v1}/patients`,
  getAllPatientsList: `${BASE_PATH}${v1}/patients/list`,
  getPatientUser: `${BASE_PATH}${v1}/patients/user-edit`,
  updatePatientUser: `${BASE_PATH}${v1}/patients/update-patient-user`,
  getUserPatientsList: `${BASE_PATH}${v1}/patients/user-patients-list`,
  getPatientDetails: `${BASE_PATH}${v1}/patients/edit`,
  updatePatientDetails: `${BASE_PATH}${v1}/patients/update`,
  updateSequenceNumber: `${BASE_PATH}${v1}/sequence`,
  getSequenceNumber: `${BASE_PATH}${v1}/sequence`,
  getUserDetailsByMobileNumber: `${BASE_PATH}${v1}/patients/user-edit?mobile`,
};

export const paymentsEndPoints = {
  getAllPatientsName: `${BASE_PATH}${v1}/patients`,
  paymentsUrl: `${BASE_PATH}${v1}/payments`,
  payeeUrl: `${BASE_PATH}${v1}/payee`,
  patientPaymentBillsUrl: `${BASE_PATH}${v1}/payments/pending-bills`,
  advanceAndExcessUrl: `${BASE_PATH}${v1}/payments/fetch-advance-excess`,
  cardUrl: `${BASE_PATH}${v1}/payments/terms`,
  paymentAdjustmentUrl: `${BASE_PATH}${v1}/payment-adjustments`,
  advanceUrl: `${BASE_PATH}${v1}/advance`,
  getAllCommonVariables: `${BASE_PATH}${v1}/common-variables`,
  commonAppConfiguration: `${BASE_PATH}${v1}/app-configuration`,
};
export const procurementsApiEndPoints = {
  getAllPurchaseBillList: `${BASE_PATH}${v1}/procurements/purchase`,
  purchaseBillFileUpload: `${BASE_PATH}${v1}/procurements/purchase/invoice-upload`,
  getAllCustomerList: `${BASE_PATH}${v1}/procurements/customer`,
  getAllPurchasePaymentsList: `${BASE_PATH}${v1}/procurements/purchase-payments`,
  getPurchaseOrdersList: `${BASE_PATH}${v1}/procurements/purchase-orders`,
  getAllPurchaseSuppliersList: `${BASE_PATH}${v1}/procurements/suppliers`,
  getAllStockAdjustmentsList: `${BASE_PATH}${v1}/procurements/stock-adjustments`,
  getAllGoodsIssueList: `${BASE_PATH}${v1}/procurements/goods-issue`,
  getAllIndentsList: `${BASE_PATH}${v1}/procurements/indents`,
  getAllDepartments: `${BASE_PATH}${v1}/procurements/departments`,
  getAllMasterDepartments: `${BASE_PATH}${v1}/procurements/departments`,
  getAllSuppliers: `${BASE_PATH}${v1}/procurements/suppliers`,
  procurementProducts: `${BASE_PATH}${v1}/procurements/products`,
  purchaseOrderUrl: `${BASE_PATH}${v1}/procurements/purchase-orders`,
  getAllDepartmentProducts: `${BASE_PATH}${v1}/procurements/products`,
  procurementTaxUrl: `${BASE_PATH}${v1}/procurements/taxes`,
  getAllPatientsName: `${BASE_PATH}${v1}/patients`,
  getAllDoctorsName: `${BASE_PATH}${v1}/doctors`,
  getAllCommonVariables: `${BASE_PATH}${v1}/common-variables`,
  getAllSymptoms: `${BASE_PATH}${v1}/symptoms`,
  getPatientDetailsById: `${BASE_PATH}${v1}/patients/edit`,
  createAntenatal: `${BASE_PATH}${v1}/gynecology/antenatal-cards`,
  getAllSuggestions: `${BASE_PATH}${v1}/gynecology/suggestions`,
  getAllFindings: `${BASE_PATH}${v1}/gynecology/findings`,
  getAllScans: `${BASE_PATH}${v1}/gynecology/scans`,
  getAllTests: `${BASE_PATH}${v1}/gynecology/tests`,
  getAllFollowupById: `${BASE_PATH}${v1}/gynecology/followups?antenatal_card_id`,
  getFollowupById: `${BASE_PATH}${v1}/gynecology/followups`,
  getAllAntenatalList: `${BASE_PATH}${v1}/gynecology/antenatal-cards`,
  getAllPrevHistory: `${BASE_PATH}${v1}/gynecology/history/followups?antenatal_card_id`,
  getPatientVitals: `${BASE_PATH}${v1}/gynecology/op-vitals?patient_id`,
  getPatientVitalsHistory: `${BASE_PATH}${v1}/gynecology/history/vitals`,
  addFollowUp: `${BASE_PATH}${v1}/gynecology/followups`,
  deleteSuggestion: `${BASE_PATH}${v1}/gynecology/followups`,
  deleteAntenatal: `${BASE_PATH}${v1}/gynecology/antenatal-cards`,
  getAllPatientTests: `${BASE_PATH}${v1}/gynecology/history/tests?antenatal_card_id`,
  getAllPatientScans: `${BASE_PATH}${v1}/gynecology/history/scans?antenatal_card_id`,
  sequenceUrl: `${BASE_PATH}${v1}/sequence`,
  commonAppConfiguration: `${BASE_PATH}${v1}/app-configuration`,

  addPurchaseAdvancePayment: `${BASE_PATH}${v1}/procurements/purchase-advance-payments`,
  getPurchasePaymentBill: `${BASE_PATH}${v1}/procurements/purchase-payments/payment-bills`,
  getPurchasePayment: `${BASE_PATH}${v1}/procurements/purchase-payments`,
  getPurchaseReturnProduct: `${BASE_PATH}${v1}/procurements/purchase-return/products`,
  getWholeSalesReturnProduct: `${BASE_PATH}${v1}/procurements/whole-sales-return/products`,
  getGoodsIssueProduct: `${BASE_PATH}${v1}/procurements/goods-issue/products`,
  getGoodsIssueById: `${BASE_PATH}${v1}/procurements/goods-issue`,
  addPurchaseReturnProduct: `${BASE_PATH}${v1}/procurements/purchase-return`,
  goodsIssueUrl: `${BASE_PATH}${v1}/procurements/goods-issue`,
  stockTransferUrl: `${BASE_PATH}${v1}/procurements/stock-transfer`,
  goodsIssueReturnUrl: `${BASE_PATH}${v1}/procurements/goods-issue-return`,
  stockAdjustmentUrl: `${BASE_PATH}${v1}/procurements/stock-adjustments`,
  stockAdjustmentById: `${BASE_PATH}${v1}/procurements/stock-adjustments/products`,
  getPharmacyProduct: `${BASE_PATH}${v1}/pharmacy/products`,
  getGoodsIssueReturnProduct: `${BASE_PATH}${v1}/procurements/goods-issue-return/products`,
  goodsIssueTempUrl: `${BASE_PATH}${v1}/procurements/goods-issue-temp`,
  pharmacySalesBill: `${BASE_PATH}${v1}/pharmacy`,
  salesHistoryUrl: `${BASE_PATH}${v1}/procurements/whole-sales/sales-history`,
  wholeSalesUrl: `${BASE_PATH}${v1}/procurements/whole-sales`,
  wholeSalesAdjustmentUrl: `${BASE_PATH}${v1}/procurements/whole-sales-adjustments`,
  wholeSalesAdjustmentBalance: `${BASE_PATH}${v1}/procurements/whole-sales-adjustments/fetch-balance`,
  getWholeSalesProduct: `${BASE_PATH}${v1}/procurements/whole-sales/products`,
  WholesSalesReturn: `${BASE_PATH}${v1}/procurements/whole-sales-return`,
  purchaseTemporaryUrl: `${BASE_PATH}${v1}/procurements/purchase-temp`,
  wholeSalesPayments: `${BASE_PATH}${v1}/procurements/whole-sales-payments`,
  wholeSalesAdvance: `${BASE_PATH}${v1}/procurements/whole-sales-advance-payments`,
  customerAdvanceExcessWholeSales: `${BASE_PATH}${v1}/procurements/whole-sales-payments/fetch-advance-excess`,
  supplierExcessAndAdvanceUrl: `${BASE_PATH}${v1}/procurements/purchase-payments/fetch-advance-excess`,
  getAllIndentProductsUrl: `${BASE_PATH}${v1}/procurements/indents/overview`,
};

export const admissionEndPoints = {
  getAllPatientsName: `${BASE_PATH}${v1}/patients`,
  getConsultantsListUrl: `${BASE_PATH}${v1}/doctors`,
  admissionUrl: `${BASE_PATH}${v1}/inpatients/admissions`,
  roomUrl: `${BASE_PATH}${v1}/inpatients/rooms`,
  getCommonVariables: `${BASE_PATH}${v1}/common-variables`,
  getAppConfiguration: `${BASE_PATH}${v1}/app-configuration`,
  getUserInfo: `${BASE_PATH}${v1}/user`,
  getPatientDetailsUrl: `${BASE_PATH}${v1}/patients/edit`,
  inPatientAdmissionDetail: `${BASE_PATH}${v1}/inpatients/admissions`,
  getIpRoomTransferHistoryDetails: `${BASE_PATH}${v1}/inpatients/admissions/room-transfer-history`,
  updateInPatientAdmissionDetail: `${BASE_PATH}${v1}/inpatients/admissions`,
  getVitalsList: `${BASE_PATH}${v1}/vitals`,
  getAdmissionIpVitals: `${BASE_PATH}${v1}/inpatients/vitals?ip_admission_id=`,
  createAdmissionIpVital: `${BASE_PATH}${v1}/inpatients/vitals`,
  deleteAdmissionIpVital: `${BASE_PATH}${v1}/inpatients/vitals`,
  getAdmissionIpDischargeSummary: `${BASE_PATH}${v1}/inpatients/discharge-summary?ip_admission_id=`,
  createAdmissionIpDischargeSummary: `${BASE_PATH}${v1}/inpatients/discharge-summary`,
  updateAdmissionIpDischargeSummary: `${BASE_PATH}${v1}/inpatients/discharge-summary`,
  editAdmissionIpDischargeSummary: `${BASE_PATH}${v1}/inpatients/discharge-summary`,
  deleteAdmissionIpDischargeSummary: `${BASE_PATH}${v1}/inpatients/discharge-summary`,
  getAdmissionIpInvestigations: `${BASE_PATH}${v1}/inpatients/investigation?ip_admission_id=`,
  createAdmissionIpInvestigation: `${BASE_PATH}${v1}/inpatients/investigation`,
  deleteAdmissionIpInvestigation: `${BASE_PATH}${v1}/inpatients/investigation`,
  getAdmissionIpTreatments: `${BASE_PATH}${v1}/inpatients/treatment?ip_admission_id=`,
  createAdmissionIpTreatment: `${BASE_PATH}${v1}/inpatients/treatment`,
  deleteAdmissionIpTreatment: `${BASE_PATH}${v1}/inpatients/treatment`,
  getAdmissionIpPrescriptions: `${BASE_PATH}${v1}/inpatients/prescription?ip_admission_id=`,
  createAdmissionIpPrescription: `${BASE_PATH}${v1}/inpatients/prescription`,
  deleteAdmissionIpPrescription: `${BASE_PATH}${v1}/inpatients/prescription`,
  getPrintNotesIpTypes: `${BASE_PATH}${v1}/print-notes?search=IP`,
  getRoomsList: `${BASE_PATH}${v1}/room-master`,
  getBillingItemsList: `${BASE_PATH}${v1}/billing/items/lab-test-items`,
  getTreatmentItemsList: `${BASE_PATH}${v1}/billing/items`,
  getPxProductsList: `${BASE_PATH}${v1}/px-products/get-pxproducts`,
  getAllUnitsList: `${BASE_PATH}${v1}/procurements/units?active=1`,
  productsList: `${BASE_PATH}${v1}/procurements/products`,
  updateSequenceNumber: `${BASE_PATH}${v1}/sequence`,
  getSequenceNumber: `${BASE_PATH}${v1}/sequence`,
  getAllIpStatus: `${BASE_PATH}${v1}/inpatients/status`,
  createIpRoomTransfer: `${BASE_PATH}${v1}/inpatients/admissions/room-transfer`,
  updateIpRoomTransfer: `${BASE_PATH}${v1}/inpatients/admissions/room-transfer-history`,
  deleteIpAdmission: `${BASE_PATH}${v1}/inpatients/admissions`,
  getAdmissionIpOts: `${BASE_PATH}${v1}/inpatients/surgery?ip_admission_id=`,
  getAllDepartmentUrl: `${BASE_PATH}${v1}/doctors/departments`,
  getAllPositionUrl: `${BASE_PATH}${v1}/inpatients/position-master`,
  getAllSurgeryUrl: `${BASE_PATH}${v1}/inpatients/surgery/items`,
  createOperationTheatreUrl: `${BASE_PATH}${v1}/inpatients/surgery`,
  editOperationTheatreUrl: `${BASE_PATH}${v1}/inpatients/surgery`,
  updateOperationTheatreUrl: `${BASE_PATH}${v1}/inpatients/surgery`,
  deleteOperationTheatreUrl: `${BASE_PATH}${v1}/inpatients/surgery`,
  createOtJobUrl: `${BASE_PATH}${v1}/inpatients/surgery/ot-jobs`,
  editOtJobUrl: `${BASE_PATH}${v1}/inpatients/surgery/ot-jobs`,
  getAllDoctorsName: `${BASE_PATH}${v1}/doctors`,
  getDoctorVitalsDetails: `${BASE_PATH}${v1}/vitals?doctor_id=`,
};

export const appointmentGeneralUrl = {
  dosageFormList: `${BASE_PATH}${v1}/procurements/dosage-forms/all-dossage-forms`,
  doctorsList: `${BASE_PATH}${v1}/doctors`,
};

export const laboratoryApiEndPoints = {
  laboratoryReports: `${BASE_PATH}${v1}/laboratory/reports`,
  labResults: `${BASE_PATH}${v1}/billing/lab-result/all-lab-result`,
  laboratoryProducts: `${BASE_PATH}${v1}/laboratory/reports/products`,
  getPurchaseReturnProduct: `${BASE_PATH}${v1}/laboratory/purchase-return/products`,
  labPurchaseReturn: `${BASE_PATH}${v1}/laboratory/purchase-return`,
  getAllLabSuppliers: `${BASE_PATH}${v1}/laboratory/suppliers`,
  getGoodsIssueProduct: `${BASE_PATH}${v1}/laboratory/goods-issue/products`,
  getGoodsIssueById: `${BASE_PATH}${v1}/laboratory/goods-issue`,
  goodsIssueUrl: `${BASE_PATH}${v1}/laboratory/goods-issue`,
  goodsIssueReturnUrl: `${BASE_PATH}${v1}/laboratory/goods-issue-return`,
  getGoodsIssueReturnProduct: `${BASE_PATH}${v1}/laboratory/goods-issue-return/products`,
  stockAdjustmentUrl: `${BASE_PATH}${v1}/laboratory/stock-adjustments`,
  getAllStockAdjustmentsList: `${BASE_PATH}${v1}/laboratory/stock-adjustments`,
  stockAdjustmentById: `${BASE_PATH}${v1}/laboratory/stock-adjustments/products`,
  getAllPurchasePaymentsList: `${BASE_PATH}${v1}/laboratory/purchase-payments`,
  getPurchasePayment: `${BASE_PATH}${v1}/laboratory/purchase-payments`,
  addPurchasePayment: `${BASE_PATH}${v1}/laboratory/purchase-advance-payments`,
  getPurchasePaymentBill: `${BASE_PATH}${v1}/laboratory/purchase-payments/payment-bills`,
  getAllMasterDepartments: `${BASE_PATH}${v1}/laboratory/departments`,
  labSupplierUrl: `${BASE_PATH}${v1}/laboratory/suppliers`,
  labPurchaseBillUrl: `${BASE_PATH}${v1}/laboratory/purchase`,
  getAllDepartments: `${BASE_PATH}${v1}/laboratory/departments`,
  laboratoryPurchaseOrderUrl: `${BASE_PATH}${v1}/laboratory/purchase-orders`,
  LaboratoryProducts: `${BASE_PATH}${v1}/laboratory/products`,
  laboratoryTaxUrl: `${BASE_PATH}${v1}/laboratory/taxes`,
  labIndentsUrl: `${BASE_PATH}${v1}/laboratory/indents`,
  LabPurchaseOrderUrl: `${BASE_PATH}${v1}/laboratory/purchase-orders`,
  labSuppliersUrl: `${BASE_PATH}${v1}/laboratory/suppliers`,
};

export const labMasterEndPoints = {
  //Lab Master
  getLabProducts: `${BASE_PATH}${v1}/laboratory/products`,
  getSuppliers: `${BASE_PATH}${v1}/laboratory/suppliers`,
  getAllTaxDetails: `${BASE_PATH}${v1}/laboratory/taxes`,
  updateTaxesDetails: `${BASE_PATH}${v1}/laboratory/taxes`,
  getTestProducts: `${BASE_PATH}${v1}/laboratory/test-products`,
  getAllMasterTestProducts: `${BASE_PATH}${v1}/laboratory/test-products/lab-tests `,
  getAllMasterProductLists: `${BASE_PATH}${v1}/laboratory/products`,
};

export const procurementsMasterApiEndPoints = {
  sequenceUpdateUrl: `${BASE_PATH}${v1}/sequence`,
  getAllTaxDetails: `${BASE_PATH}${v1}/procurements/taxes`,
  updateTaxesDetails: `${BASE_PATH}${v1}/procurements/taxes`,
  getAllDepartmentsDetails: `${BASE_PATH}${v1}/procurements/departments`,
  createDepartmentDetails: `${BASE_PATH}${v1}/procurements/departments`,
  deleteDepartmentDetails: `${BASE_PATH}${v1}/procurements/departments`,
  updateDepartmentsDetails: `${BASE_PATH}${v1}/procurements/departments`,
  editDepartmentsDetails: `${BASE_PATH}${v1}/procurements/departments`,
  getAllManufacturesDetails: `${BASE_PATH}${v1}/procurements/manufacture`,
  createManufacturesDetails: `${BASE_PATH}${v1}/procurements/manufacture`,
  deleteManufacturesDetails: `${BASE_PATH}${v1}/procurements/manufacture`,
  updateManufacturesDetails: `${BASE_PATH}${v1}/procurements/manufacture`,
  editManufacturesDetails: `${BASE_PATH}${v1}/procurements/manufacture`,
  getAllMajorDrugsDetails: `${BASE_PATH}${v1}/procurements/major-drugs`,
  createMajorDrugsDetails: `${BASE_PATH}${v1}/procurements/major-drugs`,
  deleteMajorDrugsDetails: `${BASE_PATH}${v1}/procurements/major-drugs`,
  updateMajorDrugsDetails: `${BASE_PATH}${v1}/procurements/major-drugs`,
  editMajorDrugsDetails: `${BASE_PATH}${v1}/procurements/major-drugs`,
  getAllDosageFormsDetails: `${BASE_PATH}${v1}/procurements/dosage-forms`,
  createDosageFormDetails: `${BASE_PATH}${v1}/procurements/dosage-forms`,
  deleteDosageFormDetails: `${BASE_PATH}${v1}/procurements/dosage-forms`,
  updateDosageFormDetails: `${BASE_PATH}${v1}/procurements/dosage-forms`,
  editDosageFormDetails: `${BASE_PATH}${v1}/procurements/dosage-forms`,
  getAllUnitsDetails: `${BASE_PATH}${v1}/procurements/units`,
  createUnitsDetails: `${BASE_PATH}${v1}/procurements/units`,
  deleteUnitsDetails: `${BASE_PATH}${v1}/procurements/units`,
  updateUnitsDetails: `${BASE_PATH}${v1}/procurements/units`,
  editUnitsDetails: `${BASE_PATH}${v1}/procurements/units`,
  getAllDrugClassificationDetails: `${BASE_PATH}${v1}/procurements/drug-classification`,
  createDrugClassificationDetails: `${BASE_PATH}${v1}/procurements/drug-classification`,
  deleteDrugClassificationDetails: `${BASE_PATH}${v1}/procurements/drug-classification`,
  updateDrugClassificationDetails: `${BASE_PATH}${v1}/procurements/drug-classification`,
  editDrugClassificationDetails: `${BASE_PATH}${v1}/procurements/drug-classification`,
  getAllDrugClassificationList: `${BASE_PATH}${v1}/procurements/drug-classification`,
  getDrugClassificationById: `${BASE_PATH}${v1}/procurements/drug-classification`,
  editDrugClassification: `${BASE_PATH}${v1}/procurements/drug-classification`,
  deleteDrugClassification: `${BASE_PATH}${v1}/procurements/drug-classification`,
  addDrugClassification: `${BASE_PATH}${v1}/procurements/drug-classification`,
  getAllMasterDrugClassification: `${BASE_PATH}${v1}/procurements/drug-classification`,
  getAllProductList: `${BASE_PATH}${v1}/procurements/products`,
  deleteProductById: `${BASE_PATH}${v1}/procurements/products`,
  getProductById: `${BASE_PATH}${v1}/procurements/products`,
  //Master ApiEndPoints
  getAllMasterMajorDrug: `${BASE_PATH}${v1}/procurements/major-drugs`,
  getAllMasterDosage: `${BASE_PATH}${v1}/procurements/dosage-forms/dosage-forms`,
  getAllMasterUnits: `${BASE_PATH}${v1}/procurements/units/units`,
  getAllMasterManufacture: `${BASE_PATH}${v1}/procurements/manufacture/manufacture`,
  getAllMasterTax: `${BASE_PATH}${v1}/procurements/taxes/all-taxes`,
  addMajorDrugs: `${BASE_PATH}${v1}/procurements/major-drugs`,
  addDosageForm: `${BASE_PATH}${v1}/procurements/dosage-forms`,
  addManufacture: `${BASE_PATH}${v1}/procurements/manufacture`,
  addUnits: `${BASE_PATH}${v1}/procurements/units`,
  addProducts: `${BASE_PATH}${v1}/procurements/products`,
  addExistPackType: `${BASE_PATH}${v1}/procurements/products`,
  updateProductById: `${BASE_PATH}${v1}/procurements/products`,
  addSupplier: `${BASE_PATH}${v1}/procurements/suppliers`,
  getAllSupplierList: `${BASE_PATH}${v1}/procurements/suppliers`,
  customerMasterUrl: `${BASE_PATH}${v1}/procurements/customer`,
  getAllCustomerList: `${BASE_PATH}${v1}/procurements/customer`,
  getAllDrugClassificationUrl: `${BASE_PATH}${v1}/procurements/drug-classification/all-drug-classification`,
  getAllManufactureUrl: `${BASE_PATH}${v1}/procurements/manufacture/all-manufacture`,
  getAllMajorDrugsUrl: `${BASE_PATH}${v1}/procurements/major-drugs/all-major-drugs`,
  getAllDosageFormsUrl: `${BASE_PATH}${v1}/procurements/dosage-forms/all-dosage-forms`,
  getAllUnitsUrl: `${BASE_PATH}${v1}/procurements/units/all-units`,
};

export const pharmacyApiEndPoints = {
  getLatestIP: `${BASE_PATH}${v1}/inpatients/admissions/all-ip-no`,
  getLatestOp: `${BASE_PATH}${v1}/appointments/all-op-no`,
  payeeUrl: `${BASE_PATH}${v1}/payee/all-payee`,
  referralDoctorUrl: `${BASE_PATH}${v1}/refer-doctor`,
  getCommonVariablesUrl: `${BASE_PATH}${v1}/common-variables`,
  getConsultantsListUrl: `${BASE_PATH}${v1}/doctors`,
  getPharmacyProductsList: `${BASE_PATH}${v1}/pharmacy/products`,
  pharmacyUrl: `${BASE_PATH}${v1}/pharmacy`,
  billDraftList: `${BASE_PATH}${v1}/pharmacy/bill-draft`,
  pharmacyReturnProducts: `${BASE_PATH}${v1}/pharmacy-return/products`,
  pharmacyReturnUrl: `${BASE_PATH}${v1}/pharmacy-return`,
  advanceAndExcessAmountUrl: `${BASE_PATH}${v1}/payments/fetch-advance-excess`,
  stockProductUrl: `${BASE_PATH}${v1}/pharmacy/check-product-stock`,
  getPatientBillNo: `${BASE_PATH}${v1}/pharmacy-return/sales-bills`,
  pharmacyReturnBillDetails: `${BASE_PATH}${v1}/pharmacy-return/bill-details`,
};

export const patientHistoryEndPoints = {
  getPatientHistory: `${BASE_PATH}${v1}/patients/history`,
  getPatientRecentVisits: `${BASE_PATH}${v1}/patients/history/recent-visits`,
  getPatientInvestigations: `${BASE_PATH}${v1}/patients/history/investigations`,
  getPatientRadiology: `${BASE_PATH}${v1}/patients/history/radiology`,
  getPatientMedicalRecords: `${BASE_PATH}${v1}/patients/history/medical-records`,
  getPatientPrescriptions: `${BASE_PATH}${v1}/patients/history/prescriptions`,
  getPatientVitals: `${BASE_PATH}${v1}/patients/history/vitals`,
  getPatientConsultants: `${BASE_PATH}${v1}/patients/history/doctors`,
};
