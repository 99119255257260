import { useState } from "react";
import DatePicker from "react-multi-date-picker";
import {
  DatePickerLeftArrow,
  DatePickerRightArrow,
} from "../../../assets/icons";
import { FormControl, FormHelperText, Grid, InputLabel } from "@mui/material";
import "./DatePicker.css";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { DateObject } from "react-multi-date-picker";

type DatePickerProps = {
  className?: string;
  width?: string;
  height?: string;
  name?: string; // New prop
  placeholder?: any | undefined; // New prop
  value?: any; // New prop
  onChange?: any;
  onBlur?: any;
  onClose?: any;
  helperText?: string;
  label?: string;
  style?: {};
  error?: boolean; // New prop
  format?: string; // New prop
  borderColor?: string;
  inputRef?: any;
  onFocusedDateChange?: any;
  focused?: boolean;
  enabledDates?: {} | any;
  formControlStyle?: {} | any;
  minDate?: any;
  maxDate?: any;
  labelSx?: any;
  disabled?: boolean;
};
function CustomArrowButton(
  direction: string,
  handleClick: any,
  disabled: boolean
) {
  return (
    <i
      onClick={handleClick}
      style={{
        width: "",
        padding: "0 10px",
        fontWeight: "bold",
        color: disabled ? "gray" : "blue",
      }}
      className={disabled ? "cursor-default" : "cursor-pointer"}
    >
      {direction === "right" ? (
        <DatePickerRightArrow style={{ cursor: "pointer" }} />
      ) : (
        <DatePickerLeftArrow style={{ cursor: "pointer" }} />
      )}
    </i>
  );
}
const ReactDatePicker = ({
  className,
  width,
  height = "40px",
  name,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  style,
  label,
  borderColor = "var( --grey-lighter)",
  format = "DD/MM/YYYY",
  onBlur,
  inputRef,
  onFocusedDateChange,
  focused,
  enabledDates,
  formControlStyle,
  minDate,
  maxDate,
  labelSx,
  disabled,
  onClose,
}: DatePickerProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleClose = () => {
    setIsFocused(false);
    if (onClose) {
      onClose(value);
    }
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Tab") {
      handleClose();
    }
  };
  const isDateEnabled = (date: Date | DateObject) => {
    if (enabledDates.length === 0) {
      return true;
    }
    const dateString =
      date instanceof Date
        ? date.toISOString().split("T")[0]
        : date.format("YYYY-MM-DD");
    return enabledDates.includes(dateString);
  };

  return (
    <Grid
      sx={{
        "& .datepicker .rmdp-wrapper": {
          border: "1px solid var( --grey-lighter)",
          display: isFocused ? "flex" : "none",
        },
      }}
    >
      {label && (
        <InputLabel
          focused={isFocused || focused}
          sx={{
            fontSize: "12px",
            color: isFocused ? "primary.main" : "greyScale.main",
            marginBottom: "4px",
            ...labelSx,
          }}
        >
          {label}
        </InputLabel>
      )}
      <FormControl className="datepicker" sx={{ ...formControlStyle }}>
        <DatePicker
          placeholder={placeholder}
          showOtherDays
          format={format}
          arrow={false}
          renderButton={CustomArrowButton}
          ref={inputRef}
          onFocusedDateChange={onFocusedDateChange}
          highlightToday={false}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          render={
            <InputIcon
              // placeholder={placeholder}
              onBlur={onBlur}
              onKeyDown={handleKeyDown}
              style={{
                boxShadow: "var(--input-boxShadow)",
                border: error
                  ? "1px solid #d32f2f"
                  : isFocused
                  ? "2px solid var(--primary-main)"
                  : `1px solid ${borderColor}`,
                width: width,
                height: height,
                borderRadius: "8px",
                padding: isFocused ? "2px 9px" : "3px 10px",
                fontFamily: "Inter, sans-serif",
                fontSize: "12px",
                ...style,
              }}
            />
          }
          style={{}}
          value={value}
          onChange={onChange}
          className="datepicker"
          onOpen={handleFocus}
          onClose={handleClose}
          mapDays={({ date }) => {
            if (enabledDates) {
              const isEnabled = isDateEnabled(date);
              return {
                disabled: !isEnabled,
                style: {
                  cursor: isEnabled ? "pointer" : "not-allowed",
                  opacity: isEnabled ? 1 : 0.5,
                },
              };
            }
          }}
        />
      </FormControl>
      {helperText !== undefined && (
        <FormHelperText
          error={error}
          sx={{ ml: 2, minHeight: "20px", color: "#d32f2f" }}
          onBlur={onBlur}
        >
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
};
export default ReactDatePicker;
