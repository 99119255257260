import { Outlet, Navigate } from 'react-router-dom';

type PublicRoutesTypes = {
  isLoggedIn?: boolean;
};

const PublicRoutes = ({ isLoggedIn }: PublicRoutesTypes) => {
  return !isLoggedIn ? <Outlet /> : <Navigate to='/dashboard' />;
};

export default PublicRoutes;
