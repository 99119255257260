import {
  Badge,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  Button,
  CancelButton,
  DatePickerWithTime,
  PageLoader,
  Radio,
  SaveButton,
  Select,
  TimePicker,
} from "../../../../../components/basic";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../../utils/ValidationUtils";
import { DeleteIcon, BabyIcon } from "../../../../../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { DialogWrapper } from "../../../../../components/shared";
import dayjs from "dayjs";
import moment from "moment";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../../redux/slices/snackbar";
import { ipAdmissionConst } from "../../../../../constants/displayText";
import {
  createOTJob,
  editOtJobById,
} from "../../../../../services/admissionService";
import {
  timeFormat,
  convertTimeToDate,
  convertDateToTime,
} from "../../../../../utils/DateTimeFormatUtils";
import { RouteUrls } from "../../../../../constants/routes";

const OtJob = () => {
  const initialOtJobFormError = {
    patientIn: "",
    patientOut: "",
    videoRecord: "",
    implantUsed: "",
    incisionStart: "",
    incisionEnd: "",
    patientCondition: "",
    skinPreparation: "",
    executedProcedure: "",
    findings: "",
    babyDetails: [
      {
        id: "",
        baby: "",
        babyOutDateAndTime: "",
        babyCondition: "",
      },
    ],
  };
  const [otJobFormError, setOtJobFormError] = useState<any>(
    initialOtJobFormError
  );

  const initialData = {
    patientIn: null,
    patientOut: null,
    videoRecord: 0,
    implantUsed: 0,
    incisionStart: null,
    incisionEnd: null,
    patientCondition: null,
    skinPreparation: "",
    executedProcedure: "",
    findings: "",
    babyCount: 0,
    babyDetails: [
      {
        id: Date.now(),
        baby: null,
        babyOutDateAndTime: null,
        babyCondition: null,
        isBabyTooltipOpen: false,
        isOutDateTooltipOpen: false,
        isPatientConditionTooltipOpen: false,
      },
    ],
  };

  const [initialState, setInitialState] = useState<any>(initialData);

  const [otJobFormData, setOtJobFormData] = useState<string | Date | any>(
    initialState
  );
  console.log("otJobFormData", otJobFormData);
  const fieldData: any = {
    patientIn: {
      label: "Patient In",
      name: "patientIn", // Include the name field
      value: otJobFormData.patientIn,
      isError: Boolean(otJobFormError.patientIn),
      helperText: otJobFormError.patientIn,
    },
    patientOut: {
      label: "Patient Out",
      name: "patientOut",
      value: otJobFormData.patientOut,
      isError: Boolean(otJobFormError.patientOut),
      helperText: otJobFormError.patientOut,
    },

    videoRecord: {
      label: "Video Record",
      name: "videoRecord",
      value: otJobFormData.videoRecord,
      isError: Boolean(otJobFormError.videoRecord),
      helperText: otJobFormError.videoRecord,
    },

    implantUsed: {
      label: "Implant Used",
      name: "implantUsed",
      value: otJobFormData.implantUsed,
      isError: Boolean(otJobFormError.implantUsed),
      helperText: otJobFormError.implantUsed,
    },
    incisionStart: {
      label: "Incision Start",
      name: "incisionStart",
      value: otJobFormData.incisionStart,
      isError: Boolean(otJobFormError.incisionStart),
      helperText: otJobFormError.incisionStart,
    },
    incisionEnd: {
      label: "Incision End",
      name: "incisionEnd",
      value: otJobFormData.incisionEnd,
      isError: Boolean(otJobFormError.incisionEnd),
      helperText: otJobFormError.incisionEnd,
    },
    patientCondition: {
      label: "Patient Condition",
      name: "patientCondition",
      value: otJobFormData.patientCondition,
      isError: Boolean(otJobFormError.patientCondition),
      helperText: otJobFormError.patientCondition,
    },
    skinPreparation: {
      label: "Skin Preparation",
      name: "skinPreparation",
      value: otJobFormData.skinPreparation,
      isError: Boolean(otJobFormError.skinPreparation),
      helperText: otJobFormError.skinPreparation,
    },
    executedProcedure: {
      label: "Executed Procedure",
      name: "executedProcedure",
      value: otJobFormData.executedProcedure,
      isError: Boolean(otJobFormError.executedProcedure),
      helperText: otJobFormError.executedProcedure,
    },
    findings: {
      label: "Findings",
      name: "findings",
      value: otJobFormData.findings,
      isError: Boolean(otJobFormError.findings),
      helperText: otJobFormError.findings,
    },
    babyDetails: {
      label: "Baby Details",
      name: "babyDetails",
      value: otJobFormData?.babyDetails,
      isError: Boolean(otJobFormError.babyDetails),
      helperText: otJobFormError.babyDetails,
    },
    baby: {
      label: "Baby",
      name: "baby",
      value: otJobFormData?.babyDetails?.baby,
      isError: Boolean(otJobFormError.babyDetails.baby),
      helperText: otJobFormError.babyDetails.baby,
    },
    babyOutDateAndTime: {
      label: "Baby Out Date And Time",
      name: "babyOutDateAndTime",
      value: otJobFormData?.babyDetails?.babyOutDateAndTime,
      isError: Boolean(otJobFormError.babyDetails.babyOutDateAndTime),
      helperText: otJobFormError.babyDetails.babyOutDateAndTime,
    },
    babyCondition: {
      label: "Baby Condition",
      name: "babyCondition",
      value: otJobFormData?.babyDetails?.babyCondition,
      isError: Boolean(otJobFormError?.babyDetails?.babyCondition),
      helperText: otJobFormError.babyDetails.babyCondition,
    },

    babyCount: {
      name: "babyCount",
      value: otJobFormData.babyCount,
    },
  };

  const [otJobFieldData, setOtJobFieldData] = useState(fieldData);
  const { otJobId, admissionId } = useParams();
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [isPageLoader, setIsPageLoader] = useState(false);

  const [patientConditionOptions, setPatientConditionOptions] = useState<any>(
    []
  );
  const [genderOptions, setGenderOptions] = useState<any>([]);
  const [yesOrNoOptions, setYesOrNoOptions] = useState<any>([]);

  const [isOpenBabyDetails, setIsOpenBabyDetails] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { commonVariables, appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const handleValidation = useCallback(
    (e: any) => {
      const { name, value, label } = e.target;

      switch (name) {
        case "patientCondition": {
          if (requiredValidator(value, label)) {
            updateFormDataWithHelperText(
              name,
              requiredValidator(value, label),
              setOtJobFormError
            );
          } else {
            updateFormDataWithHelperText(name, "", setOtJobFormError);
          }
          break;
        }

        default:
          break;
      }
    },
    [otJobFormError, otJobFormData]
  );

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateBookingFieldData = () => {
    setOtJobFieldData((prevFieldData: any) => {
      return Object?.keys(prevFieldData)?.map((field: any) => {
        return {
          ...field,
          helperText: otJobFormError[field.name],
          value: otJobFormData[field.name],
          isError: Boolean(otJobFormError[field.name]),
        };
      });
    });
  };

  useEffect(() => {
    updateBookingFieldData();
  }, [otJobFormError, otJobFormData]);

  const getCommonVariablesDetails = async () => {
    if (commonVariables) {
      setPatientConditionOptions(commonVariables?.patient_conditions);
      setGenderOptions(commonVariables?.gender);
      setYesOrNoOptions(commonVariables?.yes_no_options);
    }
  };

  useEffect(() => {
    getCommonVariablesDetails();
  }, [commonVariables, appConfiguration]);

  const handleInputChange = useCallback((e: any) => {
    const { value, name } = e.target;

    setOtJobFormData((prevData: any) => ({
      ...prevData,
      [name]: value, // Store the id of the selected option
    }));

    handleValidation(e);
  }, []);

  const handleTimeChange = (newValue: any, name: string) => {
    if (newValue !== null) {
      setOtJobFormData((prev: any) => ({
        ...prev,
        [name]: newValue?.toDate?.(), // Store the JavaScript Date object
      }));
    } else {
      setOtJobFormData((prev: any) => ({
        ...prev,
        [name]: null, // Store the JavaScript Date object
      }));
    }
  };

  const createOtJob = async () => {
    if (!Boolean(otJobFormData.patientCondition)) {
      validateForm();
    } else {
      try {
        setSaveBtnLoader(true);
        const filterBabyDetails = otJobFormData?.babyDetails?.filter(
          (item: any) =>
            item?.baby !== null &&
            item?.babyOutDateAndTime !== null &&
            item?.babyCondition !== null
        );

        const data: any = {
          ip_surgery_id: otJobId,
          patient_in_time: otJobFormData?.patientIn
            ? moment(
                convertDateToTime(otJobFormData?.patientIn),
                "hh:mm A"
              ).format("HH:mm")
            : null,
          patient_out_time: otJobFormData?.patientOut
            ? moment(
                convertDateToTime(otJobFormData?.patientOut),
                "hh:mm A"
              ).format("HH:mm")
            : null,

          incision_start_time: otJobFormData?.incisionStart
            ? moment(
                convertDateToTime(otJobFormData?.incisionStart),
                "hh:mm A"
              ).format("HH:mm")
            : null,

          incision_end_time: otJobFormData?.incisionEnd
            ? moment(
                convertDateToTime(otJobFormData.incisionEnd),
                "hh:mm A"
              ).format("HH:mm")
            : null,

          is_video_recorded: otJobFormData?.videoRecord,
          is_implant_used: otJobFormData?.implantUsed,
          patient_condition: otJobFormData?.patientCondition,
          skin_preparation: otJobFormData?.skinPreparation,
          executed_procedure: otJobFormData?.executedProcedure,
          findings: otJobFormData?.findings,
          baby_details: filterBabyDetails?.length
            ? filterBabyDetails?.map((item: any) => ({
                gender: item?.baby, // Assuming `baby` holds the gender value
                out_date: item?.babyOutDateAndTime
                  ? moment(
                      item?.babyOutDateAndTime,
                      "DD/MM/YYYY hh:mm:ss A"
                    ).format("YYYY-MM-DD HH:mm:ss")
                  : null, // Assuming `babyOutDateAndTime` holds the date and time
                condition: item?.babyCondition,
              }))
            : [],
        };

        await createOTJob(data)?.then((result: any) => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ipAdmissionConst.ADDED_SUCCESSFULLY as string,
            })
          );
          const ipId = result?.data?.ip_id;
          navigate(
            `${RouteUrls.inPatients}${RouteUrls.ipSummary}/${ipId}?type=ot`
          );
          setSaveBtnLoader(false);
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        const errorMessage = error?.response?.data?.errors
          ? error.response.data.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        setSaveBtnLoader(false);
      }
    }
  };

  const editOtJob = async () => {
    if (!otJobId) {
      return false;
    }
    try {
      setIsPageLoader(true);
      await editOtJobById(otJobId).then((result: any) => {
        const details = result?.data;

        if (details) {
          const babyDetailsArray = JSON?.parse(details?.baby_details);

          const newBabyDetails = babyDetailsArray?.map(
            (item: any, index: number) => ({
              id: Date.now() + index,
              baby: item?.gender,
              babyOutDateAndTime: item?.out_date
                ? moment(item?.out_date, "YYYY-MM-DD HH:mm:ss")?.format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : null,
              babyCondition: item?.condition ? item?.condition : null,
              isBabyTooltipOpen: false,
              isOutDateTooltipOpen: false,
              isPatientConditionTooltipOpen: false,
            })
          );

          const newDate = {
            patientIn:
              details?.patient_in_time !== null
                ? convertTimeToDate(timeFormat(details?.patient_in_time))
                : null,
            patientOut:
              details?.patient_out_time !== null
                ? convertTimeToDate(timeFormat(details?.patient_out_time))
                : null,
            videoRecord:
              details?.is_video_recorded !== null
                ? details?.is_video_recorded
                : initialData.videoRecord,
            implantUsed:
              details?.is_implant_used !== null
                ? details?.is_implant_used
                : initialData.implantUsed,
            incisionStart:
              details?.incision_start_time !== null
                ? convertTimeToDate(timeFormat(details?.incision_start_time))
                : null,
            incisionEnd:
              details?.incision_end_time !== null
                ? convertTimeToDate(timeFormat(details?.incision_end_time))
                : null,
            patientCondition: details?.patient_condition,
            skinPreparation: details?.skin_preparation,
            executedProcedure: details?.executed_procedure,
            findings: details?.findings,
            babyCount: 4,
            babyDetails: newBabyDetails,
          };

          setOtJobFormData(newDate);
          setInitialState(newDate);
        }

        setIsPageLoader(false);
      });
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      console.error("An error occurred:", error);
      setIsPageLoader(false);
    }
  };

  useEffect(() => {
    editOtJob();
  }, []);

  const handleDialogClose = () => {
    setIsOpenBabyDetails(false);
    setOtJobFormData((prev: any) => ({
      ...prev,
      babyDetails: initialState?.babyDetails,
    }));
  };
  const [validationErrors, setValidationErrors] = useState<any>({});

  const handleSelectChange = (e: any, id: number) => {
    const { name, value } = e.target;

    setOtJobFormData((prev: any) => ({
      ...prev,
      babyDetails: prev?.babyDetails?.map((item: any) => {
        if (item.id === id) {
          // Match the entry by unique id
          return {
            ...item,
            [name]: value, // Update the relevant field (in this case, departmentId)
          };
        }
        return item;
      }),
    }));
    if (validationErrors[id] && validationErrors[id][name]) {
      setValidationErrors((prev: any) => ({
        ...prev,
        [id]: {
          ...prev[id],
          [name]: "",
        },
      }));
    }
  };
  const handleDateAndTimeChange = (newValue: any, id: any) => {
    if (newValue) {
      const formattedDate = dayjs(newValue).format("DD/MM/YYYY hh:mm:ss A");
      setOtJobFormData((prev: any) => ({
        ...prev,
        babyDetails: prev?.babyDetails?.map((item: any) => {
          if (item.id === id) {
            return {
              ...item,
              babyOutDateAndTime: formattedDate,
            };
          }
          return item;
        }),
      }));
    } else {
      setOtJobFormData((prev: any) => ({
        ...prev,
        babyDetails: prev?.babyDetails?.map((item: any) => {
          if (item.id === id) {
            return {
              ...item,
              babyOutDateAndTime: null,
            };
          }
          return item;
        }),
      }));
    }
    if (validationErrors[id] && validationErrors[id]["babyOutDateAndTime"]) {
      setValidationErrors((prev: any) => ({
        ...prev,
        [id]: {
          ...prev[id],
          babyOutDateAndTime: "",
        },
      }));
    }
  };
  const handleRadioChange = (e: any, id: number) => {
    const { name, value } = e.target;

    setOtJobFormData((prev: any) => ({
      ...prev,
      babyDetails: prev?.babyDetails?.map((item: any) => {
        if (item.id === id) {
          // Match the entry by unique id
          return {
            ...item,
            babyCondition: value, // Update the relevant field (in this case, departmentId)
          };
        }
        return item;
      }),
    }));
    if (validationErrors[id] && validationErrors[id][name]) {
      setValidationErrors((prev: any) => ({
        ...prev,
        [id]: {
          ...prev[id],
          [name]: " ",
        },
      }));
    }
  };
  const handleAddBabyDetails = async () => {
    setOtJobFormData((prev: any) => ({
      ...prev,
      babyDetails: [
        ...prev.babyDetails,
        {
          id: Date.now() + prev.babyDetails.length + 2,
          baby: null,
          babyOutDateAndTime: null,
          babyCondition: null,
          isBabyTooltipOpen: false,
          isOutDateTooltipOpen: false,
          isPatientConditionTooltipOpen: false,
        },
      ],
    }));
  };
  const handleDeleteBabyDetails = async (id: number) => {
    setOtJobFormData((prev: any) => ({
      ...prev,
      babyDetails: prev?.babyDetails?.filter((item: any) => item.id !== id),
    }));
  };

  const editBabyDetails = async () => {
    setIsOpenBabyDetails(true);
    if (otJobFormData?.babyDetails?.length == 0) {
      setOtJobFormData((prevData: any) => ({
        ...prevData,
        babyDetails: initialData?.babyDetails,
      }));
    }
  };

  const handleSaveData = () => {
    const errors: any = {};

    otJobFormData.babyDetails.forEach((data: any, index: any) => {
      const error: any = {};

      if (!data.baby) {
        error.baby = "*Gender is required";
      }
      if (!data.babyOutDateAndTime) {
        error.babyOutDateAndTime = "*Date and time is required";
      }
      if (!data.babyCondition) {
        error.babyCondition = "*Condition is required";
      }

      if (Object.keys(error).length > 0) {
        errors[data.id] = error;
      }
    });

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return; // Stop the save process if there are validation errors
    } else {
      createBabyDetails();
    }
  };

  const createBabyDetails = async () => {
    setInitialState(otJobFormData);
    setIsOpenBabyDetails(false);
  };

  useEffect(() => {
    const validBabyDetails = otJobFormData?.babyDetails?.filter((data: any) => {
      return (
        data?.baby !== null &&
        data?.babyOutDateAndTime !== null &&
        data?.babyCondition !== null
      );
    });

    // Update the babyCount based on the length of the filtered array
    setOtJobFormData((prevData: any) => ({
      ...prevData,
      babyCount: validBabyDetails?.length,
    }));
  }, [otJobFormData?.babyDetails]);

  return isPageLoader ? (
    <PageLoader />
  ) : (
    <Grid sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F6FAFF",
            width: "100%",
            height: "37px",
            display: "flex",
            alignItems: "center",
            borderRadius: "6px",
            mb: 2,
          }}
        >
          <Typography variant="h5" color="primary.main" sx={{ ml: 2 }}>
            Surgery Details
          </Typography>
        </Box>
        <Grid sx={{ ml: "10px", width: { xs: "100%", xl: "1200px" } }}>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: {
                xs: 4,
                lg: 5,
              },
            }}
          >
            <TimePicker
              label={fieldData?.patientIn?.label}
              style={{
                height: "37px",
                borderRadius: "none",
                width: "340px",
              }}
              value={fieldData?.patientIn?.value}
              name={fieldData?.patientIn?.name}
              format="hh:mm A"
              placeholder="Select Patient In Time"
              onChange={(newValue: any) =>
                handleTimeChange(newValue, "patientIn")
              }
              helperText={fieldData?.patientIn.helperText}
            />
            <TimePicker
              label={fieldData?.patientOut?.label}
              style={{
                height: "37px",
                borderRadius: "none",
                width: "340px",
              }}
              value={fieldData?.patientOut?.value}
              name={fieldData?.patientOut?.name}
              format="hh:mm A"
              placeholder="Select Patient Out Time"
              onChange={(newValue: any) =>
                handleTimeChange(newValue, "patientOut")
              }
              helperText={fieldData?.patientOut.helperText}
            />
            <Grid sx={{ display: "flex", width: "360px", gap: 10 }}>
              <Radio
                label={fieldData.videoRecord.label}
                name={fieldData.videoRecord.name}
                value={fieldData.videoRecord.value}
                onChange={handleInputChange}
                options={yesOrNoOptions}
              />
              <Radio
                label={fieldData.implantUsed.label}
                name={fieldData.implantUsed.name}
                value={fieldData.implantUsed.value}
                onChange={handleInputChange}
                options={yesOrNoOptions}
              />
            </Grid>

            <TimePicker
              label={fieldData?.incisionStart?.label}
              style={{
                height: "37px",
                borderRadius: "none",
                width: "340px",
              }}
              value={fieldData?.incisionStart?.value}
              name={fieldData?.incisionStart?.name}
              format="hh:mm A"
              placeholder="Select Incision Start Time"
              onChange={(newValue: any) =>
                handleTimeChange(newValue, "incisionStart")
              }
              helperText={fieldData?.incisionStart.helperText}
            />
            <TimePicker
              label={fieldData?.incisionEnd?.label}
              style={{
                height: "37px",
                borderRadius: "none",
                width: "340px",
              }}
              value={fieldData?.incisionEnd?.value}
              name={fieldData?.incisionEnd?.name}
              format="hh:mm A"
              placeholder="Select Incision End Time"
              onChange={(newValue: any) =>
                handleTimeChange(newValue, "incisionEnd")
              }
              helperText={fieldData?.incisionEnd.helperText}
            />
            <Grid sx={{ display: "flex", width: "360px" }}>
              <Select
                value={fieldData?.patientCondition?.value}
                placeholder="Select Patient Condition"
                onChange={handleInputChange}
                options={patientConditionOptions}
                name={fieldData?.patientCondition?.name}
                label={fieldData?.patientCondition?.label}
                helperText={fieldData?.patientCondition?.helperText}
                sx={{
                  width: {
                    xs: "300px",
                  },
                  height: "46px",
                  mr: "15px",
                }}
              />
              <Box
                sx={{ mt: "22px", cursor: "pointer" }}
                onClick={() => editBabyDetails()}
              >
                <Badge badgeContent={fieldData.babyCount.value} color="primary">
                  <BabyIcon />
                </Badge>
              </Box>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="p" sx={{ color: "#8A8A8A" }}>
                Skin Preparation
              </Typography>
              <textarea
                style={{
                  width: "340px",
                  height: "120px",
                  borderRadius: "8px",
                  padding: "10px",
                  outline: "none",
                  border: "1px solid #EFEFEF",
                  marginTop: "5px",
                  fontFamily: "inter",
                  fontSize: "14px",
                }}
                placeholder="Enter Skin Preparation"
                name={fieldData?.skinPreparation?.name}
                value={fieldData?.skinPreparation?.value}
                onChange={handleInputChange}
                spellCheck="false"
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="p" sx={{ color: "#8A8A8A" }}>
                Executed Procedure
              </Typography>
              <textarea
                style={{
                  width: "340px",
                  height: "120px",
                  borderRadius: "8px",
                  padding: "10px",
                  outline: "none",
                  border: "1px solid #EFEFEF",
                  marginTop: "5px",
                  fontFamily: "inter",
                  fontSize: "14px",
                }}
                placeholder="Enter Executed Procedure"
                name={fieldData?.executedProcedure?.name}
                value={fieldData?.executedProcedure?.value}
                onChange={handleInputChange}
                spellCheck="false"
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="p" sx={{ color: "#8A8A8A" }}>
                Findings
              </Typography>
              <textarea
                style={{
                  width: "340px",
                  height: "120px",
                  borderRadius: "8px",
                  padding: "10px",
                  outline: "none",
                  border: "1px solid #EFEFEF",
                  marginTop: "5px",
                  fontFamily: "inter",
                  fontSize: "14px",
                }}
                placeholder="Enter Findings"
                name={fieldData?.findings?.name}
                value={fieldData?.findings?.value}
                onChange={handleInputChange}
                spellCheck="false"
              />
            </Box>
            <Grid
              item
              sx={{ display: "flex", width: "100%", gap: 3, mt: 5, mb: 2 }}
            >
              <SaveButton
                sx={{ width: "100px", height: "42px" }}
                handleClick={createOtJob}
                loading={saveBtnLoader}
              />
              <CancelButton sx={{ width: "100px", height: "42px" }} />
            </Grid>

            <DialogWrapper
              open={isOpenBabyDetails}
              handleClick={() => {
                handleSaveData();
              }}
              title={"Baby Details"}
              onClose={() => {
                handleDialogClose();
              }}
              maxWidth="md"
              loading={saveBtnLoader}
            >
              {isDialogLoading ? (
                <div
                  style={{
                    width: "100%",
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    justifyContent: "center",
                    height: "40vh",
                    background: "transparent",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <Grid>
                  {otJobFormData?.babyDetails?.map((data: any, index: any) => {
                    return (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Grid
                          key={index}
                          sx={{
                            width: "100%",
                            display: "flex",
                            height: "auto",
                            flexDirection: { xs: "row" },
                            alignItems: { xs: "center" },
                            gap: 4,
                            mb: 2,
                          }}
                        >
                          <Select
                            value={data.baby}
                            onChange={(e) => handleSelectChange(e, data.id)}
                            name="baby"
                            placeholder="Select Gender"
                            label={fieldData.baby.label}
                            options={genderOptions}
                            sx={{
                              width: {
                                xs: "220px",
                              },
                              zIndex: 1000,
                            }}
                            helperText={
                              validationErrors[data.id]?.baby
                                ? validationErrors[data.id]?.baby
                                : true
                            }
                          />

                          <Grid
                            sx={{
                              "& .MuiFormControl-root": {
                                position: "static",
                              },
                            }}
                          >
                            <DatePickerWithTime
                              label={fieldData?.babyOutDateAndTime?.label}
                              style={{
                                height: "37px",
                                borderRadius: "none",
                                width: "210px",
                              }}
                              value={data?.babyOutDateAndTime}
                              name={fieldData?.babyOutDateAndTime?.name}
                              format="DD/MM/YYYY hh:mm:ss A"
                              placeholder="Select Baby Out Date And Time"
                              onChange={(newValue: any) =>
                                handleDateAndTimeChange(newValue, data.id)
                              }
                              helperText={
                                validationErrors[data.id]?.babyOutDateAndTime
                                  ? validationErrors[data.id]
                                      ?.babyOutDateAndTime
                                  : true
                              }
                              hideSeconds={false}
                            />
                          </Grid>

                          <Grid sx={{ mt: "-5px" }}>
                            <Radio
                              label={fieldData.babyCondition.label}
                              name={fieldData.babyCondition.name}
                              value={data.babyCondition}
                              onChange={(e) => handleRadioChange(e, data.id)}
                              options={patientConditionOptions}
                            />

                            <Typography
                              variant="h6"
                              sx={{
                                ml: 2,
                                minHeight: "20px",
                                fontSize: "11px",
                                color: "#d32f2f",
                              }}
                            >
                              {validationErrors[data.id]?.babyCondition
                                ? validationErrors[data.id]?.babyCondition
                                : true}
                            </Typography>
                          </Grid>

                          <IconButton
                            sx={{
                              p: "8px",
                              mt: 3,
                            }}
                            onClick={() => handleDeleteBabyDetails(data.id)}
                          >
                            <DeleteIcon width="15px" />
                          </IconButton>
                        </Grid>
                      </Box>
                    );
                  })}
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      borderTop: 0,
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="text"
                      buttonText="+ Add"
                      handleClick={handleAddBabyDetails}
                      sx={{
                        borderRadius: "0px",
                        width: "auto",
                        height: "auto",
                        fontWeight: 400,
                        fontSize: "13px",
                        border: 0,
                        color: "primary.main",
                        "&:hover": {
                          backgroundColor: "initial",
                          color: "primary.main",
                          borderColor: "initial",
                        },
                        "&.Mui-focusVisible": {
                          border: 1,
                          borderColor: "primary.main",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </DialogWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OtJob;
