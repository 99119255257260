import React from "react";
import BillProducts from "./BillProducts";

const CreateGoodsIssue = () => {
  return (
    <>
      <BillProducts />
    </>
  );
};

export default CreateGoodsIssue;
