import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box, Button, Grid, Typography } from "@mui/material";
import { TextField } from "..";
import { DeleteIcon, PlusIcon } from "../../../assets/icons";

type SwitchProps = {
  Details?: boolean;
  label?: string;
  sx?: {};
  value?: boolean;
  name?: string;
  onChange?: (event: React.ChangeEvent) => void;
  isDisabled?: boolean;
};

const label = { inputProps: { "aria-label": "Switch demo" } };

const MuiSwitch = ({
  Details = true,
  label,
  sx,
  value = false,
  name,
  onChange,
  isDisabled,
}: SwitchProps) => {
  const [switchChecked, setSwitchChecked] = useState(value);
  const [fieldKeys, setFieldKeys] = useState<string[]>([]);

  const handleEditClick = () => {
    const newKey = `field-${fieldKeys.length}`;
    setFieldKeys([...fieldKeys, newKey]);
  };

  const handleDeleteClick = (key: string) => {
    setFieldKeys(fieldKeys.filter((fieldKey) => fieldKey !== key));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchChecked(event.target.checked);
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    setSwitchChecked(value);
  }, [value]);

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      <FormControlLabel
        control={
          <Switch
            name={name}
            checked={switchChecked}
            onChange={handleChange}
            {...{ label }}
            sx={{
              m: "0 10px",
              fontSize: "14px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            disabled={isDisabled}
          />
        }
        label={label}
        labelPlacement="start"
        sx={{
          "& .MuiTypography-root": {
            fontSize: "15px",
            fontWeight: 600,
            ...sx,
          },
        }}
      />
      {Details && (
        <>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "14px",
                m: "8px 15px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {switchChecked ? "Open" : "Closed"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", ml: 4 }}>
            {switchChecked && (
              <Grid
                className="main-box"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Box
                  className="small-box"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    variant="standard"
                    sx={{
                      width: "75px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                  <Typography>To</Typography>
                  <TextField variant="standard" sx={{ width: "75px" }} />
                  <TextField
                    variant="standard"
                    sx={{ width: "75px" }}
                    placeholder="Interval"
                  />

                  <Button onClick={handleEditClick} sx={{ height: "40px" }}>
                    <PlusIcon />
                  </Button>
                </Box>
                {fieldKeys.map((fieldKey) => (
                  <Grid
                    key={fieldKey}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 4,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <TextField variant="standard" sx={{ width: "75px" }} />

                    <Typography>To</Typography>
                    <TextField variant="standard" sx={{ width: "75px" }} />
                    <TextField
                      variant="standard"
                      sx={{ width: "75px" }}
                      placeholder="Interval"
                    />
                    <Button
                      onClick={(event) => handleDeleteClick(fieldKey)}
                      sx={{ height: "40px" }}
                    >
                      <DeleteIcon />
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </>
      )}
    </Grid>
  );
};

export default MuiSwitch;
