import {
  Box,
  CircularProgress,
  debounce,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  CheckBox,
  DatePicker,
  SaveButton,
  Select,
  TextField,
} from "../../../../components/basic";
import { useCallback, useEffect, useState } from "react";
import {
  getAllIndentProducts,
  updateIndentProductStatus,
} from "../../../../services/procurementService";
import { purchaseProductConst } from "../../../../constants/displayText";
import ProductRow from "./ProductRow";
import { getAllMasterDepartments } from "../../../../services/gynecologyService";
import ConfirmPoModal from "./ConfirmPoModal";
import { ConfirmationDialog } from "../../../../components/shared";
import { setSnackBarSuccess } from "../../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { RootState } from "../../../../redux/store";
import { IssuableIcon, PurchaseOrderIcon } from "../../../../assets/icons";

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right" | "center";
  sx?: {};
  hasCheckBox?: boolean;
};

const styles = {
  checkBoxLabelStyle: {
    fontSize: "15px",
    fontWeight: 400,
  },
  tableContainerStyle: {
    borderRadius: "5px",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: "1200px",
  },

  tableStyle: {
    borderRadius: "5px",
    border: "1px solid",
    borderColor: "var(--table-border)",
    "& .MuiTableHead-root": { border: 0, borderRadius: "unset" },
    "& .MuiTableRow-head": {
      backgroundColor: "var(--table-header)",
      borderRadius: "unset",
    },
    " & .MuiTableBody-root": {
      border: 0,
    },
    "& .MuiTableRow-root": {
      height: "34px !important",
      maxHeight: "34px !important",
      minHeight: "0px",
      lineHeight: "0px",
      border: 0,
    },
    "& .MuiTableCell-root": {
      minHeight: "0px",
    },
    "& .MuiTableCell-head": {
      height: "43px",
      maxHeight: "43px",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 10px",
      fontSize: "13px",
      fontWeight: "600",
      borderBottom: 0,
      borderRight: 1,
      borderRadius: "5px",
      borderColor: "var(--table-border)",
    },
    "& .MuiTableCell-body": {
      height: "32px !important",
      maxHeight: "32px !important",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 0px",
      fontSize: "12px",
      fontWeight: "400",
      border: 1,
      borderColor: "var(--table-border)",
    },
    "& .MuiTableCell-body:has(.Mui-focused)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },
    "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "30px",
    },
    "& .deleteIconGrid": {
      cursor: "pointer",
      position: "absolute",

      bottom: "5px",
    },
  },
  textFieldStyle: {
    height: "34px",
    width: "auto",
    fontSize: "6px",

    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-root": {
      height: "34px",
      borderRadius: "0px",
      paddingLeft: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      borderColor: "primary.main",
    },
    "& .MuiInputBase-input": {
      padding: "0px 10px",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "right",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
      height: "32px",
      maxHeight: "32px",
      top: 0,
    },

    "& input::placeholder": {
      fontSize: "12px",
    },
  },
};
const BillProducts = () => {
  const {
    indentNo,
    productName,
    department,
    requirement,
    supplier,
    quantity,
    status,
    stockQty,
  } = purchaseProductConst;
  const dispatch = useDispatch();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [indents, setIndents] = useState<any[]>([]);
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [allProductsIds, setAllProductsIds] = useState<any[]>([]);
  const [filterData, setFilterData] = useState({
    isIssuable: false,
    isPurchaseOrder: true,
    from_department: "",
    search: "",
    from_date: new Date(),
    to_date: new Date(),
  });
  const [filteredIndents, setFilteredIndents] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSupplierProducts, setSelectedSupplierProducts] = useState([]);
  const [selectedDepartmentProducts, setSelectDepartmentProducts] = useState(
    []
  );
  const [isOpenConfirmPoModal, setIsOpenConfirmPoModal] =
    useState<boolean>(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<{
    isOpen: boolean;
    data: {
      status: string | number;
      indent_id: string | number;
      indent_product_id: string | number;
    } | null;
  }>({
    isOpen: false,
    data: null,
  });
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(false);
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [issuerDepartmentOptions, setIssuerDepartmentOptions] = useState<any[]>(
    []
  );
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<{
    isOpen: boolean;
    content: string;
  }>({
    isOpen: false,
    content: "",
  });
  const [batches, setBatches] = useState<any>({});

  const TableHeaderCells: TableHeaderCell[] = [
    {
      TableCellName: indentNo,
      alignment: "center",
      sx: {
        width: "12%",
        minWidth: "140px",
      },
      hasCheckBox: true,
    },
    {
      TableCellName: productName,
      alignment: "center",
      sx: {
        width: "20%",
        minWidth: "240px",
      },
    },
    {
      TableCellName: requirement,
      alignment: "center",
      sx: {
        width: "10%",
        minWidth: "150px",
      },
    },
    {
      TableCellName: department,
      alignment: "center",
      sx: {
        width: "13%",
        minWidth: "150px",
      },
    },
    {
      TableCellName: filterData.isPurchaseOrder ? supplier : stockQty,
      alignment: "center",
      sx: {
        width: "20%",
        minWidth: "240px",
      },
    },
    {
      TableCellName: quantity,
      alignment: "center",
      sx: {
        width: "10%",
        minWidth: "150px",
      },
    },
    {
      TableCellName: status,
      alignment: "center",
      sx: {
        width: "15%",
        minWidth: "180px",
      },
    },
  ];

  const handleCheckAll = () => {
    setSelectedProducts((prevState: any) => {
      if (prevState?.length === allProductsIds?.length) {
        return [];
      }
      return allProductsIds;
    });
  };

  const handleCheck = useCallback((row: any) => {
    setSelectedProducts((prev: any) => {
      const index = prev.findIndex((ele: any) => ele === row.id);

      if (index === -1) {
        return [...prev, row.id];
      } else {
        const newState = [...prev];
        newState.splice(index, 1);
        return newState;
      }
    });
  }, []);

  const handleChange = useCallback((event: any, id?: number | string) => {
    const { name, value } = event.target;

    setIndents((prev: any) =>
      prev?.map((row: any) => {
        if (row.id === id) {
          const newRow = { ...row };
          if (
            name === "indent" &&
            newRow?.batch &&
            Number(value) > Number(newRow?.batch?.stock_qty)
          ) {
            newRow.error = { ...newRow?.error, indent: true };
          } else {
            newRow.error = { ...newRow?.error, indent: false };
          }

          return { ...newRow, [name]: value };
        }
        return row;
      })
    );
    setFilteredIndents((prev: any) =>
      prev?.map((row: any) => {
        if (row.id === id) {
          const newRow = { ...row };
          if (
            name === "indent" &&
            newRow?.batch &&
            Number(value) > Number(newRow?.batch?.stock_qty)
          ) {
            newRow.error = { ...newRow?.error, indent: true };
          } else {
            newRow.error = { ...newRow?.error, indent: false };
          }

          return { ...newRow, [name]: value };
        }
        return row;
      })
    );
  }, []);

  const handleAutoCompleteChange = useCallback(
    (event: any, newValue: any, id: number | string, name: string) => {
      setIndents((prev: any) =>
        prev.map((row: any) => {
          if (id === row.id) {
            const newRow = { ...row };

            if (
              name === "batch" &&
              newRow?.indent &&
              Number(newValue?.stock_qty) < Number(newRow?.indent)
            ) {
              newRow.error = { ...newRow?.error, indent: true };
            } else if (name === "batch") {
              newRow.error = { ...newRow?.error, indent: false };
            }
            return {
              ...newRow,
              [name]: newValue,
            };
          }

          return row;
        })
      );
      setFilteredIndents((prev: any) =>
        prev.map((row: any) => {
          if (id === row.id) {
            const newRow = { ...row };
            if (
              name === "batch" &&
              newRow?.indent &&
              Number(newValue?.stock_qty) < Number(newRow?.indent)
            ) {
              newRow.error = { ...newRow?.error, indent: true };
            } else if (name === "batch") {
              newRow.error = { ...newRow?.error, indent: false };
            }
            return {
              ...newRow,
              [name]: newValue,
            };
          }

          return row;
        })
      );
    },
    []
  );

  const handleAutoCompleteInputChange = useCallback(
    (
      e: any,
      newValue: any,
      id: string | number,
      name: string,
      parentId: number
    ) => {
      setIndents((prev: any) =>
        prev.map((row: any) => {
          return parentId === row.id
            ? {
                ...row,
                indent_products: row?.indent_products?.map((ele: any) => {
                  if (ele.id === id) {
                    return {
                      ...ele,
                      [name]: newValue,
                    };
                  }

                  return ele;
                }),
              }
            : row;
        })
      );
      setFilteredIndents((prev: any) =>
        prev.map((row: any) => {
          return parentId === row.id
            ? {
                ...row,
                indent_products: row?.indent_products?.map((ele: any) => {
                  if (ele.id === id) {
                    return {
                      ...ele,
                      [name]: newValue,
                    };
                  }

                  return ele;
                }),
              }
            : row;
        })
      );
    },
    []
  );

  const handleChangeCheckBox = (event: any, name: string) => {
    const { checked } = event?.target;

    setFilterData((prevState: any) => {
      const newFilterData = { ...prevState };
      newFilterData[name] = checked;

      if (!appConfiguration?.goods_issue_disable) {
        newFilterData[
          name === "isPurchaseOrder" ? "isIssuable" : "isPurchaseOrder"
        ] = !checked;
      }

      return newFilterData;
    });

    setSelectedProducts([]);
    setIndents([]);
    setFilteredIndents([]);
  };

  const debouncedSearch = useCallback(
    debounce((value: string, indents: any) => {
      const newFilteredIndent = indents?.filter((product: any) =>
        product?.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setIsLoading(false);
      setFilteredIndents(newFilteredIndent);
    }, 500),
    []
  );

  const handleSearch = (event: any) => {
    const { value } = event.target;
    setIsLoading(true);
    setFilterData((prev) => ({
      ...prev,
      search: value,
    }));

    debouncedSearch(value, indents);
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: newValue.validatedValue[0] };
        return newFilter;
      });
    }
  };

  const handlePoValidation = () => {
    const productsError = [] as any;

    if (selectedProducts?.length > 0) {
      let productsHasError = false;

      indents?.forEach((indent) => {
        const productError = {} as any;

        if (selectedProducts.includes(indent.id)) {
          if (!indent?.supplier_id && filterData.isPurchaseOrder) {
            productError.supplier_id = "required field";
            productsHasError = true;
          }

          if (!indent.batch && !filterData?.isPurchaseOrder) {
            indent.batch = "required field";
            productsHasError = true;
          }
          if (
            !filterData?.isPurchaseOrder &&
            Number(indent?.batch?.stock_qty) < Number(indent?.indent)
          ) {
            indent.batch = "required field";
            productsHasError = true;
          }

          if (!indent?.indent) {
            productError.indent = "required field";
            productsHasError = true;
          }
        }
        productsError.push(productError);
      });

      if (productsHasError) {
        setIsOpenErrorModal({
          isOpen: true,
          content: filterData?.isPurchaseOrder
            ? "Please fill the selected products supplier and quantity"
            : "Please check the selected products batch and quantity",
        });

        return false;
      }

      return true;
    } else {
      return false;
    }
  };

  const handleGeneratePoClick = () => {
    if (handlePoValidation()) {
      if (filterData?.isPurchaseOrder) {
        setSelectedSupplierProducts(() => {
          const newSelectedSupplierProducts: any = [];
          selectedProducts.forEach((id) => {
            let product = {} as any;
            indents?.forEach((indent) => {
              if (id === indent.id) {
                product = indent;
              }
            });

            const index = newSelectedSupplierProducts?.findIndex(
              (ele: any) => ele.supplier_id === product?.supplier_id?.id
            );

            if (index === -1) {
              newSelectedSupplierProducts.push({
                supplier_id: product?.supplier_id?.id,
                supplier: product?.supplier_id?.name,
                products: [
                  {
                    product_id: product?.product_id,
                    product_name: product?.name,
                    total_requirement: product?.requirement,
                    order_qty: product?.indent,
                    indents: [product?.indent_id],
                  },
                ],
              });
            } else {
              const productIndex = newSelectedSupplierProducts[
                index
              ]?.products?.findIndex(
                (ele: any) => ele.product_id === product.product_id
              );

              if (productIndex !== -1) {
                newSelectedSupplierProducts[index].products[
                  productIndex
                ].total_requirement =
                  Number(
                    newSelectedSupplierProducts[index].products[productIndex]
                      .total_requirement
                  ) + Number(product?.requirement);
                newSelectedSupplierProducts[index].products[
                  productIndex
                ].order_qty =
                  Number(
                    newSelectedSupplierProducts[index].products[productIndex]
                      .order_qty
                  ) + Number(product?.indent);

                if (
                  !newSelectedSupplierProducts[index]?.products[
                    productIndex
                  ]?.indents?.includes(product.indent_id)
                ) {
                  newSelectedSupplierProducts[index]?.products[
                    productIndex
                  ]?.indents?.push(product.indent_id);
                }
              } else {
                newSelectedSupplierProducts[index]?.products?.push({
                  product_id: product?.product_id,
                  product_name: product?.name,
                  total_requirement: product?.requirement,
                  order_qty: product?.indent,
                  indents: [product?.indent_id],
                });
              }
            }
          });

          return newSelectedSupplierProducts;
        });
      }

      if (filterData?.isIssuable) {
        setSelectDepartmentProducts(() => {
          const newSelectedDepartmentProducts: any = [];
          const from_department = issuerDepartmentOptions.find(
            (ele: any) => ele.id === filterData.from_department
          );

          selectedProducts?.forEach((id) => {
            let product = {} as any;

            indents?.forEach((indent) => {
              if (id === indent.id) {
                product = indent;
              }
            });

            const index = newSelectedDepartmentProducts?.findIndex(
              (ele: any) => ele?.department?.id === product?.dept_id
            );

            if (index === -1) {
              newSelectedDepartmentProducts?.push({
                department: { id: product?.dept_id, name: product?.dept_name },
                department_name: product?.dept_name,
                from_department: from_department,
                products: [
                  {
                    product_id: product?.product_id,
                    product_name: product?.name,
                    total_requirement: product?.requirement,
                    order_qty: product?.indent,
                    batch: product?.batch,
                    indents: [product?.indent_id],
                  },
                ],
              });
            } else {
              const productIndex = newSelectedDepartmentProducts[
                index
              ]?.products?.findIndex(
                (ele: any) => ele.product_id === product.product_id
              );

              if (productIndex !== -1) {
                newSelectedDepartmentProducts[index].products[
                  productIndex
                ].total_requirement =
                  Number(
                    newSelectedDepartmentProducts[index].products[productIndex]
                      .total_requirement
                  ) + Number(product?.requirement);
                newSelectedDepartmentProducts[index].products[
                  productIndex
                ].order_qty =
                  Number(
                    newSelectedDepartmentProducts[index].products[productIndex]
                      .order_qty
                  ) + Number(product?.indent);
                if (
                  !newSelectedDepartmentProducts[index]?.products[
                    productIndex
                  ]?.indents?.includes(product.indent_id)
                ) {
                  newSelectedDepartmentProducts[index]?.products[
                    productIndex
                  ]?.indents?.push(product.indent_id);
                }
              } else {
                newSelectedDepartmentProducts[index]?.products?.push({
                  product_id: product?.product_id,
                  product_name: product?.name,
                  total_requirement: product?.requirement,
                  order_qty: product?.indent,
                  batch: product?.batch,
                  indents: [product?.indent_id],
                });
              }
            }
          });

          return newSelectedDepartmentProducts;
        });
      }
      setIsOpenConfirmPoModal(true);
    }
  };

  const handleConfirmChangeStatus = () => {
    if (isOpenConfirmModal.data) {
      setIsStatusLoading(true);

      updateIndentProductStatus(isOpenConfirmModal.data)
        .then((res: any) => {
          handleChange(
            {
              target: {
                name: "status",
                value: isOpenConfirmModal?.data?.status,
              },
            },
            isOpenConfirmModal?.data?.indent_product_id
          );
          setIsOpenConfirmModal({ isOpen: false, data: null });
          setIsStatusLoading(false);
          dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
        })
        .catch((err) => {
          setIsStatusLoading(false);
          console.log("err", err);
        });
    }
  };

  const handleClosePoModal = (isSave?: boolean) => {
    if (isSave) {
      setSelectedProducts([]);
      setAllProductsIds([]);
      getAllIndentProductsData();
    }
    setIsOpenConfirmPoModal(false);
  };

  const handleChangeBatch = (data: {
    key: number;
    product_id: number | string;
    batch_id: number | string;
    qty: number;
  }) => {
    setBatches((prevState: any) => {
      const newState = { ...prevState };
      if (data?.product_id) {
        newState[data?.product_id] = newState[data?.product_id]?.map(
          (ele: any) => {
            if (data?.batch_id === ele?.id) {
              return {
                ...ele,
                stock_qty: data.qty,
              };
            }

            return ele;
          }
        );
      }

      return newState;
    });
  };

  const getAllIndentProductsData = () => {
    if (
      (filterData?.isIssuable && filterData.from_department) ||
      filterData?.isPurchaseOrder
    ) {
      setIsLoading(true);
      getAllIndentProducts({
        type: filterData?.isIssuable ? "issuable" : "orderable",
        dept_id: filterData?.from_department,
        from_date: filterData?.from_date
          ? moment(filterData?.from_date, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
        to_date: filterData?.to_date
          ? moment(filterData?.to_date, "DD-MM-YYYY").format("YYYY-MM-DD")
          : null,
      })
        .then((res: any) => {
          if (res.data) {
            setIndents(
              res?.data?.indent?.map((ele: any) => ({
                ...ele,
                indent: Number(ele.requirement) - Number(ele.issued_qty),
                requirement: Number(ele.requirement) - Number(ele.issued_qty),
              }))
            );
            setFilteredIndents(
              res?.data?.indent?.map((ele: any) => ({
                ...ele,
                indent: Number(ele.requirement) - Number(ele.issued_qty),
                requirement: Number(ele.requirement) - Number(ele.issued_qty),
              }))
            );

            if (res?.data?.stock) {
              setBatches(res?.data?.stock);
            }
            if (res?.data?.indent?.length > 0) {
              const ids = [] as any[];

              res?.data?.indent?.forEach((indent: any) => {
                ids.push(indent.id);
              });

              if (ids?.length > 0) {
                setAllProductsIds(ids);
              }
            }
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);

          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getAllIndentProductsData();
  }, [
    filterData?.isIssuable,
    filterData?.from_date,
    filterData?.to_date,
    filterData.from_department,
  ]);

  useEffect(() => {
    if (commonVariables?.indent_product_status) {
      setStatusOptions(
        commonVariables?.indent_product_status?.map((option: any) => ({
          ...option,
          disabled: option.changeable,
        }))
      );
    }
  }, [commonVariables]);

  useEffect(() => {
    getAllMasterDepartments({ type: "issuer", active: 1 })
      .then((res: any) => {
        if (res?.data?.result) {
          setIssuerDepartmentOptions(
            res?.data?.result?.map((ele: any) => ({
              ...ele,
              name: ele.dept_name,
            }))
          );

          if (res?.data?.result?.length === 1) {
            setFilterData((prevState: any) => ({
              ...prevState,
              from_department: res?.data?.result[0]?.id,
            }));
          }
        }
      })
      .catch((err: any) => console.log(err));
  }, []);

  return (
    <Box width={"100%"}>
      <Grid container columnSpacing={2} alignItems="flex-end">
        {/* {!appConfiguration?.goods_issue_disable && (
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <CheckBox
              label="Issuable"
              name="isIssuable"
              sx={styles.checkBoxLabelStyle}
              checked={filterData?.isIssuable}
              onChange={(e: any) => handleChangeCheckBox(e, "isIssuable")}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <CheckBox
            label="Purchase Order"
            sx={styles.checkBoxLabelStyle}
            checked={filterData?.isPurchaseOrder}
            onChange={(e: any) => handleChangeCheckBox(e, "isPurchaseOrder")}
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={5} xl={3}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "8px",
              background: "#F2F4F5",
              borderRadius: "10px",
              minWidth: "300px",
            }}
          >
            <Box
              sx={{
                minHeight: "40px",
                height: "100%",
                borderRadius: "10px",
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: !appConfiguration?.goods_issue_disable
                  ? "pointer"
                  : "unset",
                fontWeight: 500,
                color: "#000",
                opacity: filterData?.isIssuable ? 1 : 0.5,
                background: filterData?.isIssuable ? "#fff" : "unset",
                transition: "background 0.3s ease",
              }}
              onClick={() =>
                !appConfiguration?.goods_issue_disable
                  ? handleChangeCheckBox(
                      { target: { checked: !filterData.isIssuable } },
                      "isIssuable"
                    )
                  : undefined
              }
            >
              <IssuableIcon style={{ marginRight: "10px" }} />
              Issuable
            </Box>
            <Box
              sx={{
                minHeight: "40px",
                height: "100%",
                borderRadius: "10px",
                width: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: filterData?.isPurchaseOrder ? 1 : 0.5,
                background: filterData?.isPurchaseOrder ? "#fff" : "unset",
                fontWeight: 500,
                color: "#000",
                cursor: "pointer",
                transition: "background 0.3s ease",
              }}
              onClick={() =>
                !appConfiguration?.goods_issue_disable
                  ? handleChangeCheckBox(
                      { target: { checked: !filterData.isPurchaseOrder } },
                      "isPurchaseOrder"
                    )
                  : undefined
              }
            >
              <PurchaseOrderIcon style={{ marginRight: "10px" }} />
              Orderable
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <DatePicker
            label="From Date"
            name="from_date"
            placeholder="Select Date"
            width="100%"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "from_date")
            }
            formControlStyle={{ width: "90%" }}
            format="DD/MM/YYYY"
            value={filterData.from_date}
            style={{
              borderRadius: "5px",
              boxShadow: "none",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <DatePicker
            label="To Date"
            name="to_date"
            placeholder="Select Date"
            width="100%"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "to_date")
            }
            formControlStyle={{ width: "90%" }}
            format="DD/MM/YYYY"
            value={filterData.to_date}
            style={{
              borderRadius: "5px",
              boxShadow: "none",
            }}
          />
        </Grid>
        {filterData.isIssuable && (
          <Grid item xs={12} sm={6} md={3} xl={2}>
            <Select
              label="In Stock"
              name="from_department"
              placeholder="Select department"
              options={issuerDepartmentOptions}
              width=""
              onChange={handleChange}
              formControlStyle={{ width: "100%" }}
              value={filterData.from_department}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3} xl={2}>
          <TextField
            placeholder="Search product"
            label="search"
            value={filterData?.search}
            onChange={handleSearch}
          />
        </Grid>
      </Grid>
      {/* <Grid container columnSpacing={1} rowSpacing={1} mt={2}>
      </Grid> */}
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflowX: { md: "scroll", lg: "hidden" },
        }}
        mt={2}
        container
      >
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
          }}
        >
          <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {TableHeaderCells.map((cell, index) => {
                  return (
                    <TableCell align={cell.alignment} sx={cell.sx} key={index}>
                      {cell?.hasCheckBox ? (
                        <Box display={"flex"} alignItems={"center"}>
                          <CheckBox
                            checked={
                              selectedProducts?.length ===
                                allProductsIds?.length &&
                              selectedProducts.length > 0
                            }
                            onChange={handleCheckAll}
                          />
                          {cell.TableCellName}
                        </Box>
                      ) : (
                        <>{cell.TableCellName}</>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody sx={{ height: "auto", width: "100%" }}>
              {isLoading ? (
                <TableRow>
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "40px",
                        gap: 2,
                      }}
                    >
                      <CircularProgress />
                      <Typography variant="h2" color="initial">
                        Loading
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                </TableRow>
              ) : filteredIndents?.length > 0 ? (
                <>
                  {filteredIndents?.map((indent: any, index: number) => (
                    <>
                      <ProductRow
                        index={index}
                        styles={styles}
                        product={indent}
                        selectedProducts={selectedProducts}
                        handleCheck={handleCheck}
                        setIsOpenConfirmModal={setIsOpenConfirmModal}
                        handleChange={handleChange}
                        handleAutoCompleteInputChange={
                          handleAutoCompleteInputChange
                        }
                        isPurchaseOrder={filterData?.isPurchaseOrder}
                        statusOptions={statusOptions}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        batches={batches}
                      />
                    </>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "40px",
                        gap: 2,
                      }}
                    >
                      <Typography>No Rows</Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 2 }}>
          <SaveButton
            buttonText={filterData?.isPurchaseOrder ? "GEN PO" : "Issue"}
            handleClick={() => handleGeneratePoClick()}
            disabled={selectedProducts?.length === 0}
          />
        </Box>
      </Grid>
      {isOpenConfirmPoModal && (
        <ConfirmPoModal
          isOpen={isOpenConfirmPoModal}
          data={
            filterData?.isIssuable
              ? selectedDepartmentProducts
              : selectedSupplierProducts
          }
          handleChangeBatch={handleChangeBatch}
          isGoodsIssue={filterData?.isIssuable}
          onClose={(isSave?: boolean) => handleClosePoModal(isSave)}
        />
      )}
      {isOpenConfirmModal.isOpen && (
        <ConfirmationDialog
          open={isOpenConfirmModal.isOpen}
          title="are you surely want to change the status?"
          handleClick={handleConfirmChangeStatus}
          onClose={() => setIsOpenConfirmModal({ isOpen: false, data: null })}
          loading={isStatusLoading}
          confirmText="Yes"
        />
      )}
      <ConfirmationDialog
        open={isOpenErrorModal.isOpen}
        title={<Box>{isOpenErrorModal.content}</Box>}
        confirmText="Okay"
        type="info"
        handleClick={() => {
          setIsOpenErrorModal({ isOpen: false, content: "" });
        }}
        onClose={() => setIsOpenErrorModal({ isOpen: false, content: "" })}
      />
    </Box>
  );
};

export default BillProducts;
