import { useCallback, useEffect, useState } from "react";
import { Typography, Box, IconButton, debounce } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../../../components/shared";
import { Sort } from "../../../components/basic";
import { ViewIcon } from "../../../assets/icons";
import CustomFilter from "../../../components/shared/customFilter";
import { getAllPurchaseOrders } from "../../../services/laboratoryService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setSnackBarFailed } from "../../../redux/slices/snackbar";
import {
  errorMessage,
  indentStatusBackgroundColors,
} from "../../../constants/displayText";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "../../../constants/routes";

const { laboratoryUrl, labPurchaseOrderUrl, viewUrl } = RouteUrls;

type GridRowData = Record<string, unknown>;

const LabPurchaseOrdersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string | number | null>("");
  const [purchaseOrdersList, setPurchaseOrdersList] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.PurchaseOrders
  );
  const { commonVariables } = useSelector(
    (state: RootState) => state?.appConfiguration
  );

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          aria-label=""
          onClick={() =>
            navigate(
              `${laboratoryUrl}${labPurchaseOrderUrl}${viewUrl}/${row.id}`
            )
          }
        >
          <ViewIcon />
        </IconButton>
      </div>
    );
  };

  const getStatus = (id: number) => {
    let status = "";
    if (id) {
      status =
        commonVariables?.purchase_order_status?.find(
          (ele: any) => ele.id === id
        )?.value || "";
    }
    return status;
  };
  const columns: GridColDef[] = [
    {
      field: "sequence_no",
      flex: 0.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("sequence_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Po.No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "sequence_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "sequence_no" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.sequence_no}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },

    {
      field: "created_at",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_at")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "created_at" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "created_at" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row.created_at).format("YYYY-MM-DD hh:mm:ss A")}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "supplier_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("supplier_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Supplier
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "supplier_name" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "supplier_name" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.supplier_name}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "created_user",
      flex: 0.8,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "created_user" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "created_user" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.created_user}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "status",
      flex: 0.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("status")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "status" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "status" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          sx={{
            padding: "6px",
            borderRadius: "5px",
            minWidth: "80px",
            textAlign: "center",
            background: row?.status
              ? indentStatusBackgroundColors[getStatus(row?.status?.toString())]
                  ?.background
              : "none",
            color: row?.status
              ? indentStatusBackgroundColors[getStatus(row?.status?.toString())]
                  ?.color
              : "none",
          }}
        >
          {getStatus(row?.status?.toString())}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 0.5,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];
  // const purchaseOrdersList = [
  //   {
  //     id: 1,
  //     poNo: "001",
  //     date: "12-04-2023",
  //     supplier: "Glen",
  //     user: "Kane",
  //     status: "Success",
  //   },
  // ];

  const getPurchaseOrdersList = async (data: any) => {
    setLoading(true);

    await getAllPurchaseOrders(data)
      .then((res) => {
        setPurchaseOrdersList(res.data.result as []);
        setPageCount(res.data.total as any);
        setLoading(false);
      })
      .catch((err) => {
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage.ERROR_FETCHING_PURCHASE_ORDERS}`,
          })
        );
        setLoading(false);
      });
  };

  const debouncedGetPurchaseOrdersList = useCallback(
    debounce((data) => {
      getPurchaseOrdersList(data);
    }, 500),
    []
  );

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      limit: pageInfo?.pageSize,
      column: sortedField.field,
      order: sortedField.order,
      search: searchValue,
    };
    debouncedGetPurchaseOrdersList(data);
  }, [pageInfo, sortedField, searchValue]);

  return (
    <Box>
      <CustomFilter
        isSearchEnabled={true}
        searchOnChange={(e: any) => setSearchValue(e.target.value)}
        searchName={"search"}
        searchValue={searchValue}
        clearSearch={() => setSearchValue("")}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={purchaseOrdersList}
        pageCount={pageCount}
        currentPage={"PurchaseOrders"}
      />
    </Box>
  );
};

export default LabPurchaseOrdersList;
