import { Box } from "@mui/material";
import { SuggestionsIcon } from "../../../../../assets/icons";

type FollowUpDetailsType = {
  id: number | string;
  patient_id: number | string;
  patient_name: number | string;
  antenatal_card_id: number | string;
  date_of_visit: number | string;
  trimester_week: number | string;
  vitals: [
    {
      vital_id?: string;
      vital_date?: string;
      vital_name?: string;
      vital_unit?: string;
      vital_value?: number | string;
    }
  ];
  findings: [];
  tests: [];
  scans: [];
  notes: null;
  next_review_date: null;
  trimester: string;
  suggestions: [];
};
export default function index({
  lastFollowUpDetails,
}: {
  lastFollowUpDetails: FollowUpDetailsType;
}) {
  return (
    <div>
      {lastFollowUpDetails["suggestions"]?.map((ele: any) => (
        <Box
          sx={{
            borderRadius: "6px",
            height: "48px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            border: "1px solid #EAEAEA",
            padding: "8px 9px 11px",
            color: "#8A8A8A",
            margin: "12px 12px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px",
          }}
        >
          <Box>
            <SuggestionsIcon />
          </Box>
          <Box
            sx={{
              padding: "0px 14px",
              width: "100%",
              marginTop: "5px",
            }}
          >
            <Box
              sx={{
                marginBottom: "10px",
                fontWeight: 500,
                fontSize: "16px",
              }}
            >
              {ele?.product.name}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              <Box>{`${ele?.freq | 0} | ${ele?.dosage || 0}`}</Box>
              <Box>{`Starts - ${ele?.start_date || 0} Ends: ${
                ele?.end_date || "No Date"
              }`}</Box>
            </Box>
          </Box>
        </Box>
      ))}
    </div>
  );
}
