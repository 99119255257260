import { Box, Grid, Typography } from "@mui/material";
import { paymentViewInfoConst } from "../../../../../constants/displayText";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  cancelLabPurchaseBillPayment,
  getLabPurchasePayment,
} from "../../../../../services/laboratoryService";
import { formatTwelveHoursTime } from "../../../../../utils/DateTimeFormatUtils";
import { BillCancelImg } from "../../../../../assets/images";
import {
  ConfirmationDialog,
  DataTable,
} from "../../../../../components/shared";
import { GridColDef } from "@mui/x-data-grid";
import PaymentsFooter from "../PaymentsFooter";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { PageLoader } from "../../../../../components/basic";

type GridRowData = Record<string, unknown>;

const {
  payee,
  cashReceived,
  createdDate,
  type,
  billAmount,
  paidAmount,
  remarks,
} = paymentViewInfoConst;

export default function PaymentView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<any>({});
  const [totals, setTotals] = useState({
    totalBillAmount: 0,
    totalAmountDue: 0,
    totalPaidAmount: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const infoData = [
    {
      name: "Supplier",
      value: `${data?.name || ""}`,
    },

    {
      name: cashReceived,
      value: data?.cash_paid,
    },
    {
      name: createdDate,
      value: data?.date_created,
    },
    {
      name: type,
      value: data?.type,
    },
    {
      name: "Excess",
      value: data?.exs_bal || 0,
    },
    {
      name: "Advance",
      value: data?.adv_bal || 0,
    },
  ];

  const advanceInfoData = [
    {
      name: "Supplier",
      value: `${data?.name}`,
    },
    {
      name: cashReceived,
      value: data?.cash_paid,
    },
    {
      name: createdDate,
      value: data?.date_created,
    },
    {
      name: type,
      value: data?.type,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Supplier Name
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.name}</Typography>
      ),
      sortable: false,
      minWidth: 150,
    },
    {
      field: "invoice_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Invoice No
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.invoice_no}</Typography>
      ),
      sortable: false,
      minWidth: 150,
    },
    {
      field: "payee",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {payee}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.payee_name}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "ref_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Ref No
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.ref_no}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "bill_amt",
      flex: 1.5,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {billAmount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.bill_amt}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },

    {
      field: "paid_amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {paidAmount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.paid_amt}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "amount_due",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {remarks}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.balance}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
  ];

  // useEffect(() => {
  //   getCardOptionDetails()
  //     .then((res) => {
  //       if (res.data) {
  //         setCardDetails(res.data[0]);
  //         // setData((prevState: any) => ({
  //         //   ...prevState,
  //         //   convenience_fee: calculateConvenienceFee(
  //         //     prevState,
  //         //     res?.data[0]?.convenience_fee_percentage || 0
  //         //   ),
  //         // }));
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getLabPurchasePayment(id)
        .then((res: any) => {
          if (res.data) {
            const { bill, payment } = res.data;
            setData({
              ...payment,
              date_created: formatTwelveHoursTime(payment?.date_created),
              payment_type: payment?.type,
              excess_amount: payment?.total_excess,
              cash_received: payment?.cash_paid,
              payments: bill?.map((ele: any) => ({
                ...ele,
                discount_value: ele?.discount_value || 0,
                discount_type: ele?.discount_type || "%",
                discount_amount: ele?.discount_amount || 0,
                balance: (Number(ele?.balance) + Number(ele?.paid_amt)).toFixed(
                  2
                ),
                previously_paid: ele.is_cancel
                  ? ele.due_amount
                  : (Number(ele?.due_amount) - Number(ele?.paid_amt))?.toFixed(
                      2
                    ),
              })),
            });

            const { totalBillAmount, totalAmountDue, totalPaidAmount } =
              res?.data?.bill?.reduce(
                (totals: any, bill: any) => {
                  totals.totalBillAmount += Number(bill.net_total_amt) || 0;
                  totals.totalAmountDue +=
                    Number(bill?.balance) + Number(bill?.paid_amt) || 0;
                  totals.totalPaidAmount += Number(bill.paid_amt) || 0;
                  return totals;
                },
                { totalBillAmount: 0, totalAmountDue: 0, totalPaidAmount: 0 }
              );

            setTotals({
              totalBillAmount,
              totalAmountDue,
              totalPaidAmount,
            });
          }
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  }, [id]);

  const handleConfirmCancel = () => {
    if (isOpenCancelModal) {
      setIsCancelLoading(true);
      cancelLabPurchaseBillPayment(id as any)
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setIsOpenCancelModal(false);
          setIsCancelLoading(false);
          navigate("/laboratory/lab_purchase_payments");
        })
        .catch((err) => {
          console.log("err", err);
          setIsCancelLoading(false);
          dispatch(
            setSnackBarFailed({
              snackBarMessage: err?.response?.data?.errors,
            })
          );
        });
    }
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {data?.is_advance ? (
            <Box
              sx={{
                // width: "100%",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pl: 2,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    m: "15px 0px 10px 0px",
                  }}
                >
                  {advanceInfoData?.map((row, index) => {
                    return (
                      <Grid
                        sm={12}
                        xs={12}
                        md={4}
                        xl={4}
                        key={index}
                        sx={{
                          width: {
                            xs: "100%",
                            lg: "100%",
                          },
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          maxHeight: "35px",
                          mb: "23px",
                          color: "textPrimary.main",
                        }}
                        className="row"
                      >
                        <Grid
                          sx={{
                            width: "30%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "13px", fontWeight: "600" }}
                          >
                            {row.name}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "13px", fontWeight: "600" }}
                          >
                            :
                          </Typography>
                        </Grid>
                        <Grid
                          sx={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            pl: 3,
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ color: "greyScale.main" }}
                          >
                            {row.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                {/* <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      color: "#000",
                      padding: "12px 30px",
                      background: "#F2F2F2",
                      margin: "0.3125rem 1px",
                      fontSize: ".75rem",
                      fontWeight: 400,
                      lineHeight: 1.428571,
                      cursor: "pointer",
                      borderRadius: "30px",
                      mr: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      "& path": {
                        stroke: "#333333",
                      },
                    }}
                    // onClick={handleCancel}
                  >
                    <PrintIcon style={{ color: "#000", marginRight: "10px" }} />
                    Print
                  </Box>
                </Box> */}
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  // width: "100%",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pl: 2,
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {data?.is_cancel ? (
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      <Box>
                        <img src={BillCancelImg} />
                      </Box>
                    </Box>
                  ) : null}
                  <Grid
                    container
                    sx={{
                      // width: {
                      //   xs: "90%",
                      // },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      m: "15px 0px 10px 0px",
                    }}
                  >
                    {infoData?.map((row, index) => {
                      return (
                        <Grid
                          sm={12}
                          xs={12}
                          md={4}
                          xl={4}
                          key={index}
                          sx={{
                            width: {
                              xs: "100%",
                              lg: "100%",
                            },
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            maxHeight: "35px",
                            mb: "23px",
                            color: "textPrimary.main",
                          }}
                          className="row"
                        >
                          <Grid
                            sx={{
                              width: "30%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {row.name}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              :
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              width: "70%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              pl: 3,
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{ color: "greyScale.main" }}
                            >
                              {row.value}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>

              <DataTable
                columns={columns}
                // rows={Boolean(data?.payments?.length > 0) ? data?.payments : []}
                rows={[]}
                getRowId={(row: GridRowData) => `${String(row?.id)}`}
                tableOnly={true}
                customizedTable={true}
              />

              <Box mt={2}>
                <PaymentsFooter data={data} isView />
              </Box>
              <Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      color: "#fff",
                      backgroundColor: "#f08f00",
                      borderColor: "#c27400",
                      boxShadow:
                        "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
                      padding: "12px 30px",
                      margin: "0.3125rem 1px",
                      fontSize: ".75rem",
                      fontWeight: 400,
                      lineHeight: 1.428571,
                      cursor: "pointer",
                      borderRadius: "30px",
                      mr: "10px",
                    }}
                    onClick={() => setIsOpenCancelModal(true)}
                  >
                    X Cancel
                  </Box>
                  {/* <Box
                    sx={{
                      color: "#000",
                      padding: "12px 30px",
                      background: "#F2F2F2",
                      margin: "0.3125rem 1px",
                      fontSize: ".75rem",
                      fontWeight: 400,
                      lineHeight: 1.428571,
                      cursor: "pointer",
                      borderRadius: "30px",
                      mr: "10px",
                      "& path": {
                        stroke: "#333333",
                      },
                    }}
                    // onClick={handleCancel}
                  >
                    <PrintIcon style={{ color: "#000", marginRight: "10px" }} />
                    Full Print
                  </Box>
                  <Box
                    sx={{
                      color: "#000",
                      background: "#F2F2F2",
                      padding: "12px 30px",
                      margin: "0.3125rem 1px",
                      fontSize: ".75rem",
                      fontWeight: 400,
                      lineHeight: 1.428571,
                      cursor: "pointer",
                      borderRadius: "30px",
                      "& path": {
                        stroke: "#333333",
                      },
                    }}
                    // onClick={handleCancel}
                  >
                    <PrintIcon style={{ color: "#000", marginRight: "10px" }} />
                    Print
                  </Box> */}
                </Box>
              </Box>
              <ConfirmationDialog
                open={isOpenCancelModal}
                title={
                  <div>
                    <p>Do you want to cancel the bill?</p>{" "}
                    <p>
                      Paid amount {totals?.totalPaidAmount} will be added to
                      patient/payee excess amount
                    </p>
                  </div>
                }
                handleClick={handleConfirmCancel}
                onClose={() => setIsOpenCancelModal(false)}
                loading={isCancelLoading}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}
