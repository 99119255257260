import { Box, Typography, debounce } from "@mui/material";
import { AutoComplete, TextField } from "../../../../components/basic";
import { DialogWrapper } from "../../../../components/shared";
import React, { useCallback, useEffect, useState } from "react";
import CommonEditableTable from "../commonEditableTable";
import { DeleteIcon } from "../../../../assets/icons";
import {
  getInvestigationTemplateDetailsById,
  getLabTestList,
} from "../../../../services/appointmentService";

type AddAndEditModalProps = {
  id?: number | string | null;
  isView?: boolean;
  isOpen: boolean;
  isSaveLoading: boolean;
  onClose: () => void;
  onSave: (data: any) => void;
};

const styles = {
  tableContainerStyle: {
    borderRadius: "5px",
    boxShadow: "none",
    overflow: "hidden",
    width: "100%",
    overflowX: { xs: "scroll", lg: "hidden" },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableStyle: {
    border: "1px solid",
    borderColor: "var(--table-border)",
    width: "100%",
    minWidth: "1200px",
    overflowX: "auto",
    "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
    "& .MuiTableRow-head": {
      backgroundColor: "var(--table-header)",
    },
    " & .MuiTableBody-root": {
      border: 0,
    },
    "& .MuiTableRow-root": {
      height: "45px !important",
      maxHeight: "34px !important",
      minHeight: "0px",
      lineHeight: "0px",
      border: 0,
    },

    "& .MuiTableCell-root": {
      minHeight: "0px",
    },

    "& .MuiTableCell-body": {
      height: "12px !important",
      maxHeight: "32px !important",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 0px",
      fontSize: "12px",
      fontWeight: "400",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "var(--table-border)",
      overflow: "hidden",
    },

    "& .MuiTableCell-body:has(.Mui-focused)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },

    "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },
    "& .deleteIconGrid": {
      cursor: "pointer",
      position: "absolute",
      right: {
        xs: "0px",
        sm: "0px",
        md: "0px",
        lg: "-2vw",
      },
      bottom: "5px",
      mb: "10px",
    },
  },
  header: {
    backgroundColor: "var(--table-header)",
  },
  headerStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
    borderBottom: "1px solid var(--table-border)",
    borderRight: "1px solid var(--table-border)",
    padding: "12px 12px 12px 20px",
    marginLeft: "8px",
  },
  textFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "0px 5px",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  selectFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
};

const AddAndEditModal = (props: AddAndEditModalProps) => {
  const { id, isOpen, isView, isSaveLoading, onClose, onSave } = props;
  const [templateName, setTemplateName] = useState("");
  const [labTestes, setLabTestes] = useState([{ id: 1, name: "", type: "" }]);
  const [sortedField, setSortedField] = useState({ field: "", order: "" });
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [labTestOptions, setLabTestOptions] = useState([]);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (isFieldSort) {
      setSortedField({ field: field, order: "ASC" });
    }
    if (!isFieldSort) {
      setSortedField({ field: field, order: "DESC" });
    }
  };

  const getLabTestsOptions = (data: any) => {
    getLabTestList(data)
      .then((res: any) => {
        if (res.data) {
          setLabTestOptions(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const debouncedGetLabTestes = useCallback(
    debounce((data: any) => {
      getLabTestsOptions(data);
    }, 500),
    []
  );

  const handleAddItem = () => {
    setLabTestes((prevState: any) => [...prevState, { name: "testv-2" }]);
  };
  const handleDelete = (index: number) => {
    setLabTestes((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  const handleAutoCompleteChange = (
    event: any,
    newValue: any,
    index: number
  ) => {
    setLabTestes((prevState) => {
      const newState = [...prevState];
      newState[index].name = newValue;
      newState[index].type = newValue.type;

      return newState;
    });
  };

  const handleAutoCompleteInputChange = (
    event: any,
    newValue: any,
    index: number
  ) => {
    const { value } = event.target;
    setLabTestes((prevState: any) => {
      const newState = [...prevState];
      newState[index].labTest_input_value = value;

      return newState;
    });

    debouncedGetLabTestes({ department: 1, limit: 20, search: value });
  };

  useEffect(() => {
    const data = {
      // search,
      department: 1,
      limit: 20,
    };
    getLabTestsOptions(data);
  }, []);

  useEffect(() => {
    if (id) {
      getInvestigationTemplateDetailsById(id).then((res) => {
        if (res.data) {
          setTemplateName(res.data?.name);
          setLabTestes(res.data?.labTestes);
        }
      });
    }
  }, [id]);

  const columns = {
    headers: [
      {
        header: "S.No",
        textAlignment: "left",
        width: 10,
        name: "s.no",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={{ padding: "12px 12px 12px 20px", textAlign: "center" }}>
            <Typography>{index + 1}</Typography>
          </Box>
        ),
      },
      {
        header: "Lab Test",
        textAlignment: "left",
        width: 60,
        name: "labTest",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box
            sx={{
              height: "100%",
              "& .MuiGrid-root": { height: "100%" },
              "& .MuiTextField-root": { height: "100%" },
              "& .MuiInputBase-root": { height: "100%" },
            }}
          >
            <AutoComplete
              value={row.labTest}
              inputValue={row.labTest_input_value}
              options={labTestOptions}
              placeholder="Select Test"
              name="labTest"
              disableClearable={true}
              sx={{
                "& fieldset": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  display: "none",
                },
                "&:hover .MuiSelect-icon": {
                  display: "inline-flex",
                },
                "& .MuiInputBase-root": {
                  background: isView ? "#F6F6F6" : "unset",
                  fontWeight: isView ? 600 : 400,
                },
              }}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(e, newValue, index)
              }
              onInputChange={(e: any, newInputValue: any) => {
                handleAutoCompleteInputChange(e, newInputValue, index);
              }}
              disabled={isView}
            />
          </Box>
        ),
      },
      {
        header: "Type",
        textAlignment: "left",
        width: 20,
        name: "type",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box
            sx={{
              height: "100%",
              "& .MuiGrid-root": { height: "100%" },
              "& .MuiTextField-root": { height: "100%" },
              "& .MuiInputBase-root": { height: "100%" },
            }}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                padding: "12px 12px 12px 20px",
                background: isView ? "#F6F6F6" : "unset",
                fontWeight: isView ? 600 : 400,
              }}
            >
              <Typography>{row.type}</Typography>
            </Box>
          </Box>
        ),
      },
      {
        header: "Action",
        textAlignment: "left",
        width: 20,
        name: "",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: isView ? "#F6F6F6" : "unset",
              fontWeight: isView ? 600 : 400,
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                cursor: isView ? "unset" : "pointer",
                opacity: isView ? 0.5 : 1,
              }}
              onClick={isView ? undefined : () => handleDelete(index)}
            >
              <DeleteIcon />
            </Box>
          </Box>
        ),
      },
    ],
  };

  return (
    <DialogWrapper
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      handleClick={() => {
        onSave({ name: templateName, labTestes });
      }}
      title="Add Investigation Template"
      confirmText="Save"
      cancelText="Cancel"
      loading={isSaveLoading}
    >
      <TextField
        name="templateName"
        label="Template Name"
        sx={{
          width: "50%",
          "& .MuiInputBase-root": {
            background: isView ? "#F6F6F6" : "unset",
            fontWeight: isView ? 600 : 400,
          },
          marginBottom: "30px",
        }}
        value={templateName}
        onChange={(event: any) => setTemplateName(event.target.value)}
        disabled={isView}
      />
      <CommonEditableTable
        columns={columns}
        rows={labTestes}
        minTableWidth={"100%"}
        tableWidth={"100%"}
        sortedField={sortedField}
        handleSorting={handleSorting}
      />
      <Box
        sx={{
          padding: "10px",
          cursor: "pointer",
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "26px",
          textAlign: "left",
          color: "#3A8DFF",
          width: "150px",
        }}
        onClick={handleAddItem}
      >
        <Typography>+ Add Item</Typography>
      </Box>
    </DialogWrapper>
  );
};

export default AddAndEditModal;
