import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import { Grid, Box, CircularProgress } from "@mui/material";
import TabContent from "./TabPanel";

type BookingSlotsProps = {
  name?: string;
  selectedBox: string;
  slotTimes?: any;
  onChange: (id: number, time: string) => void;
  error?: boolean;
  helperText?: string;
  onBlur?: any;
  checkStatus?: boolean;
  loading?: boolean;
  processChart?: boolean;
};

const BookingSlots = ({
  name,
  onChange,
  selectedBox,
  slotTimes,
  error,
  helperText,
  onBlur,
  checkStatus,
  loading,
  processChart,
}: BookingSlotsProps) => {
  const {
    morning = [],
    aftrnoon = [],
    evening = [],
    night = [],
  } = slotTimes || {};

  const morningTimeSlots = morning?.concat(aftrnoon);

  const eveningTimeSlots = evening?.concat(night);

  const morningBookedSlots = morningTimeSlots?.filter(
    (slot: any) => slot?.link_appoint_id !== null
  )?.length;

  const morningAvailableSlots = morningTimeSlots?.filter(
    (slot: any) => slot?.link_appoint_id == null
  )?.length;

  const eveningBookedSlots = eveningTimeSlots?.filter(
    (slot: any) => slot?.link_appoint_id !== null
  )?.length;

  const eveningAvailableSlots = eveningTimeSlots?.filter(
    (slot: any) => slot?.link_appoint_id == null
  )?.length;

  const styles = {
    boxStyle: {
      width: { xs: "100%" },
      bgcolor: "background.paper",
      mt: "30px",
    },
    tabStyle: {
      textTransform: "none",
      fontWeight: 600,
      fontSize: "14px",
      fontFamily: ["Inter", "sans-serif"].join(","),
    },
    tabsStyle: {
      borderRadius: "3px",
      mb: "10px",
      boxShadow: "3px 5px 15px 0px rgba(35, 35, 35, 0.08)",
      color: "#207DFF",
    },
    buttonStyle: {
      width: "108px",
      height: " 49px",
      m: "0 23px 20px 0",
      border: "1px solid primary.main",
      fontSize: "15px",
    },
  };

  const [value, setValue] = React.useState("Morning");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box
        sx={{
          ...styles.boxStyle,
        }}
      >
        <Grid
          item
          sx={{ boxShadow: "3px 5px 15px 0px rgba(35, 35, 35, 0.08) " }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            sx={{
              ...styles.tabsStyle,
            }}
          >
            <Tab
              label="Morning"
              value="Morning"
              disableRipple
              sx={{ ...styles.tabStyle }}
            />
            <Tab
              label="Evening"
              value="Evening"
              disableRipple
              sx={{ ...styles.tabStyle }}
            />
          </Tabs>
        </Grid>

        {loading ? (
          <Grid
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "40vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <TabContent
              value="Morning"
              availableSlots={morningAvailableSlots}
              bookedSlots={morningBookedSlots}
              name={name}
              timeSlots={morningTimeSlots}
              handleButtonClick={onChange}
              selectedBox={selectedBox}
              helperText={helperText}
              error={error}
              onBlur={onBlur}
              checkStatus={checkStatus}
              loading={loading}
              processChart={processChart}
            />
            <TabContent
              value="Evening"
              availableSlots={eveningAvailableSlots}
              bookedSlots={eveningBookedSlots}
              name={name}
              timeSlots={eveningTimeSlots}
              handleButtonClick={onChange}
              selectedBox={selectedBox}
              sx={{
                pl: {
                  xs: "",
                  md: "400px",
                  lg: "450px",
                },
              }}
              helperText={helperText}
              error={error}
              onBlur={onBlur}
              checkStatus={checkStatus}
              loading={loading}
              processChart={processChart}
            />
          </>
        )}
      </Box>
    </TabContext>
  );
};

export default BookingSlots;
