import React, { useState, useMemo, useEffect } from "react";
import { AppointmentsOverviewPng, ProfilePng } from "../../../assets/images";
import { useParams } from "react-router-dom";
import { getPatientDetails } from "../../../services/mainCoreService";
import { Grid, Typography, Box, Avatar, CircularProgress } from "@mui/material";
import RecentVisits from "./RecentVisits";
import Prescriptions from "./Prescriptions";
import Investigations from "./Investigations";
import Vitals from "./Vitals";
import Radiology from "./Radiology";
import Treatments from "./Treatments";
import MedicalRecords from "./MedicalRecords";
import Consultants from "./Consultants";
import RadiologyReport from "./RadiologyReport";

const PatientsHistory = () => {
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [patientData, setPatientData] = useState<any>({
    name: "",
    mobile: "",
    email: "",
    uhid: "",
    dob: "",
    age: "",
    sex: "",
    relationType: "",
    relationName: "",
    language: "",
    aadhaarNumber: "",
    bloodGroup: "",
    lineOne: "",
    lineTwo: "",
    pinCode: "",
    area: "",
    district: "",
    state: "",
  });

  const patientDetails = [
    { title: "Name", value: patientData?.name },
    {
      title: "Relation",
      value:
        patientData?.relationType && patientData?.relationName
          ? `${patientData?.relationType} ${patientData?.relationName}`
          : "-",
    },
    { title: "UHID", value: patientData?.uhid },
    { title: "Mobile No", value: patientData?.mobile },
    {
      title: "Age / Gender",
      value: `${patientData?.age} / ${
        patientData?.sex === 1 ? "Male" : "Female"
      }`,
    },
    {
      title: "Address",
      value:
        patientData?.lineOne && patientData?.lineTwo
          ? `${patientData?.lineOne} ${patientData?.lineTwo}`
          : "",
    },
  ];

  const { id } = useParams();

  const getParticularPatientDetails = async () => {
    try {
      setIsPageLoading(true);

      await getPatientDetails(id).then((result: any) => {
        const data = result?.data;

        const modifiedData = {
          name: data?.name,
          mobile: data?.mobile,
          email: data?.email,
          uhid: data?.patient_id,
          dob: data?.dob,
          age: data?.age,
          sex: data?.sex,
          relationType: data?.relation_type,
          relationName: data?.relation_name,
          language: data?.language,
          aadhaarNumber: data?.aadhar_number,
          bloodGroup: data?.blood_group,
          lineOne: data?.line_1,
          lineTwo: data?.line_2,
          pinCode: data?.pincode,
          area: data?.area,
          district: data?.district,
          state: data?.state,
        };

        setPatientData(modifiedData);

        setIsPageLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getParticularPatientDetails();
    }
  }, [id]);

  return isPageLoading && !id ? (
    <Grid
      sx={{
        width: "95vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "100vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid
      sx={{
        width: { xs: "100vw", sm: "92vw" },
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          minHeight: "175px",
          height: "auto",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "flex-start", md: "space-between" },
          alignItems: { xs: "flex-start", md: "center" },
        }}
      >
        <Grid
          container
          sx={{
            width: { xs: "90%", md: "58%" },
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            borderRadius: "5px",
            border: 1,
            borderColor: "greyScale.lighter",
            backgroundColor: "#FBFBFB",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              p: "0px 20px",
            }}
          >
            <Avatar
              alt="avatar"
              src={ProfilePng}
              sx={{
                width: "10%",
                height: "40%",
              }}
            />
            <Grid
              item
              sx={{
                width: "86%",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "center", md: "flex-start" },
                justifyContent: { xs: "space-around" },
              }}
            >
              <Grid
                item
                sx={{
                  width: {
                    xs: "90%",
                  },
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: { xs: "flex-start", md: "space-between" },
                  alignItems: { xs: "flex-start", md: "center" },
                  flexWrap: "wrap",
                  m: "15px 0px 10px 0px",
                }}
              >
                {patientDetails.map((row, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        width: {
                          xs: "50%",
                          md: "50%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Grid
                        sx={{
                          width: "30%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: "400" }}
                        >
                          {row.title}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: "400" }}
                        >
                          :
                        </Typography>
                      </Grid>
                      <Grid
                        sx={{
                          width: "70%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          pl: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {row.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            width: { xs: "90%", md: "41%" },
            minHeight: "175px",
            height: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${AppointmentsOverviewPng})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "5px",
            mt: { xs: "20px", md: "0px" },
          }}
        ></Grid>
      </Grid>

      <Grid
        sx={{
          width: "100%",
          maxWidth: "100%",
          height: "auto",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
        }}
      >
        <RecentVisits />
        <Prescriptions />
        <Investigations />
        <Vitals />
        <Radiology />
        <MedicalRecords />
        <Consultants />
        <RadiologyReport />
      </Grid>
    </Grid>
  );
};

export default PatientsHistory;
