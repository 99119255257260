import { Grid } from "@mui/material";
import React from "react";
import "./DateCalender.css";
import { Calendar, DateObject, Value } from "react-multi-date-picker";

import { CalenderLeftArrow, CalenderRightArrow } from "../../../assets/icons";
type DateCalenderProps = {
  value?: any;
  onChange?: any;
  name: string;
  format?: string; // New prop
  enabledDates?: any[];
  isMinDate?: boolean;
  minDate?: any;
  maxDate?: any;
};
function CustomButton(direction: string, handleClick: any, disabled: boolean) {
  return (
    <i
      onClick={handleClick}
      style={{
        padding: "0 10px",
        fontWeight: "bold",
        color: disabled ? "gray" : "blue",
      }}
      className={disabled ? "cursor-default" : "cursor-pointer"}
    >
      {direction === "right" ? (
        <CalenderRightArrow style={{ cursor: "pointer" }} />
      ) : (
        <CalenderLeftArrow style={{ cursor: "pointer" }} />
      )}
    </i>
  );
}

const DateCalender = ({
  value,
  onChange,
  name,
  format = "YYYY-MM-DD",
  enabledDates = [],
  isMinDate,
  minDate,
  maxDate,
}: DateCalenderProps) => {
  const isDateEnabled = (date: Date | DateObject) => {
    if (enabledDates.length === 0) {
      return true;
    }
    const dateString =
      date instanceof Date
        ? date.toISOString().split("T")[0]
        : date.format("YYYY-MM-DD");
    return enabledDates.includes(dateString);
  };

  return (
    <Grid className="DateCalender">
      <Calendar
        value={value}
        onChange={onChange}
        format={format}
        data-custom-name={name}
        renderButton={CustomButton}
        className="rmdp-prime"
        showOtherDays
        monthYearSeparator=" "
        highlightToday={false}
        minDate={minDate}
        maxDate={maxDate}
        mapDays={({ date }) => {
          const isEnabled = isDateEnabled(date);
          return {
            disabled: !isEnabled,
            style: {
              cursor: isEnabled ? "pointer" : "not-allowed",
              opacity: isEnabled ? 1 : 0.5,
            },
          };
        }}
      />
    </Grid>
  );
};

export default DateCalender;
