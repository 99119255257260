import React from "react";
import { Grid, Typography } from "@mui/material";
import { billSummaryConst } from "../../../../constants/displayText";

type SummaryProps = {
  summary: any;
  handleSaveData?: () => void;
  setSummary: any;
  handleInputChange: any;
  summaryError?: any;
};

const Summary = ({ summary }: SummaryProps) => {
  const { goodsValue, taxAmount, roundedOff, netAmount, CGST, SGST, discount } =
    billSummaryConst;
  const summaryOne = [
    {
      name: goodsValue,
      value: summary.goods_value,
    },
    {
      name: CGST,
      value: summary.CGST,
    },
    {
      name: SGST,
      value: summary.SGST,
    },
  ];

  const summaryTwo = [
    {
      name: taxAmount,
      value: summary.tax_amount,
    },
    { name: "Bill Total", value: Number(summary?.bill_total) || 0 },
    // {
    //   key: discount,
    //   name: "Discount",
    //   value: Number(summary.bill_discount_amount) || 0,
    //   isSelectField: true,
    // },
    {
      name: roundedOff,
      value: summary.rounded_off,
    },
    {
      name: netAmount,
      value: Number(summary?.bill_amount) || 0,
    },
  ];
  const styles = {
    textFieldStyle: {
      height: "35px",
      fontSize: "6px",

      "& .MuiOutlinedInput-root": {
        height: "35px",
        paddingLeft: "10px",
        textAlign: "center",
        borderRadius: "5px",
        boxShadow: "none",
      },

      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },

    selectStyle: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
  };
  return (
    <Grid
      container
      sx={{
        width: { xs: "90vw", sm: "80vw", md: "90vw" },
        display: "flex",
        flexDirection: "column",
        m: "20px 0",
      }}
    >
      <Typography
        variant="h3"
        color="initial"
        sx={{
          fontWeight: "600",
          color: "primary.main",
          m: "20px 0",
        }}
      >
        Billing Details
      </Typography>
      <Grid
        container
        sx={{
          width: { xs: "90vw", sm: "80vw", md: "90vw", xl: "90vw" },
          borderRadius: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center", md: "flex-start" },
            justifyContent: { xs: "space-around" },
            border: 1,
            borderColor: "#D1D1D1",
            borderRadius: "5px",
            mr: {
              xs: "0px",
              sm: "0px",
            },
          }}
        >
          <Grid
            item
            sx={{
              width: {
                xs: "70%",
                sm: "50%",
                md: "30%",
                lg: "20%",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              m: "22px 0px 22px 0px",
            }}
          >
            {summaryOne.map((row, index) => {
              return (
                <Grid
                  key={index}
                  sx={{
                    width: {
                      xs: "100%",
                      lg: "100%",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    maxHeight: "35px",
                    mb: "16px",
                    color: "textPrimary.main",
                  }}
                  className="row"
                >
                  <Grid
                    sx={{
                      width: {
                        xs: "50%",
                        sm: "40%",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h4">{row.name}</Typography>
                    <Typography variant="h4">:</Typography>
                  </Grid>
                  <Grid
                    sx={{
                      width: {
                        xs: "50%",
                        sm: "40%",
                      },
                    }}
                  >
                    <Typography>
                      {row.name === SGST || row.name === CGST
                        ? row.value
                        : row.value.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid
            item
            sx={{
              width: {
                xs: "70%",
                sm: "50%",
                md: "35%",
                lg: "20%",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              m: "22px 0px 22px 0px",
            }}
          >
            {summaryTwo.map((row, index) => {
              return (
                <Grid
                  key={index}
                  sx={{
                    width: {
                      xs: "100%",
                      lg: "100%",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    maxHeight: "35px",
                    mb: "16px",
                    color: "textPrimary.main",
                  }}
                  className="row"
                >
                  <Grid
                    sx={{
                      width: {
                        xs: "50%",
                        sm: "40%",
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "400",
                      }}
                    >
                      {row.name}
                    </Typography>
                    <Typography variant="h4">:</Typography>
                  </Grid>
                  {/* {row?.isSelectField && (
                    <>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100px",
                          height: "35px",
                        }}
                      >
                        <TextField
                          name="bill_discount"
                          onChange={(e) => handleInputChange(e)}
                          value={summary.bill_discount}
                          height="35px"
                          sx={{
                            ...styles.textFieldStyle,
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "var(--primary-border-color)",
                                borderRadius: "5px 0px 0px 5px",
                              },
                            width: "60px",
                          }}
                          error={!!summaryError?.bill_discount}
                        />
                        <Grid
                          sx={{
                            height: "35px",
                            "& .MuiInputBase-root": {
                              padding: "0px",
                            },
                          }}
                        >
                          <Select
                            label=""
                            options={[
                              { id: "₹", name: "₹" },
                              { id: "%", name: "%" },
                            ]}
                            placeholder="%"
                            name="bill_discount_type"
                            onChange={(e) => handleInputChange(e)}
                            value={summary.bill_discount_type}
                            width="40px"
                            sx={{
                              height: "35px",
                              borderRadius: "0px 5px 5px 0px",
                              borderColor: "var(--primary-border-color)",
                              boxShadow: "none",
                              ...styles.selectStyle,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )} */}
                  <Typography
                    sx={{
                      width: "20%",
                      textAlign: "right",
                      fontWeight: "400",
                    }}
                  >
                    {row?.value?.toFixed(2)}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Summary;
