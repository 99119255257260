import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  debounce,
} from "@mui/material";
import { formula } from "../../../../utils/CalculationUtils";
import { useNavigate, useParams } from "react-router-dom";
import Info from "./info";
import Summary from "./Summary";
import {
  CancelButton,
  SaveButton,
  Button,
  PageLoader,
} from "../../../../components/basic";
import { goodsIssueConst } from "../../../../constants/displayText";
import ProductRow from "./ProductRow";
import {
  createLabGoodsIssue,
  getAllLabMasterDepartments,
  getLabGoodsIssueProduct,
  getLabGoodsIssueProductById,
  updateLabGoodsIssueById,
} from "../../../../services/laboratoryService";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartmentSequence,
  updatedSequence,
} from "../../../../services/mainCoreService";
import { RootState } from "../../../../redux/store";

import {SequenceWrapper } from "../../../../components/shared";

const BillProducts = () => {
  const {
    productName,
    pack,
    batchNo,
    expiryDate,
    usedQty,
    qty,
    mrp,
    discount,
    tax,
    amount,
  } = goodsIssueConst;

  const {
    calculateAmount,
    calculateTaxAmount,
    calculateRoundedOff,
    calculateNetAmount,
  } = formula.goodsIssue;
  const {
    calculateSubTaxes,
    calculateDiscountAmount,
    calculateTotalDiscountAmount,
  } = formula.purchaseBill;
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },

      selectStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "34px",
          maxHeight: "34px",
          p: 0,
        },

        "& .MuiInputBase-root": {
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },

        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );
  const [productRow, setProductRow] = useState([]);
  const [customSequence,setCustomSequence] = useState(true);
  const { id } = useParams();

  const TableHeaderCells: any = [
    { TableCellName: productName, alignment: "left" },
    // { TableCellName: pack, alignment: "right" },
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: expiryDate, alignment: "right" },
    ...(id ? [{ TableCellName: usedQty, alignment: "right" }] : []),
    { TableCellName: qty, alignment: "right" },
    { TableCellName: "Price", alignment: "right" },
    // { TableCellName: discount, alignment: "right" },
    { TableCellName: tax, alignment: "right" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  const initialBillProduct = {
    pack: null,
    qty: null,
    mrp: 0,
    id: 0,
    product_name: null,
    autoComplete_input_value: null,
    stock: null,
    free: null,
    ret_qty: null,
    f_ret_qty: null,
    discount: "",
    batch_no: "",
    expiry_date: "",
    tax: "",
    ptr: "",
    amount: null,
    discount_amount: null,
    tax_amount: null,
    stock_check: "",
  };

  const [billProducts, setBillProducts] = useState<any>([initialBillProduct]);
  const [issuerList, setIssuerList] = useState([]);
  const [receiverList, setReceiverList] = useState([]);
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const initialInfo: any = {
    bill_no: "",
    issuer: "",
    receiver: "",
  };
  const [batchIdNo, setBatchIdNo] = useState<number[] | []>([]);
  const [info, setInfo] = useState<{} | any>(initialInfo);

  const initialSummary = {
    total_discount_amount: 0,
    goods_value: 0,
    CGST: 0,
    SGST: 0,
    tax_amount: 0,
    rounded_off: 0,
    bill_amount: 0,
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const [summary, setSummary] = useState<{} | any>(initialSummary);
  const [formError, setFormError] = useState<
    {
      qty: boolean;
    }[]
  >([
    {
      qty: false,
    },
  ]);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  
  const [sequenceId, setSequenceId] = useState(null);


  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);


  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });


  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [errors, setErrors] = useState<any>([]);

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
  string | number | undefined
>("");



  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };


  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  let isCreateLabGoodsIsuue = true;
  let isSequenceNumberChanged = false;

  const getSequenceDetails = async () => {
    try {
      setIsSubmitLoaded(true);

      await getDepartmentSequence("Lab Goods Issue").then((res : any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreateLabGoodsIsuue && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            bill_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));
          setErrors((prevState: any) => ({
            ...prevState,
            bill_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setSequenceId(res.data[0].sequence.id);
          const newSequenceData =
          res?.data[0]?.sequence?.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
        setSequenceData(newSequenceData);
        setIsManual((prevState) => ({
          ...prevState,
          is_manual:
          res?.data[0]?.sequence?.is_manual === 1 ? true : false,
          is_auto_generate:
          res?.data[0]?.sequence?.is_manual !== 1 ? true : false,
        }));
        setSeqIsOnce((prevState) => ({
          ...prevState,
          isOnce: false,
          isOnceSeqValue: "",
        }));
        setIsManualAdded(Boolean(res?.data[0]?.sequence?.is_manual === 1));
  
          setIsSubmitLoaded(false);
        }
      });
      
    } catch (error : any) {
      setIsSubmitLoaded(false);
      console.error("An error occurred:", error);

      
    }
   
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== info.bill_no) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.bill_no,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const onCloseSequenceDialogBox = () => {
    setIsSequenceWrapperOpen(false);
  };


  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual.is_manual ? 1 : 0,
        };
        await updatedSequence(Number(sequenceId), data).then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const handleValidation = (
    currentValue: any,
    updatedValue: any,
    idx: number | string
  ) => {
    if (
      Number(currentValue?.qty) >
      Number(updatedValue.stock_qty / updatedValue.no_of_tests)
    ) {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["qty"] = true;
        return newState;
      });
    } else {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["qty"] = false;
        return newState;
      });
    }
  };
  const handleCalculateAmount = (product: any) => {
    let num = 0;
    if (isNaN(product?.qty) || isNaN(product?.product_name?.p_rate)) {
      return num;
    }
    if (product?.qty) {
      num = product.qty * product?.product_name?.p_rate;
      return num;
    }
    return num;
  };
  const handleInputChange = useCallback((e: any, index: number) => {
    const { name, value } = e.target;

    let numericValue = value.replace(/\D/g, "");

    setCurrentIndex(index as any);

    setBillProducts((prevState: any) => {
      const newState = [...prevState];

      newState[index as number] = {
        ...newState[index as number],
        [name]: numericValue,
      };
      handleValidation(
        newState[index as number],
        newState[index as number].product_name,
        index
      );
      newState[index as number] = {
        ...newState[index as number],
        amount: handleCalculateAmount(newState[index as number]),
      };
      newState[index as number] = {
        ...newState[index as number],
        discount_amount:
          calculateDiscountAmount(
            newState[index as number]?.amount || 0,
            newState[index as number]?.product_name?.discount || 0,
            newState[index as number]?.product_name?.discount_type
          ) || 0,
      };
      calculateBillData(newState);
      return newState;
    });
  }, []);




  const handleAutoCompleteChange = useCallback(
    (newValue: any, idx: number | string, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevState: any) => {
        let newState = [...prevState];
        if (newState.length > 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            id: newValue.id,
            mrp: newValue.p_rate ? newValue.p_rate : 0,
            discount_amount:
              calculateDiscountAmount(
                newState[idx as number]?.amount,
                newState[idx as number]?.newValue?.discount || 0,
                newState[idx as number]?.newValue?.discount_type
              ) || 0,
            expiry_date: newValue.expires_at,
            batch_no: `${newValue.batch_no}#${
              newValue.stock_qty / newValue.no_of_tests
            }`,
            amount: 0,
            discount: `${newValue.discount || 0}%`,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            pack: newValue.pack_type,
            barcode: newValue.barcode,
            batch_id: newValue.batch_id,
          };
        }
        if (newState.length === 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            pack: newValue.pack_type,
            id: newValue.id,
            expiry_date: newValue.expires_at,
            mrp: newValue.p_rate ? newValue.p_rate : 0,
            discount_amount:
              calculateDiscountAmount(
                newState[idx as number]?.amount,
                newState[idx as number]?.newValue?.discount || 0,
                newState[idx as number]?.newValue?.discount_type
              ) || 0,
            batch_no: `${newValue.batch_no}#${
              newValue.stock_qty / newValue.no_of_tests
            }`,
            amount: 0,
            discount: `${newValue.discount || 0}%`,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            barcode: newValue.barcode,
            batch_id: newValue.batch_id,
          };
        }
        setBatchIdNo((prevState: any) => {
          const filteredList = newState?.map(
            (ele: { batch_id: number }, idx: number) => ele?.batch_id
          );

          return filteredList;
        });
        // setBatchIdNo((prev) => [...prev, newValue?.batch_id]);
        return newState;
      });
    },
    []
  );
  const [billingSummary, setBillingSummary] = React.useState({});
  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      setIsDataTableLoading(true);
      getLabGoodsIssueProductById(id)
        .then((res: any) => {
          const purchaseBill = res.data.bill;
          const billProducts = res.data.bill_products;
          setIssuerList([
            {
              name: purchaseBill.from_dept_name,
              id: purchaseBill.from_dept_id,
            },
          ] as any);
          setReceiverList([
            {
              name: purchaseBill.to_dept_name,
              id: purchaseBill.to_dept_id,
            },
          ] as any);
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: purchaseBill?.invoice_no,
            issuer: purchaseBill?.from_dept_id,
            receiver: purchaseBill?.to_dept_id,
            date: formatTwelveHoursTime(purchaseBill?.created_at) || null,
          }));
          setInitialSequenceNumber(purchaseBill?.invoice_no);
          const data = billProducts.map((res: any) => ({
            id: res.id,
            product_name: res,
            batch_no: `${res?.batch_no}#${
              res.original_qty
                ? res?.stock_qty +
                  res?.original_qty -
                  res?.return_qty / res?.no_of_tests
                : res?.stock_qty + res?.no_of_tests
            }`,
            expiry_date: res.expires_at
              ? res?.expires_at.split("-").reverse().join("/")
              : "",
            qty: Number(res?.original_qty | 0) / Number(res?.no_of_tests | 0),
            mrp: res?.p_rate,
            pack: res?.pack_type,
            used_qty:
              res.adj_qty < 0
                ? Number(res?.sale_qty | 0) +
                  Number(res?.return_qty | 0) -
                  Number(res?.adj_qty | 0) / Number(res?.no_of_tests | 0)
                : Number(res?.sale_qty | 0) +
                  Number(res?.return_qty | 0) / Number(res?.no_of_tests | 0),
            discount: res?.discount || 0,
            bill_batch_id: res.batch_id,
            tax: `${res?.tax_id}%`,
            amount: res?.amount,
            isId: id,
            barcode: res.findRow,
          }));
          const newError = billProducts.map((ele: any) => ({
            qty: false,
          }));
          setFormError(newError);
          setBillProducts(data);
          setBillingSummary((prevState: any) => ({
            ...prevState,
            goods_value: purchaseBill?.orig_goods_val || 0.0,
            tax: purchaseBill?.gst_amt || 0.0,
            roundedOff: purchaseBill?.roundedOff || 0.0,
            netAmount: purchaseBill?.bill_amt || 0.0,
          }));
          calculateBillData(data);
          isCreateLabGoodsIsuue = false;
          isSequenceNumberChanged = false;
          getSequenceDetails();
          setIsDataTableLoading(false);
        })
        .catch((err: any) => {
          setIsDataTableLoading(false);
          console.log(err);
        });
    }else{
      isCreateLabGoodsIsuue = true;
      isSequenceNumberChanged = false;
      getSequenceDetails();
    }
  }, [id]);

  useEffect(() => {
    if (info.issuer) {
      const data: any = {
        department: info.issuer,
      };
      getLabGoodsIssueProduct(data)
        .then((res: any) => setProductRow(res.data))
        .catch((err: any) => console.log(err));
    }
  }, [info.issuer]);
  useEffect(()=>{
    if(appConfiguration?.custom_sequence === false){
      setCustomSequence((prev)=> prev = false);
    }
  },[appConfiguration]);
  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProduct,
        id: prev.length,
      },
    ]);
    setFormError((prev) => [...prev, { qty: false }]);
  }, []);

  const handleDelete = useCallback(
    (id: number, batchNo?: string) => {
      const newBillProducts = [...billProducts];
      newBillProducts.splice(id, 1);
      setBillProducts(newBillProducts);

      const newFormError = [...formError];
      newFormError.splice(id, 1);
      setFormError(newFormError);
      if (batchNo) {
        const newBatchIds = [...batchIdNo];
        const findBatchIndex = batchIdNo.findIndex(
          (ele) => ele === Number(batchNo)
        );
        newBatchIds.splice(findBatchIndex, 1);
        setBatchIdNo(newBatchIds);
      }
    },
    [batchIdNo, billProducts, formError]
  );

  const updateBillData = () => {
    const roundedOff = calculateRoundedOff(summary);
    const netAmount = calculateNetAmount(summary);
    if (
      roundedOff !== summary.rounded_off ||
      netAmount !== summary.bill_amount
    ) {
      setSummary((prevBillData: any) => ({
        ...prevBillData,
        rounded_off: roundedOff,
        bill_amount: netAmount,
      }));
    }
  };

  const calculateBillData = (allProducts: any) => {
    const newValues =
      allProducts?.length > 0 &&
      allProducts.map((ele: any) => {
        const newNum = ele.qty * ele.mrp;
        const newGoodsValue =
          appConfiguration?.lab_goods_issue_tax === "exclusive"
            ? newNum
            : newNum / (1 + ele.product_name?.tax_id / 100);
        const newTaxValue = (ele.product_name?.tax_id / 100) * newGoodsValue;
        return {
          newNum: newNum,
          newGoodsValue: newGoodsValue,
          newTaxValue: newTaxValue,
          tax: {
            id: ele.product_name?.id,
            name: ele.tax,
            tax_name: ele.product_name?.taxname,
            tax_rate: ele.product_name?.tax_id,
            sub_taxes: ele.product_name?.subtax,
          },
          tax_amount: newTaxValue,
        };
      });

    const total_discount_amount =
      calculateTotalDiscountAmount(allProducts) || 0;
    const value = calculateSubTaxes(newValues) || 0;
    setSummary((prevState: any) => ({
      ...prevState,
      goods_value: calculateProductAmount(newValues, "newGoodsValue"),
      tax_amount: calculateProductAmount(newValues, "newTaxValue"),
      bill_amount: calculateProductAmount(newValues, "newNum"),
      CGST: value.CGST,
      SGST: value.SGST,
      total_discount_amount: total_discount_amount,
    }));
  };

  const calculateProductAmount = (rows: any, name: string) => {
    const updatedValue = rows?.reduce(
      (acc: any, current: any) => acc + current[name],
      0
    );
    return updatedValue || 0;
  };
  const updateRows = (currentIndex: number) => {
    const amount = calculateAmount(billProducts, currentIndex);
    const discountAmount = calculateDiscountAmount(billProducts, currentIndex);
    const taxAmount = calculateTaxAmount(billProducts, currentIndex);

    if (
      amount !== +billProducts[currentIndex]?.amount ||
      taxAmount !== billProducts[currentIndex]?.tax_amount ||
      discountAmount !== billProducts[currentIndex]?.discount_amount
    ) {
      setBillProducts((prev: any) =>
        prev.map((billProduct: any, i: number) => {
          return i === currentIndex
            ? {
                ...billProduct,
                amount: amount,
                discount_amount: discountAmount,
                tax_amount: taxAmount,
              }
            : billProduct;
        })
      );
    }
  };

  const getAllDepartmentList = async () => {
    const data = { active: 1 };
    await getAllLabMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data?.result?.map(
          (ele: { dept_name: string; id: number; type: string }) => ({
            name: ele.dept_name,
            id: ele.id,
            type: ele.type,
          })
        );
        const newIssuerList = formattedValue?.filter(
          (ele: { dept_name: string; id: number; type: string }) =>
            ele?.type !== "B2B" && ele?.type === "issuer"
        );
        const newReceiverList = formattedValue?.filter(
          (ele: { dept_name: string; id: number; type: string }) =>
            ele?.type !== "B2B" && ele?.type === "receiver"
        );
        setIssuerList(newIssuerList);
        setReceiverList(newReceiverList);
      })
      .catch((err: any) => console.log(err));
  };
  useEffect(() => {
    getAllDepartmentList();
  }, []);

  useEffect(() => {
    updateRows(currentIndex);
  }, []);

  useEffect(() => {
    updateBillData();
  }, [billProducts]);

  const validateForm = (details: any) => {
    let validate = true;

    if (details.issuer === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Supplier not to be empty!" })
      );
      validate = false;
    }

    if (details.receiver === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "receiver not to be empty!" })
      );
      validate = false;
    }
    // if (details.invoice_value === "") {
    //   dispatch(
    //     setSnackBarFailed({ snackBarMessage: "Invoice value not to be empty!" })
    //   );
    //   validate = false;
    // }
    if (Boolean(billProducts.find((ele: any) => !ele.product_name))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please choose product first!" })
      );
      validate = false;
    }
    if (Boolean(billProducts.find((ele: any) => !ele.qty))) {
      dispatch(setSnackBarFailed({ snackBarMessage: "Please Add Qty!" }));
      validate = false;
    }
    if (Boolean(formError.find((ele) => ele.qty))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    return validate;
  };
  const handleSubmit = async () => {
    if (validateForm(info)) {
      setIsSubmitLoaded(true);

      const productDetails: any =
        billProducts.length > 0
          ? billProducts.map((obj: any) => {
              let newObj = {};
              const {
                product_id,
                pack_type,
                discount,
                bill_id,
                expires_at,
                tax_id,
                tax_type,
                supplier_id,
                no_of_tests,
                batch_no,
                mrp,
                p_rate,
              } = obj.product_name;
              newObj = {
                product_id,
                pack_type,
                expires_at,
                qty: obj.qty,
                discount,
                supplier_id,
                tax_id,
                tax_type,
                mrp,
                no_of_tests,
                p_rate,
                amount: obj.amount,
                raw_batch_no: batch_no,
              };
              if (id) {
                const { id } = obj.product_name;
                newObj = { ...newObj, id, batch_no: bill_id };
              }
              if (!id) {
                newObj = { ...newObj, id, batch_no: bill_id };
              }
              return newObj;
            })
          : 0;
      const data = {
        from_dept: info.issuer,
        to_dept: info.receiver,
        invoice_no: info.bill_no,
        invoice_no_modified:info.bill_no === initialSequenceNumber ? 0 : 1,
        product: [...productDetails],
        bill_amt: summary.bill_amount,
        orig_goods_val: summary.goods_value.toFixed(2),
        disc_goods_val: summary.goods_value.toFixed(2),
        disc_val: 0,
        gst_amt: summary.tax_amount,
      };
      if (id) {
        updateLabGoodsIssueById(id as string, data as any)
          .then((res: any) => {
            navigate("/laboratory/lab-goods-issue");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            dispatch(
              setSnackBarFailed({ snackBarMessage: err.response.data.errors })
            );
            setIsSubmitLoaded(false);
            console.log(err);
          });
      } else {
        await createLabGoodsIssue(data as any)
          .then((res: any) => {
            navigate("/laboratory/lab-goods-issue");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            dispatch(
              setSnackBarFailed({ snackBarMessage: err.response.data.errors })
            );
            setIsSubmitLoaded(false);
            console.log(err);
          });
      }
    }
  };

  const handleBarcodeOnChange = useCallback(
    (barcodeVal: string | number) => {
      if (!info.issuer || !info.receiver) {
        return;
      }
      const isExist = billProducts.find(
        (ele: { barcode: number }) => ele.barcode === barcodeVal
      );
      if (isExist) {
        const findRow: any = productRow.find(
          (ele: { barcode: number }) => ele.barcode === barcodeVal
        );
        const findRowIndex: any = productRow.findIndex(
          (ele: { barcode: number }) => ele.barcode === barcodeVal
        );
        if (findRow !== undefined && findRowIndex !== -1) {
          setBillProducts((prevState: any) => {
            let newState = [...prevState];
            if (newState.length > 1) {
              newState[findRowIndex] = {
                ...newState[findRowIndex],
                product_name: findRow,
                pack: findRow.pack_type,
                id: findRow.id,
                expiry_date: findRow.expires_at,
                mrp: findRow.p_rate ? findRow.p_rate : 0,
                discount_amount:
                  calculateDiscountAmount(
                    newState[findRowIndex]?.amount,
                    newState[findRowIndex]?.product_name?.discount || 0,
                    newState[findRowIndex]?.product_name?.discount_type
                  ) || 0,
                batch_no: `${findRowIndex.batch_no}#${
                  findRowIndex.stock_qty / findRowIndex.no_of_tests
                }`,
                amount: 0,
                discount: `${findRow.discount || 0}%`,
                tax: `${findRow.taxname}-${findRow.tax_id}%`,
                barcode: findRow.barcode,
              };
            }
            if (newState.length === 1) {
              newState[findRowIndex] = {
                ...newState[findRowIndex],
                product_name: findRow,
                pack: findRow.pack_type,
                id: findRow.id,
                expiry_date: findRow.expires_at,
                mrp: findRow.p_rate ? findRow.p_rate : 0,
                discount_amount:
                  calculateDiscountAmount(
                    newState[findRowIndex]?.amount,
                    newState[findRowIndex]?.product_name?.discount || 0,
                    newState[findRowIndex]?.product_name?.discount_type
                  ) || 0,
                batch_no: `${findRowIndex.batch_no}#${
                  findRowIndex.stock_qty / findRowIndex.no_of_tests
                }`,
                amount: 0,
                discount: `${findRow.discount || 0}%`,
                tax: `${findRow.taxname}-${findRow.tax_id}%`,
                barcode: findRow.barcode,
              };
            }

            return newState;
          });
          handleInputChange(
            {
              target: {
                name: "qty",
                value: `${Number(billProducts[findRowIndex].qty) + 1}`,
              },
            },
            findRowIndex
          );
        }
      }
      if (!isExist) {
        const findRow: any = productRow.find(
          (ele: { barcode: number }) => ele.barcode === barcodeVal
        );
        const findRowIndex = productRow.findIndex(
          (ele: { barcode: number }) => ele.barcode === barcodeVal
        );
        setBillProducts((prevState: any) => {
          let newState = [...prevState];
          if (newState.length > 1) {
            newState[findRowIndex] = {
              ...newState[findRowIndex],
              product_name: findRow,
              id: findRow.id,
              mrp: handleCalculateAmount({
                qty: 1,
                product_name: {
                  pack_type: findRow.pack_type,
                  mrp: findRow.mrp,
                },
              }),
              discount_amount:
                calculateDiscountAmount(
                  newState[findRowIndex]?.amount,
                  newState[findRowIndex]?.product_name?.discount || 0,
                  newState[findRowIndex]?.product_name?.discount_type
                ) || 0,
              expiry_date: findRow.expires_at,
              batch_no: `${findRow.batch_no}#${findRow.original_qty}`,
              amount: 0,
              discount: `${findRow.discount || 0}%`,
              tax: `${findRow.taxname}-${findRow.tax_id}%`,
              pack: findRow.pack_type,
              barcode: findRow.barcode,
            };
          }
          if (newState.length === 1) {
            newState[findRowIndex] = {
              ...newState[findRowIndex],
              product_name: findRow,
              pack: findRow.pack_type,
              id: findRow.id,
              expiry_date: findRow.expires_at,
              mrp: handleCalculateAmount({
                qty: 1,
                product_name: {
                  pack_type: findRow.pack_type,
                  mrp: findRow.mrp,
                },
              }),
              discount_amount:
                calculateDiscountAmount(
                  newState[findRowIndex]?.amount,
                  newState[findRowIndex]?.product_name?.discount || 0,
                  newState[findRowIndex]?.product_name?.discount_type
                ) || 0,
              batch_no: `${findRow.batch_no}#${findRow.original_qty}`,
              amount: 0,
              discount: `${findRow.discount || 0}%`,
              tax: `${findRow.taxname}-${findRow.tax_id}%`,
              barcode: findRow.barcode,
            };
          }

          return newState;
        });
        handleInputChange(
          {
            target: {
              name: "qty",
              value: `${Number(billProducts[findRowIndex].qty) + 1}`,
            },
          },
          findRowIndex
        );
      }

      setInfo((prevState: any) => ({ ...prevState, barcode: "" }));
    },
    [billProducts, productRow, handleInputChange, calculateDiscountAmount]
  );

  const debouncedHandleBarcodeOnChange = debounce(handleBarcodeOnChange, 300);
  return (
    <>
      {isDataTableLoading ? (
        <PageLoader />
      ) : (
    <Grid sx={{ width: "92vw" }}>
      <Info
        info={info}
        setInfo={setInfo}
        issuerList={issuerList}
        receiverList={receiverList}
        handleBarcodeOnChange={debouncedHandleBarcodeOnChange}
        setIsSequenceWrapperOpen={setIsSequenceWrapperOpen}
        isManualAdded={isManualAdded}
        isManual={isManual}
        setIsManual={setIsManual}
        errors={errors}
        handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
        customSequence={customSequence}
      />
      <Grid
        sx={{
          width: {
            xs: "92vw",
          },
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflowX: { md: "scroll", lg: "hidden" },
        }}
      >
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
          }}
        >
          <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {TableHeaderCells.map((cell: any, index: number) => {
                  return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                      {cell.TableCellName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                height: "auto",
                width: {
                  xs: "400vw",
                  sm: "200vw",
                  md: "120vw",
                  lg: "90vw",
                },
              }}
            >
              {billProducts.map((row: any, index: number) => {
                return (
                  <ProductRow
                    key={row.id}
                    index={index}
                    row={row}
                    billId={id}
                    styles={styles}
                    allRows={productRow}
                    formError={formError}
                    handleInputChange={handleInputChange}
                    handleDelete={handleDelete}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Grid
            sx={{
              width: {
                xs: "400vw",
                sm: "200vw",
                md: "120vw",
                lg: "90vw",
              },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "0px 0px 5px 5px",
              border: "1px solid",
              borderColor: "var(--table-border)",
              borderTop: 0,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: {
                  xs: "39%",
                  lg: "39%",
                },
              }}
            >
              <Button
                variant="text"
                buttonText="+ Add Product"
                handleClick={handleClick}
                sx={{
                  borderRadius: "0px",
                  width: "auto",
                  height: "auto",
                  fontWeight: 400,
                  fontSize: "13px",
                  border: 0,
                  color: "primary.main",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "primary.main",
                    borderColor: "initial",
                  },
                  "&.Mui-focusVisible": {
                    border: 1,
                    borderColor: "primary.main",
                    borderRadius: "5px",
                  },
                }}
              />
              <Grid sx={{ display: "flex" }}>
                <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                  Discount Value :
                </Typography>
                <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                  {summary?.total_discount_amount.toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              sx={{ fontSize: "13px", fontWeight: "600", mr: "10.6%" }}
            >
              Goods Value :{summary?.goods_value.toFixed(2)}
            </Typography>
          </Grid>
        </TableContainer>
      </Grid>
      <Summary
        summary={summary}
        setSummary={setSummary}
        handleSaveData={() => {}}
      />
          <Grid
            sx={{ display: "flex", flexDirection: "row", m: "35px 0 45px 0" }}
          >
        <SaveButton
          handleClick={() => handleSubmit()}
          loading={isSubmitLoaded}
          sx={{ width: "100px", height: "42px", mr: "20px" }}
        />
        <CancelButton sx={{ width: "100px", height: "42px" }} />
      </Grid>

      <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="Lab Goods Issue"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
    </Grid>
      )}
    </>
  );
};

export default BillProducts;
