import { TextField } from "../../../../../components/basic";
type FollowUpDetailsType = {
  id: number | string;
  patient_id: number | string;
  patient_name: number | string;
  antenatal_card_id: number | string;
  date_of_visit: number | string;
  trimester_week: number | string;
  vitals: [
    {
      vital_id?: string;
      vital_date?: string;
      vital_name?: string;
      vital_unit?: string;
      vital_value?: number | string;
    }
  ];
  findings: [];
  tests: [];
  scans: [];
  notes: null;
  next_review_date: null;
  trimester: string;
  suggestions: [];
};
export default function index({
  lastFollowUpDetails,
}: {
  lastFollowUpDetails: FollowUpDetailsType;
}) {
  return (
    <TextField
      name="notes"
      value={
        lastFollowUpDetails?.notes
          ? lastFollowUpDetails?.notes
          : "Currently, the patient doesn't have any notes!"
      }
      disabled
      width="100%"
      fullWidth
    />
  );
}
