import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  debounce,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
} from "@mui/material";
import { Button, PageLoader } from "../../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import Summary from "./Summary";
import { billProductsConst } from "../../../../constants/displayText";

import { formula } from "../../../../utils/CalculationUtils";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { setSnackBarSuccess,setSnackBarFailed } from "../../../../redux/slices/snackbar";
import { DeleteAlertIcon } from "../../../../assets/icons";
import moment from "moment";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";
import { RouteUrls } from "../../../../constants/routes";
import {
  // getExcessAmountByPatientId,
  getProductByPoNo,
  getTaxOptions,
  
} from "../../../../services/procurementService";

import { updatedSequence } from "../../../../services/procurementMasterService";

import {createPurchaseBill,updatePurchaseBillById,getAllLaboratoryProducts,getLabPurchaseBillById,getDepartmentSequence} from "../../../../services/laboratoryService"

import {SequenceWrapper } from "../../../../components/shared";



const { laboratoryUrl, labPurchaseUrl } = RouteUrls;
type BillProduct = {
  id?: number | null;
  product_id: number | null;
  no_of_tests: string;
  qty: number | null;
  free: number | null;
  discount: number | null;
  row_discount_type: string | null;
  batch_no: string | null;
  barcode: string | null;
  tax_type: number | null;
  p_rate: number | null;
  mrp: number | null;
  amount: number | null;
  product_name: number | null;
  autocomplete_input_value: string | number | null;
  expires_at: Date | string | null;
  discount_amount: number | null;
  tax_amount: number | null;
  used_qty: number | string;
  used_free: number | string;
  original_qty: number | null;
};

export default function BillProducts() {
  const {
    productName,
    usedQty,
    usedFree,
    qty,
    free,
    discount,
    batchNo,
    expiryDate,
    tax,
    ptr,
    mrp,
    amount,
  } = billProductsConst;
  const navigate = useNavigate();

  const {
    calculateTotalDiscountAmount,
    calculateDiscountAmount,
    calculateTaxAmount,
    calculateGoodsValue,
    calculateSubTaxes,
    calculateAmount,
    calculateBillTotal,
    calculateBillDataDiscountAmount,
    calculateRoundedOff,
    calculateNetAmount,
    calculateTotalTaxAmount,
  } = formula.purchaseBill;

  const dispatch = useDispatch();

  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: "92vw",
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },

        "& .MuiTableCell-root": {
          minHeight: "0px",
        },

        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },

        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },

        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },

        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },
      dialogTitle: { p: "20px 24px" },
      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },
    }),
    []
  );

  const { id } = useParams();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const TableHeaderCells: any = [
    { TableCellName: productName, alignment: "left" },
    ...(id ? [{ TableCellName: usedQty, alignment: "right" }] : []),
    ...(id ? [{ TableCellName: usedFree, alignment: "right" }] : []),
    /* We will add these columns in the future */
    // { TableCellName: barCode, alignment: "left" },
    // { TableCellName: packType, alignment: "right" },
    // { TableCellName: originalQty, alignment: "right" },
    { TableCellName: qty, alignment: "right" },
    { TableCellName: free, alignment: "right" },
    ...(appConfiguration?.lab_purchase_discount !== "overall" ||
    appConfiguration?.lab_purchase_discount === "both"
      ? [{ TableCellName: discount, alignment: "left" }]
      : []),
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: expiryDate, alignment: "left" },
    { TableCellName: tax, alignment: "left" },
    { TableCellName: ptr, alignment: "left" },
    { TableCellName: mrp, alignment: "right" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  const [taxOptions, setTaxOptions] = useState<any>([]);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [deletedIds, setDeletedId] = useState<string[] | number[]>([]);
  const [customSequence,setCustomSequence] = useState(true);

  const initialBillProduct = {
    product_id: null,
    no_of_tests: "",
    qty: null,
    free: null,
    discount: null,
    row_discount_type: "₹",
    batch_no: "",
    barcode: "",
    tax_type: null,
    expires_at: null,
    p_rate: null,
    mrp: null,
    amount: null,
    product_name: null,
    autocomplete_input_value: "",
    used_qty: "",
    used_free: "",
    original_qty: null,
    discount_amount: null,
    tax_amount: null,
  };

  const [billProducts, setBillProducts] = useState<BillProduct[]>([
    initialBillProduct,
  ]);

  const initialSummary = {
    total_discount_amount: null,
    goods_value: null,
    disc_goods_val: null,
    CGST: null,
    SGST: null,
    total_tax: null,
    bill_total: null,
    bill_discount: null,
    bill_discount_type: "₹",
    bill_discount_amount: null,
    rounded_off: null,
    adjustment: null,
    net_amount: null,
    payment_method: "Cash",
    cash_paid: null,
    amount_paid: null,
    balance: null,
    refunded_amount: null,
    excess_amount: null,
    net_excess: null,
  };

  const [summary, setSummary] = useState<any>(initialSummary);

  const initialInfoError = {
    supplier_id: "",
    dept_id: "",
    invoice_no: "",
    invoice_value: "",
    invoice_date: "",
    ref_no: "",
    due_date: "",
    po_no: "",
  };

  const [infoError, setInfoError] = useState<any>(initialInfoError);
  const [productError, setProductErrors] = useState<any>([]);
  const [summaryError, setSummaryErrors] = useState<any>({});

  const initialInfoData = {
    supplier_id: null,
    dept_id: null,
    department: "",
    to_dept: null,
    invoice_no: null,
    invoice_value: null,
    invoice_date: new Date(),
    ref_no: null,
    ref_no_modified: 0,
    due_date: null,
    po_no: null,
  };

  const [infoData, setInfoData] = useState<any>(initialInfoData);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<{
    isOpen: boolean;
    content: string;
  }>({
    isOpen: false,
    content: "",
  });

  const fieldData = {
    supplier_id: {
      label: "Supplier Name",
      name: "supplier_id",
      value: infoData.supplier_id,
      placeholder: "Select Supplier",
      isError: infoError.supplier_id === "" ? false : true,
      helperText: infoError.supplier_id,
      isDisabled: id ? true : false,
    },
    dept_id: {
      label: "Department",
      name: "dept_id",
      value: infoData.dept_id,
      placeholder: "Select Department",
      isError: infoError.dept_id === "" ? false : true,
      helperText: infoError.dept_id,
      isDisabled: id ? true : false,
    },
    ...(appConfiguration?.lab_goods_issue_disable
      ? {
          to_dept: {
            label: "Receiver",
            name: "to_dept",
            value: infoData.to_dept,
            placeholder: "Select Receiver",
            isError: infoError.to_dept === "" ? false : true,
            helperText: infoError.to_dept,
            isDisables: false,
          },
        }
      : {}),
    invoice_no: {
      label: "Invoice No",
      name: "invoice_no",
      value: infoData.invoice_no,
      placeholder: "Enter Invoice No",
      isError: infoError.invoice_no === "" ? false : true,
      helperText: infoError.invoice_no,
      isDisables: false,
    },
    invoice_value: {
      label: "Invoice Value",
      name: "invoice_value",
      value: infoData.invoice_value,
      placeholder: "Enter Invoice Value",
      isError: infoError.invoice_value === "" ? false : true,
      helperText: infoError.invoice_value,
      isDisables: false,
    },
    invoice_date: {
      label: "Invoice Date",
      name: "invoice_date",
      value: infoData.invoice_date,
      placeholder: "Enter Invoice Date",
      isError: infoError.invoice_date === "" ? false : true,
      helperText: infoError.invoice_date,
      isDisables: false,
    },
    ref_no: {
      label: "Bill Sequence Number",
      name: "ref_no",
      value: infoData.ref_no,
      placeholder: "Enter Bill Sequence Number",
      isError: infoError.ref_no === "" ? false : true,
      helperText: infoError.ref_no,
      isDisables: false,
    },
    due_date: {
      label: "Payment Due Date",
      name: "due_date",
      value: infoData.due_date,
      placeholder: "Payment due date",
      isError: infoError.due_date === "" ? false : true,
      helperText: infoError.due_date,
      isDisables: false,
    },
    po_no: {
      label: "PO No",
      name: "po_no",
      value: infoData.po_no,
      placeholder: "Enter PO Number",
      isError: infoError.po_no === "" ? false : true,
      helperText: infoError.po_no,
      isDisabled: id ? true : false,
    },
  };

  
  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [sequenceId, setSequenceId] = useState(null);

  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);

  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  // sequence number generator functions

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;

  const getSequenceDetails = async () => {
    try {
      await getDepartmentSequence("Lab Purchase Bill").then((result: any) => {
        let data = result?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfoData((prevState: any) => ({
            ...prevState,
            ref_no: data,
          }));

          setInfoError((prevState: any) => ({
            ...prevState,
            ref_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
       
          setInfoData((prevState: any) => ({
            ...prevState,
            ref_no: data,
          }));
          setInfoError((prevState: any) => ({
            ...prevState,
            ref_no: "",
          }));
        }

        if (result?.data?.length > 0) {
          setSequenceId(result.data[0].sequence.id);
          const newSequenceData =
            result?.data[0]?.sequence?.sequence_preferences.map(
              (ele: {
                id: number;
                type: string;
                value: string;
                separator: string;
              }) => ({
                id: ele.id,
                type: ele.type,
                value: ele.value,
                separator: ele.separator,
              })
            );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual:
              result?.data[0]?.sequence?.is_manual === 1 ? true : false,
            is_auto_generate:
              result?.data[0]?.sequence?.is_manual !== 1 ? true : false,
          }));
          setSeqIsOnce((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(result?.data[0]?.sequence?.is_manual === 1));
        }
       
      });
    } catch (error: any) {
   
      console.error("An error occurred:", error);
    }
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== infoData.ref_no) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: infoData.ref_no,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  const onCloseSequenceDialogBox = () => {
    setIsSequenceWrapperOpen(false);
  };

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual.is_manual ? 1 : 0,
        };
        await updatedSequence(Number(sequenceId), data).then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };


  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "supplier_id":
      case "dept_id":
      case "invoice_no":
      case "invoice_value":
      case "invoice_date": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setInfoError
          );
        } else {
          updateFormDataWithHelperText(name, "", setInfoError);
        }
        break;
      }
      default:
        break;
    }
  };

  const getProductByPoNumber = () => {
    if (infoData.po_no && !id) {
      getProductByPoNo(infoData.po_no)
        .then((res : any) => {
          if (res.data) {
            setBillProducts([
              {
                ...initialBillProduct,
                product_id: res?.data?.id,
                no_of_tests: res?.data?.items[0]?.product?.no_of_tests,
                product_name: res?.data?.items[0]?.product,
              },
            ]);
            setAllProducts([res?.data?.items[0]?.product]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getProductByPoNumber();
  }, [infoData.po_no]);


  const handleInputChange = useCallback((e: any, index: number, row?: any) => {
    const { name, value } = e.target;

    let numericValue = value;

    if (name !== "batch_no" && name !== "row_discount_type" && name !== "tax") {
      numericValue = +e?.target?.value?.replace(/[^\d.]/g, "");
    }

    setBillProducts((prev) => {
      const newBillProducts = prev.map((billProduct, i) => {
        const newBillProduct: any = { ...billProduct };

        if (i === index) {
          newBillProduct[name] = numericValue;

          if (name === "qty") {
            newBillProduct.original_qty =
              newBillProduct.no_of_tests *
              (name === "qty" ? numericValue : billProduct.qty);
          }
          if (newBillProduct.p_rate) {
            newBillProduct.discount_amount =
              calculateDiscountAmount(newBillProduct, index) || 0;
            newBillProduct.amount = calculateAmount(newBillProduct, index);
            newBillProduct.tax_amount =
              Number(
                calculateTaxAmount(
                  newBillProduct,
                  !!id
                    ? newBillProduct.tax_settings === 1
                      ? "exclusive"
                      : "inclusive"
                    : appConfiguration?.purchase_tax
                )
              ) || 0;
          }

          if (name === "tax") {
            newBillProduct.tax_id = value.tax_rate;
            newBillProduct.tax_type = value.id;
            newBillProduct.tax = value;
          }

          return newBillProduct;
        } else {
          return newBillProduct;
        }
      });

      setSummary((prev: any) => {
        const newState = { ...prev };
        newState.sub_taxes = calculateSubTaxes(newBillProducts) || 0;
        newState.total_discount_amount =
          calculateTotalDiscountAmount(newBillProducts) || 0;
        newState.goods_value = calculateGoodsValue(newBillProducts) || 0;
        newState.disc_goods_val = calculateGoodsValue(newBillProducts) || 0;
        newState.total_tax = calculateTotalTaxAmount(newBillProducts) || 0;
        newState.bill_total = calculateBillTotal(newState);
        newState.bill_discount_amount =
          calculateBillDataDiscountAmount(newState);
        newState.rounded_off = calculateRoundedOff(newState);
        newState.net_amount = calculateNetAmount(newState);
        return newState;
      });
      return newBillProducts;
    });

    if (name === "product_value") {
      if (!!id) {
        debouncedGetAllProcurementProductsData({
          department: infoData.dept_id ? infoData.dept_id : infoData.to_dept,
          search: value,
          start: 1,
          length: 20,
        });
      } else {
        debouncedGetAllProcurementProductsData({
          department: appConfiguration?.lab_goods_issue_disable
            ? infoData.to_dept
            : infoData.dept_id,
          search: value,
          start: 1,
          length: 20,
        });
      }
    }

    setProductErrors((prevState: any) => {
      const newState = [...prevState];
      if (value && newState.length > 0 && newState[index]) {
        newState[index][name] = "";
      }
      return newState;
    });
  }, []);

  const handleChangeSummary = (e: any) => {
    const { name, value } = e.target;
    let numericValue = value;
    if (name !== "payment_method" && name !== "bill_discount_type") {
      numericValue = value.replace(/\D/g, "");
    }

    setSummary((prev: any) => {
      const newState = { ...prev };
      newState[name] = numericValue;
      if (
        name === "adjustment" ||
        name === "bill_discount" ||
        name === "bill_discount_type"
      ) {
        newState.bill_discount_amount =
          calculateBillDataDiscountAmount(newState);
        newState.rounded_off = calculateRoundedOff(newState);
        newState.net_amount = calculateNetAmount(newState);
      }

      if (name === "cash_paid") {
        newState.balance = newState.net_amount - numericValue;
        newState.amount_paid =
          newState.net_amount > numericValue
            ? numericValue
            : newState.net_amount;

        if (numericValue > newState.net_amount) {
          newState.excess_amount = numericValue - newState.net_amount;
          newState.balance = 0;
          newState.net_excess =
            newState.excess_amount - newState.refunded_amount;
        } else {
          newState.excess_amount = 0;
          newState.net_excess = 0;
        }
      }

      if (name === "cash_paid" || name === "payment_method") {
        if (
          newState.payment_method === "Excess" &&
          newState.cash_paid > newState.excess
        ) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            cash_paid: "Paid amount is not exceeded than the excess",
          }));
        } else if (
          newState.payment_method === "Advance" &&
          newState.cash_paid > newState.advance
        ) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            cash_paid: "Paid amount is not exceeded than the Advance",
          }));
        } else {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            cash_paid: "",
          }));
        }

        if (newState.refunded_amount > newState.excess_amount) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "refunded amount is greater than excess amount",
          }));
        } else {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "",
          }));
        }
      }

      if (name === "refunded_amount") {
        newState.refunded_amount = numericValue;
        newState.net_excess = newState.excess_amount - newState.refunded_amount;

        if (numericValue > newState.excess_amount) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "refunded amount is greater than excess amount",
          }));
        } else {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "",
          }));
        }
      }

      return {
        ...newState,
      };
    });
  };

  const handleDateChange = useCallback(
    (index: any, newValue: any, name: any) => {
      setBillProducts((prevBillProduct: any) =>
        prevBillProduct.map((row: any, i: number) =>
          i === index ? { ...row, [name]: newValue.validatedValue[0] } : row
        )
      );

      setProductErrors((prevState: any) => {
        const newState = [...prevState];
        if (
          newValue?.validatedValue[0] &&
          newState.length > 0 &&
          newState[index]
        ) {
          newState[index][name] = "";
        }

        return newState;
      });
    },
    []
  );

  const handleAutoCompleteChange = useCallback(
    (newValue: any, index: number, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevBillProduct: any) =>
        prevBillProduct.map((billProduct: any, i: number) => {
          return i === index
            ? {
                ...billProduct,
                [name]: newValue,
                product_id: newValue?.id,
                no_of_tests: newValue.no_of_tests,
              }
            : billProduct;
        })
      );
      setProductErrors((prevState: any) => {
        const newState = [...prevState];
        if (newValue && newState.length > 0 && newState[index]) {
          newState[index][name] = "";
        }

        return newState;
      });
    },
    []
  );

  const handleClick = useCallback(() => {
    setBillProducts((prevBillProduct: any) => [
      ...prevBillProduct,
      {
        ...initialBillProduct,
      },
    ]);
  }, []);

  const handleDelete = useCallback(
    (index: number, id: number | string) => {
  

      setBillProducts((prevBillProduct) => {
        const newBillProducts = prevBillProduct.filter((ele, i) => index !== i);
    

    

        setSummary((prev: any) => {
          const newState = { ...prev };
          newState.sub_taxes = calculateSubTaxes(newBillProducts) || 0;
          newState.total_discount_amount =
            calculateTotalDiscountAmount(newBillProducts) || 0;
          newState.goods_value = calculateGoodsValue(newBillProducts) || 0;
          newState.disc_goods_val = calculateGoodsValue(newBillProducts) || 0;
          newState.total_tax = calculateTotalTaxAmount(newBillProducts) || 0;
          newState.bill_total = calculateBillTotal(newState);
          newState.bill_discount_amount =
            calculateBillDataDiscountAmount(newState);
          newState.rounded_off = calculateRoundedOff(newState);
          newState.net_amount = calculateNetAmount(newState);
          return newState;
        });

        return newBillProducts;
      });

      if (id) {
        setDeletedId((prevState: any) => [...prevState, id]);
      }
    },
    [billProducts]
  );

  const handleOverAllValidation = (info: any, products: any, summary: any) => {
    const infoRequiredFields = [
      "supplier_id",
      "invoice_no",
      "invoice_value",
      "invoice_date",
      "ref_no",
      "due_date",
    ];
    infoRequiredFields.push(
      appConfiguration?.lab_goods_issue_disable ? "to_dept" : "dept_id"
    );
    const productsRequiredFields = [
      "product_id",
      "product_name",
      "qty",
      "batch_no",
      "tax_type",
      "expires_at",
      "p_rate",
      "mrp",
    ];
    const infoErrors = {} as any;
    const productsError = [] as any;

    infoRequiredFields.forEach((key) => {
      if (!info[key]) {
        infoErrors[key] = "required field";
      }
    });

    products.forEach((ele: any) => {
      const productError: { [key: string]: string } = {};
      productsRequiredFields.forEach((key) => {
        if (!ele[key]) {
          productError[key] = "required field";
        }
      });
      productsError.push(productError);
    });

    let productsHasError = false;

    if (productsError?.length > 0) {
      productsError?.forEach((item: any) => {
        if (Object.keys(item).length > 0) {
          productsHasError = true;
        }
      });
    }

    if (Object.keys(infoErrors)?.length > 0 && productsHasError) {
      setInfoError(infoErrors);
      setProductErrors(productsError);
      return false;
    }

    if (info.invoice_value !== summary.net_amount) {
      setIsOpenErrorModal({
        isOpen: true,
        content: "Invoice value does not match net amount",
      });
      return false;
    }

    if (
      summary.payment_method === "Excess" &&
      summary.cash_paid > summary.excess
    ) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        cash_paid: "The paid amount cannot exceed the excess amount",
      }));
      setIsOpenErrorModal({
        isOpen: true,
        content: "The paid amount cannot exceed the excess amount",
      });
      return false;
    } else if (
      summary.payment_method === "Advance" &&
      summary.cash_paid > summary.advance
    ) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        cash_paid: "The paid amount cannot exceed the advance amount",
      }));
      setIsOpenErrorModal({
        isOpen: true,
        content: "The paid amount cannot exceed the advance amount",
      });
      return false;
    }

    if (summary?.refunded_amount > summary.excess_amount) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        refunded_amount: "refunded amount is greater than excess amount",
      }));
      setIsOpenErrorModal({
        isOpen: true,
        content: "The refunded amount cannot exceed the excess amount",
      });
      return false;
    }

    return true;
  };

  const handleSaveData = useCallback(
    (billProductsData: any, summaryData: any, infoDataData: any) => {
      if (
        handleOverAllValidation(infoDataData, billProductsData, summaryData)
      ) {
        setIsSaveLoading(true);
        if (id) {
          updatePurchaseBillById(id, {
            ...infoDataData,
            due_date: infoDataData.due_date
              ? commonDateFormatter(infoDataData.due_date, "YYYY-MM-DD")
              : null,
            invoice_date: infoDataData.invoice_date
              ? moment(
                  infoDataData.invoice_date,
                  "DD/MM/YYYY hh:mm:ss A"
                ).format("YYYY-MM-DD HH:mm:ss")
              : null,
            ref_no_modified: Number(infoDataData.ref_no) === Number(initialSequenceNumber)? 0 : 1,
            product: billProductsData.map((ele: any) => ({
              ...ele,
              expires_at: ele.expires_at
                ? commonDateFormatter(ele.expires_at, "YYYY-MM-DD")
                : null,
            })),
            disc_val: summaryData?.total_discount_amount || 0,
            orig_goods_val: summaryData?.goods_value,
            disc_goods_val: summaryData?.disc_goods_val,
            gst_amt: summaryData?.total_tax,
            net_total_amt: summaryData?.bill_total,
            discount_val: summaryData?.bill_discount,
            discount_type: summaryData?.bill_discount_type
              ? summaryData?.bill_discount_type?.toString()
              : "",
            discount_amt: summaryData?.bill_discount_amount,
            adj_amt: summaryData?.adjustment,
            bill_amt: summaryData?.net_amount,
            hidden_del_id: deletedIds.length > 0 ? deletedIds?.join(",") : "",
          })
            .then((res : any) => {
              dispatch(
                setSnackBarSuccess({
                  snackBarMessage: res.message,
                })
              );
              setIsSaveLoading(false);
              navigate(`${laboratoryUrl}${labPurchaseUrl}`);
            })
            .catch((err) => {
              console.log(err);
              setIsSaveLoading(false);
            });
        } else {
          createPurchaseBill({
            ...infoDataData,
            due_date: infoDataData.due_date
              ? commonDateFormatter(infoDataData.due_date, "YYYY-MM-DD")
              : null,
            invoice_date: infoDataData.invoice_date
              ? moment(
                  infoDataData.invoice_date,
                  "DD/MM/YYYY hh:mm:ss A"
                ).format("YYYY-MM-DD HH:mm:ss")
              : null,
            ref_no_modified: infoDataData.ref_no === initialSequenceNumber ? 0 : 1,
            product: billProductsData.map((ele: any) => ({
              ...ele,
              expires_at: ele.expires_at
                ? commonDateFormatter(ele.expires_at, "YYYY-MM-DD")
                : null,
            })),
            disc_val: billProductsData?.total_discount_amount || 0,
            orig_goods_val: billProductsData?.goods_value,
            disc_goods_val: billProductsData?.disc_goods_val,
            gst_amt: billProductsData?.total_tax,
            net_total_amt: billProductsData?.bill_total,
            discount_val: billProductsData?.bill_discount,
            discount_type: billProductsData?.bill_discount_type
              ? billProductsData?.bill_discount_type?.toString()
              : "",
            discount_amt: billProductsData?.bill_discount_amount,
            adj_amt: billProductsData?.adjustment,
            bill_amt: billProductsData?.net_amount,
            type: billProductsData?.payment_method
              ? billProductsData?.payment_method?.toString()
              : "",
            cash_paid: billProductsData?.cash_paid,
            pay_paid_amt: billProductsData?.amount_paid,
            balance: billProductsData?.balance,
            excess_amount: billProductsData?.excess_amount,
            refunded_excess: billProductsData?.refunded_amount,
            net_excess: billProductsData?.net_excess,
          })
            .then((res : any) => {
              dispatch(
                setSnackBarSuccess({
                  snackBarMessage: res.message,
                })
              );
              setIsSaveLoading(false);
              navigate(`${laboratoryUrl}${labPurchaseUrl}`);
            })
            .catch((err) => {
              console.log(err);
              setIsSaveLoading(false);
            });
        }
      }
    },
    []
  );

  const getAllLaboratoryProductsData = async (data: any) => {
    const res: any = await getAllLaboratoryProducts(data);
    // if (res.data) {
    //   setAllProducts(res.data);
    // }
    if (res?.data?.result) {
      setAllProducts(res?.data?.result);
    }
  };



  const debouncedGetAllProcurementProductsData = useCallback(
    debounce((data) => {
      getAllLaboratoryProductsData(data);
    }, 500),
    []
  );

  useEffect(() => {
    if (
      (!appConfiguration?.lab_goods_issue_disable && infoData?.dept_id) ||
      (appConfiguration?.lab_goods_issue_disable && infoData?.to_dept)
    ) {
      getAllLaboratoryProductsData({
        department: appConfiguration?.lab_goods_issue_disable
          ? infoData.to_dept
          : infoData.dept_id,
        start: 1,
        length: 20,
      });
    } else if (!!id) {
      getAllLaboratoryProductsData({
        department: infoData.dept_id ? infoData.dept_id : infoData.to_dept,
        start: 1,
        length: 20,
      });
    }
  }, [infoData.dept_id, infoData.to_dept]);

  useEffect(() => {
    getTaxOptions().then((res : any) => {
      if (res.data) {
        setTaxOptions(
          res.data?.map((ele: any) => ({
            ...ele,
            name: `${ele.tax_name}-${ele.tax_rate}%`,
          }))
        );
      }
    });
    // getExcessAmountByPatientId()
    //   .then((res: any) => {
    //     if (res.data) {
    //       setSummary((prevState: any) => ({
    //         ...prevState,
    //         excess: res?.data?.excess,
    //         advance: res?.data?.advance,
    //       }));
    //     }
    //   })
    //   .catch((err: any) => console.log(err));
  }, []);

  const calculateUsedQty = (product: any) => {
    if (product.adj_qty < 0) {
      const usedQty =
        (product.sale_qty +
          product.return_qty +
          product.free_return_qty -
          product.adj_qty) /
        product.no_of_tests;

      return Math.ceil(usedQty) || 0;
    } else {
      const usedQty =
        (product.sale_qty + product.return_qty + product.free_return_qty) /
        product.no_of_tests;
      return Math.ceil(usedQty) || 0;
    }
  };

  const getTaxOption = async (product: any) => {
    const res: any = await getTaxOptions();

    const tax = res?.data?.find((ele: any) => ele.id === product.tax_type);

    if (tax) {
      tax.name = `${tax.tax_name}-${tax.tax_rate}%`;
    }

    return tax;
  };


  const calculateRowAmount = (product: any) => {
    const qty = Number(product.original_qty) / Number(product.no_of_tests) || 0;
    const p_rate = Number(product.p_rate) || 0;
    const discount = product.discount || 0;
    const row_discount_type = product.discount_type || 0;
    const discount_amount = calculateDiscountAmount({
      qty,
      p_rate,
      discount,
      row_discount_type,
    });

    const amount = calculateAmount({
      qty,
      p_rate,
      discount_amount: Number(discount_amount),
    });

    return amount.toFixed(2) || 0;
  };

  const getPurchaseBillData = async (id: string | number) => {
    try {
      setIsLoading(true);
      const res : any = await getLabPurchaseBillById(id as any);
      if (res.data) {
        const { bill, bill_products } = res.data;
        const formattedBillProducts = await Promise.all(
          bill_products.map(async (ele: any) => {
            return {
              ...ele,
              qty: Number(ele.original_qty) / Number(ele.no_of_tests) || 0,
              used_qty: calculateUsedQty(ele),
              used_free: Number(ele.free_return_qty) / Number(ele.no_of_tests),
              free: Number(ele.free) / Number(ele.no_of_tests),
              tax: await getTaxOption(ele),
              amount: calculateRowAmount(ele),
              expires_at: ele.expires_at
                ? moment(ele.expires_at, "YYYY-MM-DD").format("DD/MM/YYYY")
                : null,
              row_discount_type: ele.discount_type,
              product_name: {
                id: ele.product_id,
                name: ele.product_name,
                generic_name: ele.name,
                no_of_tests: ele.no_of_tests,
              },
            };
          })
        );

        setBillProducts(formattedBillProducts);
        setInfoData({
          supplier_id: bill.supplier_id,
          supplier_name: bill.name,
          dept_id: bill.dept_id,
          to_dept: bill.to_dept,
          invoice_no: bill.invoice_no,
          invoice_value: bill.invoice_value,
          invoice_date: bill.invoice_date
            ? moment(bill.invoice_date, "YYYY-MM-DD HH:mm:ss").format(
                "DD/MM/YYYY hh:mm:ss A"
              )
            : null,
          ref_no: bill.ref_no,
          ref_no_modified: 0,
          due_date: bill.due_date
            ? moment(bill.due_date, "YYYY-MM-DD").format("DD/MM/YYYY")
            : null,
          po_no: bill.po_no,
        });
        setInitialSequenceNumber(bill?.ref_no);

        setSummary((prevState: any) => ({
          excess: prevState.excess,
          advance: prevState.advance,
          total_discount_amount: Number(bill.disc_val) || 0,
          goods_value: Number(bill.orig_goods_val),
          disc_goods_val: Number(bill.disc_goods_val),
          total_tax: Number(bill.gst_amt),
          bill_total: Number(bill.orig_goods_val) + Number(bill.gst_amt) || 0,
          bill_discount: bill.discount_val,
          bill_discount_type: bill.discount_type,
          bill_discount_amount: bill.discount_amt,
          rounded_off: calculateRoundedOff({
            bill_total: Number(bill.orig_goods_val) + bill.gst_amt || 0,
            bill_discount_amount: bill.discount_amt || 0,
          }),
          adjustment: Number(bill.adj_amt),
          net_amount: Number(bill.bill_amt)?.toFixed(2) || 0,
          payment_method: bill.type,
          cash_paid: bill.cash_paid,
          amount_paid: bill.pay_paid_amt,
          balance: Number(bill.balance),
          refunded_amount: bill.refunded_excess,
          excess_amount: bill.excess_amount,
          net_excess: bill.net_excess,
        }));
        isCreatePrescription = false;
        isSequenceNumberChanged = false;
        getSequenceDetails();
      }
      setIsLoading(false);
     
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchaseBillData(id);
    }else{
      isCreatePrescription = true;
        isSequenceNumberChanged = false;
        getSequenceDetails();
    }
  }, []);
  useEffect(()=>{
    if(appConfiguration?.custom_sequence === false){
      setCustomSequence((prev)=> prev = false);
    }
  },[appConfiguration]);

  return (
    <>
      {isLoading ? (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <PageLoader />
        </Box>
      ) : (
        <Grid sx={{ width: "92vw" }}>
          <Info
            error={infoError}
            setInfoData={setInfoData}
            setInfoError={setInfoError}
            fieldData={fieldData}
            infoData={infoData}
            handleValidation={handleValidation}
            isEdit={!!id}
            setIsSequenceWrapperOpen={setIsSequenceWrapperOpen}
            isManualAdded={isManualAdded}
            isManual={isManual}
            setIsManual={setIsManual}
            handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
            customSequence={customSequence}
          />
          <Grid
            sx={{
              width: "92vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells.map((cell: any, index: number) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody sx={{ height: "auto", width: "90vw" }}>
                  {billProducts.map((row: any, index: number) => {
                    return (
                      <ProductRow
                        key={row.id}
                        index={index}
                        row={row}
                        error={
                          productError?.length > 0 ? productError[index] : null
                        }
                        billId={id}
                        styles={styles}
                        allRows={allProducts}
                        billProducts={billProducts}
                        handleInputChange={handleInputChange}
                        handleDateChange={handleDateChange}
                        handleDelete={handleDelete}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        taxesOptions={taxOptions}
                        setIsOpenErrorModal={setIsOpenErrorModal}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <Grid
                sx={{
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "120vw",
                    lg: "90vw",
                  },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "0px 0px 5px 5px",
                  border: 1,
                  borderColor: "var(--table-border)",
                  borderTop: 0,
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: {
                      xs: "35%",
                    },
                  }}
                >
                  <Button
                    variant="text"
                    buttonText="+ Add Product"
                    handleClick={handleClick}
                    sx={{
                      borderRadius: "0px",
                      width: "auto",
                      height: "auto",
                      fontWeight: 400,
                      fontSize: "13px",
                      border: 0,
                      color: "primary.main",
                      "&:hover": {
                        backgroundColor: "initial",
                        color: "primary.main",
                        borderColor: "initial",
                      },
                      "&.Mui-focusVisible": {
                        border: 1,
                        borderColor: "primary.main",
                        borderRadius: "5px",
                      },
                    }}
                  />
                  <Grid
                    sx={{
                      width: "45%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid
                      sx={{
                        width: "60%",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        Discount Value :
                      </Typography>
                    </Grid>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "600",
                        mr: "1%",
                        textAlign: "left",
                      }}
                    >
                      {summary?.total_discount_amount?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: {
                      xs: "65%",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      width: "20%",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      Goods Value :
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{ fontSize: "13px", fontWeight: "600", mr: "2%" }}
                  >
                    {summary?.goods_value?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </TableContainer>
          </Grid>
          <Summary
            isSaveLoading={isSaveLoading}
            isEdit={!!id}
            summary={summary}
            summaryError={summaryError}
            handleInputChange={handleChangeSummary}
            handleSaveData={() =>
              handleSaveData(billProducts, summary, infoData)
            }
          />
          <Dialog
            open={isOpenErrorModal.isOpen}
            keepMounted
            onClose={() => setIsOpenErrorModal({ isOpen: false, content: "" })}
            aria-describedby="alert-dialog-slide-description"
            sx={{
              "& .MuiPaper-root": {
                position: "absolute",
                top: "0px",
                height: "145px",
              },
            }}
          >
            <DialogContent
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                p: "10px 20px",
              }}
            >
              <DeleteAlertIcon width="19px" height="19px" />
              <DialogTitle
                id="alert-dialog-title"
                sx={{ ...styles.dialogTitle }}
              >
                {isOpenErrorModal.content}
              </DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                buttonText="Okay"
                sx={{
                  borderRadius: "0px",
                  width: "75px",
                  height: "35px",
                  fontWeight: 400,
                  fontSize: "13px",
                  border: 0,
                  color: "#fff",
                  "&:hover": {
                    color: "#fff",
                  },
                  "&.Mui-focusVisible": {
                    border: 1,
                    borderColor: "primary.main",
                    borderRadius: "5px",
                  },
                }}
                handleClick={() =>
                  setIsOpenErrorModal({ isOpen: false, content: "" })
                }
              />
            </DialogActions>
          </Dialog>
          <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="Lab Purchase Bill"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
        </Grid>
      )}
    </>
  );
}
