import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { PageLoader, SaveButton } from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import { billProductsConst } from "../../../../constants/displayText";
import Info from "./Info";
import Summary from "./Summary";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { formula } from "../../../../utils/CalculationUtils";
import { setSnackBarSuccess } from "../../../../redux/slices/snackbar";
import { RouteUrls } from "../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelLabPurchaseBillById,
  getLabPurchaseBillById,
  getTaxOptions,
} from "../../../../services/laboratoryService";
import { RootState } from "../../../../redux/store";

const { laboratoryUrl, labPurchaseUrl } = RouteUrls;
const {
  calculateDiscountAmount,
  calculateAmount,
  calculateRoundedOff,
  calculateSubTaxes,
  calculateTaxAmount,
} = formula.purchaseBill;

const BillProducts = () => {
  const {
    productName,
    qty,
    free,
    discount,
    batchNo,
    expiryDate,
    tax,
    ptr,
    mrp,
    amount,
  } = billProductsConst;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [billProducts, setBillProducts] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: "product_name",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {productName}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.product_name}</Typography>
      ),
      sortable: false,
      minWidth: 150,
    },
    {
      field: "qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {qty}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.qty}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "free",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {free}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.free}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "discount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {discount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.discount}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "batch_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {batchNo}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.batch_no}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "expiry_date",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {expiryDate}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.expiry_date}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {tax}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row?.tax?.tax_name} - {row?.tax?.tax_rate}%
        </Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "ptr",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {ptr}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.ptr}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "mrp",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {mrp}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.mrp}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {amount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.amount}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
  ];

  const [info, setInfo] = React.useState<any>({});

  const [billingSummary, setBillingSummary] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getTaxOption = async (product: any) => {
    const res = (await getTaxOptions()) as any;

    const tax = res?.data?.find((ele: any) => ele.id === product.tax_type);

    if (tax) {
      tax.name = `${tax.name}-${tax.tax_rate}%`;
    }

    return tax;
  };

  const handleCancel = () => {
    setIsLoading(true);
    cancelLabPurchaseBillById(info.id)
      .then((res: any) => {
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: res.message,
          })
        );
        setIsLoading(false);
        navigate(`${laboratoryUrl}${labPurchaseUrl}`);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getPurchaseBillData = async (id: string | number) => {
    try {
      setIsLoading(true);
      const res = (await getLabPurchaseBillById(id as any)) as any;
      if (res.data) {
        const { bill, bill_products, validation } = res.data;
        const formattedBillProducts = await Promise.all(
          bill_products.map(async (ele: any) => {
            const qty = Number(ele.original_qty) / Number(ele.no_of_tests) || 0;
            const tax = await getTaxOption(ele);
            const discount = ele.discount || 0;
            const row_discount_type = ele.discount_type || 0;
            const discount_amount = calculateDiscountAmount({
              qty,
              p_rate: Number(ele.p_rate) || 0,
              discount,
              row_discount_type,
            });
            const amount = calculateAmount({
              qty,
              p_rate: Number(ele.p_rate) || 0,
              discount_amount,
            });
            const tax_amount =
              Number(
                calculateTaxAmount(
                  {
                    tax,
                    qty,
                    p_rate: Number(ele.p_rate) || 0,
                    discount_amount,
                  },
                  bill.tax_settings === 1 ? "exclusive" : "inclusive"
                )
              ) || 0;
            return {
              ...ele,
              ptr: ele.p_rate,
              qty,
              tax,
              amount,
              expiry_date: ele.expires_at
                ? moment(ele.expires_at, "YYYY-MM-DD").format("DD/MM/YYYY")
                : null,
              row_discount_type: ele.discount_type,
              product_name: ele.product_name,
              tax_amount,
            };
          })
        );

        setBillProducts(formattedBillProducts);
        setBillingSummary({
          goods_value: Number(bill.orig_goods_val),
          total_tax: Number(bill.gst_amt),
          bill_total: Number(bill.orig_goods_val) + Number(bill.gst_amt) || 0,
          bill_discount: bill.discount_val,
          bill_discount_type: bill.discount_type,
          bill_discount_amount: bill.discount_amt,
          rounded_off: calculateRoundedOff({
            bill_total: Number(bill.orig_goods_val) + bill.gst_amt || 0,
            bill_discount_amount: bill.discount_amt || 0,
          }),
          adjustment: Number(bill.adj_amt),
          net_amount: Number(bill.bill_amt)?.toFixed(2) || 0,
          sub_taxes: calculateSubTaxes(formattedBillProducts) || 0,
          validation,
        });
        setInfo({
          id: bill.id,
          is_cancel: bill.is_cancel,
          supplier_name: bill.name,
          department: bill.department,
          invoice_no: bill.invoice_no,
          invoice_value: bill.invoice_value,
          invoice_date: bill.invoice_date
            ? moment(bill.invoice_date, "YYYY-MM-DD HH:mm:ss").format(
                "DD/MM/YYYY hh:mm:ss A"
              )
            : null,
          payment_due_date: bill.due_date
            ? moment(bill.due_date, "YYYY-MM-DD").format("DD/MM/YYYY")
            : null,
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchaseBillData(id);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Box>
          <Info info={info} />
          <Box sx={{ m: "20px 0px" }}>
            <DataTable
              columns={columns}
              rows={billProducts}
              tableOnly={true}
              customizedTable={true}
            />
          </Box>
          <Summary billingSummary={billingSummary} />
          {!info?.validation?.is_adj_done &&
            !info?.validation?.is_free_return_filed &&
            !info?.validation?.is_return_filed &&
            !info?.validation?.is_sale_done &&
            !info?.is_cancel && (
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  sx={{
                    color: "#fff",
                    backgroundColor: "#f08f00",
                    borderColor: "#c27400",
                    boxShadow:
                      "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
                    padding: "12px 30px",
                    margin: "0.3125rem 1px",
                    fontSize: ".75rem",
                    fontWeight: 400,
                    lineHeight: 1.428571,
                    cursor: "pointer",
                    borderRadius: "30px",
                  }}
                  onClick={handleCancel}
                >
                  X Cancel
                </Box>
              </Box>
            )}
          <Box
            sx={{
              width: "40vw",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              gap: 2,
              m: "20px 0px",
            }}
          >
            <SaveButton
              buttonText="Back"
              sx={{ width: "100px", height: "42px" }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default BillProducts;
