import React, { useEffect, useRef, useState } from "react";
import { debounce, Grid } from "@mui/material";
import { SettingsIcon } from "../../../../assets/icons";
import {
  Select,
  DatePickerWithTime,
  TextField,
  DatePicker,
  InfiniteScroller,
} from "../../../../components/basic";
import { purchaseBillInfoConst } from "../../../../constants/displayText";
import {
  getAllDepartments,
  getAllSuppliers,
} from "../../../../services/gynecologyService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { getPoNoOptionsBySupplierId } from "../../../../services/procurementService";
type InfoProps = {
  infoData?: any;
  setInfoData: any;
  fieldData: any;
  handleValidation: (e: any) => void;
  error?: any;
  setInfoError?: any;
  handleBlurPoNumber?: (e: any) => void;
  isEdit?: boolean;
  initialSupplier?: any;
  customSequence?: boolean;
  setIsSequenceWrapperOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmSequenceNoChange: () => void;
  getSequenceDetails: (name: string) => void;
  handleResetBillProducts?: any;
  setIsOpenPoModal?: any;
};

type optionsListTypes = {
  id: number;
  name: string;
};
const Info = React.memo(
  ({
    infoData,
    setInfoData,
    fieldData,
    handleValidation,
    error,
    setInfoError,
    handleBlurPoNumber,
    isEdit,
    initialSupplier,
    customSequence,
    setIsSequenceWrapperOpen,
    handleConfirmSequenceNoChange,
    getSequenceDetails,
    handleResetBillProducts,
    setIsOpenPoModal,
  }: InfoProps) => {
    const [suppliersList, setSuppliersList] = useState<
      optionsListTypes[] | any
    >(initialSupplier || []);
    const [departmentList, setDepartmentList] = useState<optionsListTypes[]>(
      []
    );
    const [issuersList, setIssuersList] = useState<optionsListTypes[]>([]);
    const [poNoOptions, setPoNoOptions] = useState<any>([]);
    const { appConfiguration } = useSelector(
      (state: RootState) => state.appConfiguration
    );

    const handleChange = (e: any) => {
      const { name, value } = e.target;
      setInfoData((prev: any) => {
        if (name === "dept_id") {
          handleResetBillProducts();
        }
        if (name === "supplier_id" && prev.po_no) {
          handleResetBillProducts();
          setPoNoOptions([]);
        }

        return {
          ...prev,
          po_no: name === "supplier_id" ? null : prev.po_no,
          [name]: value,
          department:
            name === "dept_id"
              ? departmentList?.find((ele) => ele.id === value)?.name
              : prev.department,
        };
      });
      setInfoError((prevState: any) => ({
        ...prevState,
        [name]: value ? "" : prevState[name],
      }));
    };

    const handleDateChange = (newValue: any, name: any) => {
      setInfoData((prev: any) => ({
        ...prev,
        [name]: newValue.toString(),
      }));
      setInfoError((prevState: any) => ({
        ...prevState,
        [name]: newValue?.toString() ? "" : prevState[name],
      }));
    };

    const supplierPagination = useRef(1);
    const searchSupplierPagination = useRef({ search: "", page: 1 });

    const debouncedGetAllSupplierList = debounce(
      async (currentValue: string, prevOptions: any[], callback: any) => {
        try {
          return await getAllSuppliers({
            search: currentValue,
            page:
              searchSupplierPagination.current?.search === currentValue
                ? searchSupplierPagination.current?.page
                : 1,
            limit: 10,
          }).then((result: any) => {
            let data = result?.data?.result;
            const formattedSuppliersList = data.map((uniqueData: any) => {
              return { value: uniqueData.id, label: uniqueData.name };
            });
            const uniqueOptions = formattedSuppliersList.filter(
              (option: any) =>
                !prevOptions.some(
                  (prevOption: any) => prevOption.value === option.value
                )
            );
            setSuppliersList(uniqueOptions);
            searchSupplierPagination.current.page =
              searchSupplierPagination.current?.search === currentValue
                ? searchSupplierPagination.current?.page + 1
                : 1;
            searchSupplierPagination.current.search = currentValue;
            const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
            callback({
              options: uniqueOptions,
              hasMore,
            });
          });
        } catch (error) {
          console.log("error:", error);
          callback({
            options: [],
            hasMore: false,
          });
        }
      },
      300
    );

    const getAllSuppliersListData = async (
      currentValue?: any,
      prevOptions?: any
    ) => {
      try {
        if (currentValue) {
          return new Promise((resolve) => {
            debouncedGetAllSupplierList(
              currentValue,
              prevOptions,
              (response: {
                options: { value: string | number; label: string | number }[];
                hasMore: boolean;
              }) => {
                resolve(response);
              }
            );
          });
        } else {
          const page = supplierPagination.current === Math.ceil(prevOptions.length / 10)
              ? supplierPagination.current
              : Math.ceil(prevOptions.length / 10) + 1;
          return await getAllSuppliers({
            search: currentValue,
            page: page,
            limit: 10,
          })
            .then((result: any) => {
              let data = result?.data?.result;

              const formattedSuppliersList = data?.map((uniqueData: any) => {
                return { value: uniqueData.id, label: uniqueData.name };
              });
              const uniqueOptions = formattedSuppliersList.filter(
                (option: any) =>
                  !prevOptions.some(
                    (prevOption: any) => prevOption.value === option.value
                  )
              );

              if (uniqueOptions?.length > 0) {
                setSuppliersList((prevState: any) => [
                  ...prevState,
                  ...uniqueOptions,
                ]);
              }
              const currentValue = supplierPagination.current;
              supplierPagination.current = currentValue + 1;
              const hasMore = result.data.total > uniqueOptions?.length;
              return {
                options: uniqueOptions,
                hasMore,
              };
            })
            .catch((err) => {
              console.log(err);
              return {
                options: [],
                hasMore: false,
              };
            });
        }
      } catch (error) {
        console.log("error:", error);
        return {
          options: [],
          hasMore: false,
        };
      }
    };

    const getAllDepartmentList = async () => {
      try {
        await getAllDepartments().then((res: any) => {
          if (res?.data) {
            const newDepartmentList = res?.data?.result
              ?.filter((ele: any) => ele.type === "issuer")
              .map((ele: any) => ({ name: ele.dept_name, id: ele.id }));
            const newIssuerList = res.data?.result
              ?.filter((ele: any) => ele.type === "receiver")
              .map((ele: any) => ({ name: ele.dept_name, id: ele.id }));
            setDepartmentList(newDepartmentList);
            setIssuersList(newIssuerList);

            if (
              (newIssuerList?.length === 1 || newDepartmentList.length === 1) &&
              !isEdit
            ) {
              setInfoData((prevState: any) => ({
                ...prevState,
                to_dept: newIssuerList?.length === 1 ? newIssuerList[0].id : "",
                dept_id: isEdit
                  ? prevState.dept_id
                  : newDepartmentList?.length === 1
                  ? newDepartmentList[0].id
                  : "",
                department: newDepartmentList[0]?.name,
              }));
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getAllDepartmentList();
    }, [appConfiguration?.goods_issue_disable]);

    useEffect(() => {
      if (fieldData?.dept_id?.value) {
        const department = departmentList.find(
          (ele) => ele.id === fieldData?.dept_id?.value
        );
        if (department) {
          getSequenceDetails(department?.name);
        }
      }
    }, [fieldData.dept_id.value]);

    useEffect(() => {
      if (fieldData.supplier_id.value && !isEdit) {
        getPoNoOptionsBySupplierId({ supplier_id: fieldData.supplier_id.value })
          .then((res: any) => {
            if (res.data) {
              setPoNoOptions(res.data?.result);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }, [fieldData.supplier_id.value]);

    useEffect(() => {
      if (infoData.supplier_id && infoData.supplier_name) {
        setSuppliersList([
          { value: infoData.supplier_id, label: infoData.supplier_name },
        ]);
      }
    }, []);

    useEffect(() => {
      if (infoData?.po_options) {
        setPoNoOptions(infoData?.po_options);
      }
    }, [infoData?.po_options]);

    const styles = {
      selectGrid: {
        width: "188px",
        height: "45px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
      textFieldStyle: {
        width: "188px",
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 5,
        }}
      >
        <InfiniteScroller
          loadOptions={getAllSuppliersListData}
          options={suppliersList}
          handleOnChange={handleChange}
          // defaultOptions={defaultOptions}
          label={fieldData.supplier_id.label}
          name={fieldData.supplier_id.name}
          value={fieldData.supplier_id.value}
          // isDisabled={fieldData.supplier_id.isDisabled}
          width="188px"
          error={!!error.supplier_id}
        />
        <Select
          value={fieldData.dept_id.value}
          onChange={handleChange}
          placeholder={fieldData.dept_id.placeholder}
          options={departmentList}
          label={fieldData.dept_id.label}
          disabled={fieldData.dept_id?.isDisabled}
          name={fieldData.dept_id.name}
          width="188px"
          sx={{
            ...styles.selectGrid,
            backgroundColor: fieldData.po_no.isDisabled
              ? "var(--disabled-background)"
              : "initial",
          }}
          error={!!error?.dept_id}
        />
        {appConfiguration?.goods_issue_disable && (
          <Select
            value={infoData.to_dept}
            onChange={handleChange}
            placeholder={fieldData.to_dept.placeholder}
            options={issuersList}
            label={fieldData.to_dept.label}
            name={fieldData.to_dept.name}
            width="188px"
            disabled={fieldData.to_dept?.isDisabled}
            sx={{
              ...styles.selectGrid,
              backgroundColor: fieldData.po_no.isDisabled
                ? "var(--disabled-background)"
                : "initial",
            }}
            selectedType="number"
            error={!!error?.to_dept}
          />
        )}
        <TextField
          value={fieldData.invoice_no.value}
          label={fieldData.invoice_no.label}
          placeholder={fieldData.invoice_no.placeholder}
          onChange={handleChange}
          name={fieldData.invoice_no.name}
          sx={{ ...styles.textFieldStyle }}
          error={!!error?.invoice_no}
        />

        <TextField
          value={fieldData.invoice_value.value}
          label={fieldData.invoice_value.label}
          placeholder={fieldData.invoice_value.placeholder}
          name={fieldData.invoice_value.name}
          onChange={handleChange}
          sx={{ ...styles.textFieldStyle }}
          error={!!error?.invoice_value}
          onBlur={(e: any) =>
            handleChange({
              target: {
                name: "invoice_value",
                value: Number(e.target.value).toFixed(2),
              },
            })
          }
        />
        <Grid
          item
          sx={{
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                boxSizing: "border-box",
                width: "188px",
              },
          }}
        >
          <DatePickerWithTime
            value={fieldData.invoice_date.value}
            label={fieldData.invoice_date.label}
            placeholder={fieldData.invoice_date.placeholder}
            onChange={(newValue: any) =>
              handleDateChange(newValue, "invoice_date")
            }
            width="188px"
            borderColor="var(--primary-border-color)"
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
            }}
            error={!!error?.invoice_date}
          />
        </Grid>

        {customSequence ? (
          <TextField
            value={fieldData.ref_no.value}
            label={fieldData.ref_no.label}
            placeholder={fieldData.ref_no.placeholder}
            onBlur={handleConfirmSequenceNoChange}
            onChange={handleChange}
            endPlaceholderIcon={
              <SettingsIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsSequenceWrapperOpen(true);
                }}
              />
            }
            name={fieldData.ref_no.name}
            sx={{ ...styles.textFieldStyle }}
            error={!!error?.ref_no}
          />
        ) : null}
        <Grid
          item
          sx={{
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                boxSizing: "border-box",
                width: "188px",
              },
          }}
        >
          <DatePicker
            value={fieldData.due_date.value}
            label={fieldData.due_date.label}
            placeholder={fieldData.due_date.placeholder}
            width="188px"
            style={{ borderRadius: "5px" }}
            onChange={(newValue: any) => handleDateChange(newValue, "due_date")}
            formControlStyle={{ width: "90%" }}
            format="DD/MM/YYYY"
          />
        </Grid>
        <Select
          value={fieldData.po_no.value}
          label={fieldData.po_no.label}
          placeholder={fieldData.po_no.placeholder}
          onChange={(event: any) =>
            setIsOpenPoModal((prevState: any) => ({
              ...prevState,
              po_no: event?.target?.value,
              isOpen: true,
            }))
          }
          name={fieldData.po_no.name}
          disabled={fieldData.po_no.isDisabled}
          sx={{
            ...styles.selectGrid,
            backgroundColor: fieldData.po_no.isDisabled
              ? "var(--disabled-background)"
              : "initial",
          }}
          options={poNoOptions}
          width="188px"
          error={!!error?.po_no}
        />
      </Grid>
    );
  }
);

export default Info;
