import React from "react";
import {
  TableCell,
  TableRow,
  Popper,
  Box,
  List,
  Grid,
  Typography,
} from "@mui/material";
import { TextField, AutocompleteWithTable } from "../../../components/basic";
import { DeleteIcon, DisabledDelete } from "../../../assets/icons";

type ProductRowProps = {
  index: number;
  styles?: any;
  row?: any;
  handleDelete: (id: number) => void;
  allRows?: any;
  summary?: any;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number | string
  ) => void;
  handleAutoCompleteChange: (
    newValue: any,
    idx: number | string,
    name: string
  ) => false | void;
  formError?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    row,
    allRows,
    handleInputChange,
    handleAutoCompleteChange,
    handleDelete,
    index,
    formError,
  }: ProductRowProps) => {
    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            height: "40px",
            p: "10px 0px",
            "& .MuiPaper-root": {
              width: "450px",
              maxHeight: "180px",
              overflow: "hidden",
            },
          }}
        >
          <Box
            sx={{
              width: "450px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              height: "43px",
            }}
          >
            <Typography
              sx={{
                width: "150px",
                height: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "center",
                pl: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              productName
            </Typography>
            <Typography
              sx={{
                width: "150px",
                height: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "center",
                pl: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Batch No
            </Typography>
            <Typography
              sx={{
                width: "150px",
                height: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "center",
                pl: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              quantity
            </Typography>
            <Typography
              sx={{
                width: "150px",
                height: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "center",
                pl: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              discount
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="left"
          sx={{
            width: "12%",
            "& .MuiAutocomplete-root": {
              height: "31px",
            },
            "& .MuiInputBase-root": {
              height: "32px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
            },
          }}
        >
          {!row.isId ? (
            <AutocompleteWithTable
              placeholder="Select Product"
              name="product_name"
              value={row?.product_name}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(newValue, index, "product_name")
              }
              options={allRows}
              renderOption={(props: any, option: any) => {
                return (
                  <List
                    {...props}
                    key={option.batch_id}
                    sx={{
                      width: "450px",
                      borderRadius: 0,
                    }}
                  >
                    <Box
                      className="list-box"
                      display="flex"
                      justifyContent="space-between"
                      width="450px"
                    >
                      <Typography
                        variant="h6"
                        sx={{ width: "30%", textAlign: "center", pl: "5%" }}
                      >
                        {option.product_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ width: "30%", textAlign: "center", pl: "5%" }}
                      >
                        {option.batch_no}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ width: "30%", textAlign: "center", pl: "5%" }}
                      >
                        {option.stock_qty}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ width: "30%", textAlign: "center", pl: "5%" }}
                      >
                        {option.discount || 0}
                      </Typography>
                    </Box>
                  </List>
                );
              }}
              getOptionLabel={(option: any) => option?.product_name}
              sx={{
                width: "100%",
                height: "34px",
                "& .MuiFormControl-root": {
                  height: "32px",
                },

                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputBase-root .MuiInputBase-input": {
                  padding: 0,
                  fontSize: "12px",
                  fontWeight: "400",
                },
              }}
              PopperComponent={PopperMy}
            />
          ) : (
            <TextField
              name="product_name"
              value={row.product_name.product_name}
              disabled={true}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          )}
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="max_qty"
            value={row.max_qty}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="f_qty"
            value={row.f_qty}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="max_box"
            value={row.max_box}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="f_box"
            value={row.f_box}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: Boolean(formError[index]?.net_return_qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="net_return_qty"
            value={row.net_return_qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: Boolean(formError[index]?.return_qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="return_qty"
            value={row.return_qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: Boolean(formError[index]?.net_free_return_qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="net_free_return_qty"
            value={row.net_free_return_qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: Boolean(formError[index]?.free_return_qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="free_return_qty"
            value={row.free_return_qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="discount"
            value={row.discount}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="batch_no"
            value={row.batch_no}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            backgroundColor: "var(--table-cell-disabled-background)",
            width: "10%",
          }}
        >
          <TextField
            name="expiry_date"
            value={row.expiry_date}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "8%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            width="100px"
            name="tax"
            value={row.tax}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "8%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            width="100px"
            name="tax"
            value={row.ptr}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            backgroundColor: "var(--disabled-background)",
            width: "10%",
            position: "relative",
          }}
        >
          <TextField
            name="amount"
            value={row.amount}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          />

          <Grid className="deleteIconGrid">
            {index === 0 ? (
              <DisabledDelete className="delete-icon" />
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => handleDelete(index)}
              />
            )}

            <TextField
              width="0px"
              name="discountAmount"
              value={row.discountAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
            <TextField
              width="0px"
              name="taxAmount"
              value={row.taxAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
