import React from "react";
import {
  TableCell,
  TableRow,
  Grid,
  Popper,
  Box,
  Typography,
  List,
} from "@mui/material";
import { DeleteIcon, DisabledDelete } from "../../../assets/icons";
import { TextField, AutoCompleteWithTable } from "../../../components/basic";

type summary = {
  total_discount_amount: number;
  goods_value: number;
  tax_amount: number;
  rounded_off: number;
  bill_amount: number;
};

type ProductRowProps = {
  styles?: any;
  row: any;
  handleDelete: (id: number) => void;
  summary?: summary;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number | string | any
  ) => void;
  handleAutoCompleteChange: (
    newValue: any,
    idx: number | string | any,
    name: string
  ) => false | void;

  index: number;
  allRows?: any;
  formError?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    row,
    handleInputChange,
    handleAutoCompleteChange,
    handleDelete,
    allRows,
    index,
    formError,
  }: ProductRowProps) => {
    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            width: "700px",
            p: "10px 0px",
            boxSizing: "border-box",
            "& .MuiPaper-root": {
              width: "700px",
              maxHeight: "250px",
              overflow: "hidden",
              borderRadius: "0px 0px 5px 5px",
              boxSizing: "border-box",
            },
            "& .MuiAutocomplete-listbox": {
              overflowX: "hidden",
              maxHeight: "250px",
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "700px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              p: "0px 10px 0px 0px",
              height: "45px",
              boxSizing: "border-box",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <Typography
              sx={{
                width: "50%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Generic Name
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Batch
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Exp.Date
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Stock
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };
    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="left"
          sx={{
            width: "12%",
            "& .MuiAutocomplete-root": {
              height: "31px",
            },
            "& .MuiInputBase-root": {
              height: "32px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
            },
          }}
        >
          <AutoCompleteWithTable
            placeholder="Select Product"
            name="product_name"
            value={row?.product_name}
            onChange={(e: any, newValue: any) =>
              handleAutoCompleteChange(newValue, index, "product_name")
            }
            options={allRows}
            renderOption={(props: any, option: any) => {
              return (
                <List
                  {...props}
                  key={option.ID}
                  className="table-body-lists-div"
                  sx={{
                    width: "700px",
                    borderRadius: 0,
                    boxSizing: "border-box",
                    m: "0px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.lighter",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="700px"
                    className="list-row"
                    sx={{
                      boxSizing: "border-box",
                      m: "0px",
                      p: "0px 10px 0px 0px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        width: "50%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.product_name}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "20%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.batch_no}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "20%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.expires_at
                        ? option.expires_at?.split("-").reverse().join("/")
                        : ""}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "20%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.stock_qty || 0}
                    </Typography>
                  </Box>
                </List>
              );
            }}
            getOptionLabel={(option: any) => option?.product_name}
            sx={{
              width: "100%",
              height: "34px",
              "& .MuiFormControl-root": {
                height: "32px",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "& .MuiInputBase-root .MuiInputBase-input": {
                padding: 0,
                fontSize: "12px",
                fontWeight: "400",
              },
            }}
            PopperComponent={PopperMy}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="batch_no"
            value={row.batch_no}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "12%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="expiry_date"
            value={row.expiry_date}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="stock_qty"
            value={row.stock_qty}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "8%",
            border: Boolean(formError[index as number]?.ret_qty)
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="ret_qty"
            value={row.ret_qty}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, index)
            }
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="mrp"
            value={row?.mrp ? row?.mrp.toFixed(2) : 0}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="discount"
            value={row.discount}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            width="100px"
            name="tax"
            value={row.tax}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
            }}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            backgroundColor: "var(--disabled-background)",
            width: "10%",
            position: "relative",
          }}
        >
          <TextField
            name="amount"
            value={row?.amount ? row?.amount.toFixed(2) : 0}
            disabled={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleInputChange(e, index)
            }
            sx={{
              ...styles.textFieldStyle,
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          />

          <Grid className="deleteIconGrid">
            {row.id === 0 ? (
              <DisabledDelete className="delete-icon" />
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => handleDelete(row.id)}
              />
            )}

            <TextField
              width="0px"
              name="discountAmount"
              value={row?.discountAmount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, index)
              }
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
            <TextField
              width="0px"
              name="taxAmount"
              value={row?.taxAmount}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(e, index)
              }
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
