import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import PrescriptionRow from "./PrescriptionRow";
export default function AddMedicine({
  suggestionInfo,
  commonVariables,
  handleSuggestionChange,
  handleSuggestionDateChange,
}: any) {
  const styles = {
    tableContainerStyle: {
      borderRadius: "5px",
      boxShadow: "none",
      overflow: "hidden",
      width: "100%",
      overflowX: { xs: "scroll", lg: "hidden" },
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    tableStyle: {
      border: "1px solid",
      borderColor: "var(--table-border)",
      width: "100%",
      minWidth: "1200px",
      overflowX: "auto",
      "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
      "& .MuiTableRow-head": {
        backgroundColor: "var(--table-header)",
      },
      " & .MuiTableBody-root": {
        border: 0,
      },
      "& .MuiTableRow-root": {
        height: "45px !important",
        maxHeight: "34px !important",
        minHeight: "0px",
        lineHeight: "0px",
        border: 0,
      },

      "& .MuiTableCell-root": {
        minHeight: "0px",
      },

      "& .MuiTableCell-body": {
        height: "12px !important",
        maxHeight: "32px !important",
        minHeight: "0px",
        lineHeight: "0px",
        p: "0px 0px",
        fontSize: "12px",
        fontWeight: "400",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "var(--table-border)",
        overflow: "hidden",
      },

      "& .MuiTableCell-body:has(.Mui-focused)": {
        borderColor: "primary.main",
        borderWidth: "2px",
        p: "0px",
        height: "31px",
      },

      "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
        borderColor: "primary.main",
        borderWidth: "2px",
        p: "0px",
        height: "31px",
      },
      "& .deleteIconGrid": {
        cursor: "pointer",
        position: "absolute",
        right: {
          xs: "0px",
          sm: "0px",
          md: "0px",
          lg: "-2vw",
        },
        bottom: "5px",
        mb: "10px",
      },
    },
    header: {
      backgroundColor: "var(--table-header)",
    },
    textFieldStyle: {
      "&>*": {
        border: 0,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "0px",
      },
      "& .MuiInputBase-input": {
        padding: "0px 5px",
        fontSize: "12px",
        fontWeight: "400",
        textAlign: "left",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px",
      },
      "& input::placeholder": {
        fontSize: "12px",
      },
    },
    selectFieldStyle: {
      "&>*": {
        border: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px",
      },
    },
    headerStyle: {
      color: "#000",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "15px",
      borderBottom: "1px solid var(--table-border)",
      borderRight: "1px solid var(--table-border)",
      padding: "12px 12px 12px 20px",
      marginLeft: "8px",
    },
  };
  const columns = {
    headers: [
      {
        header: "Medicine Name",
        textAlignment: "left",
        width: 10,
        name: "subHeading",
        headerStyle: styles.headerStyle,
      },
      {
        header: "M-AF-E-N",
        textAlignment: "left",
        width: 10,
        name: "M-AF-E-N",
        headerStyle: styles.headerStyle,
      },
      {
        header: "Unit",
        textAlignment: "left",
        width: 10,
        name: "unit",
        headerStyle: styles.headerStyle,
      },
      {
        header: "Medication",
        textAlignment: "left",
        width: 11,
        name: "medication",
        headerStyle: styles.headerStyle,
      },
      {
        header: "Freq",
        textAlignment: "left",
        width: 6,
        name: "freq",
        headerStyle: styles.headerStyle,
      },
      {
        header: "Time",
        textAlignment: "left",
        width: 10,
        name: "time",
        headerStyle: styles.headerStyle,
      },
      {
        header: "Duration",
        textAlignment: "left",
        width: 8,
        name: "Duration",
        headerStyle: styles.headerStyle,
      },
      {
        header: "",
        textAlignment: "left",
        width: 7,
        name: "refRange",
        headerStyle: styles.headerStyle,
      },
      {
        header: "Time",
        textAlignment: "left",
        width: 7,
        name: "refRange",
        headerStyle: styles.headerStyle,
      },
      {
        header: "Days",
        textAlignment: "left",
        width: 7,
        name: "days",
        headerStyle: styles.headerStyle,
      },
      {
        header: "Start Date",
        textAlignment: "left",
        width: 10,
        name: "price",
        headerStyle: styles.headerStyle,
      },
      {
        header: "",
        textAlignment: "left",
        width: 4,
        headerStyle: { ...styles.headerStyle, borderRight: "none" },
      },
    ],
  };
  const TableHeader = React.memo((props: any) => {
    const { columns, labResultRef } = props;

    return (
      <TableHead sx={{ ...styles.header }}>
        <TableRow>
          {columns?.map((column: any) => (
            <TableCell
              align={column.textAlignment}
              ref={column?.header === "Lab Result" ? labResultRef : null}
              sx={{ ...column.headerStyle, width: `${column.width}%` }}
            >
              {column?.header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  });
  const Row = React.memo((props: any) => {
    const {
      row,
      isSubTestAddedOrDeleted, // Added this prop to render only when row is added or removed
      columns,
      rowIndex,
      labResultRef,
      onAddSubTest,
      onDelete,
      onClickRange,
      onChange,
      billingDepartmentOptions,
      labResultOptions,
      methodologyOptions,
      errors,
    } = props;
    const ItemRowMemoized = React.memo(PrescriptionRow);
    return (
      <>
        <ItemRowMemoized
          error={
            errors?.lab_tests?.length > rowIndex
              ? errors.lab_tests[rowIndex]
              : null
          }
          index={rowIndex}
          isSubTestAddedOrDeleted={isSubTestAddedOrDeleted}
          billingDepartmentOptions={billingDepartmentOptions}
          labResultOptions={labResultOptions}
          methodologyOptions={methodologyOptions}
          rowIndex={rowIndex}
          commonVariables={commonVariables}
          row={row}
          handleDateChange={handleSuggestionDateChange}
          labResultRef={labResultRef}
          columns={columns}
          onAddSubTest={onAddSubTest}
          onDelete={onDelete}
          onClickRange={onClickRange}
          onChange={onChange}
          styles={styles}
        />
      </>
    );
  });
  const TableBodyComponent = React.memo((props: any) => {
    const {
      rows,
      isSubTestAddedOrDeleted,
      labResultRef,
      onAddSubTest,
      onDelete,
      onClickRange,
      onChange,
      billingDepartmentOptions,
      labResultOptions,
      methodologyOptions,
      errors,
    } = props;

    return (
      <TableBody
        sx={{
          "& .MuiTableCell-body:has(.Mui-focused)": {
            borderBottomColor: "#207DFF !important",
          },
        }}
      >
        {rows?.map((row: any, rowIndex: number) => (
          <Row
            errors={errors}
            isSubTestAddedOrDeleted={isSubTestAddedOrDeleted}
            billingDepartmentOptions={billingDepartmentOptions}
            labResultOptions={labResultOptions}
            methodologyOptions={methodologyOptions}
            rowIndex={rowIndex}
            row={row}
            labResultRef={labResultRef}
            columns={columns}
            onAddSubTest={onAddSubTest}
            onDelete={onDelete}
            onClickRange={onClickRange}
            onChange={onChange}
          />
        ))}
      </TableBody>
    );
  });
  return (
    <Grid className="TableContainer-grid">
      <TableContainer sx={{ ...styles.tableContainerStyle }}>
        <Table sx={{ ...styles.tableStyle }}>
          <TableHeader columns={columns.headers} />
          <TableBodyComponent
            // errors={errors}
            rows={suggestionInfo}
            onChange={handleSuggestionChange}
          />
        </Table>
      </TableContainer>
    </Grid>
  );
}
