import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  MultiSelectWithCheckbox,
  Select,
  Switch,
  TextAreaEditor,
  TextField,
} from "../../../../components/basic";
import {
  DeleteIcon,
  PlusIcon,
  NotesIcon,
} from "../../../../assets/icons";
import { RefRangeImg } from "../../../../assets/images";
import { DialogWrapper } from "../../../../components/shared";

type ItemRowProps = {
  isSubTestAddedOrDeleted?: boolean;
  index: number;
  rowIndex: number;
  labResultRef: any;
  subTest: any;
  row: any;
  columns: any;
  onAddSubTest: (index: number) => void;
  onDelete: (rowIndex: number, index: number, id?: number) => void;
  onClickRange: (rowIndex: number, index: number) => void;
  onChange: (
    event: any,
    rowIndex: number,
    subTestIndex: number,
    isSubHeading?: boolean
  ) => void;
  billingDepartmentOptions: any;
  labResultOptions: any;
  methodologyOptions: any;
  error?: any;
};

type NotesModalProps = {
  isOpen: boolean;
  value?: any;
  onClose: () => void;
  onSave: (value: any) => void;
};

const styles = {
  textFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "0px 5px",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  selectFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
};

const NotesModal = React.memo((props: NotesModalProps) => {
  const { isOpen, value, onClose, onSave } = props;
  const [data, setData] = useState<any>("");

  useEffect(() => {
    if (value) {
      setData(value);
    }
  }, [value]);

  return (
    <DialogWrapper
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      handleClick={() => {
        if (data) {
          onSave(data);
          onClose();
        }
      }}
      title="Add Notes"
      confirmText="Save"
      cancelText="Cancel"
      maxWidth="md"
    >
      <TextAreaEditor
        value={data}
        onChange={(content: any) => setData(content)}
      />
    </DialogWrapper>
  );
});

const ItemRow = (props: ItemRowProps) => {
  const {
    isSubTestAddedOrDeleted,
    index,
    rowIndex,
    labResultRef,
    subTest,
    row,
    columns,
    onClickRange,
    onAddSubTest,
    onDelete,
    onChange,
    billingDepartmentOptions,
    labResultOptions,
    methodologyOptions,
    error,
  } = props;
  const [testNameAndSeqFocus, setTestNameAndSeqFocus] = useState<string>("");
  const [isOpenNotesModal, setIsOpenNotesModal] = useState<{
    isOpen: boolean;
    value: any;
    rowIndex: number | null;
    index: number | null;
  }>({
    isOpen: false,
    value: null,
    index: null,
    rowIndex: null,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: any, index: number, rowIndex: number) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const getLabResult = useCallback((values: number[]) => {
    const selectedOption = labResultOptions
      .filter((option: { id: any; name: string }) =>
        values?.includes(option.id)
      )
      .map((option: any) => option.name)
      .join(", ");

    return selectedOption;
  }, []);

  return (
    <TableRow key={`${subTest.bill_item_id} - ${index}`}>
      <TableCell
        sx={{
          borderBottomColor:
            index + 1 === row?.subTests.length
              ? "var(--table-border)"
              : "transparent !important",
          width: `${columns.headers[0].width}%`,
        }}
      >
        {index === 0 && (
          <TextField
            fullWidth
            formControlStyle={{
              width: "100%",
              marginLeft: "15px",
              overflow: "hidden",
            }}
            value={row.sub_heading}
            sx={{ ...styles.textFieldStyle }}
            name="sub_heading"
            onChange={(event: any) => {
              const { name, value } = event.target;
              onChange(event, rowIndex, index, true);
            }}
          />
        )}
      </TableCell>
      <TableCell
        sx={{
          "&.MuiTableCell-body:has(.Mui-focused)": {
            border: "#FFFFFF !important",
          },
          width: `${columns.headers[1].width}%`,
        }}
      >
        <Box display={"flex"}>
          <div
            style={{
              width: "70%",
              height: "47px",
              borderWidth: !!error?.test_name
                ? "2px"
                : testNameAndSeqFocus === "testName"
                ? "2px"
                : "0px 1px 0px 0px",
              borderStyle: "solid",
              borderColor: !!error?.test_name
                ? "#d32f2f"
                : testNameAndSeqFocus === "testName"
                ? "#207DFF"
                : "var(--table-border)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              formControlStyle={{
                width: "100%",
              }}
              value={subTest.test_name}
              sx={{
                ...styles.textFieldStyle,
              }}
              name="test_name"
              onChange={(event: any) => {
                const { name, value } = event.target;
                onChange(event, rowIndex, index);
              }}
              onFocus={() => setTestNameAndSeqFocus("testName")}
              onBlur={() => setTestNameAndSeqFocus("")}
            />
          </div>
          <div
            style={{
              width: "30%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border:
                testNameAndSeqFocus === "sequence"
                  ? "2px solid #207DFF"
                  : "unset",
            }}
          >
            <TextField
              type="number"
              formControlStyle={{ width: "100%" }}
              value={subTest.align_order}
              name="align_order"
              onChange={(event: any) => onChange(event, rowIndex, index)}
              onFocus={() => setTestNameAndSeqFocus("sequence")}
              onBlur={() => setTestNameAndSeqFocus("")}
              sx={{
                ...styles.textFieldStyle,
                "& input": {
                  textAlign: "center !important",
                },
                "&input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
              }}
            />
          </div>
        </Box>
      </TableCell>
      <TableCell
        style={{
          width: `${columns.headers[2].width}%`,
          border: !!error?.test_dept ? "2px solid #d32f2f" : "",
        }}
      >
        <Select
          formControlStyle={{
            width: "100%",
            marginLeft: "5px",
            "& .MuiInputBase-formControl": {
              boxShadow: "unset",
            },
          }}
          value={subTest.test_dept}
          label=""
          width=""
          options={billingDepartmentOptions}
          sx={{
            ...styles.selectFieldStyle,
            "& .MuiSelect-icon": {
              display:
                testNameAndSeqFocus === "department" ? "inline-flex" : "none",
            },
            "&:hover .MuiSelect-icon": {
              display: "inline-flex",
            },
          }}
          menuStyle={{
            marginLeft: "-5px",
          }}
          name="test_dept"
          onChange={(event: any) => onChange(event, rowIndex, index)}
          onBlur={() => setTestNameAndSeqFocus("")}
          onFocus={() => setTestNameAndSeqFocus("department")}
        />
      </TableCell>
      <TableCell
        style={{
          width: `${columns.headers[3].width}%`,
          maxWidth: "150px",
        }}
      >
        <Tooltip
          title={getLabResult(subTest.labResult)}
          arrow
          placement="right"
        >
          <Box width={labResultRef?.current?.offsetWidth}>
            <MultiSelectWithCheckbox
              formControlStyle={{
                width: labResultRef?.current?.offsetWidth - 2 || "100%",
                marginLeft: "5px",
                "& .MuiInputBase-formControl": {
                  boxShadow: "unset",
                },
              }}
              value={subTest.result || []}
              label=""
              width=""
              options={labResultOptions}
              sx={{
                ...styles.selectFieldStyle,
                "& .MuiSelect-select .MuiSelect-outlined": {
                  paddingRight: "10px",
                },
                "& .MuiSelect-icon": {
                  display:
                    testNameAndSeqFocus === "labResult"
                      ? "inline-flex"
                      : "none",
                },
                "&:hover .MuiSelect-icon": {
                  display: "inline-flex",
                },
              }}
              menuStyle={{
                marginLeft: "-5px",
              }}
              name="result"
              onChange={(event: any) => onChange(event, rowIndex, index)}
              onBlur={() => setTestNameAndSeqFocus("")}
              onFocus={() => setTestNameAndSeqFocus("labResult")}
            />
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell
        style={{
          width: `${columns.headers[4].width}%`,
          border: !!error?.methodology_id ? "2px solid #d32f2f" : "",
          borderBottom: "1px solid #d32f2f !important",
        }}
        sx={{
          "& .MuiTableCell-body:has(.Mui-focused)": {
            borderBottomColor: "#d32f2f !important",
          },
        }}
      >
        <Select
          formControlStyle={{
            width: "100%",
            marginLeft: "5px",
            "& .MuiInputBase-formControl": {
              boxShadow: "unset",
            },
          }}
          value={subTest.methodology_id}
          label=""
          width=""
          options={methodologyOptions}
          sx={{
            ...styles.selectFieldStyle,
            "& .MuiSelect-icon": {
              display:
                testNameAndSeqFocus === "methodology" ? "inline-flex" : "none",
            },
            "&:hover .MuiSelect-icon": {
              display: "inline-flex",
            },
          }}
          menuStyle={{
            marginLeft: "-5px",
          }}
          name="methodology_id"
          onChange={(event: any) => onChange(event, rowIndex, index)}
          onBlur={() => setTestNameAndSeqFocus("")}
          onFocus={() => setTestNameAndSeqFocus("methodology")}
        />
      </TableCell>
      <TableCell style={{ width: `${columns.headers[5].width}%` }}>
        <TextField
          fullWidth
          formControlStyle={{
            width: "100%",
            marginLeft: "15px",
          }}
          value={subTest.investigation}
          sx={{ ...styles.textFieldStyle }}
          name="investigation"
          onChange={(event: any) => onChange(event, rowIndex, index)}
        />
      </TableCell>
      <TableCell style={{ width: `${columns.headers[6].width}%` }}>
        <TextField
          fullWidth
          formControlStyle={{
            width: "100%",
            marginLeft: "15px",
          }}
          value={subTest.unit}
          sx={{
            ...styles.textFieldStyle,
            "& input": {
              textAlign: "left !important",
              paddingLeft: "20px",
            },
          }}
          name="unit"
          onChange={(event: any) => onChange(event, rowIndex, index)}
        />
      </TableCell>
      <TableCell style={{ width: `${columns.headers[7].width}%` }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <img
            style={{ cursor: "pointer" }}
            onClick={() => onClickRange(rowIndex, index)}
            src={RefRangeImg}
          />
        </Box>
      </TableCell>
      <TableCell style={{ width: `${columns.headers[8].width}%` }}>
        <Box
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <NotesIcon
            width={15}
            height={15}
            cursor={"pointer"}
            onClick={() =>
              setIsOpenNotesModal({
                isOpen: true,
                rowIndex,
                index,
                value: subTest.note,
              })
            }
          />
        </Box>
      </TableCell>
      <TableCell
        style={{
          width: `${columns.headers[9].width}%`,
          border: !!error?.price ? "2px solid #d32f2f" : "",
        }}
      >
        <TextField
          fullWidth
          formControlStyle={{
            width: "100%",
            marginLeft: "15px",
          }}
          value={subTest.price}
          sx={{ ...styles.textFieldStyle }}
          name="price"
          onChange={(event: any) => onChange(event, rowIndex, index)}
        />
      </TableCell>
      <TableCell style={{ width: `${columns.headers[10].width}%` }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box>
            <Box display="flex" alignItems="center" flexDirection="row">
              <Switch
                Details={false}
                name={"active"}
                value={Boolean(subTest.active)}
                sx={{ width: "20px" }}
                onChange={(event: any) =>
                  onChange(
                    {
                      target: {
                        name: event?.target?.name,
                        value: event?.target?.checked ? 1 : 0,
                      },
                    },
                    rowIndex,
                    index
                  )
                }
              />
            </Box>
          </Box>
          <Box
            onClick={() => {
              handleClose();
              onAddSubTest(rowIndex);
            }}
            sx={{
              "& .MuiPopover-paper .MuiMenu-paper": {
                boxShadow: "0px 5px 10px 0px rgba(35, 35, 35, 0.08)",
              },
              paddingLeft: "5px !important",
              cursor: "pointer",
            }}
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <PlusIcon
                style={{
                  width: "12px",
                  height: "12px",
                  margin: "0 10px",
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              handleClose();
              onDelete(rowIndex, index, subTest?.id);
            }}
          >
            <Box display="flex" alignItems="center" flexDirection="row">
              <DeleteIcon
                style={{
                  width: "15px",
                  height: "15px",
                  margin: "0 7px 0 -1px",
                }}
              />
            </Box>
          </Box>
        </Box>
      </TableCell>
      {isOpenNotesModal.isOpen && (
        <NotesModal
          isOpen={isOpenNotesModal.isOpen}
          value={isOpenNotesModal.value}
          onClose={() =>
            setIsOpenNotesModal({
              isOpen: false,
              rowIndex: null,
              value: null,
              index: null,
            })
          }
          onSave={(value) =>
            onChange({ target: { name: "note", value } }, rowIndex, index)
          }
        />
      )}
    </TableRow>
  );
};

export default React.memo(ItemRow);
