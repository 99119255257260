import { useCallback, useEffect, useState } from "react";
import { Typography, Box, IconButton, debounce } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable, ConfirmationDialog } from "../../../../components/shared";
import { Sort } from "../../../../components/basic";
import { DeleteIcon, EditIcon, ViewIcon } from "../../../../assets/icons";
import { RouteUrls } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import {
  deleteLabPurchaseBill,
  getAllLabPurchasePayments,
} from "../../../../services/laboratoryService";
import { setIsAdvance } from "../../../../redux/slices/procurement";
import PermissionUtils from "../../../../utils/PermissionUtils";

type GridRowData = Record<string, unknown>;

const PurchasePaymentList = () => {
  const { createUrl, editUrl, viewUrl, laboratoryUrl, labPurchasePayments } =
    RouteUrls;
  const navigate = useNavigate();
  const { can } = PermissionUtils();

  const [purchasePaymentsList, setPurchasePaymentsList] = useState<[]>([]);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string | number | null>("");
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const { procurementPurchasePayment } = useSelector(
    (state: RootState) => state.pagination
  );
  const dispatch = useDispatch();
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [purchasePaymentsToBeDeleted, setPurchasePaymentsToBeDeleted] =
    useState<any>(null);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const deleteVacation = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setPurchasePaymentsToBeDeleted(row.id);
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {can("lab_purchasebill_pay_edit") && (
          <IconButton
            aria-label=""
            onClick={() => {
              navigate(
                `${laboratoryUrl}${labPurchasePayments}${editUrl}/${row.id}`
              );
              dispatch(setIsAdvance(row.is_advance));
            }}
          >
            <EditIcon />
          </IconButton>
        )}
        {can("lab_purchasebill_pay_view") && (
          <IconButton
            aria-label=""
            onClick={() => {
              navigate(
                `${laboratoryUrl}${labPurchasePayments}${viewUrl}/${row.id}`
              );
            }}
          >
            <ViewIcon />
          </IconButton>
        )}
        {can("lab_purchasebill_pay_delete") && (
          <IconButton aria-label="" onClick={() => deleteVacation(row)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },

    {
      field: "date_created",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "date" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "date" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.date_created}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "cash_paid",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("cash_paid")}
        >
          <Typography variant="h5" fontSize={14}>
            Cash Paid
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "cash_paid" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "cash_paid" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.cash_paid}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "type",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography variant="h5" fontSize={14}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "type" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "type" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.type}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    ...(can("lab_purchasebill_pay_edit") ||
    can("lab_purchasebill_pay_add") ||
    can("lab_purchasebill_pay_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  height: "51px",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllLabPurchasePayments({
        start: data.page,
        length: data.pageSize,
        column: data.sortBy,
        order: data.sortOrder,
        search: data.search,
      })
        .then((res) => {
          setPurchasePaymentsList(res.data.result);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    dispatch(setIsAdvance(null));
    const data = {
      page: Number(procurementPurchasePayment.page) + 1,
      pageSize: procurementPurchasePayment.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      search: searchValue,
    };
    debouncedGetAllAppointment(data);
  }, [
    procurementPurchasePayment.page,
    procurementPurchasePayment.pageSize,
    sortedField.order,
    sortedField.field,
    searchValue,
    sortedField.order,
    sortedField.field,
  ]);
  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const deletePaymentById = async () => {
    if (purchasePaymentsToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    await deleteLabPurchaseBill(purchasePaymentsToBeDeleted as number)
      .then((res: any) => {
        setPurchasePaymentsToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        debouncedGetAllAppointment({
          page: procurementPurchasePayment.page,
          pageSize: procurementPurchasePayment.pageSize,
        });
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        setDeleteBtnLoader(false);
      })
      .catch((err) => {
        setPurchasePaymentsToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
        console.log(err);
      });
  };
  return (
    <Box>
      <CustomFilter
        isSearchEnabled={true}
        searchOnChange={debounce(
          (e: any) => setSearchValue(e.target.value),
          1000
        )}
        {...(can("lab_purchasebill_pay_add") && {
          editButtonTitle: "+ Add",
          onUpdateButtonClick: () =>
            navigate(`${laboratoryUrl}${labPurchasePayments}${createUrl}`),
        })}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={purchasePaymentsList}
        pageCount={pageCount}
        currentPage={"procurementPurchasePayment"}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="are you surely want to delete?"
        handleClick={deletePaymentById}
        onClose={onClose}
        loading={deleteBtnLoader}
      />
    </Box>
  );
};

export default PurchasePaymentList;
