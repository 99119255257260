import ApiUtils from "../utils/ApiUtils";
import {
  mainCoreEndPoints,
  procurementsApiEndPoints,
  procurementsMasterApiEndPoints,
} from "../constants/apiEndPoints";
import QueryParamsBuilder from "../helper/QueryParamsBuilder";
import { PatientsUserTypes } from "../views/Accounts/EditUser";

export type PatientsTypes = {
  id: number;
  title: string;
  name: string;
  blood_group: string;
  mobile: string;
  patient_mobile: string;
  sex: number;
  age: number;
  dob: string;
  uhid: string;
  email: string;
  area: string;
};
export type UserPatientsTypes = {
  id: string | number | null;
  age: string | number | null;
  user_id: string;
  title: string;
  name: string;
  mobile: string;
  line_1: null | string;
  line_2: null | string;
  area: string;
  relation_type: null | string;
  relation_name: string;
  district: string;
  pincode: string;
  patient_uhid: string;
};

const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);

  let queryString = "?";
  keys.map((key) => {
    if (data[key] && !Array.isArray(data[key])) {
      queryString += `${key}=${data[key]}&`;
    }
    if (Array.isArray(data[key])) {
      data[key].map(
        (ele: any, index: number) => (queryString += `${key}[${index}]=${ele}&`)
      );
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};

const {
  updateSequenceNumber,
  getSequenceNumber,
  getUserDetailsByMobileNumber,
} = mainCoreEndPoints;

const createPatientData = async (data: any) => {
  return await ApiUtils.postWithToken(mainCoreEndPoints.createPatient, data);
};

const getAllPatients = async (params: {
  page: number | string | null;
  per_page: number;
  search?: string;
}) => {
  const apiUrl = setQueryParams(params, mainCoreEndPoints.getPatients);
  return await ApiUtils.getWithToken(apiUrl);
};

const getAllPatientsList = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
  patient_id,
}: any): Promise<{
  data: { result: PatientsTypes[]; totalRecords: number | null };
}> => {
  const apiUrl = setQueryParams(
    { page, size: pageSize, sortBy, sortOrder, searchValue, patient_id },
    mainCoreEndPoints.getAllPatientsList
  );

  return (await ApiUtils.getWithToken(apiUrl)) as {
    data: { result: PatientsTypes[]; totalRecords: number | null };
  };
};
const getPatientUser = async (
  id: string | null | any
): Promise<{
  data: PatientsUserTypes;
}> => {
  return (await ApiUtils.getWithToken(
    `${mainCoreEndPoints.getPatientUser}/${id}`
  )) as {
    data: PatientsUserTypes;
  };
};
const updatePatientUser = async (
  id: string | null | any,
  body: any
): Promise<{
  data: {};
}> => {
  return (await ApiUtils.putWithToken(
    `${mainCoreEndPoints.updatePatientUser}/${id}`,
    body
  )) as {
    data: {};
  };
};
const getAllUserPatientsList = async ({
  page,
  pageSize,
  userId,
}: any): // sortBy,
// sortOrder,
// searchValue,
Promise<{
  data: { result: UserPatientsTypes[]; totalRecords: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: mainCoreEndPoints.getUserPatientsList,
    page,
    size: pageSize,
    userId: userId,
    // sortBy,
    // sortOrder,
    // searchValue,
  });

  return (await ApiUtils.getWithToken(apiUrl)) as {
    data: { result: UserPatientsTypes[]; totalRecords: number | null };
  };
};
const getPatientDetails = async (
  id: string | null | any
): Promise<{
  data: PatientsUserTypes;
}> => {
  return (await ApiUtils.getWithToken(
    `${mainCoreEndPoints.getPatientDetails}/${id}`
  )) as {
    data: PatientsUserTypes;
  };
};
const updatePatientDetails = async (
  id: string | null | any,
  body: any
): Promise<{
  data: {};
}> => {
  return (await ApiUtils.putWithToken(
    `${mainCoreEndPoints.updatePatientDetails}/${id}`,
    body
  )) as {
    data: {};
  };
};
const getDepartmentSequence = async (department?: string | number) => {
  try {
    return await ApiUtils.getWithToken(
      `${procurementsApiEndPoints.sequenceUrl}/${department}`
    );
  } catch (error) {
    console.log("Error while getting the item name list", error);
    throw error;
  }
};

const updatedSequence = async (sequenceId: number, data: any) => {
  return await ApiUtils.putWithToken(
    `${procurementsMasterApiEndPoints.sequenceUpdateUrl}/${sequenceId}`,
    data
  );
};

const getSequenceNo = async (departmentName: string) => {
  return await ApiUtils.getWithToken(`${getSequenceNumber}/${departmentName}`);
};

const updateSequenceNo = async (sequenceId: number, data: any) => {
  return await ApiUtils.putWithToken(
    `${updateSequenceNumber}/${sequenceId}`,
    data
  );
};

const getUserDetailsByMobile = async (mobile: any) => {
  return await ApiUtils.getWithToken(
    `${getUserDetailsByMobileNumber}=${mobile}`
  );
};

export {
  updatedSequence,
  getDepartmentSequence,
  createPatientData,
  getAllPatients,
  getAllPatientsList,
  getPatientUser,
  updatePatientUser,
  getAllUserPatientsList,
  getPatientDetails,
  updatePatientDetails,
  getSequenceNo,
  updateSequenceNo,
  getUserDetailsByMobile,
};
