import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { formula } from "../../../utils/CalculationUtils";
import {
  Button,
  SaveButton,
  CancelButton,
  PageLoader,
  DeleteDialog,
} from "../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import Summary from "./Summary";
import { purchaseReturnConst } from "../../../constants/displayText";
import {
  addPurchaseReturnProduct,
  getPurchaseReturnById,
  getPurchaseReturnProduct,
  updatePurchaseReturnProduct,
} from "../../../services/gynecologyService";
import {
  timeWithTwentyFourHoursConverter,
  formatTwelveHoursTime,
  formattedDateWithSlash,
} from "../../../utils/DateTimeFormatUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../redux/store";
import SequenceWrapper from "../../../components/shared/sequenceWrapper";
import {
  getDepartmentSequence,
  updatedSequence,
} from "../../../services/mainCoreService";
type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right";
  sx?: {};
};

type InfoType = {
  supplier_name: string | null;
  department: string | null;
  invoice_value: string | null;
  invoice_date: any | string | null;
  bill_no: string | null;
  disabled: boolean;
  to_dept_id?: any | number;
  to_dept_name?: any | string;
};

const BillProducts = ({
  suppliersList,
  departmentList,
  getAllSuppliersList,
  issuerList,
}: any) => {
  const {
    productName,
    stock,
    free,
    retQty,
    fRetQty,
    discount,
    batchNo,
    expiryDate,
    tax,
    ptr,
    amount,
  } = purchaseReturnConst;

  const {
    calculateDiscountAmount,
    calculateTaxAmount,
    calculateAmount,
    calculateBillAmount,
  } = formula.purchaseReturn;
  const [productRow, setProductRow] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [deletedId, setDeletedId] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    selectedRow: {
      id?: number;
      batchNo?: string;
      rowId: number;
    } | null;
  }>({
    isOpen: false,
    selectedRow: null,
  });
  const [customSequence, setCustomSequence] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: 1,
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },
    }),
    []
  );

  const TableHeaderCells: any[] = [
    { TableCellName: productName, alignment: "left" },
    ...(id
      ? [{ TableCellName: "Max.Qty", alignment: "right" }]
      : [{ TableCellName: stock, alignment: "right" }]),
    ...(id
      ? [{ TableCellName: "M.Free", alignment: "right" }]
      : [{ TableCellName: free, alignment: "right" }]),
    { TableCellName: retQty, alignment: "right" },
    { TableCellName: fRetQty, alignment: "right" },
    { TableCellName: "Discount", alignment: "right" },
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: expiryDate, alignment: "left" },
    { TableCellName: tax, alignment: "right" },
    { TableCellName: ptr, alignment: "right" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  const initialBillProducts = {
    id: null,
    product_name: null,
    autoComplete_input_value: null,
    stock: null,
    free: null,
    ret_qty: null,
    f_ret_qty: null,
    discount: "",
    batch_no: "",
    expiry_date: "",
    tax: "",
    ptr: "",
    amount: null,
    discount_amount: null,
    tax_amount: null,
  };
  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const [billProducts, setBillProducts] = useState<any>([initialBillProducts]);
  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });
  const [isManualAdded, setIsManualAdded] = useState(false);
  const [sequenceNo, setSequenceNo] = useState("");
  const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
  const [autoGeneratedSeqNo, setAutoGeneratedSeqNo] = useState("");
  const [seqIsOnce, setSeqIsOne] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });
  const [summary, setSummary] = useState({
    net_amount: 0,
    rounded_off: 0,
    bill_amount: 0,
  });

  const initialInfo = {
    supplier_name: "",
    department: "",
    invoice_value: "",
    invoice_date: new Date(),
    bill_no: "",
    disabled: false,
    to_dept_id: "",
    to_dept_name: "",
  };

  const [info, setInfo] = useState<InfoType>(initialInfo);
  const [batchIdNo, setBatchIdNo] = useState<any[]>([]);
  const [formError, setFormError] = useState<
    {
      ret_qty: boolean;
      free_ret_qty: boolean;
    }[]
  >([
    {
      ret_qty: false,
      free_ret_qty: false,
    },
  ]);
  const [errors, setErrors] = useState<any>([]);

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number | string) => {
      const { name, value } = e.target;

      let numericValue = value.replace(/\D/g, "");
      setCurrentIndex(index as any);
      setBillProducts((prevState: any) => {
        const newState = [...prevState];

        newState[index as number] = {
          ...newState[index as number],
          [name]: numericValue,
        };
        handleValidation(
          { target: { name: name, value: newState[index as number][name] } },
          newState[index as number],
          index
        );
        newState[index as number] = {
          ...newState[index as number],
          amount: handleCalculateAmount(
            newState[index as number],
            newState[index as number]?.product_name
          ),
        };

        return newState;
      });
    },
    []
  );

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };
  const handleInvoiceNoChange = () => {
    if (autoGeneratedSeqNo !== info.bill_no && Boolean(!isManual.is_once)) {
      setSeqIsOne((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.bill_no,
      }));
      setSequenceDialogBox(true);
      setIsManual((prevState) => ({
        ...prevState,
        is_manual: false,
        is_auto_generate: false,
        is_once: true,
      }));
    }
  };
  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;
  const handleSequence = () => {
    getDepartmentSequence("Purchase Return")
      .then((res: any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: res?.data[0]?.sequence_no,
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            ip_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: res?.data[0]?.sequence_no,
          }));
          setErrors((prevState: any) => ({
            ...prevState,
            ip_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setAutoGeneratedSeqNo(res?.data[0]?.sequence_no);
          setSequenceNo(res.data[0].sequence.id);
          const newSequenceData = res.data[0].sequence.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res.data[0].sequence.is_manual === 1 ? true : false,
            is_auto_generate:
              res.data[0].sequence.is_manual !== 1 ? true : false,
          }));
          setSeqIsOne((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res.data[0].sequence.is_manual === 1));
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);
  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value === "Select" ? "" : value,
      };
      return newState;
    });
  };
  function onCloseSequenceDialogBox() {
    setSequenceDialogBox(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceNo), data)
        .then((res) => {
          if (res) {
            isSequenceNumberChanged = true;
            handleSequence();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setSnackBarFailed({ snackBarMessage: err.message }));
        });
    }
  };
  const handleValidation = (
    e: any,
    updatedValue: any,
    idx: number | string
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "ret_qty": {
        if (value > Number(updatedValue.stock)) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["ret_qty"] = true;
            return newState;
          });
        } else if (
          value >
          Number(updatedValue.stock | 0) - Number(updatedValue.f_ret_qty | 0)
        ) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["ret_qty"] = true;
            return newState;
          });
        } else {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["ret_qty"] = false;
            return newState;
          });
        }
        break;
      }
      case "f_ret_qty": {
        if (value > Number(updatedValue.free)) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["free_ret_qty"] = true;
            return newState;
          });
        } else if (
          Number(value) + Number(updatedValue.ret_qty) >
          Number(updatedValue.stock | 0)
        ) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["free_ret_qty"] = true;
            return newState;
          });
        } else {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["free_ret_qty"] = false;
            return newState;
          });
        }
        break;
      }
      default:
        break;
    }
  };

  const handleCalculation = (name: string, productDetail: any) => {
    let num: string | number = 0;
    switch (name) {
      case "stock": {
        if (appConfiguration?.goods_issue_disable) {
          num = productDetail?.original_qty
            ? (
                parseInt(productDetail?.original_qty) +
                parseInt(
                  productDetail?.adj_qty < 0 ? productDetail?.adj_qty : 0
                ) -
                parseInt(productDetail?.return_qty) -
                parseInt(productDetail?.sale_qty)
              ).toString()
            : "";
        } else {
          num = productDetail?.original_qty
            ? (
                parseInt(productDetail?.original_qty) +
                parseInt(productDetail?.free || 0) +
                parseInt(
                  productDetail?.adj_qty < 0 ? productDetail.adj_qty : 0
                ) -
                parseInt(productDetail?.return_qty) -
                parseInt(productDetail?.sale_qty) -
                parseInt(productDetail?.free_return_qty || 0)
              ).toString()
            : "";
        }
        break;
      }
      case "free": {
        num =
          parseInt(productDetail?.free == null ? 0 : productDetail?.free) -
          parseInt(
            productDetail?.free_return_qty == null
              ? 0
              : productDetail?.free_return_qty
          );
        break;
      }
      case "p_rate": {
        num = productDetail?.p_rate
          ? parseFloat(
              String(
                productDetail.p_rate /
                  parseInt(
                    productDetail.pack_type ? productDetail.pack_type : "1"
                  )
              )
            ).toFixed(2)
          : "";

        break;
      }
      default: {
        break;
      }
    }
    return num;
  };

  const handleAutoCompleteChange = useCallback(
    (newValue: any, idx: number | string, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevState: any) => {
        let newState = [...prevState];
        if (newState.length > 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            stock: handleCalculation("stock", newValue),
            free: handleCalculation("free", newValue),
            ptr: handleCalculation("p_rate", newValue),
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            // `${newValue.batch_no}#${newValue.original_qty}`
            bill_batch_id: newValue.batch_id,
            batch_no: newValue.batch_no,
            amount: 0,
            discount: newValue.discount || 0,
            discount_type: newValue.discount_type,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            id: null,
            ref_batch_id: newValue.batch_id,
          };
        }
        if (newState.length === 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            stock: handleCalculation("stock", newValue),
            free: handleCalculation("free", newValue),
            ptr: handleCalculation("p_rate", newValue),
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            batch_no: newValue.batch_no,
            amount: 0,
            discount_type: newValue.discount_type,
            discount: newValue.discount || 0,
            bill_batch_id: newValue.batch_id,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
            id: null,
            ref_batch_id: newValue.batch_id,
          };
        }
        setBatchIdNo((prev) => [...prev, newValue?.batch_id]);
        return newState;
      });
    },
    [appConfiguration]
  );
  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProducts,
        id: prev.length,
      },
    ]);
    if (billProducts.length > 0) {
      setFormError((prev) => [
        ...prev,
        { ret_qty: false, free_ret_qty: false },
      ]);
    }
  }, []);

  const handleDelete = () => {
    const { id, batchNo, rowId }: any = isDeleteDialogOpen.selectedRow;
    setLoading(true);
    const newBillProducts: any = [...billProducts];
    newBillProducts.splice(id, 1);
    setBillProducts(newBillProducts);
    if (billProducts.length > 0) {
      const newErrorValue: any = [...formError];
      newErrorValue.splice(id, 1);
      setFormError(newErrorValue);
    }
    if (rowId) {
      setDeletedId((prevState: any) => [...prevState, rowId]);
    }
    if (batchNo) {
      const newBatchIds = [...batchIdNo];
      const findBatchIndex = batchIdNo.findIndex(
        (ele) => ele === Number(batchNo)
      );
      newBatchIds.splice(findBatchIndex, 1);
      setBatchIdNo(newBatchIds);
    }
    setLoading(false);
    setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
  };
  const handleCalculateAmount = (product: any, productDetail: any) => {
    let amount_value: string | number = 0;
    let disc_value: string | number = 0;
    let tax_value: string | number = 0;
    const per_rate =
      Number(productDetail?.p_rate) / Number(productDetail?.pack_type);
    const isInclusive =
      productDetail?.tax_settings === 0 ? "inclusive" : "exclusive";
    if (isNaN(product?.ret_qty) || isNaN(per_rate)) {
      amount_value = 0;
    } else {
      amount_value = (product?.ret_qty * per_rate).toFixed(2);
    }

    if (productDetail?.discount_type === "%") {
      if (productDetail?.discount > 100) {
        //throw new error
      } else {
        if (isNaN(productDetail?.discount) || isNaN(amount_value as number)) {
          disc_value = 0;
        } else {
          disc_value = (
            (productDetail?.discount / 100) *
            Number(amount_value)
          ).toFixed(2);
        }
      }
    } else {
      if (productDetail?.discount > product?.ret_qty * per_rate) {
      } else {
        if (isNaN(productDetail?.discount) || isNaN(amount_value as number)) {
          disc_value = 0;
        } else {
          disc_value = Number(productDetail?.discount);
        }
      }
    }

    amount_value = Number(amount_value) - Number(disc_value);

    let tax_per = productDetail?.tax_id;
    if (isNaN(tax_per)) {
      tax_value = 0;
    } else {
      if (isInclusive === "exclusive") {
        tax_value = (Number(amount_value) * tax_per) / 100;
      } else {
        tax_value = 0;
      }
    }
    amount_value = (
      amount_value ? Number(amount_value) + Number(tax_value) : 0
    ).toFixed(2);
    return amount_value;
  };
  const updateBillData = () => {
    const billAmount = calculateBillAmount(billProducts);
    const decimalPart = Math.round((billAmount % 1) * 100);
    const roundedValue: any =
      Math.trunc(billAmount) + (decimalPart >= 50 ? 1 : 0);
    const difference = roundedValue - billAmount;
    setSummary((prevState) => ({
      ...prevState,
      bill_amount: isNaN(billAmount) ? 0 : billAmount,
      rounded_off: isNaN(difference) ? 0 : difference,
      net_amount: isNaN(roundedValue) ? 0 : roundedValue,
    }));
    setInfo((prev) => ({
      ...prev,
      invoice_value: roundedValue || "0.00",
    }));
  };
  const validateForm = (details: any, billProductsList: any) => {
    let validate = true;
    const emptyRetQty = billProductsList.findIndex(
      (ele: { f_ret_qty: string; ret_qty: string }) => Boolean(!ele.ret_qty)
    );
    if (details.supplier_name === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Supplier not to be empty!" })
      );
      validate = false;
    }
    if (details.department === "") {
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "department or supplier not to be empty!",
        })
      );
      validate = false;
    }
    if (details.invoice_date === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Invoice Date not to be empty!" })
      );
      validate = false;
    }
    if (details.invoice_value === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Invoice value not to be empty!" })
      );
      validate = false;
    }
    if (
      Boolean(formError.find((ele) => ele.free_ret_qty)) ||
      Boolean(formError.find((ele) => ele.ret_qty))
    ) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    if (emptyRetQty !== -1) {
      const newFormError = [...formError];
      newFormError[emptyRetQty as number].ret_qty = true;
      setFormError(newFormError);
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Quantity not to be Empty!" })
      );
      validate = false;
    }
    return validate;
  };

  const handleSubmit = async () => {
    if (validateForm(info, billProducts)) {
      setIsSubmitLoaded(true);
      const productDetails: any =
        billProducts.length > 0
          ? billProducts.map((obj: any) => {
              let newObj = {};
              const {
                product_id,
                pack_type,
                discount,
                batch_id,
                expires_at,
                tax_id,
                tax_type,
                p_rate,
              } = obj.product_name;
              newObj = {
                product_id,
                pack_type,
                return_qty: obj.ret_qty,
                free_return_qty: obj.f_ret_qty,
                discount,
                batch_id,
                expires_at,
                tax_id,
                tax_type,
                p_rate: p_rate,
                amount: obj.amount,
                discount_type: obj.discount_type,
                id: obj.id,
                batch_no: obj.ref_batch_id,
              };
              return newObj;
            })
          : 0;
      const data: any = {
        supplier_id: info.supplier_name,
        to_dept: appConfiguration?.goods_issue_disable ? info.department : null,
        dept_id: appConfiguration?.goods_issue_disable ? 1 : info.department,
        invoice_value: info.invoice_value,
        invoice_date: info.invoice_date
          ? timeWithTwentyFourHoursConverter(info.invoice_date)
          : "",
        ref_no: info.bill_no,
        ref_no_modified: info.bill_no === initialSequenceNumber ? 0 : 1,
        product: [...productDetails],
        total_amt: summary.bill_amount,
        bill_amt: summary.net_amount,
      };
      if (id) {
        if (deletedId?.length > 0) {
          data.hidden_del_id = deletedId.join();
        }
        updatePurchaseReturnProduct(id as string, data as any)
          .then((res: any) => {
            navigate("/procurement/purchase-bill");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            if (err?.response?.data?.errors?.data) {
              const { data, message } = err.response.data.errors;
              const newBillProducts = [...billProducts];
              newBillProducts[data.key].stock = data.stock;
              newBillProducts[data.key].free = data?.free_stock;
              setBillProducts(newBillProducts);
              const newFormError = [...formError];
              newFormError[data.key]["ret_qty"] = true;
              if (data?.free_stock) {
                newFormError[data.key]["free_ret_qty"] = true;
              }
              setFormError(newFormError);

              dispatch(setSnackBarFailed({ snackBarMessage: message }));
              console.log(err);
              setIsSubmitLoaded(false);
            } else if (typeof err?.response?.data?.errors === "string") {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            setIsSubmitLoaded(false);
            console.log(err);
          });
      } else {
        await addPurchaseReturnProduct(data as any)
          .then((res: any) => {
            navigate("/procurement/purchase-bill");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            if (err?.response?.data?.errors?.data) {
              const { data, message } = err.response.data.errors;
              const newBillProducts = [...billProducts];
              newBillProducts[data.key].stock = data.stock;
              newBillProducts[data.key].free = data?.free_stock;
              setBillProducts(newBillProducts);
              const newFormError = [...formError];
              newFormError[data.key]["ret_qty"] = true;
              if (data?.free_stock) {
                newFormError[data.key]["free_ret_qty"] = true;
              }
              setFormError(newFormError);

              dispatch(setSnackBarFailed({ snackBarMessage: message }));
              console.log(err);
              setIsSubmitLoaded(false);
            } else if (typeof err?.response?.data?.errors === "string") {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            setIsSubmitLoaded(false);
            console.log(err);
          });
      }
    }
  };
  const updateRows = (currentIndex: number) => {
    const amount = calculateAmount(billProducts, currentIndex);
    const discountAmount = calculateDiscountAmount(billProducts, currentIndex);
    const taxAmount = calculateTaxAmount(billProducts, currentIndex);

    if (
      amount !== +billProducts[currentIndex]?.amount ||
      taxAmount !== billProducts[currentIndex]?.tax_amount ||
      discountAmount !== billProducts[currentIndex]?.discount_amount
    ) {
      setBillProducts((prev: any) =>
        prev.map((billProduct: any, i: number) => {
          return i === currentIndex
            ? {
                ...billProduct,
                amount: amount,
                discount_amount: discountAmount,
                tax_amount: taxAmount,
              }
            : billProduct;
        })
      );
    }
  };
  useEffect(() => {
    updateRows(currentIndex);
  }, []);

  useEffect(() => {
    updateBillData();
  }, [billProducts]);
  useEffect(() => {
    if (
      (!appConfiguration?.goods_issue_disable &&
        info.supplier_name &&
        info.department) ||
      (appConfiguration?.goods_issue_disable &&
        info.supplier_name &&
        info.to_dept_id)
    ) {
      const data: any = {
        supplier_id: info.supplier_name,
        dept_id: !appConfiguration?.goods_issue_disable
          ? info.department
          : info.to_dept_id,
        selected_batches: batchIdNo,
      };
      getPurchaseReturnProduct(data)
        .then((res: any) => setProductRow(res.data))
        .catch((err: any) => console.log(err));
    }
  }, [info.department, info.supplier_name, batchIdNo]);

  useEffect(() => {
    if (id) {
      setIsPageLoading(true);
      getPurchaseReturnById(id as string)
        .then((res: any) => {
          const bill = res.data.bill;
          const billProducts = res.data.bill_products;
          setInfo((prevState) => ({
            ...prevState,
            bill_no: bill?.ref_no,
            department: bill.dept_id,
            department_name: bill?.dept_name,
            to_dept_id: bill?.to_dept,
            to_dept_name: bill?.to_dept_name,
            supplier: bill.name,
            invoice_date: formatTwelveHoursTime(bill?.invoice_date),
            invoice_value: Number(bill?.invoice_value || 0).toFixed(2),
            supplier_name: bill?.supplier_id,
            disabled: true,
          }));

          const newData = billProducts.map((ele: any) => ({
            product_id: ele.product_id,
            product_name: ele,
            stock_qty: ele.stock_qty,
            isId: id,
            stock: ele.stock,
            free: ele.free_stock,
            ptr: handleCalculation("p_rate", ele),
            ret_qty: ele.return_qty,
            f_ret_qty: ele.free_return_qty,
            discount_type: ele.discount_type,
            discount: ele.discount || 0,
            batch_no: ele.batch_no,
            bill_batch_id: ele.batch_id,
            tax: `${ele.taxname}-${ele.tax_id}%`,
            expiry_date: formattedDateWithSlash(ele.expires_at),
            amount: Number(ele.amount).toFixed(2) || 0,
            id: ele.id,
            row_id: id,
            ref_batch_id: ele.ref_batch_id,
          }));
          const newError = newData.map((ele: any) => ({
            ret_qty: false,
            free_ret_qty: false,
          }));
          setInitialSequenceNumber(bill?.ref_no);

          setFormError(newError);
          setBillProducts(newData);
          setIsPageLoading(false);
          const newBatchIds = newData.map(
            (ele: { ref_batch_id: number }) => ele.ref_batch_id
          );
          setBatchIdNo(newBatchIds);
          isCreatePrescription = false;
          isSequenceNumberChanged = false;
          handleSequence();
        })
        .catch((err: any) => {
          setIsPageLoading(false);
          console.log(err);
        });
    } else {
      isCreatePrescription = true;
      isSequenceNumberChanged = false;
      handleSequence();
    }
  }, [id]);
  const handleDepartmentChange = () => {
    setBatchIdNo([]);
    setFormError([{ ret_qty: false, free_ret_qty: false }]);
    setBillProducts([
      {
        product_id: "",
        product_name: null,
        stock_qty: "",
        isId: "",
        stock: "",
        free: "",
        ptr: "",
        ret_qty: "",
        f_ret_qty: "",
        discount: "",
        batch_no: "",
        bill_batch_id: "",
        tax: "",
        expiry_date: "",
        amount: "",
      },
    ]);
  };
  return (
    <>
      {isPageLoading ? (
        <PageLoader />
      ) : (
        <Grid>
          <Info
            info={info}
            setInfo={setInfo}
            suppliersList={suppliersList}
            departmentList={departmentList}
            isGoodsIssue={appConfiguration?.goods_issue_disable}
            setSequenceDialogBox={setSequenceDialogBox}
            handleInvoiceNoChange={handleInvoiceNoChange}
            getAllSuppliersList={getAllSuppliersList}
            customSequence={customSequence}
            errors={errors}
            isEdit={!!id}
            handleDepartmentChange={handleDepartmentChange}
            issuerList={issuerList}
          />
          <Grid
            sx={{
              width: {
                xs: "92vw",
              },
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells.map((cell, index) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    height: "auto",
                    width: {
                      xs: "400vw",
                      sm: "200vw",
                      md: "120vw",
                      lg: "90vw",
                    },
                  }}
                >
                  {billProducts.map((row: any, index: number) => {
                    return (
                      <ProductRow
                        key={row.id}
                        index={index}
                        row={row}
                        styles={styles}
                        allRows={productRow}
                        formError={formError}
                        totalLength={billProducts.length}
                        handleInputChange={handleInputChange}
                        handleDelete={handleDelete}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <Grid
                sx={{
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "120vw",
                    lg: "90vw",
                  },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "0px 0px 5px 5px",
                  border: 1,
                  borderColor: "var(--table-border)",
                  borderTop: 0,
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: {
                      xs: "50%",
                    },
                  }}
                >
                  <Button
                    variant="text"
                    buttonText="+ Add Product"
                    handleClick={handleClick}
                    sx={{
                      borderRadius: "0px",
                      width: "auto",
                      height: "auto",
                      fontWeight: 400,
                      fontSize: "13px",
                      border: 0,
                      color: "primary.main",
                      "&:hover": {
                        backgroundColor: "initial",
                        color: "primary.main",
                        borderColor: "initial",
                      },
                      "&.Mui-focusVisible": {
                        border: 1,
                        borderColor: "primary.main",
                        borderRadius: "5px",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </TableContainer>
          </Grid>
          <Summary summary={summary} />
          <Grid
            sx={{ display: "flex", flexDirection: "row", m: "35px 0 45px 0" }}
          >
            <SaveButton
              handleClick={() => handleSubmit()}
              loading={isSubmitLoaded}
              sx={{ width: "100px", height: "42px", mr: "20px" }}
            />
            <CancelButton sx={{ width: "100px", height: "42px" }} />
          </Grid>
        </Grid>
      )}
      <SequenceWrapper
        open={sequenceDialogBox}
        title="Procurement Return"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      <DeleteDialog
        open={isDeleteDialogOpen.isOpen}
        loading={loading}
        onClose={() =>
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null })
        }
        onConfirm={handleDelete}
      />
    </>
  );
};

export default BillProducts;
