import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  debounce,
} from "@mui/material";
import SequenceWrapper from "../../../../components/shared/sequenceWrapper";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import {
  Button,
  SaveButton,
  CancelButton,
  PageLoader,
  DeleteDialog,
} from "../../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import Summary from "./Summary";
import { salesReturnConst } from "../../../../constants/displayText";
import {
  getAllMasterDepartments,
  getTestAllPatientsList,
} from "../../../../services/gynecologyService";
import {
  addSalesReturn,
  deletePharmacyReturnProduct,
  getPharmacyPatientBillNo,
  getPharmacyReturnBillById,
  getPharmacyReturnProducts,
  updatePharmacyReturnBillDetails,
  updateSalesReturn,
} from "../../../../services/pharmacyService";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { updatedSequence } from "../../../../services/procurementMasterService";
import { useNavigate, useParams } from "react-router-dom";
import { dateFormat } from "../../../../utils/DateTimeFormatUtils";
import { timeWithTwentyFourHoursConverter } from "../../../../utils/DateTimeFormatUtils";
import { getDepartmentSequence } from "../../../../services/mainCoreService";
import { useDispatch, useSelector } from "react-redux";

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right";
  sx?: {};
};

type SummaryType = {
  total_discount_amount: number | null;
  goods_value: number | null;
  tax_amount: number | null;
  bill_total: number | null;
  discount: number | null;
  bill_discount_type: string | null;
  bill_discount_amount: number | null;
  rounded_off: number | null;
  net_amount: number | null;
  refunded_amount: number | null;
};

const BillProducts = React.memo(() => {
  const {
    productName,
    billNo,
    billDiscount,
    salesDate,
    expiryDate,
    salesQty,
    salesPrice,
    salesDiscount,
    retQty,
    retPrice,
  } = salesReturnConst;

  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },

      selectStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "34px",
          maxHeight: "34px",
          p: 0,
        },

        "& .MuiInputBase-root": {
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },

        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );

  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: productName, alignment: "left" },
    { TableCellName: billNo, alignment: "left" },
    { TableCellName: billDiscount, alignment: "right" },
    { TableCellName: salesDate, alignment: "left" },
    { TableCellName: expiryDate, alignment: "left" },
    { TableCellName: salesQty, alignment: "right" },
    { TableCellName: salesPrice, alignment: "right" },
    { TableCellName: salesDiscount, alignment: "right" },
    { TableCellName: retQty, alignment: "right" },
    {
      TableCellName: retPrice,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];
  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const navigate = useNavigate();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const initialBillProduct = {
    product_id: null,
    product_input_value: "",
    ref_bill_id: null,
    mrp: null,
    pack_type: null,
    dept_id: null,
    batch_id: null,
    return_qty: null,
    discount: null,
    tax_type: null,
    tax_id: null,
    amount: null,
  };
  const [billNumberOptions, setBillNumberOptions] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    selectedRow: {
      id: number;
      batchNo?: string;
      billId?: number;
      rowId?: number;
    } | null;
  }>({
    isOpen: false,
    selectedRow: null,
  });
  const { id } = useParams();
  const [customSequence, setCustomSequence] = useState(true);

  const [formError, setFormError] = useState<
    {
      ret_qty: boolean;
    }[]
  >([
    {
      ret_qty: false,
    },
  ]);
  const [batchIdNo, setBatchIdNo] = useState<any[]>([]);
  const [billIdNo, setBillIdNo] = useState<any[]>([]);
  const [billProducts, setBillProducts] = useState<any>([initialBillProduct]);
  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });
  const [isManualAdded, setIsManualAdded] = useState(false);
  const [sequenceNo, setSequenceNo] = useState("");
  const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
  const [autoGeneratedSeqNo, setAutoGeneratedSeqNo] = useState("");
  const [seqIsOnce, setSeqIsOne] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });
  const initialSummary = {
    total_discount_amount: 0,
    goods_value: 0,
    tax_amount: 0,
    bill_total: 0,
    discount: null,
    bill_discount_type: "%",
    bill_discount_amount: 0,
    rounded_off: 0,
    net_amount: 0,
    refunded_amount: 0,
  };

  const [summary, setSummary] = useState<SummaryType>(initialSummary);
  const [summaryError, setSummaryError] = useState({
    discount: "",
    refunded_amount: "",
  });
  // form states and constants
  const [productRow, setProductRow] = useState([]);
  const handleSummaryChange = (e: any) => {
    const { name, value } = e?.target;
    setSummary((prevState: any) => {
      const newState = { ...prevState };
      newState[name] = value;
      const { bill_total } = handleSummaryData(billProducts);
      const bill_discount = calculateDiscountAmount(
        bill_total,
        newState.bill_discount_amount || 0,
        newState.bill_discount_type || "%"
      );

      const { net_amount, rounded_off } = calculateRoundedValue(
        bill_total,
        bill_discount as number
      );
      newState.rounded_off = rounded_off;
      newState.net_amount = net_amount;
      newState.bill_total = bill_total;
      newState.discount = bill_discount;

      const refundedAmount = newState.refunded_amount;
      const summaryState = newState;
      setSummaryError((prevState: any) => {
        const newState = { ...prevState };
        if (bill_total < bill_discount) {
          newState.discount = "discount is error";
        } else if (
          summaryState.bill_discount_type === "%" &&
          summaryState.bill_discount_amount > 100
        ) {
          newState.discount = "discount is error";
        } else if (
          summaryState.bill_discount_type === "₹" &&
          summaryState.bill_discount_amount > summaryState.bill_total
        ) {
          newState.discount = "discount is error";
        } else {
          newState.discount = "";
        }

        if (net_amount < refundedAmount) {
          newState.refunded_amount = "refunded amount is error";
        } else {
          newState.refunded_amount = "";
        }
        return newState;
      });

      return newState;
    });
  };

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");
  const handleReturnQtyValidation = (
    currentValue: any,
    idx: number | string
  ) => {
    if (Number(currentValue?.ret_qty) > Number(currentValue?.sale_qty)) {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["ret_qty"] = true;
        return newState;
      });
    } else {
      setFormError((prevState) => {
        const newState = [...prevState];
        newState[idx as number]["ret_qty"] = false;
        return newState;
      });
    }
  };
  const initialInfoError = {
    patient_id: "",
    mobile: "",
    patient_name: "",
    address: "",
    date_created: "",
    department: "",
    // days: "",
    // type: "",
    invoice_no: "",
  };
  const billTypes = [
    { id: "DAY", name: "Day" },
    { id: "MONTH", name: "Month" },
  ];
  const [infoError, setInfoError] = useState<any>(initialInfoError);

  const initialInfoData = {
    patient_id: null,
    mobile: "",
    patient_name: "",
    address: "",
    date_created: new Date(),
    department: null,
    // days: appConfiguration?.sales_return_days || null,
    // type: "DAY",
    invoice_no: null,
    bill_no: null,
    invoice_no_modified: 0,
  };
  const dispatch = useDispatch();
  const patientsPagination: any = useRef(1);
  const searchPatientsPagination = useRef({ search: "", page: 1 });
  const [patientsList, setPatientsList] = useState<any>([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [infoData, setInfoData] = useState<any>(initialInfoData);
  const [isEditPageLoading, setIsEditPageLoading] = useState(false);
  const fieldData = {
    UHID: {
      label: "UHID",
      name: "patient_id",
      value: id ? infoData.patient_name : infoData.patient_id,
      placeholder: "Select Patient",
      isError: Boolean(infoError.patient_id),
      helperText: infoError.patient_id,
    },
    mobile: {
      label: "Mobile",
      name: "mobile",
      value: infoData.mobile,
      isError: infoError.mobile === "" ? false : true,
      helperText: infoError.mobile,
    },
    patient_name: {
      label: "Patient Name",
      name: "patient_name",
      value: infoData.patient_name,
      isError: infoError.patient_name === "" ? false : true,
      helperText: infoError.patient_name,
    },
    address: {
      label: "Address",
      name: "address",
      value: infoData.address,
      isError: infoError.address === "" ? false : true,
      helperText: infoError.address,
    },
    date_created: {
      label: "Bill Date",
      name: "date_created",
      value: infoData.date_created,
      placeholder: "Enter Invoice Date",
      isError: infoError.date_created === "" ? false : true,
      helperText: infoError.date_created,
    },
    department: {
      label: "Department",
      name: "department",
      value: infoData.department,
      placeholder: "Select Department",
      isError: infoError.department === "" ? false : true,
      helperText: infoError.department,
    },
    // days: {
    //   label: "Days",
    //   name: "days",
    //   value: infoData.days,
    //   placeholder: "Enter Days",
    //   isError: infoError.days === "" ? false : true,
    //   helperText: infoError.days,
    // },
    // type: {
    //   label: "Type",
    //   name: "type",
    //   value: infoData.type,
    //   isError: infoError.type === "" ? false : true,
    //   helperText: infoError.type,
    // },
    invoice_no: {
      label: "Invoice No",
      name: "invoice_no",
      value: infoData.invoice_no,
      placeholder: "Enter Invoice Number",
      isError: infoError.invoice_no === "" ? false : true,
      helperText: infoError.invoice_no,
    },
    bill_no: {
      label: "Bill No",
      name: "bill_no",
      value: infoData.bill_no,
      placeholder: "Select Bill No",
      isError: infoError.bill_no === "" ? false : true,
      helperText: infoError.bill_no,
    },
  };

  const [infoFieldData, setInfoFieldData] = useState<any>(fieldData);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };
  const handleInvoiceNoChange = () => {
    if (
      autoGeneratedSeqNo !== infoData.invoice_no &&
      Boolean(!isManual.is_once)
    ) {
      setSeqIsOne((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: infoData.invoice_no,
      }));
      setSequenceDialogBox(true);
      setIsManual((prevState) => ({
        ...prevState,
        is_manual: false,
        is_auto_generate: false,
        is_once: true,
      }));
    }
  };

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;
  const handleSequence = () => {
    getDepartmentSequence("Sales Return Bill")
      .then((res: any) => {
        if (res.data.length > 0) {
          if (!id) {
            setInfoData((prevState: any) => ({
              ...prevState,
              invoice_no: res.data[0].sequence_no,
            }));
          }
          setAutoGeneratedSeqNo(res.data[0].sequence_no);
          setSequenceNo(res.data[0].sequence.id);
          setInitialSequenceNumber(res.data[0].sequence_no);
          const newSequenceData = res.data[0].sequence.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res.data[0].sequence.is_manual === 1 ? true : false,
            is_auto_generate:
              res.data[0].sequence.is_manual !== 1 ? true : false,
          }));
          setSeqIsOne((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res.data[0].sequence.is_manual === 1));
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (id) {
      setIsEditPageLoading(true);
      getPharmacyReturnBillById(id)
        .then((res: any) => {
          const { bill, bill_items } = res.data;

          setInfoData((prevState: any) => ({
            ...prevState,
            patient_id: bill.patient_id,
            invoice_no: bill.invoice_no,
            mobile: bill.patient_mobile,
            patient_name: bill.patient_name,
          }));
          const result = bill_items?.map((ele: any) => ({
            id: ele.id,
            row_id: ele.id,
            product_name: ele.product_name,
            product_id: ele.product_id,
            mrp: ele.mrp / ele.pack_type,
            pack_type: ele.pack_type,
            invoice_no: ele.invoice_no,
            dept_id: ele.dept_id,
            bill_disc: ele.discount_amt,
            tax_type: ele.tax_type,
            ref_bill: ele.ref_bill,
            sale_discount: ele.discount,
            tax_id: ele.tax_id,
            sale_date: dateFormat(ele.sales_date, "DD/MM/YYYY"),
            stock_qty: ele.stock_qty,
            sale_qty:
              Number(ele?.ref_original_qty) -
              Number(ele?.ref_return_qty) +
              Number(ele?.return_qty),
            sale_price: ele.salesamount ? ele.salesamount : "0.00",
            exp_date: ele.expires_at,
            tax: ele.tax_id,
            bill_id: ele.bill_id,
            tax_settings: ele.tax_settings === 0 ? "inclusive" : "exclusive",
            bill_batch_id: ele.batch_id,
            ret_qty: ele.return_qty,
            ret_price: ele.amount,
            goods_mrp: ele.mrp,
            sale_disc: ele.discount,
            batch_id: ele.batch_id,
          }));

          setBillProducts(result);
          const formErrors = result?.map((ele: any) => ({ retQty: false }));
          setFormError(formErrors);

          const newBatchIds = result?.map(
            (ele: { product_id: number }) => ele.product_id
          );
          setBatchIdNo(newBatchIds);

          const newBillIds = result?.map(
            (ele: { bill_id: string }) => ele.bill_id
          );
          setSummary((prevState: any) => {
            const newSummaryState = { ...prevState };
            const { bill_total } = handleSummaryData(result);
            const { net_amount, rounded_off } = calculateRoundedValue(
              bill_total,
              bill.discount_amt as number
            );
            newSummaryState.rounded_off = rounded_off;
            newSummaryState.net_amount = net_amount;
            newSummaryState.bill_total = bill_total;
            newSummaryState.discount = bill.discount_amt;
            newSummaryState.bill_discount_amount = Number(
              bill.discount_val || 0
            );
            newSummaryState.bill_discount_type = bill.discount_type;
            return newSummaryState;
          });
          setBillIdNo(newBillIds);
          setIsEditPageLoading(false);

          handleSequence();
        })
        .catch((err) => {
          setIsEditPageLoading(false);
          console.log(err);
        });
    } else {
      isCreatePrescription = true;
      isSequenceNumberChanged = false;
      handleSequence();
    }
  }, [id]);
  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);
  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value === "Select" ? "" : value,
      };
      return newState;
    });
  };
  function onCloseSequenceDialogBox() {
    setSequenceDialogBox(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceNo), data)
        .then((res) => {
          if (res) {
            handleSequence();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setSnackBarFailed({ snackBarMessage: err.message }));
        });
    }
  };
  const calculateDiscountAmount = (
    amount: number,
    discount: number,
    discount_type: number | string
  ) => {
    if (amount && discount && discount_type) {
      if (discount_type === "₹") {
        return Number(discount)?.toFixed(2);
      } else {
        return ((discount / 100) * Number(amount)).toFixed(2);
      }
    }

    return 0;
  };
  const calculateRoundedValue = (amount: number, discount: number) => {
    const billAmount = Number(amount) - Number(discount);
    const decimalPart = Math.round((billAmount % 1) * 100);
    const roundedValue: any =
      Math.trunc(billAmount) + (decimalPart >= 50 ? 1 : 0);
    const difference = roundedValue - billAmount;
    return {
      rounded_off: isNaN(difference) ? 0 : difference.toFixed(2),
      net_amount: isNaN(roundedValue) ? 0 : roundedValue,
    };
  };
  const handleSummaryData = (billProducts: any) => {
    const newValues = billProducts?.map((ele: any) => {
      const billAmount = ele.ret_price;

      return {
        billAmount,
      };
    });
    const calculatedBillAmount = calculateProductAmount(
      newValues,
      "billAmount"
    );
    const calculatedBillDiscount = calculateDiscountAmount(
      calculatedBillAmount,
      summary.bill_discount_amount || 0,
      summary.bill_discount_type || "%"
    );
    const { net_amount, rounded_off } = calculateRoundedValue(
      calculatedBillAmount,
      calculatedBillDiscount as number
    );
    return {
      bill_total: calculatedBillAmount,
      bill_discount: calculatedBillDiscount,
      rounded_amount: rounded_off,
      net_amount: net_amount,
    };
  };
  const calculateProductAmount = (rows: any, name: string) => {
    const updatedValue = rows?.reduce(
      (acc: any, current: any) => Number(acc) + Number(current[name]),
      0
    );
    return updatedValue || 0;
  };

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getTestAllPatientsList({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
              patient_details: uniqueData,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );
  const getAllPatientsList = async (currentValue: any, prevOptions: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions.length / 10)
            ? patientsPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getTestAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData?.uhid} - ${uniqueData.name}`,
              patient_details: uniqueData,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    switch (name) {
      case "patient_id":
      case "invoice_no": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setInfoError
          );
        } else {
          updateFormDataWithHelperText(name, "", setInfoError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = (details: any) => {
    let validate = true;

    if (Boolean(!details.patient_id)) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Patient not to be empty!" })
      );
      validate = false;
    }
    if (Boolean(!details.invoice_no)) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Invoice No not to be empty!" })
      );
      validate = false;
    }

    if (
      billProducts?.length > 0 &&
      billProducts.filter((ele: { product_id: number }) => !ele.product_id)
        ?.length > 0
    ) {
      dispatch(setSnackBarFailed({ snackBarMessage: "Please Add Product!" }));
      validate = false;
    }
    if (Boolean(formError.find((ele) => ele.ret_qty))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    if (summaryError.discount || summaryError.refunded_amount) {
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "Please check Discount or Refunded amount",
        })
      );
      validate = false;
    }
    if (
      billProducts?.length > 0 &&
      Boolean(billProducts.find((ele: { ret_qty: number }) => !ele.ret_qty))
    ) {
      dispatch(setSnackBarFailed({ snackBarMessage: "Please Add Ret qty!" }));
      validate = false;
    }
    return validate;
  };

  const handleSubmit = async () => {
    if (validateForm(infoData)) {
      setIsSubmitLoaded(true);
      const productData = billProducts.map((ele: any) => {
        const data: any = {
          product_id: ele.product_id,
          ref_bill: id ? ele.ref_bill : ele.bill_id,
          mrp: ele.goods_mrp,
          pack_type: ele.pack_type,
          dept_id: ele.dept_id,
          batch_id: ele.batch_id,
          return_qty: ele.ret_qty,
          discount: ele?.sale_discount || 0,
          tax_type: ele.tax_type,
          tax_id: ele.tax_id,
          amount: ele.ret_price,
          id: ele.id,
        };
        return {
          ...data,
        };
      });
      const data = {
        patient_id: infoData.patient_id,
        invoice_no: infoData.invoice_no,
        invoice_no_modified:
          infoData.invoice_no === initialSequenceNumber ? 0 : 1,
        date_created: infoData.date_created
          ? timeWithTwentyFourHoursConverter(infoData.date_created)
          : "",
        product: [...productData],
        bill_amt: summary.bill_total,
        discount_val: summary.bill_discount_amount,
        discount_type: summary.bill_discount_type,
        discount_amt: summary.discount,
        net_total_amt: summary.net_amount,
        refund_amount: summary.refunded_amount,
      };
      if (id) {
        await updateSalesReturn(Number(id), data)
          .then((res: any) => {
            if (res) {
              setIsSubmitLoaded(false);
              navigate("/pharmacy/sales-bill");
              dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            }
          })
          .catch((err: any) => {
            if (err?.response?.data?.errors?.data) {
              const { data, message, error_type } = err.response.data.errors;

              const newBillProducts = [...billProducts];
              newBillProducts[
                data.key
              ].batch_no = `${data.batch_no}#${data.current_stock}`;
              newBillProducts[data.key].stock_qty = data.current_stock;
              setBillProducts(newBillProducts);
              const newFormError = [...formError];
              newFormError[data.key]["ret_qty"] = true;
              setFormError(newFormError);

              dispatch(setSnackBarFailed({ snackBarMessage: message }));
              console.log(err);
              setIsSubmitLoaded(false);
            } else if (typeof err?.response?.data?.errors === "string") {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            setIsSubmitLoaded(false);
            console.log(err);
          });
      } else {
        await addSalesReturn(data)
          .then((res: any) => {
            if (res) {
              navigate("/pharmacy/sales-bill");
              dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            }
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            if (err?.response?.data?.errors?.data) {
              const { data, message, error_type } = err.response.data.errors;

              const newBillProducts = [...billProducts];
              newBillProducts[
                data.key
              ].batch_no = `${data.batch_no}#${data.current_stock}`;
              newBillProducts[data.key].stock_qty = data.current_stock;
              setBillProducts(newBillProducts);
              const newFormError = [...formError];
              newFormError[data.key]["ret_qty"] = true;
              setFormError(newFormError);

              dispatch(setSnackBarFailed({ snackBarMessage: message }));
              console.log(err);
              setIsSubmitLoaded(false);
            } else if (typeof err?.response?.data?.errors === "string") {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            } else {
              const { error_type } = err.response.data.errors;
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: error_type,
                })
              );
            }
            setIsSubmitLoaded(false);
            console.log(err);
          });
      }
    }
  };
  const updateInfoFieldData = () => {
    setInfoFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: infoData[field.name],
          isError: infoError[field.name] == "" ? false : true,
          helperText: infoError[field.name],
        };
      });
    });
  };

  const handleCalculateAmount = (product: any, name: string) => {
    let num: string | number = 0;
    let discount: string | number = 0;
    let tax_value: string | number = 0;
    if (product.sale_discount) {
      discount =
        product.mrp *
        Number(product.ret_qty) *
        (Number(product.sale_discount) / 100);
    } else {
      discount = 0;
    }
    if (isNaN(product.mrp) || isNaN(product.ret_qty)) {
      num = 0;
    } else {
      num = (product.mrp * product.ret_qty).toFixed(2);
    }

    num = Number(num) - discount;
    if (isNaN(product.tax)) {
      tax_value = 0;
    } else {
      if (product.tax_settings === "exclusive") {
        tax_value = (Number(num) * product.tax) / 100;
      } else {
        tax_value = 0;
      }
    }

    num = Number(num) + Number(tax_value);
    return num.toFixed(2);
  };
  const handleInputChange = (e: any, index?: number) => {
    const { name, value } = e.target;

    let numericValue = value.replace(/\D/g, "");

    setCurrentIndex(index as any);
    setBillProducts((prevState: any) => {
      const newState: any = [...prevState];
      newState[index as number] = {
        ...newState[index as number],
        [name]: numericValue,
      };
      newState[index as number] = {
        ...newState[index as number],
        ret_price: handleCalculateAmount(newState[index as number], "ret_qty"),
      };
      handleReturnQtyValidation(newState[index as number], index as number);
      setSummary((prevState: any) => {
        const newSummaryState = { ...prevState };
        const { bill_total, bill_discount } = handleSummaryData(newState);
        const { net_amount, rounded_off } = calculateRoundedValue(
          bill_total,
          bill_discount as number
        );
        newSummaryState.rounded_off = rounded_off;
        newSummaryState.net_amount = net_amount;
        newSummaryState.bill_total = bill_total;
        newSummaryState.discount = bill_discount;
        return newSummaryState;
      });
      return newState;
    });
  };
  const handleAutoCompleteInputChange = (
    e: any,
    newValue: any,
    name: string,
    idx: Number
  ) => {
    if (newValue === null) {
      return false;
    }
    setBillProducts((prevState: any) => {
      let newState = [...prevState];
      if (newState.length > 1) {
        newState[idx as number] = {
          ...newState[idx as number],
          [name]: newValue,
        };
      }
      if (newState.length === 1) {
        newState[idx as number] = {
          ...newState[idx as number],
          [name]: newValue,
        };
      }

      return newState;
    });
  };
  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, idx: Number, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevState: any) => {
        let newState = [...prevState];
        if (newState.length > 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            product_id: newValue.product_id,
            id: null,
            mrp: newValue.mrp / newValue.pack_type,
            pack_type: newValue.pack_type,
            invoice_no: newValue.invoice_no,
            dept_id: newValue.dept_id,
            bill_disc: newValue.discount_amt,
            bill_id: newValue.bill_id,
            tax_type: newValue.tax_type,
            tax_id: newValue.tax_id,
            sale_date: dateFormat(newValue.date, "DD/MM/YYYY"),
            stock_qty: newValue.stock_qty,
            sale_qty: newValue.qty,
            sale_price: newValue.amount ? newValue.amount : "",
            sale_discount: newValue.discount ? newValue.discount : "",
            batch_id: newValue?.batch_id,
            exp_date: newValue.expires_at,
            tax: newValue.tax_id,
            tax_settings:
              newValue.tax_settings === 0 ? "inclusive" : "exclusive",
            bill_batch_id: newValue.product_id,
            goods_mrp: newValue.mrp,
            product_input_value: newValue?.product_name,
          };
        }
        if (newState.length === 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            product_id: newValue.product_id,
            mrp: newValue.mrp / newValue.pack_type,
            pack_type: newValue.pack_type,
            invoice_no: newValue.invoice_no,
            dept_id: newValue.dept_id,
            bill_disc: newValue.discount_amt,
            bill_id: newValue.bill_id,
            tax_type: newValue.tax_type,
            batch_id: newValue?.batch_id,
            tax_id: newValue.tax_id,
            sale_date: dateFormat(newValue.date, "DD/MM/YYYY"),
            stock_qty: newValue.stock_qty,
            sale_qty: newValue.qty,
            sale_price: newValue.amount ? newValue.amount : "",
            sale_discount: newValue.discount ? newValue.discount : "",
            exp_date: newValue.expires_at,
            product_input_value: newValue?.product_name,
            tax: newValue.tax_id,
            id: null,
            tax_settings:
              newValue.tax_settings === 0 ? "inclusive" : "exclusive",
            bill_batch_id: newValue.batch_id,
            goods_mrp: newValue.mrp,
          };
        }
        let newBatchIds = [...batchIdNo];
        let newBillIds = [...billIdNo];

        if (newBatchIds[idx as number]) {
          newBatchIds[idx as number] = newValue?.product_id;
          newBillIds[idx as number] = newValue?.bill_id;
        } else {
          newBatchIds.push(newValue?.product_id);
          newBillIds.push(newValue?.bill_id);
        }
        setBatchIdNo(newBatchIds);
        setBillIdNo(newBillIds);
        return newState;
      });
    },
    [batchIdNo, billIdNo]
  );

  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProduct,
        id: prev.length,
      },
    ]);
    setFormError((prev) => [...prev, { ret_qty: false }]);
  }, []);

  useEffect(() => {
    if (infoData.patient_id && !id) {
      const data: any = {
        patient_id: infoData.patient_id,
        // type: infoData.type,
        // days: infoData.days,
        bill_no: infoData.bill_no,
        batch: batchIdNo,
        bill_id: billIdNo,
        department: infoData.department,
      };
      getPharmacyReturnProducts(data)
        .then((res: any) => setProductRow(res.data))
        .catch((err) => console.log(err));
    }
  }, [
    batchIdNo,
    billIdNo,
    infoData.department,
    infoData.patient_id,
    infoData.bill_no,
  ]);

  const handleSummaryBillUpdate = async (billDetails: any) => {
    await updatePharmacyReturnBillDetails(id as any, billDetails)
      .then(async (res) => {
        if (res) {
          console.log(res);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleDeleteReturnProductDelate = async (
    rowId: number,
    billDetails: any
  ) => {
    await deletePharmacyReturnProduct(rowId)
      .then(async (res) => {
        if (res) {
          await handleSummaryBillUpdate(billDetails);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleDelete = () => {
    const { id, batchNo, billId, rowId }: any = isDeleteDialogOpen.selectedRow;
    setLoading(true);
    const newBillProducts = [...billProducts];
    newBillProducts.splice(id, 1);
    setBillProducts(newBillProducts);
    const newFormError = [...formError];
    newFormError.splice(id, 1);
    setFormError(newFormError);
    if (batchNo) {
      const newBatchIds = [...batchIdNo];
      const findBatchIndex = batchIdNo.findIndex(
        (ele) => ele === Number(batchNo)
      );
      newBatchIds.splice(findBatchIndex, 1);
      setBatchIdNo(newBatchIds);
    }
    if (billId) {
      const newBillIds = [...billIdNo];
      const findBatchIndex = billIdNo.findIndex(
        (ele) => ele === Number(billId)
      );
      newBillIds.splice(findBatchIndex, 1);
      setBillIdNo(newBillIds);
    }
    const { bill_total, bill_discount } = handleSummaryData(newBillProducts);
    const { net_amount, rounded_off } = calculateRoundedValue(
      bill_total,
      bill_discount as number
    );

    setSummary((prevState: any) => {
      const newSummaryState = { ...prevState };

      newSummaryState.rounded_off = rounded_off;
      newSummaryState.net_amount = net_amount;
      newSummaryState.bill_total = bill_total;
      newSummaryState.discount = bill_discount;
      if (rowId) {
        handleDeleteReturnProductDelate(rowId, {
          bill_amt: net_amount,
          discount_val: newSummaryState.discount,
          discount_type: newSummaryState.bill_discount_type,
          discount_amt: bill_discount,
          net_total_amt: bill_total,
        });
      }
      return newSummaryState;
    });
    setLoading(false);
    setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
  };
  const getDepartmentOptions = async () => {
    const data = { active: 1, type: "receiver" };
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data?.result?.map(
          (ele: { dept_name: string; id: number; type: string }) => ({
            name: ele.dept_name,
            id: ele.id,
            type: ele.type,
          })
        );

        setDepartmentOptions(formattedValue);
      })
      .catch((err: any) => console.log(err));
  };

  const getPatientBillsById = async (
    patient_id: number,
    department: number
  ) => {
    await getPharmacyPatientBillNo({ patient_id, department })
      .then((res: any) => {
        const patientBillList =
          res?.data.length > 0 &&
          res?.data?.map((ele: any) => ({
            name: ele.invoice_no,
            id: ele.id,
          }));
        setBillNumberOptions(patientBillList || []);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (infoData.patient_id) {
      getPatientBillsById(infoData.patient_id, infoData.department);
    }
  }, [infoData.patient_id, infoData.department]);

  useEffect(() => {
    getDepartmentOptions();
  }, []);
  const handleRemoveInfoProductData = (name: string) => {
    if (name === "department" || name === "bill_no") {
      setBillProducts([
        {
          product_name: null,
          product_input_value: "",
          invoice_no: "",
          bill_id: "",
          sale_date: "",
          sale_qty: "",
          sale_price: "",
          sale_discount: "",
          exp_date: "",
          tax: "",
          tax_settings: "",
          bill_batch_id: "",
          goods_mrp: "",
          ret_qty: "",
          ret_price: "",
        },
      ]);
      setProductRow([]);
      setBatchIdNo([]);
      setBillIdNo([]);
      setSummary((prevState) => {
        const newState = { ...prevState };
        newState.total_discount_amount = 0;
        newState.goods_value = 0;
        newState.tax_amount = 0;
        newState.bill_total = newState.discount = 0;
        newState.bill_discount_type = "%";
        newState.bill_discount_amount = 0;
        newState.rounded_off = 0;
        newState.net_amount = 0;
        newState.refunded_amount = 0;
        return newState;
      });
      setFormError([
        {
          ret_qty: false,
        },
      ]);
    }

    if (name === "patient_id") {
      setBillProducts([
        {
          ...initialBillProduct,
          product_name: "",
          invoice_no: "",
          bill_id: "",
          sale_date: "",
          sale_qty: "",
          sale_price: "",
          sale_discount: "",
          exp_date: "",
          tax: "",
          tax_settings: "",
          bill_batch_id: "",
          goods_mrp: "",
          ret_qty: "",
          ret_price: "",
        },
      ]);
      setInfoData((prevState: any) => {
        const newState = { ...prevState };
        newState.department = null;
        newState.bill_no = null;
        return newState;
      });
      setBillIdNo([]);
      setProductRow([]);
      setBatchIdNo([]);
      setBillIdNo([]);
      setSummary((prevState) => {
        const newState = { ...prevState };
        newState.total_discount_amount = 0;
        newState.goods_value = 0;
        newState.tax_amount = 0;
        newState.bill_total = newState.discount = 0;
        newState.bill_discount_type = "%";
        newState.bill_discount_amount = 0;
        newState.rounded_off = 0;
        newState.net_amount = 0;
        newState.refunded_amount = 0;
        return newState;
      });
      setFormError([
        {
          ret_qty: false,
        },
      ]);
    }
  };
  return (
    <>
      {isEditPageLoading ? (
        <PageLoader />
      ) : (
        <Grid sx={{ width: "92vw" }}>
          <Info
            setInfoData={setInfoData}
            fieldData={fieldData}
            handleValidation={handleValidation}
            getAllPatients={getAllPatientsList}
            patientsList={patientsList}
            departmentOptions={departmentOptions}
            isManual={isManual}
            isManualAdded={isManualAdded}
            setIsManual={setIsManual}
            setSequenceDialogBox={setSequenceDialogBox}
            handleInvoiceNoChange={handleInvoiceNoChange}
            billTypes={billTypes}
            isEdit={Boolean(id)}
            customSequence={customSequence}
            billNumberOptions={billNumberOptions}
            handleRemoveInfoProductData={handleRemoveInfoProductData}
          />
          <Grid
            sx={{
              width: {
                xs: "92vw",
              },
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells.map((cell, index) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    height: "auto",
                    width: {
                      xs: "400vw",
                      sm: "200vw",
                      md: "120vw",
                      lg: "90vw",
                    },
                  }}
                >
                  {billProducts?.map((row: any, index: number) => {
                    return (
                      <ProductRow
                        key={row.id}
                        index={index}
                        row={row}
                        styles={styles}
                        allRows={productRow}
                        formError={formError}
                        handleInputChange={handleInputChange}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        handleAutoCompleteInputChange={
                          handleAutoCompleteInputChange
                        }
                        handleDelete={handleDelete}
                        isEdit={Boolean(id)}
                        totalLength={billProducts.length}
                        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              {!id && (
                <Grid
                  sx={{
                    width: {
                      xs: "400vw",
                      sm: "200vw",
                      md: "120vw",
                      lg: "90vw",
                    },
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "0px 0px 5px 5px",
                    border: 1,
                    borderColor: "var(--table-border)",
                    borderTop: 0,
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: {
                        xs: "78%",
                      },
                    }}
                  >
                    <Button
                      variant="text"
                      buttonText="+ Add Product"
                      handleClick={handleClick}
                      sx={{
                        borderRadius: "0px",
                        width: "auto",
                        height: "auto",
                        fontWeight: 400,
                        fontSize: "13px",
                        border: 0,
                        color: "primary.main",
                        "&:hover": {
                          backgroundColor: "initial",
                          color: "primary.main",
                          borderColor: "initial",
                        },
                        "&.Mui-focusVisible": {
                          border: 1,
                          borderColor: "primary.main",
                          borderRadius: "5px",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </TableContainer>
          </Grid>
          <Summary
            summary={summary}
            setSummary={setSummary}
            handleInputChange={handleSummaryChange}
            handleSaveData={() => {}}
            summaryError={summaryError}
          />
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              m: "35px 0 45px 0",
            }}
          >
            <SaveButton
              handleClick={() => handleSubmit()}
              loading={isSubmitLoaded}
              sx={{ width: "100px", height: "42px", mr: "20px" }}
            />
            <CancelButton sx={{ width: "100px", height: "42px" }} />
          </Grid>
        </Grid>
      )}
      <SequenceWrapper
        open={sequenceDialogBox}
        title="Pharmacy Sales Return"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      <DeleteDialog
        open={isDeleteDialogOpen.isOpen}
        loading={loading}
        onClose={() =>
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null })
        }
        onConfirm={handleDelete}
      />
    </>
  );
});

export default BillProducts;
