import {
  Box,
  Grid,
  InputAdornment,
  Button,
  CircularProgress,
  styled,
  Divider,
  Typography,
} from "@mui/material";
import {
  CancelButton,
  DatePicker,
  SaveButton,
  TextField,
} from "../../../../components/basic";
import {
  FindingsIcon,
  ColoredHistory,
  AccordionDropDownIcon,
  SelectPlusIcon,
  SelectedIcon,
} from "../../../../assets/icons";
import { displayText, inputEndValue } from "../../../../constants/displayText";
import { useCallback, useEffect, useState } from "react";
import { DialogWrapper } from "../../../../components/shared";
import Accordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import {
  addFollowUp,
  deleteSuggestionsById,
  getAllFindings,
  getAllScans,
  getAllSuggestions,
  getAllTests,
  getPatientVitals,
  getPrevHistoryById,
  updateFollowUp,
} from "../../../../services/gynecologyService";
import PreviousHistory from "../FollowUpHistory/PreviousHistory";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import AddMedicine from "./AddMedicine";
import { setSnackBarFailed } from "../../../../redux/slices/snackbar";
import { setLatestAppointId } from "../../../../redux/slices/gynecology";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";
type SuggestionType = {
  medicine_name?: string | number;
  product_id?: string | number | null;
  dosage?: string | number | null;
  units?: string | number | null;
  freq?: string | number | null;
  freq_time?: string | number | null;
  freq_time_type?: string | number | null;
  start_date: any;
  duration_type?: string | number | null;
  id?: number | string;
  followup_id?: string | number | null;
  duration: {
    specific_days?: string[];
    custom_date?: string | number | null;
    interval_days?: string | number | null;
    duration_count?: string | number | null;
    interval?: string | number | null;
  };
  dosage_time?: string[];
};
export default function AddFollowUp() {
  const { id } = useParams();
  const [followUpInfo, setFollowUpInfo] = useState({
    date_of_visit: "",
    trimester_week: "",
    notes: "",
    findings: "",
    suggestions: [],
    tests: "",
    scans: "",
    next_review_date: "",
    vitals: [],
  });

  const [isAddLoading, setIsAddLoading] = useState<boolean>(false);
  const {
    lastFollowUpId,
    followUpDetails,
    patientId,
    gynecologyId,
    latestAppointId,
    latestTrimesterWeek,
  } = useSelector((state: RootState) => state.gynecology);
  const [data, setData] = useState<any>({});
  const [isPrescriptionOpen, setIsPrescriptionOpen] = useState(false);
  const [prevHistoryList, setPrevHistoryList] = useState([]);
  const [isDetailPageLoading, setIsDetailPageLoading] = useState(false);
  const [isPrevHistoryClicked, setIsPrevHistoryClicked] = useState(false);
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [testDataList, setTestListData] = useState([]);
  const [scansDataList, setScansDataList] = useState([]);
  const [findingsDataList, setFindingsDataList] = useState([]);
  const [SuggestionsDataList, setSuggestionsListData] = useState([]);
  const [suggestionInfo, setSuggestionInfo] = useState<SuggestionType[]>([]);
  const [formError, setFormError] = useState({
    date_of_visit: "",
    trimester_week: "",
  });
  const [isPrevHistoryLoading, setIsPrevHistoryLoading] = useState(false);
  const [currentFindingsList, setCurrentFindingsList] = useState([]);
  const findingsListData: any = {
    Tests: testDataList,
    Scans: scansDataList,
    Findings: findingsDataList,
    Suggestions: SuggestionsDataList,
  };
  const findingsList = [
    { name: "Findings" },
    { name: "Suggestions" },
    { name: "Tests" },
    { name: "Scans" },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleInputChange = useCallback(
    (e: any, vitalId?: number | string | any) => {
      const { name, value } = e.target;
      if (Boolean(vitalId)) {
        setFollowUpInfo((prevState: any) => ({
          ...prevState,
          vitals: prevState.vitals.map((vital: any, index: number | string) =>
            vital.vital_id === vitalId
              ? {
                  ...vital,
                  vital_id: vital.vital_id,
                  vital_value: value,
                  vital_name: vital.vital_name,
                }
              : vital
          ),
        }));
      }
      if (vitalId === undefined) {
        setFollowUpInfo((prevState: any) => ({ ...prevState, [name]: value }));
      }
    },
    []
  );

  const handleDateChange = useCallback((name: string, newValue: any) => {
    setFollowUpInfo((prevState: any) => ({
      ...prevState,
      [name]: newValue?.validatedValue[0],
    }));
  }, []);

  const getPatientVitalsById = async (id: number | string) => {
    setIsDetailPageLoading(true);
    await getPatientVitals(id)
      .then((res: any) => {
        const { vitals, appoint_id } = res.data;
        dispatch(setLatestAppointId(appoint_id));
        setFollowUpInfo((prevState) => ({
          ...prevState,
          vitals:
            vitals?.length > 0
              ? vitals.map(
                  (vital: {
                    vital_id: string;
                    vital_value: string;
                    vital_name: string;
                  }) => ({
                    vital_id: vital.vital_id,
                    vital_value: vital.vital_value,
                    vital_name: vital.vital_name,
                  })
                )
              : [],
        }));
      })
      .catch((err) => console.log(err));
    setIsDetailPageLoading(false);
  };

  const getAllTestsList = async () => {
    await getAllTests(latestTrimesterWeek as number)
      .then((res: any) => {
        setTestListData(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getAllPrevHistoryList = async () => {
    setIsPrevHistoryLoading(true);
    await getPrevHistoryById(gynecologyId as string).then((res: any) =>
      setPrevHistoryList(res.data)
    );
    setIsPrevHistoryLoading(false);
  };
  const handlePrevHistory = () => {
    if (lastFollowUpId) {
      setIsPrevHistoryClicked(true);
      getAllPrevHistoryList();
    }
  };

  const handleSuggestionChange = (
    e: any,
    index: number | string,
    isDuration?: boolean
  ) => {
    const { name, value } = e.target;
    const newState: any = [...suggestionInfo];
    if (isDuration) {
      newState[index]["duration"][name] = value;
      setSuggestionInfo(newState);
    }
    if (!isDuration) {
      newState[index][name] = value;
      setSuggestionInfo(newState);
    }
  };
  const handleSuggestionDateChange = useCallback(
    (name: string, newValue: any, index: number) => {
      const newState: any = [...suggestionInfo];
      newState[index][name] = newValue?.validatedValue[0];
      setSuggestionInfo(newState);
    },
    [suggestionInfo]
  );

  const getAllFindingsList = async () => {
    await getAllFindings(latestTrimesterWeek as number)
      .then((res: any) => {
        setFindingsDataList(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getAllScansList = async () => {
    await getAllScans(latestTrimesterWeek as number)
      .then((res: any) => {
        setScansDataList(res.data);
      })
      .catch((err) => console.log(err));
  };
  const getAllSuggestionsList = async () => {
    await getAllSuggestions(latestTrimesterWeek as number)
      .then((res: any) => {
        setSuggestionsListData(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (id) {
      setFollowUpInfo((prevState: any) => ({
        ...prevState,
        date_of_visit: followUpDetails.date_of_visit
          ? followUpDetails.date_of_visit.split("-").reverse().join("/")
          : null,
        next_review_date: followUpDetails.next_review_date
          ? followUpDetails.next_review_date.split("-").reverse().join("/")
          : null,
        notes: followUpDetails.notes,
        trimester_week: followUpDetails.trimester_week,
        vitals: followUpDetails.vitals?.map(
          (vital: {
            vital_id: string;
            vital_value: string;
            vital_name: string;
          }) => ({
            vital_id: vital.vital_id,
            vital_value: vital.vital_value,
            vital_name: vital.vital_name,
          })
        ),
      }));
      const listData = {
        Findings: followUpDetails.findings?.map(
          (ele: { id: string | number; symptom_name: string }) => ele.id
        ),

        Scans: followUpDetails.scans?.map(
          (ele: { id: string | number; name: string }) => ele?.id
        ),
        Tests: followUpDetails.tests?.map(
          (ele: { id: string | number; name: string }) => ele?.id
        ),
        Suggestions: followUpDetails?.suggestions?.map(
          (ele: { product_id: string | number; name: string }) =>
            ele?.product_id
        ),
      };

      setCurrentFindingsList((prevState: any) => ({
        ...prevState,
        ...listData,
      }));
      const newSuggestionData = followUpDetails?.suggestions?.map(
        (ele: any) => ({
          medicine_name: ele?.product.name,
          dosage: ele?.dosage,
          dosage_time: ele?.dosage_time,
          duration: {
            interval: ele?.duration?.interval,
            custom_date: ele?.duration?.custom_date,
            interval_days: ele?.duration?.interval_days,
            specific_days: ele?.duration?.specific_days,
            duration_count: ele?.duration?.duration_count,
          },
          duration_type: ele?.duration_type,
          followup_id: ele?.followup_id,
          freq: ele?.freq,
          freq_time: ele?.freq_time,
          freq_time_type: ele?.freq_time_type,
          id: ele?.id,
          product_id: ele?.product_id,
          start_date: ele?.start_date,
          units: ele?.units,
        })
      );
      const newData = newSuggestionData ? [...newSuggestionData] : [];
      setSuggestionInfo(newData);
    }
    if (patientId) {
      getPatientVitalsById(patientId);
    }
  }, [id]);
  useEffect(() => {
    getAllTestsList();
    getAllFindingsList();
    getAllSuggestionsList();
    getAllScansList();
  }, []);
  const personalInfoList = [
    {
      name: "date_of_visit",
      label: "Date of Visit",
      placeholder: "",
      value: followUpInfo.date_of_visit,
      type: "date",
      isFullWidth: true,
      flex: "flex-start",
      helperText: formError.date_of_visit,
      error: Boolean(formError.date_of_visit),
    },
    {
      name: "trimester_week",
      label: "Trimester Week",
      placeholder: "",
      value: followUpInfo.trimester_week,
      type: "textField",
      isFullWidth: true,
      flex: "flex-start",
      helperText: formError.trimester_week,
      error: Boolean(formError?.trimester_week),
    },
  ];

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    switch (name) {
      case "trimester_week": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "date_of_visit": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };
  const validateForm = () => {
    for (const fieldName in personalInfoList) {
      if (fieldName) {
        handleValidation({
          target: (personalInfoList as any)[Number(fieldName)],
        });
      }
    }
  };
  const handleAddFindings = (id: number | string, finding: number | string) => {
    let idList = [];
    const isExist = data[finding];

    if (isExist) {
      if (isExist.includes(id)) {
        idList = data[finding].filter((ele: any) => ele !== id);
      } else {
        idList = [...data[finding], id];
      }
    } else {
      idList = [id];
    }
    const newFindingWithList: any = {
      ...data,
      [finding]: idList,
    };
    setData(newFindingWithList);
    if (finding === "Suggestions") {
      handleSuggestionList(id);
    }
  };

  const handleSuggestionList = async (id: number | string) => {
    const suggestionIndex = suggestionInfo.findIndex(
      (ele) => Number(ele.product_id) === id
    );
    if (suggestionIndex === -1) {
      setSuggestionInfo([
        ...suggestionInfo,
        {
          medicine_name: findingsListData["Suggestions"]?.find(
            (elem: { id: number; name: string }) => elem.id === id
          ).name,

          product_id: findingsListData["Suggestions"]?.find(
            (elem: { id: number; name: string }) => elem.id === id
          ).id,
          dosage: "",
          units: "",
          freq: "",
          freq_time: "",
          freq_time_type: "",
          start_date: "",
          duration_type: "",
          duration: {
            specific_days: [],
            custom_date: "",
            interval_days: "",
            duration_count: "",
            interval: "",
          },
          dosage_time: [],
        },
      ]);
    } else {
      const newSuggestionInfo = [...suggestionInfo];
      newSuggestionInfo.splice(suggestionIndex, 1);
      setSuggestionInfo(newSuggestionInfo);
      if (suggestionInfo[suggestionIndex].id) {
        await deleteSuggestion(
          suggestionInfo[suggestionIndex].followup_id as number,
          suggestionInfo[suggestionIndex].id as number
        );
      }
    }
  };
  const deleteSuggestion = async (
    id: number | string,
    suggestionId: number | string
  ) => {
    await deleteSuggestionsById(id, suggestionId)
      .then((res) => {
        if (res) {
          return true;
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSubmit = async () => {
    if (
      followUpInfo.trimester_week === "" ||
      followUpInfo.date_of_visit === ""
    ) {
      validateForm();
    } else {
      setIsAddLoading(true);
      const vitalsValue = followUpInfo.vitals.filter(
        (ele: { vital_id: string; vital_value: string; vital_name: string }) =>
          Boolean(ele.vital_value)
      );
      const suggestionData =
        suggestionInfo.length > 0
          ? suggestionInfo?.map((ele: { start_date: any; duration: any }) => ({
              ...ele,
              start_date: ele?.start_date
                ? ele?.start_date?.split("/").reverse().join("-")
                : "",
              duration: {
                ...ele.duration,
                interval: ele?.duration?.interval
                  ? ele.duration.interval.toString()
                  : "",
              },
            }))
          : [];
      let followupData = {
        ...followUpInfo,
        date_of_visit: followUpInfo?.date_of_visit
          ?.split("/")
          .reverse()
          .join("-"),
        vitals: vitalsValue,
        antenatal_card_id: gynecologyId,
        appointment_id: latestAppointId,
        next_review_date: followUpInfo?.next_review_date
          ?.split("/")
          .reverse()
          .join("-"),
        suggestions: [...suggestionData],
        findings: data?.Findings?.length > 0 ? data.Findings?.join(",") : "",
        tests: data?.Tests?.length > 0 ? data.Tests?.join(",") : "",
        scans: data?.Scans?.length > 0 ? data.Scans?.join(",") : "",
      };
      if (id) {
        await updateFollowUp(followUpDetails.id, followupData)
          .then((res) => {
            if (res) {
              setIsAddLoading(false);
              navigate(`/gynecology/view/${gynecologyId}`);
            }
          })
          .catch((err) => {
            console.log(err);
            dispatch(setSnackBarFailed(err));
          });
      }
      if (!id) {
        await addFollowUp(followupData)
          .then((res) => {
            if (res) {
              setIsAddLoading(false);
              navigate(`/gynecology/view/${gynecologyId}`);
            }
          })
          .catch((err) => dispatch(setSnackBarFailed(err)));
      }
      setIsAddLoading(false);
    }
  };
  useEffect(() => {
    setData(currentFindingsList);
  }, [currentFindingsList]);
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<AccordionDropDownIcon style={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    width: "100%",
    height: "auto",
    cursor: "pointer",
    backgroundColor: "#ECF4FFCC",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(180deg)",
    },
    "&:before": {
      borderRadius: "10px 10px 0px 0px",
    },
    "&:after": {
      borderRadius: "10px 10px 0px 0px",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));
  return (
    <>
      <Grid container sx={{ display: "flex", flexDirection: "row" }}>
        <Grid item xs={3.6}>
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#207DFF",
              padding: "10px",
              marginLeft: "-22px",
              paddingLeft: "23px",
            }}
          >
            Follow up Details
          </Box>
          {isDetailPageLoading ? (
            <div
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                height: "50vh",
                background: "transparent",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Box>
              <Grid container>
                {personalInfoList.map((ele, idx) => (
                  <>
                    <Grid
                      item
                      sm={12}
                      sx={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        paddingRight: "12px",
                      }}
                    >
                      {ele.type === "textField" && (
                        <>
                          <TextField
                            name={ele.name}
                            label={ele.label}
                            onChange={handleInputChange}
                            value={ele.value}
                            width={"370px"}
                            helperText={ele.helperText}
                            error={ele.error}
                          />
                        </>
                      )}
                      {ele.type === "date" && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          <DatePicker
                            name={ele.name}
                            label={ele.label}
                            style={{ width: "347px" }}
                            onChange={(e: any, newValue: any) =>
                              handleDateChange(ele.name, newValue)
                            }
                            value={ele.value}
                            helperText={ele.helperText}
                            error={ele.error}
                          />
                          {ele.name === "date_of_visit" && lastFollowUpId && (
                            <ColoredHistory
                              style={{ cursor: "pointer", marginTop: "12px" }}
                              onClick={() => handlePrevHistory()}
                            />
                          )}
                        </Box>
                      )}
                    </Grid>
                  </>
                ))}
                {followUpInfo?.vitals?.length > 0 &&
                  followUpInfo?.vitals?.map(
                    (
                      ele: {
                        vital_id: string;
                        vital_value: string;
                        vital_name: string;
                      },
                      idx: number
                    ) => (
                      <Grid
                        item
                        sm={12}
                        key={idx}
                        sx={{
                          marginBottom: "10px",
                          marginTop: "10px",
                          paddingRight: "12px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <TextField
                          name={ele.vital_name}
                          label={ele.vital_name}
                          onChange={(e) => handleInputChange(e, ele.vital_id)}
                          value={ele.vital_value}
                          width={"370px"}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {inputEndValue[ele.vital_name]}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    )
                  )}

                <Grid
                  item
                  sm={12}
                  sx={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    paddingRight: "12px",
                  }}
                >
                  <DatePicker
                    name={"next_review_date"}
                    label="Next Review date"
                    style={{ width: "347px" }}
                    value={followUpInfo.next_review_date}
                    onChange={(e: any, newValue: any) =>
                      handleDateChange("next_review_date", newValue)
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={0.7}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#207DFF",
              padding: "10px",
              marginLeft: "-22px",
              paddingLeft: "23px",
            }}
          >
            Findings
          </Box>
          {findingsList.map((elem: any) => (
            <Accordion
              sx={{
                "& .MuiAccordionSummary-root": { borderRadius: "14px" },
                "& .MuiAccordionSummary-content": {
                  margin: "0 !important",
                },
                "& .MuiPaper-root": {
                  "&::before": {
                    opacity: "0 !important",
                  },
                },
                width: "100%",
                height: "auto",
                borderRadius: "12px",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <AccordionSummary>
                <Grid container>
                  <Grid
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <Grid xs={1}>
                      <FindingsIcon style={{ padding: "20px 14px" }} />
                    </Grid>
                    <Grid xs={10} sx={{ margin: "18px 0px 0px 10px" }}>
                      <Box
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          margin: 0,
                        }}
                      >
                        {elem.name}
                      </Box>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          margin: 0,
                        }}
                      >
                        {data[elem.name] &&
                          data[elem.name]
                            .map((diseaseId: number) => {
                              const symptom = findingsListData[elem.name]?.find(
                                (symptom: any) => symptom?.id === diseaseId
                              );
                              return symptom
                                ? symptom?.symptom_name || symptom?.name
                                : null;
                            })
                            .join(", ")}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ lineHeight: "1.6", padding: "16px" }}>
                  {findingsListData[elem.name]?.map(
                    (ele: any, idx: number | string) => (
                      <>
                        {data[elem.name] && data[elem.name].includes(ele.id) ? (
                          <Button
                            sx={{
                              backgroundColor: "#207DFF",
                              height: "36px",
                              gap: "12px",
                              margin: "5px",
                              color: "#F6F6F6",
                              "&:hover": {
                                backgroundColor: "#207DFF",
                                color: "#F6F6F6",
                              },
                            }}
                            onClick={() => handleAddFindings(ele.id, elem.name)}
                          >
                            {ele.symptom_name || ele.name}
                            <SelectedIcon />
                          </Button>
                        ) : (
                          <Button
                            sx={{
                              backgroundColor:
                                ele.is_recommended === 0 ? "#F6F6F6" : "",
                              height: "36px",
                              gap: "12px",
                              margin: "5px",
                              color: "black",
                              border: ele.is_recommended && "#FFCD18",
                              boxShadow:
                                ele.is_recommended &&
                                "inset 0px 0px 7px #FFCD18",
                              "&:hover": {
                                backgroundColor:
                                  ele.is_recommended === 0
                                    ? "#F6F6F6"
                                    : "#ffffff",
                                color: "black",
                              },
                            }}
                            onClick={() => handleAddFindings(ele.id, elem.name)}
                          >
                            {ele.symptom_name || ele.name}
                            <SelectPlusIcon />
                          </Button>
                        )}
                      </>
                    )
                  )}
                </Box>
                <Box sx={{ float: "right" }}>
                  {elem.name === "Suggestions" && (
                    <Button
                      sx={{
                        backgroundColor: "#207DFF",
                        height: "36px",
                        gap: "12px",
                        margin: "5px",
                        color: "#F6F6F6",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#207DFF",
                          color: "#F6F6F6",
                        },
                      }}
                      onClick={() => {
                        setIsPrescriptionOpen(true);
                      }}
                    >
                      Add to Prescription
                    </Button>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
          <Grid
            item
            sm={12}
            sx={{
              marginBottom: "10px",
              marginTop: "10px",
              paddingRight: "12px",
            }}
          >
            <Typography variant="p">Notes</Typography>
            <textarea
              style={{
                width: "100%",
                height: "154px",
                borderRadius: "8px",
                padding: "10px",
                resize: "none",
                outline: "none",
                border: "1px solid #EFEFEF",
                marginTop: "10px",
                fontFamily: "inter",
                fontSize: "14px",
              }}
              name="notes"
              value={followUpInfo.notes}
              onChange={handleInputChange}
              spellCheck="false"
            />
          </Grid>
          <Box mt={5}>
            <SaveButton
              buttonText={displayText.SAVE}
              sx={{ width: "100px", height: "42px", mr: "20px" }}
              loading={isAddLoading}
              handleClick={() => handleSubmit()}
            />
            <CancelButton
              isDisabled={Boolean(isAddLoading)}
              buttonText={displayText.CANCEL}
              sx={{ width: "100px", height: "42px" }}
              handleClick={() => navigate(`/gynecology/view/${gynecologyId}`)}
            />
          </Box>
        </Grid>
      </Grid>

      <DialogWrapper
        open={isPrevHistoryClicked}
        handleClick={() => setIsPrevHistoryClicked(!isPrevHistoryClicked)}
        onClose={() => setIsPrevHistoryClicked(!isPrevHistoryClicked)}
        title={"Previous History"}
      >
        {isPrevHistoryLoading ? (
          <div
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <PreviousHistory prevHistoryList={prevHistoryList} />
        )}
      </DialogWrapper>
      <DialogWrapper
        open={isPrescriptionOpen}
        //need to develop
        handleClick={() => setIsPrescriptionOpen(!isPrescriptionOpen)}
        onClose={() => setIsPrescriptionOpen(!isPrescriptionOpen)}
        title={"Prescription"}
        maxWidth="lg"
      >
        <AddMedicine
          suggestionInfo={suggestionInfo}
          commonVariables={commonVariables}
          handleSuggestionChange={handleSuggestionChange}
          handleSuggestionDateChange={handleSuggestionDateChange}
        />
      </DialogWrapper>
    </>
  );
}
