import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../../components/shared";
import {
  DeleteIcon,
  EditIcon,
  PrintIcon,
  HistoryIcon,
} from "../../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { AutoComplete, TextAreaEditor } from "../../../../../components/basic";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../../utils/ValidationUtils";
import { getAllPrintNotesIpTypes } from "../../../../../services/admissionService";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { ipAdmissionConst } from "../../../../../constants/displayText";
import { setPrintPdfUrl } from "../../../../../redux/slices/appconfiguration";
import { RouteUrls } from "../../../../../constants/routes";
import { Link } from "react-router-dom";
import PermissionUtils from "../../../../../utils/PermissionUtils";

type PropsType = {
  ipAdmissionDetails?: any;
};

const DoctorVisits = ({ ipAdmissionDetails }: PropsType) => {
  const { ADDED_SUCCESSFULLY, UPDATED_SUCCESSFULLY, DELETED_SUCCESSFULLY } =
    ipAdmissionConst;

  const {
    ipSummary,
    dischargeSummary,
    inPatients,
    doctorVisitsUrl,
    createUrl,
  } = RouteUrls;
  const { can } = PermissionUtils();

  const dispatch = useDispatch();

  // common variables
  const [admissionId, setAdmissionId] = useState<any>(ipAdmissionDetails?.id);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);
  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);

  // dialog and loader variables

  const [isDialogWrapperButtonLoading, setIsDialogWrapperButtonLoading] =
    useState(false);
  const [isDialogWrapperOpen, setIsDialogWrapperOpen] =
    useState<boolean>(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isConfirmationDialogButtonLoading,
    setIsConfirmationDialogButtonLoading,
  ] = useState(false);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  // field variables

  const [doctorsVisitData, setDoctorVisitsData] = useState<any>([]);

  const [typeOptions, setTypeOptions] = useState<any>([]);

  const initialFormError = {
    type: "",
    content: "",
  };

  const [formError, setFormError] = useState<any>(initialFormError);

  const initialFormData = {
    type: null,
    typeInput: "",
    content: "",
  };

  const [formData, setFormData] = useState<any>(initialFormData);

  const fieldData: any = {
    type: {
      label: "Type",
      name: "type",
      placeholder: "Select Type",
      inputValue: formData?.typeInput,
      value: formData.type,
      isError: formError.type === "" ? false : true,
      helperText: formError.type,
    },
    content: {
      label: "Content",
      name: "content",
      value: formData.content,
      isError: formError.content === "" ? false : true,
      helperText: formError.content,
    },
  };

  const [formFieldData, setFormFieldData] = useState(fieldData);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    switch (name) {
      case "type": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateFormFieldData = () => {
    setFormFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: formData[field.name],
          helperText: formError[field.name],
          isError: formError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateFormFieldData();
  }, [formError, formData]);

  const dialogClose = async () => {
    setIsDialogWrapperButtonLoading(false);
    setIsDialogWrapperOpen(false);
    setIsDialogLoading(false);
    setIdToBeUpdated(null);
    setFormError(initialFormError);
    setIdToBeDeleted(null);
    setIsConfirmationDialogOpen(false);
    setIsDialogLoading(false);
    setFormData(initialFormData);
  };

  // data table and actions

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {can("ip_discharge_delete") && (
          <IconButton
            onClick={() => {
              setIsConfirmationDialogOpen(true);
              setIdToBeDeleted(row?.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        {/* developer comment */}
        {/* <Link
          to={`${inPatients}${ipSummary}${dischargeSummary}/print`}
          style={{
            textDecoration: "none",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch(setPrintPdfUrl(row?.pdf_url));
          }}
        >
          <PrintIcon />
        </Link> */}
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.5,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          S.No
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sNo}
        </Typography>
      ),
      minWidth: 50,
      sortable: false,
    },
    {
      field: "date",
      flex: 2,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Date & Time
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.date}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "doctor",
      flex: 2,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Doctor Name
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.doctor}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  // field onchange functions

  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    const content = newValue.content;

    if (newValue === null) {
      return false;
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: newValue,
      content: content,
    }));

    handleValidation(e);
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: any,
    name: string
  ) => {
    if (name === "typeInput" && newInputValue === "") {
      return false;
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: newInputValue,
    }));
  };

  const handleTextAreaChange = (content: any) => {
    setFormData((prev: any) => ({
      ...prev,
      content: content,
    }));
  };

  // api call functions to get select options

  const getPrintNotesIpTypes = async () => {
    try {
      await getAllPrintNotesIpTypes().then((result: any) => {
        let data = result?.data?.result;

        setTypeOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // crud operation api calls functions

  const handleDelete = async (id: any) => {};

  // dialog functions

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };

  useEffect(() => {
    getPrintNotesIpTypes();
  }, []);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "95vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "40vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isConfirmationDialogButtonLoading}
      />
      {can("ip_discharge_add") ? (
        <>
          <DialogWrapper
            onClose={dialogClose}
            open={isDialogWrapperOpen}
            // handleClick={createAndUpdateAdmissionIpDischargeSummary}
            title="Previous Visits"
            maxWidth="lg"
            loading={isDialogWrapperButtonLoading}
          >
            {isDialogLoading ? (
              <Grid
                sx={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  height: "50vh",
                  background: "transparent",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item xl={11.5} xs={12} sx={{ gap: 5 }}></Grid>
            )}
          </DialogWrapper>
          <Grid
            item
            xl={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid
              sx={{
                width: "15%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h2" sx={{ m: "20px 0px" }}>
                Doctor Visit Register
              </Typography>
              <HistoryIcon style={{ cursor: "pointer" }} />
            </Grid>
            <Button
              variant="text"
              sx={{
                width: "88px",
                height: "38px",
                textTransform: "none",
              }}
              onClick={() => {
                // developer comment
                // setIsDialogWrapperOpen(true);
              }}
            >
              <Typography variant="h4">+Add</Typography>
            </Button>
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid item xl={12} sx={{ mb: "50px" }}>
        <Box>
          <DataTable
            loading={isDataTableLoading}
            columns={columns}
            rows={doctorsVisitData}
            getRowId={(row: any) => `${String(row?.sNo)}`}
            tableOnly={true}
            customizedTable={true}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DoctorVisits;
