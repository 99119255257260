import { Box } from "@mui/material";
import { Tabs } from "../../../components/shared";
import Products from "./Products";
import Departments from "./Departments";
import DosageForm from "./DosageForm";
import DrugClassification from "./DrugClassification";
import MajorDrugs from "./MajorDrugs";
import Manufacture from "./Manufacture";
import Suppliers from "./Suppliers";
import Tax from "./Tax";
import Units from "./Units";
import Customers from "./Customer";

function ProcurementMaster() {
  const masterTabsList = [
    { children: <Products />, label: "Products" },
    { children: <Suppliers />, label: "Suppliers" },
    { children: <Customers />, label: "Customer" },
    { children: <Tax />, label: "Tax" },
    { children: <Departments />, label: "Departments" },
    { children: <Manufacture />, label: "Manufacture" },
    { children: <MajorDrugs />, label: "Major Drugs" },
    { children: <DosageForm />, label: "Dosage Form" },
    { children: <Units />, label: "Units" },
    { children: <DrugClassification />, label: "Drug Classification" },
  ];

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs data={masterTabsList} scrollButtons={true} />
    </Box>
  );
}

export default ProcurementMaster;
