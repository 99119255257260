import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DialogWrapper } from "../../../../components/shared";
import { CheckBox } from "../../../../components/basic";
import { useEffect, useState } from "react";
import { poProductModalConst } from "../../../../constants/displayText";
import { getProductByPoNo } from "../../../../services/procurementService";

type POProductSelectModalPropsType = {
  isOpenPoModalData: {
    isOpen: boolean;
    po_no: null | string | number;
    is_po_already_exist: boolean;
  };
  onClose: () => void;
  onAdd: (data: any) => void;
  infoData?: any;
};

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right" | "center";
  sx?: {};
  hasCheckBox?: boolean;
};

const styles = {
  tableContainerStyle: {
    borderRadius: "5px",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: "100%",
    overflowX: "unset",
  },
  tableStyle: {
    borderRadius: "5px",
    border: "1px solid",
    borderColor: "var(--table-border)",
    "& .MuiTableHead-root": { border: 0, borderRadius: "unset" },
    "& .MuiTableRow-head": {
      backgroundColor: "var(--table-header)",
      borderRadius: "unset",
    },
    " & .MuiTableBody-root": {
      border: 0,
    },
    "& .MuiTableRow-root": {
      height: "34px !important",
      maxHeight: "34px !important",
      minHeight: "0px",
      lineHeight: "0px",
      border: 0,
    },
    "& .MuiTableCell-root": {
      minHeight: "0px",
    },
    "& .MuiTableCell-head": {
      height: "43px",
      maxHeight: "43px",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 10px",
      fontSize: "13px",
      fontWeight: "600",
      borderBottom: 0,
      borderRight: 1,
      borderRadius: "5px",
      borderColor: "var(--table-border)",
    },
    "& .MuiTableCell-body": {
      height: "32px !important",
      maxHeight: "32px !important",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 0px",
      fontSize: "12px",
      fontWeight: "400",
      border: 1,
      borderColor: "var(--table-border)",
    },
    "& .MuiTableCell-body:has(.Mui-focused)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },
    "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "30px",
    },
    "& .deleteIconGrid": {
      cursor: "pointer",
      position: "absolute",

      bottom: "5px",
    },
  },
  textStyle: {
    p: "10px 10px",
    height: "38px",
    width: "100%",
    fontSize: "12px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
  },
};

const POProductSelectModal = (props: POProductSelectModalPropsType) => {
  const { isOpenPoModalData, infoData, onClose, onAdd } = props;
  const { product, orderQty, receivedQty } = poProductModalConst;
  const [poProducts, setPoProducts] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const TableHeaderCells: TableHeaderCell[] = [
    {
      TableCellName: product,
      alignment: "center",
      sx: {
        width: "40%",
        minWidth: "140px",
      },
      hasCheckBox: true,
    },
    {
      TableCellName: orderQty,
      alignment: "center",
      sx: {
        width: "30%",
        minWidth: "200px",
      },
    },
    {
      TableCellName: receivedQty,
      alignment: "center",
      sx: {
        width: "30%",
        minWidth: "150px",
      },
    },
  ];

  const handleCheckAll = () => {
    setSelectedProducts((prevState: any) => {
      if (prevState?.length === poProducts?.length) {
        return [];
      }
      return poProducts?.map((ele: any) => ele?.product_id);
    });
  };

  const handleCheck = (row: any) => {
    setSelectedProducts((prev: any) => {
      const index = prev.findIndex((ele: any) => ele === row.product_id);

      if (index === -1) {
        return [...prev, row.product_id];
      } else {
        const newState = [...prev];
        newState.splice(index, 1);
        return newState;
      }
    });
  };

  const handleAdd = () => {
    if (selectedProducts?.length > 0) {
      const finalSelectedProducts = poProducts?.filter((ele: any) =>
        selectedProducts?.includes(ele.product_id)
      );

      onAdd(finalSelectedProducts);
    } else {
      onAdd([]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getProductByPoNo(isOpenPoModalData?.po_no, { dept_id: infoData.dept_id })
      .then((res: any) => {
        if (res.data) {
          if (res.data?.length > 0) {
            setPoProducts(res?.data);
          } else {
            setPoProducts([]);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <DialogWrapper
      open={isOpenPoModalData?.isOpen}
      onClose={() => onClose()}
      title="Po Products"
      confirmText="add"
      handleClick={handleAdd}
    >
      <TableContainer
        className="table-container"
        component={Paper}
        sx={{
          ...styles.tableContainerStyle,
        }}
      >
        <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {TableHeaderCells.map((cell, index) => {
                return (
                  <TableCell align={cell.alignment} sx={cell.sx} key={index}>
                    {cell?.hasCheckBox ? (
                      <Box display={"flex"} alignItems={"center"}>
                        <CheckBox
                          checked={
                            selectedProducts?.length === poProducts?.length &&
                            selectedProducts.length > 0
                          }
                          onChange={handleCheckAll}
                        />
                        {cell.TableCellName}
                      </Box>
                    ) : (
                      <>{cell.TableCellName}</>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{ height: "auto", width: "100%" }}>
            {isLoading ? (
              <TableRow>
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                      gap: 2,
                    }}
                  >
                    <CircularProgress />
                    <Typography variant="h2" color="initial">
                      Loading
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ border: "unset !important" }} />
              </TableRow>
            ) : poProducts?.length > 0 ? (
              <>
                {poProducts?.map((product: any) => (
                  <TableRow>
                    <TableCell
                      className="last-cell"
                      align="center"
                      sx={{
                        width: "40%",
                        position: "relative",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          ml: "10px",
                        }}
                      >
                        <CheckBox
                          checked={selectedProducts?.includes(
                            product?.product_id
                          )}
                          onChange={() => handleCheck(product)}
                        />
                        <Typography
                          sx={{
                            ...styles.textStyle,
                          }}
                        >
                          {product?.product?.name}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell
                      className="last-cell"
                      align="center"
                      sx={{
                        width: "30%",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          ...styles.textStyle,
                        }}
                      >
                        {product?.order_qty}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="last-cell"
                      align="center"
                      sx={{
                        width: "30%",
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{
                          ...styles.textStyle,
                        }}
                      >
                        {product?.received_qty}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                      gap: 2,
                    }}
                  >
                    <Typography>No Rows</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ border: "unset !important" }} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </DialogWrapper>
  );
};

export default POProductSelectModal;
