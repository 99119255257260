export const testOptions = [
  { title: "Pregnancy Test", id: 0 },
  { title: "Coomb’s Test", id: 1 },
  { title: "Blood Test", id: 2 },
  { title: "Malaria Test", id: 3 },
  {
    title: "Covid",
    id: 4,
    subTitle:
      "Urine test, Blood test, WBC, RBC, Limp test, Urine test, Blood test, WBC, RBC, Limp test",
  },
  { title: "Viral Fever", id: 5 },
  { title: "HIV Test", id: 6 },
  { title: "Covid -1 ", id: 7 },
  { title: "Viral Fever - 1", id: 8 },
  { title: "HIV Test - 1", id: 9 },
  {
    title: "Covid - 2",
    id: 10,
    subTitle:
      "Urine test, Blood test, WBC, RBC, Limp test, Urine test, Blood test, WBC, RBC, Limp test",
  },
  { title: "Viral Fever - 2", id: 11 },
  { title: "HIV Test - 2", id: 12 },
  { title: "Covid - 3", id: 13 },
  {
    title: "Viral Fever - 3",
    id: 14,
    subTitle:
      "Urine test, Blood test, WBC, RBC, Limp test, Urine test, Blood test, WBC, RBC, Limp test",
  },
  { title: "HIV Test - 3", id: 15 },
];

export const billingDepartmentOptions = [
  { id: 1, name: "Laboratory" },
  { id: 2, name: "Hospital" },
  { id: 3, name: "IP" },
  { id: 4, name: "OP" },
  { id: 5, name: "Emergency" },
  { id: 6, name: "Consultation" },
];

export const billingGroupNameOptions = [
  { id: 1, name: "EMR Charge" },
  { id: 2, name: "HP Item" },
  { id: 3, name: "IP Bills" },
  { id: 4, name: "OP Bills" },
  { id: 5, name: "Lab Test" },
];

export const billingConsulatantOptions = [
  { id: 1, name: "Dr.K Prveen Kumar" },
  { id: 2, name: "CMR Karthik" },
  { id: 3, name: "Dr.K Raja" },
];

export const billingItemsOptions = [
  { id: 1, name: "CT- Brain with Facial Bone " },
  { id: 2, name: "CT- Brain with Facial Bone " },
  { id: 3, name: "CT- Brain with Facial Bone " },
];

export const labResultOptions = [
  { id: 1, name: "Positive" },
  { id: 2, name: "Negative" },
  { id: 3, name: "Present" },
  { id: 4, name: "Absent" },
  { id: 5, name: "Reddish" },
  { id: 6, name: "Colourless" },
  { id: 7, name: "Liquid" },
];

export const methodologyOptions = [
  { id: 1, name: "Serum Calculation" },
  { id: 2, name: "Serum GHOD-POD" },
  { id: 3, name: "Microscopic" },
];

export const categoryOptions = [
  { id: 1, name: "All" },
  { id: 2, name: "Newborn" },
  { id: 3, name: "Infants" },
  { id: 4, name: "Children" },
  { id: 5, name: "Adolescents" },
  { id: 6, name: "Adult" },
  { id: 7, name: "Male" },
  { id: 8, name: "Female" },
];

export const conditionOptions = [
  { id: ">", name: "Greater than ( > )" },
  { id: "<", name: "Less than ( < )" },
  { id: "-", name: "Between ( - )" },
];
export const billStatusOptions = [
  { id: "all", name: "ALL" },
  { id: "Billed", name: "Billed" },
  { id: "Paid", name: "Paid" },
  { id: "Partiallypaid", name: "Partiallypaid" },
  { id: "Canceled", name: "Canceled" },
];

export const campaignFilterOptions = [
  { id: 1, name: "All" },
  { id: 2, name: "Vaccines" },
  { id: 3, name: "Investigations" },
  { id: 4, name: "Scans" },
  { id: 5, name: "Orders" },
];

export const itemCategoryOptions = [
  { id: 1, name: "Lab Test" },
  { id: 2, name: "Radiology" },
  { id: 3, name: "Other" },
];

export const paymentOptions = [
  { id: "Cash", name: "Cash" },
  { id: "Bank", name: "Bank" },
  { id: "Advance", name: "Advance" },
  { id: "Excess", name: "Excess" },
];

export const procurementBillListOptions = [
  { id: "bill_list", name: "Bill list" },
  { id: "approval_list", name: "Approval list" },
];

export const indentStatusOptions = [
  { id: 2, name: "Canceled" },
  { id: 3, name: "Ordered", disabled: true },
  { id: 4, name: "Discontinued" },
  { id: 5, name: "Received" },
];
