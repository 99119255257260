import { Typography, Grid, Switch, InputAdornment } from "@mui/material";
import { DeleteIcon } from "../../../../../assets/icons";
import {
  TextField,
  Select,
  SaveButton,
  CancelButton,
} from "../../../../../components/basic";
import {
  AutoComplete,
  CheckBox,
  Button,
  InfiniteScroller,
} from "../../../../../components/basic";
import { RouteUrls } from "../../../../../constants/routes";
import { useNavigate, useParams } from "react-router-dom";

type AddAndEditProductType = {
  fieldData: any;
  handleInputChange: (e: any, index?: number | string) => void;
  handleValidation: (e: any) => void;
  handleAutoCompleteChange: (e: any, newValue: any, name: string) => void;
  handleAutoCompleteInputChange: (newInputValue: any, name: string) => void;
  handleSelectChange: (e: any, id: number) => void;
  productFormData: any;
  handleDeleteDepartment: (id: number) => Promise<void>;
  handleAddDepartment: () => Promise<void>;
  handleSwitchChange: (e: any) => Promise<void>;
  idToBeUpdated: number | null;
  handleAddItem?: any;
  createProduct?: () => void;
  updateExistPackType?: () => void;
  departmentList?: any;
  getDrugClassificationList?: any;
  isButtonLoading?: any;
  isUpdateExistButtonLoading?: any;
  handleCheckBoxChange?: any;
  closeAddAndEditDialog?: any;
  getAllMajorDrugsData?: any;
};

const AddAndEditProduct = ({
  fieldData,
  handleInputChange,
  handleValidation,
  handleAutoCompleteChange,
  handleAutoCompleteInputChange,
  handleSelectChange,
  productFormData,
  handleDeleteDepartment,
  handleAddDepartment,
  handleSwitchChange,
  idToBeUpdated,
  handleAddItem,
  createProduct,
  updateExistPackType,
  departmentList,
  getDrugClassificationList,
  isButtonLoading,
  isUpdateExistButtonLoading,
  handleCheckBoxChange,
  closeAddAndEditDialog,
  getAllMajorDrugsData,
}: AddAndEditProductType) => {
  const navigate = useNavigate();
  return (
    <div>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          "& .MuiTypography-root": {
            m: "10px 0px",
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            width: "100%",
            textAlign: "left",
            color: "primary.main",
          }}
        >
          Product Details
        </Typography>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <TextField
            value={fieldData.name.value}
            name={fieldData.name.name}
            onChange={handleInputChange}
            label={fieldData.name.label}
            helperText={fieldData.name.helperText}
            placeholder="Enter Name"
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.name }))
            }
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />

          <TextField
            value={fieldData.generic_name.value}
            name={fieldData.generic_name.name}
            onChange={handleInputChange}
            label={fieldData.generic_name.label}
            placeholder="Enter Generic Name"
            helperText={fieldData.generic_name.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.generic_name }))
            }
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <TextField
            value={fieldData.hsn_code.value}
            name={fieldData.hsn_code.name}
            onChange={handleInputChange}
            label={fieldData.hsn_code.label}
            placeholder="Enter HSN Code"
            error={fieldData.hsn_code.isError}
            helperText={fieldData.hsn_code.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.hsn_code }))
            }
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />

          <TextField
            value={fieldData.prescription.value}
            name={fieldData.prescription.name}
            onChange={handleInputChange}
            label={fieldData.prescription.label}
            placeholder="Enter Prescription"
            error={fieldData.prescription.isError}
            helperText={fieldData.prescription.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.prescription }))
            }
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />
        </Grid>
        <Typography
          variant="h2"
          sx={{
            width: "100%",
            textAlign: "left",
            color: "primary.main",
          }}
        >
          Drug Composition Details
        </Typography>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <AutoComplete
            value={fieldData.major_drug.value}
            disableClearable={true}
            inputValue={fieldData.major_drug.major_drug_input}
            options={fieldData.major_drug.options}
            name={fieldData.major_drug.name}
            label={fieldData.major_drug.label}
            placeholder="Select Major Drug"
            error={fieldData.major_drug.isError}
            helperText={fieldData.major_drug.helperText}
            onChange={(e: any, newValue: any) =>
              handleAutoCompleteChange(e, newValue, fieldData.major_drug.name)
            }
            onInputChange={(e: any, newValue: any) => {
              handleAutoCompleteInputChange(newValue, "major_drug_input");
            }}
            onClick={() => handleAddItem("Major Drugs")}
            customButton={{
              enabled: true,
              text: "+ Add",
            }}
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },

              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                height: "46px",
                padding: "5px 8px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                borderRadius: "8px",
              },
            }}
            // isVirtualization={true}
          />
          {/* <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
            <InfiniteScroller
              loadOptions={getAllMajorDrugsData}
              options={fieldData.major_drug.options}
              name={fieldData.major_drug.name}
              label={fieldData.major_drug.label}
              placeholder="Select Major Drug"
              error={fieldData.major_drug.isError}
              helperText={fieldData.major_drug.helperText}
              defaultOptions={fieldData.major_drug.defaultOptions}
              handleOnChange={handleInputChange}
              width={300}
              // sx={{
              //   backgroundColor: "red",
              //   pointerEvents: appointmentId ? "none" : "",
              //   opacity: appointmentId ? 1 : 1,
              // }}
            />
            <Box
              sx={{
                width: "30px",
                height: "30px",
                borderRadius: " 5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor: "#ECF4FF ",
                mt: 4.5,
                ml: 2,
                cursor: "pointer",
              }}
              onClick={() => handleAddItem("Major Drugs")}
            >
              <AddIcon />
            </Box>
          </Grid> */}

          <AutoComplete
            value={fieldData.drug_classification.value}
            name={fieldData.drug_classification.name}
            label={fieldData.drug_classification.label}
            placeholder="Select Drug Classification"
            error={fieldData.drug_classification.isError}
            helperText={fieldData.drug_classification.helperText}
            disableClearable={true}
            options={fieldData.drug_classification.options}
            inputValue={fieldData.drug_classification.drug_classification_input}
            onChange={(e: any, newValue: any, name: string) =>
              handleAutoCompleteChange(
                e,
                newValue,
                fieldData.drug_classification.name
              )
            }
            onClick={() => handleAddItem("Drug Classification")}
            onInputChange={(e: any, newValue: any) => {
              handleAutoCompleteInputChange(
                newValue,
                "drug_classification_input"
              );
            }}
            customButton={{ enabled: true, text: "+ Add" }}
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },

              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                height: "46px",
                padding: "5px 10px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                borderRadius: "8px",
              },
            }}
            // isVirtualization={true}
          />

          {/* <InfiniteScroller
            value={fieldData.drug_classification.value}
            options={fieldData.drug_classification.options}
            name={fieldData.drug_classification.name}
            label={fieldData.drug_classification.label}
            placeholder="Select Drug Classification"
            error={fieldData.drug_classification.isError}
            helperText={fieldData.drug_classification.helperText}
            loadOptions={getDrugClassificationList}
            handleOnChange={handleInputChange}
            width={"350px"}
          /> */}
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <AutoComplete
            value={fieldData.dosage_form.value}
            disableClearable={true}
            inputValue={fieldData.dosage_form.dosage_form_input}
            options={fieldData.dosage_form.options}
            name={fieldData.dosage_form.name}
            label={fieldData.dosage_form.label}
            placeholder="Select Dosage Form"
            error={fieldData.dosage_form.isError}
            helperText={fieldData.dosage_form.helperText}
            onChange={(e: any, newValue: any, name: string) =>
              handleAutoCompleteChange(e, newValue, fieldData.dosage_form.name)
            }
            onClick={() => handleAddItem("Dosage Forms")}
            onInputChange={(e: any, newValue: any) => {
              handleAutoCompleteInputChange(newValue, "dosage_form_input");
            }}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.dosage_form }))
            }
            customButton={{ enabled: true, text: "+ Add" }}
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },

              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                height: "46px",
                padding: "5px 10px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                borderRadius: "8px",
              },
            }}
            // isVirtualization={true}
          />

          <AutoComplete
            value={fieldData.unit.value}
            disableClearable={true}
            inputValue={fieldData.unit.unit_input}
            options={fieldData.unit.options}
            name={fieldData.unit.name}
            label={fieldData.unit.label}
            error={fieldData.unit.isError}
            helperText={fieldData.unit.helperText}
            onChange={(e: any, newValue: any, name: string) =>
              handleAutoCompleteChange(e, newValue, fieldData.unit.name)
            }
            onClick={() => handleAddItem("Units")}
            onInputChange={(e: any, newValue: any) => {
              handleAutoCompleteInputChange(newValue, "unit_input");
            }}
            placeholder="Select Unit"
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.unit }))
            }
            customButton={{ enabled: true, text: "+ Add" }}
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },

              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                height: "46px",
                padding: "5px 10px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                borderRadius: "8px",
              },
            }}
            // isVirtualization={true}
          />
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <Select
            value={fieldData.schedule_type.value}
            options={fieldData.schedule_type.options}
            name={fieldData.schedule_type.name}
            label={fieldData.schedule_type.label}
            error={fieldData.schedule_type.isError}
            placeholder="Select Schedule Type"
            helperText={fieldData.schedule_type.helperText}
            onChange={(e) => handleInputChange(e)}
            // selectedType="number"
            // width={{ xs: "350px", sm: "250px", md: "350px" }}
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
            // isVirtualization={true}
          />

          <AutoComplete
            value={fieldData.manufacturer.value}
            disableClearable={true}
            inputValue={fieldData.manufacturer.manufacturer_input}
            options={fieldData.manufacturer.options}
            name={fieldData.manufacturer.name}
            label={fieldData.manufacturer.label}
            placeholder="Select Manufacturer"
            error={fieldData.manufacturer.isError}
            helperText={fieldData.manufacturer.helperText}
            onChange={(e: any, newValue: any, name: string) =>
              handleAutoCompleteChange(e, newValue, fieldData.manufacturer.name)
            }
            onClick={() => handleAddItem("Manufacturer")}
            onInputChange={(e: any, newValue: any) => {
              handleAutoCompleteInputChange(newValue, "manufacturer_input");
            }}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.manufacturer }))
            }
            customButton={{ enabled: true, text: "+ Add" }}
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },

              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                height: "46px",
                padding: "5px 10px",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                borderRadius: "8px",
              },
            }}
            // isVirtualization={true}
            // filterOptions={createFilterOptions({
            //   ignoreCase: true,
            //   matchFrom: "start",
            //   limit: 100,
            // })}
          />
        </Grid>
        <Typography
          variant="h2"
          sx={{
            width: "100%",
            textAlign: "left",
            color: "primary.main",
          }}
        >
          Purchase Details
        </Typography>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <Select
            value={fieldData.tax.value}
            onChange={(e) => handleSelectChange(e, 0)}
            name={fieldData.tax.name}
            placeholder={"Select Tax"}
            options={fieldData.tax.options}
            label={fieldData.tax.label}
            error={fieldData.tax.isError}
            helperText={fieldData.tax.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.tax }))
            }
            selectedType="number"
            // width={{ xs: "350px", sm: "250px", md: "350px" }}
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />

          <TextField
            value={fieldData.discount.value}
            name={fieldData.discount.name}
            onChange={handleInputChange}
            placeholder="Enter Discount"
            label={fieldData.discount.label}
            helperText={fieldData.discount.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.discount }))
            }
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  sx={{ fontWeight: 700, fontSize: "18px" }}
                >
                  %
                </InputAdornment>
              ),
            }}
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />
        </Grid>
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "flex-start", sm: "center" },
            justifyContent: "space-between",
          }}
        >
          <TextField
            value={fieldData.pack_type.value}
            name={fieldData.pack_type.name}
            onChange={handleInputChange}
            placeholder="Enter Pack Type"
            label={fieldData.pack_type.label}
            helperText={fieldData.pack_type.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.pack_type }))
            }
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />

          <TextField
            value={fieldData.rackposition.value}
            name={fieldData.rackposition.name}
            onChange={handleInputChange}
            label={fieldData.rackposition.label}
            placeholder="Enter Rack Position"
            error={fieldData.rackposition.isError}
            helperText={fieldData.rackposition.helperText}
            onBlur={(e: any) =>
              handleValidation((e = { target: fieldData.rackposition }))
            }
            sx={{
              width: {
                xs: "350px",
                sm: "250px",
                md: "350px",
              },
            }}
          />
        </Grid>
        {productFormData?.product_departments?.map((data: any, index: any) => {
          return (
            <>
              {index === 0 && (
                <Grid
                  key={index}
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "350px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ width: { xs: "50%", md: "350px" } }}
                    >
                      Department
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "350px",
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        width: { sm: "70px", md: "90px" },
                        textAlign: "left",
                      }}
                    >
                      Re Order Qty
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        width: { sm: "70px", md: "80px" },
                        textAlign: "left",
                      }}
                    >
                      Max Qty
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        width: { sm: "70px", md: "100px" },
                        textAlign: "left",
                      }}
                    >
                      Min Qty
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{
                        width: { sx: "15px" },
                        textAlign: "left",
                      }}
                    ></Typography>
                  </Grid>
                </Grid>
              )}
              <Grid
                key={index}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: { xs: "row" },
                  alignItems: { xs: "center" },
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "350px",
                    mb: "10px",
                  }}
                >
                  <Select
                    value={data.dept_id as number}
                    onChange={(e) => handleSelectChange(e, index)}
                    name="dept_id"
                    placeholder="Select Department"
                    options={departmentList}
                    // width="350px"
                    sx={{
                      width: {
                        xs: "180px",
                        sm: "250px",
                        md: "350px",
                      },
                    }}
                  />
                  <CheckBox
                    value={data.is_indent}
                    checked={data.is_indent}
                    onChange={(e: any) => handleCheckBoxChange(e, index)}
                    name="is_indent"
                    sx={{ width: "30px" }}
                    formControlStyle={{ mt: "10px", ml: "0px" }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "350px",
                    mb: "10px",
                  }}
                >
                  <TextField
                    value={data.re_order_qty}
                    name="re_order_qty"
                    onChange={(e) => handleInputChange(e, index)}
                    sx={{
                      width: {
                        sm: "70px",
                        md: "100px",
                      },
                    }}
                  />
                  <TextField
                    value={data.max_qty}
                    name="max_qty"
                    onChange={(e) => handleInputChange(e, index)}
                    sx={{
                      width: {
                        sm: "70px",
                        md: "90px",
                      },
                    }}
                  />
                  <TextField
                    value={data.min_qty}
                    name="min_qty"
                    onChange={(e) => handleInputChange(e, index)}
                    sx={{
                      width: {
                        sm: "70px",
                        md: "80px",
                      },
                    }}
                  />
                  <Grid
                    sx={{
                      width: {
                        sx: "15px",
                      },
                      p: "10px",
                      display: index !== 0 ? "flex" : "hidden",
                      border: index !== 0 ? 1 : 0,
                      borderColor: "backgroundPrimary.main",
                      transition: "all 0.2s linear",
                      "&:hover": { borderColor: "textPrimary.main" },
                      cursor: index !== 0 ? "pointer" : "default",
                    }}
                    onClick={
                      index !== 0
                        ? () => {
                            handleDeleteDepartment(index);
                          }
                        : () => {}
                    }
                  >
                    {index !== 0 ? (
                      <DeleteIcon width="15px" />
                    ) : (
                      <Typography
                        variant="h6"
                        sx={{
                          width: "15px",
                          color: "backgroundPrimary.main",
                        }}
                      >
                        ""
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          );
        })}
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            borderTop: 0,
            width: "100%",
          }}
        >
          <Button
            variant="text"
            buttonText="+ Add"
            handleClick={handleAddDepartment}
            sx={{
              borderRadius: "0px",
              width: "auto",
              height: "auto",
              fontWeight: 400,
              fontSize: "13px",
              border: 0,
              color: "primary.main",
              "&:hover": {
                backgroundColor: "initial",
                color: "primary.main",
                borderColor: "initial",
              },
              "&.Mui-focusVisible": {
                border: 1,
                borderColor: "primary.main",
              },
            }}
          />
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            mb: "10px",
            width: "100%",
          }}
        >
          <Typography>{fieldData.status.label}</Typography>
          <Switch
            name={fieldData.status.name}
            checked={fieldData.status.value}
            onChange={handleSwitchChange}
            value={fieldData.status.active}
            disabled={Boolean(!idToBeUpdated)}
          />
        </Grid>
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            gap: 2,
          }}
        >
          <SaveButton
            sx={{ width: "100px", height: "42px" }}
            handleClick={createProduct}
            loading={isButtonLoading}
          />
          <CancelButton
            sx={{ width: "100px", height: "42px", ml: 3 }}
            handleClick={closeAddAndEditDialog}
          />
        </Grid>
        {idToBeUpdated !== null && (
          <>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                justifyContent: "space-between",
                mt: 2,
                gap: 2,
              }}
            >
              {" "}
              <TextField
                value={fieldData.exist_pack_type.value}
                name="exist_pack_type"
                onChange={handleInputChange}
                label="Pack Type"
                placeholder="Enter Pack Type"
                sx={{
                  width: {
                    xs: "350px",
                    sm: "250px",
                    md: "350px",
                  },
                }}
                helperText=""
              />
              <TextField
                name="p_rate"
                value={fieldData.pack_rate.value}
                onChange={handleInputChange}
                label="Purchase Rate"
                placeholder="Enter Purchase Rate"
                sx={{
                  width: {
                    xs: "350px",
                    sm: "250px",
                    md: "350px",
                  },
                }}
                helperText=""
              />
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "flex-start", sm: "center" },
                justifyContent: "space-between",
              }}
            >
              {" "}
              <TextField
                value={fieldData.mrp.value}
                name="mrp"
                onChange={handleInputChange}
                label="MRP"
                placeholder="Enter MRP"
                sx={{
                  width: {
                    xs: "350px",
                    sm: "250px",
                    md: "350px",
                  },
                }}
                helperText=""
              />
              <Select
                value={fieldData.tax_id.value}
                onChange={(e) => handleSelectChange(e, 0)}
                name="tax_id"
                placeholder={fieldData.tax_id.placeholder}
                options={fieldData.tax_id.options}
                label={fieldData.tax_id.label}
                error={fieldData.tax_id.isError}
                // width="350px"
                sx={{
                  width: {
                    xs: "350px",
                    sm: "250px",
                    md: "350px",
                  },
                }}
                helperText=""
              />
            </Grid>
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                gap: 2,
                mt: 3,
              }}
            >
              <SaveButton
                sx={{ width: "100px", height: "42px" }}
                handleClick={updateExistPackType}
                loading={isUpdateExistButtonLoading}
              />
              <CancelButton
                sx={{ width: "100px", height: "42px", ml: 3 }}
                handleClick={closeAddAndEditDialog}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};

export default AddAndEditProduct;
