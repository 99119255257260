import { useCallback, useEffect, useRef, useState } from "react";
import { Grid,debounce } from "@mui/material";
import BillProducts from "./BillProducts";
import {
  getAllLabMasterDepartments,
  getAllLabSuppliers,
} from "../../../services/laboratoryService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
type optionsListTypes = {
  id: number | string | null;
  name: number | string | null;
};
const PurchaseReturn = () => {
  const [suppliersList, setSuppliersList] = useState<optionsListTypes[]>([]);
  const [departmentList, setDepartmentList] = useState<optionsListTypes[]>([]);
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const supplierPagination = useRef(1);
  const searchSupplierPagination = useRef({ search: "", page: 1 });


  // const debouncedGetAllSupplierList = useCallback(
  //   debounce(async (currentValue:any) => {
  //     return await getAllLabSuppliers({
  //       search: currentValue,
  //       page: supplierPagination.current,
  //       limit: 10,
  //     }).then((result: any) => {
  //       let data = result.data.result;
  //       const formattedSuppliersList = data.map((uniqueData: any) => {
  //         return { value: uniqueData.id, label: uniqueData.name };
  //       });
  //       setSuppliersList(formattedSuppliersList);
  //       supplierPagination.current = 1;
  //       const hasMore = result.data.total > suppliersList?.length;
  //       return {
  //         options: suppliersList,
  //         hasMore,
  //       };
  //     });
  //   }, 300),
  //   []
  // );
  // const getAllSuppliersList = async (currentValue: any) => {
  //   try {
  //     if (currentValue) {
  //       debouncedGetAllSupplierList(currentValue);
  //     } else {
  //       return await getAllLabSuppliers({
  //         search: currentValue,
  //         page: supplierPagination.current,
  //         limit: 10,
  //       }).then((result: any) => {
  //         let data = result.data.result;

  //         const formattedSuppliersList = data?.map((uniqueData: any) => {
  //           return { value: uniqueData.id, label: uniqueData.name };
  //         });
  //         if (formattedSuppliersList.length > 0) {
  //           setSuppliersList((prevState: any) => [
  //             ...prevState,
  //             ...formattedSuppliersList,
  //           ]);
  //         }
  //         const currentValue = supplierPagination.current;
  //         supplierPagination.current = currentValue + 1;
  //         const hasMore = result.data.total > suppliersList?.length;
  //         return {
  //           options: suppliersList,
  //           hasMore,
  //         };
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const debouncedGetAllSupplierList = debounce(
    async (currentValue: string, prevOptions: any[], callback: any) => {
      try {

        return await getAllLabSuppliers({
          search: currentValue,
          page:
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page
              : 1,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.result;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return { value: uniqueData.id, label: uniqueData.name };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );
          setSuppliersList(uniqueOptions);
          searchSupplierPagination.current.page =
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page + 1
              : 1;
          searchSupplierPagination.current.search = currentValue;
          const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
        
      } catch (error) {

        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
        
      }
     
    },
    300
  );

  const getAllSuppliersList = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllSupplierList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page = supplierPagination.current === Math.ceil(prevOptions.length / 10)
            ? supplierPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllLabSuppliers({
          search: currentValue,
          page: page,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.result;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return { value: uniqueData.id, label: uniqueData.name };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setSuppliersList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = supplierPagination.current;
          supplierPagination.current = currentValue + 1;
          const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:",error);
      return {
        options:[] ,
        hasMore:false,
      };
    }
  };

  const getAllDepartmentList = async () => {
    // const isGoodsIssue = appConfiguration?.lab_goods_issue_disable
    //   ? "receiver"
    //   : "issuer";
    const data = { active: 1 };
    await getAllLabMasterDepartments(data)
      .then((res: any) => {
        const isGoodsIssue = appConfiguration?.lab_goods_issue_disable
          ? "receiver"
          : "issuer";
        const formattedValue = res.data.result
          ?.filter(
            (elem: { type: string }) =>
              elem.type !== "B2B" && elem.type === isGoodsIssue
          )
          .map((ele: { dept_name: string; id: number }) => ({
            id: ele.id,
            name: ele.dept_name,
          }));
        setDepartmentList(formattedValue);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllDepartmentList();
  }, [appConfiguration]);
  return (
    <Grid
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <BillProducts
        suppliersList={suppliersList}
        departmentList={departmentList}
        loadOptions={getAllSuppliersList}
      />
    </Grid>
  );
};

export default PurchaseReturn;
