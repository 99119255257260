import axios from "axios";
import {
  laboratoryApiEndPoints,
  procurementsApiEndPoints,
} from "../constants/apiEndPoints";
import QueryParamsBuilder from "../helper/QueryParamsBuilder";
import ApiUtil from "../utils/ApiUtils";
import { getCookie } from "../utils/GeneralUtils";

type PurchaseBillType = {
  supplier_id?: number | string | null;
  bill_id?: number | string | null;
};

const {
  getAllPurchaseBillList,
  getPurchaseOrdersList,
  getAllPurchaseSuppliersList,
  getAllStockAdjustmentsList,
  getAllGoodsIssueList,
  getAllIndentsList,
  procurementProducts,
  sequenceUrl,
  procurementTaxUrl,
  getAllSuppliers,
  purchaseOrderUrl,
  purchaseTemporaryUrl,
  stockAdjustmentUrl,
  supplierExcessAndAdvanceUrl,
  purchaseBillFileUpload,
} = procurementsApiEndPoints;
const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);

  let queryString = "?";
  keys.map((key) => {
    if (data[key] && !Array.isArray(data[key])) {
      queryString += `${key}=${data[key]}&`;
    }
    if (Array.isArray(data[key])) {
      data[key].map(
        (ele: any, index: number) => (queryString += `${key}[${index}]=${ele}&`)
      );
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};
const getSupplierById = async (id: string | number) => {
  return await ApiUtil.getWithToken(`${getAllSuppliers}/${id}`);
};

const getAllPurchaseBill = async (
  params: any
): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(params, getAllPurchaseBillList);

  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
const getAllPurchaseSuppliers = async (params: any) => {
  const apiUrl = setQueryParams(params, getAllPurchaseSuppliersList);
  return await ApiUtil.getWithToken(apiUrl);
};
const createStockAdjustment = async (data: any) => {
  return await ApiUtil.postWithToken(`${stockAdjustmentUrl}`, data);
};

const getAllPurchasePayments = async (
  data: any
): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(
    data,
    procurementsApiEndPoints.getAllPurchasePaymentsList
  );
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};

const getAllPurchaseOrders = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: getPurchaseOrdersList,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue: searchValue,
  });

  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};

const generateGoodsIssue = async (data: any) => {
  try {
    return await ApiUtil.postWithToken(
      `${getPurchaseOrdersList}/goods-issue`,
      data
    );
  } catch (error) {
    console.log("Error occurs while creating the goods issue:", error);
    throw error;
  }
};

const deletePurchaseOrderById = async (id: number | string) => {
  try {
    return await ApiUtil.deleteWithToken(`${getPurchaseOrdersList}/${id}`);
  } catch (error) {
    console.log("Error occurs while deleting the purchase order by id:", error);
    throw error;
  }
};

const deletePurchaseOrderProductWiseById = async (
  id: number | string,
  product_id: string | number
) => {
  try {
    return await ApiUtil.deleteWithToken(
      `${getPurchaseOrdersList}/${id}/product/${product_id}`,
      { product_id }
    );
  } catch (error) {
    console.log(
      "Error occurs while deleting the purchase order product wise by id:",
      error
    );
    throw error;
  }
};
const getAllStockAdjustment = async (
  data: any
): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(data, getAllStockAdjustmentsList);

  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
type GoodsIssueType = {
  page?: string | null | number;
  limit?: string | null | number;
  column?: string | null | number;
  order?: string | null | number;
  from_date?: string | null | number;
  to_date?: string | null | number;
  type?: string | null | number;
  invoice_no?: string | null | number;
  product?: string | null | number;
  search?: string | null | number;
};
const getAllGoodsIssue = async (
  data: GoodsIssueType
): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(data, getAllGoodsIssueList);
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};

const getAllIndents = async (params: {
  page: number;
  limit: number;
  column: number;
  order: number;
  indent_no: string;
  from_date: any;
  to_date: any;
  search: string;
}): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(params, getAllIndentsList);

  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};

const getAllProcurementProducts = async (params: {
  search?: string;
  department?: string | number;
  start?: number;
  length?: number;
}) => {
  try {
    // const apiUrl = setQueryParams(params, `${getAllGoodsIssueList}/products`);
    const apiUrl = setQueryParams(
      params,
      `${procurementProducts}/all-products`
    );
    return await ApiUtil.getWithToken(apiUrl);
    // return {
    //   data: [
    //     {
    //       id: 6672,
    //       name: "Product s",
    //       generic_name: "Generic name",
    //       prescription: "1234",
    //       tax: 18,
    //       manufacturer_id: null,
    //       rackposition: "1",
    //       pack_type: "1",
    //       major_drug_id: 1,
    //       drug_clasification_id: 1,
    //       dosage_form_id: 1,
    //       unit_id: 1,
    //       schedule_type: "Scheduled",
    //       hsn_code: "1234",
    //       discount: "5.00",
    //       p_rate: null,
    //       mrp: null,
    //       tax_id: null,
    //       departments: null,
    //       active: 1,
    //       is_gynecology: 0,
    //       created_at: "2024-05-17T09:58:04.000000Z",
    //       updated_at: "2024-05-17T12:04:55.000000Z",
    //       deleted_at: null,
    //       created_by: 1,
    //       updated_by: 1,
    //       deleted_by: null,
    //     },
    //   ],
    // };
  } catch (error) {
    console.log("Error occurs while getting the procurement products:", error);
    throw error;
  }
};

const getSequenceNoByDepartment = async (department: any) => {
  try {
    return await ApiUtil.getWithToken(`${sequenceUrl}/${department}`);
  } catch (error) {
    console.log("Error occurs while getting the sequence number:", error);
    throw error;
  }
};

const getTaxOptions = async () => {
  try {
    return await ApiUtil.getWithToken(`${procurementTaxUrl}/all-taxes`);
  } catch (error) {
    console.log("Error occurs while getting the tax options:", error);
    throw error;
  }
};

const getExcessAmountBySupplierId = async ({
  supplier_id,
}: {
  supplier_id?: string | number;
}) => {
  try {
    return await ApiUtil.getWithToken(
      `${supplierExcessAndAdvanceUrl}/${supplier_id}`
    );
  } catch (error) {
    console.log("Error while getting the excess amount by patient id", error);
    throw error;
  }
};

const getProductByPoNo = async (
  po_no: any,
  params?: {
    dept_id: string | number;
  }
) => {
  try {
    const apiUrl = setQueryParams(
      params,
      `${getPurchaseOrdersList}/${po_no}/order`
    );
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error while getting the product by Po Number", error);
    throw error;
  }
};

const createPurchaseBill = async (data: any, purchase_approval: boolean) => {
  try {
    // return await ApiUtil.postWithToken(getAllPurchaseBillList, data);
    console.log("createPurchaseBill", purchase_approval);

    return await ApiUtil.postWithToken(
      purchase_approval ? purchaseTemporaryUrl : getAllPurchaseBillList,
      data
    );
  } catch (error) {
    console.log("Error occurs while creating the purchase bill:", error);
    throw error;
  }
};

const updatePurchaseBillById = async (
  id: number | string,
  data: any,
  purchase_approval: boolean
) => {
  try {
    // return await ApiUtil.putWithToken(`${getAllPurchaseBillList}/${id}`, data);
    return await ApiUtil.putWithToken(
      `${
        purchase_approval ? purchaseTemporaryUrl : getAllPurchaseBillList
      }/${id}`,
      data
    );
  } catch (error) {
    console.log("Error occurs while creating the purchase bill:", error);
    throw error;
  }
};

const uploadInvoiceFile = async ({
  id,
  formData,
}: {
  id: string;
  formData: any;
}) => {
  try {
    const localStorageAccessToken: any = getCookie("accessToken");

    if (localStorageAccessToken) {
      const access_token = JSON.parse(localStorageAccessToken);
      return await axios.post(`${purchaseBillFileUpload}/${id}`, formData, {
        headers: {
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
      });
    }
  } catch (error) {
    console.log("Error occurs while creating the purchase bill:", error);
    throw error;
  }
};
const cancelPurchaseBillById = async (id: number | string) => {
  try {
    return await ApiUtil.putWithToken(`${getAllPurchaseBillList}/${id}/cancel`);
  } catch (error) {
    console.log(
      "Error occurs while cancelling the purchase bill by id:",
      error
    );
    throw error;
  }
};

const cancelPurchaseTempBillById = async (id: number | string) => {
  try {
    return await ApiUtil.putWithToken(`${purchaseTemporaryUrl}/${id}/reject`);
  } catch (error) {
    console.log("Error occurs while rejecting the purchase bill by id:", error);
    throw error;
  }
};

const approvePurchaseTempBillById = async (id: number | string) => {
  try {
    return await ApiUtil.putWithToken(`${purchaseTemporaryUrl}/${id}/approval`);
  } catch (error) {
    console.log("Error occurs while rejecting the purchase bill by id:", error);
    throw error;
  }
};

const getPurchaseBillById = async (
  id: number | string,
  purchase_approval: boolean
) => {
  try {
    // return await ApiUtil.getWithToken(`${getAllPurchaseBillList}/${id}`);
    return await ApiUtil.getWithToken(
      `${
        purchase_approval ? purchaseTemporaryUrl : getAllPurchaseBillList
      }/${id}`
    );
  } catch (error) {
    console.log("Error occurs while creating the purchase bill:", error);
    throw error;
  }
};

const getPoNoOptionsBySupplierId = async (params: any) => {
  try {
    const apiUrl = setQueryParams(params, purchaseOrderUrl);
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error occurs while getting the purchase order:", error);
    throw error;
  }
};

const getProductsPriceMarginByProductId = async (id: number | string) => {
  try {
    return await ApiUtil.getWithToken(
      `${getAllPurchaseBillList}/price-margin/${id}`
    );
  } catch (error) {
    console.log("Error occurs while getting the product price margin:", error);
    throw error;
  }
};

const deletePurchaseBillById = async (
  id: number | string,
  purchase_approval: boolean
) => {
  try {
    return await ApiUtil.deleteWithToken(
      `${
        purchase_approval ? purchaseTemporaryUrl : getAllPurchaseBillList
      }/${id}`
    );
  } catch (error) {
    console.log("Error occurs while deleting the purchase bill:", error);
    throw error;
  }
};

const createIndentsById = async (data: any) => {
  try {
    return await ApiUtil.postWithToken(getAllIndentsList, data);
  } catch (error) {
    console.log("Error occurs while deleting the purchase bill:", error);
    throw error;
  }
};

const getIndentById = async (id: number | string) => {
  try {
    return await ApiUtil.getWithToken(`${getAllIndentsList}/${id}`);
  } catch (error) {
    console.log("Error occurs while getting the indent by id:", error);
    throw error;
  }
};

const updateIndentsById = async (id: number | string, data: any) => {
  try {
    return await ApiUtil.putWithToken(`${getAllIndentsList}/${id}`, data);
  } catch (error) {
    console.log("Error occurs while deleting the purchase bill:", error);
    throw error;
  }
};

const deleteIndentById = async (id: string | number) => {
  try {
    return await ApiUtil.deleteWithToken(`${getAllIndentsList}/${id}`);
  } catch (error) {
    console.log("Error occurs while deleting the purchase bill:", error);
    throw error;
  }
};

const createPo = async (data: any) => {
  try {
    return await ApiUtil.postWithToken(`${purchaseOrderUrl}`, data);
  } catch (error) {
    console.log("Error occurs while creating the purchase order:", error);
    throw error;
  }
};

const getPurchaseOrderById = async (id: number | string) => {
  try {
    return await ApiUtil.getWithToken(`${purchaseOrderUrl}/${id}`);
  } catch (error) {
    console.log("Error occurs while getting the purchase order by id:", error);
    throw error;
  }
};

const updatePurchaseOrderById = async (id: number | string, data: any) => {
  try {
    return await ApiUtil.putWithToken(`${purchaseOrderUrl}/${id}`, data);
  } catch (error) {
    console.log("Error occurs while updating the purchase order by id:", error);
    throw error;
  }
};
type LabStockAdjustmentTypes = {
  product?: string | null | number;
  chemical_reagent?: string | null | number;
  search?: string | null | number;
  column?: string | null | number;
  order?: string | null | number;
  page?: string | null | number;
  limit?: string | null | number;
};
const getAllLabStockAdjustment = async (
  data: LabStockAdjustmentTypes
): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(
    data,
    laboratoryApiEndPoints.getAllStockAdjustmentsList
  );
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
type LabGoodsIssueType = {
  page?: string | null | number;
  limit?: string | null | number;
  column?: string | null | number;
  order?: string | null | number;
  from_date?: string | null | number;
  to_date?: string | null | number;
  type?: string | null | number;
  invoice_no?: string | null | number;
  product?: string | null | number;
  search?: string | null | number;
};
const getAllLabGoodsIssue = async (
  data: LabGoodsIssueType
): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(data, laboratoryApiEndPoints.goodsIssueUrl);
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
const getAllLabPurchasePayments = async (
  data: any
): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(
    data,
    laboratoryApiEndPoints.getAllPurchasePaymentsList
  );
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
type WholeSalesListType = {
  from_date?: string | null | number;
  to_date?: string | null | number;
  search?: string | null | number;
  column?: string | null | number;
  order?: string | null | number;
  limit?: string | null | number;
  page?: string | null | number;
  invoice_no?: string | null | number;
};
const getAllWholeSalesList = async (
  data: WholeSalesListType
): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(data, procurementsApiEndPoints.wholeSalesUrl);
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
const deleteWholeSalesById = async (id: string | number) => {
  try {
    return await ApiUtil.deleteWithToken(
      `${procurementsApiEndPoints.wholeSalesUrl}/${id}`
    );
  } catch (error) {
    console.log("Error occurs while deleting the purchase bill:", error);
    throw error;
  }
};
const getAllSalesList = async (params: {
  department: number | string | null;
  uhid: number | string | null;
  bill_type: number | string | null;
  pay_type: number | string | null;
  status: number | string | null;
  bill_no: number | string | null;
  from_date: number | string | null;
  to_date: number | string | null;
  search: number | string | null;
  column: number | string | null;
  order: number | string | null;
  page: number | string | null;
  limit: number | string | null;
}) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.pharmacySalesBill
  );
  return await ApiUtil.getWithToken(apiUrl);
};

const getProductsPriceMarginByCustomerId = async (params: {
  id: number;
  customer_id: number;
}) => {
  try {
    const apiUrl = setQueryParams(
      params,
      procurementsApiEndPoints.salesHistoryUrl
    );
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error occurs while getting the product price margin:", error);
    throw error;
  }
};

const getAllWholesSalesPayment = async (
  data: any
): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = setQueryParams(
    data,
    procurementsApiEndPoints.wholeSalesPayments
  );
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
const getPurchasePaymentBillById = async (params: PurchaseBillType) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getPurchasePaymentBill
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const updateWholeSalesPurchaseBillById = async (
  id: number | string,
  data: any
) => {
  try {
    return await ApiUtil.putWithToken(
      `${procurementsApiEndPoints.wholeSalesUrl}/${id}`,
      data
    );
  } catch (error) {
    console.log("Error occurs while creating the purchase bill:", error);
    throw error;
  }
};
const addWholeSalesPurchaseBillById = async (data: any) => {
  try {
    return await ApiUtil.postWithToken(
      `${procurementsApiEndPoints.wholeSalesUrl}`,
      data
    );
  } catch (error) {
    console.log("Error occurs while creating the purchase bill:", error);
    throw error;
  }
};
const getWholeSalesAdvanceAndExcessByCustomer = async (
  id: number | string
): Promise<{
  data: {
    advance: number;
    creditlimit: number;
    excess: number;
    overalldue: number;
  };
}> => {
  return (await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.customerAdvanceExcessWholeSales}/${id}`
  )) as {
    data: {
      advance: number;
      creditlimit: number;
      excess: number;
      overalldue: number;
    };
  };
};

const getAllIndentProducts = async (params: {
  type: string;
  dept_id: string | number;
  from_date: any;
  to_date: any;
}) => {
  try {
    const apiUrl = setQueryParams(
      params,
      procurementsApiEndPoints.getAllIndentProductsUrl
    );
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Errors occurs while getting the indent products:", error);
    throw error;
  }
};

const updateIndentProductStatus = async (data: {
  indent_id: string | number;
  indent_product_id: string | number;
  status: string | number;
}) => {
  try {
    return await ApiUtil.patchWithToken(
      `${procurementsApiEndPoints?.getAllIndentsList}/status`,
      data
    );
  } catch (error) {
    console.log("Errors occurs while getting the indent products:", error);
    throw error;
  }
};
export {
  getWholeSalesAdvanceAndExcessByCustomer,
  getAllWholesSalesPayment,
  getProductsPriceMarginByCustomerId,
  getAllSalesList,
  deleteWholeSalesById,
  getAllWholeSalesList,
  getAllLabPurchasePayments,
  getAllLabGoodsIssue,
  getAllPurchaseBill,
  getAllLabStockAdjustment,
  getAllPurchasePayments,
  getAllPurchaseOrders,
  getAllPurchaseSuppliers,
  getAllStockAdjustment,
  getAllGoodsIssue,
  getAllIndents,
  getAllProcurementProducts,
  createPurchaseBill,
  getSequenceNoByDepartment,
  getTaxOptions,
  getExcessAmountBySupplierId,
  getProductByPoNo,
  getPurchaseBillById,
  getSupplierById,
  updatePurchaseBillById,
  getPoNoOptionsBySupplierId,
  cancelPurchaseBillById,
  getProductsPriceMarginByProductId,
  deletePurchaseBillById,
  createIndentsById,
  getIndentById,
  updateIndentsById,
  deleteIndentById,
  createPo,
  getPurchaseOrderById,
  updatePurchaseOrderById,
  cancelPurchaseTempBillById,
  approvePurchaseTempBillById,
  getPurchasePaymentBillById,
  addWholeSalesPurchaseBillById,
  updateWholeSalesPurchaseBillById,
  createStockAdjustment,
  uploadInvoiceFile,
  getAllIndentProducts,
  updateIndentProductStatus,
  deletePurchaseOrderById,
  deletePurchaseOrderProductWiseById,
  generateGoodsIssue,
};
