import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type AutocompleteWithTableProps = {
  value?: string | null;
  name?: string | any;
  onChange?: any;
  placeholder?: string;
  options: string[];
  sx?: {};
  renderOption?: any;
  getOptionLabel?: any;
  PopperComponent?: any;
  onFocus?: any;
  onBlur?: any;
  isHideClearIcon?: boolean;
  isDisabled?: boolean;
  inputRef?: any;
  inputValue?: any;
  onInputChange?: any;
  noOptionsText?: any;
  filterOptions?: any;
};

const MuiAutocompleteWithTable = ({
  value,
  onChange,
  placeholder,
  name,
  options,
  sx,
  renderOption,
  PopperComponent,
  getOptionLabel,
  isHideClearIcon,
  onFocus,
  onBlur,
  isDisabled,
  inputRef,
  inputValue,
  onInputChange,
  noOptionsText,
  filterOptions,
}: AutocompleteWithTableProps) => {
  return (
    <Autocomplete
      onChange={onChange}
      popupIcon={<ExpandMoreIcon />}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      noOptionsText={noOptionsText}
      value={value}
      sx={sx}
      onFocus={onFocus}
      onBlur={onBlur}
      disableClearable={isHideClearIcon}
      disabled={isDisabled}
      inputValue={inputValue}
      onInputChange={onInputChange}
      filterOptions={filterOptions}
      renderInput={(params) => {
        return (
          <>
            <TextField
              {...params}
              name={name}
              value={value}
              inputRef={inputRef}
              InputProps={{
                ...params.InputProps,
              }}
              placeholder={placeholder}
            />
          </>
        );
      }}
      PopperComponent={PopperComponent}
      includeInputInList
    />
  );
};

export default MuiAutocompleteWithTable;
