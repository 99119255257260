import React from "react";
import { Card, Box, CardHeader, Divider } from "@mui/material";
import CardTitle from "./CardTitle";
import CardContent from "@mui/material/CardContent";
export type CardPropsTypes = {
  children?: React.ReactNode;
  title?: string;
  titleColor?: string;
  fontSize?: string;
  headerActionTitle?: string;
  TitleIcon?: React.ElementType;
  HeaderActionIcon?: React.ElementType;
  sx?: {};
};
export default function CardComponent({
  children,
  title,
  titleColor,
  fontSize,
  headerActionTitle,
  HeaderActionIcon,
  TitleIcon,
  sx
}: CardPropsTypes) {
  const styles = {
    mainBox: {
      minWidth: 275,
    },
    cardMain: {
      border: 'none',
      boxShadow: '0px 5px 5px 0px rgba(49, 47, 47, 0.5)',
      ...sx,
    },
    cardChildren: { margin: '12px' },
  };
  return (
    <Box sx={styles.mainBox}>
      <Card variant='outlined' sx={styles.cardMain}>
        {title && (
          <CardHeader
            title={
              <CardTitle
                title={title}
                TitleIcon={TitleIcon}
                titleColor={titleColor}
                fontSize={fontSize}
                headerActionTitle={headerActionTitle}
                HeaderActionIcon={HeaderActionIcon}
              />
            }
          />
        )}
        {title && <Divider variant='middle' />}
        <Box sx={styles.cardChildren}>{children}</Box>
      </Card>
    </Box>
  );
}
