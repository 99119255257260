import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { Sort } from "../../../components/basic";

const styles = {
  tableContainerStyle: {
    borderRadius: "5px",
    boxShadow: "none",
    overflow: "hidden",
    width: "100%",
    overflowX: { xs: "scroll", lg: "hidden" },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableStyle: {
    border: "1px solid",
    borderColor: "var(--table-border)",
    width: "100%",
    // minWidth: "1200px",
    overflowX: "auto",
    "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
    "& .MuiTableRow-head": {
      backgroundColor: "var(--table-header)",
    },
    " & .MuiTableBody-root": {
      border: 0,
    },
    "& .MuiTableRow-root": {
      height: "45px !important",
      maxHeight: "34px !important",
      minHeight: "0px",
      lineHeight: "0px",
      border: 0,
    },

    "& .MuiTableCell-root": {
      minHeight: "0px",
    },

    "& .MuiTableCell-body": {
      height: "12px !important",
      maxHeight: "32px !important",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 0px",
      fontSize: "12px",
      fontWeight: "400",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "var(--table-border)",
      overflow: "hidden",
    },

    "& .MuiTableCell-body:has(.Mui-focused)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },

    "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },
    "& .deleteIconGrid": {
      cursor: "pointer",
      position: "absolute",
      right: {
        xs: "0px",
        sm: "0px",
        md: "0px",
        lg: "-2vw",
      },
      bottom: "5px",
      mb: "10px",
    },
  },
  header: {
    backgroundColor: "var(--table-header)",
  },
  textFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "0px 5px",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  selectFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
  headerStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
    borderBottom: "1px solid var(--table-border)",
    borderRight: "1px solid var(--table-border)",
    padding: "12px 12px 12px 20px",
    marginLeft: "8px",
  },
};

const TableHeader = React.memo((props: any) => {
  const { columns, sortedField, handleSorting } = props;
  return (
    <TableHead sx={{ ...styles.header }}>
      <TableRow>
        {columns?.map((column: any) => (
          <TableCell
            align={column.textAlignment}
            sx={{ ...column.headerStyle, width: `${column.width}%` }}
            onClick={() => handleSorting(column?.name)}
          >
            <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
              <Typography>{column?.header}</Typography>
              {column?.isSortable && (
                <Sort
                  ascendingActive={
                    sortedField.field === column?.name &&
                    sortedField.order === "ASC"
                      ? true
                      : false
                  }
                  descendingActive={
                    sortedField.field === column?.name &&
                    sortedField.order === "DESC"
                      ? true
                      : false
                  }
                />
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

const Row = React.memo((props: any) => {
  const { index, row, columns } = props;
  return (
    <TableRow key={`row - ${index}`}>
      {columns?.map((column: any) => (
        <TableCell
          sx={{
            width: `${columns.width}%`,
          }}
        >
          {column?.field({ row, index })}
        </TableCell>
      ))}
    </TableRow>
  );
});

const TableBodyComponent = React.memo((props: any) => {
  const { rows, columns } = props;
  return (
    <TableBody
      sx={{
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderBottomColor: "#207DFF !important",
        },
      }}
    >
      {rows.map((row: any, rowIndex: number) => (
        <Row row={row} index={rowIndex} columns={columns} />
      ))}
    </TableBody>
  );
});

const CommonEditableTable = (props: {
  columns: any;
  rows: any;
  sortedField: any;
  handleSorting: any;
  minTableWidth?: any;
  tableWidth?: any;
}) => {
  const {
    columns,
    rows,
    sortedField,
    handleSorting,
    minTableWidth = "1000px",
    tableWidth,
  } = props;

  return (
    <Box
      style={{ overflowX: "auto", minWidth: minTableWidth, width: tableWidth }}
    >
      <TableContainer sx={{ ...styles.tableContainerStyle }}>
        <Table sx={{ ...styles.tableStyle }}>
          <TableHeader
            columns={columns.headers}
            sortedField={sortedField}
            handleSorting={handleSorting}
          />
          <TableBodyComponent rows={rows} columns={columns.headers} />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CommonEditableTable;
