import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Scans from "./Scans";
import Tests from "./Test";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export default function ColorTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        sx={{
          "& .MuiTabs-scroller": {
            borderRadius: "6px",
            backgroundColor: "#F3F3F3",
          },
          "& .MuiButtonBase-root": {
            margin: "8px 15px",
            borderRadius: "6px",
            fontSize: "16px",
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .Mui-selected": {},
        }}
      >
        <Tab
          value={0}
          label="Test"
          sx={{
            minWidth: "49%",
            backgroundColor: value === 0 ? "#FFFFFF" : "",
            color: value === 0 ? "#121212 !important" : "#7D7D7D !important",
            fontWeight: value === 0 ? 700 : 400,
          }}
        />
        <Tab
          value={1}
          label="Scan"
          sx={{
            minWidth: "49%",
            backgroundColor: value === 1 ? "#FFFFFF" : "",
            color: value === 1 ? "#121212 !important" : "#7D7D7D !important",
            fontWeight: value === 1 ? 700 : 400,
          }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Tests />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Scans />
      </TabPanel>
    </Box>
  );
}
