import { Box, Grid, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  AutoComplete,
  AutoCompleteWithCheckBoxes,
  CancelButton,
  DatePickerWithTime,
  PageLoader,
  SaveButton,
  Select,
  TextField,
} from "../../../../../components/basic";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../../utils/ValidationUtils";
import { RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  createOperationTheatre,
  editOperationTheatreById,
  getAllDepartments,
  getAllPositions,
  getAllSurgery,
  getConsultantsList,
  getInPatientAdmissionDetailById,
  updateOperationTheatreById,
} from "../../../../../services/admissionService";
import { KebabMenuIcon } from "../../../../../assets/icons";
import WardRoomModal from "../../WardRoomModal";
import { RouteUrls } from "../../../../../constants/routes";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../../redux/slices/snackbar";
import { ipAdmissionConst } from "../../../../../constants/displayText";
import { setOperationTheatreList } from "../../../../../redux/slices/inpatients";

const AddAndEditOperationTheatre = () => {
  const initialOperationTheatreFormError = {
    surgery: "",
    doctor: "",
    operationTheatre: "",
    scheduleStartDate: "",
    scheduleEndDate: "",
    operationHours: "",
    department: "",
    position: "",
    bloodGroup: "",
    units: "",
    inHouseSurgeon: "",
    outHouseSurgeon: "",
    nurse: "",
    inHouseAnasthesian: "",
    outHouseAnasthesian: "",
    assistant: "",
    procedureType: "",
    proposedProcedure: "",
    preDiagnosis: "",
  };

  const [operationTheatreFormError, setOperationTheatreFormError] =
    useState<any>(initialOperationTheatreFormError);

  const initialData = {
    surgery: null,
    doctor: null,
    doctorInputValue: "",
    operationTheatre: "",
    scheduleStartDate: null,
    scheduleEndDate: null,
    operationHours: "",
    department: null,
    position: null,
    bloodGroup: null,
    units: "",
    surgeon: [],
    surgeonInput: "",
    nurse: [],
    nurseInput: "",
    anasthesian: [],
    anasthesianInput: "",
    assistant: [],
    assistantInput: "",
    procedureType: null,
    proposedProcedure: "",
    preDiagnosis: "",
    isOpenWardRoomModal: false,
    wardRoom: "",
    operationTheatreId: "",
    patientId: "",
    ipId: "",
  };

  const [operationTheatreFormData, setOperationTheatreFormData] = useState<
    string | Date | any
  >(initialData);

  const fieldData: any = {
    surgery: {
      label: "Surgery",
      name: "surgery", // Include the name field
      value: operationTheatreFormData.surgery,
      isError: Boolean(operationTheatreFormError.surgery),
      helperText: operationTheatreFormError.surgery,
    },
    doctor: {
      label: "Doctor",
      name: "doctor",
      value: operationTheatreFormData.doctor,
      isError: Boolean(operationTheatreFormError.doctor),
      helperText: operationTheatreFormError.doctor,
    },
    doctorInputValue: {
      name: "doctorInputValue",
      value: operationTheatreFormData.doctorInputValue,
    },
    operationTheatre: {
      label: "Operation Theatre",
      name: "operationTheatre",
      value: operationTheatreFormData.operationTheatre,
      isError: Boolean(operationTheatreFormError.operationTheatre),
      helperText: operationTheatreFormError.operationTheatre,
    },

    scheduleStartDate: {
      label: "Schedule Start Date",
      name: "scheduleStartDate",
      value: operationTheatreFormData.scheduleStartDate,
      isError: Boolean(operationTheatreFormError.scheduleStartDate),
      helperText: operationTheatreFormError.scheduleStartDate,
    },
    scheduleEndDate: {
      label: "Schedule End Date",
      name: "scheduleEndDate",
      value: operationTheatreFormData.scheduleEndDate,
      isError: Boolean(operationTheatreFormError.scheduleEndDate),
      helperText: operationTheatreFormError.scheduleEndDate,
    },
    operationHours: {
      label: "Operation Hours",
      name: "operationHours",
      value: operationTheatreFormData.operationHours,
      isError: Boolean(operationTheatreFormError.operationHours),
      helperText: operationTheatreFormError.operationHours,
    },
    department: {
      label: "Department",
      name: "department",
      value: operationTheatreFormData.department,
      isError: Boolean(operationTheatreFormError.department),
      helperText: operationTheatreFormError.department,
    },
    position: {
      label: "Position",
      name: "position",
      value: operationTheatreFormData.position,
      isError: Boolean(operationTheatreFormError.position),
      helperText: operationTheatreFormError.position,
    },
    bloodGroup: {
      label: "Blood Group",
      name: "bloodGroup",
      value: operationTheatreFormData.bloodGroup,
      isError: Boolean(operationTheatreFormError.bloodGroup),
      helperText: operationTheatreFormError.bloodGroup,
    },
    units: {
      label: "Units",
      name: "units",
      value: operationTheatreFormData.units,
      isError: Boolean(operationTheatreFormError.units),
      helperText: operationTheatreFormError.units,
    },
    surgeon: {
      label: "Surgeon",
      name: "surgeon",
      value: operationTheatreFormData.surgeon,
      isError: Boolean(operationTheatreFormError.surgeon),
      helperText: operationTheatreFormError.surgeon,
    },
    nurse: {
      label: "Nurse",
      name: "nurse",
      value: operationTheatreFormData.nurse,
      isError: Boolean(operationTheatreFormError.nurse),
      helperText: operationTheatreFormError.nurse,
    },
    anasthesian: {
      label: "Anesthesian",
      name: "anasthesian",
      value: operationTheatreFormData.anasthesian,
      isError: Boolean(operationTheatreFormError.anasthesian),
      helperText: operationTheatreFormError.anasthesian,
    },
    assistant: {
      label: "Assistant",
      name: "assistant",
      value: operationTheatreFormData.assistant,
      isError: Boolean(operationTheatreFormError.assistant),
      helperText: operationTheatreFormError.assistant,
    },
    procedureType: {
      label: "Procedure Type",
      name: "procedureType",
      value: operationTheatreFormData.procedureType,
      isError: Boolean(operationTheatreFormError.procedureType),
      helperText: operationTheatreFormError.procedureType,
    },
    proposedProcedure: {
      label: "Proposed Procedure",
      name: "proposedProcedure",
      value: operationTheatreFormData.proposedProcedure,
      isError: Boolean(operationTheatreFormError.proposedProcedure),
      helperText: operationTheatreFormError.proposedProcedure,
    },
    preDiagnosis: {
      label: "Pre-Diagnosis",
      name: "preDiagnosis",
      value: operationTheatreFormData.preDiagnosis,
      isError: Boolean(operationTheatreFormError.preDiagnosis),
      helperText: operationTheatreFormError.preDiagnosis,
    },
  };

  const [operationTheatreFieldData, setOperationTheatreFieldData] =
    useState(fieldData);

  const [inputValue, setInputValue] = useState("");
  const [isAutoCompleteLoading, setIsAutoCompleteLoading] = useState(false);
  const [addOtLoader, setAddOtLoader] = useState(false);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [isOtView, setIsOtView] = useState(false);
  const [isDisabled, setIsDisabled] = useState<any>(false);
  const [bloodGroupOptions, setBloodGroupOptions] = useState<any>([]);
  const [procedureTypesOptions, setProcedureTypesOptions] = useState<any>([]);
  const [departmentsOption, setDepartmentsOption] = useState<any>([]);
  const [doctorsOption, setDoctorsOption] = useState<any>([]);
  const [positionsOption, setPositionsOption] = useState<any>([]);
  const [surgeryOption, setSurgeryOption] = useState<any>([]);
  const [admissionDetails, setAdmissionDetails] = useState<any>([]);
  const { otId, admissionId } = useParams();
  const location = useLocation();
  const { commonVariables, appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.includes("operation-theatre/view")) {
      setIsOtView(true);
      setIsDisabled(true);
    }
  }, [location.pathname]);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "surgery":
      case "doctor":
      case "operationTheatre": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setOperationTheatreFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setOperationTheatreFormError);
        }
        break;
      }

      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateBookingFieldData = () => {
    setOperationTheatreFieldData((prevFieldData: any) => {
      return Object?.keys(prevFieldData)?.map((field: any) => {
        return {
          ...field,
          value: operationTheatreFormData[field.name],
          isError: Boolean(operationTheatreFormError[field.name]),
          helperText: operationTheatreFormError[field.name],
        };
      });
    });
  };

  useEffect(() => {
    updateBookingFieldData();
  }, [operationTheatreFormError, operationTheatreFormData]);

  const handleInputChange = useCallback((e: any) => {
    const { value, name } = e.target;
    setOperationTheatreFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
    handleValidation(e);
  }, []);

  const handleDateChange = (newValue: any, name: string) => {
    setOperationTheatreFormData((prev: any) => {
      const updatedFormData = {
        ...prev,
        [name]: newValue?.toString(),
      };

      const { scheduleEndDate, scheduleStartDate } = updatedFormData;

      if (scheduleEndDate && scheduleStartDate) {
        // Parse the dates
        const endDate = moment(scheduleEndDate, "DD/MM/YYYY hh:mm:ss A");
        const startDate = moment(scheduleStartDate, "DD/MM/YYYY hh:mm:ss A");

        // Check if scheduleEndDate is greater than scheduleStartDate
        if (endDate.isAfter(startDate)) {
          // Calculate the difference in minutes
          const durationInMinutes = endDate?.diff(startDate, "minutes");

          // Calculate hours and minutes
          const hours = Math.floor(durationInMinutes / 60);
          const minutes = durationInMinutes % 60;

          // Format the result as HH:mm
          const operationHours = `${String(hours)?.padStart(2, "0")}:${String(
            minutes
          )?.padStart(2, "0")}`;

          return {
            ...updatedFormData,
            operationHours,
          };
        } else {
          return {
            ...updatedFormData,
            operationHours: "",
          };
        }
      }
      return updatedFormData;
    });
  };

  const handleAutoCompleteWithCheckBoxChange = useCallback(
    (e: any, newValue: any, name: any) => {
      setOperationTheatreFormData((prevData: any) => ({
        ...prevData,
        [name]: newValue,
      }));
    },

    []
  );

  const handleAutoCompleteWithCheckBoxInputChange = (
    e: any,
    newValue: any,
    name: any
  ) => {
    if (newValue === "") {
      return false;
    }
    setOperationTheatreFormData((prevData: any) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    if (newValue === null) {
      return false;
    }

    setOperationTheatreFormData((prevData: any) => ({
      ...prevData,
      [name]: newValue,
    }));

    handleValidation({
      target: {
        name: name,
        value: newValue,
      },
    });
  };

  const handleAutoCompleteInputChange = useCallback(
    (e: any, newInputValue: any, name: string) => {
      if (newInputValue == "" && otId) {
        return false;
      }
      setOperationTheatreFormData((prevData: any) => ({
        ...prevData,
        doctorInputValue: newInputValue,
      }));
    },

    []
  );

  const handleSelectRoom = (value: any) => {
    setOperationTheatreFormData((prevState: any) => ({
      ...prevState,
      isOpenWardRoomModal: false,
      operationTheatre: `${value?.selectedWard} - ${value?.room_no}`,
      operationTheatreId: value?.id,
    }));

    handleValidation({
      target: {
        name: "operationTheatre",
        value: value,
      },
    });
  };

  const getCommonVariablesDetails = async () => {
    if (commonVariables) {
      setBloodGroupOptions(commonVariables?.blood_groups);
      setProcedureTypesOptions(commonVariables?.procedure_types);
    }
  };

  useEffect(() => {
    getCommonVariablesDetails();
  }, [commonVariables, appConfiguration]);

  const getAllDepartmentsList = async () => {
    try {
      getAllDepartments().then((result: any) => {
        let data = result?.data;
        const doctorsList = data?.map((uniqueData: any) => {
          return { id: uniqueData?.id, name: uniqueData?.name };
        });
        setDepartmentsOption(doctorsList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllDoctorsList = async () => {
    try {
      getConsultantsList().then((result: any) => {
        let data = result?.data;
        const doctorsList = data?.map((uniqueData: any) => {
          return { id: uniqueData?.id, name: uniqueData?.name };
        });
        setDoctorsOption(doctorsList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllPositionsList = async () => {
    try {
      getAllPositions().then((result: any) => {
        let data = result?.data?.result;
        const positionsList = data?.map((uniqueData: any) => {
          return { id: uniqueData?.id, name: uniqueData?.name };
        });
        setPositionsOption(positionsList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllSurgeryList = async () => {
    try {
      getAllSurgery().then((result: any) => {
        let data = result?.data;
        const surgeryList = data?.map((uniqueData: any) => {
          return { id: uniqueData?.id, name: uniqueData?.name };
        });
        setSurgeryOption(surgeryList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAdmissionDetails = async () => {
    if (!admissionId) {
      return false;
    }
    try {
      await getInPatientAdmissionDetailById(admissionId).then((result: any) => {
        let data = result?.data;
        setAdmissionDetails(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const createOt = async () => {
    if (
      !Boolean(operationTheatreFormData.surgery) ||
      !Boolean(operationTheatreFormData.doctor) ||
      !Boolean(operationTheatreFormData.operationTheatre)
    ) {
      validateForm();
    } else {
      try {
        setAddOtLoader(true);
        const data = {
          ip_id: admissionDetails?.id,
          patient_id: admissionDetails?.patient_id,
          surgery_id: operationTheatreFormData?.surgery,
          doctor_id: operationTheatreFormData?.doctor?.id,
          op_theater: operationTheatreFormData?.operationTheatreId,
          schedule_start_date: operationTheatreFormData?.scheduleStartDate
            ? moment(
                operationTheatreFormData?.scheduleStartDate,
                "DD/MM/YYYY hh:mm:ss A"
              ).format("YYYY-MM-DD HH:mm:ss")
            : null,
          schedule_end_date: operationTheatreFormData?.scheduleEndDate
            ? moment(
                operationTheatreFormData?.scheduleEndDate,
                "DD/MM/YYYY hh:mm:ss A"
              ).format("YYYY-MM-DD HH:mm:ss")
            : null,
          operation_hrs: operationTheatreFormData?.operationHours,
          doctor_department_id: operationTheatreFormData?.department,
          position_id: operationTheatreFormData?.position,
          blood_groups: operationTheatreFormData?.bloodGroup,
          unit: operationTheatreFormData?.units,
          procedure_type: operationTheatreFormData?.procedureType,
          proposed_procedure: operationTheatreFormData?.proposedProcedure,
          pre_diagnosis: operationTheatreFormData?.preDiagnosis,
          surgeon: [
            ...operationTheatreFormData?.surgeon?.map((surgeon: any) => ({
              type: "surgeon",
              user_id: surgeon.id,
            })),
            ...operationTheatreFormData?.nurse?.map((nurse: any) => ({
              type: "nurse",
              user_id: nurse.id,
            })),
            ...operationTheatreFormData?.anasthesian?.map(
              (anasthesian: any) => ({
                type: "anesthetics",
                user_id: anasthesian.id,
              })
            ),
            ...operationTheatreFormData?.assistant?.map((assistant: any) => ({
              type: "assistant",
              user_id: assistant.id,
            })),
          ],
        };

        await createOperationTheatre(data)?.then((result) => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ipAdmissionConst.ADDED_SUCCESSFULLY as string,
            })
          );
          dispatch(setOperationTheatreList(null));
          navigate(
            `${RouteUrls.inPatients}${RouteUrls.ipSummary}/${admissionId}?type=ot`
          );
          setAddOtLoader(false);
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        const errorMessage = error?.response?.data?.errors
          ? error.response.data.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        setAddOtLoader(false);
      }
    }
  };

  const editOt = async () => {
    if (!otId) {
      return false;
    }
    try {
      setIsPageLoader(true);
      await editOperationTheatreById(otId).then((result: any) => {
        const details = result?.data;

        const surgeons: any[] = [];
        const nurses: any[] = [];
        const anesthetists: any[] = [];
        const assistants: any[] = [];

        details?.surgeons?.forEach((user: any) => {
          switch (user?.type) {
            case "surgeon":
              surgeons.push(user?.user);
              break;
            case "nurse":
              nurses.push(user?.user);
              break;
            case "anesthetics":
              anesthetists.push(user?.user);
              break;
            case "assistant":
              assistants.push(user?.user);
              break;
            default:
              break;
          }
        });

        setOperationTheatreFormData((prevData: any) => ({
          ...prevData,
          surgery: details?.surgery_id,
          doctor: details?.doctor,
          doctorInputValue: details?.doctor?.name,
          operationTheatre: details?.room?.room_no,
          operationTheatreId: details?.room?.id,
          scheduleStartDate: details?.schedule_start_date
            ? moment(
                details?.schedule_start_date,
                "YYYY-MM-DD HH:mm:ss"
              ).format("DD/MM/YYYY hh:mm:ss A")
            : null,
          scheduleEndDate: details?.schedule_end_date
            ? moment(details?.schedule_end_date, "YYYY-MM-DD HH:mm:ss").format(
                "DD/MM/YYYY hh:mm:ss A"
              )
            : null,
          operationHours: details?.operation_hrs,
          department: details?.doctor_department_id,
          position: details?.position_id,
          bloodGroup: details?.blood_groups,
          units: details?.unit,
          surgeon: surgeons,
          nurse: nurses,
          anasthesian: anesthetists,
          assistant: assistants,
          procedureType: details?.procedure_type,
          proposedProcedure: details?.proposed_procedure,
          preDiagnosis: details?.pre_diagnosis,
          isOpenWardRoomModal: false,
          patientId: details?.patient_id,
          ipId: details?.ip_id,
        }));
        setIsPageLoader(false);
      });
    } catch (error: any) {
      console.error("An error occurred:", error);
      setIsPageLoader(false);
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
    }
  };

  const updateOt = async () => {
    if (
      !Boolean(operationTheatreFormData.surgery) ||
      !Boolean(operationTheatreFormData.doctor) ||
      !Boolean(operationTheatreFormData.operationTheatre)
    ) {
      validateForm();
    } else {
      try {
        setAddOtLoader(true);

        const data = {
          ip_id: operationTheatreFormData?.ipId,
          patient_id: operationTheatreFormData?.patientId,
          surgery_id: operationTheatreFormData?.surgery,
          doctor_id: operationTheatreFormData?.doctor?.id,
          op_theater: operationTheatreFormData?.operationTheatreId,
          schedule_start_date: operationTheatreFormData?.scheduleStartDate
            ? moment(
                operationTheatreFormData?.scheduleStartDate,
                "DD/MM/YYYY hh:mm:ss A"
              ).format("YYYY-MM-DD HH:mm:ss")
            : null,
          schedule_end_date: operationTheatreFormData?.scheduleEndDate
            ? moment(
                operationTheatreFormData?.scheduleEndDate,
                "DD/MM/YYYY hh:mm:ss A"
              ).format("YYYY-MM-DD HH:mm:ss")
            : null,
          operation_hrs: operationTheatreFormData?.operationHours,
          doctor_department_id: operationTheatreFormData?.department,
          position_id: operationTheatreFormData?.position,
          blood_groups: operationTheatreFormData?.bloodGroup,
          unit: operationTheatreFormData?.units,
          procedure_type: operationTheatreFormData?.procedureType,
          proposed_procedure: operationTheatreFormData?.proposedProcedure,
          pre_diagnosis: operationTheatreFormData?.preDiagnosis,
          surgeon: [
            ...operationTheatreFormData?.surgeon?.map((surgeon: any) => ({
              type: "surgeon",
              user_id: surgeon.id,
            })),
            ...operationTheatreFormData?.nurse?.map((nurse: any) => ({
              type: "nurse",
              user_id: nurse.id,
            })),
            ...operationTheatreFormData?.anasthesian?.map(
              (anasthesian: any) => ({
                type: "anesthetics",
                user_id: anasthesian.id,
              })
            ),
            ...operationTheatreFormData?.assistant?.map((assistant: any) => ({
              type: "assistant",
              user_id: assistant.id,
            })),
          ],
        };

        await updateOperationTheatreById(otId, data).then((result: any) => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ipAdmissionConst.UPDATED_SUCCESSFULLY as string,
            })
          );
        });
        navigate(
          `${RouteUrls.inPatients}${RouteUrls.ipSummary}/${operationTheatreFormData?.ipId}?type=ot`
        );
        setAddOtLoader(false);
        dispatch(setOperationTheatreList(null));
      } catch (error: any) {
        console.error("An error occurred:", error);
        const errorMessage = error?.response?.data?.errors
          ? error?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        setAddOtLoader(false);
      }
    }
  };

  useEffect(() => {
    getAllDepartmentsList();
    getAllDoctorsList();
    getAllPositionsList();
    getAllSurgeryList();
    getAdmissionDetails();
    editOt();
  }, []);
  return isPageLoader ? (
    <PageLoader />
  ) : (
    <Grid sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {/* <Typography fontSize={"20px"} fontWeight={600} sx={{ ml: "10px" }}>
        {otId && isOtView ? "View" : otId ? "Edit" : "Add New"}
      </Typography> */}

      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F6FAFF",
            width: "100%",
            height: "37px",
            display: "flex",
            alignItems: "center",
            borderRadius: "6px",
          }}
        >
          <Typography variant="h5" color="primary.main" sx={{ ml: 2 }}>
            Surgery Details
          </Typography>
        </Box>
        <Grid sx={{ ml: "10px", width: { xs: "100%", xl: "1200px" } }}>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: {
                xs: 2,
                lg: 5,
              },
            }}
          >
            <Select
              value={fieldData?.surgery?.value}
              placeholder="Select Surgery"
              onChange={handleInputChange}
              options={surgeryOption}
              name={fieldData?.surgery?.name}
              label={
                <span>
                  {fieldData?.surgery?.label}
                  <span style={{ color: "#F43F5E" }}> *</span>
                </span>
              }
              helperText={fieldData?.surgery?.helperText}
              sx={{
                width: {
                  xs: "360px",
                },
                height: "45px",
              }}
              disabled={isDisabled}
            />

            <Grid
              sx={{
                width: {
                  xs: "360px",
                },
              }}
            >
              <AutoComplete
                label={
                  <span>
                    {fieldData?.doctor?.label}
                    <span style={{ color: "#F43F5E" }}> *</span>
                  </span>
                }
                value={fieldData.doctor.value}
                inputValue={fieldData.doctorInputValue.value}
                placeholder={"Select Doctor"}
                name={fieldData.doctor.name}
                onChange={(e: any, newValue: any) =>
                  handleAutoCompleteChange(e, newValue, fieldData.doctor.name)
                }
                onInputChange={(e: any, newValue: any) =>
                  handleAutoCompleteInputChange(
                    e,
                    newValue,
                    fieldData.doctorInputValue.name
                  )
                }
                options={doctorsOption}
                helperText={fieldData.doctor.helperText}
                sx={{
                  width: {
                    xs: "360px",
                  },

                  "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                    height: "46px",
                    padding: "5px 8px",
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    borderRadius: "8px",
                  },
                }}
                disabled={isDisabled}
              />
            </Grid>

            <TextField
              value={fieldData?.operationTheatre?.value}
              fullWidth
              placeholder="Select Operation Theatre"
              label={
                <span>
                  {fieldData?.operationTheatre?.label}
                  <span style={{ color: "#F43F5E" }}> *</span>
                </span>
              }
              name="operationTheatre"
              sx={{
                width: "360px",
                "& .MuiOutlinedInput-root": {
                  height: "45px",
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                },
              }}
              endPlaceholderIcon={
                !isDisabled ? (
                  <Box sx={{ rotate: "90deg", "& path": { fill: "#207DFF" } }}>
                    <KebabMenuIcon
                      width="20px"
                      height="20px"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOperationTheatreFormData((prevState: any) => ({
                          ...prevState,
                          isOpenWardRoomModal: true,
                        }));
                      }}
                    />
                  </Box>
                ) : (
                  <></>
                )
              }
              helperText={fieldData?.operationTheatre?.helperText}
              disabled={isDisabled}
            />
            <Grid
              sx={{
                "& .MuiFormControl-root": {
                  position: "static",
                },
              }}
            >
              <DatePickerWithTime
                label={fieldData?.scheduleStartDate?.label}
                style={{
                  height: "37px",
                  borderRadius: "none",
                  width: "340px",
                }}
                value={fieldData?.scheduleStartDate?.value}
                name={fieldData?.scheduleStartDate?.name}
                format="DD/MM/YYYY hh:mm:ss A"
                placeholder="Select Schedule Start Date"
                onChange={(newValue: any) =>
                  handleDateChange(newValue, "scheduleStartDate")
                }
                helperText={fieldData?.scheduleStartDate.helperText}
                isDisabled={isDisabled}
                hideSeconds={false}
              />
            </Grid>
            <Grid
              sx={{
                "& .MuiFormControl-root": {
                  position: "static",
                },
              }}
            >
              <DatePickerWithTime
                label={fieldData?.scheduleEndDate?.label}
                style={{
                  height: "37px",
                  borderRadius: "none",
                  width: "340px",
                }}
                value={fieldData?.scheduleEndDate?.value}
                name={fieldData?.scheduleEndDate?.name}
                format="DD/MM/YYYY hh:mm:ss A"
                placeholder="Select Schedule Start Date"
                onChange={(newValue: any) =>
                  handleDateChange(newValue, "scheduleEndDate")
                }
                helperText={fieldData?.scheduleStartDate.helperText}
                isDisabled={isDisabled}
                hideSeconds={false}
              />
            </Grid>
            <TextField
              placeholder="Enter Operation Hours"
              label={fieldData?.operationHours?.label}
              sx={{
                width: "360px",
                "& .MuiOutlinedInput-root": {
                  height: "45px",
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                },
              }}
              value={fieldData?.operationHours?.value}
              onChange={handleInputChange}
              name={fieldData?.operationHours?.name}
              helperText={fieldData?.operationHours?.helperText}
              disabled={isDisabled}
            />
            <Select
              value={fieldData?.department?.value}
              placeholder="Select Department"
              onChange={handleInputChange}
              options={departmentsOption}
              name={fieldData?.department?.name}
              label={fieldData?.department?.label}
              helperText={fieldData?.department?.helperText}
              sx={{
                width: {
                  xs: "360px",
                },

                height: "45px",
              }}
              disabled={isDisabled}
            />
            <Select
              value={fieldData?.position?.value}
              placeholder="Select Position"
              onChange={handleInputChange}
              options={positionsOption}
              name={fieldData?.position?.name}
              label={fieldData?.position?.label}
              helperText={fieldData?.position.helperText}
              sx={{
                width: {
                  xs: "360px",
                },

                height: "45px",
              }}
              disabled={isDisabled}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Select
                value={fieldData?.bloodGroup?.value}
                placeholder="Select Blood Group"
                onChange={handleInputChange}
                options={bloodGroupOptions}
                name={fieldData?.bloodGroup?.name}
                label={fieldData?.bloodGroup?.label}
                helperText={fieldData?.bloodGroup?.helperText}
                sx={{
                  width: {
                    xs: "160px",
                  },
                  mr: "40px",
                  height: "45px",
                }}
                disabled={isDisabled}
              />
              <TextField
                placeholder="Enter Units"
                label={fieldData?.units?.label}
                sx={{
                  width: "160px",
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    borderRadius: "8px",
                    paddingLeft: "8px",
                    boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  },
                }}
                inputAdornment="ml"
                value={fieldData?.units?.value}
                onChange={handleInputChange}
                name={fieldData?.units?.name}
                helperText={fieldData?.units?.helperText}
                disabled={isDisabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            backgroundColor: "#F6FAFF",
            width: "100%",
            height: "37px",
            display: "flex",
            alignItems: "center",
            borderRadius: "6px",
          }}
        >
          <Typography variant="h5" color="primary.main" sx={{ ml: 2 }}>
            Surgeon
          </Typography>
        </Box>
        <Grid sx={{ ml: "10px", width: { xs: "100%", xl: "1200px" } }}>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: {
                xs: 2,
                lg: 5,
              },
            }}
          >
            <AutoCompleteWithCheckBoxes
              name={fieldData.surgeon.name}
              label={fieldData.surgeon.label}
              value={fieldData.surgeon.value}
              onChange={(event: any, newValue: any, name: any) =>
                handleAutoCompleteWithCheckBoxChange(event, newValue, "surgeon")
              }
              inputValue={inputValue}
              onInputChange={(e: any, newInputValue: any, name: any) => {
                handleAutoCompleteWithCheckBoxInputChange(
                  e,
                  newInputValue,
                  "surgeonInput"
                );
              }}
              placeholder={"Select Surgeon"}
              options={doctorsOption}
              defaultValue={
                fieldData.surgeon.value !== null
                  ? fieldData.surgeon.value
                  : null
              }
              loading={isAutoCompleteLoading}
              sx={{
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  padding: "5px 0px",
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  minHeight: "48px",
                  maxHeight: "auto",
                  overflowX: "hidden",
                },
                width: "360px",
              }}
              helperText={fieldData?.surgeon?.helperText}
              disabled={isDisabled}
            />

            <AutoCompleteWithCheckBoxes
              name={fieldData?.nurse?.name}
              label={fieldData?.nurse?.label}
              value={fieldData?.nurse?.value}
              onChange={(event: any, newValue: any, name: any) =>
                handleAutoCompleteWithCheckBoxChange(event, newValue, "nurse")
              }
              inputValue={inputValue}
              onInputChange={(e: any, newInputValue: any, name: any) => {
                handleAutoCompleteWithCheckBoxInputChange(
                  e,
                  newInputValue,
                  "nurseInput"
                );
              }}
              placeholder={"Select Nurse"}
              options={doctorsOption}
              defaultValue={
                fieldData?.nurse?.value !== null
                  ? fieldData?.nurse?.value
                  : null
              }
              loading={isAutoCompleteLoading}
              sx={{
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  padding: "5px 0px",
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  minHeight: "48px",
                  maxHeight: "auto",
                  overflowX: "hidden",
                },
                width: "360px",
              }}
              helperText={fieldData?.nurse?.helperText}
              disabled={isDisabled}
            />

            <AutoCompleteWithCheckBoxes
              name={fieldData.anasthesian.name}
              label={"Anasthesian"}
              value={fieldData.anasthesian.value}
              onChange={(event: any, newValue: any, name: any) =>
                handleAutoCompleteWithCheckBoxChange(
                  event,
                  newValue,
                  "anasthesian"
                )
              }
              inputValue={inputValue}
              onInputChange={(e: any, newInputValue: any, name: any) => {
                handleAutoCompleteWithCheckBoxInputChange(
                  e,
                  newInputValue,
                  "anasthesianInput"
                );
              }}
              placeholder={"Select Anasthesian"}
              options={doctorsOption}
              defaultValue={
                fieldData.anasthesian.value !== null
                  ? fieldData.anasthesian.value
                  : null
              }
              loading={isAutoCompleteLoading}
              sx={{
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  padding: "5px 0px",
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  minHeight: "48px",
                  maxHeight: "auto",
                  overflowX: "hidden",
                },
                width: "360px",
              }}
              helperText={fieldData?.anasthesian?.helperText}
              disabled={isDisabled}
            />
            <AutoCompleteWithCheckBoxes
              name={fieldData?.assistant?.name}
              label={fieldData?.assistant?.label}
              value={fieldData.assistant?.value}
              onChange={(event: any, newValue: any, name: any) =>
                handleAutoCompleteWithCheckBoxChange(
                  event,
                  newValue,
                  "assistant"
                )
              }
              inputValue={inputValue}
              onInputChange={(e: any, newInputValue: any, name: any) => {
                handleAutoCompleteWithCheckBoxInputChange(
                  e,
                  newInputValue,
                  "assistantInput"
                );
              }}
              placeholder={"Select Assistant"}
              options={doctorsOption}
              defaultValue={
                fieldData.assistant.value !== null
                  ? fieldData.assistant.value
                  : null
              }
              loading={isAutoCompleteLoading}
              sx={{
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  padding: "5px 0px",
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  minHeight: "48px",
                  maxHeight: "auto",
                  overflowX: "hidden",
                },
                width: "360px",
              }}
              helperText={fieldData?.assistant?.helperText}
              disabled={isDisabled}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            backgroundColor: "#F6FAFF",
            width: "100%",
            height: "37px",
            display: "flex",
            alignItems: "center",
            borderRadius: "6px",
          }}
        >
          <Typography variant="h5" color="primary.main" sx={{ ml: 2 }}>
            Procedures
          </Typography>
        </Box>
        <Grid sx={{ ml: "10px", width: { xs: "100%", xl: "1200px" } }}>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: {
                xs: 2,
                lg: 5,
              },
              mb: 1,
            }}
          >
            <Select
              value={fieldData?.procedureType?.value}
              placeholder="Select Procedure Type"
              onChange={handleInputChange}
              options={procedureTypesOptions}
              name={fieldData?.procedureType?.name}
              label={fieldData?.procedureType?.label}
              helperText={fieldData?.procedureType?.helperText}
              sx={{
                width: {
                  xs: "360px",
                },
                height: "45px",
              }}
              disabled={isDisabled}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="p" sx={{ color: "#8A8A8A" }}>
                Proposed Procedure
              </Typography>
              <textarea
                style={{
                  width: "340px",
                  height: "120px",
                  borderRadius: "8px",
                  padding: "10px",
                  outline: "none",
                  border: "1px solid #EFEFEF",
                  marginTop: "5px",
                  fontFamily: "inter",
                  fontSize: "14px",
                }}
                placeholder="Enter Proposed Procedure"
                name={fieldData?.proposedProcedure?.name}
                value={fieldData?.proposedProcedure?.value}
                onChange={handleInputChange}
                spellCheck="false"
                disabled={isDisabled}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="p" sx={{ color: "#8A8A8A" }}>
                Pre Diagnosis
              </Typography>
              <textarea
                style={{
                  width: "340px",
                  height: "120px",
                  borderRadius: "8px",
                  padding: "10px",
                  outline: "none",
                  border: "1px solid #EFEFEF",
                  marginTop: "5px",
                  fontFamily: "inter",
                  fontSize: "14px",
                }}
                placeholder="Enter Pre Diagnosis"
                name={fieldData?.preDiagnosis?.name}
                value={fieldData?.preDiagnosis?.value}
                onChange={handleInputChange}
                spellCheck="false"
                disabled={isDisabled}
              />
            </Box>
            {!isDisabled && (
              <Grid item sx={{ display: "flex", width: "100%", gap: 3 }}>
                <SaveButton
                  sx={{ width: "100px", height: "42px" }}
                  handleClick={!otId ? createOt : updateOt}
                  loading={addOtLoader}
                />
                <CancelButton
                  sx={{ width: "100px", height: "42px" }}
                  handleClick={() =>
                    navigate(
                      `${RouteUrls.inPatients}${RouteUrls.ipSummary}/${admissionId}?type=ot`
                    )
                  }
                />
              </Grid>
            )}

            <WardRoomModal
              isOpen={operationTheatreFormData?.isOpenWardRoomModal}
              onClose={() =>
                setOperationTheatreFormData((prevState: any) => ({
                  ...prevState,
                  isOpenWardRoomModal: false,
                }))
              }
              onSelectRoom={handleSelectRoom}
              isSelectAllRoom={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddAndEditOperationTheatre;
