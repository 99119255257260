import ApiUtils from "../utils/ApiUtils";
import { paymentsEndPoints } from "../constants/apiEndPoints";

const {
  paymentsUrl,
  payeeUrl,
  patientPaymentBillsUrl,
  advanceAndExcessUrl,
  cardUrl,
  paymentAdjustmentUrl,
  advanceUrl,
  getAllCommonVariables,
  commonAppConfiguration,
} = paymentsEndPoints;

const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);

  let queryString = "?";
  keys.map((key) => {
    if (data[key] && !Array.isArray(data[key])) {
      queryString += `${key}=${data[key]}&`;
    }
    if (Array.isArray(data[key])) {
      data[key].map(
        (ele: any, index: number) => (queryString += `${key}[${index}]=${ele}&`)
      );
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};

const getAllCommons = async () => {
  return await ApiUtils.getWithToken(`${getAllCommonVariables}`);
};

const getCommonAppConfigurations = async () => {
  try {
    return await ApiUtils.getWithToken(commonAppConfiguration);
  } catch (error) {
    console.log("Error occurs while getting the  app configuration:", error);
    throw error;
  }
};

const getAllPatientsList = async (params: {
  page: number | string | null;
  search: string;
  limit: number;
}) => {
  const apiUrl = setQueryParams(params, paymentsEndPoints.getAllPatientsName);
  return await await ApiUtils.getWithToken(apiUrl);
};
const getBillList = async (page: number | string | null) => {
  return await await ApiUtils.getWithToken(
    `${paymentsEndPoints.getAllPatientsName}`
  );
};

const getAllPayments = async (params: {
  page: number;
  limit: number;
  column: string;
  order: string;
  filter_from_date: Date;
  filter_to_date: Date;
  filter_patient: string | number;
  term_filter: string | number;
  search: string;
}) => {
  try {
    const apiUrl = setQueryParams(params, paymentsUrl);
    return await ApiUtils.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error occurs while getting payment list:", error);
    throw error;
  }
};

const getPayeeOptions = async (params: { active: number }) => {
  try {
    const apiUrl = setQueryParams(params, payeeUrl);
    return await ApiUtils.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error occurs while getting payment list:", error);
    throw error;
  }
};

const getCardOptionDetails = async () => {
  try {
    return await ApiUtils.getWithToken(cardUrl);
    // return { data: { id: 1, type: "Card", convenience_fee_percentage: "3" } };
  } catch (error) {
    console.log("Error occurs while getting payment list:", error);
    throw error;
  }
};

const getPatientsBills = async (params: {
  patient_id: string | number;
  payee_id: string | number;
  bill_id: string | number;
  type: string;
}) => {
  try {
    const apiUrl = setQueryParams(params, patientPaymentBillsUrl);
    return await ApiUtils.getWithToken(apiUrl);
    // return {
    //   data: [
    //     {
    //       department: "Laboratory",
    //       patient_name: "New1",
    //       patient_bill_no: "LAB0013",
    //       payee_name: "Self",
    //       net_total_amt: "1830.00",
    //       balance: "1830.00",
    //       patient_id: 27209,
    //       payee_id: 1,
    //       bill_type: "patient_bill",
    //       bill_id: 3,
    //       bill_date: "2024-06-24 11:10:58",
    //       bill_no: "LAB0013",
    //     },
    //     {
    //       department: "Laboratory",
    //       patient_name: "New1",
    //       patient_bill_no: "LAB0015",
    //       payee_name: "Self",
    //       net_total_amt: "2590.00",
    //       balance: "2590.00",
    //       patient_id: 27209,
    //       payee_id: 1,
    //       bill_type: "patient_bill",
    //       bill_id: 5,
    //       bill_date: "2024-06-25 13:08:24",
    //       bill_no: "LAB0015",
    //     },
    //   ],
    // };
  } catch (error) {
    console.log("Error occurs while getting payment list:", error);
    throw error;
  }
};

const getAdvanceAndExcess = async (data: {
  patient_id: string | number;
  payee_id?: string | number;
  type?: string;
}) => {
  try {
    const apiUrl = setQueryParams(data, advanceAndExcessUrl);
    return await ApiUtils.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error occurs while getting payment list:", error);
    throw error;
  }
};

const createPayments = async (data: any) => {
  try {
    return await ApiUtils.postWithToken(paymentsUrl, data);
  } catch (error) {
    console.log("Error occurs while creating payment:", error);
    throw error;
  }
};

const updatePayments = async (id: number | string, data: any) => {
  try {
    return await ApiUtils.putWithToken(`${paymentsUrl}/${id}`, data);
  } catch (error) {
    console.log("Error occurs while creating payment:", error);
    throw error;
  }
};
const deletePaymentById = async (
  id: number | number | any,
  data?: { type: string; sub_payment_id: number | string }
) => {
  try {
    return await ApiUtils.deleteWithToken(`${paymentsUrl}/${id}`, data);
  } catch (error) {
    console.log("Error occurs while creating payment:", error);
    throw error;
  }
};

const getPaymentById = async (id: number | string | any) => {
  try {
    return await ApiUtils.getWithToken(`${paymentsUrl}/${id}`);
  } catch (error) {
    console.log("Error occurs while creating payment:", error);
    throw error;
  }
};

const cancelPaymentBillById = async (
  id: number | string | any,
  data?: { type: string; sub_payment_id: number | string }
) => {
  try {
    return await ApiUtils.putWithToken(`${paymentsUrl}/${id}/cancel`, data);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};

const returnPaymentBillById = async (
  id: any,
  data?: { type: string; sub_payment_id: number | string }
) => {
  try {
    return await ApiUtils.putWithToken(`${paymentsUrl}/${id}/return`, data);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};

const createAdvance = async (data?: {
  patient_id: number | string;
  payee_id: number | string;
  cash_received: number | string;
  date_created: any;
  payment_type: string;
  convenience_fee?: number;
  pay_term_id?: null | string;
  urn_number?: null | string;
}) => {
  try {
    return await ApiUtils.postWithToken(advanceUrl, data);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};

const updateAdvance = async (
  id: number | string,
  data?: {
    patient_id: number | string;
    payee_id: number | string;
    cash_received: number | string;
    date_created: any;
    payment_type: string;
    convenience_fee?: number;
    pay_term_id?: null | string;
    urn_number?: null | string;
  }
) => {
  try {
    return await ApiUtils.putWithToken(`${advanceUrl}/${id}`, data);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};

// adjustment services

const getAllAdjustments = async (params: {
  page: number;
  limit: number;
  column: number;
  order: number;
  search: string;
}) => {
  try {
    const apiUrl = setQueryParams(params, paymentAdjustmentUrl);
    return await ApiUtils.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};

const createAdjustment = async (data: {
  patient_id: string | number;
  adjustment_type: string | number;
  payment_type: string | number;
  balance: number;
  adjustment: string | number;
}) => {
  try {
    return await ApiUtils.postWithToken(paymentAdjustmentUrl, data);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};
const updateAdjustment = async (
  id: number | string,
  data: {
    patient_id: string | number;
    adjustment_type: string | number;
    payment_type: string | number;
    balance: number;
    adjustment: string | number;
  }
) => {
  try {
    return await ApiUtils.putWithToken(`${paymentAdjustmentUrl}/${id}`, data);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};

const getAdjustmentById = async (id: number | string) => {
  try {
    return await ApiUtils.getWithToken(`${paymentAdjustmentUrl}/${id}`);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};
const deleteAdjustmentById = async (id: number | string) => {
  try {
    return await ApiUtils.deleteWithToken(`${paymentAdjustmentUrl}/${id}`);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};

export {
  getAllPatientsList,
  getBillList,
  getAllPayments,
  getPayeeOptions,
  getCardOptionDetails,
  getPatientsBills,
  getAdvanceAndExcess,
  createPayments,
  updatePayments,
  getPaymentById,
  deletePaymentById,
  cancelPaymentBillById,
  getAllAdjustments,
  createAdvance,
  updateAdvance,
  getAllCommons,
  getCommonAppConfigurations,
  createAdjustment,
  getAdjustmentById,
  updateAdjustment,
  deleteAdjustmentById,
  returnPaymentBillById,
};
