import store from "../redux/store";

export const requiredValidator = (
  value: string | number | null | undefined | boolean,
  label?: string
): string | boolean => {
  if (value === undefined || value === null) {
    return `*${label ?? "Field"} is required`;
  }

  if (value === false) {
    return `*${label ?? "Field"} cannot be inactive`;
  }

  if (typeof value === "string" && value.trim() === "") {
    return `*${label ?? "Field"} is required`;
  }

  return false;
};

export const emailValidator = (
  value: string,
  key: string
): string | boolean => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const requiredError = requiredValidator(value, key);

  if (requiredError) {
    return requiredError;
  }

  if (!emailRegex.test(value)) {
    return "*Invalid Email Id";
  }

  return false; // No error, validation successful
};

export const mobileNumberValidator = (
  value: string,
  key: string
): string | boolean => {
  const requiredError = requiredValidator(value, key);

  if (requiredError) {
    return requiredError;
  }

  const mobileNumberRegex = /^(?:\+\d{1,3})?[1-9]\d{9,9}$/i;

  if (value && !/^[0-9]*$/i.test(value)) {
    return "*Only numbers are allowed";
  }

  if (value && !mobileNumberRegex.test(value)) {
    return "*Invalid mobile number";
  }

  return false; // No error, validation successful
};

export const ageAndDateValidator = (
  value: string | number | null | undefined,
  key: string
): string | false | any => {
  const isAgeValid = (age: number): boolean => age >= 0 && age <= 120;

  const isDateValid = (dateString: string): boolean => {
    // You can customize the date validation logic as needed
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  const isValueNumeric = typeof value === "number" || !isNaN(Number(value));
  const isValueString = typeof value === "string";

  if (requiredValidator(value, key)) {
    return requiredValidator(value, key);
  }

  if (isValueNumeric && isAgeValid(Number(value))) {
    return false; // No error, age validation successful
  }

  if (isValueString && isDateValid(String(value))) {
    return false; // No error, date validation successful
  }

  return `Invalid ${isValueNumeric ? "age" : ""}`;
};

export const dateValidator = (
  value: string,
  key: string
): string | undefined | any => {
  const requiredError = requiredValidator(value, key);

  if (requiredError) {
    return requiredError;
  }

  const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

  if (!dateRegex.test(value)) {
    return "Invalid date format. Please use DD/MM/YYYY";
  }

  const parts = value.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);

  // Validate day, month, and year as needed
  if (day < 1 || day > 31 || month < 1 || month > 12 || year < 1900) {
    return "Invalid date values";
  }

  return false; // No error, validation successful
};

export const updateFormDataWithHelperText = (
  fieldName: string,
  helperText: string | boolean,
  setFormError: any
) => {
  setFormError((prevFormError: any) => ({
    ...prevFormError,
    [fieldName]: helperText,
  }));
};

export const handleExpDateBackgroundColor = (diffDate: number) => {
  const { appConfiguration } = store.getState().appConfiguration;
  let color;
  if (diffDate < appConfiguration?.product_expiry_alert_1?.day) {
    color = appConfiguration?.product_expiry_alert_1?.colour;
  } else if (diffDate < appConfiguration?.product_expiry_alert_2?.day) {
    color = appConfiguration?.product_expiry_alert_2?.colour;
  } else if (diffDate < appConfiguration?.product_expiry_alert_3?.day) {
    color = appConfiguration?.product_expiry_alert_3?.colour;
  } else if (diffDate < appConfiguration?.product_expiry_alert_4?.day) {
    color = appConfiguration?.product_expiry_alert_4?.colour;
  } else {
    color = "#FFFFFF";
  }
  return color;
};

export const gstNoValidator = (value: any, key: string): string | boolean => {
  const requiredError = requiredValidator(value, key);

  if (value && value.length !== 15) {
    return "*GST No must be exactly 15 characters";
  }

  return false; // No error, validation successful
};

export const discountValidator = (value: any): string | boolean => {
  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return "*Invalid number"; // Return an error if the value is not a number
  }

  if (numberValue > 100) {
    return "*Discount cannot be more than 100";
  }

  return false; // No error, validation successful
};
