/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useCallback } from "react";
import {
  Typography,
  Box,
  debounce,
  MenuItem,
  Menu,
  Tooltip,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import {
  DataTable,
  ConfirmationDialog,
  DialogWrapper,
} from "../../../components/shared";
import { KebabMenuIcon, BillInfoIcon } from "../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Sort, TextField, DatePicker, Select } from "../../../components/basic";
import { RootState } from "../../../redux/store";
import {
  getAllPatientsList,
  getAllPatientRoomData,
  getIpStatus,
  deleteIpAdmissionById,
} from "../../../services/admissionService";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../components/shared/customFilter";
import { InfiniteScroller } from "../../../components/basic";
import { dateFormat } from "../../../utils/DateTimeFormatUtils";
import { RouteUrls } from "../../../constants/routes";
import { commonDateFormatter } from "../../../utils/DateTimeFormatUtils";
import PermissionUtils from "../../../utils/PermissionUtils";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../redux/slices/snackbar";
import { ipAdmissionConst } from "../../../constants/displayText";
import { Link } from "react-router-dom";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../utils/ValidationUtils";
import {
  setCurrentPage,
  setFilterDataValues,
  setHandleClearSearch,
  setSortField,
} from "../../../redux/slices/pagination";
import moment from "moment";
import { getPrintUrl } from "../../../utils/GeneralUtils";

type GridRowData = Record<string, unknown>;

const CommonGrid = (props: {
  label: string;
  value: string | number;
  isDark?: boolean;
}) => {
  const { label, value, isDark } = props;

  return (
    <Grid container mb={1}>
      <Grid item xl={5.5}>
        <Typography fontSize="10px" fontWeight={isDark ? 800 : 500}>
          {label}
        </Typography>
      </Grid>
      <Grid item xl={1}>
        :
      </Grid>
      <Grid item xl={5.5} display={"flex"} justifyContent={"flex-end"}>
        <Typography fontSize="10px" fontWeight={isDark ? 800 : 500}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BillInfo = () => {
  return (
    <Box sx={{ padding: "10px" }}>
      <CommonGrid label="Total" value={15000.0} />
      <CommonGrid label="Tax" value={250.0} />
      <CommonGrid label="Discount" value={200.0} />
      <CommonGrid label="Advance" value={5000.0} />
      <CommonGrid label="Amount Payable" value={10050.0} isDark />
    </Box>
  );
};

const style = {
  textOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

export default function AdmissionList() {
  const { patientsUrl, historyUrl } = RouteUrls;

  const { DELETED_SUCCESSFULLY } = ipAdmissionConst;
  const { can } = PermissionUtils();
  const { authUser }: any = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const printOptions = [
    { id: 1, name: "Ip" },
    { id: 2, name: "Date" },
  ];

  // dialog variables

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isConfirmationDialogButtonLoading,
    setIsConfirmationDialogButtonLoading,
  ] = useState(false);
  const [isDialogWrapperButtonLoading, setIsDialogWrapperButtonLoading] =
    useState(false);
  const [isDialogWrapperOpen, setIsDialogWrapperOpen] =
    useState<boolean>(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);

  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);

  // data table variables

  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const initialFilterData = {
    patient_id: "",
    ip_no: "",
    status: 1,
    from_date: new Date(),
    to_date: new Date(),
    search: "",
  };
  const patientsPagination: any = useRef(1);
  const searchPatientsPagination = useRef({ search: "", page: 1 });
  const [patientsList, setPatientsList] = useState<any>([]);
  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.ipAdmissionsList,
      filterData: state?.pagination?.ipAdmissionsList?.filterData,
      count: state?.pagination?.ipAdmissionsList?.count,
      sortField: state?.pagination?.ipAdmissionsList?.sortedField,
    })
  );
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [patientRoomList, setPatientRoomList] = useState<any>();
  const [statusOptions, setStatusOptions] = useState<any>([]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);

  const initialFormError = {
    printType: "",
  };

  const [formError, setFormError] = useState<any>(initialFormError);

  const initialFormData = {
    printType: null,
  };

  const [formData, setFormData] = useState<any>(initialFormData);

  const fieldData: any = {
    printType: {
      label: "Choose Print",
      name: "printType",
      placeholder: "Select",
      value: formData.printType,
      isError: formError.printType === "" ? false : true,
      helperText: formError.printType,
    },
  };

  const [formFieldData, setFormFieldData] = useState(fieldData);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;
    switch (name) {
      case "printType": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateFormFieldData = () => {
    setFormFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: formData[field.name],
          helperText: formError[field.name],
          isError: formError[field.name] === "" ? false : true,
        };
      });
    });
  };

  // api call functions to get select options

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getAllPatientsList({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData?.id,
              label: `${uniqueData?.uhid} - ${uniqueData?.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  const getAllPatients = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions?.length / 10)
            ? patientsPagination?.current
            : Math?.ceil(prevOptions?.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }

          const currentValue = patientsPagination?.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const getAllIpStatus = async () => {
    try {
      await getIpStatus().then((result: any) => {
        let data = result?.data;

        setStatusOptions([{ id: 0, name: "All" }, ...data]);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getAllPatients();
    getAllIpStatus();
    if (filterData.patient_id) {
      setPatientsList([filterData.patient_id]);
    }
  }, []);

  let patientRoomList1: any;

  const debouncedGetAllAdmissions = useCallback(
    debounce((data: any) => {
      setIsDataTableLoading(true);
      getAllPatientRoomData(data)
        .then((res: any) => {
          if (res?.data) {
            patientRoomList1 = res.data?.result as any;
            setPatientRoomList(patientRoomList1);
            setPageCount(res?.data?.total);
            setIsDataTableLoading(false);
          }
        })
        .catch((err) => {
          setIsDataTableLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      limit: pageInfo.pageSize,
      column: sortField?.field,
      order: sortField?.order,
      from_date: filterData.from_date
        ? commonDateFormatter(filterData.from_date, "YYYY-MM-DD")
        : "",
      to_date: filterData.to_date
        ? commonDateFormatter(filterData.to_date, "YYYY-MM-DD")
        : "",
      patient_id: filterData.patient_id?.value,
      ip_no: filterData.ip_no,
      search: filterData.search,
      is_discharge: filterData.status,
    };
    debouncedGetAllAdmissions(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortField?.order,
    sortField?.field,
    filterData.from_date,
    filterData.to_date,
    filterData.ip_no,
    filterData.patient_id,
    filterData.search,
    filterData.status,
  ]);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "ipAdmissionsList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "ipAdmissionsList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const TableActions = ({ row }: any) => {
    const styles = {
      typographyStyle: {
        color: "#232323",
        fontSize: "13px",
        fontWeight: 400,
        lineHeight: "10px",
        textAlign: "center",
      },
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [printAnchorEl, setPrintAnchorEl] = useState<null | HTMLElement>(
      null
    );
    const isAnchorElOpen = Boolean(anchorEl);
    const isPrintOpen = Boolean(printAnchorEl);

    const handleClose = () => {
      setAnchorEl(null);
      setPrintAnchorEl(null);
    };

    return (
      <Box>
        <KebabMenuIcon
          style={{
            width: "15px",
            height: "15px",
            cursor: "pointer",
            padding: "0px",
            paddingTop: "0px",
            paddingBottom: "0px",
          }}
          aria-controls={isAnchorElOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isAnchorElOpen ? "true" : undefined}
          onClick={(event: any) => setAnchorEl(event.currentTarget)}
        />
        <Menu
          anchorEl={anchorEl}
          open={isAnchorElOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ p: "0px", paddingTop: "0px", paddingBottom: "0px" }}
        >
          {can("ip_view") ? (
            <Link
              to={`${RouteUrls.inPatients}${RouteUrls.ipSummary}/${row?.id}`}
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              <MenuItem>
                <Typography
                  style={{
                    color: "#232323",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "10px",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  View IP Details
                </Typography>
              </MenuItem>
            </Link>
          ) : (
            <></>
          )}
          <Link
            to={`${RouteUrls.paymentUrl}${RouteUrls.createUrl}?patient=${row?.patient_id}&patient_name=${row?.uhid} - ${row.patient_name}&is_advance=true`}
            style={{ textDecoration: "none", cursor: "pointer" }}
          >
            <MenuItem sx={{ gap: 2 }}>
              <Typography sx={{ ...styles.typographyStyle }}>
                Advance Amount
              </Typography>
            </MenuItem>
          </Link>
          {can("ip_delete") ? (
            <MenuItem
              onClick={() => {
                setIsConfirmationDialogOpen(true);
                setIdToBeDeleted(row?.id);
              }}
              sx={{ gap: 2 }}
            >
              <Typography sx={{ ...styles.typographyStyle, color: "red" }}>
                Delete
              </Typography>
            </MenuItem>
          ) : (
            <></>
          )}
          <MenuItem
            onClick={(event) => {
              const pdfUrl = getPrintUrl(`inpatients/print?ip_id=${row?.id}`);
              const queryParams = new URLSearchParams({
                url: pdfUrl,
              }).toString();
              window.open(`${RouteUrls?.printUrl}?${queryParams}`, "_blank");
            }}
            sx={{ gap: 2 }}
          >
            <Typography sx={{ ...styles.typographyStyle }}>
              Consolidated Print
            </Typography>
          </MenuItem>
          {/* <MenuItem
            onClick={(event) => {
              setPrintAnchorEl(event.currentTarget);
            }}
            sx={{ gap: 2 }}
          >
            <Typography sx={{ ...styles.typographyStyle }}>Print</Typography>
          </MenuItem> */}
        </Menu>
        <Menu
          anchorEl={printAnchorEl}
          open={isPrintOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{ p: "0px", paddingTop: "0px", paddingBottom: "0px" }}
        >
          <Link
            to={``}
            style={{
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <MenuItem
              sx={{ gap: 2 }}
              onClick={() => setIsDialogWrapperOpen(true)}
            >
              <Typography sx={{ ...styles.typographyStyle }}>
                Consolidate
              </Typography>
            </MenuItem>
            <MenuItem sx={{ gap: 2 }}>
              <Typography sx={{ ...styles.typographyStyle }}>
                Pharmacy Bills
              </Typography>
            </MenuItem>
            <MenuItem sx={{ gap: 2 }}>
              <Typography sx={{ ...styles.typographyStyle }}>
                Patient Bills
              </Typography>
            </MenuItem>
          </Link>
        </Menu>
      </Box>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "ip_no",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("ip_no")}
        >
          <Typography variant="h5" fontSize={14}>
            IP No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "ip_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "ip_no" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.ip_no}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "Patient UHID",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("uhid")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient UHID
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "uhid" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "uhid" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Link
          to={`${patientsUrl}${historyUrl}/${row?.patient_id}`}
          style={{
            width: "100%",
            height: "100%",
            textDecoration: "none",
            cursor: "pointer",
            color: "inherit",
            outline: "none",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            {row?.uhid}
          </Typography>
        </Link>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "Patient’s Name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("patient_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient’s Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "patient_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "patient_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={style.textOverflow}>
          {row?.patient_name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "mobile",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("mobile")}
        >
          <Typography variant="h5" fontSize={14}>
            Mobile No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "mobile" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "mobile" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.mobile}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "doctor_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("doctor_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Doctor Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "doctor_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "doctor_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={style.textOverflow}>
          {row?.doctor_name}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "doa",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("doa")}
        >
          <Typography variant="h5" fontSize={14}>
            Admission date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "doa" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "doa" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={style.textOverflow}>
          {moment(row?.doa).format("DD/MM/YYYY hh:mm:ss A")}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "dod",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("dod")}
        >
          <Typography variant="h5" fontSize={14}>
            Discharge date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "dod" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "dod" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={style.textOverflow}>
          {row?.dod ? dateFormat(row?.dod) : ""}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      field: "room_no",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("room_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Room
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "room_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "room_no" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={style.textOverflow}>
          {`${row?.floor_name ? row?.floor_name : ""}/${
            row?.room_no ? row?.room_no : "-"
          }`}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "created_user",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_user" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_user" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={style.textOverflow}>
          {row?.created_user}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    // dev comment
    // {
    //   field: "bill_info",
    //   flex: 1,
    //   renderHeader: () => (
    //     <div
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         width: "100%",
    //         height: "51px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       <Typography variant="h5" fontSize={14}>
    //         Bill Info
    //       </Typography>
    //     </div>
    //   ),
    //   renderCell: ({ row }: any) => (
    //     <Typography
    //       variant="h5"
    //       fontSize={14}
    //       sx={{
    //         alignItems: "center",
    //         textAlign: "center",
    //         display: "flex",
    //         width: "75%",
    //         justifyContent: "center",
    //       }}
    //     >
    //       <Tooltip
    //         disableFocusListener
    //         arrow
    //         componentsProps={{
    //           tooltip: {
    //             sx: {
    //               background: "#fff",
    //               border: "1px solid #D8D8D8",
    //               color: "#232323",
    //               fontSize: "8px",
    //               fontWeight: 500,
    //               lineHeight: "9.68px",
    //               width: "150px",
    //             },
    //           },
    //           arrow: {
    //             sx: {
    //               color: "#fff !important",
    //               fontSize: 15,
    //               "&:before": {
    //                 border: "1px solid #D8D8D8",
    //               },
    //             },
    //           },
    //         }}
    //         placement="top-start"
    //         title={<BillInfo />}
    //       >
    //         <IconButton>
    //           <BillInfoIcon style={{ cursor: "pointer" }} />
    //         </IconButton>
    //       </Tooltip>
    //     </Typography>
    //   ),
    //   minWidth: 50,
    //   sortable: false,
    // },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "75%",
          }}
        >
          <TableActions row={row} />
        </Box>
      ),
      minWidth: 80,
      sortable: false,
    },
  ];

  const clearFilters = () => {
    if (
      filterData.from_date ||
      filterData.to_date ||
      filterData.ip_no ||
      filterData.patient_id ||
      filterData.status
    ) {
      dispatch(
        setFilterDataValues({
          key: "ipAdmissionsList",
          name: "clearFilter",
          value: initialFilterData,
        })
      );

      setIsClearFilter(true);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "patient_id" && value) {
      const finalValue = patientsList.filter((obj: any) => {
        if (obj.value === value) {
          return obj;
        }
      });
      dispatch(
        setFilterDataValues({
          key: "ipAdmissionsList",
          name: name,
          value: finalValue[0],
        })
      );
      dispatch(setCurrentPage({ key: "ipAdmissionsList", value: 0 }));

      return;
    }

    dispatch(
      setFilterDataValues({ key: "ipAdmissionsList", name: name, value: value })
    );
    dispatch(setCurrentPage({ key: "ipAdmissionsList", value: 0 }));
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(
        setFilterDataValues({
          key: "ipAdmissionsList",
          name: name,
          value: newValue.validatedValue[0],
        })
      );
      dispatch(setCurrentPage({ key: "ipAdmissionsList", value: 0 }));
    }
  };

  const handleDialogFieldsInputChange = (e: any) => {
    const { name, value } = e.target;

    setFormData((prev: any) => ({ [name]: value }));
    handleValidation(e);
  };

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <InfiniteScroller
          loadOptions={getAllPatients}
          options={patientsList}
          isClearFilter={isClearFilter}
          setIsClearFilter={setIsClearFilter}
          label={"Select Patient"}
          handleOnChange={handleInputChange}
          name={"patient_id"}
          value={filterData.patient_id?.value}
          width={"100%"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.ip_no}
          placeholder={"IP number"}
          label={"IP number"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"ip_no"}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "45px",
              borderRadius: "5px",
              paddingLeft: "10px",
            },
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Select
          value={filterData.status}
          placeholder="Select Status"
          label="Status"
          formControlStyle={{ width: "100%" }}
          options={statusOptions}
          onChange={handleInputChange}
          name="status"
          sx={{
            mr: "10px",
            width: "100%",
            height: "45px",
            boxShadow: "none",
            borderRadius: "5px",
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <DatePicker
          label="From Date"
          name="from_date"
          placeholder="Select Date"
          width="100%"
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "from_date")
          }
          formControlStyle={{ width: "90%" }}
          format="DD/MM/YYYY"
          value={filterData.from_date}
          maxDate={filterData?.to_date}
          style={{
            height: "35px",
            borderRadius: "5px",
            boxShadow: "none",
          }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <DatePicker
          label="To Date"
          name="to_date"
          placeholder="Select Date"
          width="100%"
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "to_date")
          }
          formControlStyle={{ width: "90%" }}
          format="DD/MM/YYYY"
          value={filterData.to_date}
          minDate={filterData?.from_date}
          style={{
            height: "35px",
            borderRadius: "5px",
            boxShadow: "none",
          }}
        />
      ),
    },
  ];

  const handleClearSearch = () => {
    dispatch(
      setHandleClearSearch({
        key: "ipAdmissionsList",
        value: "",
        name: "search",
      })
    );
  };

  const handleDelete = async (id: any) => {
    try {
      setIsDataTableLoading(true);
      setIsConfirmationDialogButtonLoading(true);
      await deleteIpAdmissionById(id).then((result: any) => {
        setIsDataTableLoading(false);

        const data = {
          page: Number(pageInfo.page) + 1,
          limit: pageInfo.pageSize,
          column: sortField?.field,
          order: sortField?.order,
          from_date: filterData.from_date
            ? commonDateFormatter(filterData.from_date, "YYYY-MM-DD")
            : "",
          to_date: filterData.to_date
            ? commonDateFormatter(filterData.to_date, "YYYY-MM-DD")
            : "",
          patient_id: filterData.patient_id,
          ip_no: filterData.ip_no,
          search: filterData.search,
          is_discharge: filterData.status,
        };

        debouncedGetAllAdmissions(data);
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: DELETED_SUCCESSFULLY,
          })
        );

        setIsConfirmationDialogOpen(false);
        setIsConfirmationDialogButtonLoading(false);
        setIdToBeDeleted(null);
      });
    } catch (error: any) {
      const errorData = error?.response?.data?.error_data
        ? error?.response?.data?.error_data
        : null;

      const errorMessage = errorData ? Object.keys(errorData).join(", ") : "";

      setIsDataTableLoading(false);
      setIsConfirmationDialogOpen(false);
      setIsConfirmationDialogButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: errorData
            ? `${error?.response?.data?.errors} ${errorMessage}`
            : `${error?.response?.data?.errors}`,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };

  // dialog functions

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const dialogClose = async () => {
    setIsDialogWrapperButtonLoading(false);
    setIsDialogWrapperOpen(false);
    setIsDialogLoading(false);
    setFormError(initialFormError);
    setIdToBeDeleted(null);
    setIsConfirmationDialogOpen(false);
    setIsDialogLoading(false);
    setFormData(initialFormData);
  };

  const generatePrint = async () => {
    if (formData?.printType === null) {
      validateForm();
    } else {
    }
  };

  useEffect(() => {
    updateFormFieldData();
  }, [formError, formData]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "95vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "90vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Box>
      <DialogWrapper
        onClose={dialogClose}
        open={isDialogWrapperOpen}
        handleClick={generatePrint}
        title="Filter Bills"
        maxWidth="xs"
        loading={isDialogWrapperButtonLoading}
        confirmText="Generate"
      >
        {isDialogLoading ? (
          <Grid
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid>
            <Select
              label={fieldData.printType.label}
              name={fieldData.printType.name}
              value={fieldData.printType.value}
              options={printOptions}
              onChange={handleDialogFieldsInputChange}
              error={fieldData.printType.isError}
              helperText={fieldData.printType.helperText}
              width="160px"
              sx={{
                width: {
                  xs: "160px",
                  xl: "160px",
                },
                height: "45px",
                boxShadow: "none",
                borderRadius: "5px",
              }}
            />
          </Grid>
        )}
      </DialogWrapper>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isConfirmationDialogButtonLoading}
      />
      <CustomFilter
        data={appointmentFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        clearFilter={clearFilters}
        searchOnChange={handleInputChange}
        searchName={"search"}
        appliedFilterCount={count}
        clearSearch={handleClearSearch}
        searchValue={filterData.search}
        {...(can("ip_add") && {
          editButtonTitle: "+ Add",
          onUpdateButtonClick: () =>
            navigate(`${RouteUrls.inPatients}${RouteUrls.addUrl}`),
        })}
      />
      <DataTable
        loading={isDataTableLoading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={patientRoomList || []}
        pageCount={pageCount}
        currentPage={"ipAdmissionsList"}
      />
    </Box>
  );
}
