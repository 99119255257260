import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { SaveButton, CancelButton, Button } from "../../../../components/basic";
import PaymentsTabs from "./PaymentsTabs";
import PaymentsTable from "./PaymentsTable";
import PaymentsFooter from "./PaymentsFooter";
import {
  createWholeSalesPaymentAdvance,
  createWholeSalesPayment,
  geWholeSalesPaymentBillById,
} from "../../../../services/gynecologyService";
import { formula } from "../../../../utils/CalculationUtils";
import { paymentOptions } from "../../../../constants/dropdownOptions";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { DeleteAlertIcon } from "../../../../assets/icons";
import { timeWithTwentyFourHoursConverter } from "../../../../utils/DateTimeFormatUtils";
import { useNavigate } from "react-router-dom";

const { calculateDiscountAmount, calculateAmountDue, calculateConvenienceFee } =
  formula.payments;
// const { payments, paymentsListUrl } = RouteUrls;

const Payments = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState("1");
  const initialData: any = {
    customer_id: "",
    payee_id: 1,
    payment_type: "Cash",
    urn_number: "",
    cash_received: "",
    used_amount: 0,
    excess_amount: 0,
    refunded_excess: 0,
    net_excess: 0,
    convenience_fee: 0,
    pay_term_id: null,
    date_created: new Date(),
    is_pay_full_amount: false,
    payments: [],
  };
  const navigate = useNavigate();
  const [data, setData] = useState(initialData);
  const [advanceData, setAdvanceData] = useState({
    customer_id: "",
    cash_received: "",
    date_created: new Date(),
    payment_type: "Cash",
    urn_number: "",
  });
  const [totals, setTotals] = useState({
    totalBillAmount: 0,
    totalAmountDue: 0,
    totalPaidAmount: 0,
  });
  const [errors, setErrors] = useState<any[]>([]);
  const [cashReceivedError, setCashReceivedError] = useState(false);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState(paymentOptions);
  const [cardDetails, setCardDetails] = useState<any>({});
  const [excessAmount, setExcessAmount] = useState({
    excess: 0,
    advance: 0,
  });
  const [loading, setLoading] = useState(false);
  const [isPatientBillsLoading, setIsPatientBillsLoading] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<{
    isOpen: boolean;
    content: string;
  }>({
    isOpen: false,
    content: "",
  });

  const calCulatePreviousTotalAmount = (data: any, index: number) => {
    let cashReceived = Number(data.cash_received) || 0;

    if (data?.payments?.length > 0) {
      const bills = data?.payments?.slice(0, index);

      bills?.forEach((ele: any) => {
        cashReceived -= Number(ele.paid_amt) || 0;
      });
    }

    return cashReceived;
  };

  const tableCalculation = (
    row: any,
    index: number,
    data: any,
    name: string
  ) => {
    row.discount_amount = calculateDiscountAmount(row);
    row.balance = calculateAmountDue(row);

    // if (row.payInFull) {
    // row.paid_amt = row.balance;
    // }

    if (row?.balance < 0) {
      setErrors((prev) => {
        const newErrors = [...prev];
        newErrors[index].paid_amt = row.paid_amt ? "required field" : "";
        newErrors[index].discount_value = row.discount_amount
          ? "required field"
          : "";
        newErrors[index].tds = row.tds ? "required field" : "";
        return newErrors;
      });
    } else {
      setErrors((prev) => {
        const newErrors = [...prev];
        newErrors[index].paid_amt =
          Number(row?.paid_amt) > Number(row?.balance) ? "required field" : "";
        newErrors[index].discount_value = "";
        newErrors[index].tds = "";

        if (name === "paid_amt") {
          const previousAmount = calCulatePreviousTotalAmount(data, index);

          if (
            row.paid_amt &&
            // !newErrors[index]?.paid_amt &&
            previousAmount < row.paid_amt
          ) {
            newErrors[index].paid_amt = "Required field";
          } else if (Number(row?.paid_amt) > Number(row?.balance)) {
            newErrors[index].paid_amt = "Required field";
          } else {
            newErrors[index].paid_amt = "";
          }
        }
        return newErrors;
      });
    }

    return row;
  };

  // useEffect(() => {
  //   if (data.supplier_id) {
  //     geWholeSalesPaymentBillById({ supplier_id: data.supplier_id, bill_id: null })
  //       .then((res) => {
  //         const purchaseBillData = res.data.bills;
  //         const purchaseAdvance = res.data.purchase_advance?.balance;
  //         const purchaseExcess = res.data.purchase_excess?.balance;
  //         const paymentsList = purchaseBillData.map((ele: any) => ({
  //           bill_id: ele.id,
  //           department_name: ele.name,
  //           invoice_no: ele.invoice_no,
  //           ref_no: ele.ref_no,
  //           net_total_amt: ele.bill_amt,
  //           amount_due: ele.bill_amt,
  //           remarks: "",
  //           balance: ele.bill_amt,
  //         }));
  //         const tableErrorList = paymentsList.map((ele: any) => ({
  //           setTableError: false,
  //         }));
  //         // setTableError((prevState) => [...prevState, ...tableErrorList]);
  //         setData((prevState: any) => ({
  //           ...prevState,
  //           net_excess: purchaseExcess || 0,
  //           purchase_excess: purchaseExcess || 0,
  //           purchase_advance: purchaseAdvance || 0,
  //           payments: paymentsList.length ? [...paymentsList] : [],
  //         }));
  //         const newTypeOptions = paymentOptions.map((option) => {
  //           const newOption = { ...option } as any;

  //           if (option.id === "Advance") {
  //             newOption["disabled"] = !Boolean(Number(purchaseAdvance) > 0);
  //           }
  //           if (option.id === "Excess") {
  //             newOption["disabled"] = !Boolean(Number(purchaseExcess) > 0);
  //           }
  //           return newOption;
  //         });
  //         // setTypeOptions(newTypeOptions);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [data.supplier_id]);
  const handleInfoChange = (e: any) => {
    const { name, value } = e.target;
    console.log(name, value);

    setData((prevFilterData: any) => {
      const prevState = { ...prevFilterData };

      prevState[name] = value;

      if (
        name === "cash_received" &&
        Number(totals.totalPaidAmount) > Number(value)
      ) {
        setCashReceivedError(true);
        const newErrors = [...errors];
        let amount = prevState.cash_received;
        prevState?.payments?.forEach((ele: any, i: number) => {
          if (
            ele.paid_amt &&
            !newErrors[i]?.paid_amt &&
            amount < Number(ele.paid_amt)
          ) {
            newErrors[i].paid_amt = "Required field";
          } else {
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
      } else if (
        name === "cash_received" &&
        prevState.payment_type === "Card"
      ) {
        prevState.convenience_fee = calculateConvenienceFee(
          prevState,
          cardDetails?.convenience_fee_percentage
        );
        if (name === "cash_received" && cashReceivedError) {
          setCashReceivedError(false);
        }
        const newErrors = [...errors];
        let amount = prevState.cash_received;
        prevState?.payments?.forEach((ele: any, i: number) => {
          if (ele.paid_amt && amount < ele.paid_amt) {
            newErrors[i].paid_amt = "Required field";
          } else {
            newErrors[i].paid_amt = "";
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
      } else if (name === "cash_received") {
        const newErrors = [...errors];
        let amount = prevState.cash_received;
        prevState?.payments?.forEach((ele: any, i: number) => {
          if (ele.paid_amt && amount < ele.paid_amt) {
            newErrors[i].paid_amt = "Required field";
          } else {
            newErrors[i].paid_amt = "";
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
        if (name === "cash_received" && cashReceivedError) {
          setCashReceivedError(false);
        }
      }

      if (name === "is_pay_full_amount" && value) {
        let cash = prevState.cash_received;

        const newErrors = [...errors];
        const payments = prevState?.payments?.map((bill: any, ind: number) => {
          if (cash > 0 && bill.balance) {
            bill.paid_amt = cash - bill.balance > 0 ? bill.balance : cash;
            cash -= bill.paid_amt;
            newErrors[ind].paid_amt = "";
          } else if (cash === 0 && bill.paid_amt) {
            bill.paid_amt = 0;
            newErrors[ind].paid_amt = "";
          }
          return {
            ...bill,
          };
        });

        setErrors(newErrors);
        setCashReceivedError(false);

        const { totalBillAmount, totalAmountDue, totalPaidAmount } =
          payments?.reduce(
            (totals: any, bill: any) => {
              totals.totalBillAmount += parseFloat(bill.net_total_amt) || 0;
              totals.totalAmountDue += parseFloat(bill.balance) || 0;
              totals.totalPaidAmount += parseFloat(bill.paid_amt) || 0;
              return totals;
            },
            { totalBillAmount: 0, totalAmountDue: 0, totalPaidAmount: 0 }
          );

        setTotals({
          totalBillAmount,
          totalAmountDue,
          totalPaidAmount,
        });
      } else if (name === "is_pay_full_amount" && !value) {
        // const newPatientBills = [...prevState.payments];
        // prevState.payments = newPatientBills?.map((ele) => ({
        //   ...ele,
        //   paid_amt: 0,
        // }));
      }

      if (
        name === "payment_type" &&
        value === "Card" &&
        prevState?.cash_received
      ) {
        prevState.convenience_fee = calculateConvenienceFee(
          prevState,
          cardDetails?.convenience_fee_percentage || 0
        );
      } else if (name === "payment_type" && value !== "Card") {
        prevState.convenience_fee = 0;
      }

      if (
        name === "payment_type" &&
        (value === "Advance" || value === "Excess")
      ) {
        prevState.cash_received =
          excessAmount[value === "Advance" ? "advance" : "excess"];
        prevState.is_pay_full_amount = false;
        const newErrors = [...errors];
        let amount = prevState.cash_received;
        prevState?.payments?.forEach((ele: any, i: number) => {
          if (
            ele.paid_amt &&
            !newErrors[i]?.paid_amt &&
            amount < ele.paid_amt
          ) {
            newErrors[i].paid_amt = "Required field";
          } else {
            amount -= ele.paid_amt;
          }
        });
        setErrors(newErrors);
      }

      prevState.used_amount = prevState?.payments?.reduce(
        (total: any, bill: any) => {
          return total + (parseFloat(bill.paid_amt) || 0);
        },
        0
      );
      prevState.excess_amount =
        (prevState.cash_received || 0) - prevState.used_amount;
      const net_excess =
        (prevState.cash_received || 0) -
        prevState.used_amount -
        (prevState.refunded_excess || 0);
      prevState.net_excess = net_excess > 0 ? net_excess : 0;
      return prevState;
    });
  };
  const handleRowChange = (e: any, index: number) => {
    const { name, value } = e.target;

    setData((prevState: any) => {
      const newRows = prevState?.payments?.map((row: any, i: number) => {
        if (i === index) {
          let newRow = { ...row, [name]: value };

          if (name === "paid_amt" && newRow.payInFull) {
            newRow.payInFull = false;
          }
          if (
            name === "discount_value" ||
            name === "discount_type" ||
            name === "tds" ||
            name === "paid_amt"
          ) {
            const newData = { ...prevState };
            if (newData?.payments[index]) {
              newData.payments[index][name] = value;
            }
            const newState = tableCalculation(newRow, index, newData, name);

            // if (name === "paid_amt") {
            //   const rows = [...prevState?.payments];
            //   rows[index][name] = value;

            //   const newErrors = [...errors];
            //   let amount = prevState.cash_received;
            //   rows?.forEach((ele: any, i: number) => {
            //     if (ele.paid_amt && amount < Number(ele.paid_amt)) {
            //       newErrors[i].paid_amt = "Required field";
            //       amount -= Number(ele.paid_amt);
            //     } else {
            //       amount -= Number(ele.paid_amt) || 0;
            //       newErrors[i].paid_amt = "";
            //     }
            //   });
            //   setErrors(newErrors);
            // }

            return newState;
          }

          if (name === "payInFull" && value) {
            newRow.paid_amt = setRowPaidAmount(data, newRow, index);
            if (errors[index]?.paid_amt) {
              setErrors((prev) => {
                const newErrors = [...prev];
                if (newErrors[index].paid_amt) {
                  newErrors[index].paid_amt = "";
                }
                return newErrors;
              });
            }
          }

          if (
            (name === "payInFull" || name === "paid_amt") &&
            !data.cash_received
          ) {
            setCashReceivedError(true);
          }

          setErrors((prev) => {
            const newErrors = [...prev];
            if (newErrors[index][name] && value) {
              newErrors[index][name] = "";
            }
            return newErrors;
          });
          return newRow;
        }
        return row;
      });

      if (
        name === "discount_value" ||
        name === "discount_type" ||
        name === "tds" ||
        name === "paid_amt" ||
        (name === "payInFull" && value)
      ) {
        const { totalBillAmount, totalAmountDue, totalPaidAmount } =
          newRows?.reduce(
            (totals: any, bill: any) => {
              totals.totalBillAmount += parseFloat(bill.net_total_amt) || 0;
              totals.totalAmountDue += parseFloat(bill.balance) || 0;
              totals.totalPaidAmount += parseFloat(bill.paid_amt) || 0;
              return totals;
            },
            { totalBillAmount: 0, totalAmountDue: 0, totalPaidAmount: 0 }
          );

        prevState.used_amount = totalPaidAmount;
        prevState.excess_amount =
          (prevState.cash_received || 0) - totalPaidAmount;
        prevState.net_excess =
          (prevState.cash_received || 0) -
          totalPaidAmount -
          (prevState.refunded_excess || 0);

        setTotals({
          totalBillAmount,
          totalAmountDue,
          totalPaidAmount,
        });
      }
      return { ...prevState, payments: newRows };
    });
  };

  useEffect(() => {
    if (data.customer_id) {
      setIsPatientBillsLoading(true);
      geWholeSalesPaymentBillById({
        customer_id: data.customer_id,
        bill_id: null,
      })
        .then((res: any) => {
          if (res.data) {
            const { bills, whole_sales_advance, whole_sales_excess } = res.data;
            const purchaseAdvance = whole_sales_advance?.balance;
            const purchaseExcess = whole_sales_excess?.balance;
            setData((prevState: any) => ({
              ...prevState,
              payments: bills?.map((ele: any) => ({
                ...ele,
                paid_amt: 0,
                net_total_amt: ele.bill_amt,
                remarks: "",
                discount_value: 0,
                discount_type: "%",
                discount_amount: 0,
                tds: 0,
              })),
            }));
            setErrors(bills?.map(() => ({})));
            const { totalBillAmount, totalAmountDue } =
              res?.data?.bills?.reduce(
                (totals: any, bill: any) => {
                  totals.totalBillAmount += parseFloat(bill.net_total_amt) || 0;
                  totals.totalAmountDue += parseFloat(bill.balance) || 0;
                  return totals;
                },
                { totalBillAmount: 0, totalAmountDue: 0 }
              );

            setTotals((prevState) => ({
              ...prevState,
              totalBillAmount,
              totalAmountDue,
            }));

            setExcessAmount({
              advance: purchaseAdvance,
              excess: purchaseExcess,
            });
            setPaymentTypeOptions((prevState: any) => {
              const newTypeOptions = prevState.map((option: any) => {
                const newOption = { ...option } as any;

                if (option.id === "Advance" || option.id === "Excess") {
                  newOption.disabled = !Boolean(
                    option.id === "Advance" ? purchaseAdvance : purchaseExcess
                    // res?.data[option.id === "Advance" ? "advance" : "excess"]
                  );
                }
                return newOption;
              });

              return newTypeOptions;
            });
          }
          setIsPatientBillsLoading(false);
        })
        .catch((err: any) => {
          setIsPatientBillsLoading(false);
        });
    }
  }, [data.customer_id]);

  const handleAdvanceInfoChange = (event: any) => {
    const { name, value } = event.target;
    setAdvanceData((prevState: any) => {
      const newState = { ...prevState };
      newState[name] = value;
      return newState;
    });
  };

  const setRowPaidAmount = (data: any, row: any, index: number) => {
    const totalCashPaidAmount = data?.payments?.reduce(
      (total: any, bill: any, i: any) => {
        if (i === index) {
          return total;
        }

        return total + (parseFloat(bill.paid_amt) || 0);
      },
      0
    );

    const balance = data.cash_received
      ? data.cash_received > totalCashPaidAmount
        ? Number(data.cash_received) - Number(row.balance) > 0
          ? row.balance
          : Number(data.cash_received) - totalCashPaidAmount
        : 0
      : row?.balance;
    return balance;
  };

  const handleValidate = () => {
    if (cashReceivedError) {
      return false;
    }

    if (data.refunded_excess > data.excess_amount) {
      setIsOpenErrorModal({
        isOpen: true,
        content: "The refunded amount cannot exceed the excess amount",
      });
      return false;
    }
    let productsHasError = true;

    if (errors?.length > 0) {
      errors?.forEach((item: any) => {
        if (Object.values(item)?.filter((ele) => Boolean(ele))?.length > 0) {
          productsHasError = false;
        }
      });
    }

    return productsHasError;
  };

  const handleSave = () => {
    if (handleValidate()) {
      setLoading(true);
      const formattedPayment = data?.payments?.map(
        (ele: {
          id: number;
          paid_amt: string;
          remarks: string;
          discount_value: string;
          discount_amount: string;
          discount_type: string;
          tds: string;
        }) => ({
          bill_id: ele.id,
          paid_amount: ele.paid_amt,
          remarks: ele.remarks,
          discount: ele.discount_value,
          discount_amount: ele.discount_amount,
          discount_type: ele.discount_type,
          tds: ele.tds,
        })
      );
      const paymentData = {
        customer_id: data.customer_id,
        cash_paid: data.cash_received,
        created_date: data.date_created
          ? timeWithTwentyFourHoursConverter(data.date_created)
          : "",
        pay_term_id: data.pay_term_id || "",
        bill_payment_type: data.payment_type,
        urn_number: data.urn_number,
        payments: [...formattedPayment],
        used_amount: data.used_amount,
        excess_amount: data.excess_amount,
        refunded_excess: data.refunded_excess,
        net_excess: data.net_excess,
      };
      createWholeSalesPayment(paymentData as any)
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setLoading(false);
          navigate(`/procurement/whole_sales_payments`);
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            setSnackBarFailed({ snackBarMessage: err.response.data.errors })
          );
          setLoading(false);
        });
    }
  };

  const handleSaveAdvance = () => {
    setLoading(true);
    createWholeSalesPaymentAdvance({
      customer_id: advanceData.customer_id,
      cash_paid: advanceData.cash_received,
      date_created: timeWithTwentyFourHoursConverter(
        advanceData.date_created as any
      ),
      bill_payment_type: advanceData.payment_type,
      urn_number: advanceData.urn_number,
    })
      .then((res: any) => {
        if (res) {
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        }
        setLoading(false);
        navigate(`/procurement/whole_sales_payments`);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
      });
  };

  console.log("data");

  return (
    <Grid className="payment">
      <PaymentsTabs
        tabValue={setTabValue}
        data={data}
        advanceData={advanceData}
        onChange={handleInfoChange}
        onAdvanceChange={handleAdvanceInfoChange}
        cashReceivedError={cashReceivedError}
        paymentTypeOptions={paymentTypeOptions}
        excessAmount={excessAmount}
      />
      {((tabValue === "1" && data.patient_id) ||
        (tabValue === "1" && data.payee_id)) && (
        <PaymentsTable
          data={data}
          isPatientBillsLoading={isPatientBillsLoading}
          totals={totals}
          onChange={handleRowChange}
          errors={errors}
        />
      )}
      {((tabValue === "1" && data.patient_id) ||
        (tabValue === "1" && data.payee_id)) && (
        <PaymentsFooter data={data} onChange={handleInfoChange} />
      )}
      {tabValue === "1" ? (
        <Grid sx={{ m: "35px 0 45px 0" }}>
          <SaveButton
            sx={{ width: "100px", height: "42px", mr: "20px" }}
            loading={loading}
            disabled={
              data?.payments?.length === 0 ||
              !Boolean(totals?.totalPaidAmount) ||
              cashReceivedError
            }
            handleClick={handleSave}
          />
          <CancelButton
            sx={{ width: "100px", height: "42px" }}
            // handleClick={() => navigate(`${payments}${paymentsListUrl}`)}
            isDisabled={loading}
          />
        </Grid>
      ) : (
        <Grid sx={{ m: "35px 0 45px 0" }}>
          <SaveButton
            sx={{ width: "100px", height: "42px", mr: "20px" }}
            loading={loading}
            disabled={
              Boolean(!advanceData.customer_id) ||
              Boolean(!advanceData?.cash_received)
            }
            handleClick={handleSaveAdvance}
          />
          <CancelButton
            sx={{ width: "100px", height: "42px" }}
            // handleClick={() => navigate(`${payments}${paymentsListUrl}`)}
            isDisabled={loading}
          />
        </Grid>
      )}
      <Dialog
        open={isOpenErrorModal.isOpen}
        keepMounted
        onClose={() => setIsOpenErrorModal({ isOpen: false, content: "" })}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiPaper-root": {
            position: "absolute",
            top: "0px",
            height: "145px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            p: "10px 20px",
            borderRadius: "10px",
          }}
        >
          <DeleteAlertIcon width="19px" height="19px" />
          <DialogTitle id="alert-dialog-title" sx={{ p: "20px 24px" }}>
            {isOpenErrorModal.content}
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            buttonText="Okay"
            sx={{
              borderRadius: "0px",
              width: "75px",
              height: "35px",
              fontWeight: 400,
              fontSize: "13px",
              border: 0,
              color: "#fff",
              "&:hover": {
                color: "#fff",
              },
              "&.Mui-focusVisible": {
                border: 1,
                borderColor: "primary.main",
                borderRadius: "5px",
              },
            }}
            handleClick={() =>
              setIsOpenErrorModal({ isOpen: false, content: "" })
            }
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Payments;
