import {
  Cheese,
  Chicken,
  Beans,
  Eggs,
  Fruits,
} from "../../../../../assets/icons";

type FollowUpDetailsType = {
  id: number | string;
  patient_id: number | string;
  patient_name: number | string;
  antenatal_card_id: number | string;
  date_of_visit: number | string;
  trimester_week: number | string;
  vitals: [
    {
      vital_id?: string;
      vital_date?: string;
      vital_name?: string;
      vital_unit?: string;
      vital_value?: number | string;
    }
  ];
  findings: [];
  tests: [];
  scans: [];
  notes: null;
  next_review_date: null;
  trimester: string;
  suggestions: [];
};
export default function index({
  lastFollowUpDetails,
}: {
  lastFollowUpDetails: FollowUpDetailsType;
}) {
  const activitiesInfoList = [
    {
      name: "Fruits",
      Icon: () => <Fruits />,
    },
    {
      name: "Cheese",
      Icon: () => <Cheese />,
    },
    {
      name: "Chicken",
      Icon: () => <Chicken />,
    },
    {
      name: "Eggs",
      Icon: () => <Eggs />,
    },
    {
      name: "Beans",
      Icon: () => <Beans />,
    },
  ];
  return (
    <div>
      {activitiesInfoList?.map((ele: { name: string | number; Icon: any }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            border: "1px solid #EDEDED",
            borderRadius: "7px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px",
            padding: "11px 12px 12px",
            margin: "12px 12px",
          }}
        >
          <div>
            <ele.Icon />
          </div>
          <div>
            <div style={{ fontSize: "16px", fontWeight: 500 }}>{ele.name}</div>
            {ele.name === "Fruits" && (
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 500,

                  color: "#030303",
                }}
              >
                Avocado, Apple, Banana, Orange, Mango, Avocado, Apple, Banana,
                Orange, Mango,
                <span style={{ color: "#207DFF", cursor: "pointer" }}>
                  &nbsp;View more
                </span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
