import React from "react";
import { Grid, Typography } from "@mui/material";
import { billSummaryConst } from "../../../constants/displayText";

type SummaryType = {
  total_discount_amount: number;
  goods_value: number;
  CGST: number;
  SGST: number;
  tax_amount: number;
  rounded_off: number;
  bill_amount: number;
};
type SummaryProps = {
  summary: SummaryType;
  handleSaveData?: () => void;
  setSummary: React.Dispatch<React.SetStateAction<SummaryType>>;
};

const Summary = React.memo(
  ({ summary, setSummary, handleSaveData }: SummaryProps) => {
    const { goodsValue, CGST, SGST, taxAmount, roundedOff, netAmount } =
      billSummaryConst;

    const summaryOne = [
      {
        name: goodsValue,
        value: summary.goods_value,
      },
      {
        name: CGST,
        value: summary.CGST,
      },
      {
        name: SGST,
        value: summary.SGST,
      },
    ];

    const summaryTwo = [
      {
        name: taxAmount,
        value: summary.tax_amount,
      },
      {
        name: roundedOff,
        value: summary.rounded_off,
      },
      {
        name: netAmount,
        value: summary.bill_amount,
      },
    ];

    return (
      <Grid
        container
        sx={{
          width: { xs: "90vw", sm: "80vw", md: "90vw" },
          display: "flex",
          flexDirection: "column",
          m: "20px 0",
        }}
      >
        <Typography
          variant="h3"
          color="initial"
          sx={{
            fontWeight: "600",
            color: "primary.main",
            m: "20px 0",
          }}
        >
          Billing Details
        </Typography>
        <Grid
          container
          sx={{
            width: { xs: "90vw", sm: "80vw", md: "90vw" },
            borderRadius: "5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: { xs: "space-around" },
              border: 1,
              borderColor: "#D1D1D1",
              borderRadius: "5px",
              mr: {
                xs: "0px",
                sm: "0px",
              },
            }}
          >
            <Grid
              item
              sx={{
                width: {
                  xs: "70%",
                  sm: "50%",
                  md: "30%",
                  lg: "20%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                m: "51px 0px 45px 0px",
              }}
            >
              {summaryOne.map((row, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      width: {
                        xs: "100%",
                        lg: "100%",
                      },
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      maxHeight: "35px",
                      mb: "24px",
                      color: "textPrimary.main",
                    }}
                    className="row"
                  >
                    <Grid
                      sx={{
                        width: {
                          xs: "50%",
                          sm: "40%",
                        },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h4">{row.name}</Typography>
                      <Typography variant="h4">:</Typography>
                    </Grid>
                    <Typography>
                      {row.name === SGST || row.name === CGST
                        ? row?.value
                        : row?.value?.toFixed(2)}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "70%",
                  sm: "50%",
                  md: "35%",
                  lg: "20%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                m: "51px 0px 45px 0px",
              }}
            >
              {summaryTwo.map((row, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      width: {
                        xs: "100%",
                        lg: "100%",
                      },
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      maxHeight: "35px",
                      mb: "24px",
                      color: "textPrimary.main",
                    }}
                    className="row"
                  >
                    <Grid
                      sx={{
                        width: {
                          xs: "50%",
                          sm: "40%",
                        },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: "400",
                        }}
                      >
                        {row.name}
                      </Typography>
                      <Typography variant="h4">:</Typography>
                    </Grid>

                    <Typography
                      sx={{
                        width: "20%",
                        textAlign: "right",
                        fontWeight: "400",
                      }}
                    >
                      {row.name === netAmount
                        ? row?.value
                        : row?.value?.toFixed(2)}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default Summary;
