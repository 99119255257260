import { Box, Grid, InputAdornment } from "@mui/material";
import {
  DatePicker,
  TextField,
  Select,
  InfiniteScroller,
} from "../../../../components/basic";
import { inputEndValue } from "../../../../constants/displayText";
import { InfoIconSmall, CardCancelIcon } from "../../../../assets/icons";

export default function AddAntenatal({
  antenatalInfo,
  handleInputChange,
  getAllPatients,
  obstetricViewList,
  addressInfoList,
  personalInfoList,
  setIsHistoryOfDiseaseOpen,
  historyOfDiseaseData,
  symptomsList,
  handleDateChange,
  handleRemoveDisease,
}: any) {
  const ObstetricView = () => {
    return (
      <Grid sx={{ maxWidth: "88%" }}>
        <Box sx={{ fontWeight: 400, fontSize: "12px", color: "#8A8A8A" }}>
          Obstetric Score
        </Box>
        <Grid
          item
          sm={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            marginTop: "7px",
          }}
        >
          {obstetricViewList.map((ele: any, idx: number) => (
            <>
              <Box
                sx={{
                  marginTop: "16px",
                }}
                key={idx}
              >
                {ele.frontLetter}
              </Box>
              <Box>
                <TextField
                  name={ele.name}
                  value={ele.value}
                  onChange={(e) => handleInputChange(e, idx)}
                />
              </Box>
            </>
          ))}
        </Grid>
      </Grid>
    );
  };
  return (
    <Box>
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          color: "#207DFF",
          backgroundColor: "#F8F8F8",
          padding: "10px",
          width: "101%",
          marginLeft: "-22px",
          paddingLeft: "23px",
        }}
      >
        Patient Information
      </Box>
      <Grid container>
        {personalInfoList.map((ele: any, idx: number) => (
          <Grid
            item
            sm={ele.isFullWidth ? 6 : 3}
            sx={{
              marginBottom: "10px",
              marginTop: "10px",
              paddingRight: "12px",
              display: "flex",
              flexDirection: "row",
              justifyContent: `${ele.flex} !important`,
            }}
          >
            {ele.name === "patient_id" && !ele.disabled && (
              <InfiniteScroller
                loadOptions={getAllPatients}
                label={"Select Patient"}
                handleOnChange={handleInputChange}
                name={"patient_id"}
                value={ele.value}
                width={"370px"}
              />
            )}
            {ele.type === "textField" && (
              <TextField
                name={ele.name}
                label={ele.label}
                onChange={handleInputChange}
                value={ele.value}
                width={ele.isFullWidth ? "370px" : "155px"}
                disabled={ele.disabled}
                error={ele?.error}
                helperText={ele?.helperText}
              />
            )}
            {ele.name === "obstetric" && <ObstetricView />}
            {ele.type === "date" && (
              <DatePicker
                name={ele.name}
                label={ele.label}
                value={ele.value}
                style={{ width: ele.name === "dofv" ? "347px" : "137px" }}
                format="DD/MM/YYYY"
                onChange={(e: any, newValue: any) =>
                  handleDateChange(ele.name, newValue)
                }
                error={ele?.error}
                helperText={ele?.helperText}
              />
            )}
            {ele.type === "select" && ele.name !== "patient_id" && (
              <Select
                name={ele.name}
                label={
                  ele.name === "first_pregnancy" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "15.5rem",
                      }}
                    >
                      <Box>{ele.label}</Box>
                      <Box>
                        <InfoIconSmall style={{ cursor: "pointer" }} />
                      </Box>
                    </Box>
                  ) : (
                    ele.label
                  )
                }
                value={ele.value}
                error={ele?.error}
                helperText={ele?.helperText}
                options={ele.options || []}
                onChange={handleInputChange}
                width={ele.name === "menstruation_cycle" ? "155px" : "370px"}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          color: "#207DFF",
          backgroundColor: "#F8F8F8",
          padding: "10px",
          width: "101%",
          marginLeft: "-22px",
          paddingLeft: "23px",
        }}
      >
        Address
      </Box>
      <Grid container>
        {addressInfoList.map((ele: any, idx: number) => (
          <Grid
            item
            sm={ele.isFullWidth ? 6 : 3}
            sx={{
              marginBottom: "10px",
              marginTop: "10px",
              paddingRight: "12px",
              display: "flex",
              flexDirection: "row",
              justifyContent: `${ele.flex} !important`,
            }}
          >
            {ele.type === "textField" && (
              <TextField
                name={ele.name}
                label={ele.label}
                value={ele.value}
                onChange={handleInputChange}
                width={ele.isFullWidth ? "370px" : "155px"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {inputEndValue[ele.name]}
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {ele.type === "select" && (
              <Select
                name={ele.name}
                label={ele.label}
                options={ele.options || []}
                onChange={handleInputChange}
                value={ele.value}
                width={"155px"}
              />
            )}
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          color: "#207DFF",
          backgroundColor: "#F8F8F8",
          padding: "10px",
          width: "101%",
          marginLeft: "-22px",
          paddingLeft: "23px",
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Box> History of Disease</Box>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => setIsHistoryOfDiseaseOpen(true)}
        >
          + Add
        </Box>
      </Box>
      {historyOfDiseaseData?.map((ele: any) => (
        <Box sx={{ backgroundColor: "#E9F2FF", margin: "10px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              padding: "10px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
                {ele.relationship}
              </Box>
              <Box>-</Box>
              <Box sx={{ fontSize: "14px", fontWeight: "500" }}>
                {ele?.disease
                  .map((diseaseId: number) => {
                    const symptom = symptomsList?.find(
                      (symptom: any) => symptom?.id === diseaseId
                    );
                    return symptom ? symptom?.symptom_name : null;
                  })
                  .join(", ")}
              </Box>
            </Box>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => handleRemoveDisease(ele.relationship)}
            >
              <CardCancelIcon />
            </Box>
          </Box>
        </Box>
      ))}
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          color: "#207DFF",
          backgroundColor: "#F8F8F8",
          padding: "10px",
          width: "101%",
          marginLeft: "-22px",
          paddingLeft: "23px",
        }}
      >
        Surgical History
      </Box>
      <Box sx={{ marginTop: "10px" }}>
        <TextField
          placeholder="Type something..."
          name={"surgical_history"}
          value={antenatalInfo?.surgical_history}
          onChange={handleInputChange}
        />
      </Box>
    </Box>
  );
}
