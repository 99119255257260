import React from "react";
import { Button, Grid, Typography } from "@mui/material";

type BrowseButtonProps = {
  buttonText?: string;
  buttonSx?: object;
  onChange?: any;
  selectedItems?: any;
};

const BrowseButton = ({
  buttonText = "Browse",
  buttonSx,
  onChange,
  selectedItems,
}: BrowseButtonProps) => {
  return (
    <Grid sx={{ display: "flex", alignItems: "center", width: "300px" }}>
      <Button component="label" variant="outlined" sx={{ ...buttonSx }}>
        {buttonText}
        <input
          style={{
            clip: "rect(0 0 0 0)",
            clipPath: "inset(50%)",
            height: 1,
            overflow: "hidden",
            position: "absolute",
            bottom: 0,
            left: 0,
            whiteSpace: "nowrap",
            width: 1,
          }}
          multiple
          type="file"
          onChange={onChange}
        />
      </Button>
      <Grid sx={{ width: "150px" }}>
        {selectedItems.length > 0 ? (
          <Typography variant="h6" sx={{ ml: "10px" }}>
            {selectedItems.length === 1
              ? selectedItems[0].name
              : `${selectedItems.length} files selected`}
          </Typography>
        ) : (
          <Typography variant="h6" sx={{ ml: "10px" }}>
            No files selected.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default BrowseButton;
