import { admissionEndPoints } from "../constants/apiEndPoints";
import ApiUtils from "../utils/ApiUtils";
type getAllPatientRoomFilterType = {
  page: number;
  limit: number;
  column: string;
  order: string;
  from_date: string;
  to_date: string;
  patient_id: number | string;
  ip_no: number | string;
  search: string;
};

type PatientsUserTypes = {
  active: number;
  alt_mobile: string;
  area: string;
  device_tokens: null | string | any;
  district: string;
  email: string;
  id: number | string;
  language: string;
  last_name: string;
  line_1: string;
  line_2: string;
  mobile: string;
  name: string;
  paid: number | string;
  paid_date: string;
  pincode: string;
  state: string;
  title: string;
};

const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);

  let queryString = "?";
  keys.map((key) => {
    if (data[key] && !Array.isArray(data[key])) {
      queryString += `${key}=${data[key]}&`;
    }
    if (Array.isArray(data[key])) {
      data[key].map(
        (ele: any, index: number) => (queryString += `${key}[${index}]=${ele}&`)
      );
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};

const {
  getCommonVariables,
  getAppConfiguration,
  getUserInfo,
  getAllPatientsName,
  admissionUrl,
  roomUrl,
  getConsultantsListUrl,
  getPatientDetailsUrl,
  inPatientAdmissionDetail,
  getIpRoomTransferHistoryDetails,
  getVitalsList,
  getAdmissionIpVitals,
  createAdmissionIpVital,
  deleteAdmissionIpVital,
  getAdmissionIpDischargeSummary,
  createAdmissionIpDischargeSummary,
  updateAdmissionIpDischargeSummary,
  editAdmissionIpDischargeSummary,
  deleteAdmissionIpDischargeSummary,
  getAdmissionIpInvestigations,
  createAdmissionIpInvestigation,
  deleteAdmissionIpInvestigation,
  getAdmissionIpTreatments,
  createAdmissionIpTreatment,
  deleteAdmissionIpTreatment,
  getAdmissionIpPrescriptions,
  createAdmissionIpPrescription,
  deleteAdmissionIpPrescription,
  getPrintNotesIpTypes,
  getRoomsList,
  getBillingItemsList,
  getTreatmentItemsList,
  getAllUnitsList,
  getPxProductsList,
  productsList,
  updateSequenceNumber,
  getSequenceNumber,
  getAllIpStatus,
  createIpRoomTransfer,
  updateIpRoomTransfer,
  deleteIpAdmission,
  getAdmissionIpOts,
  getAllDepartmentUrl,
  getAllPositionUrl,
  getAllSurgeryUrl,
  createOperationTheatreUrl,
  editOperationTheatreUrl,
  updateOperationTheatreUrl,
  deleteOperationTheatreUrl,
  createOtJobUrl,
  editOtJobUrl,
  getAllDoctorsName,
  getDoctorVitalsDetails,
} = admissionEndPoints;

const getCommonVariablesDetails = async () => {
  return await ApiUtils.getWithToken(getCommonVariables);
};

const getAppConfigurationDetails = async () => {
  return await ApiUtils.getWithToken(getAppConfiguration);
};

const getUserDetails = async () => {
  return await ApiUtils.getWithToken(getUserInfo);
};

const getAllPatientsList = async (params: {
  page: number | string | null;
  per_page: number;
  search?: string;
}) => {
  const apiUrl = setQueryParams(params, getAllPatientsName);
  return await ApiUtils.getWithToken(apiUrl);
};

const getAllPatientRoomData = async (filters: getAllPatientRoomFilterType) => {
  try {
    const apiUrl = setQueryParams(filters, admissionUrl);
    return await ApiUtils.getWithToken(apiUrl);
  } catch (error) {
    console.log(
      "Error occurs while getting the all patients room data:",
      error
    );
    throw error;
  }
};

const getAllIpRooms = async () => {
  return await ApiUtils.getWithToken(roomUrl);
};

const updateAdmission = async (id: number | string, data: any) => {
  try {
    return ApiUtils.putWithToken(`${admissionUrl}/${id}`, data);
  } catch (error) {
    console.log("Error Occurs while updating the admission:", error);
    throw error;
  }
};

const createAdmission = async (data: any) => {
  try {
    return await ApiUtils.postWithToken(admissionUrl, data);
  } catch (error) {
    console.log("Error Occurs while updatin the admission:", error);
    throw error;
  }
};

const deleteIpAdmissionById = async (id: any) => {
  return await ApiUtils.deleteWithToken(`${deleteIpAdmission}/${id}`);
};

const getConsultantsList = async () => {
  try {
    return ApiUtils.getWithToken(getConsultantsListUrl);
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
  }
};

const getIpDetailsById = async (id: number | string) => {
  try {
    return await ApiUtils.getWithToken(`${admissionUrl}/${id}`);
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
  }
};

const getIpTypes = async () => {
  try {
    return await ApiUtils.getWithToken(getCommonVariables);
  } catch (error) {
    console.log("Error Occurs while getting ip types:", error);
  }
};

const getIpStatus = async () => {
  try {
    return await ApiUtils.getWithToken(getAllIpStatus);
  } catch (error) {
    console.log("Error Occurs while getting ip status:", error);
  }
};

const getPatientDetails = async (
  id: string | null | any
): Promise<{
  data: PatientsUserTypes;
}> => {
  return (await ApiUtils.getWithToken(`${getPatientDetailsUrl}/${id}`)) as {
    data: PatientsUserTypes;
  };
};

const getInPatientAdmissionDetailById = async (
  id: string | null | any
): Promise<{
  data: any;
}> => {
  return (await ApiUtils.getWithToken(`${inPatientAdmissionDetail}/${id}`)) as {
    data: any;
  };
};

const getIpAdmissionRoomTransferHistoryDetail = async (id: any) => {
  return await ApiUtils.getWithToken(
    `${getIpRoomTransferHistoryDetails}/${id}`
  );
};

const getAllVitals = async () => {
  return await ApiUtils.getWithToken(getVitalsList);
};

const getAdmissionIpVitalsById = async (id: number | string) => {
  return await ApiUtils.getWithToken(`${getAdmissionIpVitals}${id}`);
};

const createAdmissionIpVitals = async (data: any) => {
  return await ApiUtils.postWithToken(createAdmissionIpVital, data);
};

const deleteAdmissionIpVitalById = async (
  vitalId: any,
  admissionId: any,
  data: any
) => {
  return await ApiUtils.deleteWithToken(
    `${deleteAdmissionIpVital}/${vitalId}/${admissionId}`,
    data
  );
};

const getAdmissionIpDischargeSummaryById = async (id: number | string) => {
  return await ApiUtils.getWithToken(`${getAdmissionIpDischargeSummary}${id}`);
};

const createAdmissionIpDischargeSummaryById = async (data: any) => {
  return await ApiUtils.postWithToken(createAdmissionIpDischargeSummary, data);
};

const updateAdmissionIpDischargeSummaryById = async (id: any, data: any) => {
  return await ApiUtils.putWithToken(
    `${updateAdmissionIpDischargeSummary}/${id}`,
    data
  );
};

const editAdmissionIpDischargeSummaryById = async (id: number | string) => {
  return await ApiUtils.getWithToken(
    `${editAdmissionIpDischargeSummary}/${id}`
  );
};

const deleteAdmissionIpDischargeSummaryById = async (id: any) => {
  return await ApiUtils.deleteWithToken(
    `${deleteAdmissionIpDischargeSummary}/${id}`
  );
};

const printAdmissionIpDischargeSummary = async (url: string) => {
  return await ApiUtils.getWithToken(url);
};

const getAdmissionIpInvestigationsById = async (id: number | string) => {
  return await ApiUtils.getWithToken(`${getAdmissionIpInvestigations}${id}`);
};

const createAdmissionIpInvestigationById = async (data: any) => {
  return await ApiUtils.postWithToken(createAdmissionIpInvestigation, data);
};

const deleteAdmissionIpInvestigationById = async (id: any) => {
  return await ApiUtils.deleteWithToken(
    `${deleteAdmissionIpInvestigation}/${id}`
  );
};

const getAdmissionIpTreatmentsById = async (id: number | string) => {
  return await ApiUtils.getWithToken(`${getAdmissionIpTreatments}${id}`);
};

const createAdmissionIpTreatmentById = async (data: any) => {
  return await ApiUtils.postWithToken(createAdmissionIpTreatment, data);
};

const deleteAdmissionIpTreatmentById = async (id: any) => {
  return await ApiUtils.deleteWithToken(`${deleteAdmissionIpTreatment}/${id}`);
};

const getAdmissionIpPrescriptionsById = async (id: number | string) => {
  return await ApiUtils.getWithToken(`${getAdmissionIpPrescriptions}${id}`);
};

const createAdmissionIpPrescriptionById = async (data: any) => {
  return await ApiUtils.postWithToken(createAdmissionIpPrescription, data);
};

const deleteAdmissionIpPrescriptionById = async (id: any) => {
  return await ApiUtils.deleteWithToken(
    `${deleteAdmissionIpPrescription}/${id}`
  );
};

const getAllPrintNotesIpTypes = async () => {
  return await ApiUtils.getWithToken(`${getPrintNotesIpTypes}`);
};

const getAllRooms = async () => {
  return await ApiUtils.getWithToken(`${getRoomsList}`);
};

const getAllBillingItems = async () => {
  return await ApiUtils.getWithToken(`${getBillingItemsList}`);
};

const getAllTreatmentItems = async (search: any) => {
  const apiUrl = setQueryParams(search, getTreatmentItemsList);

  return await ApiUtils.getWithToken(apiUrl);
};

const getAllUnits = async () => {
  return await ApiUtils.getWithToken(`${getAllUnitsList}`);
};

const getAllPxProductsList = async (search: any) => {
  const apiUrl = setQueryParams(search, getPxProductsList);

  return await ApiUtils.getWithToken(apiUrl);
};

const getProductsList = async (search: any) => {
  const apiUrl = setQueryParams(search, productsList);

  return await ApiUtils.getWithToken(apiUrl);
};

const getSequenceNo = async (departmentName: string) => {
  return await ApiUtils.getWithToken(`${getSequenceNumber}/${departmentName}`);
};

const updateSequenceNo = async (sequenceId: number, data: any) => {
  return await ApiUtils.putWithToken(
    `${updateSequenceNumber}/${sequenceId}`,
    data
  );
};

const createIpRoomTransferById = async (id: any, data: any) => {
  return await ApiUtils.postWithToken(`${createIpRoomTransfer}/${id}`, data);
};

const updateIpRoomTransferById = async (id: number | string, data: any) => {
  return await ApiUtils.putWithToken(`${updateIpRoomTransfer}/${id}`, data);
};

const getAllOtById = async (id: number | string) => {
  return await ApiUtils.getWithToken(`${getAdmissionIpOts}${id}`);
};

const getAllDepartments = async () => {
  return await ApiUtils.getWithToken(getAllDepartmentUrl);
};
const getAllPositions = async () => {
  return await ApiUtils.getWithToken(getAllPositionUrl);
};
const getAllSurgery = async () => {
  return await ApiUtils.getWithToken(getAllSurgeryUrl);
};
const createOperationTheatre = async (data: any) => {
  return await ApiUtils.postWithToken(createOperationTheatreUrl, data);
};

const editOperationTheatreById = async (id: number | string) => {
  return await ApiUtils.getWithToken(`${editOperationTheatreUrl}/${id}`);
};
const updateOperationTheatreById = async (id: any, data: any) => {
  return await ApiUtils.putWithToken(
    `${updateOperationTheatreUrl}/${id}`,
    data
  );
};

const deleteOperationTheatreById = async (id: any) => {
  return await ApiUtils.deleteWithToken(`${deleteOperationTheatreUrl}/${id}`);
};
const createOTJob = async (data: any) => {
  return await ApiUtils.postWithToken(createOtJobUrl, data);
};

const editOtJobById = async (id: number | string) => {
  return await ApiUtils.getWithToken(`${editOtJobUrl}/${id}`);
};

const doctorDetails = async () => {
  return await ApiUtils.getWithToken(getAllDoctorsName);
};

const getDoctorVisitDetailById = async (id: any) => {
  return await ApiUtils.getWithToken(`${getAdmissionIpDischargeSummary}${id}`);
};

const createDoctorVisitDetailById = async (data: any) => {
  return await ApiUtils.postWithToken(createAdmissionIpDischargeSummary, data);
};

const updateDoctorVisitDetailById = async (id: any, data: any) => {
  return await ApiUtils.putWithToken(
    `${updateAdmissionIpDischargeSummary}/${id}`,
    data
  );
};

const getDoctorVitals = async (doctorId: any) => {
  return await ApiUtils.getWithToken(`${getDoctorVitalsDetails}${doctorId}`);
};

export {
  getCommonVariablesDetails,
  getAppConfigurationDetails,
  getAllPatientsList,
  getAllPatientRoomData,
  getAllIpRooms,
  createAdmission,
  updateAdmission,
  getConsultantsList,
  getIpDetailsById,
  getIpTypes,
  getPatientDetails,
  getInPatientAdmissionDetailById,
  getAllVitals,
  getAdmissionIpVitalsById,
  createAdmissionIpVitals,
  deleteAdmissionIpVitalById,
  getAdmissionIpDischargeSummaryById,
  createAdmissionIpDischargeSummaryById,
  updateAdmissionIpDischargeSummaryById,
  editAdmissionIpDischargeSummaryById,
  deleteAdmissionIpDischargeSummaryById,
  printAdmissionIpDischargeSummary,
  getAdmissionIpInvestigationsById,
  createAdmissionIpInvestigationById,
  deleteAdmissionIpInvestigationById,
  getAdmissionIpTreatmentsById,
  createAdmissionIpTreatmentById,
  deleteAdmissionIpTreatmentById,
  getAdmissionIpPrescriptionsById,
  createAdmissionIpPrescriptionById,
  deleteAdmissionIpPrescriptionById,
  getAllPrintNotesIpTypes,
  getAllRooms,
  getAllBillingItems,
  getAllUnits,
  getAllPxProductsList,
  getProductsList,
  getUserDetails,
  getSequenceNo,
  updateSequenceNo,
  getIpStatus,
  getIpAdmissionRoomTransferHistoryDetail,
  createIpRoomTransferById,
  getAllTreatmentItems,
  updateIpRoomTransferById,
  deleteIpAdmissionById,
  getAllOtById,
  getAllDepartments,
  getAllPositions,
  getAllSurgery,
  createOperationTheatre,
  editOperationTheatreById,
  updateOperationTheatreById,
  deleteOperationTheatreById,
  createOTJob,
  editOtJobById,
  doctorDetails,
  getDoctorVisitDetailById,
  createDoctorVisitDetailById,
  updateDoctorVisitDetailById,
  getDoctorVitals,
};
