import React, { useState, useRef, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Typography, debounce } from "@mui/material";
import {
  Select,
  TextField,
  CheckBox,
  DatePickerWithTime,
} from "../../../components/basic";
import { RupeeIcon } from "../../../assets/icons";
import { PaymentsAndAdvancePic } from "../../../assets/images";
import {
  getAllPatientsList,
  getPayeeOptions,
} from "../../../services/paymentService";
import { InfiniteScroller } from "../../../components/basic";

const PaymentsTabs = ({
  tabValue,
  data,
  advanceData,
  onChange,
  cashReceivedError,
  paymentTypeOptions,
  excessAmount,
  onAdvanceChange,
  initialPatientList,
  isAdvance,
}: any) => {
  const [value, setValue] = useState("1");
  const [patientsList, setPatientsList] = useState<any>(
    initialPatientList || []
  );
  const [payeeOptions, setPayeeOptions] = useState<
    {
      id: number | string;
      name: any;
    }[]
  >([]);
  const [advancePaymentOptions, setAdvancePaymentOptions] =
    useState(paymentTypeOptions);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    tabValue(newValue);

    if (newValue === "2") {
      setAdvancePaymentOptions(
        paymentTypeOptions.filter(
          (ele: any) => ele.id !== "Advance" && ele.id !== "Excess"
        )
      );
    }
  };
  const patientsPagination = useRef(1);
  const searchPatientsPagination = useRef({ search: "", page: 1 });

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {

        return await getAllPatientsList({
          search: currentValue,
          page:searchPatientsPagination.current?.search === currentValue ? searchPatientsPagination.current?.page : 1, 
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: { value: number | string; label: number | string }) =>
              !prevOptions.some(
                (prevOption: {
                  value: number | string;
                  label: number | string;
                }) => prevOption.value === option.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page = searchPatientsPagination.current?.search === currentValue ? searchPatientsPagination.current?.page + 1: 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
        
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
     
    },
    300
  );

  const getAllPatients = async (currentValue: any, prevOptions: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList( currentValue,prevOptions,(response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
      

        const page = patientsPagination.current === Math.ceil(prevOptions.length / 10) ? patientsPagination.current : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: { value: number | string; label: number | string }) =>
              !prevOptions.some(
                (prevOption: {
                  value: number | string;
                  label: number | string;
                }) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination.current;
          patientsPagination.current = currentValue + 1;
          const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;

          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:",error);
      return {
        options:[] ,
        hasMore:false,
      };
    }
  };

  const styles = {
    selectGrid: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    textFieldGrid: {
      "& input::placeholder": {
        fontSize: "12px",
        color: "textPrimary.main",
      },
      "& .MuiFormLabel-root": {
        color: "greyScale.main",
      },
    },
    textFieldStyle: {
      height: "44px",
      fontSize: "6px",
      "& .MuiOutlinedInput-root": {
        height: "44px",
        borderRadius: "5px",
        paddingLeft: "14.4px",
        boxShadow: "none",
        borderColor: "var(--primary-border-color)",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
  };

  useEffect(() => {
    getPayeeOptions({ active: 1 })
      .then((res: any) => {
        if (res.data) {
          setPayeeOptions([{ id: 1, name: "Self" }, ...res?.data?.result]);
        }
      })
      .catch((err: any) => console.log(err));
  }, []);

  useEffect(() => {
    if (initialPatientList?.length > 0) {
      setPatientsList(initialPatientList);
    }
  }, [initialPatientList]);

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      onChange({ target: { name, value: newValue.validatedValue[0] } });
    }
  };

  useEffect(() => {
    if (isAdvance) {
      setValue("2");
    }
    setAdvancePaymentOptions(paymentTypeOptions);
  }, [isAdvance, paymentTypeOptions]);

  return (
    <Box
      sx={{
        borderRadius: "5px",
        border: "1px solid #e9e9e9",
        margin: "0px 3px",
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "greyScale.lighter" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label="Bill Payments"
              value="1"
              disableRipple
              sx={{
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "capitalize",
                borderRadius: "1px solid green",
              }}
            />
            <Tab
              label="Advance"
              value="2"
              disableRipple
              sx={{
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            />
          </TabList>
        </Box>
        <TabPanel
          value="1"
          sx={{ position: "relative", p: "0px 24px 24px 24px" }}
        >
          <Grid container columnGap={3} rowGap={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ mt: { xs: "25px" } }}
            >
              <InfiniteScroller
                loadOptions={getAllPatients}
                options={patientsList}
                handleOnChange={onChange}
                name={"patient_id"}
                label={"Select Patient"}
                value={data.patient_id}
                width="100%"
              />
              <Box
                sx={{
                  width: "275px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  mt: "6px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "11",
                    fontWeight: "500",
                    color: "primary.main",
                  }}
                >
                  Excess: {excessAmount?.excess || 0}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "11",
                    fontWeight: "500",
                    color: "#207DFF",
                  }}
                >
                  Advance: {excessAmount?.advance || 0}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <Select
                value={data.payee_id}
                placeholder="Select Payee"
                options={payeeOptions}
                label={"Payee"}
                name="payee_id"
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  height: "45px",
                  boxShadow: "none",
                  borderRadius: "5px",
                  borderColor: "var(--primary-border-color)",
                  ...styles.selectGrid,
                }}
                onChange={onChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ mt: { xs: "25px" } }}
            >
              <Box sx={{ ...styles.textFieldGrid, width: "100%" }}>
                <TextField
                  placeholder="Cash Received"
                  label={"Cash Received"}
                  name="cash_received"
                  endPlaceholderIcon={<RupeeIcon />}
                  width=""
                  formControlStyle={{ width: "100%" }}
                  sx={{
                    ...styles.textFieldStyle,
                  }}
                  value={data.cash_received}
                  onChange={onChange}
                  error={cashReceivedError}
                  disabled={
                    data.payment_type === "Advance" ||
                    data.payment_type === "Excess"
                  }
                />
                <Box
                  sx={{
                    width: "100%",
                    height: "20px",
                    mt: "6px",
                    "& .MuiFormControl-root": { height: "100%" },
                    "& .MuiBox-root": {
                      height: "100%",
                    },
                    "& .MuiFormControlLabel-root": {
                      height: "100%",
                    },
                    "& .MuiSvgIcon-root": {
                      width: "20px",
                      height: "20px",
                    },
                    "& .PrivateSwitchBase-input": {
                      height: "inherit",
                    },
                  }}
                >
                  <CheckBox
                    label={`Pay Full Amount (${data.cash_received})`}
                    checked={data?.is_pay_full_amount}
                    name={"is_pay_full_amount"}
                    onChange={(event: any) =>
                      onChange({
                        target: {
                          name: event?.target?.name,
                          value: event?.target?.checked,
                        },
                      })
                    }
                    sx={{
                      fontSize: "12",
                      fontWeight: "400",
                    }}
                    // disabled={!data?.cash_received}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              item
              sx={{
                width: { xs: "275px" },
                mt: { xs: "25px" },
              }}
            >
              <DatePickerWithTime
                label="Created Date"
                style={{
                  height: "37px",
                  borderRadius: "none",
                  width: "95%",
                }}
                formControlStyle={{ width: "100%" }}
                value={data?.date_created}
                name="date_created"
                format="DD/MM/YYYY hh:mm:ss A"
                borderColor="var(--primary-border-color)"
                onChange={(e: any, newValue: any) => {
                  handleDateChange(e, newValue, "date_created");
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <Select
                value={data.payment_type}
                name={"payment_type"}
                placeholder="Select Payment Type"
                options={paymentTypeOptions}
                label={"Payment Type"}
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  height: "45px",
                  boxShadow: "none",
                  borderRadius: "5px",
                  borderColor: "var(--primary-border-color)",
                  ...styles.selectGrid,
                }}
                onChange={onChange}
              />
            </Grid>
            {data?.payment_type === "Bank" && (
              <Grid
                item
                xs={12}
                sm={12}
                md={5.8}
                lg={2.8}
                sx={{ ...styles.textFieldGrid, mt: { xs: "25px" } }}
              >
                <TextField
                  placeholder="URN No"
                  label={"URN No"}
                  width=""
                  formControlStyle={{ width: "100%" }}
                  sx={{
                    ...styles.textFieldStyle,
                  }}
                  name="urn_number"
                  value={data.urn_number}
                  onChange={onChange}
                />
              </Grid>
            )}
            <Box
              sx={{
                position: "absolute",
                right: 0,
                bottom: "-5px",
                display: { xs: "none", lg: "block" },
              }}
            >
              <PaymentsAndAdvancePic />
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel
          value="2"
          sx={{ position: "relative", p: "0px 24px 24px 24px" }}
        >
          <Grid container columnGap={3} rowGap={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <InfiniteScroller
                loadOptions={getAllPatients}
                options={patientsList}
                handleOnChange={onAdvanceChange}
                name={"patient_id"}
                label={"Select Patient"}
                value={advanceData.patient_id}
                width="100%"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <Select
                value={advanceData.payee_id}
                placeholder="Select Payee"
                options={payeeOptions}
                label={"Payee"}
                name="payee_id"
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  height: "45px",
                  boxShadow: "none",
                  borderRadius: "5px",
                  borderColor: "var(--primary-border-color)",
                  ...styles.selectGrid,
                }}
                onChange={onAdvanceChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.textFieldGrid, mt: { xs: "25px" } }}
            >
              <TextField
                placeholder="Cash Received"
                label={"Cash Received"}
                name="cash_received"
                endPlaceholderIcon={<RupeeIcon />}
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  ...styles.textFieldStyle,
                }}
                value={advanceData.cash_received}
                onChange={onAdvanceChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{
                mt: { xs: "25px" },
              }}
            >
              <DatePickerWithTime
                label="Created Date"
                style={{
                  height: "37px",
                  borderRadius: "none",
                  width: "95%",
                }}
                formControlStyle={{ width: "100%" }}
                value={advanceData?.date_created}
                name="date_created"
                format="DD/MM/YYYY hh:mm:ss A"
                borderColor="var(--primary-border-color)"
                onChange={(e: any, newValue: any) => {
                  onAdvanceChange({
                    target: {
                      name: "date_created",
                      value: newValue.validatedValue[0],
                    },
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <Select
                value={advanceData.payment_type}
                name={"payment_type"}
                placeholder="Select Payment Type"
                options={advancePaymentOptions}
                label={"Payment Type"}
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  height: "45px",
                  boxShadow: "none",
                  borderRadius: "5px",
                  borderColor: "var(--primary-border-color)",
                  ...styles.selectGrid,
                }}
                onChange={onAdvanceChange}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  mt: "6px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "11",
                    fontWeight: "500",
                    color: "#207DFF",
                  }}
                >
                  Convenience Fee : {advanceData?.convenience_fee}
                </Typography>
              </Box>
            </Grid>
            {advanceData?.payment_type === "Bank" && (
              <Grid
                item
                xs={12}
                sm={12}
                md={5.8}
                lg={2.8}
                sx={{ ...styles.textFieldGrid, mt: { xs: "25px" } }}
              >
                <TextField
                  placeholder="URN No"
                  label={"URN No"}
                  width=""
                  formControlStyle={{ width: "100%" }}
                  sx={{
                    ...styles.textFieldStyle,
                  }}
                  name="urn_number"
                  value={advanceData.urn_number}
                  onChange={onAdvanceChange}
                />
              </Grid>
            )}
            <Box
              sx={{
                position: "absolute",
                right: 0,
                bottom: "-5px",
                display: { xs: "none", lg: "block" },
              }}
            >
              <PaymentsAndAdvancePic />
            </Box>
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
export default PaymentsTabs;

