import { Box } from "@mui/material";
import { Tabs } from "../../../components/shared";
import Products from "./Products";
import Suppliers from "./Suppliers";
import Tax from "./Tax";
import TestProducts from "./TestProducts";
import PermissionUtils from "../../../utils/PermissionUtils";

function LaboratoryMaster() {
  const { can } = PermissionUtils();

  const masterTabsList = [
    ...(can("lab_products_list")
      ? [{ children: <Products />, label: "Products" }]
      : []),
    ...(can("lab_products_list")
      ? [{ children: <Suppliers />, label: "Suppliers" }]
      : []),
    ...(can("lab_products_list") ? [{ children: <Tax />, label: "Tax" }] : []),
    ...(can("lab_products_list")
      ? [{ children: <TestProducts />, label: "Test Products" }]
      : []),
  ];

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Tabs data={masterTabsList} scrollButtons={true} />
    </Box>
  );
}

export default LaboratoryMaster;
