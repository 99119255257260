import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { billInfoConst } from "../../../../constants/displayText";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import { BillCancelImg } from "../../../../assets/images";
import { RouteUrls } from "../../../../constants/routes";
type InfoProps = {
  info: any;
  isTokenEnable?: boolean;
};

const Info = ({ info, isTokenEnable }: InfoProps) => {
  const { mobile, payee, paymentType, ReferredBy } = billInfoConst;
  const patientInformation = [
    {
      name: "Patient Name",
      value: `${info.patient_name}`,
    },
    {
      name: "Gender",
      value: info.gender,
    },
    {
      name: mobile,
      value: info.patient_mobile,
    },
    {
      name: "UHID",
      value: info.uhid,
    },
  ];
  const billingInformationOne = [
    {
      name: payee,
      value: info.payee_name,
    },
    {
      name: paymentType,
      value: info.bill_payment_type === 0 ? "Pay now" : "Credit",
    },
    {
      name: "IP No",
      value: info.ip_no ? (
        <a
          style={{ textDecoration: "unset" }}
          rel="noreferrer"
          target="_blank"
          href={`${RouteUrls.pharmacyUrl}${RouteUrls.salesBillListUrl}${RouteUrls.viewUrl}/${info.id}`}
        >
          {info.ip_no}
        </a>
      ) : (
        ""
      ),
    },
    {
      name: "OP No",
      value: info.op_no ? (
        <a
          style={{ textDecoration: "unset" }}
          rel="noreferrer"
          target="_blank"
          href={`${RouteUrls.pharmacyUrl}${RouteUrls.salesBillListUrl}${RouteUrls.viewUrl}/${info.id}`}
        >
          {info.op_no}
        </a>
      ) : (
        ""
      ),
    },
    isTokenEnable &&
      info?.op_no && {
        name: "Token No",
        value: info.token_no,
      },
  ];

  const billingInformationTwo = [
    {
      name: "Doctor Name",
      value: info.doctor_name,
    },
    {
      name: ReferredBy,
      value: info.refdoctor_name,
    },

    {
      name: "Date",
      value: info.date_created ? formatTwelveHoursTime(info.date_created) : "",
    },
    {
      name: "Bill No",
      value: info.invoice_no,
    },
  ];

  return (
    <Grid
      container
      sx={{
        width: "92vw",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
      }}
    >
      <Grid
        container
        sx={{
          width: { xs: "70vw", md: "30vw" },
          display: "flex",
          flexDirection: "column",
          border: 1,
          borderColor: "greyScale.lighter",
          borderRadius: "5px",
        }}
      >
        <Grid
          sx={{
            height: "50px",
            display: "flex",
            alignItems: "center",
            pl: 2,
            borderBottom: 1,
            borderColor: "greyScale.lighter",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "primary.main",
            }}
          >
            Patient Information
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            width: "100%",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: { xs: "space-around" },
            }}
          >
            <Grid
              item
              sx={{
                width: {
                  xs: "90%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                m: "15px 0px 10px 0px",
              }}
            >
              {patientInformation.map((row, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      width: {
                        xs: "100%",
                        lg: "100%",
                      },
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      maxHeight: "35px",
                      mb: "23px",
                      color: "textPrimary.main",
                    }}
                    className="row"
                  >
                    <Grid
                      sx={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        {row.name}
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        :
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        width: "70%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        pl: 3,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "greyScale.main",
                          width: "100%",
                          wordWrap: "break-word",
                        }}
                      >
                        {(row.name === "OP No" || row.name === "IP No") &&
                        row.value ? (
                          <a
                            style={{ textDecoration: "unset" }}
                            rel="noreferrer"
                            target="_blank"
                            href={""}
                          >
                            {row.value}
                          </a>
                        ) : (
                          row.value
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          width: { xs: "70vw", md: "60vw" },
          display: "flex",
          flexDirection: "column",
          border: 1,
          borderColor: "greyScale.lighter",
          borderRadius: "5px",
        }}
      >
        <Grid
          sx={{
            height: "50px",
            display: "flex",
            alignItems: "center",
            pl: 2,
            borderBottom: 1,
            borderColor: "greyScale.lighter",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "primary.main",
            }}
          >
            Billing Information
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            width: "100%",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            sx={{
              width: info?.is_cancel ? "45%" : "50%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: { xs: "space-around" },
            }}
          >
            <Grid
              item
              sx={{
                width: {
                  xs: "90%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                m: "15px 0px 10px 0px",
              }}
            >
              {billingInformationOne.map((row: any, index: any) => {
                return (
                  row?.name && (
                    <Grid
                      key={index}
                      sx={{
                        width: {
                          xs: "100%",
                          lg: "100%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Grid
                        sx={{
                          width: "30%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "13px", fontWeight: "600" }}
                        >
                          {row?.name}
                        </Typography>
                        <Typography
                          sx={{ fontSize: "13px", fontWeight: "600" }}
                        >
                          :
                        </Typography>
                      </Grid>
                      <Grid
                        sx={{
                          width: "70%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          pl: 3,
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ color: "greyScale.main" }}
                        >
                          {row?.value}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                );
              })}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: info?.is_cancel ? "45%" : "50%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: { xs: "space-around" },
            }}
          >
            <Grid
              item
              sx={{
                width: {
                  xs: "90%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                m: "15px 0px 10px 0px",
              }}
            >
              {billingInformationTwo.map((row, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      width: {
                        xs: "100%",
                        lg: "100%",
                      },
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      maxHeight: "35px",
                      mb: "23px",
                      color: "textPrimary.main",
                    }}
                    className="row"
                  >
                    <Grid
                      sx={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        {row.name}
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        :
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        width: "70%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        pl: 3,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color:
                            row.name === "OP No"
                              ? "primary.main"
                              : "greyScale.main",
                        }}
                      >
                        {row.value}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {info?.is_cancel ? (
            <Grid item sx={{ width: "5%" }}>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="100%"
                sx={{ marginTop: "10px", marginRight: "20px" }}
              >
                <Box>
                  <img src={BillCancelImg} />
                </Box>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Info;
