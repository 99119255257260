import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../../../../components/shared";
import { PrintIcon } from "../../../../assets/icons";
import { Link, useParams } from "react-router-dom";
import { getPatientPrescriptionsById } from "../../../../services/patientHistoryService";
import moment from "moment";
import { RouteUrls } from "../../../../constants/routes";

const Prescriptions = () => {
  const {
    ipSummaryUrl,
    inPatientsUrl,
    appointmentsUrl,
    appointmentsDetailsUrl,
  } = RouteUrls;

  const { id } = useParams();

  // data table variables

  const [totalCount, setTotalCount] = useState(null);
  const [initialCount, setInitialCount] = useState(null);
  const [limit, setLimit]: any = useState(null);
  const [allData, setAllData] = useState<any>([]);
  const [dataGridData, setDataGridData]: any = useState([]);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isDataGridExpanded, setIsDataGridExpanded] = useState(false);

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton>
          <PrintIcon onClick={() => ""} />
        </IconButton>
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.5,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          S.No
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sNo}
        </Typography>
      ),
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Date
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.date
            ? moment(row?.date, ["YYYY-MM-DD HH:mm:ss"]).format("DD/MM/YYYY")
            : null}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "presId",
      flex: 1.4,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Pres Id
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Link
          to={
            row?.type === "OP_PRESCRIPTION"
              ? `${appointmentsUrl}${appointmentsDetailsUrl}/${row?.id}?type=prescriptions`
              : `${inPatientsUrl}${ipSummaryUrl}/${row?.id}?type=prescriptions`
          }
          style={{
            width: "100%",
            height: "100%",
            textDecoration: "none",
            cursor: "pointer",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontSize={14} sx={{ color: "primary.main" }}>
            {row?.seq_no}
          </Typography>
        </Link>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "medicine",
      flex: 2,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Medicine
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.product_name}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "dosage",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Dosage
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.dosage}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    // {
    //   field: "actions",
    //   flex: 0.4,
    //   renderHeader: () => (
    //     <Grid
    //       style={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         width: "100%",
    //         height: "51px",
    //         cursor: "pointer",
    //       }}
    //     >
    //       <Typography variant="h5" fontSize={14}></Typography>
    //     </Grid>
    //   ),
    //   renderCell: ({ row }: any) => {
    //     return <TableActions row={row} />;
    //   },
    //   minWidth: 60,
    //   sortable: false,
    // },
  ];

  const toggleDataGrid = () => {
    setIsDataGridExpanded((prev) => !prev);
    if (totalCount && initialCount && totalCount > initialCount) {
      setLimit("all");
    }
  };

  const getPatientVisitsDetails = async () => {
    try {
      await getPatientPrescriptionsById(id, limit).then((result: any) => {
        const data = result?.data?.result;
        if (data) {
          setTotalCount(result?.data?.count);
          if (!limit) {
            setInitialCount(data?.length);
          }
          const modifiedData = data?.map((item: any, index: number) => ({
            ...item,
            sNo: index + 1,
          }));

          setAllData(modifiedData);
          setDataGridData(modifiedData);
        }
        setIsDataTableLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setIsDataTableLoading(false);
    }
  };

  useEffect(() => {
    if (isDataGridExpanded) {
      setDataGridData(allData);
    } else {
      const slicedData = allData.slice(0, initialCount);
      setDataGridData(slicedData);
    }
  }, [isDataGridExpanded]);

  useEffect(() => {
    if (id) {
      setIsDataTableLoading(true);
      if (limit) {
        getPatientVisitsDetails();
      } else {
        setTimeout(getPatientVisitsDetails, 1500);
      }
    }
  }, [limit]);

  return (
    <Grid
      sx={{
        width: { xs: "90%", md: "49%" },
        minWidth: { xs: "90%", md: "49%" },
        boxShadow: "3px 5px 15px 0px #23232314",
        borderRadius: "5px",
        height: "auto",
        maxHeight: isDataGridExpanded
          ? `${200 + allData?.length * 50}px`
          : `${200 + dataGridData?.length * 50}px`,
        m: "20px 0px",
        border: 1,
        borderColor: "greyScale.lighter",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "12px 16px 0px",
        }}
      >
        <Typography variant="h2" sx={{ color: "primary.main" }}>
          Prescriptions
        </Typography>
        <Button
          sx={{
            color: "textPrimary.main",
            backgroundColor: "#F4F4F4",
            p: "10px",
            fontWeight: "500",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
          onClick={toggleDataGrid}
        >
          View All
        </Button>
      </Grid>
      <Grid
        sx={{
          width: "100%",
          p: "12px 16px 18px",
        }}
      >
        <DataTable
          loading={isDataTableLoading}
          columns={columns}
          rows={dataGridData}
          getRowId={(row: any) => `${String(row?.sNo)}`}
          tableOnly={true}
        />
      </Grid>
    </Grid>
  );
};

export default Prescriptions;
