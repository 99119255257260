import {
  Badge,
  Box,
  useTheme,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { useState, type ChangeEvent } from "react";
import { FilterIcon, SearchIcon, ClearFilterIcon } from "../../../assets/icons";
import { CustomButton as Button, SearchBar } from "../../basic";

interface Props {
  title?: string;
  subtitle?: string;
  addButtonTitle?: string | null;
  addButtonTitle2?: string | null;
  editButtonTitle?: string | null;
  isShowExport?: boolean;
  searchEnabled?: boolean;
  onAddButtonClick?: () => void;
  onAddButtonClick2?: () => void;
  onUploadButtonClick?: (e: ChangeEvent<HTMLInputElement>) => void;
  onUpdateButtonClick?: () => void;
  onExportClick?: () => void;
  handleSearch?: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  flag?: boolean;
  isFilterEnabled?: boolean;
  handleFilterClick?: () => void;
  handleSearchClick?: () => void;
  isSearchClicked?: boolean;
  FilterElement?: any;
  isFilterOpen?: boolean;
  data?: [] | any;
  isSearchEnabled?: boolean;
  clearFilter?: any;
  searchOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  searchName?: string;
  searchValue?: any;
  appliedFilterCount?: number | string;
  customComponent?: any;
  filterGridSx?: {};
  clearSearch?: () => void;
}

const CustomFilter = ({
  title,
  addButtonTitle,
  addButtonTitle2,
  editButtonTitle,
  isShowExport = false,
  onAddButtonClick,
  onAddButtonClick2,
  onUpdateButtonClick,
  onUploadButtonClick,
  onExportClick,
  flag = false,
  isFilterEnabled = false,
  data,
  isSearchEnabled = false,
  clearFilter,
  searchOnChange,
  searchName,
  searchValue,
  appliedFilterCount,
  customComponent,
  filterGridSx,
  clearSearch,
}: Props): JSX.Element => {
  const currentTheme = useTheme();
  const colors = currentTheme.palette;
  const [filterClicked, setFilterClicked] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const styles = {
    mainBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    buttonGroupBox: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
      textAlign: "center",
    },
    editButton: {
      textTransform: "none",
      height: 40,
      width: 100,
      margin: 1,
      maxWidth: "max-content",
    },
    addButton: {
      textTransform: "none",
      height: 40,
      width: "100%",
      maxWidth: "max-content",
      margin: "0 10px 0",
    },
    filterStyles: {
      textAlign: "center",
      alignItems: "center",
      cursor: "pointer !important",
      paddingBottom: "10px",
      margin: 1,
      marginRight: 2,
    },
    searchStyles: {
      textAlign: "center",
      alignItems: "center",
      cursor: "pointer",
      padding: "1rem",
    },
    addBtnTwo: {
      height: 40,
      bgcolor: colors.primary.lighter,
      borderRadius: 2,
      textTransform: "none",
      margin: "0 10px 0",
    },
    filterIconBadge: { padding: "2px" },
  };

  const isMediumScreen = useMediaQuery(
    "(min-width:251px) and (max-width:900px)"
  );
  return (
    <>
      <Box sx={styles.mainBox}>
        {title && (
          <Typography variant="h1" sx={{ width: "500px", pl: 1 }}>
            {title}
          </Typography>
        )}
        {customComponent && customComponent}
        <Box sx={styles.buttonGroupBox}>
          {editButtonTitle && (
            <Button
              variant="contained"
              buttonText={editButtonTitle}
              sx={styles.editButton}
              backgroundColor="#207DFF"
              textColor="#FFFFFF"
              border={"none"}
              onClick={onUpdateButtonClick}
            />
          )}
          {addButtonTitle && !flag && (
            <Button
              variant="outlined"
              buttonText={addButtonTitle}
              textColor={"#207DFF"}
              onClick={onAddButtonClick}
              sx={styles.addButton}
              border={"1px solid #207DFF"}
            />
          )}
          {addButtonTitle2 && !flag && (
            <Button
              variant="contained"
              buttonText={addButtonTitle2}
              sx={styles.addBtnTwo}
              onClick={onAddButtonClick2}
            >
              {addButtonTitle2}
            </Button>
          )}
          {addButtonTitle && flag && (
            <Button
              variant="contained"
              buttonText={addButtonTitle}
              component="label"
            >
              Upload File
              <input
                onChange={onUploadButtonClick}
                hidden
                accept=".csv,text/xml"
                type="file"
              />
            </Button>
          )}
          {isShowExport && (
            <Button
              variant="contained"
              sx={{
                height: 40,
                bgcolor: colors.primary.lighter,
                borderRadius: 2,
                textTransform: "none",
                margin: "0 10px 0",
              }}
              onClick={onExportClick}
            >
              Export
            </Button>
          )}
          {isFilterEnabled && (
            <Box
              sx={styles.filterStyles}
              onClick={() => setFilterClicked(!filterClicked)}
            >
              <Badge
                badgeContent={appliedFilterCount}
                color="error"
                sx={styles.filterIconBadge}
              >
                <FilterIcon />
              </Badge>
            </Box>
          )}
          {isSearchEnabled && (
            <Box
              sx={styles.searchStyles}
              onClick={() => setSearchClicked(!searchClicked)}
            >
              <SearchIcon />
            </Box>
          )}
        </Box>
      </Box>
      {/* Developer use */}
      {/* {isMediumScreen && searchClicked && (
        <div
          style={{
            margin: "0 -12px",
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <SearchBar
            onChange={searchOnChange}
            searchName={searchName}
            handleSearchClick={() => setSearchClicked(!searchClicked)}
          />
        </div>
      )} */}
      {/* {isMediumScreen && filterClicked && data && data.length > 0 && (
        <div
          style={{
            width: "100%",
            marginBottom: "1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="filter-grid-parent"
        >
          <Grid className="filter-grid" container spacing={1}>
            {data.map((ele: any) => (
              <Grid item {...ele.gridProps}>
                {ele.children}
              </Grid>
            ))}
            <Grid
              item
              xl={1}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="h3"
                  color="primary.main"
                  onClick={clearFilter}
                >
                  Clear Filter
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      )} */}
      {/* {!isMediumScreen && ( */}
      <Grid container>
        {isMediumScreen && (
          <Grid item sm={12} md={3} xl={3} xs={12} alignItems={"flex-end"}>
            {searchClicked && (
              <div
                style={{
                  margin: "24px -7px 0",
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <SearchBar
                  value={searchValue}
                  sx={{ width: "95%" }}
                  onChange={searchOnChange}
                  searchName={searchName}
                  handleSearchClick={clearSearch}
                />
              </div>
            )}
          </Grid>
        )}
        <Grid
          item
          xl={searchClicked ? 10 : 12}
          sm={searchClicked ? 10 : 12}
          md={searchClicked ? 10 : 12}
        >
          {filterClicked && data && data.length > 0 && (
            <div
              style={{
                width: "100%",
                // marginBottom: "0.6rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
              className="filter-grid-parent"
            >
              <Grid
                className="filter-grid"
                container
                spacing={1}
                sx={{ ...filterGridSx }}
              >
                {data.map((ele: any, index: number) => (
                  <Grid item {...ele.gridProps} key={index}>
                    {ele.children}
                  </Grid>
                ))}
                <Grid
                  item
                  xl={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Box
                    sx={{
                      mt: {
                        xl: 2.7,
                        sm: 2.7,
                        xs: 2,
                      },
                      cursor: "pointer",
                    }}
                    color="primary.main"
                    onClick={clearFilter}
                  >
                    <ClearFilterIcon />
                  </Box>
                </Grid>
              </Grid>
            </div>
          )}
        </Grid>
        {!isMediumScreen && (
          <Grid item sm={12} md={2} xl={2} alignItems={"flex-end"}>
            {searchClicked && (
              <div
                style={{
                  margin: "24px -7px 0",
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <SearchBar
                  value={searchValue}
                  sx={{ width: "95%" }}
                  onChange={searchOnChange}
                  searchName={searchName}
                  handleSearchClick={clearSearch}
                />
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CustomFilter;
