import React from "react";
import {
  KegalsIcon,
  MeditationIcon,
  VrikshasanaIcon,
  YogaAsanas,
  BlackAndPregnantIcon,
} from "../../../../../assets/icons";

type FollowUpDetailsType = {
  id: number | string;
  patient_id: number | string;
  patient_name: number | string;
  antenatal_card_id: number | string;
  date_of_visit: number | string | null;
  trimester_week: number | string;
  vitals: [
    {
      vital_id?: string;
      vital_date?: string;
      vital_name?: string;
      vital_unit?: string;
      vital_value?: number | string;
    }
  ];
  findings: [];
  tests: [];
  scans: [];
  notes: string | null;
  next_review_date: string | null;
  trimester: string;
  suggestions: [];
};
export default function index({
  lastFollowUpDetails,
}: {
  lastFollowUpDetails: FollowUpDetailsType;
}) {
  const activitiesInfoList = [
    {
      name: "Kegals",
      activities: "Supta Vajrasana",
      Icon: () => <KegalsIcon />,
      time: "14 Mins",
    },
    {
      name: "Meditation",
      activities: "Sukhasana",
      Icon: () => <MeditationIcon />,
      time: "25 Mins",
    },
    {
      name: "Yoga asanas",
      activities: "Virabhadrasana",
      Icon: () => <YogaAsanas />,
      time: "30 Mins",
    },
    {
      name: "Yoga asanas",
      activities: "Vrikshasana",
      Icon: () => <VrikshasanaIcon />,
      time: "14 Mins",
    },
  ];
  return (
    <div>
      {activitiesInfoList?.map(
        (ele: {
          name: string;
          activities: string;
          Icon: any;
          time: string;
        }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              border: "1px solid #EDEDED",
              borderRadius: "7px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px",
              padding: "11px 12px 12px",
              margin: "12px 12px",
            }}
          >
            <div
              style={{
                margin:
                  ele.activities === "Vrikshasana" ? "0 32px 0" : "0 15px 0",
              }}
            >
              <ele.Icon />
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "4px",
                  alignItems: "center",
                }}
              >
                <BlackAndPregnantIcon />
                <div style={{ fontSize: "12px", fontWeight: 400 }}>
                  {ele.name}
                </div>
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: 500,
                  margin: "8px 0 8px",
                }}
              >
                {ele.activities}
              </div>
              <div
                style={{ color: "#9A9FA4", fontSize: "11px", fontWeight: 400 }}
              >
                {ele.time}
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
