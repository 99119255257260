import React, { useState, useMemo, useEffect } from "react";
import { AppointmentsDetailsPic } from "../../../../../../assets/images";
import { Grid, Typography, Box, Avatar, CircularProgress } from "@mui/material";
import RecentVisits from "./RecentVisits";
import Prescriptions from "./Prescriptions";
import Investigations from "./Investigations";
import Vitals from "./Vitals";
import Treatments from "./Treatments";
import { useParams } from "react-router-dom";
import DoctorsAdvice from "./DoctorsAdvice";
import DoctorVisits from "./DoctorVisits";
import OperationTheatre from "./OperationTheatre";
import BedHistory from "./BedHistory";

const IpHistory = () => {
  return (
    <Grid
      sx={{
        width: "100%",
        maxWidth: "100%",
        height: "auto",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
      }}
    >
      {/* developer comment */}
      {/* <DoctorsAdvice /> */}
      {/* <RecentVisits /> */}
      <Vitals />
      <Investigations />
      <Treatments />
      <Prescriptions />
      {/* <DoctorVisits /> */}
      <OperationTheatre />
      <BedHistory />
    </Grid>
  );
};

export default IpHistory;
