import { Box, Grid, Typography } from "@mui/material";
import { paymentViewInfoConst } from "../../../../constants/displayText";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  cancelPaymentBillById,
  getCardOptionDetails,
  getPaymentById,
  returnPaymentBillById,
} from "../../../../services/paymentService";
import moment from "moment";
import { formula } from "../../../../utils/CalculationUtils";
import { BillCancelImg } from "../../../../assets/images";
import { ConfirmationDialog, DataTable } from "../../../../components/shared";
import { GridColDef } from "@mui/x-data-grid";
import PaymentsFooter from "../PaymentsFooter";
import { RestartIcon, PrintIcon } from "../../../../assets/icons";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { PageLoader } from "../../../../components/basic";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { RouteUrls } from "../../../../constants/routes";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import { getPrintUrl } from "../../../../utils/GeneralUtils";

type GridRowData = Record<string, unknown>;
const { calculateConvenienceFee } = formula.payments;
const { payments, paymentsListUrl } = RouteUrls;

const {
  uhid,
  payee,
  cashReceived,
  createdDate,
  type,
  department,
  patientName,
  invoiceNumber,
  billDate,
  billAmount,
  discount,
  previouslyPaid,
  paidAmount,
  amountDue,
  remarks,
  returnText,
  convenienceFee,
  urnNumber,
} = paymentViewInfoConst;

export default function PaymentView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { can } = PermissionUtils();
  const [data, setData] = useState<any>({});
  const [cardDetails, setCardDetails] = useState({});
  const [totals, setTotals] = useState<any>({
    totalBillAmount: 0,
    totalAmountDue: 0,
    totalPaidAmount: 0,
    totalDiscount: 0,
    totalTds: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpenCancelModal, setIsOpenCancelModal] = useState<boolean>(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isReturnLoading, setIsReturnLoading] = useState(false);
  const [isOpenReturnModal, setIsOpenReturnModal] = useState<{
    isOpen: boolean;
    row: any;
    isBillCancel: boolean;
  }>({
    isOpen: false,
    isBillCancel: false,
    row: null,
  });

  const infoData = [
    {
      name: uhid,
      value: `${data?.uhid || ""} - ${data?.patient_name || ""}`,
    },
    {
      name: payee,
      value: data?.payee_name,
    },
    {
      name: cashReceived,
      value: data?.cash_received,
    },
    {
      name: createdDate,
      value: data?.date_created ? data?.date_created : null,
    },
    {
      name: type,
      value: data?.type,
    },
    // {
    //   name: excess,
    //   value: data?.excess,
    // },
    // {
    //   name: advance,
    //   value: data?.advance,
    // },
  ];

  const advanceInfoData = [
    {
      name: uhid,
      value: `${data?.uhid} - ${data?.patient_name}`,
    },
    {
      name: payee,
      value: data?.payee_name,
    },
    {
      name: cashReceived,
      value: data?.cash_received,
    },
    {
      name: createdDate,
      value: data?.date_created ? data?.date_created : null,
    },
    {
      name: type,
      value: data?.type,
    },
    ...(Number(data?.convenience_fee)
      ? [
          {
            name: convenienceFee,
            value: data?.convenience_fee,
          },
        ]
      : []),
    ...(data.type === "Bank" && data.urn_number
      ? [
          {
            name: urnNumber,
            value: data?.urn_number,
          },
        ]
      : []),
  ];

  const columns: GridColDef[] = [
    {
      field: "department",
      flex: 0.8,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {department}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{ color: row?.is_cancel ? "#F44336" : "textPrimary.main" }}
        >
          {row?.department}
        </Typography>
      ),
      sortable: false,
      minWidth: 150,
    },
    {
      field: "patient_name",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {patientName}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{ color: row?.is_cancel ? "#F44336" : "textPrimary.main" }}
        >
          {row?.patient_name}
        </Typography>
      ),
      sortable: false,
      minWidth: 150,
    },
    {
      field: "payee",
      flex: 0.5,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {payee}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{ color: row?.is_cancel ? "#F44336" : "textPrimary.main" }}
        >
          {row?.payee_name}
        </Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "invoice_number",
      flex: 0.8,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {invoiceNumber}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{ color: row?.is_cancel ? "#F44336" : "textPrimary.main" }}
        >
          {row?.bill_no}
        </Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "bill_date",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {billDate}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{
            color: row?.is_cancel ? "#F44336" : "textPrimary.main",
            fontWeight: row?.id ? 400 : 600,
          }}
        >
          {row?.bill_date}
        </Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "bill_amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {billAmount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{
            color: row?.is_cancel ? "#F44336" : "textPrimary.main",
            fontWeight: row?.id ? 400 : 600,
          }}
        >
          {row?.net_total_amt}
        </Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "discount",
      flex: 0.5,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {discount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{
            color: row?.is_cancel ? "#F44336" : "textPrimary.main",
            fontWeight: row?.id ? 400 : 600,
          }}
        >
          {row?.discount_value || 0} {row?.id ? row?.discount_type || "₹" : ""}{" "}
        </Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "previously_paid",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {previouslyPaid}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{
            color: row?.is_cancel ? "#F44336" : "textPrimary.main",
            fontWeight: row?.id ? 400 : 600,
          }}
        >
          {row?.previously_paid}
        </Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "amount_due",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {amountDue}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{
            color: row?.is_cancel ? "#F44336" : "textPrimary.main",
            fontWeight: row?.id ? 400 : 600,
          }}
        >
          {row?.balance}
        </Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "paid_amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {paidAmount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{
            color: row?.is_cancel ? "#F44336" : "textPrimary.main",
            fontWeight: row?.id ? 400 : 600,
          }}
        >
          {row?.paid_amt}
        </Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "remarks",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {remarks}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h6"
          sx={{ color: row?.is_cancel ? "#F44336" : "textPrimary.main" }}
        >
          {row?.remarks}
        </Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    ...(can("payment_refund") &&
    data?.type !== "Advance" &&
    data?.type !== "Excess"
      ? [
          {
            field: "return",
            flex: 1,
            renderHeader: () => (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "textPrimary.main",
                  }}
                >
                  {returnText}
                </Typography>
              </Grid>
            ),
            renderCell: ({ row }: any) => (
              <Typography
                variant="h6"
                sx={{
                  "& path": {
                    fill:
                      row?.is_cancel && !row?.is_return ? "#4caf50" : "#CCCCCC",
                    cursor:
                      row?.is_cancel && !row?.is_return ? "pointer" : "unset",
                  },
                }}
                onClick={
                  row?.is_cancel &&
                  !row?.is_return &&
                  !row?.is_advance &&
                  can("payment_refund") &&
                  row.id
                    ? () =>
                        setIsOpenReturnModal({
                          isOpen: true,
                          row,
                          isBillCancel: false,
                        })
                    : undefined
                }
              >
                {row.id ? (
                  <>
                    {row?.is_cancel &&
                    !row?.is_return &&
                    !row?.is_advance &&
                    can("payment_refund") ? (
                      <RestartIcon style={{ cursor: "pointer" }} />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </Typography>
            ),
            sortable: false,
            minWidth: 100,
          },
        ]
      : []),
  ];

  useEffect(() => {
    getCardOptionDetails()
      .then((res: any) => {
        if (res.data) {
          setCardDetails(res.data);
          // setData((prevState: any) => ({
          //   ...prevState,
          //   convenience_fee: calculateConvenienceFee(
          //     prevState,
          //     res?.data[0]?.convenience_fee_percentage || 0
          //   ),
          // }));
        }
      })
      .catch((err: any) => console.log(err));
  }, []);

  const getPaymentDetailsById = () => {
    setLoading(true);
    getPaymentById(id)
      .then((res: any) => {
        if (res.data) {
          setData({
            ...res.data,
            date_created: res?.data?.created_at
              ? formatTwelveHoursTime(res?.data?.created_at)
              : null,
            payment_type: res?.data?.type,
            excess_amount: res?.data?.total_excess,
            convenience_fee: res?.data?.convenience_fee,
            payments: res.data?.payments?.map((ele: any) => ({
              ...ele,
              discount_value: ele?.discount_value || 0,
              discount_type: ele?.discount_type || "%",
              discount_amount: ele?.discount_amount || 0,
              balance: (Number(ele?.balance) + Number(ele?.paid_amt)).toFixed(
                2
              ),
              bill_date: ele?.bill_date
                ? formatTwelveHoursTime(ele?.bill_date)
                : null,
              net_total_amt:
                Number(ele.net_total_amt) + Number(ele?.discount_amount) || 0,
              amount_due: (
                Number(ele?.balance) +
                Number(ele?.paid_amt) +
                Number(ele?.discount_amount) +
                Number(ele?.tds)
              ).toFixed(2),
              previously_paid: ele.is_cancel
                ? ele.due_amount
                : (Number(ele?.due_amount) - Number(ele?.paid_amt))?.toFixed(2),
            })),
          });

          const {
            totalBillAmount,
            totalAmountDue,
            totalPaidAmount,
            totalDiscount,
            totalTds,
          } = res?.data?.payments?.reduce(
            (totals: any, bill: any) => {
              totals.totalBillAmount +=
                Number(bill.net_total_amt) + Number(bill.discount_amount) || 0;
              totals.totalAmountDue +=
                Number(bill?.balance) + Number(bill?.paid_amt) || 0;
              totals.totalPaidAmount += Number(bill.paid_amt) || 0;
              totals.totalDiscount += Number(bill.discount_amount) || 0;
              totals.totalTds += Number(bill.tds) || 0;
              return totals;
            },
            {
              totalBillAmount: 0,
              totalAmountDue: 0,
              totalPaidAmount: 0,
              totalDiscount: 0,
              totalTds: 0,
            }
          );

          setTotals({
            totalBillAmount: Number(totalBillAmount)?.toFixed(2),
            totalAmountDue: Number(totalAmountDue)?.toFixed(2),
            totalPaidAmount: Number(totalPaidAmount)?.toFixed(2),
            totalDiscount: Number(totalDiscount)?.toFixed(2),
            totalTds: Number(totalTds)?.toFixed(2),
          });

          if (res.data?.payments?.length > 0) {
            setData((prevState: any) => ({
              ...prevState,
              payments: [
                ...prevState?.payments,
                {
                  bill_date: "Total",
                  net_total_amt: totalBillAmount,
                  discount_value: totalDiscount,
                  previously_paid: totalTds,
                  balance: totalAmountDue,
                  paid_amt: totalPaidAmount,
                },
              ],
            }));
          }
        }
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      getPaymentDetailsById();
    }
  }, [id]);

  const handleConfirmCancel = () => {
    if (isOpenCancelModal) {
      setIsCancelLoading(true);
      cancelPaymentBillById(id)
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setIsOpenCancelModal(false);
          setIsCancelLoading(false);
          navigate(`${payments}${paymentsListUrl}`);
        })
        .catch((err: any) => {
          console.log("err", err);
          setIsCancelLoading(false);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }

          if (err?.response?.status === 400 || err?.response?.status === 500) {
            getPaymentDetailsById();
            setIsOpenCancelModal(false);
          }
        });
    }
  };

  const handleConfirmReturn = () => {
    if (isOpenReturnModal?.isBillCancel) {
      setIsReturnLoading(true);
      returnPaymentBillById(id)
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setIsOpenReturnModal({
            isOpen: false,
            row: null,
            isBillCancel: false,
          });
          setIsReturnLoading(false);
          navigate(`${payments}${paymentsListUrl}`);
        })
        .catch((err: any) => {
          console.log("err", err);
          setIsReturnLoading(false);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }

          if (err?.response?.status === 400 || err?.response?.status === 500) {
            getPaymentDetailsById();
            setIsOpenReturnModal({
              isOpen: false,
              row: null,
              isBillCancel: false,
            });
          }
        });
    } else if (isOpenReturnModal?.row) {
      setIsReturnLoading(true);
      returnPaymentBillById(id, {
        type: isOpenReturnModal?.row?.bill_type,
        sub_payment_id: isOpenReturnModal?.row?.id,
      })
        .then((res: any) => {
          if (res) {
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          }
          setIsOpenReturnModal({
            isOpen: false,
            row: null,
            isBillCancel: false,
          });
          setIsReturnLoading(false);
          setData((prevState: any) => {
            const newState = { ...prevState };
            newState.payments = newState?.payments?.map((ele: any) => {
              if (ele.id === isOpenReturnModal?.row?.id) {
                return { ...ele, is_return: 1 };
              }

              return ele;
            });

            return newState;
          });
        })
        .catch((err: any) => {
          console.log("err", err);
          setIsReturnLoading(false);
          if (err?.response?.data?.errors) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.errors,
              })
            );
          }

          if (err?.response?.status === 400 || err?.response?.status === 500) {
            getPaymentDetailsById();
            setIsOpenReturnModal({
              isOpen: false,
              row: null,
              isBillCancel: false,
            });
          }
        });
    }
  };

  const handlePrintChange = (format?: string) => {
    let url = `payments/print/${id}`;

    if (format) {
      url = `${url}/${format}`;
    }
    const pdfUrl = getPrintUrl(url);
    const queryParams = new URLSearchParams({
      url: pdfUrl,
    }).toString();
    window.open(`${RouteUrls?.printUrl}?${queryParams}`, "_blank");
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <>
          {data?.is_advance ? (
            <Box
              sx={{
                // width: "100%",
                borderRadius: "5px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pl: 2,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    m: "15px 0px 10px 0px",
                  }}
                >
                  {advanceInfoData?.map((row, index) => {
                    return (
                      <Grid
                        sm={12}
                        xs={12}
                        md={4}
                        xl={4}
                        key={index}
                        sx={{
                          width: {
                            xs: "100%",
                            lg: "100%",
                          },
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          maxHeight: "35px",
                          mb: "23px",
                          color: "textPrimary.main",
                        }}
                        className="row"
                      >
                        <Grid
                          sx={{
                            width: "30%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "13px", fontWeight: "600" }}
                          >
                            {row.name}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "13px", fontWeight: "600" }}
                          >
                            :
                          </Typography>
                        </Grid>
                        <Grid
                          sx={{
                            width: "70%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            pl: 3,
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ color: "greyScale.main" }}
                          >
                            {row.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      color: "#000",
                      padding: "12px 30px",
                      background: "#F2F2F2",
                      margin: "0.3125rem 1px",
                      fontSize: ".75rem",
                      fontWeight: 400,
                      lineHeight: 1.428571,
                      cursor: "pointer",
                      borderRadius: "30px",
                      mr: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      "& path": {
                        stroke: "#333333",
                      },
                    }}
                    onClick={() => handlePrintChange()}
                  >
                    <PrintIcon style={{ color: "#000", marginRight: "10px" }} />
                    Print
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  // width: "100%",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pl: 2,
                  mb: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {data?.is_cancel ? (
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      <Box>
                        <img src={BillCancelImg} />
                      </Box>
                    </Box>
                  ) : null}
                  <Grid
                    container
                    sx={{
                      // width: {
                      //   xs: "90%",
                      // },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      m: "15px 0px 10px 0px",
                    }}
                  >
                    {infoData?.map((row, index) => {
                      return (
                        <Grid
                          sm={12}
                          xs={12}
                          md={4}
                          xl={3}
                          key={index}
                          sx={{
                            width: {
                              xs: "100%",
                              lg: "100%",
                            },
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            maxHeight: "35px",
                            mb: "23px",
                            color: "textPrimary.main",
                          }}
                          className="row"
                        >
                          <Grid
                            sx={{
                              width: "30%",
                              display: "flex",
                              alignItems: "center",
                              // justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              {row.name}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "13px", fontWeight: "600" }}
                            >
                              :
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              width: "70%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              pl: 3,
                            }}
                          >
                            <Typography
                              variant="h6"
                              sx={{ color: "greyScale.main" }}
                            >
                              {row.value}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid container>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      md={4}
                      xl={4}
                      sx={{
                        width: {
                          xs: "100%",
                          lg: "100%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Box
                        sx={{
                          width: "50%",
                          minWidth: "250px",
                          height: "100%",
                          backgroundColor: "#fbfafa",
                          padding: "15px",
                          borderRadius: "10px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "600",
                              marginRight: "10px",
                              maxWidth: "80px",
                              width: "60%",
                            }}
                          >
                            Excess:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "600",
                              width: "40%",
                              color: "#8A8A8A",
                            }}
                          >
                            {data?.excess?.balance || 0.0}
                          </Typography>
                        </Box>

                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "600",
                              marginRight: "10px",
                              maxWidth: "80px",
                              width: "60%",
                            }}
                          >
                            Advance:
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: "600",
                              width: "40%",
                              color: "#8A8A8A",
                            }}
                          >
                            {data?.advance?.balance || 0.0}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Box sx={{ margin: "0px 5px" }}>
                <DataTable
                  columns={columns}
                  rows={data?.payments || []}
                  getRowId={(row: GridRowData) => `${String(row.id)}`}
                  tableOnly={true}
                  customizedTable={true}
                />
              </Box>

              <Box mt={2}>
                <PaymentsFooter data={data} isView />
              </Box>
              <Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {can("payment_cancel") && !data?.is_cancel && (
                    <Box
                      sx={{
                        color: "#fff",
                        backgroundColor: "#f08f00",
                        borderColor: "#c27400",
                        boxShadow:
                          "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
                        padding: "12px 30px",
                        margin: "0.3125rem 1px",
                        fontSize: ".75rem",
                        fontWeight: 400,
                        lineHeight: 1.428571,
                        cursor: "pointer",
                        borderRadius: "30px",
                        mr: "10px",
                      }}
                      onClick={() => setIsOpenCancelModal(true)}
                    >
                      X Cancel
                    </Box>
                  )}
                  {can("payment_print") && !data?.is_cancel && (
                    <Box
                      sx={{
                        color: "#000",
                        padding: "12px 30px",
                        background: "#F2F2F2",
                        margin: "0.3125rem 1px",
                        fontSize: ".75rem",
                        fontWeight: 400,
                        lineHeight: 1.428571,
                        cursor: "pointer",
                        borderRadius: "30px",
                        mr: "10px",
                        "& path": {
                          stroke: "#333333",
                        },
                      }}
                      onClick={() => handlePrintChange("full")}
                    >
                      <PrintIcon
                        style={{ color: "#000", marginRight: "10px" }}
                      />
                      Full Print
                    </Box>
                  )}
                  {can("payment_print") && !data?.is_cancel && (
                    <Box
                      sx={{
                        color: "#000",
                        background: "#F2F2F2",
                        padding: "12px 30px",
                        margin: "0.3125rem 1px",
                        fontSize: ".75rem",
                        fontWeight: 400,
                        lineHeight: 1.428571,
                        cursor: "pointer",
                        borderRadius: "30px",
                        "& path": {
                          stroke: "#333333",
                        },
                      }}
                      onClick={() => handlePrintChange()}
                    >
                      <PrintIcon
                        style={{ color: "#000", marginRight: "10px" }}
                      />
                      Print
                    </Box>
                  )}
                  {can("payment_refund") &&
                  data?.is_cancel &&
                  data?.type !== "Advance" &&
                  data?.type !== "Excess" &&
                  !data.is_return ? (
                    <Box
                      sx={{
                        color: "#fff",
                        backgroundColor: "#00aec5",
                        borderColor: "#008697",
                        boxShadow:
                          "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
                        padding: "12px 30px",
                        margin: "0.3125rem 1px",
                        fontSize: ".75rem",
                        fontWeight: 400,
                        lineHeight: 1.428571,
                        cursor: "pointer",
                        borderRadius: "30px",
                        mr: "10px",
                      }}
                      onClick={() =>
                        setIsOpenReturnModal({
                          isOpen: true,
                          row: null,
                          isBillCancel: true,
                        })
                      }
                    >
                      Return
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <ConfirmationDialog
                open={isOpenCancelModal}
                title={
                  <div>
                    <p>Do you want to cancel the bill?</p>{" "}
                    <p>
                      Paid amount {totals?.totalPaidAmount} will be added to
                      patient/payee excess amount
                    </p>
                  </div>
                }
                confirmText="Yes"
                handleClick={handleConfirmCancel}
                onClose={() => setIsOpenCancelModal(false)}
                loading={isCancelLoading}
              />
              <ConfirmationDialog
                open={isOpenReturnModal?.isOpen}
                title={
                  <div style={{ width: "100%" }}>
                    {isOpenReturnModal?.isBillCancel ? (
                      <p>Do you want to return this payment?</p>
                    ) : (
                      <div style={{ width: "100%" }}>
                        <p>Do you want to return this payment?</p>
                        <p>
                          Paid amount {isOpenReturnModal?.row?.paid_amt} want's
                          to refund. Do you like to proceed?
                        </p>
                      </div>
                    )}
                  </div>
                }
                confirmText="Yes"
                handleClick={handleConfirmReturn}
                onClose={() =>
                  setIsOpenReturnModal({
                    isOpen: false,
                    row: null,
                    isBillCancel: false,
                  })
                }
                loading={isReturnLoading}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
}
