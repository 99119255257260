import React from "react";
import {
  TableCell,
  TableRow,
  Popper,
  Box,
  List,
  Grid,
  Typography,
} from "@mui/material";
import {
  TextField,
  AutocompleteWithTable,
  Select,
} from "../../../../components/basic";
import { DeleteIcon, DisabledDelete } from "../../../../assets/icons";
import { formattedDateWithSlash } from "../../../../utils/DateTimeFormatUtils";
type ProductRowProps = {
  styles?: any;
  row?: any;
  handleDelete: (id: number) => void;
  allRows?: any;
  handleInputChange: (e: any, id: number | string | null | any) => void;
  handleAutoCompleteChange: (
    newValue: any,
    index: number | string | null | any,
    name: string
  ) => false | void;
  index?: number;
  rowDisables?: any;
  formErrors?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    row,
    allRows,
    handleInputChange,
    handleAutoCompleteChange,
    handleDelete,
    index,
    rowDisables,
    formErrors,
  }: ProductRowProps) => {
    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            height: "40px",
            p: "10px 0px",
            "& .MuiPaper-root": {
              width: "450px",
              maxHeight: "180px",
              overflow: "hidden",
            },
          }}
        >
          <Box
            sx={{
              width: "450px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              height: "43px",
            }}
          >
            <Typography
              sx={{
                width: "150px",
                height: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "center",
                pl: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Product Name
            </Typography>
            <Typography
              sx={{
                width: "150px",
                height: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "center",
                pl: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Batch
            </Typography>
            <Typography
              sx={{
                width: "150px",
                height: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "center",
                pl: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Exp.Date
            </Typography>
            <Typography
              sx={{
                width: "150px",
                height: "40px",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "center",
                pl: "5%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Stock
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="left"
          sx={{
            width: "12%",
            "& .MuiAutocomplete-root": {
              height: "31px",
            },
            "& .MuiInputBase-root": {
              height: "32px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
            },
          }}
        >
          <AutocompleteWithTable
            placeholder="Select Product"
            name="product_name"
            value={row?.product_name}
            onChange={(e: any, newValue: any) =>
              handleAutoCompleteChange(newValue, index, "product_name")
            }
            options={allRows}
            renderOption={(props: any, option: any) => {
              return (
                <List
                  {...props}
                  key={option.ID}
                  sx={{
                    width: "450px",
                    borderRadius: 0,
                  }}
                >
                  <Box
                    className="list-box"
                    display="flex"
                    justifyContent="space-between"
                    width="450px"
                  >
                    <Typography
                      variant="h6"
                      sx={{ width: "30%", textAlign: "center", pl: "5%" }}
                    >
                      {option.product_name}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ width: "30%", textAlign: "center", pl: "5%" }}
                    >
                      {option.batch_no}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ width: "30%", textAlign: "center", pl: "5%" }}
                    >
                      {formattedDateWithSlash(option.expires_at)}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ width: "30%", textAlign: "center", pl: "5%" }}
                    >
                      {option.stock_qty}
                    </Typography>
                  </Box>
                </List>
              );
            }}
            getOptionLabel={(option: any) => option.product_name}
            sx={{
              width: "100%",
              height: "34px",
              "& .MuiFormControl-root": {
                height: "32px",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "& .MuiInputBase-root .MuiInputBase-input": {
                padding: 0,
                fontSize: "12px",
                fontWeight: "400",
              },
            }}
            PopperComponent={PopperMy}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "8%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="batch_no"
            value={row.batch_no}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="stock"
            value={row.stock}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "8%",
            backgroundColor: rowDisables[index as number].inc_qty
              ? "var(--table-cell-disabled-background)"
              : "",
          }}
        >
          <TextField
            name="inc_qty"
            value={row.inc_qty}
            disabled={rowDisables[index as number].inc_qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "8%",
            backgroundColor: rowDisables[index as number]?.dec_qty
              ? "var(--table-cell-disabled-background)"
              : "",
            border: formErrors[index as number]?.dec_qty
              ? "2px solid red !important"
              : "",
          }}
        >
          <TextField
            name="dec_qty"
            value={row.dec_qty}
            disabled={rowDisables[index as number].dec_qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "10%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="price"
            value={row.price}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            backgroundColor: "var(--table-cell-disabled-background)",
            width: "7%",
          }}
        >
          <TextField
            name="discount"
            value={row.discount}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "7%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="tax"
            value={row.tax}
            disabled={true}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>

        <TableCell
          align="left"
          sx={{
            width: "9%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="inc_amount"
            value={row.inc_amount}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            backgroundColor: "var(--table-cell-disabled-background)",
            width: "9%",
          }}
        >
          <TextField
            name="dec_amount"
            value={row.dec_amount}
            disabled={true}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>

        <TableCell
          className="last-cell"
          align="right"
          sx={{
            width: "10%",
            position: "relative",
          }}
        >
          <Grid
            className="select-grid"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "200px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
                borderWidth: 0,
                height: "34px",
                maxHeight: "34px",
                p: 0,
              },

              "& .MuiInputBase-root": {
                padding: "0px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              },

              "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "primary.main",
                  borderWidth: "1px",
                },

              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderWidth: 0,
                },
            }}
          >
            <Select
              label=""
              options={[
                { id: "Returned", name: "Returned" },
                {
                  id: "Stolen",
                  name: "Stolen",
                },
                {
                  id: "Wrong Entry",
                  name: "Wrong Entry",
                },
              ]}
              name="reason"
              value={row.reason}
              onChange={(e: any) => handleInputChange(e, index)}
              width="inherit"
              sx={{
                width: "200px",
                height: "34px",
                borderRadius: "0px",
                "& .MuiOutlinedInput-input": {
                  padding: "0px 12px",
                },
              }}
            />
          </Grid>
          <Grid className="deleteIconGrid">
            {index === 0 ? (
              <DisabledDelete className="delete-icon" />
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => handleDelete(index as number)}
              />
            )}

            <TextField
              width="0px"
              name="discountAmount"
              value={row.discountAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
            <TextField
              width="0px"
              name="taxAmount"
              value={row.taxAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
