import { Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import {
  CheckBox,
  DatePickerWithTime,
  PageLoader,
} from "../../../components/basic";
import { LabReportImg, LaboratoryTestTubeImg } from "../../../assets/images";
import { useEffect, useState } from "react";
import CommonEditableTable from "./CommonEditableDataTable";
import {
  getLabReportDetailsByRecordId,
  getLaboratoryProductOptions,
} from "../../../services/laboratoryService";
import ChemicalReagentModal from "./ChemicalReagentModal";

const styles = {
  reportDetailStyle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "35px",
  },
  labelStyle: {
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "15.73px",
    textAlign: "left",
    color: "textPrimary.main",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  valueStyle: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "15.73px",
    textAlign: "left",
    color: "textPrimary.main",
    height: "100%",
    display: "flex",
    alignItems: "center",
    opacity: "0.6",
  },
  headerStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
    borderBottom: "1px solid var(--table-border)",
    borderRight: "1px solid var(--table-border)",
    padding: "12px 12px 12px 20px",
    marginLeft: "8px",
  },
  tableContentStyle: {
    padding: "12px 12px 12px 20px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "11px",
    textAlign: "left",
    color: "textPrimary.main",
  },
};

const ViewLabBill = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [billDetails, setBillDetails] = useState<any>({});
  const [labBills, setLabBills] = useState<any>([]);
  const [isOpenModal, setIsOpenModal] = useState<any>({
    isOpen: false,
    row: null,
    index: null,
  });
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const handleCheck = (id: number | string) => {
    setSelectedRows((prevState) => {
      const newState: any = [...prevState];
      const selectedIndex = newState.findIndex((ele: any) => ele === id);

      if (selectedIndex !== -1) {
        newState.splice(selectedIndex, 1);
      } else {
        newState.push(id);
      }

      return newState;
    });
  };
  const handleCheckAll = () => {
    setSelectedRows((prevState: any) => {
      let newState = [];
      if (prevState.length !== labBills.length) {
        newState = labBills.map((ele: any) => ele.id);
      }

      return newState;
    });
  };

  const columns = {
    headers: [
      {
        header: "S.No",
        textAlignment: "left",
        headerChildren: () => (
          <CheckBox
            checked={selectedRows?.length === labBills?.length}
            onChange={handleCheckAll}
          />
        ),
        width: 5,
        name: "s.no",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => {
          return (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CheckBox
                label={index + 1 > 9 ? index + 1 : `0${index + 1}`}
                checked={selectedRows?.includes(row.id)}
                onChange={() => handleCheck(row.id)}
              />
            </Box>
          );
        },
      },
      {
        header: "Heading",
        textAlignment: "left",
        width: 15,
        name: "name",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            <Typography>{row?.name}</Typography>
          </Box>
        ),
      },
      {
        header: "Test Name",
        textAlignment: "left",
        width: 20,
        name: "test_name",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            <Typography>{row?.test_name}</Typography>
          </Box>
        ),
      },
      {
        header: "Results",
        textAlignment: "left",
        width: 10,
        name: "result",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            <Typography>{row?.result}</Typography>
          </Box>
        ),
      },
      {
        header: "Reading Value",
        textAlignment: "left",
        width: 15,
        name: "reading_value",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            <Typography>{row?.reading_value}</Typography>
          </Box>
        ),
      },
      {
        header: "Units",
        textAlignment: "left",
        width: 10,
        name: "units",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            <Typography>{row?.unit}</Typography>
          </Box>
        ),
      },
      {
        header: "Reference Range",
        textAlignment: "left",
        width: 20,
        name: "ref_range",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            {row?.lab_test_reference_ranges?.map(
              (refRange: any, idx: number) => (
                <Typography key={idx}>
                  {refRange?.age_group?.age_group_name}: {refRange?.min_value} -{" "}
                  {refRange?.max_value}
                </Typography>
              )
            )}
          </Box>
        ),
      },
      {
        header: "Action",
        textAlignment: "left",
        width: 5,
        name: "",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => setIsOpenModal({ isOpen: true, row, index })}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={LaboratoryTestTubeImg}
              />
            </Box>
          </Box>
        ),
      },
    ],
  };

  const getFormattedProducts = async (
    product: any,
    test_id: number | string
  ) => {
    const res: any = await getLaboratoryProductOptions({ search: "", test_id });
    if (res.data) {
      if (product) {
        const products = product?.map((ele: any) => ({
          ...ele,
          sales_id: ele.id,
          product_test_id: ele?.lab_test_id,
          product: ele.product_id,
          batch_id: ele.batch_id,
          product_id:
            typeof ele?.product_id === "number" ||
            typeof ele.product_id === "string"
              ? {
                  product_id: ele.product_id,
                  batch_id: ele.batch_id,
                  batch_no: ele.batch_no,
                  stock_qty: ele.qty,
                  product_name: ele.product_name,
                  id: ele.product_id,
                }
              : ele.product_id,
        }));

        return products;
      } else {
        return [
          {
            product_id: null,
            batch_no: "",
            qty: "",
            is_retest: "",
          },
        ];
      }
    }
  };

  const getLabReportEditDetails = async (id: number | string) => {
    setIsLoading(true);

    getLabReportDetailsByRecordId(id)
      .then(async (res: any) => {
        if (res.data) {
          const { patient_tests } = res.data;
          setBillDetails({
            ...res.data.bill,
            date_created: res.data?.bill?.date_created,
            sample_date:
              patient_tests?.length > 0 ? patient_tests[0]?.sample_date : null,
            report_date:
              patient_tests?.length > 0 ? patient_tests[0]?.date_created : null,
          });
          const formattedLabBills = await Promise.all(
            patient_tests.map(async (ele: any) => ({
              ...ele,
              report_id: ele.id,
              product: await getFormattedProducts(
                ele.product,
                ele?.lab_test_id
              ),
            }))
          );
          setLabBills(formattedLabBills);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (id) {
      getLabReportEditDetails(id);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Box>
          <Grid container columnGap={3} rowGap={3} mb={3}>
            <Grid item xs={12} md={5.8} xl={5.8}>
              <Box
                style={{
                  width: "100%",
                  boxShadow: "3px 5px 15px 0px #23232314",
                  borderRadius: "10px",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    padding: "10px 20px",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      lineHeight: "18.15px",
                      textAlign: "left",
                      color: "primary.main",
                    }}
                  >
                    Report Details
                  </Typography>
                </Box>
                <Grid container p="15px 20px">
                  <Grid item xs={12} sm={6} md={6} xl={6}>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>Name</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.patients_details?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>UHID</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.patients_details?.patient_id}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>Doctor</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.doctor_name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>Mobile</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.patients_details?.mobile}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} xl={6}>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>
                          Bill Date
                        </Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.date_created}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>Bill No</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.bill_no}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>
                          Sample Date
                        </Typography>
                      </Box>
                      <Box width={"60%"}>
                        <DatePickerWithTime
                          style={{
                            height: "20px",
                            borderRadius: "none",
                            width: "90%",
                          }}
                          value={billDetails?.sample_date}
                          format="DD/MM/YYYY hh:mm:ss A"
                          isDisabled
                          isEditable={false}
                        />
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>
                          Report Date
                        </Typography>
                      </Box>
                      <Box width={"60%"}>
                        <DatePickerWithTime
                          style={{
                            height: "20px",
                            borderRadius: "none",
                            width: "90%",
                          }}
                          format="DD/MM/YYYY hh:mm:ss A"
                          value={billDetails?.report_date}
                          isDisabled
                          isEditable={false}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={5.8} xl={5.8}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={LabReportImg}
                />
              </Box>
            </Grid>
          </Grid>
          <CommonEditableTable
            columns={columns}
            rows={labBills}
            tableWidth={"100%"}
            minTableWidth={"100%"}
          />
          {isOpenModal.isOpen && (
            <ChemicalReagentModal
              isView
              isOpen={isOpenModal.isOpen}
              row={isOpenModal.row}
              index={isOpenModal.index}
              onClose={() =>
                setIsOpenModal({ isOpen: false, row: null, index: null })
              }
            />
          )}
        </Box>
      )}
    </>
  );
};

export default ViewLabBill;
