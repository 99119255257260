import React from "react";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
  Typography,
  Button,
  Grid,
  Link,
  CircularProgress,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { TokenImg } from "../../../assets/images";
import { ToolTips } from "../../../components/basic";

type BookingTokensProps = {
  tokenList?: any;
  checkStatus?: boolean;
  loading?: boolean;
};

const BookingTokens = ({
  tokenList,
  checkStatus,
  loading,
}: BookingTokensProps) => {
  const styles = {
    boxStyle: {
      width: { xs: "100%" },
      bgcolor: "background.paper",
      mt: "30px",
    },
    tabStyle: {
      textTransform: "none",
      fontWeight: 600,
      fontSize: "14px",
      fontFamily: ["Inter", "sans-serif"].join(","),
    },
    tabsStyle: {
      borderRadius: "3px",
      mb: "10px",
      boxShadow: "3px 5px 15px 0px rgba(35, 35, 35, 0.08)",
      color: "#207DFF",
    },
    buttonStyle: {
      width: "108px",
      height: " 49px",
      backgroundColor: "#17CF9D",
      m: "0 23px 20px 0",
      border: "1px solid primary.main",
      fontSize: "15px",
    },
  };

  const [value, setValue] = React.useState("1");
  const [selectedBox, setSelectedBox] = React.useState<number | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleButtonClick = (id: number) => {
    if (selectedBox === id) {
      setSelectedBox(null);
    } else {
      setSelectedBox(id);
    }
  };

  return (
    <TabContext value={value}>
      <Box
        sx={{
          ...styles.boxStyle,
        }}
      >
        <Grid
          item
          sx={{ boxShadow: "3px 5px 15px 0px rgba(35, 35, 35, 0.08) " }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            sx={{
              ...styles.tabsStyle,
            }}
          >
            <Tab
              label="Tokens"
              value="1"
              disableRipple
              sx={{ ...styles.tabStyle }}
            />
          </Tabs>
        </Grid>

        <TabPanel value="1" sx={{ p: "0 0 0 3px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "45vh",
              maxHeight: "auto",
            }}
          >
            {loading ? (
              <Grid
                sx={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  justifyContent: "center",
                  height: "40vh",
                  background: "transparent",
                }}
              >
                <CircularProgress />
              </Grid>
            ) : checkStatus ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 5,
                  gap: 1,
                }}
              >
                <Box>
                  <Typography
                    variant="h2"
                    sx={{
                      display: "flex",
                      backgroundColor: "secondary.main",
                      color: "backgroundPrimary.main",
                      justifyContent: "center",
                      alignItems: "center",
                      p: "10px 15px",
                      width: "500px",
                    }}
                  >
                    An Appointment has been already booked for this patient
                  </Typography>
                </Box>
                <Link underline="none" sx={{ fontWeight: 600, fontSize: 15 }}>
                  View details
                </Link>
                <Typography variant="h3">
                  Please call hospital for any help.
                </Typography>
                <Typography variant="h3">Helpline: ( 9994502102 )</Typography>
              </Box>
            ) : tokenList.length === 0 ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "45vh",
                  }}
                >
                  <TokenImg />
                  <Typography variant="h5" color="greyScale.main">
                    "No Tokens Issued for this day"
                  </Typography>
                </Box>
              </>
            ) : (
              <Box sx={{ height: "45vh" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: { xs: "center", lg: "left" },
                    mt: 3,
                  }}
                >
                  {tokenList
                    .sort((a: any, b: any) => a.id - b.id)
                    .map((token: any, index: any) => (
                      <ToolTips
                        key={index}
                        PatientName={token.Name}
                        personalId={token.link_appoint_id}
                        phone={token.Mobile}
                        consultationFor={token.purposename}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            boxShadow: "none",
                            borderRadius: "5px",
                            fontWeight: 400,
                            textTransform: "none",
                            "&.MuiLoadingButton-loading": {
                              backgroundColor: "primary.light",
                              color: "white",
                            },
                            "&:hover": {
                              backgroundColor: "#17CF9D",
                              color: "initial",
                              boxShadow: "none",
                            },
                            ...styles.buttonStyle,
                          }}
                        >
                          <Typography
                            variant="h3"
                            color="backgroundPrimary.main"
                            sx={{ fontWeight: 500 }}
                          >
                            {`Token-${index + 1}`}
                          </Typography>
                        </Button>
                      </ToolTips>
                    ))}
                </Box>
              </Box>
            )}
          </Box>
        </TabPanel>
      </Box>
    </TabContext>
  );
};

export default BookingTokens;
