import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import {
  AutoComplete,
  Select,
  TextField,
  DatePicker,
} from "../../../../../components/basic";
import { DeleteIcon } from "../../../../../assets/icons";

type RowProps = {
  styles?: any;
  row?: any;
  billId?: any;
  handlePrescriptionDelete: (id: number) => void;
  handleInputChange: (e: any, prescriptionId: number | null) => void;
  handleDateChange: (prescriptionId: number, newValue: any, name: any) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    prescriptionId: number | null,
    name: string
  ) => false | undefined;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: any,
    prescriptionId: number | null,
    name: string
  ) => void;
  index: number;
  itemOptions: any;
  unitsOptions: any;
  freqOptions: any;
  freqTimeTypeOptions: any;
  durationTypeOptions: any;
  specificDaysOptions: any;
  intervalOptions: any;
  isAutocompleteOptionsLoading?: boolean;
  validationErrors?: any;
};

const Row = React.memo(
  ({
    styles,
    index,
    row,
    handleInputChange,
    handleDateChange,
    handleAutoCompleteChange,
    handleAutoCompleteInputChange,
    handlePrescriptionDelete,
    itemOptions,
    unitsOptions,
    freqOptions,
    freqTimeTypeOptions,
    durationTypeOptions,
    specificDaysOptions,
    intervalOptions,
    isAutocompleteOptionsLoading,
    validationErrors,
  }: RowProps) => {
    const durationTypes: any = {
      specific_days: "specific_days",
      interval_days: "interval_days",
      custom_date: "custom_date",
    };

    return (
      <Grid
        sx={{
          width: "auto",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          mt: index === 0 ? "0px" : "10px",
        }}
      >
        <AutoComplete
          label={index === 0 ? "Product" : undefined}
          name="product_id"
          value={row.product_id}
          inputValue={row.product_id_input}
          options={itemOptions}
          noOptionsText={
            isAutocompleteOptionsLoading ? "...Loading" : "No Options"
          }
          onChange={(e: any, newValue: any) =>
            handleAutoCompleteChange(e, newValue, row.id, "product_id")
          }
          onInputChange={(e: any, newValue: any) =>
            handleAutoCompleteInputChange(
              e,
              newValue,
              row.id,
              "product_id_input"
            )
          }
          helperText={
            validationErrors?.prescriptions[row.id]?.product_id
              ? validationErrors?.prescriptions[row.id]?.product_id
              : true
          }
          width="200px"
          sx={{
            width: {
              xs: "200px",
              sm: "200px",
              md: "200px",
            },
            boxSizing: "border-box",
            margin: 0,
            "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
              padding: "5px",
              boxShadow: "none",
              borderRadius: "5px",
            },
            height: "45px",
          }}
        />
        <TextField
          label={index === 0 ? "Dosage" : undefined}
          name="dosage"
          value={row.dosage}
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText=""
          sx={{
            ...styles.textFieldStyle,
            width: {
              xs: "100px",
              xl: "100px",
            },
          }}
        />
        <Select
          label={index === 0 ? "Unit" : undefined}
          name="units"
          value={row.units}
          options={unitsOptions}
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText=""
          sx={{
            ...styles.selectStyle,
            width: {
              xs: "100px",
              xl: "100px",
            },
          }}
        />
        <Select
          label={index === 0 ? "Duration Type" : undefined}
          name="duration_type"
          value={row.duration_type}
          options={durationTypeOptions}
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText=""
          sx={{
            ...styles.selectStyle,
            width: {
              xs: "200px",
              xl: "200px",
            },
          }}
        />
        <Typography
          sx={{
            width: durationTypes[row?.duration_type] ? "0px" : "200px",
            display: durationTypes[row?.duration_type] ? "none" : "flex",
          }}
        ></Typography>
        <Grid
          sx={{
            display: row?.duration_type === "specific_days" ? "flex" : "none",
          }}
        >
          <Select
            label={
              row?.duration_type === "specific_days" && index === 0
                ? "Specific Days"
                : undefined
            }
            options={specificDaysOptions}
            name="specific_days"
            isMultiSelect={true}
            value={row?.duration?.specific_days || []}
            selectedType="number"
            multiSelectSelectedType="number"
            optionName="value"
            onChange={(e: any) => handleInputChange(e, row.id)}
            helperText=""
            sx={{
              ...styles.selectStyle,
              display: row?.duration_type === "specific_days" ? "flex" : "none",
              width: {
                xs: row?.duration_type === "specific_days" ? "200px" : "0px",
                xl: row?.duration_type === "specific_days" ? "200px" : "0px",
              },
            }}
          />
        </Grid>
        <Grid
          sx={{
            display: row?.duration_type === "interval_days" ? "flex" : "none",
          }}
        >
          <TextField
            label={
              row?.duration_type === "interval_days" && index === 0
                ? "Interval Days"
                : undefined
            }
            name="interval_days"
            placeholder="Ex-2"
            value={row.duration.interval_days}
            onChange={(e: any) => handleInputChange(e, row.id)}
            helperText=""
            sx={{
              ...styles.textFieldStyle,
              display: row?.duration_type === "interval_days" ? "flex" : "none",
              width: row?.duration_type === "interval_days" ? "200px" : "0px",
            }}
          />
        </Grid>
        <Grid
          sx={{
            ...styles.datePickerGridStyle,
            display: row?.duration_type === "custom_date" ? "flex" : "none",
            mt: index === 0 ? "-20px" : "0px",
          }}
        >
          <DatePicker
            label={
              row?.duration_type === "custom_date" && index === 0
                ? "Custom Date"
                : undefined
            }
            name="custom_date"
            value={row.duration.custom_date}
            onChange={(e: any, newValue: any) =>
              handleDateChange(row.id, newValue, "custom_date")
            }
            height="28px"
            helperText=""
            style={{
              boxShadow: "none",
              border: "1px solid var( --grey-lighter)",
              height: "45px",
              borderRadius: "5px",
              padding: "0px 10px",
              width: row?.duration_type === "custom_date" ? "176px" : "0px",
              display: row?.duration_type === "custom_date" ? "flex" : "none",
            }}
          />
        </Grid>
        <TextField
          label={index === 0 ? "Duration Count" : undefined}
          name="duration_count"
          placeholder="2"
          value={row.duration.duration_count}
          disabled={
            row?.duration_type === "custom_date" ||
            row?.duration_type === "forever" ||
            row?.duration_type === ""
              ? true
              : false
          }
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText=""
          sx={{
            ...styles.textFieldStyle,
            width: "100px",
            "& .MuiInputBase-input": {
              fontSize: "12px",
              fontWeight: "400",
              textAlign: "left",
              p: "0px 10px",
            },
          }}
        />
        <Select
          label={index === 0 ? "Interval" : undefined}
          options={intervalOptions}
          name="interval"
          value={Number(row.duration.interval)}
          disabled={
            row?.duration_type === "custom_date" ||
            row?.duration_type === "forever" ||
            row?.duration_type === ""
              ? true
              : false
          }
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText=""
          sx={{
            ...styles.selectStyle,

            width: {
              xs: "100px",
              xl: "100px",
            },
            "&.Mui-disabled.MuiOutlinedInput-root": {
              borderColor: "initial",
              pl: "10px",
              borderRadius: "5px",
            },
            "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: "initial",
            },
          }}
        />
        <Select
          label={index === 0 ? "Frequency" : undefined}
          name="freq"
          value={row.freq}
          options={freqOptions}
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText=""
          sx={{
            ...styles.selectStyle,
            width: {
              xs: "100px",
              xl: "100px",
            },
          }}
        />
        <TextField
          label={index === 0 ? "Freq Time" : undefined}
          name="freq_time"
          value={row.freq_time}
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText=""
          sx={{
            ...styles.textFieldStyle,
            width: {
              xs: "100px",
              xl: "100px",
            },
          }}
        />
        <Select
          label={index === 0 ? "Freq Time Type" : undefined}
          name="freq_time_type"
          value={row.freq_time_type}
          options={freqTimeTypeOptions}
          onChange={(e: any) => handleInputChange(e, row.id)}
          helperText=""
          sx={{
            ...styles.selectStyle,
            width: {
              xs: "100px",
              xl: "100px",
            },
          }}
        />
        <Grid
          sx={{
            ...styles.datePickerGridStyle,
            mt: index === 0 ? "-44px" : "-24px",
          }}
        >
          <DatePicker
            label={index === 0 ? "Start Date" : undefined}
            value={row.start_date}
            name="start_date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(row.id, newValue, "start_date")
            }
            height="42px"
            helperText=""
            style={{
              boxShadow: "none",
              border: "1px solid var( --grey-lighter)",
              width: "200px",
              height: "45px",
              borderRadius: "5px",
              padding: "0px 10px",
            }}
            labelSx={{ marginBottom: index === 0 ? "2px" : "4px" }}
          />
        </Grid>
        <Button
          sx={{ width: "10px", height: "20px", mt: "2px" }}
          onClick={() => handlePrescriptionDelete(row.id)}
        >
          <DeleteIcon />
        </Button>
      </Grid>
    );
  }
);

export default Row;
