import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import {
  CancelButton,
  SaveButton,
  TextField,
  AutoCompleteWithCheckBoxes,
} from "../../../../../components/basic";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../../components/shared";
import { GridColDef } from "@mui/x-data-grid";
import { DeleteIcon } from "../../../../../assets/icons";
import {
  getAllVitals,
  getAdmissionIpVitalsById,
  deleteAdmissionIpVitalById,
  createAdmissionIpVitals,
  getDoctorVitals,
} from "../../../../../services/admissionService";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { ipAdmissionConst } from "../../../../../constants/displayText";
import PermissionUtils from "../../../../../utils/PermissionUtils";
import { setVitalsList } from "../../../../../redux/slices/inpatients";
import { RootState } from "../../../../../redux/store";

type VitalsProps = {
  ipAdmissionDetails?: any;
};

const Vitals = ({ ipAdmissionDetails }: VitalsProps) => {
  const { ADDED_SUCCESSFULLY, UPDATED_SUCCESSFULLY, DELETED_SUCCESSFULLY } =
    ipAdmissionConst;
  const { can } = PermissionUtils();

  const dispatch = useDispatch();
  const { vitalsList } = useSelector((state: RootState) => state.inPatients);

  // common variables

  const [admissionId, setAdmissionId] = useState<any>(ipAdmissionDetails?.id);
  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);
  const [dateToBeDeleted, setDateToBeDeleted] = useState<any>(null);

  //field variables

  const [vitalOptions, setVitalOptions] = useState<any>([]);
  const [addedObservations, setAddedObservations] = useState<any>([]);
  const [finalObservations, setFinalObservations] = useState<any>([]);
  const [bmiStatus, setBmiStatus] = useState<any>({
    category: "",
    color: "textPrimary.main",
  });

  const [validationErrors, setValidationErrors] = useState<any>({});

  // dialog and loader variables

  const [isDialogBoxOpen, setIsDialogBoxOpen] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isConfirmationDialogButtonLoading,
    setIsConfirmationDialogButtonLoading,
  ] = useState(false);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] =
    useState<boolean>(false);
  const [admissionVitalsData, setAdmissionVitalsData] = useState<any>(false);

  // data table columns and actions

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <IconButton
          onClick={() => {
            setIsConfirmationDialogOpen(true);
            setIdToBeDeleted(row?.vital_id);
            setDateToBeDeleted(row?.vital_date);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.4,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          S.No
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sNo}
        </Typography>
      ),
      minWidth: 40,
      sortable: false,
    },
    {
      field: "observations",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Observations
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.vital_name}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "values",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Values
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.vital_value}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Date
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.vital_date}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "generatedBy",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Generated By
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {"Super Admin"}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    ...(can("ip_vitals_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  height: "51px",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </Grid>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 200,
            sortable: false,
          },
        ]
      : []),
  ];

  // field onchange functions

  const handleInputChange = (e: any) => {
    const { value, name } = e.target;

    let numericValue = value.replace(/[^\d.]/g, "");

    const height = finalObservations.find(
      (ele: any) => ele.name === "Height" && Boolean(ele.value)
    );

    const weight = finalObservations.find(
      (ele: any) => ele.name === "Weight" && Boolean(ele.value)
    );

    if (name === "IBW" && Boolean(height?.value)) {
      return false;
    }

    if (name === "BMI") {
      if (Boolean(height?.value) || Boolean(weight?.value)) {
        return false;
      }
    }

    setFinalObservations((prev: any) => {
      return prev?.map((vital: any) => {
        if (vital.name === name) {
          return { ...vital, value: numericValue };
        }
        return vital;
      });
    });

    if (validationErrors[name]) {
      setValidationErrors((prev: any) => ({
        ...prev,
        [name]: numericValue ? "" : "*This is required field",
      }));
    }

    if (name === "Height" || name === "Weight") {
      if (name === "Height") {
        handleBmiCalculation(numericValue, weight?.value);
      } else {
        handleBmiCalculation(height?.value, numericValue);
      }
    }
  };

  const handleAutoCompleteChange = (e: any, newValue: any) => {
    if (newValue === null) {
      return false;
    }

    setAddedObservations((prev: any) => newValue);
  };

  function removeDuplicates(array: any) {
    const uniqueObjects: any = [];
    const encounteredIds: any = {};

    for (const obj of array) {
      // Check if the ID has been encountered before
      if (!encounteredIds[obj.id]) {
        // If not, add it to the uniqueObjects array and mark its ID as encountered
        uniqueObjects.push(obj);
        encounteredIds[obj.id] = true;
      }
    }

    return uniqueObjects;
  }

  function createMergedArray(arrOne: any, arrTwo: any) {
    return arrTwo.map((itemTwo: any) => {
      // Check if there's an object with the same id in arrayOne
      const foundItem = arrOne.find(
        (itemOne: any) => itemOne.id === itemTwo.id
      );

      // If found, return the object from arrayOne; otherwise, return the object from arrayTwo
      return foundItem || itemTwo;
    });
  }

  const handleAddObservations = () => {
    setIsDialogBoxOpen(false);

    const newValue = removeDuplicates([
      ...finalObservations,
      ...addedObservations,
    ]);

    const mergedArray = createMergedArray(finalObservations, addedObservations);

    setFinalObservations(mergedArray);
  };

  const resetObservations = () => {
    setAddedObservations([]);
    setFinalObservations([]);
  };

  const handleBmiCalculation = (
    height: string | number | null,
    weight: string | number | null
  ) => {
    let bmiValue: number | string = 0;
    let ibwValue: number | string = 0;

    if (height) {
      let data = ipAdmissionDetails?.patient_details?.sex === 1 ? 100 : 105;
      ibwValue = (Number(height || 0) - data).toFixed(1);
      setBmiStatus((prevState: any) => ({
        ...prevState,
        category: "",
        color: "",
      }));
    }

    if (height && weight) {
      bmiValue = (
        Number(weight || 0) / Math.pow(Number(height || 0) / 100, 2)
      ).toFixed(1);
      if (Number(bmiValue) < 18.5) {
        setBmiStatus((prevState: any) => ({
          ...prevState,
          category: "Underweight",
          color: "#ffc44d",
        }));
      } else if (Number(bmiValue) >= 18.5 && Number(bmiValue) <= 24.9) {
        setBmiStatus((prevState: any) => ({
          ...prevState,
          category: "Normal Weight",
          color: "#0be881",
        }));
      } else if (Number(bmiValue) >= 25 && Number(bmiValue) <= 29.9) {
        setBmiStatus((prevState: any) => ({
          ...prevState,
          category: "Overweight",
          color: "#ff884d",
        }));
      } else {
        setBmiStatus((prevState: any) => ({
          ...prevState,
          category: "Obese",
          color: "#ff5e57",
        }));
      }
    }
    if (!height) {
      ibwValue = 0;
    }
    if (Boolean(!height || !weight)) {
      bmiValue = 0;
    }

    setFinalObservations((prev: any) => {
      return prev?.map((vital: any) => {
        if (vital.name === "BMI") {
          return { ...vital, value: bmiValue };
        }
        if (vital.name === "IBW") {
          return { ...vital, value: ibwValue };
        }
        return vital;
      });
    });

    if (validationErrors.BMI) {
      setValidationErrors((prev: any) => ({
        ...prev,
        BMI: bmiValue ? "" : "*This is required field",
      }));
    }

    if (validationErrors.IBW) {
      setValidationErrors((prev: any) => ({
        ...prev,
        IBW: ibwValue ? "" : "*This is required field",
      }));
    }
  };

  // api call functions to get select options

  const getVitalsList = async () => {
    try {
      await getAllVitals().then((result: any) => {
        let data = result?.data;
        const modifiedData = data?.map((option: any) => {
          return {
            ...option,
            value: null,
          };
        });
        setVitalOptions(modifiedData);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getDoctorVitalsList = async () => {
    try {
      await getDoctorVitals(ipAdmissionDetails?.doctor_id).then(
        (result: any) => {
          let data = result?.data;

          const modifiedData = data?.map((option: any) => {
            return {
              ...option,
              value: null,
            };
          });
          setFinalObservations(modifiedData);
          setAddedObservations(modifiedData);
        }
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // crud operation api calls functions

  const getAdmissionIpVitals = async () => {
    if (!admissionId) {
      return false;
    }
    try {
      setIsPageLoading(true);
      setIsDataTableLoading(true);
      await getAdmissionIpVitalsById(admissionId).then((result: any) => {
        let data = result?.data;

        if (data) {
          const modifiedData = data.map((item: any, index: number) => ({
            ...item,
            sNo: index + 1,
          }));
          dispatch(setVitalsList(modifiedData));
        }
        setIsPageLoading(false);
        setIsDataTableLoading(false);
      });
    } catch (error) {
      setIsPageLoading(false);
      setIsDataTableLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const createNewAdmissionIpVitals = async () => {
    let errors: any = {};

    finalObservations.forEach((observation: any, index: any) => {
      if (!observation.value) {
        errors[observation.name] = "*This is required field";
      }
    });

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    } else {
      try {
        setIsSaveButtonLoading(true);

        const vitals = finalObservations?.map((item: any) => {
          return {
            vital_id: item.id,
            vital_value: Math.round(Number(item.value)),
          };
        });

        const data = { ip_admission_id: admissionId, vitals: vitals };

        await createAdmissionIpVitals(data).then((res: any) => {
          let data = res?.data;

          setIsSaveButtonLoading(false);
          resetObservations();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          getAdmissionIpVitals();
        });
      } catch (error: any) {
        setIsSaveButtonLoading(false);
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error.response.data.message,
          })
        );
      }
    }
  };

  const handleDelete = async (vitalId: any) => {
    try {
      setIsDataTableLoading(true);
      setIsConfirmationDialogButtonLoading(true);

      const data = {
        date: dateToBeDeleted,
      };

      await deleteAdmissionIpVitalById(vitalId, admissionId, data).then(
        (result: any) => {
          setIsDataTableLoading(false);

          getAdmissionIpVitals();

          dispatch(
            setSnackBarSuccess({
              snackBarMessage: DELETED_SUCCESSFULLY,
            })
          );

          setIsConfirmationDialogOpen(false);
          setIsConfirmationDialogButtonLoading(false);
          setIdToBeDeleted(null);
        }
      );
    } catch (error) {
      setIsDataTableLoading(false);
      setIsConfirmationDialogOpen(false);
      setIsConfirmationDialogButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "something went wrong",
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };

  // dialog functions

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleDialogClose = () => {
    setIsDialogBoxOpen(false);
    setAddedObservations(finalObservations);
  };


  useEffect(() => {
    if (finalObservations?.length > 0) {
      const height = finalObservations?.find(
        (ele: any) => ele.name === "Height" && Boolean(ele.value)
      );

      const weight = finalObservations?.find(
        (ele: any) => ele.name === "Weight" && Boolean(ele.value)
      );

      if (height?.value) {
        handleBmiCalculation(height?.value, weight?.value);
      }
    }
  }, [isDialogBoxOpen]);

  useEffect(() => {
    getVitalsList();
    getDoctorVitalsList();
  }, []);

  useEffect(() => {
    if (!vitalsList) {
      getAdmissionIpVitals();
    }
  }, [admissionId, vitalsList]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "95vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "40vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isConfirmationDialogButtonLoading}
      />

      {can("ip_vitals_add") ? (
        <>
          <DialogWrapper
            open={isDialogBoxOpen}
            title="Add Observations"
            onClose={handleDialogClose}
            handleClick={handleAddObservations}
            confirmText="Apply"
          >
            <AutoCompleteWithCheckBoxes
              label="Vitals"
              value={addedObservations}
              placeholder="Select Vitals"
              isCheckBoxEnabled={true}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(e, newValue)
              }
              options={vitalOptions}
              sx={{
                mb: 3,
                width: {
                  xl: "500px",
                  md: "500px",
                  sm: "250px",
                },
              }}
            />
          </DialogWrapper>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              m: "20px 0px",
            }}
          >
            <Grid xl={12} sx={{ m: "10px 0px" }}>
              <Typography variant="h2">Add Observations</Typography>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                m: "0px 0px 10px 0px",
              }}
            >
              {finalObservations?.map((observation: any, index: number) => (
                <Grid item key={index}>
                  <TextField
                    label={observation?.name}
                    inputAdornment={observation?.inputAdornment}
                    name={observation?.name}
                    value={observation?.value}
                    onChange={handleInputChange}
                    helperText={validationErrors[observation?.name]}
                    sx={{
                      width: "200px",
                      height: "48px",
                      mr: "20px",
                      mb:
                        observation?.name === "BMI" ||
                        observation?.name === "IBW"
                          ? "2px"
                          : 2,
                    }}
                  />
                  {observation?.name === "BMI" && (
                    <Typography
                      variant="h6"
                      sx={{
                        color: bmiStatus.color,
                        margin:
                          validationErrors[observation?.name] === ""
                            ? "0px"
                            : "5px 0px",
                      }}
                    >
                      {observation?.value ? bmiStatus.category : ""}
                    </Typography>
                  )}
                </Grid>
              ))}
              <Button
                variant="text"
                sx={{
                  width: "100px",
                  height: "50px",
                  mt: 2.6,
                  textTransform: "none",
                }}
                onClick={() => setIsDialogBoxOpen(true)}
              >
                <Typography variant="h4">+Add</Typography>
              </Button>
            </Grid>
            <Grid sx={{ display: "flex", gap: "2" }}>
              <SaveButton
                loading={isSaveButtonLoading}
                buttonText={"Add"}
                handleClick={createNewAdmissionIpVitals}
                disabled={finalObservations.length > 0 ? false : true}
                sx={{ width: "88px", height: "38px" }}
              />
              <CancelButton
                sx={{ width: "88px", height: "38px", ml: 3 }}
                buttonText={"Reset"}
                handleClick={resetObservations}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3, color: "#D9D9D9" }} />
        </>
      ) : (
        <></>
      )}
      <Grid item sx={{ m: "20px 0px" }}>
        <Typography variant="h2" sx={{ m: "20px 0px" }}>
          Added Observations
        </Typography>
        <DataTable
          loading={isDataTableLoading}
          columns={columns}
          rows={vitalsList || []}
          getRowId={(row: any) => `${String(row.sNo)}`}
          tableOnly={true}
          customizedTable={true}
        />
      </Grid>
    </Grid>
  );
};

export default Vitals;
