import React from "react";
import {
  TableCell,
  TableRow,
  Popper,
  Box,
  List,
  Grid,
  Typography,
} from "@mui/material";
import { TextField, AutoCompleteWithTable } from "../../../../components/basic";
import {
  DeleteIcon,
  ClockHistoryIcon,
  DisabledDelete,
} from "../../../../assets/icons";
import moment from "moment";

type ProductRowProps = {
  styles?: any;
  row?: any;
  billId?: any;
  handleDelete: (id: number, batchNo?: string) => void;
  allRows?: any;
  summary?: any;
  handleInputChange: any;
  handleDateChange: any;
  handleAutoCompleteChange: any;
  index: number;
  taxesOptions: any;
  error?: any;
  setIsOpenErrorModal?: any;
  setIsOpenPriceMargin?: any;
  productLength?: number;
};

const ProductRow = React.memo(
  ({
    styles,
    index,
    row,
    billId,
    allRows,
    handleInputChange,
    handleDateChange,
    handleAutoCompleteChange,
    handleDelete,
    taxesOptions,
    error,
    setIsOpenErrorModal,
    setIsOpenPriceMargin,
    productLength,
  }: ProductRowProps) => {
    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            width: "700px",
            p: "10px 0px",
            boxSizing: "border-box",
            "& .MuiPaper-root": {
              width: "700px",
              maxHeight: "250px",
              overflow: "hidden",
              borderRadius: "0px 0px 5px 5px",
              boxSizing: "border-box",
            },
            "& .MuiAutocomplete-listbox": {
              overflowX: "hidden",
              maxHeight: "250px",
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "700px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              p: "0px 10px 0px 0px",
              height: "45px",
              boxSizing: "border-box",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <Typography
              sx={{
                width: "50%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Product Name
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Batch
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Exp.Date
            </Typography>
            <Typography
              sx={{
                width: "20%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Stock
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    const calculateMonthDifference = (date: any) => {
      const today = moment();
      const givenDate = moment(date, "DD/MM/YYYY");

      const monthDifference = today.diff(givenDate, "months");
      return Math.abs(monthDifference);
    };

    const handleBlur = () => {
      const monthDifference = calculateMonthDifference(row.expires_at);
      if (monthDifference <= 6) {
        setIsOpenErrorModal({
          isOpen: true,
          content: `This product will expire in ${monthDifference} months.`,
        });
      }
    };

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="left"
          sx={{
            width: "12%",
            "& .MuiAutocomplete-root": {
              height: "31px",
            },
            "& .MuiInputBase-root": {
              height: "32px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
            },
            border: !!error?.product_name ? "2px solid #d32f2f !important" : "",
          }}
        >
          <AutoCompleteWithTable
            placeholder="Select Product"
            name="product_name"
            value={row?.product_name || null}
            onChange={(e: any, newValue: any) =>
              handleAutoCompleteChange(newValue, index, "product_name")
            }
            options={allRows}
            renderOption={(props: any, option: any) => {
              return (
                <List
                  {...props}
                  key={option.ID}
                  className="table-body-lists-div"
                  sx={{
                    width: "700px",
                    borderRadius: 0,
                    boxSizing: "border-box",
                    m: "0px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.lighter",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="700px"
                    className="list-row"
                    sx={{
                      boxSizing: "border-box",
                      m: "0px",
                      p: "0px 10px 0px 0px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        width: "50%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.product_name}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "20%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.batch_no}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "20%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.expires_at
                        ? option.expires_at?.split("-").reverse().join("/")
                        : ""}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "20%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option.stock_qty || 0}
                    </Typography>
                  </Box>
                </List>
              );
            }}
            getOptionLabel={(option: any) => option.product_name}
            sx={{
              width: "100%",
              height: "34px",
              "& .MuiFormControl-root": {
                height: "32px",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "& .MuiInputBase-root .MuiInputBase-input": {
                padding: 0,
                fontSize: "12px",
                fontWeight: "400",
              },
            }}
            PopperComponent={PopperMy}
          />
        </TableCell>

        <TableCell
          sx={{
            width: "4%",
            backgroundColor: "var(--table-cell-disabled-background)",
            border: !!error?.pack_type ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="pack_type"
            value={row.pack_type}
            disabled
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>

        <TableCell
          align="left"
          sx={{
            width: "8%",
            backgroundColor: "var(--table-cell-disabled-background)",
            border: !!error?.batch_no ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="batch_no"
            value={row.batch_no}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
              width: "inherit",
            }}
            disabled
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "8%",
            backgroundColor: "var(--disabled-background)",
          }}
        >
          <TextField
            name="expires_at"
            value={row.expires_at}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
            disabled
          />
        </TableCell>
        {billId && (
          <TableCell
            sx={{
              width: "4%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="used_qty"
              value={row.used_qty}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
        )}
        {billId && (
          <TableCell
            sx={{
              width: "4%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="used_free"
              value={row.used_free}
              disabled={true}
              sx={{
                ...styles.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
        )}
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: !!error?.qty ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            width="100px"
            name="qty"
            value={row.qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
            }}
          />
        </TableCell>

        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: !!error?.free ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="free"
            value={row.free}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>

        <TableCell
          align="left"
          sx={{
            width: "8%",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            name="p_rate"
            value={row.p_rate}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
              width: "inherit",
            }}
            disabled
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: !!error?.mrp ? "2px solid #d32f2f !important" : "",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            width="100px"
            name="mrp"
            value={row.mrp}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
            }}
            disabled
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            border: !!error?.rate ? "2px solid #d32f2f !important" : "",
          }}
        >
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              "&:has(.Mui-focused) .icon-grid": {
                backgroundColor: "var(--table-cell-background)",
              },
            }}
          >
            <TextField
              width="100px"
              name="rate"
              value={row.rate}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                ...styles.textFieldStyle,
                "& .MuiInputBase-input": {
                  p: "0px 10px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "left",
                },
              }}
            />
            <Grid
              className="icon-grid"
              sx={{
                width: "33px",
                height: "34px",
                p: "10px",
                cursor: "pointer",
              }}
              onClick={() =>
                setIsOpenPriceMargin({
                  isOpen: true,
                  product_id: row.product_id,
                })
              }
            >
              <ClockHistoryIcon />
            </Grid>
          </Grid>
        </TableCell>

        <TableCell
          align="center"
          sx={{
            width: "9%",
            border: !!error?.discount ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            width="100px"
            name="discount"
            value={`${row.discount}`}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
            }}
          />
        </TableCell>

        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: !!error?.tax ? "2px solid #d32f2f !important" : "",
            backgroundColor: "var(--table-cell-disabled-background)",
          }}
        >
          <TextField
            width="100px"
            name="tax_data"
            value={row.tax_data}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
            }}
            disabled
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            backgroundColor: "var(--disabled-background)",
            width: "9%",
            position: "relative",
          }}
        >
          <TextField
            name="amount"
            value={row.amount}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{ ...styles.textFieldStyle }}
            disabled
          />

          <Grid className="deleteIconGrid">
            {productLength === 0 ? (
              <DisabledDelete className="delete-icon" />
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => handleDelete(index, row.batch_id)}
              />
            )}

            <TextField
              width="0px"
              name="discountAmount"
              value={row.discount_amount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
            <TextField
              width="0px"
              name="taxAmount"
              value={row.tax_amount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
