import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { CancelButton, PageLoader, Select } from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import { goodsIssueConst } from "../../../../constants/displayText";
import Info from "./Info";
import Summary from "./Summary";
import { useNavigate, useParams } from "react-router-dom";
import { getGoodsIssueViewProductById } from "../../../../services/gynecologyService";
import { formula } from "../../../../utils/CalculationUtils";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import { PrintingLightIcon } from "../../../../assets/icons";
const BillProducts = () => {
  const { productName, batchNo, expiryDate, tax, amount } = goodsIssueConst;
  const { id } = useParams();
  const { calculateSubTaxes } = formula.purchaseBill;
  const { calculateTotalAmount, calculateRoundedOff, calculateNetAmount } =
    formula.goodsIssueReturn;
  const [info, setInfo] = React.useState({});
  const navigate = useNavigate();
  const [billingSummary, setBillingSummary] = React.useState<any>({});
  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);
  const printMethod = useRef<any>([{ id: "", name: "", disabled: false }]);
  const printOptions = [
    { id: "a4", name: "A4" },
    { id: "a5", name: "A5" },
    { id: "dot_matrix", name: "Dot Matrix" },
  ];
  const [billProducts, setBillProducts] = useState<any>([]);

  const getGoodsReturnById = async (IssueId: number | string) => {
    setIsDataTableLoading(true);
    await getGoodsIssueViewProductById(IssueId)
      .then((res: any) => {
        const purchaseBill = res.data.bill;
        const billProducts = res.data.bill_products;
        // const validation = res.data.validation;
        const print_template = res.data.print_template;

        printMethod.current = printOptions.map((ele) => ({
          ...ele,
          disabled: !print_template?.paper_size[ele.id],
        }));
        setInfo((prevState) => ({
          ...prevState,
          invoice_no: purchaseBill.invoice_no,
          issuer: purchaseBill.from_dept_name,
          receiver: purchaseBill.to_dept_name,
          date: formatTwelveHoursTime(purchaseBill.created_at),
        }));
        const data = billProducts?.map((res: any) => ({
          id: res.id,
          product_name: res.product_name,
          batch_no: res.batch_no,
          expiry_date: res.expires_at
            ? res.expires_at.split("-").reverse().join("/")
            : "",
          qty: res.return_qty,
          mrp: Number(res.mrp / res.pack_type).toFixed(2),
          discount: res.discount || 0,
          discount_type: res?.discount_type || "",
          tax_value: `${res.taxname}-${res.tax_id}%`,
          tax: {
            id: res.id,
            name: res.tax,
            tax_name: res.taxname,
            tax_rate: res.tax_id,
            sub_taxes: res.subtax,
          },
          tax_amount: (res.tax_id / 100) * res.amount,
          amount: res.amount,
          strip_qty: res.original_qty / res.pack_type,
          ptr: Number(res.p_rate / res.pack_type).toFixed(2),
        }));
        const newBillAmount = calculateTotalAmount(data);
        setBillProducts(data);
        const value = calculateSubTaxes(data) || 0;
        const roundedOff = calculateRoundedOff({
          bill_amount: Number(newBillAmount),
        });
        const newNetAmount = calculateNetAmount({
          bill_amount: newBillAmount,
          rounded_off: roundedOff,
        });
        setBillingSummary((prevState: any) => ({
          ...prevState,
          goods_value: purchaseBill.orig_goods_val,
          tax: Number(purchaseBill.gst_amt).toFixed(2),
          roundedOff: roundedOff.toFixed(2),
          netAmount: Number(newNetAmount).toFixed(2),
          CGST: value.CGST,
          SGST: value.SGST,
          bill_amount: Number(newBillAmount).toFixed(2),
        }));
        setIsDataTableLoading(false);
      })
      .catch((err: any) => {
        setIsDataTableLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (id) {
      getGoodsReturnById(id);
    }
  }, [id]);

  const columns: GridColDef[] = [
    {
      field: "product_name",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {productName}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.product_name}</Typography>
      ),
      sortable: false,
    },
    {
      field: "batch_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {batchNo}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.batch_no}</Typography>
      ),
      sortable: false,
    },
    {
      field: "expiry_date",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {expiryDate}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.expiry_date}</Typography>
      ),
      sortable: false,
    },
    {
      field: "qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Qty
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.qty}</Typography>
      ),
      sortable: false,
    },
    {
      field: "ptr",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            PTR /Unit
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.ptr}</Typography>
      ),
      sortable: false,
    },
    {
      field: "discount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Discount
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.discount
            ? `${row.discount || 0} ${row.discount_type || ""}`
            : ""}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {tax}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.tax_value}</Typography>
      ),
      sortable: false,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {amount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.amount}</Typography>
      ),
      sortable: false,
    },
  ];
  return isDataTableLoading ? (
    <PageLoader />
  ) : (
    <Grid>
      <Info info={info} />
      <Grid sx={{ m: "20px 0px" }}>
        <DataTable
          columns={columns}
          rows={billProducts}
          tableOnly={true}
          customizedTable={true}
        />
      </Grid>
      <Summary summary={billingSummary} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <CancelButton
          buttonText="Back"
          sx={{ width: "100px", height: "42px", marginTop: "20px" }}
          handleClick={() => navigate("/procurement/goods-issue")}
        />

        <Select
          label="Select Print Size"
          placeholder={<PrintingLightIcon />}
          name="discountType"
          sx={{ width: "306px" }}
          width="306px"
          options={printMethod.current || []}
        />
      </div>
    </Grid>
  );
};

export default BillProducts;
