import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getBillDraftDetailsById } from "../../../../../services/billingService";
import {
  CheckBox,
  PageLoader,
  SaveButton,
} from "../../../../../components/basic";
import { DataTable } from "../../../../../components/shared";
import { GridColDef } from "@mui/x-data-grid";
import { billDraftViewsConst } from "../../../../../constants/displayText";
import { formatTwelveHoursTime } from "../../../../../utils/DateTimeFormatUtils";
import { RouteUrls } from "../../../../../constants/routes";

type GridRowData = Record<string, unknown>;

const { date, item, description, quantity, rate, consultant, billNo } =
  billDraftViewsConst;
const { billingUrl, createUrl } = RouteUrls;

const styles = {
  labelStyles: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: 1.6,
    color: "#8A8A8A",
  },
  valueStyles: {
    lineHeight: 1.5,
    fontSize: "13px",
    fontWeight: 600,
  },
};
const BillDraftView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const draft_type = searchParams.get("draft_type");
  const dept_id = searchParams.get("dept_id");
  const dept_name = searchParams.get("dept_name");
  const [loading, setLoading] = useState(false);
  const [draftDetails, setDraftDetails] = useState({
    patient_id: null,
    name: "",
    uhid: "",
    mobile: "",
    doctor_name: "",
    doctor_id: null,
    bills: [],
  });
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [selectableIds, setSelectableIds] = useState<any[]>([]);

  const columns: GridColDef[] = [
    {
      field: "date",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          {selectableIds?.length > 0 ? (
            <CheckBox
              checked={
                selectedProducts.length === selectableIds?.length &&
                selectableIds?.length > 0
              }
              onChange={handleCheckAll}
            />
          ) : (
            <Box sx={{ mr: "25px" }} />
          )}
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {date}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Box display={"flex"} alignItems={"center"}>
          {!row.bill_no ? (
            <CheckBox
              checked={
                selectedProducts?.findIndex((ele) => ele === row.index) !== -1
              }
              onChange={() => handleCheck(row)}
            />
          ) : (
            <Box sx={{ mr: "25px" }} />
          )}
          <Typography variant="h6" ml={1}>
            {row.date_created ? formatTwelveHoursTime(row.date_created) : ""}
          </Typography>
        </Box>
      ),
      sortable: false,
    },
    {
      field: "item",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {item}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.name}</Typography>
      ),
      sortable: false,
    },
    {
      field: "description",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {description}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.desc}</Typography>
      ),
      sortable: false,
    },
    {
      field: "quantity",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {quantity}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.quantity}</Typography>
      ),
      sortable: false,
    },
    {
      field: "rate",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {rate}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.price}</Typography>
      ),
      sortable: false,
    },
    {
      field: "consultant",
      flex: 1.5,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {consultant}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.doctor_name}</Typography>
      ),
      sortable: false,
    },
    {
      field: "bill_no",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {billNo}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Link
          style={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: 1.6,
          }}
          to={`${RouteUrls.billingUrl}${RouteUrls.viewUrl}/${row?.bill_id}`}
        >
          {row.bill_no}
        </Link>
      ),
      sortable: false,
    },
  ];

  const handleCheck = (row: any) => {
    const index = selectedProducts.findIndex((ele: any) => ele === row.index);

    if (index === -1) {
      setSelectedProducts((prev: any) => [...prev, row.index]);
    } else {
      setSelectedProducts((prev) => {
        const newState = [...prev];
        newState.splice(index, 1);
        return newState;
      });
    }
  };

  const handleCheckAll = () => {
    setSelectedProducts((prevState: any) => {
      if (prevState?.length === selectableIds?.length) {
        return [];
      }
      return selectableIds;
    });
  };

  const calculateItemPrice = (item: any) => {
    if (item.dept_id && item.ward_price) {
      const ward_prices = JSON.parse(item.ward_price);
      const ward_price = ward_prices?.find(
        (ele: any) => Number(ele.department_id) === Number(item.dept_id)
      );

      const price = ward_price?.ward_price
        ? (Number(ward_price?.ward_price) * Number(item.price)) / 100
        : 0;

      return price + Number(item.price);
    } else {
      return Number(item.price) || 0;
    }
  };

  const handleGeneratePoClick = () => {
    navigate(`${billingUrl}${createUrl}`, {
      state: {
        patient_id: draftDetails?.patient_id,
        uhid: draftDetails?.uhid,
        patient_name: draftDetails?.name,
        doctor_name: draftDetails?.doctor_name,
        doctor_id: draftDetails?.doctor_id,
        mobile: draftDetails?.mobile,
        draft_type: draft_type === "IP" ? "IP" : "OP",
        department: draft_type,
        dept_id: dept_id,
        dept_name: dept_name,
        bills: draftDetails?.bills
          ?.filter((ele: any) => selectedProducts.includes(ele.index))
          ?.map((ele: any) => ({
            id: ele.item_id,
            type: ele.item_id,
            custom_id: ele.custom_id,
            qty: ele.quantity,
            name: ele.name,
            desc: ele.desc,
            price: calculateItemPrice(ele),
            // package_tests: ",",
            consultant: ele.consultant_id,
            department: ele?.dept_name,
            dept_id: ele.dept_id,
            draft_id: ele.draft_id,
            draft_type: ele.draft_type,
          })),
        // bills: selectedProducts,
      },
    });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getBillDraftDetailsById(id, draft_type)
        .then((res: any) => {
          if (res.data) {
            setDraftDetails({
              patient_id: res?.data?.parentData?.patient_id,
              name: res?.data?.parentData?.name,
              uhid: res?.data?.parentData?.uhid,
              mobile: res?.data?.parentData?.mobile,
              doctor_id: res?.data?.parentData?.doctor_id,
              doctor_name: res?.data?.parentData?.doctor_name,
              bills: res?.data?.childData?.map((ele: any, index: number) => ({
                ...ele,
                index,
              })),
            });

            if (res?.data?.childData?.length > 0) {
              setSelectableIds(() => {
                const ids: any = [];
                res?.data?.childData?.forEach((ele: any, index: number) => {
                  if (!ele.bill_no) {
                    ids?.push(index);
                  }
                });

                return ids;
              });
            }
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
        });
    }
  }, [id]);

  return (
    <Box>
      {loading ? (
        <PageLoader />
      ) : (
        <Box>
          <Grid container columnGap={1} rowGap={1}>
            <Grid item xs={12} sm={5.8} md={3.8} xl={3.8}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={6}
                  md={6}
                  xl={4}
                  sx={{ ...styles.valueStyles }}
                >
                  Patient Name:
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={6}
                  md={6}
                  xl={8}
                  sx={{ ...styles.labelStyles }}
                >
                  {draftDetails.name}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5.8} md={3.8} xl={3.8}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={6}
                  md={6}
                  xl={4}
                  sx={{ ...styles.valueStyles }}
                >
                  Patient UHID:
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={6}
                  md={6}
                  xl={8}
                  sx={{ ...styles.labelStyles }}
                >
                  {draftDetails.uhid}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5.8} md={3.8} xl={3.8}>
              <Grid container>
                <Grid
                  item
                  xs={4}
                  sm={6}
                  md={6}
                  xl={4}
                  sx={{ ...styles.valueStyles }}
                >
                  Patient Mobile:
                </Grid>
                <Grid
                  item
                  xs={8}
                  sm={6}
                  md={6}
                  xl={8}
                  sx={{ ...styles.labelStyles }}
                >
                  {draftDetails.mobile}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid sx={{ m: "20px 0px" }}>
            <DataTable
              columns={columns}
              rows={draftDetails?.bills}
              getRowId={(row: GridRowData) =>
                `${String(row?.draft_id)}/${String(row?.date_created)}/${String(
                  row?.draft_type
                )}/${String(row?.item_id)}`
              }
              tableOnly={true}
              // customizedTable={true}
            />
          </Grid>
          <Grid
            sx={{
              width: "40vw",
              display: "flex",
              alignItems: "flex-end",
              gap: 2,
              m: "20px 0px",
            }}
          >
            <SaveButton
              buttonText="GEN BILL"
              sx={{ width: "100px", height: "42px" }}
              handleClick={handleGeneratePoClick}
            />
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default BillDraftView;
