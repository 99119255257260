import React, { useCallback, useEffect, useState } from "react";
import {
  TableCell,
  TableRow,
  Grid,
  debounce,
  CircularProgress,
} from "@mui/material";
import { TextField, AutoComplete } from "../../../../components/basic";
import { DeleteIcon, DisabledDelete } from "../../../../assets/icons";
import { getAllProcurementProducts } from "../../../../services/procurementService";
import { Can } from "../../../../utils/PermissionUtils";

type ProductRowProps = {
  styles?: any;
  row?: any;
  handleDelete: (id: number, index: number) => void;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    id: Number,
    name: string
  ) => false | void;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: any,
    id: Number,
    name: string
  ) => void;
  index: number;
  products?: any;
  isShowDeleteIcon?: any;
  error: { [key: string]: string };
  isEdit?: boolean;
  infoData: any;
  excludeIds?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    row,
    handleInputChange,
    handleAutoCompleteChange,
    handleAutoCompleteInputChange,
    handleDelete,
    index,
    products,
    isShowDeleteIcon,
    error,
    isEdit,
    infoData,
    excludeIds,
  }: ProductRowProps) => {
    const [allProducts, setAllProducts] = useState<any[]>([]);
    const [isProductLoading, setIsProductLoading] = useState(false);

    const getAllProcurementProductsData = async (data: any) => {
      const res: any = await getAllProcurementProducts(data);
      if (res.data) {
        setAllProducts(res.data);
      }
      setIsProductLoading(false);
    };

    const debouncedGetAllSupplierList = useCallback(
      debounce((value: string, excludeIds: any, infoData: any) => {
        const data = {
          department: infoData?.department,
          search: value,
          page: 1,
          limit: 20,
          excluded_product_ids: excludeIds?.filter(
            (ele: any, i: number) => i !== index
          ),
        };

        getAllProcurementProductsData(data);
      }, 500),
      []
    );

    const handleAutoCompleteRowInputChange = (
      e: any,
      newInputValue: any,
      index: number,
      name: string
    ) => {
      handleAutoCompleteInputChange(e, newInputValue, index, name);
      if (newInputValue?.length >= 3 && infoData?.department) {
        setIsProductLoading(true);
        debouncedGetAllSupplierList(newInputValue, excludeIds, infoData);
      }
    };

    useEffect(() => {
      if (row.product_name) {
        setAllProducts([row?.product_name]);
      }
    }, [row.product_name]);

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="center"
          sx={{
            width: "30%",
            height: "34px",
            "& .MuiAutocomplete-root": {
              height: "32px",
            },
            "& .MuiFormLabel-root": {
              display: "none",
            },
            "& .MuiInputBase-root": {
              height: "32px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
              boxShadow: "none",
            },
            border: !!error?.product_id ? "2px solid #d32f2f !important" : "",
          }}
        >
          <AutoComplete
            placeholder="select"
            isAddAutocompleteValue
            value={row.product_name}
            inputValue={row.product_name_input_value}
            name="product_name"
            onChange={(e: any, newValue: any) => {
              handleAutoCompleteChange(e, newValue, index, "product_name");
            }}
            onInputChange={(e: any, newInputValue: any) => {
              handleAutoCompleteRowInputChange(
                e,
                newInputValue,
                index,
                "product_name_input_value"
              );
            }}
            filterOptions={(options: any) => options}
            noOptionsText={
              isProductLoading ? (
                <div>
                  <CircularProgress style={{ width: "10px", height: "10px" }} />{" "}
                  Loading ...
                </div>
              ) : row?.product_name_input_value?.length < 3 ||
                !row?.product_name_input_value ? (
                `Please enter ${
                  3 - (row?.product_name_input_value?.length || 0)
                } or more characters`
              ) : (
                "No options"
              )
            }
            options={allProducts}
            sx={{
              width: "100%",
              height: "34px",
              "& .MuiFormControl-root": {
                height: "34px",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "& .MuiInputBase-root .MuiInputBase-input": {
                padding: 0,
                fontSize: "12px",
                fontWeight: "400",
              },

              fontFamily: ["Inter", "sans-serif"].join(","),

              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "greyScale.light",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.main",
              },
              "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
                borderColor: "greyScale.lighter",
              },
              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                padding: "6.5px",
                boxShadow: "none",
                borderRadius: "8px",
              },
              "& .MuiAutocomplete-endAdornment": {
                pt: "3px",
                display: !row.id ? "inline-flex" : "none",
              },
              "&:hover .MuiAutocomplete-endAdornment": {
                display: !row.id ? "inline-flex" : "none",
              },
            }}
            disabled={row.id}
            onBlur={() => setAllProducts([])}
            disableClearable={false}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            width: "20%",
            position: "relative",
            backgroundColor: "var(--disabled-background)",
          }}
        >
          <TextField
            name="current_stock"
            value={row.current_stock}
            sx={{
              ...styles.textFieldStyle,
            }}
            disabled
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            width: "20%",
            position: "relative",
            backgroundColor: "var(--disabled-background)",
          }}
        >
          <TextField
            name="re_order_qty"
            value={row.re_order_qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
            }}
            disabled
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            width: "30%",
            position: "relative",
            border: !!error?.requirement ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="requirement"
            value={row.requirement}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
            }}
            type="number"
          />

          <Grid className="deleteIconGrid">
            {Can("indents_delete") && (
              <>
                {isShowDeleteIcon ? (
                  <DisabledDelete className="delete-icon" />
                ) : (
                  <DeleteIcon
                    className="delete-icon"
                    onClick={() => handleDelete(row.id, index)}
                  />
                )}
              </>
            )}

            <TextField
              width="0px"
              name="discountAmount"
              value={row.discountAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
            <TextField
              width="0px"
              name="taxAmount"
              value={row.taxAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);
export default ProductRow;
