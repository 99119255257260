import { useState, useEffect, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  Typography,
  Grid,
  CircularProgress,
  Switch,
  debounce,
  IconButton,
} from "@mui/material";
import {
  Sort,
  CustomButton,
  TextField,
  PageLoader,
} from "../../../../components/basic";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import { ActiveStatusColor, statusColor } from "../../../../utils/GeneralUtils";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import CustomFilter from "../../../../components/shared/customFilter";
import { displayText } from "../../../../constants/displayText";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  addDrugClassification,
  deleteDrugClassification,
  getAllDrugClassificationList,
  getDrugClassificationById,
  updateDrugClassification,
} from "../../../../services/procurementMasterService";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { setCurrentPage } from "../../../../redux/slices/pagination";
type GridRowData = Record<string, unknown>;

function DrugClassification() {
  const { ADD, EDIT, ADD_BUTTON_TITLE, CONFIRMATION_DELETE_DIALOG_TITLE } =
    displayText;
  const { procurementMasterDrugClarification } = useSelector(
    (state: RootState) => state.pagination
  );
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const [pageCount, setPageCount] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>();
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
  const [drugClassificationList, setDrugClassificationList] = useState([]);

  // Add And Edit dialog states and constants

  const [isAddAndEditDialogOpen, setIsAddAndEditDialogOpen] =
    useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isAddAndEditDialogLoading, setIsAddAndEditDialogLoading] =
    useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [drugToBeDeleted, setDrugToBeDeleted] = useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);

  // form states and constants

  const initialFormError = {
    name: "",
    shortName: "",
    active: "",
  };

  const [drugClarificationFormError, setDrugClarificationFormError] =
    useState<any>(initialFormError);

  const initialFormData = {
    name: "",
    active: true,
  };

  const [drugClarificationFormData, setDrugClarificationFormData] =
    useState<any>(initialFormData);

  const fieldData = {
    name: {
      label: "Name",
      name: "name",
      value: drugClarificationFormData.name,
      isError: Boolean(drugClarificationFormError.name),
      helperText: drugClarificationFormError.name,
    },
    active: {
      label: "Status",
      name: "active",
      value: drugClarificationFormData.active,
      isError: Boolean(drugClarificationFormError.active),
      helperText: drugClarificationFormError.active,
    },
  };
  const dispatch = useDispatch();
  const [drugClarificationFormFieldData, setDrugClarificationFormFieldData] =
    useState<any>(fieldData);
  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllDrugClassificationList(data)
        .then((res: any) => {
          setDrugClassificationList(res.data.result);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          const errorMessage = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.error("An error occurred:", error);
        });
    }, 300),
    []
  );
  const [filterData, setFilterData] = useState({ search: "" });
  useEffect(() => {
    const data = {
      page: Number(procurementMasterDrugClarification.page) + 1,
      pageSize: procurementMasterDrugClarification.pageSize,
      column: sortedField.field,
      order: sortedField.order,
      search: filterData.search,
    };
    debouncedGetAllAppointment(data);
  }, [
    procurementMasterDrugClarification.page,
    procurementMasterDrugClarification.pageSize,
    sortedField.order,
    sortedField.field,
    filterData.search,
  ]);
  const handleSearchInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;
      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        return newFilter;
      });
    }
    dispatch(
      setCurrentPage({ key: "procurementMasterDrugClarification", value: 0 })
    );
  };
  const deleteDrug = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setDrugToBeDeleted(row.id);
  };
  const deleteProductById = async () => {
    if (drugToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    await deleteDrugClassification(drugToBeDeleted as number)
      .then((res: any) => {
        setDrugToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        debouncedGetAllAppointment({
          page: Number(procurementMasterDrugClarification.page) + 1,
          pageSize: procurementMasterDrugClarification.pageSize,
          column: sortedField.field,
          order: sortedField.order,
          search: filterData.search,
        });
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        setDeleteBtnLoader(false);
      })
      .catch((err) => {
        setDeleteBtnLoader(false);
        setDrugToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        const errorMessage = err?.response?.data?.errors
          ? err?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        console.log(err);
      });
  };
  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          onClick={() => {
            setIdToBeUpdated(row.id);
            openAddAndEditDialog();
          }}
        >
          <EditIcon />
        </IconButton>

        <IconButton onClick={() => deleteDrug(row)}>
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "active",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row.active : null;
        const { buttonText, backgroundColor, textColor } =
          ActiveStatusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setDrugClarificationFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setDrugClarificationFormError);
        }
        break;
      }
      default:
        break;
    }
  };
  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateProductsFieldData = () => {
    setDrugClarificationFormFieldData((prev: any) => {
      return Object?.keys(prev)?.map((field: any) => {
        return {
          ...field,
          value: drugClarificationFormData[field.name],
          isError: Boolean(drugClarificationFormError[field.name]),
          helperText: drugClarificationFormError[field.name],
        };
      });
    });
  };
  const [isAddAndEditLoading, setIsAddAndEditLoading] = useState(false);

  const createProduct = async () => {
    if (!Boolean(drugClarificationFormData.name)) {
      validateForm();
    } else {
      setIsAddAndEditLoading(true);
      setIsButtonLoading(true);
      const data = {
        name: drugClarificationFormData.name,
        active: drugClarificationFormData.active === false ? 0 : 1,
      };
      await addDrugClassification(data)
        .then((res: any) => {
          setIsButtonLoading(false);
          closeAddAndEditDialog();
          debouncedGetAllAppointment({
            page: Number(procurementMasterDrugClarification.page) + 1,
            pageSize: procurementMasterDrugClarification.pageSize,
            column: sortedField.field,
            order: sortedField.order,
            search: filterData.search,
          });
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        })
        .catch((err) => {
          setIsButtonLoading(false);
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
        });
    }
  };
  const updateProduct = async () => {
    if (!Boolean(drugClarificationFormData.name)) {
      validateForm();
    } else {
      setIsAddAndEditLoading(true);
      setIsButtonLoading(true);

      const data = {
        name: drugClarificationFormData.name,
        active: drugClarificationFormData.active === false ? 0 : 1,
      };
      await updateDrugClassification(idToBeUpdated as number, data)
        .then((res: any) => {
          setIsButtonLoading(false);
          closeAddAndEditDialog();
          debouncedGetAllAppointment({
            page: Number(procurementMasterDrugClarification.page) + 1,
            pageSize: procurementMasterDrugClarification.pageSize,
            column: sortedField.field,
            order: sortedField.order,
            search: filterData.search,
          });
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          setIsButtonLoading(false);
        });
    }
  };
  const handleConfirmationDelete = async () => {};

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setDrugClarificationFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setDrugClarificationFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };
  useEffect(() => {
    if (idToBeUpdated) {
      setIsAddAndEditDialogLoading(true);
      getDrugClassificationById(idToBeUpdated)
        .then((res: any) => {
          const supplierDetail: any = res.data;
          setDrugClarificationFormData((prevState: any) => ({
            ...prevState,
            name: supplierDetail.name,
            active: supplierDetail.active === 0 ? false : true,
          }));
          setIsAddAndEditDialogLoading(false);
        })
        .catch((error) => {
          setIsAddAndEditDialogLoading(false);
          const errorMessage = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.error("An error occurred:", error);
        });
    }
  }, [idToBeUpdated]);
  useEffect(() => {
    updateProductsFieldData();
  }, [drugClarificationFormError, drugClarificationFormData]);

  // Add And Edit dialog functions

  const openAddAndEditDialog = async () => {
    setIsAddAndEditDialogOpen(true);
  };

  const closeAddAndEditDialog = async () => {
    setIsButtonLoading(false);
    setIsAddAndEditDialogOpen(false);
    setDrugClarificationFormData(initialFormData);
    setIdToBeUpdated(null);
    setDrugClarificationFormError(initialFormError);
  };

  // confirmation dialog functions

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={CONFIRMATION_DELETE_DIALOG_TITLE}
        handleClick={deleteProductById}
        onClose={closeConfirmationDialog}
        loading={deleteBtnLoader}
      />
      <CustomFilter
        searchValue={filterData.search}
        clearSearch={() => {
          setFilterData({ search: "" });
          dispatch(
            setCurrentPage({
              key: "procurementMasterDrugClarification",
              value: 0,
            })
          );
        }}
        isSearchEnabled={true}
        editButtonTitle={ADD_BUTTON_TITLE}
        onUpdateButtonClick={openAddAndEditDialog}
        searchOnChange={handleSearchInputChange}
        searchName={"search"}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={drugClassificationList}
        pageCount={pageCount}
        currentPage={"procurementMasterDrugClarification"}
      />
      <DialogWrapper
        onClose={closeAddAndEditDialog}
        open={isAddAndEditDialogOpen}
        handleClick={idToBeUpdated === null ? createProduct : updateProduct}
        title={
          idToBeUpdated === null
            ? "Add Drug Clarification"
            : "Edit Drug Clarification"
        }
        loading={isButtonLoading}
        maxWidth="sm"
      >
        {isAddAndEditDialogLoading ? (
          <PageLoader />
        ) : (
          <>
            <TextField
              value={fieldData.name.value}
              name={fieldData.name.name}
              onChange={handleInputChange}
              label={fieldData.name.label}
              placeholder="Enter Name"
              helperText={fieldData.name.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.name }))
              }
              sx={{
                width: "100%",
              }}
            />

            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "5px",
                mt: "5px",
              }}
            >
              <Typography>{fieldData.active.label}</Typography>
              <Switch
                name={fieldData.active.name}
                checked={fieldData.active.value}
                onChange={handleSwitchChange}
                disabled={Boolean(!idToBeUpdated)}
                value={fieldData.active.value}
              />
            </Grid>
          </>
        )}
      </DialogWrapper>
    </>
  );
}

export default DrugClassification;
