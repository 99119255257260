import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import {
  DatePickerLeftArrow,
  DatePickerRightArrow,
} from "../../../assets/icons";
import "./DatePickerWithTime.css";
import { FormControl, Grid, FormHelperText, InputLabel } from "@mui/material";
import InputIcon from "react-multi-date-picker/components/input_icon";

type DatePickerWithTimeTypes = {
  className?: string;
  width?: string;
  height?: string;
  name?: string; // New prop
  placeholder?: string;
  value?: any; // New prop
  onChange?: (value: any, newValue: any) => void;
  label?: string;
  style?: {};
  formControlStyle?: {};
  error?: boolean;
  format?: string; // New prop
  borderColor?: string;
  hideSeconds?: boolean;
  focused?: boolean;
  ref?: any;
  helperText?: string;
  onBlur?: any;
  isDisabled?: boolean;
  isEditable?: boolean;
  minDate?: any;
  maxDate?: any;
};

function CustomArrowButton(
  direction: string,
  handleClick: any,
  disabled: boolean
) {
  return (
    <i
      onClick={handleClick}
      style={{
        width: "",
        padding: "0 10px",
        fontWeight: "bold",
        color: disabled ? "gray" : "blue",
      }}
      className={disabled ? "cursor-default" : "cursor-pointer"}
    >
      {direction === "right" ? (
        <DatePickerRightArrow style={{ cursor: "pointer" }} />
      ) : (
        <DatePickerLeftArrow style={{ cursor: "pointer" }} />
      )}
    </i>
  );
}
const DatePickerWithTime = ({
  className,
  width,
  height = "40px",
  name,
  placeholder,
  value,
  onChange,
  style,
  label,
  error,
  borderColor = "var( --grey-lighter)",
  format = "DD/MM/YYYY hh:mm:ss A",
  hideSeconds = true,
  focused,
  ref,
  helperText,
  formControlStyle,
  onBlur,
  isDisabled,
  isEditable = true,
  minDate,
  maxDate,
}: DatePickerWithTimeTypes) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleClose = () => {
    setIsFocused(false);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === "Tab") {
      handleClose();
    }
  };

  return (
    <Grid
      sx={{
        "& .datepickerwithtime .rmdp-wrapper": {
          border: "1px solid var( --grey-lighter)",
          // currently commented for time set in bottom
          // display: isFocused ? "flex" : "none",
        },
      }}
    >
      <InputLabel
        focused={isFocused || focused}
        sx={{
          fontSize: "12px",
          color: isFocused ? "primary.main" : "greyScale.main",
          marginBottom: "4px",
        }}
        error={error}
      >
        {label}
      </InputLabel>
      <FormControl className="datepickerwithtime" sx={{ ...formControlStyle }}>
        <DatePicker
          ref={ref}
          disableDayPicker={false}
          format={format}
          plugins={[<TimePicker position="bottom" hideSeconds={hideSeconds} />]}
          monthYearSeparator=" "
          showOtherDays
          renderButton={CustomArrowButton}
          highlightToday={false}
          arrow={false}
          placeholder={placeholder}
          editable={isEditable}
          minDate={minDate}
          maxDate={maxDate}
          render={
            <InputIcon
              onBlur={onBlur}
              onKeyDown={handleKeyDown}
              // placeholder={placeholder}
              style={{
                boxShadow: "var(--input-boxShadow)",
                border: error
                  ? "1px solid #d32f2f"
                  : isFocused
                  ? "2px solid var(--primary-main)"
                  : `1px solid ${borderColor}`,
                width: width,
                height: height,
                borderRadius: "8px",
                padding: isFocused ? "2px 9px" : "3px 10px",
                fontFamily: "Inter, sans-serif",
                fontSize: "12px",
                backgroundColor: isDisabled ? "#F4F4F5" : "initial",
                ...style,
              }}
            />
          }
          value={value}
          onChange={onChange}
          onOpen={handleFocus}
          onClose={handleClose}
          disabled={isDisabled}
          style={{
            color: isDisabled ? "greyScale.main" : "textPrimary.main",
            backgroundColor: isDisabled ? "greyScale.lighter" : "initial",
          }}
        />
      </FormControl>
      {helperText !== undefined && (
        <FormHelperText
          error={error}
          sx={{
            ml: 2,
            fontSize: "11px",
            minHeight: "20px",
            height: "auto",
            color: "#d32f2f",
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default DatePickerWithTime;
