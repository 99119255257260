import { Box, Grid } from "@mui/material";
import Info from "./info";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CancelButton,
  PageLoader,
  SaveButton,
} from "../../../../components/basic";
import {
  getPurchaseOrderById,
  updatePurchaseOrderById,
} from "../../../../services/procurementService";
import moment from "moment";
import ProductTable from "./ProductTable";
import { setSnackBarSuccess } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

const ProductTableMemoized = React.memo(ProductTable);

const PurchaseOrderView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [info, setInfo] = useState<{
    po_no: string;
    supplier: string;
    date: any;
    status: string;
    products: any[];
  }>({
    po_no: "",
    supplier: "",
    date: null,
    status: "",
    products: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isStatusDisabled, setIsStatusDisabled] = useState<boolean>(false);

  const handleInfoChange = useCallback((event: any) => {
    const { name, value } = event.target;
    setInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleChange = useCallback((event: any, index: number) => {
    const { name, value } = event.target;
    setInfo((prevState: any) => ({
      ...prevState,
      products: prevState?.products?.map((ele: any, i: number) => {
        if (index === i) {
          ele[name] = value;

          if (name === "ptr") {
            ele.value = value * ele?.order_qty;
          }
        }

        return {
          ...ele,
        };
      }),
    }));
  }, []);

  const handleDeleteProduct = useCallback((data: any) => {
    setInfo((prevState: any) => ({
      ...prevState,
      products: prevState?.products?.filter(
        (ele: any, i: number) => ele.id !== data?.id
      ),
    }));
  }, []);

  const handleSaveData = () => {
    if (id) {
      setIsSaveLoading(true);
      updatePurchaseOrderById(id, {
        status: info.status,
        products: info?.products?.map((ele: any) => ({
          id: ele.id,
          ptr: ele.ptr,
          value: ele.value,
        })),
      })
        .then((res: any) => {
          dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
          navigate(-1);
          setIsSaveLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsSaveLoading(false);
        });
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getPurchaseOrderById(id)
        .then((res: any) => {
          if (res.data) {
            setInfo({
              po_no: res?.data?.sequence_no,
              supplier: res?.data?.supplier?.name,
              date: res?.data?.date_created
                ? moment(res?.data?.date_created).format(
                    "YYYY-MM-DD hh:mm:ss A"
                  )
                : null,
              status: res?.data?.status?.toString(),
              products: res?.data?.purchase_order_products,
            });

            let isStatusDisabled = false;
            res?.data?.purchase_order_products?.forEach((ele: any) => {
              if (ele?.received_qty && !isStatusDisabled) {
                isStatusDisabled = true;
              }
            });

            if (isStatusDisabled) {
              setIsStatusDisabled(isStatusDisabled);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Box>
          <Info
            info={info}
            onChange={handleInfoChange}
            isStatusDisabled={isStatusDisabled}
          />
          <ProductTableMemoized
            data={info.products}
            onChange={handleChange}
            id={id}
            onDelete={handleDeleteProduct}
          />
          <Grid sx={{ m: "35px 0 45px 0" }}>
            <SaveButton
              loading={isSaveLoading}
              handleClick={handleSaveData}
              sx={{ width: "100px", height: "42px", mr: "20px" }}
              disabled={isSaveLoading}
            />
            <CancelButton
              sx={{ width: "100px", height: "42px" }}
              isDisabled={isSaveLoading}
            />
          </Grid>
        </Box>
      )}
    </>
  );
};

export default PurchaseOrderView;
