import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Button,
  debounce,
} from "@mui/material";
import {
  CancelButton,
  SaveButton,
  AutoComplete,
} from "../../../../../components/basic";
import { DeleteIcon } from "../../../../../assets/icons";
import {
  DataTable,
  ConfirmationDialog,
} from "../../../../../components/shared";
import { GridColDef } from "@mui/x-data-grid";
import {
  getAdmissionIpTreatmentsById,
  createAdmissionIpTreatmentById,
  deleteAdmissionIpTreatmentById,
  getAllRooms,
  getAllTreatmentItems,
  getConsultantsList,
} from "../../../../../services/admissionService";
import { ipAdmissionConst } from "../../../../../constants/displayText";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../../redux/slices/snackbar";
import Row from "./row";
import PermissionUtils from "../../../../../utils/PermissionUtils";
import { useDispatch, useSelector } from "react-redux";
import { setTreatmentsList } from "../../../../../redux/slices/inpatients";
import { RootState } from "../../../../../redux/store";

type PropsType = {
  ipAdmissionDetails?: any;
};

const Treatments = ({ ipAdmissionDetails }: PropsType) => {
  const styles = useMemo(
    () => ({
      textFieldStyle: {
        width: {
          xs: "200px",
          xl: "275px",
        },
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "5px",
        },
      },
      autocompleteStyle: {
        width: {
          xs: "200px",
          xl: "275px",
        },
        height: "45px",
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
          padding: "5px 0px",
          boxShadow: "none",
          borderRadius: "5px",
        },
      },
    }),
    []
  );

  const dispatch = useDispatch();
  const { treatmentsList } = useSelector(
    (state: RootState) => state.inPatients
  );

  const { ADDED_SUCCESSFULLY, UPDATED_SUCCESSFULLY, DELETED_SUCCESSFULLY } =
    ipAdmissionConst;
  const { can } = PermissionUtils();

  // common variables

  const [admissionId, setAdmissionId] = useState<any>(ipAdmissionDetails?.id);
  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);

  // dialog and loader variables
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isConfirmationDialogButtonLoading,
    setIsConfirmationDialogButtonLoading,
  ] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] =
    useState<boolean>(false);

  //field variables

  const [roomOptions, setRoomOptions] = useState<any>([]);
  const [itemOptions, setItemOptions] = useState<any>([]);
  const [consultantOptions, setConsultantOptions] = useState<any>([]);
  const [treatmentsData, setTreatmentsData] = useState<any>([]);
  const [isAutocompleteOptionsLoading, setIsAutocompleteOptionsLoading] =
    useState<boolean>(false);

  const [validationErrors, setValidationErrors]: any = useState<any>({
    treatments: {},
  });

  const initialFormData = {
    consultant: null,
    consultantInput: "",
    ward: ipAdmissionDetails?.bed,
    wardInput: `${ipAdmissionDetails?.floor_name} / ${ipAdmissionDetails?.room_no}`,
    treatments: [
      {
        id: Date.now(),
        item: null,
        itemInput: "",
        quantity: null,
        instruction: "",
      },
    ],
  };

  const [formData, setFormData] = useState<any>(initialFormData);

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <IconButton
          onClick={() => {
            setIsConfirmationDialogOpen(true);
            setIdToBeDeleted(row?.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.4,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            S.No
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.sNo}
        </Typography>
      ),
      minWidth: 40,
      sortable: false,
    },
    {
      field: "consultant",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Consultant
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.doctor_name}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "items/particulars",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Items/Particulars
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.item_name}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "quantity",
      flex: 0.5,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Quantity
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.quantity}
        </Typography>
      ),
      minWidth: 50,
      sortable: false,
    },
    {
      field: "instructions",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Instructions
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.instruction}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.created_at}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      field: "ward",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Ward
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {`${row?.floor_name} / ${row?.room_no}`}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "user",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.created_user}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    ...(can("ip_treatments_delete")
      ? [
          {
            field: "actions",
            flex: 0.6,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  height: "51px",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 60,
            sortable: false,
          },
        ]
      : []),
  ];

  // field onchange functions

  const handleInputChange = useCallback(
    (e: any, id: any) => {
      const { name, value } = e.target;

      let numericValue = value;

      if (name === "quantity") {
        numericValue = value.replace(/[^\d.]/g, "").slice(0, 10);
      }

      setFormData((prev: any) => {
        return {
          ...prev,
          treatments: prev?.treatments?.map((row: any) => {
            if (row.id === id) {
              return { ...row, [name]: numericValue };
            }
            return row;
          }),
        };
      });

      if (
        validationErrors?.treatments[id] &&
        validationErrors?.treatments[id][name]
      ) {
        setValidationErrors((prev: any) => ({
          ...prev,
          treatments: {
            ...prev?.treatments,
            [id]: {
              ...prev?.treatments[id],
              [name]: "",
            },
          },
        }));
      }
    },
    [validationErrors]
  );

  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, name: string, treatmentId: any) => {
      if (newValue === null) {
        return false;
      }
      if (name === "ward" || name === "consultant") {
        setFormData((prev: any) => ({
          ...prev,
          [name]: newValue,
        }));

        if (validationErrors[name]) {
          setValidationErrors((prev: any) => ({
            ...prev,
            [name]: "",
          }));
        }
      } else {
        setFormData((prev: any) => {
          return {
            ...prev,
            treatments: prev?.treatments?.map((row: any) => {
              if (row.id === treatmentId) {
                return { ...row, [name]: newValue };
              }
              return row;
            }),
          };
        });

        if (
          validationErrors?.treatments[treatmentId] &&
          validationErrors?.treatments[treatmentId][name]
        ) {
          setValidationErrors((prev: any) => ({
            ...prev,
            treatments: {
              ...prev?.treatments,
              [treatmentId]: {
                ...prev?.treatments[treatmentId],
                [name]: "",
              },
            },
          }));
        }
      }
    },
    [validationErrors]
  );

  const handleAutoCompleteInputChange = useCallback(
    (e: any, newInputValue: any, name: string, treatmentId: any) => {
      if (
        newInputValue === "" &&
        name === "wardInput" &&
        formData.ward !== null
      ) {
        return false;
      }

      if (name === "wardInput" || name === "consultantInput") {
        setFormData((prev: any) => ({
          ...prev,
          [name]: newInputValue,
        }));
      } else {
        setFormData((prev: any) => {
          return {
            ...prev,
            treatments: prev?.treatments?.map((row: any) => {
              if (row.id === treatmentId) {
                return { ...row, [name]: newInputValue };
              }
              return row;
            }),
          };
        });
      }

      if (name === "itemInput") {
        getAllBillingItemsList(newInputValue);
      }
    },
    [validationErrors]
  );

  const handleTreatmentAdd = useCallback(() => {
    setFormData((prev: any) => {
      return {
        ...prev,
        treatments: [
          ...prev.treatments,
          {
            id: Date.now(),
            item: null,
            itemInput: "",
            quantity: null,
            instruction: "",
          },
        ],
      };
    });
  }, [validationErrors]);

  const handleTreatmentDelete = useCallback(
    (id: any) => {
      setFormData((prev: any) => {
        return {
          ...prev,
          treatments: prev?.treatments?.filter(
            (treatment: any) => treatment.id !== id
          ),
        };
      });
    },
    [validationErrors]
  );

  const resetTreatments = () => {
    setFormData(initialFormData);
  };

  // api call functions to get select options

  const getAllRoomsList = async () => {
    try {
      await getAllRooms().then((result: any) => {
        let data = result?.data?.result;

        const modifiedData = data?.map((item: any) => {
          return {
            id: item?.id,
            name: `${item?.floor_name} / ${item?.room_no}`,
          };
        });

        setRoomOptions(modifiedData);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllBillingItemsList = useCallback(
    debounce(async (search?: any) => {
      try {
        const data = {
          search: search,
          page: 1,
          limit: "20",
          order: "ASC",
          type: "ip_treatments",
        };
        setIsAutocompleteOptionsLoading(true);
        await getAllTreatmentItems(data).then((result: any) => {
          let data = result?.data?.result;

          const newArray = [...itemOptions, ...data];

          const updatedArray = newArray.filter(
            (value, index, self) =>
              index === self.findIndex((t) => t.id === value.id)
          );

          setItemOptions(updatedArray);
          setIsAutocompleteOptionsLoading(false);
        });
      } catch (error) {
        setIsAutocompleteOptionsLoading(false);
        console.error("An error occurred:", error);
      }
    }, 300),
    []
  );

  const getAllConsultantsList = async () => {
    try {
      await getConsultantsList().then((result: any) => {
        let data = result?.data;

        setConsultantOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // crud operation api calls functions

  const getAdmissionIpTreatments = async () => {
    if (!admissionId) {
      return false;
    }
    try {
      setIsPageLoading(true);
      setIsDataTableLoading(true);
      await getAdmissionIpTreatmentsById(admissionId).then((result: any) => {
        let data = result?.data;

        if (data) {
          const modifiedData = data?.map((item: any, index: number) => ({
            ...item,
            sNo: index + 1,
          }));

          dispatch(setTreatmentsList(modifiedData));
        }
        setIsPageLoading(false);
        setIsDataTableLoading(false);
      });
    } catch (error) {
      setIsPageLoading(false);
      setIsDataTableLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const createAdmissionIpTreatments = async () => {
    const errors: any = { treatments: {} };

    formData?.treatments?.forEach((data: any, index: any) => {
      const error: any = {};

      if (!data.item) {
        error.item = "*item is required";
      }

      if (!data.quantity) {
        error.quantity = "*quantity is required";
      }

      if (Object.keys(error).length > 0) {
        errors.treatments[data.id] = error;
      }
    });

    if (formData.ward === null) {
      errors["ward"] = "*Ward is required field";
    }

    if (formData.consultant === null) {
      errors["consultant"] = "*Consultant is required field";
    }

    if (Object?.keys(errors)?.length > 1) {
      setValidationErrors(errors);
      return;
    } else if (Object?.keys(errors?.treatments)?.length > 0) {
      setValidationErrors(errors);
      return;
    } else {
      try {
        setIsSaveButtonLoading(true);

        const treatments = formData?.treatments?.map((treatment: any) => {
          return {
            item: treatment?.item?.id,
            quantity: treatment?.quantity,
            instruction: treatment?.instruction,
          };
        });

        const data = {
          ip_admission_id: admissionId,
          consultant_id: formData?.consultant.id,
          ward_id: formData?.ward?.id ? formData?.ward?.id : formData?.ward,
          treatments: treatments,
        };

        await createAdmissionIpTreatmentById(data).then((result: any) => {
          let data = result?.data;

          resetTreatments();
          setIsSaveButtonLoading(false);
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          getAdmissionIpTreatments();
        });
      } catch (error: any) {
        setIsSaveButtonLoading(false);
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error.response.data.message,
          })
        );
      }
    }
  };

  const handleDelete = async (id: any) => {
    try {
      setIsConfirmationDialogButtonLoading(true);
      await deleteAdmissionIpTreatmentById(id).then((result: any) => {
        getAdmissionIpTreatments();

        dispatch(
          setSnackBarSuccess({
            snackBarMessage: DELETED_SUCCESSFULLY,
          })
        );

        setIsConfirmationDialogOpen(false);
        setIsConfirmationDialogButtonLoading(false);
        setIdToBeDeleted(null);
      });
    } catch (error) {
      setIsConfirmationDialogOpen(false);
      setIsConfirmationDialogButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "something went wrong",
        })
      );
      console.error("An error occurred:", error);
    }
  };

  // dialog functions

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };

  useEffect(() => {
    getAllRoomsList();
    getAllBillingItemsList();
    getAllConsultantsList();
  }, []);

  useEffect(() => {
    if (!treatmentsList) {
      getAdmissionIpTreatments();
    }
  }, [admissionId]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "95vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "40vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid sx={{ display: "flex", flexDirection: "column" }}>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isConfirmationDialogButtonLoading}
      />

      {can("ip_treatments_add") ? (
        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h2" sx={{ m: "20px 0px" }}>
            Add Treatments
          </Typography>

          <Grid
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              gap: 2,
            }}
          >
            <AutoComplete
              label="Ward"
              placeholder="Select Ward"
              name="ward"
              value={formData.ward}
              inputValue={formData.wardInput}
              disabled={can("ip_treatments_ward_change") ? false : true}
              options={roomOptions}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(e, newValue, "ward", null)
              }
              onInputChange={(e: any, newValue: any) =>
                handleAutoCompleteInputChange(e, newValue, "wardInput", null)
              }
              helperText={validationErrors["ward"]}
              width="275px"
              sx={{ ...styles.autocompleteStyle }}
            />
            <AutoComplete
              label="Consultant"
              placeholder="Select Consultant"
              name="consultant"
              value={formData.consultant}
              inputValue={formData.consultantInput}
              options={consultantOptions}
              helperText={validationErrors["consultant"]}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(e, newValue, "consultant", null)
              }
              onInputChange={(e: any, newValue: any) =>
                handleAutoCompleteInputChange(
                  e,
                  newValue,
                  "consultantInput",
                  null
                )
              }
              width="275px"
              sx={{ ...styles.autocompleteStyle }}
            />
            <Grid sx={{ display: "flex", flexDirection: "column" }}>
              {formData?.treatments?.map((treatment: any, index: number) => {
                return (
                  <Row
                    styles={styles}
                    index={index}
                    row={treatment}
                    itemOptions={itemOptions}
                    handleInputChange={handleInputChange}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                    handleAutoCompleteInputChange={
                      handleAutoCompleteInputChange
                    }
                    handleTreatmentDelete={handleTreatmentDelete}
                    isAutocompleteOptionsLoading={isAutocompleteOptionsLoading}
                    validationErrors={validationErrors}
                  />
                );
              })}
              <Button
                variant="text"
                sx={{
                  width: "50px",
                  height: "50px",
                  textTransform: "none",
                  m: "10px 0px",
                }}
                onClick={handleTreatmentAdd}
              >
                <Typography variant="h4">+Add</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item sx={{ display: "flex", mt: 2 }}>
            <SaveButton
              buttonText={"Add"}
              loading={isSaveButtonLoading}
              handleClick={createAdmissionIpTreatments}
              sx={{ width: "88px", height: "38px" }}
            />
            <CancelButton
              buttonText={"Reset"}
              handleClick={resetTreatments}
              sx={{ width: "88px", height: "38px", ml: 3 }}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Divider sx={{ mt: 3, color: "#D9D9D9" }} />
      <Grid sx={{ m: "20px 0px" }}>
        <Typography variant="h2" sx={{ m: "20px 0px" }}>
          Added Treatments
        </Typography>
        <DataTable
          loading={isDataTableLoading}
          columns={columns}
          rows={treatmentsList || []}
          tableOnly={true}
          customizedTable={true}
        />
      </Grid>
    </Grid>
  );
};

export default Treatments;
