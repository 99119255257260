import { Box, Grid, InputAdornment } from "@mui/material";
import { TextField } from "../../../../../components/basic";
import { inputEndValue } from "../../../../../constants/displayText";
import { EditFollowUpIcon, SuggestionsIcon } from "../../../../../assets/icons";
export default function DetailsPage({
  followUpDetail,
  handleEditFollowup,
  personalInfoList,
}: {
  followUpDetail: any;
  handleEditFollowup: (id: number | string, details: any) => void;
  personalInfoList: any;
}) {
  const findingsLabel: any = {
    findings: "Findings",
    tests: "Tests",
    scans: "Scans",
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          marginBottom: "5px",
          cursor: "pointer",
        }}
        onClick={() => handleEditFollowup(followUpDetail.id, followUpDetail)}
      >
        <EditFollowUpIcon />
      </Box>
      <Grid container>
        {personalInfoList.map((ele: any, idx: number) => (
          <Grid
            item
            sm={ele.isFullWidth ? 6 : 3}
            sx={{
              marginBottom: "10px",
              marginTop: "10px",
              paddingRight: "12px",
              display: "flex",
              flexDirection: "row",
              justifyContent: `${ele.flex} !important`,
            }}
          >
            <TextField
              name={ele.name}
              label={ele.label}
              value={ele.value}
              width={ele.isFullWidth ? "370px" : "155px"}
              disabled={ele.disabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {inputEndValue[ele.name]}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        ))}
      </Grid>
      {followUpDetail["findings"] && followUpDetail["findings"]?.length > 0 && (
        <Box sx={{ marginTop: "15px" }}>
          <Box
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              marginBottom: "5px",
              color: "#8A8A8A",
            }}
          >
            {findingsLabel["findings"]}
          </Box>
          <Box
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "6px",
              height: "48px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              border: "1px solid #EAEAEA",
            }}
          >
            <p
              style={{
                color: "#8A8A8A",
                fontSize: "14px",
                lineHeight: "16px",

                marginTop: "14px",
                marginLeft: "7px",
              }}
            >
              {followUpDetail["findings"]
                ?.map((ele: any) => {
                  return ele.symptom_name;
                })
                .join(",")}
            </p>
          </Box>
        </Box>
      )}
      {followUpDetail["tests"] && followUpDetail["tests"]?.length > 0 && (
        <Box sx={{ marginTop: "15px" }}>
          <Box
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              marginBottom: "5px",
              color: "#8A8A8A",
            }}
          >
            {findingsLabel["tests"]}
          </Box>
          <Box
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "6px",
              height: "48px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              border: "1px solid #EAEAEA",
            }}
          >
            <p
              style={{
                color: "#8A8A8A",
                fontSize: "14px",
                lineHeight: "16px",

                marginTop: "14px",
                marginLeft: "7px",
              }}
            >
              {followUpDetail["tests"]
                ?.map((ele: any) => {
                  return ele.name;
                })
                .join(",")}
            </p>
          </Box>
        </Box>
      )}
      {followUpDetail["scans"] && followUpDetail["scans"]?.length > 0 && (
        <Box sx={{ marginTop: "15px" }}>
          <Box
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              marginBottom: "5px",
              color: "#8A8A8A",
            }}
          >
            {findingsLabel["scans"]}
          </Box>
          <Box
            sx={{
              backgroundColor: "#F9F9F9",
              borderRadius: "6px",
              height: "48px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              border: "1px solid #EAEAEA",
            }}
          >
            <p
              style={{
                color: "#8A8A8A",
                fontSize: "14px",
                lineHeight: "16px",
                marginTop: "14px",
                marginLeft: "7px",
              }}
            >
              {followUpDetail["scans"]
                ?.map((ele: any) => {
                  return ele.name;
                })
                .join(",")}
            </p>
          </Box>
        </Box>
      )}
      {followUpDetail["suggestions"] &&
        followUpDetail["suggestions"].length > 0 && (
          <Box sx={{ marginTop: "15px", marginBottom: "12px" }}>
            <Box
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                marginBottom: "5px",
                color: "#8A8A8A",
              }}
            >
              Suggestions
            </Box>

            {followUpDetail["suggestions"].map((ele: any) => (
              <Box
                sx={{
                  backgroundColor: "#F9F9F9",
                  borderRadius: "6px",
                  height: "48px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  border: "1px solid #EAEAEA",
                  padding: "8px 9px 11px",
                  color: "#8A8A8A",
                  marginTop: "7px",
                  marginBottom: "7px",
                }}
              >
                <Box>
                  <SuggestionsIcon />
                </Box>
                <Box
                  sx={{
                    padding: "0px 14px",
                    width: "100%",
                    marginTop: "5px",
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: "10px",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    {ele?.product.name}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      fontWeight: 500,
                      fontSize: "12px",
                    }}
                  >
                    <Box>{`${ele?.freq | 0} | ${ele?.dosage || 0}`}</Box>
                    <Box>{`Starts - ${ele?.start_date || 0} Ends: ${
                      ele?.end_date || "No Date"
                    }`}</Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
    </>
  );
}
