import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Grid, Box } from "@mui/material";
import { DialogWrapper, Tabs } from "../../../components/shared";
import {
  InvestigationIcon,
  MedicalRecordIcon,
  PrescriptionIcon,
  VitalsIcon,
  HighlightedMedicalRecordIcon,
  HighlightedInvestigationIcon,
  HighlightedPrescriptionIcon,
  HighlightedVitalsIcon,
  OverViewIcon,
  HighlightedOverviewIcon,
} from "../../../assets/icons";
import { Select, PageLoader } from "../../../components/basic";
import Vitals from "./Vitals";
import Prescriptions from "./Prescriptions";
import Investigations from "./Investigations";
import MedicalRecord from "./MedicalRecords";
import { appointmentDetailsConst } from "../../../constants/displayText";
import { setSnackBarSuccess } from "../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RouteUrls } from "../../../constants/routes";
import { AppDispatch } from "../../../redux/store";
import { setIsLoading, setIsPageLoading } from "../../../redux/slices/loader";
import {
  appointmentInfo,
  cancelAppointmentDetails,
} from "../../../services/appointmentService";
import PermissionUtils from "../../../utils/PermissionUtils";
import Overview from "./Overview";

const MemoizedTabs = React.memo(Tabs);

const AppointmentsDetails = () => {
  const { can } = PermissionUtils();
  const dispatch = useDispatch<AppDispatch>();

  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const IsPageLoading = useSelector((state: any) => state.loader.isPageLoading);
  const { appointmentId } = useParams();
  const [appointmentCancel, setAppointmentCancel] = useState(false);
  const [cancelReason, setCancelReason] = useState<any>("");
  const [appointmentsDetails, setAppointmentsDetails] = useState<any>(null);
  const [appointmentCancelLoading, setAppointmentCancelLoading] =
    useState(false);
  const [appointId, setAppointId] = useState("");

  const [initialTabValue, setInitialTabValue] = useState<number>(0);

  const tabsValues: any = [
    ...(can("appointments_view") && appointmentsDetails
      ? [
          {
            Icon: <OverViewIcon />,
            highlightedIcon: <HighlightedOverviewIcon />,
            label: "Overview",
            children: (
              <Overview
                appointmentsDetails={appointmentsDetails}
                appointmentId={appointmentId}
                setAppointmentsDetails={setAppointmentsDetails}
              />
            ),
          },
        ]
      : []),
    ...(can("appointments_view") &&
    appointmentsDetails &&
    appointmentsDetails?.status !== 2
      ? [
          {
            Icon: <VitalsIcon />,
            highlightedIcon: <HighlightedVitalsIcon />,
            label: "Vitals",
            children: (
              <Vitals
                appointmentsDetails={appointmentsDetails}
                appointmentId={appointmentId}
              />
            ),
          },
        ]
      : []),
    ...(can("appointments_view") &&
    can("prescription_view") &&
    appointmentsDetails &&
    appointmentsDetails?.status !== 2
      ? [
          {
            Icon: <PrescriptionIcon />,
            highlightedIcon: <HighlightedPrescriptionIcon />,
            label: "Prescriptions",
            children: (
              <Prescriptions appointmentsDetails={appointmentsDetails} />
            ),
          },
        ]
      : []),
    ...(can("appointments_view") &&
    can("op_investigation_view") &&
    appointmentsDetails &&
    appointmentsDetails?.status !== 2
      ? [
          {
            Icon: <InvestigationIcon />,
            highlightedIcon: <HighlightedInvestigationIcon />,
            label: "Investigations",
            children: (
              <Investigations appointmentsDetails={appointmentsDetails} />
            ),
          },
        ]
      : []),
    ...(can("appointments_view") &&
    can("medirecord_view") &&
    appointmentsDetails &&
    appointmentsDetails?.status !== 2
      ? [
          {
            Icon: <MedicalRecordIcon />,
            highlightedIcon: <HighlightedMedicalRecordIcon />,
            label: "Medical Record",
            children: (
              <MedicalRecord appointmentsDetails={appointmentsDetails} />
            ),
          },
        ]
      : []),
  ];

  const Reasons = [
    { id: "I am busy", name: "I am busy" },
    {
      id: "Forgot about the appointment",
      name: "Forgot about the appointment",
    },
    { id: "Visited another doctor", name: "Visited another doctor" },
    { id: "Change my mind", name: "Change my mind" },
    { id: "Clinic/Hospital is far", name: "Clinic/Hospital is far" },
    { id: "Doctor asked me to cancel", name: "Doctor asked me to cancel" },
    { id: "Others", name: "Others" },
  ];

  const getAppointmentsDetails = async () => {
    if (appointmentId === undefined) {
      return false;
    }
    try {
      dispatch(setIsLoading(true));
      dispatch(setIsPageLoading(true));
      await appointmentInfo(appointmentId)
        .then((result: any) => {
          let data = result?.data?.appointment;
          setAppointId(data?.id);
          setAppointmentsDetails(data);
        })
        .then((result: any) => {
          dispatch(setIsLoading(false));
          dispatch(setIsPageLoading(false));
        });
    } catch (error) {
      console.error("An error occurred:", error);
      dispatch(setIsLoading(false));
      dispatch(setIsPageLoading(false));
    }
  };

  const cancelAppointment = async () => {
    if (cancelReason === "") {
      return false;
    }
    const data = {
      appoint_id: appointId,
      status: "2",
      reason: cancelReason,
    };
    try {
      setAppointmentCancelLoading(true);
      cancelAppointmentDetails(data).then((result: any) => {
        setCancelReason("");
        setAppointmentCancel(!appointmentCancel);
        getAppointmentsDetails();
        dispatch(
          setSnackBarSuccess({
            snackBarMessage:
              appointmentDetailsConst.APPOINTMENT_CANCELLED as string,
          })
        );
        setAppointmentCancelLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getAppointmentsDetails();
  }, []);

  useEffect(() => {
    if (type === "prescriptions") {
      setInitialTabValue(1);
    }
  }, [type]);

  return (
    <>
      {IsPageLoading ? (
        <PageLoader />
      ) : (
        <Grid
          className="appointment-details"
          sx={{
            width: { xs: "100vw", sm: "92vw" },
            mt: { xs: "0px", sm: "-20px" },
          }}
        >
          <MemoizedTabs
            data={tabsValues as any}
            initialValue={initialTabValue}
            scrollButtons={true}
            sx={{ width: "100%", boxSizing: "border-box" }}
          />
        </Grid>
      )}
      <DialogWrapper
        open={appointmentCancel}
        title={"Cancel Appointment"}
        onClose={() => setAppointmentCancel(!appointmentCancel)}
        handleClick={cancelAppointment}
        confirmText="Yes"
        cancelText="No"
        buttonWidth="80px"
        buttonHeight="36px"
        maxWidth="sm"
        loading={appointmentCancelLoading}
      >
        <Box>
          <Box sx={{ display: "flex", gap: 1, mb: 3 }}>
            Are you sure you want to cancel this appointment ?
          </Box>
          <Select
            label="Cancel Reason"
            placeholder="Select Reason"
            options={Reasons}
            value={cancelReason}
            onChange={(e: any) => {
              setCancelReason(e.target.value);
            }}
          />
        </Box>
      </DialogWrapper>
    </>
  );
};

export default AppointmentsDetails;
