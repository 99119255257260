import React, { useState } from "react";
import { Typography, Box, Button, FormHelperText, Link } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import { ToolTips } from "../../../components/basic";
import { TokenImg } from "../../../assets/images";

interface TimeSlot {
  id: number;
  label: string;
  link_appoint_id: string;
}

type TabContentProps = {
  value: string;
  availableSlots: number;
  bookedSlots: number;
  timeSlots: any;
  name?: any;
  handleButtonClick: (id: number, time: string) => void;
  selectedBox: string;
  sx?: {};
  error?: boolean;
  helperText?: string;
  onBlur?: any;
  checkStatus?: boolean;
  loading?: boolean;
  processChart?: boolean;
};

const TabContent = ({
  value,
  availableSlots,
  bookedSlots,
  timeSlots,
  handleButtonClick,
  selectedBox,
  name,
  sx,
  error,
  helperText,
  onBlur,
  checkStatus,
  loading,
  processChart,
}: TabContentProps) => {
  const styles = {
    buttonStyle: {
      width: "108px",
      height: " 49px",
      m: "0 23px 20px 0",
      fontSize: "15px",
    },
  };

  return (
    <TabPanel value={value} sx={{ p: "0 0 0 3px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "45vh",
          maxHeight: "auto",
        }}
      >
        {checkStatus ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              mt: 5,
              gap: 1,
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{
                  display: "flex",
                  backgroundColor: "secondary.main",
                  color: "backgroundPrimary.main",
                  justifyContent: "center",
                  alignItems: "center",
                  p: "10px 15px",
                  width: "500px",
                }}
              >
                An Appointment has been already booked for this patient
              </Typography>
            </Box>

            <Typography variant="h3">
              Appointment Date: 02/01/2024 Time: 10:30 AM
            </Typography>
            <Link underline="none" sx={{ fontWeight: 600, fontSize: 15 }}>
              View details
            </Link>
            <Typography variant="h3">
              Please call hospital for any help.
            </Typography>
            <Typography variant="h3">Helpline: ( 9994502102 )</Typography>
          </Box>
        ) : processChart ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              height: "45vh",
            }}
          >
            <Typography variant="h5" color="greyScale.main">
              Choose a doctor for consultation → Select consultation type →
              Choose Patient name from list
            </Typography>
          </Box>
        ) : (
          <>
            {availableSlots + bookedSlots !== 0 ? (
              <>
                <Box sx={{ display: "flex", gap: 2, p: "17px 75px", ...sx }}>
                  <Typography variant="h5" color="#207DFF">
                    Available Slots - {availableSlots}
                  </Typography>
                  <Typography variant="h5" color="#17CF9D">
                    Booked Slots - {bookedSlots}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",

                    alignItems: "center",
                    justifyContent: { xs: "center", lg: "left" },
                  }}
                >
                  {timeSlots?.map((slot: any) => (
                    <React.Fragment key={slot.id}>
                      {slot.link_appoint_id && (
                        <ToolTips
                          PatientName={slot.patient_name}
                          personalId={slot.link_appoint_id}
                          phone={slot.patient_mobile}
                          consultationFor={slot.purpose}
                        >
                          <Button
                            name={name}
                            // onClick={() => handleButtonClick(slot.id, slot.slot_name)}
                            variant="outlined"
                            sx={{
                              backgroundColor: "#17CF9D",
                              cursor: "pointer",
                              color: "backgroundPrimary.main",
                              border: "none",
                              "&:hover": {
                                border: "none",
                                backgroundColor: "#17CF9D",
                                color: "backgroundPrimary.main",
                              },
                              ...styles.buttonStyle,
                            }}
                          >
                            {slot.label}
                          </Button>
                        </ToolTips>
                      )}
                      {!slot.link_appoint_id && (
                        <Button
                          name={name}
                          onClick={() =>
                            handleButtonClick(slot.id, slot.slot_name)
                          }
                          variant="outlined"
                          sx={{
                            backgroundColor:
                              selectedBox === slot.slot_name
                                ? "primary.main"
                                : "transparent",
                            cursor: "pointer",
                            color:
                              selectedBox === slot.slot_name
                                ? "backgroundPrimary.main"
                                : "primary.main",
                            border: "1px solid primary.main",
                            "&:hover": {
                              backgroundColor:
                                selectedBox === slot.slot_name
                                  ? "primary.main"
                                  : "primary.lighter",
                            },
                            ...styles.buttonStyle,
                          }}
                        >
                          {slot.label}
                        </Button>
                      )}
                    </React.Fragment>
                  ))}
                </Box>
                <FormHelperText
                  error={error}
                  sx={{
                    ml: 2,
                    display: "flex",
                    justifyContent: "bottom",
                    alignItems: "flex-start",
                  }}
                >
                  {helperText}
                </FormHelperText>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "45vh",
                }}
              >
                <TokenImg />
                <Typography variant="h5" color="greyScale.main">
                  "No Slots Issued"
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
    </TabPanel>
  );
};

export default TabContent;
