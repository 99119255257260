import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

type ProcurementState = {
  isAdvance: null | number;
  goodsIssueType: string | null;
};

const initialProcurementState: ProcurementState = {
  isAdvance: null,
  goodsIssueType: "",
};

const procurementSlice = createSlice({
  name: "procurement",
  initialState: initialProcurementState,
  reducers: {
    setIsAdvance: (state, action: PayloadAction<null | number>) => {
      state.isAdvance = action.payload;
    },
    setGoodsIssueType: (state, action: PayloadAction<null | string>) => {
      state.goodsIssueType = action.payload;
    },
  },
});

export const { setIsAdvance, setGoodsIssueType } = procurementSlice.actions;

export default procurementSlice.reducer;