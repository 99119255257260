import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styleText } from "../../../constants/displayText";
import { CheckBox } from "..";

type MultiSelectWithCheckboxProps = {
  label?: string;
  value?: undefined | string[] | any;
  name?: string;
  focused?: boolean;
  formControlStyle?: {};
  width?: string;
  inputRef?: any;
  placeholder?: string;
  options?: { id: number | string; name: string }[];
  placeholderIcon?: React.ReactNode;
  sx?: {};
  menuStyle?: {};
  error?: boolean;
  helperText?: string;
  onFocus?: () => void;
  onChange?: (event: SelectChangeEvent<string>) => void;
  onBlur?: () => void;
  defaultValue?: string | undefined | string[] | any;
};

const MultiSelectWithCheckbox = ({
  label,
  value,
  name,
  focused,
  formControlStyle,
  width = styleText.DEFAULT_SELECT_WIDTH,
  inputRef,
  placeholderIcon,
  placeholder,
  options,
  sx,
  menuStyle,
  onFocus,
  onChange,
  onBlur,
  error,
  helperText,
  defaultValue,
}: MultiSelectWithCheckboxProps) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const selectRef: any = useRef();

  const [isFocused, setIsFocused] = useState(false);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: width
          ? (width as string | number)
          : selectRef?.current?.offsetWidth,
        ...menuStyle,
      },
    },
  };

  const styles = {
    formControl: {
      ...formControlStyle,
      mt: label ? "5px" : "0px",
    },
    select: {
      width: { width },
      pl: 1.8,
      boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      borderRadius: "8px",
      "& .MuiOutlinedInput-input": {
        padding: "14px 2px",
      },
      color: "textPrimary.main",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "greyScale.lighter",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "greyScale.lighter",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary.main",
      },
      "& .Mui-focused": {
        visibility: "hidden",
      },
      "& .MuiInputBase-root:has(.MuiSelect-iconOpen) .MuiInputBase-root.Mui-focused":
        {
          visibility: "visible",
        },
      "& .MuiTableCell-body:has(.Mui-focused)": {
        borderColor: "primary.main",
        borderWidth: "2px",
        p: "0px",
        height: "31px",
      },
    },
  };
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  return (
    <>
      {label && (
        <InputLabel
          focused={isFocused || focused}
          sx={{ color: "greyScale.main", fontSize: "12px" }}
        >
          {label}
        </InputLabel>
      )}
      <FormControl sx={{ ...styles.formControl }}>
        <Select
          ref={selectRef}
          displayEmpty
          autoWidth
          open={isFocused}
          onClose={() => {
            setIsFocused(false);
          }}
          onOpen={() => {
            setIsFocused(true);
          }}
          onFocus={onFocus}
          value={value || []}
          defaultValue={defaultValue}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          IconComponent={ExpandMoreIcon}
          inputRef={inputRef}
          multiple={true}
          input={
            <OutlinedInput
              startAdornment={
                placeholderIcon && (
                  <InputAdornment position="start">
                    {placeholderIcon}
                  </InputAdornment>
                )
              }
            />
          }
          renderValue={(selected: any) => {
            const selectedOption = options
              ?.filter((option: any) => selected?.includes(option.id))
              .map((option: any) => option.name)
              .join(", ");
            setCheckedItems(selected);

            if (!selectedOption) {
              return (
                <Box
                  sx={{
                    color: "textPrimary.main",
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                >
                  {placeholder}
                </Box>
              );
            }

            return (
              <Box
                sx={{
                  color: "textPrimary.main",
                  textAlign: "left",
                  fontSize: "12px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {selectedOption}
              </Box>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            ...styles.select,
            ...sx,
          }}
        >
          {options?.map((option: any, index: number) => (
            <MenuItem
              key={index}
              value={option.id}
              sx={{
                "&:hover:not(.Mui-focused)": {
                  backgroundColor: "#ededed",
                },
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                },
                borderRadius: "5px",
                p: "0px 16px",
                m: "0 5px",
              }}
            >
              <CheckBox
                checked={checkedItems?.includes(option.id)}
                formControlStyle={{
                  "&.MuiFormControlLabel-root": { display: "block" },
                }}
              />
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText error={error} sx={{ ml: 2, fontSize: "11px" }}>
        {helperText}
      </FormHelperText>
    </>
  );
};

export default MultiSelectWithCheckbox;
