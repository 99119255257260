import { combineReducers } from "@reduxjs/toolkit";
import layoutReducer from "./layout";
import paginationReducer from "./pagination";
import snackBar from "./snackbar";
import authReducer from "./auth";
import loader from "./loader";
import appConfiguration from "./appconfiguration";
import patientPaymentReducer from "./patientPayment";
import procurementReducer from "./procurement";
import gynecology from "./gynecology";
import inPatients from "./inpatients";

export const reducer = {
  layout: layoutReducer,
  pagination: paginationReducer,
  snackBar: snackBar,
  auth: authReducer,
  loader: loader,
  appConfiguration: appConfiguration,
  patientPayment: patientPaymentReducer,
  procurement: procurementReducer,
  gynecology: gynecology,
  inPatients: inPatients,
};

export const createReducer = (asyncReducers = {}) =>
  combineReducers({
    ...asyncReducers,
    ...reducer,
  });
