import {
    laboratoryApiEndPoints,
    procurementsApiEndPoints,
  } from "../constants/apiEndPoints";
  import ApiUtil from "../utils/ApiUtils";
  const {
    laboratoryReports,
    labResults,
    laboratoryProducts,
    labSupplierUrl,
    labPurchaseBillUrl,
    laboratoryPurchaseOrderUrl,
    LaboratoryProducts,
    laboratoryTaxUrl,
    labIndentsUrl,
    LabPurchaseOrderUrl,
    labSuppliersUrl,
  } = laboratoryApiEndPoints;
  type DepartmentType = {
    type?: string;
    active?: number;
  };
  type LabPurchaseBillType = {
    supplier_id?: number | string | null;
    bill_id?: number | string | null;
  };
  
  type LabPurchasePaymentTypes = {
    supplier_id?: string | number | null;
    cash_paid?: string | number | null;
    created_date?: string | number | null;
    bill_payment_type?: string | number | null;
    urn_number?: string | number | null;
    payments?: [
      {
        bill_id?: string | number | null;
        paid_amount?: string | number | null;
        remarks?: string | number | null;
      }
    ];
    used_amount?: string | number | null;
    excess_amount?: string | number | null;
    refunded_excess?: string | number | null;
    net_excess?: string | number | null;
  };
  
  type LabPurchaseProductType = {
    supplier_id?: string | number | null;
    dept_id?: string | number | null;
    to_dept?: string | number | null;
    invoice_value?: string | number | null;
    invoice_date?: string | number | null;
    ref_no?: string | number | null;
    ref_no_modified?: string | number | null;
    product?: [
      {
        product_id?: string | number | null;
        pack_type?: string | number | null;
        return_qty?: string | number | null;
        free_return_qty?: string | number | null;
        discount?: string | number | null;
        batch_no?: string | number | null;
        expires_at?: string | number | null;
        tax_id?: string | number | null;
        tax_type?: string | number | null;
        p_rate?: string | number | null;
        amount?: string | number | null;
      }
    ];
    bill_amt?: string | number | null;
    total_amt?: string | number | null;
  };
  
  type LabPurchaseReturnProductType = {
    supplier_id: number | string | null;
    dept_id?: number | string | null;
    selected_batches: number[] | string[] | [];
    search?: number | string | null;
  };
  
  type PurchaseProductType = {
    supplier_id?: string | number | null;
    dept_id?: string | number | null;
    to_dept?: string | number | null;
    invoice_value?: string | number | null;
    invoice_date?: string | number | null;
    ref_no?: string | number | null;
    ref_no_modified?: string | number | null;
    product?: [
      {
        product_id?: string | number | null;
        pack_type?: string | number | null;
        return_qty?: string | number | null;
        free_return_qty?: string | number | null;
        discount?: string | number | null;
        batch_no?: string | number | null;
        expires_at?: string | number | null;
        tax_id?: string | number | null;
        tax_type?: string | number | null;
        p_rate?: string | number | null;
        amount?: string | number | null;
      }
    ];
    bill_amt?: string | number | null;
    total_amt?: string | number | null;
  };
  
  const setQueryParams = (data: any, baseUrl: string) => {
    const keys = Object.keys(data);
  
    let queryString = "?";
    keys.map((key) => {
      if (data[key] && !Array.isArray(data[key])) {
        queryString += `${key}=${data[key]}&`;
      }
      if (Array.isArray(data[key])) {
        data[key].map(
          (ele: any, index: number) => (queryString += `${key}[${index}]=${ele}&`)
        );
      }
    });
    if (queryString.endsWith("&")) {
      queryString = queryString.slice(0, -1);
    }
  
    const apiUrl = baseUrl + queryString;
  
    return apiUrl;
  };
  const getAllDepartments = async (): Promise<{
    data: { active: number; dept_name: string; id: number; type: string }[];
  }> => {
    try {
      return (await ApiUtil.getWithToken(
        procurementsApiEndPoints.getAllDepartments
      )) as {
        data: { active: 1; dept_name: string; id: 1; type: string }[];
      };
      // return ApiUtil.getWithToken(laboratoryApiEndPoints.getAllDepartments) as {
      //   data: { active: 1; dept_name: string; id: 1; type: string }[];
      // };
    } catch (error) {
      console.log("Error Occurs while getting the departments:", error);
      throw error;
    }
  };
  
  const getAllLabBills = async (params: {
    patient_id: string | number;
    from_date: string | Date;
    to_date: string | Date;
    bill_no: string;
    search: string;
    column: string;
    order: string;
    page: number | string;
    limit: number | string;
  }) => {
    try {
      const apiUrl = setQueryParams(params, laboratoryReports);
      return await ApiUtil.getWithToken(apiUrl);
    } catch (error) {
      console.log("Error occurs while getting the lab bills:", error);
      throw error;
    }
  };
  
  const getLabReportDetailsByBillId = async (id: number | string) => {
    try {
      return await ApiUtil.getWithToken(`${laboratoryReports}/${id}/create`);
    } catch (error) {
      console.log(
        "Error occurs while getting the lab report details by id:",
        error
      );
      throw error;
    }
  };
  const getLabReportDetailsByRecordId = async (id: number | string) => {
    try {
      return await ApiUtil.getWithToken(`${laboratoryReports}/${id}`);
    } catch (error) {
      console.log(
        "Error occurs while getting the lab report details by id:",
        error
      );
      throw error;
    }
  };
  const getLabResults = async () => {
    try {
      const apiUrl = setQueryParams({ active: 1 }, labResults);
      return await ApiUtil.getWithToken(apiUrl);
    } catch (error) {
      console.log("Error occurs while getting the lab results:", error);
      throw error;
    }
  };
  
  const getLaboratoryProductOptions = async (params: {
    search: string;
    limit?: number;
    test_id?: number | string;
  }) => {
    try {
      const apiUrl = setQueryParams(params, laboratoryProducts);
      return await ApiUtil.getWithToken(apiUrl);
    } catch (error) {
      console.log("Error occurs while getting the lab product options:", error);
      throw error;
    }
  };
  
  const createLabBill = async (data: any) => {
    try {
      return await ApiUtil.postWithToken(laboratoryReports, data);
    } catch (error) {
      console.log("Error occurs while creating the lab bill:", error);
      throw error;
    }
  };
  
  const updateLabBill = async (id: number | string, data: any) => {
    try {
      return await ApiUtil.putWithToken(`${laboratoryReports}/${id}`, data);
    } catch (error) {
      console.log("Error occurs while creating the lab bill:", error);
      throw error;
    }
  };
  
  const deleteLabReportItemById = async (id: number | string) => {
    try {
      return await ApiUtil.deleteWithToken(`${laboratoryReports}/${id}`);
    } catch (error) {
      console.log(
        "Error occurs while deleting the lab report item by id:",
        error
      );
      throw error;
    }
  };
  
  const cancelLabReportItemById = async (id: number | string) => {
    try {
      return await ApiUtil.putWithToken(`${laboratoryReports}/${id}/item-cancel`);
    } catch (error) {
      console.log(
        "Error occurs while cancelling the lab report item by id:",
        error
      );
      throw error;
    }
  };
  
  const getAllLabPurchaseSuppliers = async (params: any) => {
    try {
      const apiUrl = setQueryParams(params, labSupplierUrl);
      return await ApiUtil.getWithToken(apiUrl);
    } catch (error) {
      console.log("Error occurs while getting the lab purchase supplier:", error);
      throw error;
    }
  };
  
  const getAllLabPurchaseBill = async (params: {
    page: number;
    limit: number;
    column: string;
    order: string;
    from_date: Date | string | null;
    to_date: Date | string | null;
    bill_no: string | number;
    product_name: string;
    supplier_id: string | number;
    search: string;
  }) => {
    try {
      const apiUrl = setQueryParams(params, labPurchaseBillUrl);
      return await ApiUtil.getWithToken(apiUrl);
    } catch (error) {
      console.log("Error occurs while getting the lab purchase supplier:", error);
      throw error;
    }
  };
  
  const updatePurchaseBillById = async (id: number | string, data: any) => {
    try {
      return await ApiUtil.putWithToken(`${labPurchaseBillUrl}/${id}`, data);
    } catch (error) {
      console.log("Error occurs while creating the purchase bill:", error);
      throw error;
    }
  };
  
  const createPurchaseBill = async (data: any) => {
    try {
      return await ApiUtil.postWithToken(labPurchaseBillUrl, data);
    } catch (error) {
      console.log("Error occurs while creating the purchase bill:", error);
      throw error;
    }
  };
  
  const getLabPurchaseBillById = async (params: LabPurchaseBillType) => {
    const apiUrl = setQueryParams(
      params,
      laboratoryApiEndPoints.getPurchasePaymentBill
    );
    return await ApiUtil.getWithToken(apiUrl);
  };
  
  const deleteLabPurchaseBillById = async (id: number | string) => {
    try {
      return await ApiUtil.deleteWithToken(`${labPurchaseBillUrl}/${id}`);
    } catch (error) {
      console.log(
        "Error occurs while deleting the lab purchase bill by id:",
        error
      );
      throw error;
    }
  };
  
  const cancelLabPurchaseBillById = async (id: number | string) => {
    try {
      return await ApiUtil.putWithToken(`${labPurchaseBillUrl}/${id}/cancel`);
    } catch (error) {
      console.log(
        "Error occurs while cancelling the purchase bill by id:",
        error
      );
      throw error;
    }
  };
  
  const getPoNoOptionsBySupplierId = async (params: any) => {
    try {
      const apiUrl = setQueryParams(params, laboratoryPurchaseOrderUrl);
      return await ApiUtil.getWithToken(apiUrl);
    } catch (error) {
      console.log("Error occurs while getting the purchase order:", error);
      throw error;
    }
  };
  
  const getProductByPoNo = async (po_no: any) => {
    try {
      return await ApiUtil.getWithToken(
        `${laboratoryPurchaseOrderUrl}/${po_no}/order`
      );
    } catch (error) {
      console.log("Error while getting the product by Po Number", error);
      throw error;
    }
  };
  
  const getAllLaboratoryProducts = async (params: {
    search?: string;
    department: string | number;
    start?: number;
    length?: number;
  }) => {
    try {
      // const apiUrl = setQueryParams(params, `${LaboratoryProducts}/all-products`);
      const apiUrl = setQueryParams(params, `${LaboratoryProducts}`);
      return await ApiUtil.getWithToken(apiUrl);
    } catch (error) {
      console.log("Error occurs while getting the procurement products:", error);
      throw error;
    }
  };
  
  const getTaxOptions = async () => {
    try {
      return await ApiUtil.getWithToken(`${laboratoryTaxUrl}/all-taxes`);
    } catch (error) {
      console.log("Error occurs while getting the tax options:", error);
      throw error;
    }
  };
  
  const getExcessAmountByPatientId = async (patient_id?: string | number) => {
    try {
      return {
        data: {
          excess: 100,
          advance: 100,
          creditlimit: 0,
          overalldue: 0,
          net_total: 0,
        },
      };
    } catch (error) {
      console.log("Error while getting the excess amount by patient id", error);
      throw error;
    }
  };
  
  const getAllIndents = async (params: {
    page: number;
    limit: number;
    column: number;
    order: number;
    indent_no: string;
    from_date: any;
    to_date: any;
    search: string;
  }): Promise<{
    data: { result: []; total: number | null };
  }> => {
    const apiUrl = setQueryParams(params, labIndentsUrl);
  
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: { result: []; total: number | null };
    };
  };
  
  const createIndentsById = async (data: any) => {
    try {
      return await ApiUtil.postWithToken(labIndentsUrl, data);
    } catch (error) {
      console.log("Error occurs while deleting the purchase bill:", error);
      throw error;
    }
  };
  
  const updateIndentsById = async (id: number | string, data: any) => {
    try {
      return await ApiUtil.putWithToken(`${labIndentsUrl}/${id}`, data);
    } catch (error) {
      console.log("Error occurs while deleting the purchase bill:", error);
      throw error;
    }
  };
  
  const deleteIndentById = async (id: string | number) => {
    try {
      return await ApiUtil.deleteWithToken(`${labIndentsUrl}/${id}`);
    } catch (error) {
      console.log("Error occurs while deleting the purchase bill:", error);
      throw error;
    }
  };
  
  const getIndentById = async (id: number | string) => {
    try {
      return await ApiUtil.getWithToken(`${labIndentsUrl}/${id}`);
    } catch (error) {
      console.log("Error occurs while getting the indent by id:", error);
      throw error;
    }
  };
  
  const createPo = async (data: any) => {
    try {
      return await ApiUtil.postWithToken(`${LabPurchaseOrderUrl}`, data);
    } catch (error) {
      console.log("Error occurs while creating the purchase order:", error);
      throw error;
    }
  };
  
  const getAllSuppliers = async (
    params: any
  ): Promise<{
    data: {
      active: number;
      address: string | null;
      email: null | string;
      gst_no: string;
      id: number;
      name: string;
      phone: string | null;
    }[];
  }> => {
    try {
      const apiUrl = setQueryParams(params, labSuppliersUrl);
      return (await ApiUtil.getWithToken(apiUrl)) as {
        data: {
          active: number;
          address: string | null;
          email: null | string;
          gst_no: string;
          id: number;
          name: string;
          phone: string | null;
        }[];
      };
    } catch (error) {
      console.log("Error Occurs while getting the Suppliers:", error);
      throw error;
    }
  };
  
  const getAllPurchaseOrders = async (params: {
    page: number;
    limit: number;
    column: string;
    order: string;
    searchValue: string;
  }): Promise<{
    data: { result: []; total: number | null };
  }> => {
    const apiUrl = setQueryParams(params, LabPurchaseOrderUrl);
  
    console.log("apiUrl", apiUrl);
  
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: { result: []; total: number | null };
    };
  };
  
  const updateLabPurchaseOrderById = async (id: number | string, data: any) => {
    try {
      return await ApiUtil.putWithToken(`${LabPurchaseOrderUrl}/${id}`, data);
    } catch (error) {
      console.log("Error occurs while updating the purchase order by id:", error);
      throw error;
    }
  };
  
  const getLabPurchaseOrderById = async (id: number | string) => {
    try {
      return await ApiUtil.getWithToken(`${LabPurchaseOrderUrl}/${id}`);
    } catch (error) {
      console.log("Error occurs while getting the purchase order by id:", error);
      throw error;
    }
  };
  type StockAdjustProductType = {
    department?: number | string | null;
    selected_batches: number[] | string[] | [];
    search?: number | string | null;
  };

  type LabGoodsIssueProductType = {
    department?: number | string | null;
    selected_batches: number[] | string[] | [];
    search?: number | string | null;
  };
  const getLabGoodsIssueProduct = async (params: LabGoodsIssueProductType) => {
    const apiUrl = setQueryParams(
      params,
      laboratoryApiEndPoints.getGoodsIssueProduct
    );
    return await ApiUtil.getWithToken(apiUrl);
  };
  const getAllLabMasterDepartments = async (
    data?: DepartmentType
  ): Promise<{
    data: { active: number; dept_name: string; id: number; type: string }[];
  }> => {
    try {
      const apiUrl = setQueryParams(
        data,
        laboratoryApiEndPoints.getAllMasterDepartments
      );
      return ApiUtil.getWithToken(apiUrl) as unknown as {
        data: { active: 1; dept_name: string; id: 1; type: string }[];
      };
    } catch (error) {
      console.log("Error Occurs while getting the departments:", error);
      throw error;
    }
  };
  const deleteLabGoodsIssueById = async (id: number | string) => {
    return await ApiUtil.deleteWithToken(
      `${laboratoryApiEndPoints.goodsIssueUrl}/${id}`
    );
  };
  const getLabGoodsIssueProductById = async (id: string | number) => {
    return await ApiUtil.getWithToken(
      `${laboratoryApiEndPoints.getGoodsIssueById}/${id}`
    );
  };
  const createLabGoodsIssue = async (data: any) => {
    return await ApiUtil.postWithToken(
      `${laboratoryApiEndPoints.goodsIssueUrl}`,
      data
    );
  };
  const updateLabGoodsIssueById = async (id: number | string, data: any) => {
    return await ApiUtil.putWithToken(
      `${laboratoryApiEndPoints.goodsIssueUrl}/${id}`,
      data
    );
  };
  const cancelLabGoodsIssue = async (id: number | string) => {
    return await ApiUtil.putWithToken(
      `${laboratoryApiEndPoints.goodsIssueUrl}/${id}/cancel`
    );
  };
  
  type LabGoodsIssueType = {
    page?: string | null | number;
    limit?: string | null | number;
    column?: string | null | number;
    order?: string | null | number;
    from_date?: string | null | number;
    to_date?: string | null | number;
    type?: string | null | number;
    invoice_no?: string | null | number;
    product?: string | null | number;
    search?: string | null | number;
  };
  const getAllLabGoodsIssue = async (
    data: LabGoodsIssueType
  ): Promise<{
    data: { result: []; total: number | null };
  }> => {
    const apiUrl = setQueryParams(data, laboratoryApiEndPoints.goodsIssueUrl);
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: { result: []; total: number | null };
    };
  };
  type LabGoodsReturnType = {
    to_dept?: number | string | null;
    from_dept?: number | string | null;
    selected_batches: number[] | string[] | [];
    search?: number | string | null;
  };
  const getLabGoodsReturnProducts = async (params: LabGoodsReturnType) => {
    const apiUrl = setQueryParams(
      params,
      laboratoryApiEndPoints.getGoodsIssueReturnProduct
    );
    return await ApiUtil.getWithToken(apiUrl);
  };
  const createLabGoodsIssueReturn = async (data: any) => {
    return await ApiUtil.postWithToken(
      `${laboratoryApiEndPoints.goodsIssueReturnUrl}`,
      data
    );
  };
  type LabPurchaseAdvanceTypes = {
    supplier_id: string | number | null;
    cash_paid: string | number | null;
    created_date: string | number | null;
    bill_payment_type: string | number | null;
    urn_number: string | number | null;
  };
  const createLabPurchaseAdvance = async (data: LabPurchaseAdvanceTypes) => {
    return await ApiUtil.postWithToken(
      `${laboratoryApiEndPoints.addPurchasePayment}`,
      data
    );
  };
  const createLabPurchasePayment = async (data: LabPurchasePaymentTypes) => {
    return await ApiUtil.postWithToken(
      `${laboratoryApiEndPoints.getPurchasePayment}`,
      data
    );
  };
  const getLabPurchaseBillDetails = async (id: number | string) => {
    return await ApiUtil.getWithToken(
      `${laboratoryApiEndPoints.getPurchasePayment}/${id}`
    );
  };
  const updateLabPurchasePayment = async (
    id: number | string,
    data: LabPurchaseBillType
  ) => {
    return await ApiUtil.putWithToken(
      `${laboratoryApiEndPoints.getPurchasePayment}/${id}`,
      data
    );
  };
  const deleteLabPurchaseBill = async (id: number | string) => {
    return await ApiUtil.deleteWithToken(
      `${laboratoryApiEndPoints.getPurchasePayment}/${id}`
    );
  };
  const getAllLabPurchasePayments = async (
    data: any
  ): Promise<{
    data: { result: []; total: number | null };
  }> => {
    const apiUrl = setQueryParams(
      data,
      laboratoryApiEndPoints.getAllPurchasePaymentsList
    );
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: { result: []; total: number | null };
    };
  };
  const cancelLabPurchaseBill = async (id: number | string) => {
    return await ApiUtil.putWithToken(
      `${laboratoryApiEndPoints.getPurchasePayment}/${id}/cancel`
    );
  };
  const updateLabPurchaseAdvance = async (
    id: number,
    data: LabPurchaseAdvanceTypes
  ) => {
    return await ApiUtil.postWithToken(
      `${laboratoryApiEndPoints.addPurchasePayment}`,
      data
    );
  };
  const cancelLabPurchaseBillPayment = async (id: number | string) => {
    return await ApiUtil.putWithToken(
      `${laboratoryApiEndPoints.getPurchasePayment}/${id}/cancel`
    );
  };
  const getLabPurchasePayment = async (id: number | string) => {
    return await ApiUtil.getWithToken(
      `${laboratoryApiEndPoints.getPurchasePayment}/${id}`
    );
  };
  const getAllLabSuppliers = async (
    params: any
  ): Promise<{
    data: {
      result: {
        active: number;
        address: string | null;
        email: null | string;
        gst_no: string;
        id: number;
        name: string;
        phone: string | null;
      };
    }[];
  }> => {
    try {
      const apiUrl = setQueryParams(
        params,
        laboratoryApiEndPoints.getAllLabSuppliers
      );
      return (await ApiUtil.getWithToken(apiUrl)) as {
        data: {
          result: {
            active: number;
            address: string | null;
            email: null | string;
            gst_no: string;
            id: number;
            name: string;
            phone: string | null;
          };
        }[];
      };
    } catch (error) {
      console.log("Error Occurs while getting the Suppliers:", error);
      throw error;
    }
  };
  
  const addLabPurchaseReturnProduct = async (data: LabPurchaseProductType) => {
    return await ApiUtil.postWithToken(
      `${laboratoryApiEndPoints.labPurchaseReturn}`,
      data
    );
  };
  
  const getDepartmentSequence = async (department?: string | number) => {
    try {
      return await ApiUtil.getWithToken(
        `${procurementsApiEndPoints.sequenceUrl}/${department}`
      );
    } catch (error) {
      console.log("Error while getting the item name list", error);
      throw error;
    }
  };
  const getStockAdjustProduct = async (params: StockAdjustProductType) => {
    const apiUrl = setQueryParams(
      params,
      procurementsApiEndPoints.stockAdjustmentById
    );
    return await ApiUtil.getWithToken(apiUrl);
  }
  const getLabPurchaseReturnById = async (id: string | number) => {
    return await ApiUtil.getWithToken(
      `${laboratoryApiEndPoints.labPurchaseReturn}/${id}`
    );
  };
  
  const getLabPurchaseReturnProduct = async (
    params: LabPurchaseReturnProductType
  ) => {
    const apiUrl = setQueryParams(
      params,
      laboratoryApiEndPoints.getPurchaseReturnProduct
    );
    return await ApiUtil.getWithToken(apiUrl);
  };
  
  const updateLabPurchaseReturnProduct = async (
    id: number | string,
    data: PurchaseProductType
  ) => {
    return await ApiUtil.putWithToken(
      `${laboratoryApiEndPoints.labPurchaseReturn}/${id}`,
      data
    );
  };
  export {
    getAllLabSuppliers,
    getLabPurchasePayment,
    cancelLabPurchaseBillPayment,
    updateLabPurchaseAdvance,
    cancelLabPurchaseBill,
    getAllLabPurchasePayments,
    deleteLabPurchaseBill,
    getLabGoodsReturnProducts,
    createLabGoodsIssueReturn,
    getAllLabGoodsIssue,
    cancelLabGoodsIssue,
    getLabGoodsIssueProduct,
    updateLabGoodsIssueById,
    createLabGoodsIssue,
    getLabGoodsIssueProductById,
    deleteLabGoodsIssueById,
    getAllLabMasterDepartments,
    getAllLabBills,
    getLabResults,
    getLabReportDetailsByBillId,
    getLabReportDetailsByRecordId,
    getLaboratoryProductOptions,
    createLabBill,
    updateLabBill,
    deleteLabReportItemById,
    cancelLabReportItemById,
    getAllLabPurchaseSuppliers,
    getAllLabPurchaseBill,
    deleteLabPurchaseBillById,
    getAllDepartments,
    getPoNoOptionsBySupplierId,
    getProductByPoNo,
    getAllLaboratoryProducts,
    updatePurchaseBillById,
    createPurchaseBill,
    getTaxOptions,
    getExcessAmountByPatientId,
    getLabPurchaseBillById,
    cancelLabPurchaseBillById,
    getAllIndents,
    createIndentsById,
    updateIndentsById,
    deleteIndentById,
    getIndentById,
    createPo,
    getAllSuppliers,
    getAllPurchaseOrders,
    updateLabPurchaseOrderById,
    getLabPurchaseOrderById,
    createLabPurchaseAdvance,
    createLabPurchasePayment,
    getLabPurchaseBillDetails,
    updateLabPurchasePayment,
    addLabPurchaseReturnProduct,
    getDepartmentSequence,
    getLabPurchaseReturnById,
    getLabPurchaseReturnProduct,
    updateLabPurchaseReturnProduct,
    getStockAdjustProduct
  };