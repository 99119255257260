import { Box, Typography } from "@mui/material";
import { DialogWrapper } from "../../../../components/shared";
import { setSnackBarSuccess } from "../../../../redux/slices/snackbar";
import { useState } from "react";
import { RouteUrls } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createPo } from "../../../../services/laboratoryService";

const { laboratoryUrl, labPurchaseOrderUrl } = RouteUrls;
type ConfirmPoModalProps = {
  isOpen: boolean;
  onClose: () => void;
  data: any;
};

const styles = {
  headerContainerStyle: {
    width: "100%",
    borderTop: "1px solid #EBEBEB",
    borderBottom: "1px solid #EBEBEB",
    padding: "10px",
    display: "flex",
    alignItems: "center",
  },
  bodyContainerStyle: {
    width: "100%",
    borderTop: "1px solid #EBEBEB",
    borderBottom: "1px solid #EBEBEB",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    background: "#f9f9f9",
  },
  headerStyle: {
    width: "33%",
  },
};

const ConfirmPoModal = (props: ConfirmPoModalProps) => {
  const { isOpen, onClose, data } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const handleSave = () => {
    setIsSaveLoading(true);
    createPo({ ...data, purchaser_order: data.rows })
      .then((res: any) => {
        if (res.data) {
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          navigate(`${laboratoryUrl}${labPurchaseOrderUrl}`);
        }
        setIsSaveLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsSaveLoading(false);
      });
  };

  return (
    <DialogWrapper
      open={isOpen}
      onClose={onClose}
      handleClick={handleSave}
      loading={isSaveLoading}
      title="Confirm PO"
      confirmText={"Save"}
      cancelText={"Cancel"}
    >
      <Box>
        {data?.rows?.map((ele: any) => (
          <Box mb={2}>
            <Typography variant="h3" mb={2}>
              {ele.supplier?.name}
            </Typography>
            <Box sx={styles.headerContainerStyle}>
              <Typography sx={styles.headerStyle}>Product</Typography>
              <Typography sx={styles.headerStyle}>Required Qty</Typography>
              <Typography sx={styles.headerStyle}>Indent</Typography>
            </Box>
            {ele.items.map((item: any) => (
              <Box sx={styles.bodyContainerStyle}>
                <Typography sx={styles.headerStyle}>
                  {item.product_name}
                </Typography>
                <Typography sx={styles.headerStyle}>
                  {item.total_requirement}
                </Typography>
                <Typography sx={styles.headerStyle}>
                  {item.indent_qty}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </DialogWrapper>
  );
};

export default ConfirmPoModal;
