import { useState, useEffect, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Grid, Typography, debounce } from "@mui/material";
import {
  Sort,
  CustomButton,
  TextField,
  PageLoader,
  Select,
  SelectWithCancelChips,
  AutoCompleteWithCheckBoxes,
} from "../../../../components/basic";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import { ActiveStatusColor, statusColor } from "../../../../utils/GeneralUtils";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import CustomFilter from "../../../../components/shared/customFilter";
import { displayText } from "../../../../constants/displayText";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import {
  addMasterTestProducts,
  updateMasterTestProductsById,
  deleteMasterTestProducts,
  getAllLabTestProductList,
  getAllMasterTestProducts,
  getMasterTestProductsById,
  getAllMasterProductList,
} from "../../../../services/procurementMasterService";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import _without from "lodash/without";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

function TestProducts() {
  const { can } = PermissionUtils();

  const { ADD_BUTTON_TITLE, CONFIRMATION_DELETE_DIALOG_TITLE } = displayText;

  // data table constants and states

  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [loading, setLoading] = useState(false);
  const [testList, setTestList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [productsData, setProductsData] = useState<any[]>([]);
  const [isAddAndEditDialogOpen, setIsAddAndEditDialogOpen] =
    useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isAddAndEditDialogLoading, setIsAddAndEditDialogLoading] =
    useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);
  const [productList, setProductList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  // confirmation dialog states and constants

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [settestProductToBeDeleted, setTestProductToBeDeleted] =
    useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const { laboratoryMasterTestProducts } = useSelector(
    (state: RootState) => state.pagination
  );
  const [filterData, setFilterData] = useState({ test_name: "", search: "" });
  const initialFormError = {
    id: "",
    product_id: "",
  };

  const [supplierFormError, setSupplierFormError] =
    useState<any>(initialFormError);
  type InitialForDataTypes = {
    id: string | null | number;
    product_id: number[] | any;
    product_id_input: any;
    active: boolean;
  };

  const initialFormData: InitialForDataTypes = {
    id: null,
    product_id: [],
    product_id_input: "",
    active: true,
  };

  const [supplierFormData, setSupplierFormData] =
    useState<any>(initialFormData);
  const [pageCount, setPageCount] = useState(null);
  const [isAutoCompleteLoading, setIsAutoCompleteLoading] = useState(false);

  const fieldData = {
    id: {
      label: "Add Test",
      name: "id",
      value: supplierFormData.id,
      isError: Boolean(supplierFormError.id),
      helperText: supplierFormError.id,
    },
    product_id: {
      label: "Add Product",
      name: "product_id",
      value: supplierFormData.product_id,
      inputValue: supplierFormData.product_id_input,
      isError: Boolean(supplierFormError.product_id),
      helperText: supplierFormError.product_id,
    },
    active: {
      label: "Status",
      name: "active",
      value: supplierFormData.active,
      isError: supplierFormError.active === "" ? false : true,
      helperText: supplierFormError.active,
    },
  };

  const [departmentFieldData, setDepartmentFieldData] =
    useState<any>(fieldData);

  // data table columns and actions
  const deleteProductById = async () => {
    if (settestProductToBeDeleted === null) {
      return false;
    }

    setDeleteBtnLoader(true);
    await deleteMasterTestProducts(settestProductToBeDeleted as number)
      .then((res: any) => {
        setTestProductToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        debouncedGetAllAppointment({
          page: Number(laboratoryMasterTestProducts.page) + 1,
          pageSize: laboratoryMasterTestProducts.pageSize,
          column: sortedField.field,
          order: sortedField.order,
          search: filterData.search,
          test_name: filterData.test_name,
        });
        dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
        setDeleteBtnLoader(false);
      })
      .catch((err) => {
        setDeleteBtnLoader(false);
        setTestProductToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        const errorMessage = err?.response?.data?.errors
          ? err?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        console.log(err);
      });
  };

  const deleteVacation = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setTestProductToBeDeleted(row.id);
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {can("lab_test_products_edit") && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIdToBeUpdated(row.id);
              openAddAndEditDialog();
            }}
          >
            <EditIcon />
          </div>
        )}

        {can("lab_test_products_delete") && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => deleteVacation(row)}
          >
            <DeleteIcon />
          </div>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "test_name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("test_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "test_name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "test_name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.test_name}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "active",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row.active : null;
        const { buttonText, backgroundColor, textColor } =
          ActiveStatusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    ...(can("lab_test_products_edit") || can("lab_test_products_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  height: "51px",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "id": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setSupplierFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setSupplierFormError);
        }
        break;
      }
      case "product_id": {
        const newValue = value?.map((item: any) => item?.name).join(", ");

        if (requiredValidator(newValue, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(newValue, label),
            setSupplierFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setSupplierFormError);
        }
        break;
      }
      default:
        break;
    }
  };
  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateProductsFieldData = () => {
    setDepartmentFieldData((prev: any) => {
      return Object?.keys(prev)?.map((field: any) => {
        return {
          ...field,
          value: supplierFormData[field.name],
          isError: supplierFormError[field.name] === "" ? false : true,
          helperText: supplierFormError[field.name],
        };
      });
    });
  };

  // form API call functions
  const dispatch = useDispatch();
  const createProduct = async () => {
    if (
      supplierFormData.id === null ||
      supplierFormData?.product_id?.length < 1
    ) {
      validateForm();
    } else {
      const data = {
        id: supplierFormData?.id,
        product_id: supplierFormData?.product_id?.map((item: any) => item?.id),
      };
      setIsButtonLoading(true);
      await addMasterTestProducts(data)
        .then((res: any) => {
          closeAddAndEditDialog();
          dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
          debouncedGetAllAppointment({
            page: Number(laboratoryMasterTestProducts.page) + 1,
            pageSize: laboratoryMasterTestProducts.pageSize,
            column: sortedField.field,
            order: sortedField.order,
            search: filterData.search,
            test_name: filterData.test_name,
          });
          setIsButtonLoading(false);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          setIsButtonLoading(false);
        });
    }
  };
  const updateProduct = async () => {
    if (
      supplierFormData?.id === null ||
      supplierFormData?.product_id?.length < 1
    ) {
      validateForm();
    } else {
      setIsButtonLoading(true);
      const data = {
        id: supplierFormData?.id,
        product_id: supplierFormData?.product_id?.map((item: any) => item?.id),
      };
      await updateMasterTestProductsById(idToBeUpdated as number, data)
        .then((res: any) => {
          setIsButtonLoading(false);
          closeAddAndEditDialog();
          dispatch(setSnackBarSuccess({ snackBarMessage: res?.message }));
          debouncedGetAllAppointment({
            page: Number(laboratoryMasterTestProducts.page) + 1,
            pageSize: laboratoryMasterTestProducts.pageSize,
            column: sortedField.field,
            order: sortedField.order,
            search: filterData.search,
            test_name: filterData.test_name,
          });
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          setIsButtonLoading(false);
        });
    }
  };

  // form fields onchange functions

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setSupplierFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setSupplierFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };

  useEffect(() => {
    updateProductsFieldData();
  }, [supplierFormError, supplierFormData]);

  // Add And Edit dialog functions

  const openAddAndEditDialog = async () => {
    setIsAddAndEditDialogOpen(true);
  };

  const closeAddAndEditDialog = async () => {
    setIsButtonLoading(false);
    setIsAddAndEditDialogOpen(false);
    setSupplierFormData(initialFormData);
    setIdToBeUpdated(null);
    setSupplierFormError(initialFormError);
    setInputValue("");
  };

  useEffect(() => {
    if (idToBeUpdated) {
      setIsAddAndEditDialogLoading(true);
      getMasterTestProductsById(idToBeUpdated)
        .then((res: any) => {
          const supplierDetail: any = res.data;
          setSupplierFormData((prevState: InitialForDataTypes) => ({
            ...prevState,
            id: supplierDetail.id,
            product_id: supplierDetail.test_products,
          }));
          setIsAddAndEditDialogLoading(false);
        })
        .catch((err) => {
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          setIsAddAndEditDialogLoading(false);
          console.log(err);
        });
    }
  }, [idToBeUpdated]);

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };
  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllLabTestProductList(data)
        .then((res: any) => {
          setSupplierList(res?.data?.result);
          setPageCount(res?.data?.total as any);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );

  const handleSearchInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;
      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        return newFilter;
      });
    }
    dispatch(setCurrentPage({ key: "laboratoryMasterTestProducts", value: 0 }));
  };

  useEffect(() => {
    const data = {
      page: Number(laboratoryMasterTestProducts.page) + 1,
      pageSize: laboratoryMasterTestProducts.pageSize,
      column: sortedField.field,
      order: sortedField.order,
      search: filterData.search,
      test_name: filterData.test_name,
    };
    debouncedGetAllAppointment(data);
  }, [
    laboratoryMasterTestProducts.page,
    laboratoryMasterTestProducts.pageSize,
    sortedField.order,
    sortedField.field,
    filterData.search,
    filterData.test_name,
  ]);

  // custom component and it's function

  const [appliedFilterCount, setAppliedFilterCount] = useState(0);

  const handleFilterInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;

      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        const appliedFilterCount =
          Object.values(newFilter).filter(Boolean).length;
        setAppliedFilterCount(appliedFilterCount);
        return newFilter;
      });
    }
  };
  const productFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <TextField
          value={filterData.test_name}
          placeholder={"Enter Test Name"}
          label={"Test Name"}
          formControlStyle={{ width: "100%" }}
          onChange={handleFilterInputChange}
          name={"test_name"}
        />
      ),
    },
  ];

  const clearFilters = () => {
    if (filterData.test_name) {
      setFilterData({
        ...filterData,
        test_name: "",
      });
      setAppliedFilterCount(0);
    }
  };

  useEffect(() => {
    getAllMasterTestProducts()
      .then((res: any) => {
        const newState = res?.data?.map(
          (ele: { name: string; id: number | string }) => ({
            id: ele?.id,
            name: ele?.name,
          })
        );
        setTestList(newState);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getAllProductList = debounce(async (search?: any) => {
    try {
      setIsAutoCompleteLoading(true);
      const data = {
        search: search,
        limit: "40",
      };
      return getAllMasterProductList(data).then((result: any) => {
        const data = result?.data?.result?.map(
          (ele: { name: string; id: number | string }) => ({
            id: ele?.id,
            name: ele?.name,
          })
        );

        setProductList(data);
        setIsAutoCompleteLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setIsAutoCompleteLoading(false);
    }
  }, 200);

  useEffect(() => {
    if (inputValue.length >= 3 && inputValue !== "") {
      getAllProductList(inputValue);
    }
  }, [inputValue]);

  const handleAutoCompleteWithCheckBoxChange = useCallback(
    (e: any, newValue: any) => {
      setSupplierFormData({
        ...supplierFormData,
        product_id: newValue,
      });
      handleValidation({
        target: {
          name: "product_id",
          value: newValue,
        },
      });
    },

    [handleValidation]
  );

  const handleAutoCompleteWithCheckBoxInputChange = (e: any, newValue: any) => {
    if (newValue === "") {
      return false;
    }
    setInputValue(newValue);
  };
  useEffect(() => {
    const handleBackspace = (event: any) => {
      if (event.key === "Backspace" && inputValue.length === 1) {
        setInputValue("");
      }
    };

    window.addEventListener("keydown", handleBackspace);

    return () => {
      window.removeEventListener("keydown", handleBackspace);
    };
  }, [inputValue]);

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={CONFIRMATION_DELETE_DIALOG_TITLE}
        handleClick={deleteProductById}
        onClose={closeConfirmationDialog}
        loading={deleteBtnLoader}
      />
      <CustomFilter
        isSearchEnabled={true}
        appliedFilterCount={appliedFilterCount}
        clearFilter={clearFilters}
        data={productFilter}
        searchValue={filterData.search}
        clearSearch={() => {
          setFilterData({
            ...filterData,
            search: "",
          });
          dispatch(
            setCurrentPage({ key: "laboratoryMasterTestProducts", value: 0 })
          );
        }}
        isFilterEnabled={true}
        searchName="search"
        searchOnChange={handleSearchInputChange}
        {...(can("lab_test_products_add") && {
          editButtonTitle: ADD_BUTTON_TITLE,
          onUpdateButtonClick: openAddAndEditDialog,
        })}
      />
      <DataTable
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={supplierList}
        pageCount={pageCount}
        loading={loading}
        currentPage={"laboratoryMasterTestProducts"}
      />
      <DialogWrapper
        onClose={closeAddAndEditDialog}
        open={isAddAndEditDialogOpen}
        handleClick={idToBeUpdated === null ? createProduct : updateProduct}
        title={
          idToBeUpdated === null ? "Add Test Product" : "Edit Test Product"
        }
        loading={isButtonLoading}
        maxWidth="sm"
      >
        {isAddAndEditDialogLoading ? (
          <PageLoader />
        ) : (
          <Grid sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Select
              value={fieldData?.id?.value}
              name={fieldData?.id?.name}
              onChange={handleInputChange}
              label={fieldData?.id?.label}
              // error={fieldData.id.isError}
              placeholder={"Select Test"}
              helperText={fieldData?.id?.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.id }))
              }
              formControlStyle={{ width: "100%" }}
              width=""
              options={testList}
            />
            <AutoCompleteWithCheckBoxes
              name={fieldData?.product_id?.name}
              label={fieldData?.product_id?.label}
              value={fieldData?.product_id?.value}
              onChange={(event: any, newValue: any) =>
                handleAutoCompleteWithCheckBoxChange(event, newValue)
              }
              inputValue={inputValue}
              onInputChange={(e: any, newInputValue: any) => {
                handleAutoCompleteWithCheckBoxInputChange(e, newInputValue);
              }}
              placeholder={"Select Product"}
              options={inputValue.length >= 3 ? productList : []}
              noOptionsText={
                inputValue.length <= 2
                  ? "Please enter 3 or more characters"
                  : "No options"
              }
              defaultValue={
                fieldData?.product_id?.value !== null
                  ? fieldData?.product_id?.value
                  : null
              }
              loading={isAutoCompleteLoading}
              sx={{
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  padding: "5px 0px",
                  borderRadius: "8px",
                  paddingLeft: "8px",
                  minHeight: "48px",
                  maxHeight: "auto",
                  overflowX: "hidden",
                },
                width: "100%",
              }}
              helperText={fieldData?.product_id?.helperText}
            />
          </Grid>
        )}
      </DialogWrapper>
    </>
  );
}

export default TestProducts;
