import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Grid,
  CardMedia,
  Typography,
  IconButton,
  Box,
  Link,
  CircularProgress,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { DeleteIcon, PdfIcon } from "../../../../assets/icons";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "../../../../components/basic";
import {
  ConfirmationDialog,
  DialogWrapper,
} from "../../../../components/shared";
import {
  createMedicalRecordsDetails,
  deleteMedicalRecordsDetails,
  medicalRecordsByAppointmentDetails,
  medicalReportsByAppointmentDetails,
  viewMedicalRecordsDetails,
} from "../../../../services/appointmentService";
import { useNavigate, useParams } from "react-router-dom";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import { setSnackBarSuccess } from "../../../../redux/slices/snackbar";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import { appointmentDetailsConst } from "../../../../constants/displayText";
import { dateFormat } from "../../../../utils/DateTimeFormatUtils";

export default function ViewReport() {
  const { appointmentId }: any = useParams();
  // const BASE_URL = `${process.env.DR_CARROT_BASE_URL}/storage/app/medi_records/${appointmentId}/`;
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [dialogOpen, setDialogOpen] = useState<any>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>("");
  const [reportDetails, setReportDetails] = useState<any>([]);
  const [reportLists, setReportLists] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addReport, setAddReport] = useState(false);
  const [title, setTitle] = useState<string | number | any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [medicalReportToBeDeleted, setMedicalReportToBeDeleted] =
    useState<any>(null);
  const dispatch = useDispatch<AppDispatch>();

  const initialMedicalRecordsFormError = {
    title: "",
    attachment: "",
  };
  const [medicalRecordsFormError, setMedicalRecordsFormError] = useState<any>(
    initialMedicalRecordsFormError
  );

  const initialData = {
    title: "",
    attachment: "",
  };

  const [medicalRecordsFormData, setMedicalRecordsFormData] = useState<
    string | Date | any
  >(initialData);
  const fieldData: any = {
    title: {
      label: "Title",
      name: "title",
      value: medicalRecordsFormData.title,
      isError: medicalRecordsFormError.title === "" ? false : true,
      helperText: medicalRecordsFormError.title,
    },
    attachment: {
      label: "Attachment",
      name: "attachment",
      value: medicalRecordsFormData.attachment,
      isError: medicalRecordsFormError.attachment === "" ? false : true,
      helperText: medicalRecordsFormError.attachment,
    },
  };

  const [medicalRecordsFieldData, setMedicalRecordsFieldData] =
    useState(fieldData);

  const handleValidation = useCallback(
    (e: any) => {
      const { name, value, label } = e.target;

      switch (name) {
        case "title":
        case "attachment": {
          if (requiredValidator(value, label)) {
            updateFormDataWithHelperText(
              name,
              requiredValidator(value, label),
              setMedicalRecordsFormError
            );
          } else {
            updateFormDataWithHelperText(name, "", setMedicalRecordsFormError);
          }
          break;
        }
        default:
          break;
      }
    },
    [medicalRecordsFormError]
  );

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateMedicalRecordsFormData = () => {
    setMedicalRecordsFieldData((prevFieldData: any) => {
      return Object.keys(prevFieldData).map((field: any) => {
        return {
          ...field,
          helperText: medicalRecordsFormError[field.name],
          value: medicalRecordsFormError[field.name],
          isError: medicalRecordsFormError[field.name] == "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateMedicalRecordsFormData();
  }, [medicalRecordsFormError, medicalRecordsFormData]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setSelectedFiles(Array.from(files));
    }
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setTitle(value);
  };

  const handleClickOpen = (id: any) => {
    const image = reportLists.find((item: any) => item.id === id);
    setSelectedImage(image);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleNext = () => {
    // Find the index of the selected image in the itemData array
    const index = reportLists.findIndex(
      (item: any) => item.id === selectedImage.id
    );

    // Find the index of the next non-PDF item
    let nextIndex = (index + 1) % reportLists.length;
    for (let i = 0; i < reportLists.length; i++) {
      if (!reportLists[nextIndex]?.name.toLowerCase().endsWith(".pdf")) {
        setSelectedImage(reportLists[nextIndex]);
        break;
      }
      nextIndex = (nextIndex + 1) % reportLists.length;
    }
  };

  const handlePrev = () => {
    // Find the index of the selected image in the itemData array
    const index = reportLists.findIndex(
      (item: any) => item.id === selectedImage.id
    );

    // Find the index of the previous non-PDF item
    let prevIndex = (index - 1 + reportLists.length) % reportLists.length;
    for (let i = 0; i < reportLists.length; i++) {
      if (!reportLists[prevIndex]?.name.toLowerCase().endsWith(".pdf")) {
        setSelectedImage(reportLists[prevIndex]);
        break;
      }
      prevIndex = (prevIndex - 1 + reportLists.length) % reportLists.length;
    }
  };

  const getAllMedicalRecords = () => {
    try {
      setLoading(true);
      medicalReportsByAppointmentDetails(appointmentId).then((result: any) => {
        setReportLists(result?.data);
        setLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  // const handleConfirmDelete = async () => {
  //   try {
  //     setDeleteBtnLoader(true);
  //     await deleteMedicalRecordsDetails(medicalRecordToBeDeleted).then(
  //       (result) => {
  //         setMedicalRecordToBeDeleted(null);
  //         setIsConfirmationDialogOpen(false);
  //         getAllMedicalRecords();
  //         dispatch(
  //           setSnackBarSuccess({
  //             snackBarMessage:
  //               appointmentDetailsConst.REPORT_DELETED_SUCCESS as string,
  //           })
  //         );
  //         setDeleteBtnLoader(false);
  //       }
  //     );
  //   } catch (error) {
  //     console.error("An error occurred:", error);
  //     setMedicalRecordToBeDeleted(null);
  //     setIsConfirmationDialogOpen(false);
  //     setDeleteBtnLoader(false);
  //   }
  // };

  const deleteMedicalRecords = (id: any) => {
    setIsConfirmationDialogOpen(true);
    setMedicalReportToBeDeleted(id);
  };
  const deleteMedicalReports = async () => {
    try {
      setDeleteBtnLoader(true);
      await deleteMedicalRecordsDetails(medicalReportToBeDeleted).then(
        (result) => {
          setReportLists((prev: any) =>
            prev?.filter(
              (report: any) => report?.id !== medicalReportToBeDeleted
            )
          );
          dispatch(
            setSnackBarSuccess({
              snackBarMessage:
                appointmentDetailsConst.REPORT_DELETED_SUCCESS as string,
            })
          );
          setDeleteBtnLoader(false);
          setIsConfirmationDialogOpen(false);
          setMedicalReportToBeDeleted(null);
        }
      );
    } catch (error) {
      console.error("An error occurred:", error);
      setDeleteBtnLoader(false);
      setMedicalReportToBeDeleted(null);
      setIsConfirmationDialogOpen(false);
    }
  };

  const createMedicalRecord = async () => {
    setIsSaveBtnLoading(true);
    const currentDate = new Date();
    const data = new FormData();
    data.append("appoint_id", appointmentId);
    data.append("patient_id", reportLists[0]?.patient_id);
    data.append("doc_id", "null"); // Note: Set as string
    data.append("type", "report");
    data.append("title", title);
    data.append("date_created", dateFormat(currentDate, "DD/MM/YYYY"));
    for (let i = 0; i < selectedFiles.length; i++) {
      data.append("userfile[]", selectedFiles[i]);
    }

    createMedicalRecordsDetails(data, 1)
      .then((result: any) => {
        dispatch(
          setSnackBarSuccess({
            snackBarMessage:
              appointmentDetailsConst.REPORT_CREATED_SUCCESS as string,
          })
        );
        getAllMedicalRecords();
        setIsSaveBtnLoading(false);
        setAddReport(false);
        setSelectedFiles("");
        setTitle("");
      })
      .catch((err) => {
        setIsSaveBtnLoading(false);
      });
  };

  useEffect(() => {
    getAllMedicalRecords();
  }, []);
  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  return (
    <>
      {loading ? (
        <Grid
          sx={{
            width: "100%",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            height: "90vh",
            background: "transparent",
          }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: "0 20px 30px 10px",
              alignItems: "center",
            }}
          >
            <Typography color="initial" sx={{ fontSize: "20px" }}></Typography>

            <Box>
              <Button variant="outlined" onClick={() => setAddReport(true)}>
                {" "}
                + Add More
              </Button>
            </Box>
          </Grid>
          <Grid container spacing={2}>
            {reportLists.map((item: any) => (
              <Grid item key={item.id} xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    border: "1px solid #EFEFEF",
                    transition: "box-shadow 0.3s",
                    boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                    "&:hover": {
                      boxShadow: "0px 1px 4px rgba(16, 24, 40, 0.3)",
                    },
                  }}
                >
                  {item.name.toLowerCase().endsWith(".pdf") ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "200px",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Link
                        href={`${item.name}`}
                        download="Example-PDF-document"
                        target="_blank"
                        underline="none"
                      >
                        {item.name.split("/").pop()}
                      </Link>
                    </Box>
                  ) : (
                    <CardMedia
                      component="img"
                      height="200"
                      image={`${item.name}`}
                      alt={item.title}
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleClickOpen(item.id)}
                    />
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: 1.5,
                      borderTop: "1px solid #EFEFEF",
                    }}
                  >
                    <Typography variant="subtitle1">{item.title}</Typography>
                    <IconButton onClick={() => deleteMedicalRecords(item.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "20px 20px 0 20px",
            alignItems: "center",
          }}
        >
          <Typography variant="h2">
            {selectedImage && selectedImage.title}
          </Typography>

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Carousel
            autoPlay={false}
            animation="slide"
            indicators={false}
            navButtonsAlwaysVisible={true}
            next={handleNext}
            prev={handlePrev}
          >
            <img
              src={selectedImage ? `${selectedImage.name}` : ""}
              alt={selectedImage ? selectedImage.title : ""}
              style={{ width: "100%" }}
            />
          </Carousel>
        </DialogContent>
      </Dialog>

      <DialogWrapper
        open={addReport}
        title="Add Medical Records"
        onClose={() => {
          setSelectedFiles("");
          setTitle("");
          setAddReport(!addReport);
        }}
        loading={isSaveBtnLoading}
        handleClick={() => {
          createMedicalRecord();
        }}
        confirmText="Save"
      >
        <>
          <TextField
            placeholder="Enter Title"
            label="Title"
            sx={{ width: "300px" }}
            value={title}
            onChange={handleInputChange}
            name={fieldData.title.name}
            error={fieldData.title.isError}
            helperText={fieldData.title.helperText}
          />
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="h6"
              color="greyScale.main"
              sx={{ mb: "5px", fontSize: "12px" }}
            >
              Attachments
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Button
                component="label"
                variant="outlined"
                sx={{
                  textTransform: "none",
                }}
              >
                Browse...
                <input
                  style={{
                    clip: "rect(0 0 0 0)",
                    clipPath: "inset(50%)",
                    height: 1,
                    overflow: "hidden",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    whiteSpace: "nowrap",
                    width: 1,
                  }}
                  multiple
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  onChange={handleFileChange}
                />
              </Button>
              <Box>
                {selectedFiles.length > 0 ? (
                  <Typography variant="h6" sx={{ ml: "10px" }}>
                    {selectedFiles && selectedFiles.length === 1
                      ? selectedFiles[0].name
                      : `${selectedFiles.length} files selected`}
                  </Typography>
                ) : (
                  <Typography variant="h6" sx={{ ml: "10px" }}>
                    No files selected.
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography fontSize={"11px"} color="#d32f2f" sx={{ p: 1 }}>
              {fieldData.attachment.helperText}
            </Typography>
          </Box>
        </>
      </DialogWrapper>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="are you surely want to delete?"
        handleClick={deleteMedicalReports}
        onClose={onClose}
        loading={deleteBtnLoader}
      />
    </>
  );
}
