import React, { useCallback, useEffect, useMemo, useState } from "react";
import Info from "./info";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { Button, CancelButton, SaveButton } from "../../../../components/basic";
import { stockAdjustmentConst } from "../../../../constants/displayText";
import ProductRow from "./productRow";
import {
  getAllLabMasterDepartments,
  getDepartmentSequence,
  getStockAdjustProduct,
} from "../../../../services/laboratoryService";
import {createStockAdjustment} from "../../../../services/procurementService"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { useNavigate } from "react-router-dom";
import { SequenceWrapper } from "../../../../components/shared";
import { updatedSequence } from "../../../../services/procurementMasterService";

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right";
  sx?: {};
};
type BillProductType = {
  id: number | string | null;
  name: number | string | null;
};
const BillProducts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },

      selectStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "34px",
          maxHeight: "34px",
          p: 0,
        },

        "& .MuiInputBase-root": {
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },

        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );
  const {
    productName,
    batchNo,
    stock,
    decQty,
    incQty,
    price,
    discount,
    tax,
    incAmount,
    decAmount,
    reason,
  } = stockAdjustmentConst;
  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: productName, alignment: "left" },
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: stock, alignment: "right" },
    { TableCellName: incQty, alignment: "right" },
    { TableCellName: decQty, alignment: "right" },
    { TableCellName: price, alignment: "right" },
    { TableCellName: discount, alignment: "right" },
    { TableCellName: tax, alignment: "right" },
    { TableCellName: incAmount, alignment: "right" },
    { TableCellName: decAmount, alignment: "right" },
    {
      TableCellName: reason,
      alignment: "left",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];
  const [formErrors, setFormErrors] = useState<{ dec_qty: boolean }[]>([
    { dec_qty: false },
  ]);
  const [departmentList, setDepartmentList] = useState<BillProductType[]>([]);
  const [productRow, setProductRow] = useState([]);
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [sequenceId, setSequenceId] = useState(null);


  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);


  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });


  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [errors, setErrors] = useState<any>([]);

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
  string | number | undefined
>("");



  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };


  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  let isCreateLabGoodsIsuue = true;
  let isSequenceNumberChanged = false;

  const getSequenceDetails = async () => {
    try {
      setIsSubmitLoaded(true);

      await getDepartmentSequence("Lab Stock Adjustment").then((res : any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreateLabGoodsIsuue && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            bill_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: data,
          }));
          setErrors((prevState: any) => ({
            ...prevState,
            bill_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setSequenceId(res.data[0].sequence.id);
          const newSequenceData =
          res?.data[0]?.sequence?.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
        setSequenceData(newSequenceData);
        setIsManual((prevState) => ({
          ...prevState,
          is_manual:
          res?.data[0]?.sequence?.is_manual === 1 ? true : false,
          is_auto_generate:
          res?.data[0]?.sequence?.is_manual !== 1 ? true : false,
        }));
        setSeqIsOnce((prevState) => ({
          ...prevState,
          isOnce: false,
          isOnceSeqValue: "",
        }));
        setIsManualAdded(Boolean(res?.data[0]?.sequence?.is_manual === 1));
  
          setIsSubmitLoaded(false);
        }
      });
      
    } catch (error : any) {
      setIsSubmitLoaded(false);
      console.error("An error occurred:", error);

      
    }
   
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== info.bill_no) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.bill_no,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const onCloseSequenceDialogBox = () => {
    setIsSequenceWrapperOpen(false);
  };


  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual.is_manual ? 1 : 0,
        };
        await updatedSequence(Number(sequenceId), data).then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.errors,
          })
        );
      }
    }
  };


  const getAllDepartmentList = async () => {
    const data = { active: 1 };
    await getAllLabMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data.result
          ?.filter((elem: { type: string }) => elem.type !== "B2B")
          .map((ele: { dept_name: string; id: number }) => ({
            id: ele.id,
            name: ele.dept_name,
          }));
        setDepartmentList(formattedValue);
      })
      .catch((err) => console.log(err));
  };
  const initialBillProduct = {
    id: null,
    product_name: null,
    batch_no: "",
    stock: null,
    dec_qty: null,
    inc_qty: null,
    price: null,
    discount: null,
    tax: null,
    inc_amount: null,
    dec_amount: null,
    reason: null,
  };

  const [billProducts, setBillProducts] = useState<any>([initialBillProduct]);

  const initialInfo: any = {
    department: null,
    bill_no: "",
  };

  const [info, setInfo] = useState<{} | any>(initialInfo);
  const [customSequence,setCustomSequence] = useState(true);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [rowDisables, setRowDisables] = useState<
    { inc_qty: boolean; dec_qty: boolean }[]
  >([
    {
      inc_qty: false,
      dec_qty: false,
    },
  ]);
  const handleCalculation = useCallback((product: any, index: any) => {
    let rate =
      product?.product_name?.p_rate / product?.product_name?.no_of_tests;
    let inc_disc_value: any = 0;
    let inc_tax_value: any = 0;
    let dec_tax_value: any = 0;
    let dec_disc_value: any = 0;
    let inc_amount_value = product?.inc_qty
      ? (product?.inc_qty * rate || 0).toFixed(2)
      : 0;
    let dec_amount_value = product?.dec_qty
      ? (product?.dec_qty * rate || 0).toFixed(2)
      : 0;
    let tax_per = product?.product_name?.tax_id;

    if (isNaN(product?.discount) || isNaN(inc_amount_value as number)) {
      inc_disc_value = 0;
    } else {
      inc_disc_value = (
        (product?.discount / 100) *
        Number(inc_amount_value)
      ).toFixed(2);
    }

    if (isNaN(tax_per)) {
      inc_tax_value = 0;
    } else {
      inc_tax_value = (
        (tax_per / 100) *
        (Number(inc_amount_value) - inc_disc_value)
      ).toFixed(2);
    }

    if (isNaN(product?.discount) || isNaN(Number(dec_amount_value))) {
      dec_disc_value = 0;
    } else {
      dec_disc_value = (
        (product?.discount / 100) *
        Number(dec_amount_value)
      ).toFixed(2);
    }
    if (isNaN(tax_per)) {
      dec_tax_value = 0;
    } else {
      dec_tax_value = (
        (tax_per / 100) *
        (Number(dec_amount_value) - dec_disc_value)
      ).toFixed(2);
    }

    return {
      increase_amount:
        (
          Number(inc_amount_value) -
          inc_disc_value +
          parseFloat(inc_tax_value)
        ).toFixed(2) || 0,
      decrease_amount:
        (
          Number(dec_amount_value) -
          dec_disc_value +
          parseFloat(dec_tax_value)
        ).toFixed(2) || 0,
    };
  }, []);
  const handleDisabledRows = useCallback(
    (name: string, product: any, index: number) => {
      if (name === "inc_qty" && product.inc_qty.length > 0) {
        setRowDisables((prevState) => {
          const newState = [...prevState];
          newState[index as number]["dec_qty"] = true;
          return newState;
        });
      } else if (name === "dec_qty" && product.dec_qty.length > 0) {
        setRowDisables((prevState) => {
          const newState = [...prevState];
          newState[index as number]["inc_qty"] = true;
          return newState;
        });
      } else {
        setRowDisables((prevState) => {
          const newState = [...prevState];
          newState[index as number]["dec_qty"] = false;
          newState[index as number]["inc_qty"] = false;
          return newState;
        });
      }
    },
    []
  );
  const handleFormValidation = useCallback(
    (e: any, updatedValue: any, idx: number | string) => {
      const { name, value } = e.target;
      switch (name) {
        case "dec_qty": {
          if (value > Number(updatedValue.stock)) {
            setFormErrors((prevState) => {
              const newState = [...prevState];
              newState[idx as number]["dec_qty"] = true;
              return newState;
            });
          } else {
            setFormErrors((prevState) => {
              const newState = [...prevState];
              newState[idx as number]["dec_qty"] = false;
              return newState;
            });
          }
          break;
        }
        default:
          break;
      }
    },
    []
  );
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const { name, value } = e.target;
      let numericValue = value;

      if (name !== "reason") {
        numericValue = value.replace(/\D/g, "");
      }

      setCurrentIndex(index);
      setBillProducts((prevState: any) => {
        const newState = [...prevState];

        newState[index as number] = {
          ...newState[index as number],
          [name]: numericValue,
        };
        const { increase_amount, decrease_amount } = handleCalculation(
          newState[index as number],
          index
        );
        newState[index as number] = {
          ...newState[index as number],
          inc_amount: increase_amount,
          dec_amount: decrease_amount,
        };
        if (increase_amount || decrease_amount) {
          handleDisabledRows(name, newState[index as number], index as number);
        }
        handleFormValidation(
          { target: { name: name, value: newState[index as number][name] } },
          newState[index as number],
          index
        );
        return newState;
      });
    },
    []
  );
  const validateForm = (details: any) => {
    let validate = true;

    if (!details.department) {
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "department or supplier not to be empty!",
        })
      );
      validate = false;
    }

    if (Boolean(formErrors.find((ele) => ele.dec_qty))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    if (Boolean(billProducts.find((ele: any) => !ele.reason))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Reason need for every data!" })
      );
      validate = false;
    }
    if (Boolean(billProducts.find((ele: any) => !ele.product_name))) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please choose product first!" })
      );
      validate = false;
    }

    return validate;
  };
  const handleSubmit = async () => {
    if (validateForm(info)) {
      setIsSubmitLoaded(true);
      const departmentType = appConfiguration?.lab_goods_issue_disable
        ? "receiver"
        : "issuer";
      const productDetails: any =
        billProducts.length > 0
          ? billProducts.map((obj: any) => {
              let newObj = {};
              const {
                product_id,
                supplier_id,
                pack_type,
                discount,
                tax_id,
                tax_type,
                id,
                batch_no,
                no_of_tests,
              } = obj.product_name;
              newObj = {
                product_id,
                supplier_id,
                pack_type,
                discount,
                tax_id,
                tax_type,
                raw_batch_no: batch_no,
                batch_id: id,
                price: obj.price,
                reason: obj.reason,
                no_of_tests,
                increase_qty: obj.inc_qty,
                decrease_qty: obj.dec_qty,
                increase_amount: obj.inc_amount,
                decrease_amount: obj.dec_amount,
              };
              return newObj;
            })
          : 0;
      const data = {
        dept_id: info.department,
        dept_type: departmentType,
        bill_no: info.bill_no,
        bill_no_modified: Number(info.bill_no) === Number(initialSequenceNumber)? 0 : 1,
        product: [...productDetails],
      };

      await createStockAdjustment(data)
        .then((res : any) => {
          navigate("/laboratory/lab-issue-stock-adjustments");
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          setIsSubmitLoaded(false);
        })
        .catch((err : any) => {
          
          const errorMessage = err.response?.data?.errors?.message || "An error occurred";
          dispatch(setSnackBarFailed({ snackBarMessage: errorMessage }));
          setIsSubmitLoaded(false);
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getAllDepartmentList();
    getSequenceDetails();
  }, [appConfiguration]);
  useEffect(()=>{
    if(appConfiguration?.custom_sequence === false){
      setCustomSequence((prev)=> prev = false);
    }
  },[appConfiguration]);
  const handleAutoCompleteChange = useCallback(
    (newValue: any, index: Number, name: string) => {
      if (newValue === null) {
        return false;
      }

      setBillProducts((prev: any) => {
        let newState = [...prev];
        if (newState.length > 1) {
          newState[index as number] = {
            ...newState[index as number],
            [name]: newValue,
            batch_no: `${newValue.batch_no}#${newValue.stock_qty}`,
            stock: newValue.stock_qty,
            price:
              !newValue?.no_of_tests || !newValue.p_rate
                ? 0
                : (newValue?.p_rate / newValue?.no_of_tests).toFixed(2),
            discount: newValue.product_discount,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
          };
        }
        if (newState.length === 1) {
          newState[index as number] = {
            ...newState[index as number],
            [name]: newValue,
            batch_no: `${newValue.batch_no}#${newValue.stock_qty}`,
            stock: newValue.stock_qty,
            price:
              !newValue?.no_of_tests || !newValue.p_rate
                ? 0
                : (newValue?.p_rate / newValue?.no_of_tests).toFixed(2),
            discount: newValue.product_discount,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
          };
        }
        return newState;
      });
    },
    []
  );

  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProduct,
        id: prev.length,
      },
    ]);
    setRowDisables((prev) => [
      ...prev,
      {
        inc_qty: false,
        dec_qty: false,
      },
    ]);
    setFormErrors((prevState) => [...prevState, { dec_qty: false }]);
  }, []);

  const handleDelete = useCallback(
    (id: number) => {
      const newBillProducts: any = [...billProducts];
      newBillProducts.splice(id, 1);
      setBillProducts(newBillProducts);

      const newErrorValue: any = [...rowDisables];
      newErrorValue.splice(id, 1);
      setRowDisables(newErrorValue);

      const newFormErrors: any = [...formErrors];
      newFormErrors.splice(id, 1);
      setFormErrors(newFormErrors);
    },
    [billProducts, formErrors, rowDisables]
  );
  useEffect(() => {
    if (info.department) {
      const data: any = {
        department: info.department,
      };
      getStockAdjustProduct(data)
        .then((res : any) => setProductRow(res.data))
        .catch((err) => console.log(err));
    }
  }, [info.department]);
  return (
    <Grid sx={{ width: "92vw" }}>
      <Info 
      info={info} 
      setInfo={setInfo} 
      departmentList={departmentList} 
      setIsSequenceWrapperOpen={setIsSequenceWrapperOpen}
      isManualAdded={isManualAdded}
      isManual={isManual}
      setIsManual={setIsManual}
      errors={errors}
      handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
      customSequence={customSequence}
      />
      <Grid
        sx={{
          width: {
            xs: "92vw",
          },
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          overflowX: { md: "scroll", lg: "hidden" },
        }}
      >
        <TableContainer
          className="table-container"
          component={Paper}
          sx={{
            ...styles.tableContainerStyle,
          }}
        >
          <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {TableHeaderCells.map((cell, index) => {
                  return (
                    <TableCell align={cell.alignment} sx={cell.sx} key={index}>
                      {cell.TableCellName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody
              sx={{
                height: "auto",
                width: {
                  xs: "400vw",
                  sm: "200vw",
                  md: "120vw",
                  lg: "90vw",
                },
              }}
            >
              {billProducts.map((row: any, index: number) => {
                return (
                  <ProductRow
                    key={row.id}
                    index={index}
                    row={row}
                    styles={styles}
                    allRows={productRow}
                    rowDisables={rowDisables}
                    formErrors={formErrors}
                    handleInputChange={handleInputChange}
                    handleDelete={handleDelete}
                    handleAutoCompleteChange={handleAutoCompleteChange}
                  />
                );
              })}
            </TableBody>
          </Table>
          <Grid
            sx={{
              width: {
                xs: "400vw",
                sm: "200vw",
                md: "120vw",
                lg: "90vw",
              },
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "0px 0px 5px 5px",
              border: "1px solid",
              borderColor: "var(--table-border)",
              borderTop: 0,
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: {
                  xs: "39%",
                  lg: "39%",
                },
              }}
            >
              <Button
                variant="text"
                buttonText="+ Add Product"
                handleClick={handleClick}
                sx={{
                  borderRadius: "0px",
                  width: "auto",
                  height: "auto",
                  fontWeight: 400,
                  fontSize: "13px",
                  border: 0,
                  color: "primary.main",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "primary.main",
                    borderColor: "initial",
                  },
                  "&.Mui-focusVisible": {
                    border: 1,
                    borderColor: "primary.main",
                    borderRadius: "5px",
                  },
                }}
              />
            </Grid>
          </Grid>
        </TableContainer>
      </Grid>
      <Grid sx={{ display: "flex", flexDirection: "row", m: "35px 0 45px 0" }}>
        <SaveButton
          handleClick={() => handleSubmit()}
          loading={isSubmitLoaded}
          sx={{ width: "100px", height: "42px", mr: "20px" }}
        />
        <CancelButton sx={{ width: "100px", height: "42px" }} />
      </Grid>
      <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="Lab Stock Adjustment"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
    </Grid>
  );
};

export default BillProducts;