import { Box, Grid, Typography, Tooltip, Divider } from "@mui/material";
import { DialogWrapper, Tabs } from "../../../components/shared";
import { useEffect, useState } from "react";
import { getAllIpRooms } from "../../../services/admissionService";
import { commonDateFormatter } from "../../../utils/DateTimeFormatUtils";
import {
  RoomIcon,
  BedIcon,
  BookedRoomIcon,
  BookedBedIcon,
} from "../../../assets/icons";
import { styled } from "@mui/material/styles";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

type WardRoomModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelectRoom: (value: any) => void;
  isSelectAllRoom?: any;
};

const WardRoomModal = (props: WardRoomModalProps) => {
  const { isOpen, onClose, onSelectRoom, isSelectAllRoom = false } = props;
  const [roomList, setRoomList] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const dateWithTime = commonDateFormatter(new Date(), "DD/MM/YYYY hh:mm:ss A");

  const handleTooltipClose = (id: number) => {
    setRoomList((prev: any) => {
      return prev?.map((item: any) => {
        return {
          ...item,
          rooms: item?.rooms?.map((room: any) => {
            if (room.id === id) {
              return {
                ...room,
                isTooltipOpen: false,
              };
            }
            return room;
          }),
        };
      });
    });
  };

  const handleTooltipOpen = (id: number) => {
    setRoomList((prev: any) => {
      return prev?.map((item: any) => {
        return {
          ...item,
          rooms: item?.rooms?.map((room: any) => {
            if (room.id === id) {
              return {
                ...room,
                isTooltipOpen: true,
              };
            }
            return room;
          }),
        };
      });
    });
  };

  const getIpRooms = async () => {
    getAllIpRooms()
      .then((res: any) => {
        if (res.data) {
          const data = res?.data;

          const modifiedData = data?.map((item: any) => {
            return {
              ...item,
              rooms: item?.rooms?.map((room: any) => {
                return {
                  ...room,
                  isTooltipOpen: false,
                };
              }),
            };
          });
          setRoomList(modifiedData);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getIpRooms();
  }, []);

  const CommonGrid = ({ name, value, isIncludeMarginBottom = true }: any) => {
    return (
      <Grid
        sx={{
          width: {
            xs: "100%",
            md: "70%",
            lg: "70%",
          },
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          color: "textPrimary.main",
          mb: isIncludeMarginBottom ? "23px" : "0px",
        }}
        className="row"
      >
        <Grid
          sx={{
            width: "40%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "500",
              color: "textPrimary.main",
            }}
          >
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "500",
              color: "textPrimary.main",
            }}
          >
            :
          </Typography>
        </Grid>
        <Grid
          sx={{
            width: "60%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            pl: 3,
          }}
        >
          <Typography variant="h6" sx={{ color: "textPrimary.main" }}>
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 600,
    },
    [`& .MuiTooltip-popper`]: {
      width: "500px",
    },
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "white",
      border: "1px solid black",
    },
    [`& .MuiTooltip-arrow`]: {
      backgroundColor: "white",
    },
  });

  return (
    <DialogWrapper
      open={isOpen}
      onClose={() => {
        onClose();
      }}
      sx={{
        "& .MuiDialogContent-root": {
          overflowY: "unset",
        },
      }}
      title="IP Room Booking"
    >
      <Box display="flex" alignItems="center" justifyContent="center" mb={3}>
        <Typography>Admission date : {dateWithTime}</Typography>
      </Box>
      <Grid container display="flex" columnGap={2} height={400}>
        <Grid
          item
          xs={3}
          sm={3}
          md={3}
          xl={3}
          sx={{
            "& .MuiTabs-root": {
              boxShadow: "unset",
            },
          }}
        >
          <Tabs
            orientation="vertical"
            data={roomList}
            initialValue={null}
            sx={{
              "& .MuiTabs-flexContainer": {
                height: "100%",
                justifyContent: "unset",
                alignItems: "unset",
                boxShadow: "unset",
              },
              "& .MuiTab-root": {
                color: "#8A8A8A",
                background: "#F4F4F4",
                borderBottom: "1px solid #E0E0E0",
                width: "150px",
                minHeight: "75px",
              },
              [`& .MuiTab-root:nth-child(${selectedTab + 1})`]: {
                background: "inherit",
                borderBottom: "unset",
                color: "#207DFF",
              },
              [`& .MuiTab-root:nth-child(${selectedTab})`]: {
                borderRadius: "0px 0px 20px 0px",
              },
              [`& .MuiTab-root:nth-child(${selectedTab + 2})`]: {
                borderRadius: "0px 20px 0px 0px",
              },
              "& .MuiTab-root:first-child": {
                border: selectedTab === 0 ? "1px solid #E0E0E0" : "unset",
                borderBottom: selectedTab === 0 ? "0px" : "1px solid #E0E0E0",
                borderRadius:
                  selectedTab === 0 || selectedTab === 1
                    ? "0 0 20px 0"
                    : "unset",
              },
              "& .MuiTab-root:last-child": {
                border:
                  selectedTab === roomList.length - 1
                    ? "1px solid #E0E0E0"
                    : "unset",
                borderTop: "0px",
                borderRadius:
                  selectedTab === roomList.length - 1 ||
                  selectedTab === roomList.length - 2
                    ? "0 20px 0 0"
                    : "unset",
              },
              "& .MuiTabs-indicator": {
                background: "unset",
              },
            }}
            onChange={(_: any, newValue: any) => {
              setSelectedTab(newValue);
            }}
          />
        </Grid>
        <Grid
          item
          xs={8}
          sm={8}
          md={8}
          xl={8}
          sx={{ width: "100%", height: "100%" }}
        >
          {roomList.length > 0 && (
            <Grid
              container
              columnGap={3}
              rowGap={3}
              sx={{
                "& .MuiTooltip-tooltip": {
                  backgroundColor: "primary.main !important",
                },
                "& .MuiTooltip-arrow": {
                  backgroundColor: "red",
                },
              }}
            >
              {roomList[selectedTab]?.rooms?.map((room: any) => {
                return (
                  <CustomWidthTooltip
                    open={room?.isTooltipOpen}
                    onClose={() => handleTooltipClose(room.id)}
                    onOpen={() => handleTooltipOpen(room.id)}
                    className="custom-tooltip"
                    title={
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Grid
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <CommonGrid
                            name="Room / Bed No"
                            value={room?.room_no}
                          />
                          <CommonGrid
                            name="Type"
                            value={room?.room_type?.name}
                          />
                          <CommonGrid
                            name="Description"
                            value={room?.description}
                          />
                        </Grid>
                        {room?.ip_admission?.length > 0 && (
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Divider sx={{ m: "10px 0px" }} />
                            <CommonGrid
                              name="Patient Name"
                              value={
                                room?.ip_admission[0]?.patient_details?.name
                              }
                            />
                            <CommonGrid
                              name="UHID"
                              value={
                                room?.ip_admission[0]?.patient_details?.uhid
                              }
                            />
                            <CommonGrid
                              name="Mobile"
                              value={
                                room?.ip_admission[0]?.patient_details?.mobile
                              }
                            />
                            <CommonGrid
                              name="Doctor"
                              value={
                                room?.ip_admission[0]?.doctor_details?.name
                              }
                            />
                          </Grid>
                        )}
                      </Grid>
                    }
                    arrow
                  >
                    <Grid
                      item
                      xs={2.8}
                      sm={2.8}
                      xl={3.5}
                      md={3.5}
                      sx={{
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        background: !(room?.ip_admission?.length > 0)
                          ? "#E0EDFF"
                          : "#DEFFF6",
                        borderRadius: "10px",
                        cursor: !isSelectAllRoom
                          ? room?.ip_admission?.length > 0
                            ? "no-drop"
                            : "pointer"
                          : "pointer",
                      }}
                      gap={2}
                      onClick={() =>
                        !isSelectAllRoom
                          ? room?.ip_admission?.length > 0
                            ? null
                            : onSelectRoom({
                                selectedWard: roomList[selectedTab]?.name,
                                ...room,
                              })
                          : onSelectRoom({
                              selectedWard: roomList[selectedTab]?.name,
                              ...room,
                            })
                      }
                    >
                      <Grid>
                        {room.room_or_bed ? (
                          room?.ip_admission?.length > 0 ? (
                            <BookedRoomIcon />
                          ) : (
                            <RoomIcon />
                          )
                        ) : room?.ip_admission?.length > 0 ? (
                          <BookedBedIcon />
                        ) : (
                          <BedIcon />
                        )}
                      </Grid>
                      <Box>{room.room_no}</Box>
                    </Grid>
                  </CustomWidthTooltip>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default WardRoomModal;
