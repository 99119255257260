import { Box, Grid, Typography, debounce } from "@mui/material";
import { DatePicker, Select, TextField } from "../../components/basic";
import { campaignFilterOptions } from "../../constants/dropdownOptions";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../../components/shared";
import { useCallback, useEffect, useState } from "react";
import { getAllColdLeads } from "../../services/campaignService";
import { CampaignsIconList } from "../../constants/displayText";
type GridRowData = Record<string, unknown>;

const ColdLeads = () => {
  const [loading, setLoading] = useState(false);
  const [coldLeads, setColdLeads] = useState<any>({ rows: [], pageCount: 0 });
  const [filterData, setFilterData] = useState({
    department: 1,
    item_name: "",
    from_date: null,
    to_date: null,
  });
  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.2,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            S.No
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.sNo}
        </Typography>
      ),
      minWidth: 80,
      sortable: false,
    },
    {
      field: "prospect",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Prospect
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.prospect}
        </Typography>
      ),
      minWidth: 150,
      sortable: false,
    },
    {
      field: "mobile",
      //   flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Mobile No
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.mobile}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "campaign",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Campaign
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.campaign}
        </Typography>
      ),
      minWidth: 150,
      sortable: false,
    },
    {
      field: "Mode",
      //   flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Mode
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} display={"flex"}>
          <Box sx={{ width: "20px", height: "20px" }}>
            {CampaignsIconList[row.mode]}
          </Box>
          {row.mode}
        </Typography>
      ),
      minWidth: 150,
      sortable: false,
    },
    {
      field: "follow_up",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Follow-up
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          sx={{
            marginLeft: row.follow_up_date ? "unset" : "25%",
            width: "100%",
          }}
        >
          {row.follow_up_date ? row.follow_up_date : "-"}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "tele_caller",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Tele Caller
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.tele_caller}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "call_status",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Call Status
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.call_status}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "appointment_status",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Appointment Status
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Box
          sx={{
            padding: "10px",
            borderRadius: "5px",
            color: !row.appointment_status
              ? "#000"
              : row.appointment_status === "Booked"
              ? "#00BA87"
              : "#FF971C",
            fontWeight: 500,
            fontSize: "13px",
            lineHeight: "12.1px",
            width: "100%",
            marginLeft: row.appointment_status ? "unset" : "25%",
          }}
        >
          {row.appointment_status ? row.appointment_status : "-"}
        </Box>
      ),
      minWidth: 100,
      sortable: false,
    },
  ];

  const getAllColdLeadsList = (data: any) => {
    setLoading(true);
    setTimeout(() => {
      getAllColdLeads(data)
        .then((res) => {
          if (res.data) {
            setLoading(false);
            setColdLeads({
              rows: res?.data?.result,
              pageCount: res.data.total,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }, 500);
  };

  const debouncedGetAllColdLeadsList = useCallback(
    debounce((data) => {
      getAllColdLeadsList({});
    }, 300),
    []
  );
  const handleChange = (event: any) => {
    const { name, value } = event.target;

    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    debouncedGetAllColdLeadsList({});
  };

  const handleDateChange = (_: any, newValue: any, name: string) => {
    setFilterData((prevState) => ({
      ...prevState,
      [name]: newValue.validatedValue[0],
    }));
    getAllColdLeadsList({});
  };

  useEffect(() => {
    getAllColdLeadsList({});
  }, []);

  return (
    <Box>
      <Grid container columnGap={2} rowGap={2}>
        <Grid item xs={12} sm={5.8} md={2.8} xl={2.5}>
          <Select
            formControlStyle={{ width: "100%" }}
            label="select"
            options={campaignFilterOptions}
            width=""
            name="department"
            value={filterData.department}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={5.8} md={2.8} xl={2.5}>
          <TextField
            label="Item Name"
            placeholder="Ex: Email"
            name="item_name"
            value={filterData.item_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={5.8} md={2.8} xl={2.5}>
          <DatePicker
            formControlStyle={{ width: "100%" }}
            placeholder="Select from date"
            name="from_date"
            label="From Date"
            width="93%"
            value={filterData.from_date}
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "from_date")
            }
          />
        </Grid>
        <Grid item xs={12} sm={5.8} md={2.8} xl={2.5}>
          <DatePicker
            formControlStyle={{ width: "100%" }}
            placeholder="Select to date"
            name="to_date"
            label="to Date"
            width="93%"
            value={filterData.to_date}
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "to_date")
            }
          />
        </Grid>
      </Grid>
      <DataTable
        loading={loading}
        columns={columns}
        rows={coldLeads.rows}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        pageCount={coldLeads.pageCount}
        currentPage={"inhouseLeads"}
      />
    </Box>
  );
};

export default ColdLeads;
