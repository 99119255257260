import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { TextField } from "../../../../components/basic";
import { Select } from "../../../../components/basic";
import { CheckBox } from "../../../../components/basic";
import moment from "moment";

type PaymentsTableProps = {
  data: any;
  totals: {
    totalBillAmount: number;
    totalAmountDue: number;
    totalPaidAmount: number;
    totalDiscountAmount?: number;
    totalsTds?: number;
  };
  onChange: (event: any, index: number) => void;
  errors: any[];
  isPatientBillsLoading: boolean;
};
const PaymentsTable = ({
  data,
  totals,
  onChange,
  errors,
  isPatientBillsLoading,
}: PaymentsTableProps) => {
  // const initialRow: any = {
  //   id: 1,
  //   department: "Laboratory",
  //   patientName: "stuart",
  //   invoiceNo: "SBINV00040",
  //   payee: "SSR Institutions",
  //   billAmount: 10000,
  //   discount: 15,
  //   amountDue: 2000.0,
  //   paid_amt: 10000,
  //   payInFull: true,
  //   remarks: "",
  //   discountType: "₹",
  // };

  // const [rows, setRows] = useState<any>({});

  // const [hasFocusedChild, setHasFocusedChild] = useState(false);

  // useEffect(() => {
  //   const children = document.querySelectorAll(
  //     ".MuiTableCell-body .mui-focused"
  //   );

  //   setHasFocusedChild(children.length > 0);
  // }, []);

  const styles = {
    tableContainerStyle: {
      borderRadius: "5px",
      boxShadow: "none",
      overflow: "hidden",
      width: {
        xs: "400vw",
        sm: "200vw",
        md: "120vw",
        lg: "92vw",
      },
      overflowX: { xs: "scroll", lg: "hidden" },
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },

    tableStyle: {
      borderRadius: "5px",
      border: "1px solid",
      borderColor: "var(--table-border)",
      width: {
        xs: "400vw",
        sm: "200vw",
        md: "120vw",
        lg: "90vw",
      },
      "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
      "& .MuiTableRow-head": {
        backgroundColor: "var(--table-header)",
      },
      " & .MuiTableBody-root": {
        border: 0,
      },
      "& .MuiTableRow-root": {
        height: "40px !important",
        maxHeight: "40px !important",
        minHeight: "0px",
        lineHeight: "0px",
        border: 0,
      },
      "& .MuiTableCell-root": {
        minHeight: "0px",
      },

      "& .MuiTableCell-body": {
        height: "38px !important",
        maxHeight: "38px !important",
        minHeight: "0px",
        lineHeight: "0px",
        p: "0px 0px",
        fontSize: "12px",
        fontWeight: "400",
        border: 1,
        borderColor: "var(--table-border)",
        borderRadius: "5px",
      },

      "& .MuiTableCell-body:has(.Mui-focused)": {
        borderColor: "primary.main",
        borderWidth: "2px",
        p: "0px",
        height: "31px",
      },

      "& .MuiInputBase-root": {
        height: "34px",
      },
    },

    tableCellHead: {
      height: "43px",
      maxHeight: "43px",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 10px",
      fontSize: "13px",
      fontWeight: "600",
      borderBottom: 0,
      borderRight: 1,
      borderRadius: "5px",
      borderColor: "var(--table-border)",
    },

    textFieldStyle: {
      height: "37px",
      width: "auto",
      fontSize: "6px",

      "&>*": {
        border: 0,
      },
      "& .MuiOutlinedInput-root": {
        height: "37px",
        borderRadius: "0px",
        paddingLeft: "0px",
      },
      "& .MuiOutlinedInput-root.Mui-focused": {
        borderColor: "primary.main",
      },
      "& .MuiInputBase-input": {
        padding: "0px 10px",
        fontSize: "12px",
        fontWeight: "400",
        textAlign: "right",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "0px",
        height: "32px",
        maxHeight: "32px",
        top: 0,
      },

      "& input::placeholder": {
        fontSize: "12px",
      },
    },
  };

  // const handleSelectChange = (e: SelectChangeEvent<string>, index: number) => {
  //   const { name, value } = e.target;

  //   setRows((prevRows) =>
  //     prevRows?.map((row, i) => (i === index ? { ...row, [name]: value } : row))
  //   );
  // };

  // const [isFocused, setIsFocused] = useState(false);

  return (
    <Grid
      sx={{
        m: "33px 0px 29px 0px",
        // width: {
        //   xs: "92vw",
        // },
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        overflowX: { md: "scroll", lg: "hidden" },
      }}
    >
      <TableContainer
        className="table-container"
        component={Paper}
        sx={{
          ...styles.tableContainerStyle,
        }}
      >
        <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
          <TableHead sx={{ width: "100%" }}>
            <TableRow>
              <TableCell align="left" sx={{ ...styles.tableCellHead }}>
                Department
              </TableCell>

              <TableCell align="left" sx={{ ...styles.tableCellHead }}>
                Invoice No
              </TableCell>

              <TableCell align="left" sx={{ ...styles.tableCellHead }}>
                Ref No
              </TableCell>
              <TableCell align="left" sx={{ ...styles.tableCellHead }}>
                Bill Amount
              </TableCell>
              <TableCell align="left" sx={{ ...styles.tableCellHead }}>
                Discount{" "}
              </TableCell>
              <TableCell align="left" sx={{ ...styles.tableCellHead }}>
                TDS{" "}
              </TableCell>
              <TableCell align="left" sx={{ ...styles.tableCellHead }}>
                Amount Due
              </TableCell>
              <TableCell align="left" sx={{ ...styles.tableCellHead }}>
                Paid Amount
              </TableCell>
              <TableCell align="center" sx={{ ...styles.tableCellHead }}>
                Pay In Full
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  ...styles.tableCellHead,
                  border: 0,
                  borderRadius: "0px 5px 0px 0px",
                }}
              >
                Remarks
              </TableCell>
            </TableRow>
          </TableHead>
          {isPatientBillsLoading ? (
            <TableBody>
              <TableRow>
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "40px",
                      gap: 2,
                    }}
                  >
                    <CircularProgress />
                    <Typography variant="h2" color="initial">
                      Loading
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data?.payments?.length !== 0 ? (
                data?.payments?.map((row: any, index: number) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "& td, & th": { border: 0 },
                    }}
                  >
                    <TableCell
                      align="left"
                      // size="small"
                      sx={{
                        width: "10%",
                      }}
                    >
                      <Typography
                        sx={{
                          p: "10px 10px",

                          width: "100%",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      // // size="small"
                      sx={{
                        width: "10%",
                      }}
                    >
                      <Typography
                        sx={{
                          p: "10px 10px",

                          width: "100%",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {row?.invoice_no}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      // size="small"
                      sx={{
                        width: "10%",
                      }}
                    >
                      <Typography
                        sx={{
                          p: "10px 10px",

                          width: "100%",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {moment(
                          row?.invoice_date,
                          "YYYY-MM-DD hh:mm:ss A"
                        ).format("DD/MM/YYYY hh:mm:ss A")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      // size="small"
                      sx={{
                        width: "10%",
                      }}
                    >
                      <Typography
                        sx={{
                          p: "10px 10px",

                          width: "100%",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {row?.net_total_amt}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        width: "10%",
                        p: "0px 0px",
                        border: !!errors[index]?.discount_value
                          ? "2px solid #d32f2f !important"
                          : "",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Grid
                          sx={{
                            width: "60%",
                            "& .MuiFormControl-root": {
                              width: "100%",
                            },
                          }}
                        >
                          <TextField
                            name="discount_value"
                            value={row?.discount_value}
                            onChange={(e: any) => onChange(e, index)}
                            sx={{
                              ...styles.textFieldStyle,
                              width: "inherit",
                              "& .MuiInputBase-input": {
                                fontSize: "12px",
                                fontWeight: "400",
                                textAlign: "left",
                                p: "0px 10px",
                              },
                            }}
                          />
                        </Grid>

                        <Grid
                          className="select-grid"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "40%",
                            "& .MuiFormControl-root": { width: "100%" },

                            "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                              borderWidth: 0,
                              height: "34px",
                              maxHeight: "34px",
                              p: 0,
                            },

                            "& .MuiInputBase-root": {
                              padding: "0px",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            },

                            "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "primary.main",
                                borderWidth: "1px",
                              },

                            "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderWidth: 0,
                              },

                            "& .MuiGrid-root": {
                              width: "100%",
                            },
                          }}
                        >
                          <Select
                            label=""
                            options={[
                              { id: "₹", name: "₹" },
                              { id: "%", name: "%" },
                            ]}
                            placeholder="%"
                            name="discount_type"
                            value={row?.discount_type || "%"}
                            onChange={(e: any) => onChange(e, index)}
                            width="inherit"
                            sx={{
                              height: "34px",
                              borderRadius: "0px",
                              "& input": {
                                height: "100%",
                              },
                            }}
                            formControlStyle={{
                              "& .MuiOutlinedInput-input": {
                                height: "100%",
                                "& .MuiBox-root": {
                                  height: "100%",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell
                      align="left"
                      // size="small"
                      sx={{
                        width: "10%",
                        border: !!errors[index]?.tds
                          ? "2px solid #d32f2f !important"
                          : "",
                      }}
                    >
                      <TextField
                        name="tds"
                        value={row?.tds}
                        onChange={(e: any) => onChange(e, index)}
                        sx={{
                          ...styles.textFieldStyle,
                          width: "inherit",
                          "& .MuiInputBase-input": {
                            fontSize: "12px",
                            fontWeight: "400",
                            textAlign: "left",
                            p: "0px 10px",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="left"
                      // size="small"
                      sx={{
                        width: "10%",
                      }}
                    >
                      <Typography
                        sx={{
                          p: "10px 10px",

                          width: "100%",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {row.balance}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        width: "10%",
                        border: !!errors[index]?.paid_amt
                          ? "2px solid #d32f2f !important"
                          : "",
                      }}
                    >
                      <TextField
                        value={row.paid_amt}
                        name="paid_amt"
                        onChange={(e) => onChange(e, index)}
                        sx={{
                          ...styles.textFieldStyle,
                          "& .MuiInputBase-input": {
                            fontSize: "12px",
                            fontWeight: "400",
                            textAlign: "left",
                            p: "0px 10px",
                          },
                          width: "inherit",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        width: "10%",
                        "& .MuiFormControl-root": {
                          height: "18px",
                          width: "18px",
                        },
                        "& .MuiBox-root": {
                          height: "18px",
                        },
                        "& .MuiFormControlLabel-root": {
                          height: "18px",
                        },
                        "& .PrivateSwitchBase-input": {
                          width: "18px",
                          height: "18px",
                        },
                        "& .MuiSvgIcon-root": {
                          width: "18px",
                          height: "18px",
                        },
                      }}
                    >
                      <CheckBox
                        label={row.payInFull}
                        checked={row.payInFull || false}
                        name={"payInFull"}
                        value={row.payInFull || false}
                        sx={{
                          fontSize: "12",
                          fontWeight: "400",
                        }}
                        onChange={(event) => {
                          onChange(
                            {
                              target: {
                                name: event?.target?.name,
                                value: event?.target?.checked,
                              },
                            },
                            index
                          );
                        }}
                      />
                      <Typography>{row?.payInFull}</Typography>
                    </TableCell>
                    <TableCell
                      className="last-cell"
                      align="left"
                      sx={{
                        width: "10%",
                      }}
                    >
                      <TextField
                        value={row.remarks}
                        name="remarks"
                        placeholder="Type Your Remarks"
                        onChange={(e) => onChange(e, index)}
                        sx={{
                          ...styles.textFieldStyle,
                          "& .MuiInputBase-input": {
                            fontSize: "12px",
                            fontWeight: "400",
                            textAlign: "left",
                            p: "0px 10px",
                          },
                          width: "inherit",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ borderBottom: "1px solid #edeeef !important" }}>
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }}>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "600",
                        color: "black",
                        textAlign: "left",
                      }}
                    >
                      No Record Found!
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                  <TableCell sx={{ border: "unset !important" }} />
                </TableRow>
              )}
              <TableRow>
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "primary.main",
                      textAlign: "left",
                    }}
                  >
                    Total
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "unset !important" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "primary.main",
                      textAlign: "left",
                    }}
                  >
                    {totals?.totalBillAmount ? totals?.totalBillAmount : 0}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "unset !important" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "primary.main",
                      textAlign: "left",
                    }}
                  >
                    {totals?.totalDiscountAmount
                      ? totals?.totalDiscountAmount
                      : 0}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "unset !important" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "primary.main",
                      textAlign: "left",
                    }}
                  >
                    {totals?.totalsTds ? totals?.totalsTds : 0}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "unset !important" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "primary.main",
                      textAlign: "left",
                    }}
                  >
                    {totals?.totalAmountDue ? totals?.totalAmountDue : 0}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "unset !important" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "primary.main",
                      textAlign: "left",
                    }}
                  >
                    {totals?.totalPaidAmount ? totals?.totalPaidAmount : 0}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
                <TableCell sx={{ border: "unset !important" }} />
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default PaymentsTable;
