import { useCallback, useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  debounce,
  MenuItem,
  Menu,
  Tooltip,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteIcon,
  EditIcon,
  ViewIcon,
  PaymentRupeeIcon,
  KebabMenuIcon,
} from "../../../../assets/icons";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  Sort,
  TextField,
  DatePicker,
  Select,
  InfiniteScroller,
  DeleteDialog,
} from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import { RouteUrls } from "../../../../constants/routes";
import { RootState } from "../../../../redux/store";
import { displayText } from "../../../../constants/displayText";
import { getAllSalesList } from "../../../../services/procurementService";
import { deletePharmacyBill } from "../../../../services/pharmacyService";
import {
  setCurrentPage,
  setFilterDataValues,
  setHandleClearSearch,
  setSortField,
} from "../../../../redux/slices/pagination";

import {
  getAllMasterDepartments,
  getBillTermsOptions,
  getTestAllPatientsList,
} from "../../../../services/gynecologyService";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import {
  dateFormat,
  formatTwelveHoursTime,
} from "../../../../utils/DateTimeFormatUtils";
import { Link } from "react-router-dom";
import PermissionUtils from "../../../../utils/PermissionUtils";
import ListFooter from "./ListFooter";

type GridRowData = Record<string, unknown>;

type BillListTypes = {
  id: number | string | null;
  patient_id: number | string | null;
  payee: number | string | null;
  invoice_no: number | string | null;
  bill_invoice_no: number | string | null;
  date_created: number | string | null;
  net_total_amt: number | string | null;
  paid_amt: number | string | null;
  balance: number | string | null;
  is_return: number | string | null;
  is_cancel: number | string | null;
  bill_payment_type: number | string | null;
  name: number | string | null;
  patient_sys_id: number | string | null;
  mobile: number | string | null;
  uhid: number | string | null;
  created_user: number | string | null;
  doctor_name: number | string | null;
  degree: number | string | null;
  status: number | string | null;
};
const SalesBillsList = () => {
  const { ADD_BUTTON_TITLE } = displayText;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    pharmacyUrl,
    salesBillListUrl,
    createUrl,
    editUrl,
    viewUrl,
    salesReturnUrl,
    historyUrl,
    patientsUrl,
    paymentUrl,
  } = RouteUrls;
  const styles = {
    selectGrid: {
      width: "180px",
      height: "45px",
      boxShadow: "none",
      borderRadius: "5px",
      borderColor: "var(--primary-border-color)",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    textFieldStyle: {
      width: "180px",
      "& .MuiOutlinedInput-root": {
        height: "45px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    DatePickerStyle: {
      "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container >div >div .rmdp-input":
        {
          boxSizing: "border-box",
          width: "180px",
        },
    },
  };

  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.pharmacySalesBill,
      filterData: state?.pagination?.pharmacySalesBill?.filterData,
      count: state?.pagination?.pharmacySalesBill?.count,
      sortField: state?.pagination?.pharmacySalesBill?.sortedField,
    })
  );

  // data table constants and states
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    selectedRow: {
      id: number | string;
    } | null;
  }>({
    isOpen: false,
    selectedRow: null,
  });
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState<boolean>(false);
  const [isClearFilter, setIsClearFilter] = useState<boolean>(false);
  const initialFilterData = {
    department: null,
    uhid: null,
    bill_type: "",
    pay_type: "",
    status: "",
    bill_no: "",
    from_date: dateFormat(new Date(), "DD/MM/YYYY"),
    to_date: dateFormat(new Date(), "DD/MM/YYYY"),
    search: "",
  };
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const { can } = PermissionUtils();
  const [receiverList, setReceiverList] = useState<
    { name: string; id: number; type: string }[] | []
  >([]);
  const [billPaymentTypeOptions, setBillPaymentTypeOptions] = useState([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [isAscendingOrderClicked, setIsAscendingOrderClicked] =
    useState<boolean>(false);
  const [isDescendingOrderClicked, setIsDescendingOrderClicked] =
    useState<boolean>(false);
  const [salesBillList, setSalesBillList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const getPaymentTypeOptions = () => {
    getBillTermsOptions()
      .then((res: any) => {
        if (res) {
          setBillPaymentTypeOptions(res);
        }
      })
      .catch((err) => console.log("err", err));
  }; // data table filter elements options
  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllSalesList({
        bill_no: data.bill_no,
        bill_type: data.bill_type,
        column: data.column,
        department: data.department,
        from_date: data.from_date
          ? data?.from_date?.split("/").reverse().join("-")
          : "",
        limit: data.limit,
        order: data.order,
        page: data.page,
        pay_type: data.pay_type,
        search: data.search,
        status: data.status,
        to_date: data.to_date
          ? data.to_date.split("/").reverse().join("-")
          : "",
        uhid: data.uhid,
      })
        .then((res: any) => {
          const newSalesBillList = [...res.data.result] as any;
          if (can("salesbill_list_total")) {
            newSalesBillList.push({
              date_created: "Total :",
              net_total_amt: res.data.sum_net_total_amt,
              paid_amt: res.data.sum_paid_amt,
              balance: res.data.sum_balance,
            });
          }
          setSalesBillList(newSalesBillList as BillListTypes[]);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data = {
      bill_no: filterData.bill_no,
      bill_type: filterData.bill_type,
      department: filterData.department,
      from_date: filterData.from_date,
      limit: pageInfo.pageSize,
      column: sortField?.field,
      order: sortField?.order,
      page: Number(pageInfo.page) + 1,
      pay_type: filterData.pay_type,
      search: filterData.search,
      status: filterData.status,
      to_date: filterData.to_date,
      uhid: filterData.uhid?.value,
    };
    debouncedGetAllAppointment(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortField?.order,
    sortField?.field,
    filterData.from_date,
    filterData.to_date,
    filterData.department,
    filterData.bill_type,
    filterData.pay_type,
    filterData.bill_no,
    filterData.search,
    filterData.uhid,
    filterData.status,
  ]);

  const billTypeOptions = [
    { id: "All", name: "All" },
    { id: "0", name: "Sales" },
    { id: "1", name: "Return" },
  ];

  const deletePharmacyBillList = async () => {
    const { id }: any = isDeleteDialogOpen.selectedRow;
    setIsDeleteLoading(true);
    await deletePharmacyBill(id as number)
      .then((res: any) => {
        setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
        debouncedGetAllAppointment({
          page: pageInfo.page,
          pageSize: pageInfo.pageSize,
        });
        setIsDeleteLoading(false);
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
      })
      .catch((err) => {
        console.log(err);
        const errorList = Array.isArray(err.response.data.errors.message);
        setIsDeleteLoading(false);
        if (errorList) {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: errorList
                ? err.response.data.errors.message[0]?.table
                : err.response.data.errors,
            })
          );
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
        } else {
          dispatch(
            setSnackBarFailed({ snackBarMessage: "Something went wrong!" })
          );
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
        }
      });
  };
  const TableActions = ({ row }: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Box>
        <KebabMenuIcon
          style={{ width: "15px", height: "15px", cursor: "pointer" }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event: any) => setAnchorEl(event.currentTarget)}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {can("salesbill_pay_add") && !row.is_return && !row.is_cancel && (
            <MenuItem
              onClick={() => {
                handleClose();
              }}
              sx={{
                "& .MuiPopover-paper .MuiMenu-paper": {
                  boxShadow: "0px 5px 10px 0px rgba(35, 35, 35, 0.08)",
                },
                paddingLeft: "5px !important",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                onClick={() => {
                  navigate(
                    `${paymentUrl}${createUrl}?patient=${row?.patient_id}&patient_name=${row.uhid} - ${row.name}&payee=${row?.payee}&bill_no=${row?.id}&type=sales_bill&sub_type=${row?.department}`
                  );
                }}
              >
                <PaymentRupeeIcon
                  style={{
                    margin: "0 10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#232323",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "10px",
                  }}
                >
                  Add Payment
                </Typography>
              </Box>
            </MenuItem>
          )}
          {can("salesbill_edit") && row.is_cancel !== 1 && (
            <MenuItem
              onClick={() => {
                handleClose();
                row.is_return === 1
                  ? can("sales_return_edit") &&
                    navigate(`${pharmacyUrl}${salesReturnUrl}/${row.id}`)
                  : navigate(
                      `${pharmacyUrl}${salesBillListUrl}${editUrl}/${row.id}`
                    );
              }}
              sx={{
                "& .MuiPopover-paper .MuiMenu-paper": {
                  boxShadow: "0px 5px 10px 0px rgba(35, 35, 35, 0.08)",
                },
                paddingLeft: "5px !important",
              }}
            >
              <Box display="flex" alignItems="center" flexDirection="row">
                <EditIcon
                  style={{
                    margin: "0 10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#232323",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "10px",
                  }}
                >
                  Edit bill
                </Typography>
              </Box>
            </MenuItem>
          )}
          {can("salesbill_view") && (
            <MenuItem
              onClick={() => {
                handleClose();
                row.is_return === 1
                  ? can("sales_return_view") &&
                    navigate(
                      `${pharmacyUrl}${salesReturnUrl}${viewUrl}/${row.id}`
                    )
                  : navigate(
                      `${pharmacyUrl}${salesBillListUrl}${viewUrl}/${row.id}`
                    );
              }}
              sx={{
                "& .MuiPopover-paper .MuiMenu-paper": {
                  boxShadow: "0px 5px 10px 0px rgba(35, 35, 35, 0.08)",
                },
                paddingLeft: "5px !important",
              }}
            >
              <Box display="flex" alignItems="center" flexDirection="row">
                <ViewIcon
                  style={{
                    margin: "0 10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#232323",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "10px",
                  }}
                >
                  View bill
                </Typography>
              </Box>
            </MenuItem>
          )}
          {can("salesbill_delete") && (
            <MenuItem
              onClick={() => {
                handleClose();
                setIsDeleteDialogOpen({
                  isOpen: true,
                  selectedRow: { id: row.id },
                });
              }}
              sx={{
                "& .MuiPopover-paper .MuiMenu-paper": {
                  boxShadow: "0px 5px 10px 0px rgba(35, 35, 35, 0.08)",
                },
                paddingLeft: "5px !important",
              }}
            >
              <Box display="flex" alignItems="center" flexDirection="row">
                <DeleteIcon
                  style={{
                    margin: "0 10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#232323",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "10px",
                  }}
                >
                  Delete bill
                </Typography>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>
    );
  };

  const getAllDepartmentList = async () => {
    const data = { active: 1, type: "receiver" };
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data?.result?.map(
          (ele: { dept_name: string; id: number; type: string }) => ({
            name: ele.dept_name,
            id: ele.id,
            type: ele.type,
          })
        );

        // const newReceiverList = formattedValue?.filter(
        //   (ele: { dept_name: string; id: number; type: string }) =>
        //     ele?.type === "receiver"
        // );
        setReceiverList(formattedValue);
      })
      .catch((err: any) => console.log(err));
  };
  const columns: GridColDef[] = [
    {
      field: "bill_invoice_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("bill_invoice_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "bill_invoice_no" &&
              sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "bill_invoice_no" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setIsAscendingOrderClicked(!isDescendingOrderClicked)
            }
            descendingOnClick={() =>
              setIsDescendingOrderClicked(!isAscendingOrderClicked)
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" sx={{ textWrap: "wrap" }}>
          {row?.bill_invoice_no}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "uhid",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("uhid")}
        >
          <Typography variant="h5" fontSize={14}>
            UHID
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "uhid" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "uhid" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Link
          to={`${patientsUrl}${historyUrl}/${row?.patient_id}`}
          style={{
            width: "100%",
            height: "100%",
            textDecoration: "none",
            cursor: "pointer",
            color: "primary.main",
            outline: "none",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            {row?.uhid}
          </Typography>
        </Link>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "name",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Tooltip title={row?.name} arrow placement="top">
          <Typography
            variant="h5"
            fontSize={14}
            sx={{
              textWrap: "wrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            {row?.name}
          </Typography>
        </Tooltip>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "mobile",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("mobile")}
        >
          <Typography variant="h5" fontSize={14}>
            Mobile
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "mobile" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "mobile" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.mobile}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "date_created",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("date_created")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "date_created" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "date_created" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          sx={{ textWrap: "wrap" }}
          fontWeight={!row?.ref_no ? 600 : 400}
        >
          {row?.date_created !== "Total :"
            ? formatTwelveHoursTime(row?.date_created)
            : row?.date_created}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "doctor_name",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("doctor_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Doctor
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "doctor_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "doctor_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Tooltip title={row?.doctor_name} arrow placement="top">
          <Typography
            variant="h5"
            fontSize={14}
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            {row?.doctor_name}
          </Typography>
        </Tooltip>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "net_total_amt",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("net_total_amt")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill Amount
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "net_total_amt" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "net_total_amt" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={{ textWrap: "wrap" }}>
          {Number(row?.net_total_amt | 0).toFixed(2)}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "paid_amt",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("paid_amt")}
        >
          <Typography variant="h5" fontSize={14}>
            Paid Amount
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "paid_amt" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "paid_amt" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={{ textWrap: "wrap" }}>
          {Number(row?.paid_amt || 0).toFixed(2)}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "balance",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("balance")}
        >
          <Typography variant="h5" fontSize={14}>
            Balance
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "balance" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "balance" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={{ textWrap: "wrap" }}>
          {Number(row?.balance || 0).toFixed(2)}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "status",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("status")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "status" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "status" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={{ textWrap: "wrap" }}>
          {row?.status}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "bill_payment_type",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("bill_payment_type")}
        >
          <Typography variant="h5" fontSize={14}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "bill_payment_type" &&
              sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "bill_payment_type" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={{ textWrap: "wrap" }}>
          {row?.bill_payment_type}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "type",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography variant="h5" fontSize={14}>
            Pay Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "type" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "type" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={{ textWrap: "wrap" }}>
          {row?.type}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "created_user",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
            textWrap: "wrap",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_user" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_user" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} sx={{ textWrap: "wrap" }}>
          {row?.created_user}
        </Typography>
      ),
      minWidth: 80,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return row?.id ? <TableActions row={row} /> : null;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  // data table functions

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "pharmacySalesBill",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "pharmacySalesBill",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const clearFilters = () => {
    if (
      filterData.uhid ||
      filterData.bill_type ||
      filterData.pay_type ||
      filterData.status ||
      filterData.bill_no ||
      filterData.department ||
      filterData.from_date ||
      filterData.to_date
    ) {
      dispatch(
        setFilterDataValues({
          key: "pharmacySalesBill",
          name: "clearFilter",
          value: initialFilterData,
        })
      );
      setIsClearFilter(true);
    }
  };

  const handleInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;

      if (name === "uhid" && value) {
        const finalValue = patientsList.filter((obj: any) => {
          if (obj.value === value) {
            return obj;
          }
        });
        dispatch(
          setFilterDataValues({
            key: "pharmacySalesBill",
            name: name,
            value: finalValue[0],
          })
        );
        dispatch(setCurrentPage({ key: "pharmacySalesBill", value: 0 }));
        return;
      }
      dispatch(
        setFilterDataValues({
          key: "pharmacySalesBill",
          name: name,
          value: value,
        })
      );
      dispatch(setCurrentPage({ key: "pharmacySalesBill", value: 0 }));
    }
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(setCurrentPage({ key: "pharmacySalesBill", value: 0 }));
      dispatch(
        setFilterDataValues({
          key: "pharmacySalesBill",
          name: name,
          value: newValue.validatedValue[0],
        })
      );
    }
  };

  // data table filter
  const patientsPagination: any = useRef();
  const [patientsList, setPatientsList] = useState<any>([]);
  const searchPatientsPagination = useRef({ search: "", page: 1 });

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getTestAllPatientsList({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );
  const getAllPatientsList = async (currentValue: any, prevOptions: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions.length / 10)
            ? patientsPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getTestAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  const appointmentFilter = [
    {
      children: (
        <InfiniteScroller
          loadOptions={getAllPatientsList}
          options={patientsList}
          handleOnChange={handleInputChange}
          name="uhid"
          label="UHID"
          value={filterData.uhid?.value}
          width="188px"
        />
      ),
    },
    {
      children: (
        <Select
          value={filterData.bill_type}
          onChange={handleInputChange}
          placeholder="Select"
          options={billTypeOptions}
          label="Bill Type"
          name="bill_type"
          width="180px"
          sx={{
            ...styles.selectGrid,
          }}
        />
      ),
    },
    {
      children: (
        <Select
          value={filterData.pay_type}
          onChange={handleInputChange}
          placeholder="Select"
          options={billPaymentTypeOptions}
          label="Pay Type"
          name="pay_type"
          width="180px"
          sx={{
            ...styles.selectGrid,
          }}
        />
      ),
    },
    {
      children: (
        <Select
          value={filterData.status}
          onChange={handleInputChange}
          placeholder="Select"
          options={commonVariables?.bill_payment_status}
          label="Status"
          name="status"
          width="180px"
          sx={{
            ...styles.selectGrid,
          }}
        />
      ),
    },
    {
      children: (
        <Select
          value={filterData.department}
          onChange={handleInputChange}
          placeholder="Select"
          options={receiverList}
          label="Department"
          name="department"
          width="180px"
          sx={{
            ...styles.selectGrid,
          }}
        />
      ),
    },
    {
      children: (
        <TextField
          value={filterData.bill_no}
          placeholder="Enter Bill No"
          label="Bill No"
          onChange={handleInputChange}
          name="bill_no"
          sx={{
            ...styles.textFieldStyle,
          }}
        />
      ),
    },
    {
      children: (
        <Grid
          sx={{
            ...styles.DatePickerStyle,
          }}
        >
          <DatePicker
            label="From Date"
            name="from_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "from_date")
            }
            format="DD/MM/YYYY"
            value={filterData.from_date}
            style={{ boxShadow: "none", height: "45px", borderRadius: "5px" }}
            borderColor="var(--primary-border-color)"
          />
        </Grid>
      ),
    },
    {
      children: (
        <Grid
          sx={{
            ...styles.DatePickerStyle,
          }}
        >
          <DatePicker
            label="To Date"
            name="to_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "to_date")
            }
            format="DD/MM/YYYY"
            value={filterData.to_date}
            style={{ boxShadow: "none", height: "45px", borderRadius: "5px" }}
            borderColor="var(--primary-border-color)"
          />{" "}
        </Grid>
      ),
    },
  ];
  useEffect(() => {
    getAllDepartmentList();
    getPaymentTypeOptions();
    if (filterData.uhid) {
      setPatientsList([filterData.uhid]);
    }
  }, []);
  return (
    <>
      <Box>
        <CustomFilter
          data={appointmentFilter}
          isFilterEnabled={true}
          isSearchEnabled={true}
          editButtonTitle={can("salesbill_add") ? ADD_BUTTON_TITLE : null}
          addButtonTitle={can("salesbill_draft") ? "BILL DRAFT" : null}
          clearFilter={clearFilters}
          clearSearch={() =>
            dispatch(
              setHandleClearSearch({
                key: "pharmacySalesBill",
                value: "",
                name: "search",
              })
            )
          }
          searchOnChange={handleInputChange}
          searchValue={filterData.search}
          searchName={"search"}
          onAddButtonClick={() => navigate("/pharmacy/bill-draft")}
          onUpdateButtonClick={() =>
            navigate(`${pharmacyUrl}${salesBillListUrl}${createUrl}`)
          }
          appliedFilterCount={count}
          filterGridSx={{
            width: "80%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        />
        <DataTable
          loading={loading}
          columns={columns}
          getRowId={(row: GridRowData) => `${String(row.id)}`}
          rows={salesBillList}
          pageCount={pageCount}
          currentPage="pharmacySalesBill"
        />
        <ListFooter />
      </Box>
      <DeleteDialog
        open={isDeleteDialogOpen.isOpen}
        loading={isDeleteLoading}
        onClose={() =>
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null })
        }
        onConfirm={deletePharmacyBillList}
      />
    </>
  );
};

export default SalesBillsList;
