import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Grid,
  debounce,
  IconButton,
  Switch,
  CircularProgress,
} from "@mui/material";
import { Label, Sort, TextField, Select } from "../../../../components/basic";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { appointmentsMasterConst } from "../../../../constants/displayText";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  getProductsList,
  createProduct,
  editProduct,
  updateProduct,
  deleteProduct,
  getDosageFormList,
} from "../../../../services/appointmentsMasterService";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { setCurrentPage } from "../../../../redux/slices/pagination";

const ProductsList = () => {
  const {
    NAME,
    GENERIC_NAME,
    STATUS,
    ADDED_SUCCESSFULLY,
    UPDATED_SUCCESSFULLY,
    DELETED_SUCCESSFULLY,
  } = appointmentsMasterConst;
  const { can } = PermissionUtils();

  const [dosageOptions, setDosageOptions] = useState<any>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [productToBeDeleted, setProductToBeDeleted] = useState<any>(null);

  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);

  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState<any>("");

  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.appointmentMasterProductsList
  );

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [productsList, setProductsList] = useState([]);

  const initialProductError = {
    name: "",
    generic_name: "",
    dosage_form: "",
    prescription: "",
    active: "",
  };
  const [productError, setProductError] = useState<any>(initialProductError);

  const initialData = {
    name: "",
    generic_name: "",
    dosage_form: null,
    prescription: "",
    active: true,
  };

  const [productFormData, setProductFormData] = useState<string | Date | any>(
    initialData
  );

  const fieldData: any = {
    name: {
      label: "Name",
      name: "name",
      value: productFormData.name,
      isError: productError.name === "" ? false : true,
      helperText: productError.name,
    },
    generic_name: {
      label: "Generic Name",
      name: "generic_name",
      value: productFormData.generic_name,
      isError: productError.generic_name === "" ? false : true,
      helperText: productError.generic_name,
    },
    dosage_form: {
      label: "Dosage Form",
      name: "dosage_form",
      value: productFormData.dosage_form,
      isError: Boolean(productError.dosage_form),
      helperText: productError.dosage_form,
    },
    prescription: {
      label: "prescription",
      name: "prescription",
      value: productFormData.prescription,
      isError: productError.prescription === "" ? false : true,
      helperText: productError.prescription,
    },
    active: {
      label: "Status",
      name: "active",
      value: productFormData.active,
      isError: productError.active === "" ? false : true,
      helperText: productError.active,
    },
  };

  const [productFieldData, setProductFieldData] = useState(fieldData);

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "5px",
        }}
      >
        {can("pxproducts_edit") && (
          <div style={{ cursor: "pointer" }}>
            <IconButton onClick={() => productEdit(row.id)}>
              <EditIcon />
            </IconButton>
          </div>
        )}
        {can("pxproducts_delete") && (
          <div style={{ cursor: "pointer" }}>
            <IconButton onClick={() => productDelete(row.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("name")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Label variant="h5" label={NAME} sx={{ color: "primary.main" }} />
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.name == null ? "" : row?.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "generic_name",
      flex: 1,
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("generic_name")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Label
            variant="h5"
            label={GENERIC_NAME}
            sx={{ color: "primary.main" }}
          />
          <Sort
            ascendingActive={
              sortedField.field === "generic_name" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "generic_name" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.generic_name == null ? "" : row?.generic_name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "status",
      flex: 1,
      renderHeader: () => (
        <Grid
          onClick={() => handleSorting("active")}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Label variant="h5" label={STATUS} sx={{ color: "primary.main" }} />
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.active == null ? "" : row?.active === 1 ? "Active" : "Inactive"}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    ...(can("pxproducts_edit") || can("pxproducts_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  height: "51px",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 120,
            sortable: false,
          },
        ]
      : []),
  ];

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const handleSearchOnChange = (e: any) => {
    setSearchValue(e.target.value);
    dispatch(setCurrentPage({ key: "appointmentMasterProductsList", value: 0 }));
  };

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name":
      case "generic_name":
      case "dosage_form":
      case "prescription": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setProductError
          );
        } else {
          updateFormDataWithHelperText(name, "", setProductError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateProductFieldData = () => {
    setProductFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          helperText: productError[field.name],
          value: productFormData[field.name],
          isError: productError[field.name] === "" ? false : true,
        };
      });
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setProductFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setProductFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };

  const getProductsDetails = useCallback(
    debounce(async (data) => {
      setLoading(true);

      await getProductsList(data)
        .then((result: any) => {
          let data = result?.data?.result;
          setProductsList(data);
          setPageCount(result?.data?.total as any);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          console.log("error", err);
        });
    }, 300),
    []
  );

  const productCreate = async () => {
    if (
      productFormData.name === "" ||
      productFormData.generic_name === "" ||
      productFormData.prescription === "" ||
      productFormData.dosage_form === null
    ) {
      validateForm();
    } else {
      try {
        const data = productFormData;
        setButtonLoading(true);
        await createProduct(data).then((result: any) => {
          dialogClose();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          getProductsDetails({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        setButtonLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
        console.error("An error occurred:", error);
      }
    }
  };

  const productEdit = async (id: number) => {
    if (id === null) {
      return false;
    }
    try {
      setOpen(true);
      setIsDialogLoading(true);
      await editProduct(id).then((result: any) => {
        const data = result?.data;
        setProductFormData({
          name: data.name,
          generic_name: data.generic_name,
          dosage_form: Number(data.dosage_form),
          prescription: data.prescription === null ? "" : data.prescription,
          active: data.active === 1 ? true : false,
        });
        setIdToBeUpdated(id);
        setIsDialogLoading(false);
      });
    } catch (error: any) {
      setOpen(false);
      setIsDialogLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const productUpdate = async () => {
    if (
      productFormData.name === "" ||
      productFormData.generic_name === "" ||
      productFormData.prescription === "" ||
      productFormData.dosage_form === ""
    ) {
      validateForm();
    } else {
      try {
        if (idToBeUpdated === null) {
          return false;
        }
        const data = productFormData;
        setButtonLoading(true);
        await updateProduct(idToBeUpdated, data).then((result: any) => {
          dialogClose();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: UPDATED_SUCCESSFULLY,
            })
          );
          getProductsDetails({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        setButtonLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
        console.error("An error occurred:", error);
      }
    }
  };

  const productDelete = (symptomId: number) => {
    setIsConfirmationDialogOpen(true);
    setProductToBeDeleted(symptomId);
  };

  const handleConfirmDelete = async () => {
    if (productToBeDeleted === null) {
      return false;
    }
    try {
      setButtonLoading(true);
      await deleteProduct(productToBeDeleted).then((result: any) => {
        dialogClose();
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: DELETED_SUCCESSFULLY,
          })
        );
        getProductsDetails({
          page: Number(pageInfo.page) + 1,
          pageSize: pageInfo.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          searchValue: searchValue,
        });
      });
    } catch (error: any) {
      setButtonLoading(false);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.data?.errors,
        })
      );
      setIsConfirmationDialogOpen(false);
      console.error("An error occurred:", error);
    }
  };

  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const dialogOpen = async () => {
    setOpen(true);
  };

  const dialogClose = async () => {
    setIsDialogLoading(false);
    setButtonLoading(false);
    setProductFormData(initialData);
    setOpen(false);
    setIdToBeUpdated(null);
    setProductError(initialProductError);
    setProductToBeDeleted(null);
    setIsConfirmationDialogOpen(false);
  };

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      searchValue: searchValue,
    };
    getProductsDetails(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortedField.field,
    sortedField.order,
    searchValue,
  ]);

  useEffect(() => {
    updateProductFieldData();
  }, [productError, productFormData]);

  const getAllDosageFormList = async () => {
    try {
      await getDosageFormList()?.then((result: any) => {
        setDosageOptions(result?.data);
      });
    } catch (error: any) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getAllDosageFormList();
  }, []);

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title="are you surely want to delete?"
          handleClick={handleConfirmDelete}
          onClose={onClose}
          loading={buttonLoading}
        />

        <CustomFilter
          isSearchEnabled={true}
          searchValue={searchValue}
          searchOnChange={handleSearchOnChange}
          searchName={"search"}
          clearSearch={() => setSearchValue("")}
          title="Products List"
          {...(can("pxproducts_add") && {
            editButtonTitle: "Add",
            onUpdateButtonClick: dialogOpen,
          })}
        />
        <DataTable
          loading={loading}
          columns={columns}
          getRowId={(row: any) => `${String(row.id)}`}
          rows={productsList}
          pageCount={pageCount}
          currentPage={"appointmentMasterProductsList"}
        />
      </Box>

      <DialogWrapper
        onClose={dialogClose}
        open={open}
        handleClick={idToBeUpdated === null ? productCreate : productUpdate}
        title={idToBeUpdated === null ? "Add Product" : "Edit Product"}
        maxWidth="sm"
        loading={buttonLoading}
      >
        {isDialogLoading ? (
          <Grid
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid sx={{ mb: "10px" }}>
              <TextField
                sx={{ width: { xs: "250px", sm: "350px", md: "527px" } }}
                value={fieldData.name.value}
                onChange={handleInputChange}
                name={fieldData.name.name}
                label={fieldData.name.label}
                helperText={fieldData.name.helperText}
                onBlur={(e: any) =>
                  handleValidation((e = { target: fieldData.name }))
                }
              />
            </Grid>
            <Grid sx={{ mb: "10px" }}>
              <TextField
                sx={{ width: { xs: "250px", sm: "350px", md: "527px" } }}
                value={fieldData.generic_name.value}
                onChange={handleInputChange}
                name={fieldData.generic_name.name}
                label={fieldData.generic_name.label}
                helperText={fieldData.generic_name.helperText}
                onBlur={(e: any) =>
                  handleValidation((e = { target: fieldData.generic_name }))
                }
              />
            </Grid>
            <Grid sx={{ mb: "10px" }}>
              <Select
                options={dosageOptions}
                sx={{
                  width: { xs: "250px", sm: "350px", md: "527px" },
                }}
                value={fieldData.dosage_form.value}
                onChange={handleInputChange}
                name={fieldData.dosage_form.name}
                label={fieldData.dosage_form.label}
                helperText={fieldData.dosage_form.helperText}
                selectedType="number"
                onBlur={(e: any) =>
                  handleValidation((e = { target: fieldData.dosage_form }))
                }
                width="527px"
              />
            </Grid>
            <Grid sx={{ mb: "10px" }}>
              <TextField
                sx={{ width: { xs: "250px", sm: "350px", md: "527px" } }}
                value={fieldData.prescription.value}
                onChange={handleInputChange}
                name={fieldData.prescription.name}
                label={fieldData.prescription.label}
                helperText={fieldData.prescription.helperText}
                onBlur={(e: any) =>
                  handleValidation((e = { target: fieldData.prescription }))
                }
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "10px",
              }}
            >
              <Typography>{fieldData.active.label}</Typography>
              <Switch
                name={fieldData.active.name}
                checked={fieldData.active.value}
                onChange={handleSwitchChange}
                disabled={idToBeUpdated === null ? true : false}
              />
            </Grid>
          </>
        )}
      </DialogWrapper>
    </div>
  );
};

export default ProductsList;
