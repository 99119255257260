import { Box, Grid } from "@mui/material";
import { Select, TextField } from "../../../../../components/basic";
import { DeleteIcon, DisabledDelete } from "../../../../../assets/icons";
import { genderValue } from "../../../../../constants/displayText";

function MaternalHistory({
  maternalHistory,
  handleAddNewItem,
  handleMaternalHistoryInputChange,
  handleDeleteRow,
  modeOfDelivery,
}: any) {
  return (
    <>
      <Grid container display={"flex"} flexDirection={"row"}>
        {maternalHistory.map((ele: any, idx: any) => (
          <>
            <Grid item xs={1.5}>
              <Select
                name={"mode_of_delivery"}
                value={maternalHistory[idx]["mode_of_delivery"]}
                label={"Mode Of Delivery"}
                width={"100px"}
                options={modeOfDelivery || []}
                onChange={(e: any) => handleMaternalHistoryInputChange(e, idx)}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                name={"complications"}
                value={maternalHistory[idx]["complications"]}
                label={"Complications"}
                width={"100px"}
                onChange={(e: any) => handleMaternalHistoryInputChange(e, idx)}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                name={"place_of_birth"}
                value={maternalHistory[idx]["place_of_birth"]}
                label={"Place Of Birth"}
                width={"100px"}
                onChange={(e: any) => handleMaternalHistoryInputChange(e, idx)}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                name={"city_name"}
                value={maternalHistory[idx]["city_name"]}
                label={"City Name"}
                width={"100px"}
                onChange={(e: any) => handleMaternalHistoryInputChange(e, idx)}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                name={"age"}
                value={maternalHistory[idx]["age"]}
                label={"Age"}
                width={"100px"}
                onChange={(e: any) => handleMaternalHistoryInputChange(e, idx)}
              />
            </Grid>
            <Grid item xs={2}>
              <Select
                name={"gender"}
                value={maternalHistory[idx]["gender"]}
                label={"Gender"}
                width={"140px"}
                options={genderValue || []}
                onChange={(e: any) => handleMaternalHistoryInputChange(e, idx)}
              />
            </Grid>
            <Grid item xs={1.5}>
              <TextField
                name={"born_weight"}
                value={maternalHistory[idx]["born_weight"]}
                label={"Born Weight"}
                width={"100px"}
                onChange={(e: any) => handleMaternalHistoryInputChange(e, idx)}
              />
            </Grid>
            <Grid
              item
              xs={0.5}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "10%",
              }}
              onClick={() =>
                maternalHistory.length === 1 ? null : handleDeleteRow(idx)
              }
            >
              {maternalHistory.length === 1 ? (
                <DisabledDelete />
              ) : (
                <DeleteIcon style={{ cursor: "pointer" }} />
              )}
            </Grid>
          </>
        ))}
      </Grid>
      <Box
        sx={{
          color: "#3A8DFF",
          fontSize: "14px",
          fontWeight: "400",
          marginTop: "10px",
          cursor: "pointer",
          width: "10%",
        }}
        onClick={handleAddNewItem}
      >
        + Add Item
      </Box>
    </>
  );
}
export default MaternalHistory;
