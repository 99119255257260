import React from "react";
import { TableRow, TableCell, Tooltip, Typography } from "@mui/material";
import { AutoComplete } from "../../../../components/basic";
import { DeleteIcon } from "../../../../assets/icons";
import PermissionUtils from "../../../../utils/PermissionUtils";

type InvestigationRowProps = {
  index: number;
  row?: any;
  handleDelete: (id: number) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    index: number,
    name: string
  ) => void;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: any,
    index: number,
    name: string
  ) => void;
  testOptions?: any;
  handleToolTipClose?: any;
};

const InvestigationRow = React.memo(
  ({
    index,
    row,
    testOptions,
    handleAutoCompleteChange,
    handleAutoCompleteInputChange,
    handleDelete,
    handleToolTipClose,
  }: InvestigationRowProps) => {
    const isOddRow = index % 2 === 0 ? false : true;
    const { can } = PermissionUtils();

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "auto",
          backgroundColor: isOddRow ? "backgroundPrimary.main" : "#F9F9F9",
          border: 1,
          borderColor: isOddRow ? "#EBEBEB" : "#F9F9F9",
          height:
            row?.billing_item?.lab_tests?.length < 2
              ? "36px !important"
              : "auto",
          display: "flex",
          flexDirection: "row",
          boxSizing: "border-box",
          margin: 0,
          "& >*": {
            boxSizing: "border-box",
            margin: 0,
          },
        }}
      >
        <Tooltip
          open={row.billing_item.isTestNameTooltipOpen}
          onClose={() => handleToolTipClose("isTestNameTooltipOpen", index)}
          title={
            <Typography
              sx={{
                color: "white",
                fontSize: "14px",
              }}
            >
              This field is required
            </Typography>
          }
          arrow
        >
          <TableCell
            align="left"
            sx={{
              width: "30vw",
              p: 0,
              height: "38px",
              "& .MuiAutocomplete-root": {
                height: "36px",
              },
              "& .MuiFormLabel-root": {
                display: "none",
              },
              "& .MuiInputBase-root": {
                height: "36px",
                p: "0px",
                pr: "0px",
              },
              "& .MuiOutlinedInput-root": {
                p: "0px",
                pr: "0px",
                boxShadow: "none",
              },
              "& .MuiInputBase-input:focus": {
                backgroundColor: isOddRow
                  ? "backgroundPrimary.main !important"
                  : "#F9F9F9 !important",
              },
              "& .css-1d06h37-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input":
                {
                  p: 0,
                },
            }}
          >
            <AutoComplete
              value={row?.billing_item}
              inputValue={row?.billing_item?.name}
              options={testOptions}
              placeholder="Select Test"
              name="id"
              disableClearable={true}
              onChange={(e: any, newValue: any) =>
                handleAutoCompleteChange(e, newValue, row?.id, "id")
              }
              onInputChange={(e: any, newInputValue: any) => {
                handleAutoCompleteInputChange(
                  e,
                  newInputValue,
                  row?.id,
                  "name"
                );
              }}
              sx={{
                width: {
                  sm: "30vw",
                },
                boxSizing: "border-box",
                margin: 0,
                height: "36px",
                "& .MuiFormControl-root": {
                  height: "36px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                "& .MuiInputBase-root .MuiInputBase-input": {
                  padding: 0,
                  fontSize: "12px",
                  fontWeight: "400",
                },

                fontFamily: ["Inter", "sans-serif"].join(","),

                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "greyScale.light",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                },
                "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
                  borderColor: "greyScale.lighter",
                },
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                  padding: "6.5px",
                  boxShadow: "none",
                  borderRadius: "8px",
                },
              }}
            />
          </TableCell>
        </Tooltip>
        <TableRow
          sx={{
            height:
              row?.billing_item?.lab_tests?.length < 2
                ? "36px !important"
                : "auto",
          }}
        >
          {row?.billing_item?.lab_tests?.map(
            (testDataRow: any, index: number) => {
              return (
                <>
                  <TableRow
                    key={index}
                    sx={{
                      height: "36px !important",
                      borderBottom:
                        index !== row?.billing_item?.lab_tests?.length - 1
                          ? "1px solid var(--table-border)"
                          : "none",
                      "& >*": {
                        boxSizing: "border-box",
                        margin: 0,
                      },
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        width: "28vw",
                        height: "38px",
                        p: "0 10px",
                      }}
                    >
                      {testDataRow.test_name}
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        width: "28vw",
                        height: "38px",
                        p: "0 10px",
                      }}
                    >
                      {testDataRow.unit === null ? "-" : testDataRow.unit}
                    </TableCell>
                  </TableRow>
                </>
              );
            }
          )}
        </TableRow>

        {can("op_investigation_delete") && (
          <TableCell
            align="center"
            sx={{
              width: "1vw",
              height: "38px",
              p: 0,
              position: "absolute",
              right: "15px",
              mt: "3px",
              ml: "10px",
            }}
          >
            <DeleteIcon
              className="delete-icon"
              onClick={() => handleDelete(row?.id)}
              style={{ cursor: "pointer" }}
            />
          </TableCell>
        )}
      </TableRow>
    );
  }
);

export default InvestigationRow;
