import * as React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { Box, Typography } from "@mui/material";
import {
  PatientNameIcon,
  PersonalId,
  PhoneIcon,
  ConsultationForIcon,
} from "../../../assets/icons";
type TooltipProps = {
  PatientName: string;
  personalId: string;
  phone: string;
  consultationFor: string;
  children: React.ReactElement;
};

const MuiTooltips = ({
  PatientName,
  personalId,
  phone,
  consultationFor,
  children,
}: TooltipProps) => {
  const Details = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "auto",
        gap: 1,
        p: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <PatientNameIcon />
        <Typography
          color="initial"
          style={{ marginLeft: "8px", fontSize: "16px", fontWeight: "600" }}
        >
          {PatientName}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <PersonalId />
        <Typography
          color="initial"
          sx={{ ml: 1, fontSize: "14px", fontWight: 400 }}
        >
          {personalId}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <PhoneIcon />

        <Typography
          color="initial"
          sx={{ ml: 1, fontSize: "14px", fontWight: 400 }}
        >
          {phone}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <ConsultationForIcon />
        <Typography
          color="initial"
          sx={{ ml: 1, fontSize: "14px", fontWight: 400 }}
        >
          {consultationFor}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Tooltip
      TransitionProps={{ timeout: 600 }}
      title={Details}
      placement="top-start"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            color: "#514E6A",
            backgroundColor: "#ffff",
            border: " 1px solid #D8D8D8",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
            ml: 3,
            "& .MuiTooltip-arrow": {
              left: "-20px !important",
              "&::before": {
                backgroundColor: "white",
                border: " 1.5px solid #D8D8D8",
                boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.2)",
              },
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default MuiTooltips;
