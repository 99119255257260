import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../../assets/icons";
import {
  Select,
  DatePickerWithTime,
  TextField,
  DatePicker,
} from "../../../../components/basic";
import { purchaseBillInfoConst } from "../../../../constants/displayText";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {InfiniteScroller } from "../../../../components/basic";
import { debounce } from "lodash";
import {
  getAllPurchaseSuppliers,
} from "../../../../services/procurementService";
import {
  getAllDepartments,
  getPoNoOptionsBySupplierId,
} from "../../../../services/laboratoryService";

type InfoProps = {
  infoData?: any;
  setInfoData: any;
  fieldData: any;
  handleValidation: (e: any) => void;
  error?: any;
  setInfoError?: any;
  handleBlurPoNumber?: (e: any) => void;
  isEdit?: boolean;
  setIsSequenceWrapperOpen:React.Dispatch<React.SetStateAction<boolean>>;
  setIsManual:React.Dispatch<React.SetStateAction<any>>;
  isManualAdded:any;
  isManual:any;
  handleConfirmSequenceNoChange:()=>void;
  customSequence?:boolean;

};

type optionsListTypes = {
  id: number;
  name: string;
};
const Info = React.memo(
  ({
    infoData,
    setInfoData,
    fieldData,
    handleValidation,
    error,
    setInfoError,
    handleBlurPoNumber,
    isEdit,customSequence,setIsSequenceWrapperOpen,
    setIsManual,
    isManualAdded,
    isManual,handleConfirmSequenceNoChange
  }: InfoProps) => {
    const {
      supplierName,
      department,
      invoiceNo,
      invoiceValue,
      invoiceDate,
      billSequenceNo,
    } = purchaseBillInfoConst;
    const [suppliersList, setSuppliersList] = useState<
      optionsListTypes[] | any
    >([]);
    const [departmentList, setDepartmentList] = useState<
      optionsListTypes[] | any
    >([]);
    const [issuersList, setIssuersList] = useState<optionsListTypes[]>([]);
    const [poNoOptions, setPoNoOptions] = useState<any>([]);
    const { appConfiguration } = useSelector(
      (state: RootState) => state.appConfiguration
    );

    const handleChange = (e: any) => {
      const { name, value } = e.target;
      setInfoData((prev: any) => ({
        ...prev,
        [name]: value,
        department:
          name === "dept_id"
            ? departmentList.find((ele: any) => ele.id === value)?.name
            : prev.department,
      }));
      setInfoError((prevState: any) => ({
        ...prevState,
        [name]: value ? "" : prevState[name],
      }));
    };

    const handleDateChange = (newValue: any, name: any) => {
      setInfoData((prev: any) => ({
        ...prev,
        [name]: newValue.toString(),
      }));
      setInfoError((prevState: any) => ({
        ...prevState,
        [name]: newValue?.toString() ? "" : prevState[name],
      }));
    };

    const supplierPagination = useRef(1);
  const searchSupplierPagination = useRef({ search: "", page: 1 });


    const debouncedGetAllSupplierList = useCallback(
      debounce(async (currentValue, prevOptions, callback) => {
        try {
          return await getAllPurchaseSuppliers({
            search: currentValue,
            page:searchSupplierPagination.current?.search === currentValue ? searchSupplierPagination.current?.page : 1,
            limit: 10,
          }).then((result: any) => {
            let data = result?.data?.result;
            const formattedSuppliersList = data.map((uniqueData: any) => {
              return { value: uniqueData.id, label: uniqueData.name };
            });
            const uniqueOptions = formattedSuppliersList.filter(
              (option: any) =>
                !prevOptions?.some(
                  (prevOption: any) => prevOption?.value === option?.value
                )
            );
            setSuppliersList(formattedSuppliersList);
            searchSupplierPagination.current.page = searchSupplierPagination.current?.search === currentValue ? searchSupplierPagination.current?.page + 1: 1;
            searchSupplierPagination.current.search = currentValue;
          const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
          });
        } catch (error) {
          console.log("error:", error);
          callback({
            options: [],
            hasMore: false,
          });
          
        }
     
      }, 300),
      []
    );
    const getAllSuppliersList = async (currentValue?: any, prevOptions?: any) => {
      try {
        if (currentValue) {
          return new Promise((resolve) => {
            debouncedGetAllSupplierList(
              currentValue,
              prevOptions,
              (response: {
                options: { value: string | number; label: string | number }[];
                hasMore: boolean;
              }) => {
                resolve(response);
              }
            );
          });
        } else {
          return await getAllPurchaseSuppliers({
            search: currentValue,
            page: supplierPagination.current,
            limit: 10,
          }).then((result: any) => {
            let data = result?.data?.result;

            const formattedSuppliersList = data?.map((uniqueData: any) => {
              return { value: uniqueData.id, label: uniqueData.name };
            });
            if (formattedSuppliersList.length > 0) {
              setSuppliersList((prevState: any) => [
                ...prevState,
                ...formattedSuppliersList,
              ]);
            }
            const uniqueOptions = formattedSuppliersList?.filter(
              (option: any) =>
                !prevOptions?.some(
                  (prevOption: any) => prevOption?.value === option?.value
                )
            );
            const currentValue = supplierPagination.current;
            supplierPagination.current = currentValue + 1;
            const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
            return {
              options: uniqueOptions,
              hasMore,
            };
          });
        }
      } catch (error) {
        console.log("error:",error);
        return {
          options:[] ,
          hasMore:false,
        };
      }
    };

    const getAllDepartmentList = async () => {
      try {
        await getAllDepartments().then((res: any) => {
          if (res.data) {
            const newDepartmentList = res.data?.result
              ?.filter((ele: any) => ele.type === "issuer")
              .map((ele: any) => ({ name: ele.dept_name, id: ele.id }));
            const newIssuerList = res.data?.result
              ?.filter((ele: any) => ele.type === "receiver")
              .map((ele: any) => ({ name: ele.dept_name, id: ele.id }));
            setDepartmentList(newDepartmentList);
            setIssuersList(newIssuerList);

            if (newIssuerList?.length === 1 || newDepartmentList.length === 1) {
              setInfoData((prevState: any) => ({
                ...prevState,
                to_dept: newIssuerList?.length === 1 ? newIssuerList[0].id : "",
                dept_id: isEdit
                  ? prevState.dept_id
                  : newDepartmentList?.length === 1
                  ? newDepartmentList[0].id
                  : "",
                department: newDepartmentList[0]?.name,
              }));
            }
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getAllDepartmentList();
    }, [appConfiguration?.lab_goods_issue_disable]);

    useEffect(() => {
      if (fieldData.supplier_id.value) {
        getPoNoOptionsBySupplierId({ supplier_id: fieldData.supplier_id.value })
          .then((res : any) => {
            if (res.data) {
              setPoNoOptions(res.data?.result);
            }
          })
          .catch((err : any) => {
            console.log("err", err);
          });
      }
    }, [fieldData.supplier_id.value]);

    useEffect(() => {
      if (infoData.supplier_id && infoData.supplier_name) {
        // getAllSuppliersList(infoData.supplier_name);
        setSuppliersList([
          { value: infoData.supplier_id, label: infoData.supplier_name },
        ]);
      }
    }, []);

    const styles = {
      selectGrid: {
        width: "188px",
        height: "45px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
      textFieldStyle: {
        width: "188px",
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 5,
        }}
      >
        <InfiniteScroller
          // addButtonLabel={"+ Add"}
          loadOptions={getAllSuppliersList}
          options={suppliersList}
          handleOnChange={handleChange}
          label={fieldData.supplier_id.label}
          name={fieldData.supplier_id.name}
          value={fieldData.supplier_id.value}
          isDisabled={fieldData.supplier_id.isDisables}
          width="188px"
        />
        <Select
          value={fieldData.dept_id.value}
          onChange={handleChange}
          placeholder={fieldData.dept_id.placeholder}
          options={departmentList}
          label={fieldData.dept_id.label}
          disabled={fieldData.dept_id?.isDisables}
          name={fieldData.dept_id.name}
          width="188px"
          sx={{
            ...styles.selectGrid,
            backgroundColor: fieldData.po_no.isDisabled
              ? "var(--disabled-background)"
              : "initial",
          }}
          error={!!error?.dept_id}
        />
        {appConfiguration?.lab_goods_issue_disable && (
          <Select
            value={fieldData.to_dept.value}
            onChange={handleChange}
            placeholder={fieldData.to_dept.placeholder}
            options={issuersList}
            label={fieldData.to_dept.label}
            name={fieldData.to_dept.name}
            width="188px"
            disabled={fieldData.to_dept?.isDisables}
            sx={{
              ...styles.selectGrid,
              backgroundColor: fieldData.po_no.isDisabled
                ? "var(--disabled-background)"
                : "initial",
            }}
            selectedType="number"
            error={!!error?.to_dept}
          />
        )}
        <TextField
          value={fieldData.invoice_no.value}
          label={fieldData.invoice_no.label}
          placeholder={fieldData.invoice_no.placeholder}
          onChange={handleChange}
          name={fieldData.invoice_no.name}
          sx={{ ...styles.textFieldStyle }}
          error={!!error?.invoice_no}
        />

        <TextField
          value={fieldData.invoice_value.value}
          label={fieldData.invoice_value.label}
          placeholder={fieldData.invoice_value.placeholder}
          name={fieldData.invoice_value.name}
          onChange={handleChange}
          sx={{ ...styles.textFieldStyle }}
          error={!!error?.invoice_value}
          onBlur={(e: any) =>
            handleChange({
              target: {
                name: "invoice_value",
                value: Number(e.target.value).toFixed(2),
              },
            })
          }
        />
        <Grid
          item
          sx={{
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                boxSizing: "border-box",
                width: "188px",
              },
          }}
        >
          <DatePickerWithTime
            value={fieldData.invoice_date.value}
            label={fieldData.invoice_date.label}
            placeholder={fieldData.invoice_date.placeholder}
            onChange={(newValue) => handleDateChange(newValue, "invoice_date")}
            width="188px"
            borderColor="var(--primary-border-color)"
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
            }}
            error={!!error?.invoice_date}
          />
        </Grid>

        {customSequence ? (<TextField
          value={fieldData.ref_no.value}
          label={fieldData.ref_no.label}
          placeholder={fieldData.ref_no.placeholder}
          onChange={handleChange}
          onBlur={handleConfirmSequenceNoChange}
          endPlaceholderIcon={<SettingsIcon 
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsSequenceWrapperOpen(true);
            }}
          
          />}
          name={fieldData.ref_no.name}
          sx={{ ...styles.textFieldStyle }}
          error={!!error?.ref_no}
        />) : null}
        <Grid
          item
          sx={{
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                boxSizing: "border-box",
                width: "188px",
              },
          }}
        >
          <DatePicker
            value={fieldData.due_date.value}
            label={fieldData.due_date.label}
            placeholder={fieldData.due_date.placeholder}
            width="188px"
            style={{ borderRadius: "5px" }}
            onChange={(newValue: any) => handleDateChange(newValue, "due_date")}
            formControlStyle={{ width: "90%" }}
            format="DD/MM/YYYY"
          />
        </Grid>
        <Select
          value={fieldData.po_no.value}
          label={fieldData.po_no.label}
          placeholder={fieldData.po_no.placeholder}
          onChange={handleChange}
          name={fieldData.po_no.name}
          disabled={fieldData.po_no.isDisabled}
          sx={{
            ...styles.selectGrid,
            backgroundColor: fieldData.po_no.isDisabled
              ? "var(--disabled-background)"
              : "initial",
          }}
          options={poNoOptions}
          width="188px"
          error={!!error?.po_no}
        />
      </Grid>
    );
  }
);

export default Info;
