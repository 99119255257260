import React from "react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material/styles";
import { VariableSizeList } from "react-window";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type AutoCompleteWithCheckBoxesProps = {
  label?: any;
  sx?: any;
  placeholder?: string;
  options?: [] | any;
  onChange?: any;
  defaultValue?: [] | any;
  value?: any;
  disabled?: boolean;
  helperText?: any;
  error?: any;
  inputRef?: any;
  PopperWidth?: any;
  disableCloseOnSelect?: any;
  name?: any;
  optionName?: string;
  isCheckBoxEnabled?: boolean;
  onInputChange?: any;
  inputValue?: any;
  noOptionsText?: any;
  loading?: any;
  isVirtualization?: any;
  groupBy?: any;
};

const AutoCompleteWithCheckBoxes = ({
  label,
  sx,
  placeholder,
  options,
  onChange,
  defaultValue,
  value,
  disabled,
  helperText,
  error,
  name,
  optionName = "name",
  isCheckBoxEnabled = true,
  onInputChange,
  inputValue,
  noOptionsText,
  loading,
  isVirtualization = false,
  groupBy,
}: AutoCompleteWithCheckBoxesProps) => {

  const LISTBOX_PADDING = 8;

  const OuterElementContext = React.createContext({});

  const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null);
    React.useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  // Adapter for react-window
  const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: any = React.Children.toArray(children);

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
      noSsr: true,
    });
    const itemCount = options?.length;
    const itemSize = smUp ? 36 : 48;

    const getHeight = () => {
      if (itemCount > 8) {
        return 7 * 40;
      }
      return itemCount * 40;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="li"
            itemSize={() => itemSize}
            overscanCount={5}
            itemCount={itemCount}
            style={{
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            {({ index, style }) => (
              <div
                style={{
                  ...style,
                  top: (style.top as number) + LISTBOX_PADDING,
                }}
              >
                {itemData[index]}
              </div>
            )}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  });

  return (
    <Grid>
      <InputLabel
        sx={{ fontSize: "12px", color: "greyScale.main" }}
        htmlFor="autocomplete-input"
      >
        {label}
      </InputLabel>
      <FormControl fullWidth sx={{ mt: label ? "5px" : "0px" }}>
        <Autocomplete
          multiple
          value={value || []}
          options={options}
          defaultValue={defaultValue}
          onChange={onChange}
          onInputChange={onInputChange}
          inputValue={inputValue}
          getOptionLabel={(option: any) => option[optionName]}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          disabled={disabled}
          disableCloseOnSelect={true}
          noOptionsText={noOptionsText}
          loading={loading}
          groupBy={groupBy}
          popupIcon={<KeyboardArrowDownIcon />}
          ListboxComponent={isVirtualization ? ListboxComponent : undefined}
          renderOption={(props: any, option: any, { selected }: any) => {
            return isCheckBoxEnabled ? (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            ) : (
              <MenuItem
                sx={{
                  "&:hover, &.Mui-focused:hover": {
                    color: "backgroundPrimary.main",
                    backgroundColor: "primary.main",
                  },
                  borderRadius: "5px",
                  p: "15px",
                  m: "0 5px",
                  width: "initial",
                }}
                {...props}
              >
                {option[optionName]}
              </MenuItem>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              error={error}
              name={name}
            />
          )}
          sx={{
            fontFamily: ["Inter", "sans-serif"].join(","),
            width: "200px",

            "& .MuiChip-filled": {
              backgroundColor: "#F4F4F4",
              borderRadius: "5px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "#d32f2f" : "greyScale.lighter", // Default border color
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "#d32f2f" : "greyScale.light", // Change the border color on hover
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "#d32f2f" : "primary.main",
            },
            "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
              borderColor: error ? "#d32f2f" : "greyScale.lighter", // Change the border color on hover
            },
            "& .MuiInputBase-input::placeholder": {
              fontSize: "12px", // Change this to your desired font size
            },
            "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
              padding: "5px 0px",
              boxShadow: "none",
              borderRadius: "5px",
              paddingLeft: "8px",
              minHeight: "45px",
              maxHeight: "110px",
              overflowX: "hidden",
              overflowY: value && value?.length > 2 ? "scroll" : "none",
            },
            "& .MuiInputBase-input.Mui-disabled": {
              borderColor: "#E5E7EB",
              backgroundColor: "#F4F4F5",
              pl: "5px",
              borderRadius: "8px",
            },
            "& .MuiOutlinedInput-root.Mui-disabled": {
              borderColor: "#E5E7EB",
              backgroundColor: "#F4F4F5",
              // pl: "15px",
              borderRadius: "8px",
            },
            ...sx,
          }}
        />
      </FormControl>
      {helperText !== undefined && (
        <FormHelperText
          error={error}
          sx={{ ml: 2, minHeight: "20px", fontSize: "11px", color: "#d32f2f" }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Grid>
  );
};
export default AutoCompleteWithCheckBoxes;
