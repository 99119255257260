import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import {
  CancelButton,
  PageLoader,
  SaveButton,
  Select,
} from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import { goodsIssueConst } from "../../../../constants/displayText";
import Info from "./Info";
import Summary from "./Summary";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  cancelGoodsIssue,
  getGoodsIssueViewProductById,
  getTempGoodsIssueProductById,
  updateTempGoodsIssueApproval,
  updateTempGoodsIssueReject,
} from "../../../../services/gynecologyService";
import { formula } from "../../../../utils/CalculationUtils";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { PrintingLightIcon } from "../../../../assets/icons";
import { getPrintUrl } from "../../../../utils/GeneralUtils";
import { RouteUrls } from "../../../../constants/routes";

const { printUrl } = RouteUrls;

const BillProducts = () => {
  const { productName, batchNo, expiryDate, tax, amount } = goodsIssueConst;
  const { id, bill_type } = useParams();
  const { calculateSubTaxes } = formula.purchaseBill;
  const { calculateRoundedOff } = formula.goodsIssue;
  const [info, setInfo] = React.useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [billingSummary, setBillingSummary] = React.useState<any>({});
  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);
  const [isBillCancelClicked, setIsBillCancelClicked] = useState(false);
  const [isShowCancelBill, setIsCancelBill] = useState(false);
  const printMethod = useRef<any>([{ id: "", name: "", disabled: false }]);
  const printOptions = [
    { id: "a4", name: "A4" },
    { id: "a5", name: "A5" },
    { id: "dot_matrix", name: "Dot Matrix" },
  ];
  const [billProducts, setBillProducts] = useState<any>([]);
  const [isRejectClicked, setIsRejectClicked] = useState(false);
  const [isApprovalClicked, setIsApprovalClicked] = useState(false);
  const getTempGoodsIssueById = async (IssueId: number | string) => {
    setIsDataTableLoading(true);
    await getTempGoodsIssueProductById(IssueId)
      .then((res: any) => {
        const purchaseBill = res.data.bill;
        const billProducts = res.data.bill_products;
        setInfo((prevState) => ({
          ...prevState,
          invoice_no: purchaseBill.invoice_no,
          issuer: purchaseBill.from_dept_name,
          receiver: purchaseBill.to_dept_name,
          date: formatTwelveHoursTime(purchaseBill.created_at),
        }));
        const data = billProducts.map((res: any, index: number) => ({
          id: index,
          product_name: res.product_name,
          batch_no: res.batch_no,
          expiry_date: res.expires_at
            ? res.expires_at.split("-").reverse().join("/")
            : "",
          qty: res.original_qty,
          mrp: Number(res.mrp / res.pack_type).toFixed(2),
          discount: res.discount || 0,
          discount_type: res.discount_type,
          tax_value: `${res.taxname}-${res.tax_id}%`,
          tax: {
            id: res.id,
            name: res.tax,
            tax_name: res.taxname,
            tax_rate: res.tax_id,
            sub_taxes: res.subtax,
          },
          tax_amount: (res.tax_id / 100) * res.amount,
          amount: res.amount,
          strip_qty: res.original_qty / res.pack_type,
          ptr: Number(res.p_rate / res.pack_type).toFixed(2),
        }));
        setBillProducts(data);
        const value = calculateSubTaxes(data) || 0;
        const roundedOff = calculateRoundedOff({
          goods_value: Number(purchaseBill.orig_goods_val),
          tax_amount: Number(purchaseBill.gst_amt),
        });
        setBillingSummary((prevState: any) => ({
          ...prevState,
          goods_value: purchaseBill.orig_goods_val,
          tax: Number(purchaseBill.gst_amt).toFixed(2),
          roundedOff: roundedOff.toFixed(2),
          netAmount: Number(purchaseBill.bill_amt).toFixed(2),
          CGST: value.CGST,
          SGST: value.SGST,
        }));
        setIsDataTableLoading(false);
      })
      .catch((err: any) => {
        navigate("/procurement/goods-issue");
        console.log(err);
        setIsDataTableLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: "Unexpected Error!. Please try again.",
          })
        );
      });
  };

  const getGoodsIssueById = async (IssueId: number | string) => {
    setIsDataTableLoading(true);
    await getGoodsIssueViewProductById(IssueId)
      .then((res: any) => {
        const purchaseBill = res.data.bill;
        const billProducts = res.data.bill_products;
        const validation = res.data.validation;
        const print_template = res.data.print_template;
        const checkCancelBill = {
          is_cancel: purchaseBill.is_cancel,
          is_return: purchaseBill.is_return,
          is_return_filed: validation.is_return_filed,
          is_transfer_done: validation.is_transfer_done,
          is_sale_done: validation.is_sale_done,
          is_adj_done: validation.is_adj_done,
        };
        for (const [, value] of Object.entries(checkCancelBill)) {
          if (value !== 0) {
            setIsCancelBill(true);
          }
        }
        printMethod.current = printOptions.map((ele) => ({
          ...ele,
          disabled: !print_template?.paper_size[ele.id],
        }));
        setInfo((prevState) => ({
          ...prevState,
          invoice_no: purchaseBill.invoice_no,
          issuer: purchaseBill.from_dept_name,
          receiver: purchaseBill.to_dept_name,
          date: formatTwelveHoursTime(purchaseBill.created_at),
        }));
        const data = billProducts?.map((res: any) => ({
          id: res.id,
          product_name: res.product_name,
          batch_no: res.batch_no,
          expiry_date: res.expires_at
            ? res.expires_at.split("-").reverse().join("/")
            : "",
          qty: res.original_qty,
          mrp: Number(res.mrp / res.pack_type).toFixed(2),
          discount: res.discount || 0,
          discount_type: res.discount_type,
          tax_value: `${res.taxname}-${res.tax_id}%`,
          tax: {
            id: res.id,
            name: res.tax,
            tax_name: res.taxname,
            tax_rate: res.tax_id,
            sub_taxes: res.subtax,
          },
          tax_amount: (res.tax_id / 100) * res.amount,
          amount: res.amount,
          strip_qty: res.original_qty / res.pack_type,
          ptr: Number(res.p_rate / res.pack_type).toFixed(2),
        }));
        setBillProducts(data);
        const value = calculateSubTaxes(data) || 0;
        const roundedOff = calculateRoundedOff({
          goods_value: Number(purchaseBill.orig_goods_val),
          tax_amount: Number(purchaseBill.gst_amt),
        });
        setBillingSummary((prevState: any) => ({
          ...prevState,
          goods_value: purchaseBill.orig_goods_val,
          tax: Number(purchaseBill.gst_amt).toFixed(2),
          roundedOff: roundedOff.toFixed(2),
          netAmount: Number(purchaseBill.bill_amt).toFixed(2),
          CGST: value.CGST,
          SGST: value.SGST,
        }));
        setIsDataTableLoading(false);
      })
      .catch((err: any) => {
        navigate("/procurement/goods-issue");
        console.log(err);
        setIsDataTableLoading(false);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: "Unexpected Error!. Please try again.",
          })
        );
      });
  };

  useEffect(() => {
    if (id) {
      setIsDataTableLoading(true);
      bill_type === "bill_list"
        ? getGoodsIssueById(id)
        : getTempGoodsIssueById(id);
    }
  }, [id]);

  const handleCancelBill = async () => {
    setIsBillCancelClicked(true);
    await cancelGoodsIssue(id as string)
      .then((res: any) => {
        setIsBillCancelClicked(false);

        navigate("/procurement/goods-issue");
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
      })
      .catch((err: any) => {
        setIsBillCancelClicked(false);

        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
        console.log(err);
      });
  };
  const handleApprovalBill = async () => {
    setIsApprovalClicked(true);
    await updateTempGoodsIssueApproval(id as string)
      .then((res: any) => {
        setIsApprovalClicked(false);

        navigate("/procurement/goods-issue");
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
      })
      .catch((err: any) => {
        setIsApprovalClicked(false);

        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
        console.log(err);
      });
  };
  const handleRejectBill = async () => {
    setIsRejectClicked(true);
    await updateTempGoodsIssueReject(id as string)
      .then((res: any) => {
        setIsRejectClicked(false);
        navigate("/procurement/goods-issue");
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
      })
      .catch((err: any) => {
        setIsRejectClicked(false);

        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
        console.log(err);
      });
  };

  const handlePrintChange = (event: any) => {
    const { name, value } = event?.target;
    setBillingSummary((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

    const pdfUrl = getPrintUrl(
      `procurements/print_goods_issue_bill/${id}/${value}`
    );
    const queryParams = new URLSearchParams({
      url: pdfUrl,
    }).toString();
    window.open(`${printUrl}?${queryParams}`, "_blank");
  };
  const columns: GridColDef[] = [
    {
      field: "product_name",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {productName}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.product_name}</Typography>
      ),
      sortable: false,
    },
    {
      field: "batch_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {batchNo}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.batch_no}</Typography>
      ),
      sortable: false,
    },
    {
      field: "expiry_date",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {expiryDate}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.expiry_date}</Typography>
      ),
      sortable: false,
    },
    {
      field: "strip_qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Strip Qty
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.strip_qty}</Typography>
      ),
      sortable: false,
    },
    {
      field: "qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Qty
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.qty}</Typography>
      ),
      sortable: false,
    },
    {
      field: "ptr",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            PTR /Unit
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.ptr}</Typography>
      ),
      sortable: false,
    },
    {
      field: "discount_value",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Discount
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{`${row?.discount || 0} ${
          row?.discount_type || ""
        }`}</Typography>
      ),
      sortable: false,
    },
    {
      field: "MRP/Unit",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            MRP/Unit
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.mrp}</Typography>
      ),
      sortable: false,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {tax}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.tax_value}</Typography>
      ),
      sortable: false,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {amount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.amount}</Typography>
      ),
      sortable: false,
    },
  ];

  return isDataTableLoading ? (
    <PageLoader />
  ) : (
    <Grid>
      <Info info={info} />
      <Grid sx={{ m: "20px 0px" }}>
        <DataTable
          columns={columns}
          rows={billProducts}
          tableOnly={true}
          customizedTable={true}
        />
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "0px 0px 5px 5px",
            border: 1,
            borderColor: "var(--table-border)",
            borderTop: 0,
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              width: {
                xs: "35%",
              },
            }}
          >
            <Grid
              sx={{
                width: "45%",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Grid
                sx={{
                  width: "60%",
                }}
              >
                <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                  {/* Discount Value : */}
                </Typography>
              </Grid>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: "600",
                  mr: "1%",
                  textAlign: "left",
                }}
              >
                {/* {Number(billingSummary?.disc_val || 0).toFixed(2)} */}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: {
                xs: "65%",
              },
            }}
          >
            <Grid
              sx={{
                width: "20%",
              }}
            >
              <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                Goods Value :
              </Typography>
            </Grid>
            <Typography sx={{ fontSize: "13px", fontWeight: "600", mr: "2%" }}>
              {Number(billingSummary?.goods_value || 0).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Summary
        billingSummary={billingSummary}
        isTemp={bill_type !== "bill_list"}
      />
      {bill_type === "approval_list" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <SaveButton
            buttonText="Approval Bill"
            sx={{
              width: "200px",
              height: "42px",
              backgroundColor: "#12b23a !important",
              marginTop: "20px",
            }}
            handleClick={() => handleApprovalBill()}
            loading={isApprovalClicked}
          />
          <SaveButton
            buttonText="Reject Bill"
            sx={{
              width: "200px",
              height: "42px",
              backgroundColor: "#f00000 !important",
              marginTop: "20px",
            }}
            handleClick={() => handleRejectBill()}
            loading={isRejectClicked}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "12px",
            alignItems: "center",
          }}
        >
          <CancelButton
            buttonText="Back"
            sx={{ width: "100px", height: "42px", marginTop: "20px" }}
            handleClick={() => navigate("/procurement/goods-issue")}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "12px",
              width: "100%",
            }}
          >
            {!isShowCancelBill && (
              <SaveButton
                buttonText="Cancel Goods Issue"
                sx={{
                  width: "200px",
                  height: "42px",
                  backgroundColor: "red",
                  marginTop: "27px",
                  "&:hover": {
                    backgroundColor: "red !important",
                  },
                }}
                handleClick={() => handleCancelBill()}
                loading={isBillCancelClicked}
              />
            )}

            <Select
              label="Select Print Size"
              placeholder={<PrintingLightIcon />}
              name="print"
              sx={{ width: "306px" }}
              width="306px"
              options={printMethod.current || []}
              value={billingSummary?.print}
              onChange={handlePrintChange}
            />
          </div>
        </div>
      )}
    </Grid>
  );
};

export default BillProducts;
