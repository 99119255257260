import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number | null;
}

type PageTabsProps = {
  data: any;
  scrollButtons?: boolean;
  orientation?: "horizontal" | "vertical";
  sx?: any;
  onChange?: any;
  initialValue?: number | null;
};

const PageTabs = ({
  data,
  scrollButtons,
  orientation = "horizontal",
  sx,
  onChange,
  initialValue = 0,
}: PageTabsProps) => {
  const [value, setValue] = useState<number | null>(initialValue);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (onChange) {
      onChange(event, newValue);
    } else {
      setValue(newValue);
    }
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        className="tab-elements-grid"
        {...other}
        sx={{ width: "100%" }}
      >
        {value === index && (
          <Box
            className="tab-element"
            sx={{ width: "100%", p: 2, boxSizing: "border-box" }}
          >
            {children}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      <Tabs
        value={value}
        indicatorColor="primary"
        onChange={handleChange}
        orientation={orientation}
        textColor="primary"
        variant={scrollButtons ? "scrollable" : "fullWidth"}
        scrollButtons={scrollButtons}
        allowScrollButtonsMobile={scrollButtons}
        className="tabs"
        sx={{
          display: "flex",
          boxShadow: " 3px 5px 15px 0px rgba(35, 35, 35, 0.08)",
          "& .MuiTabs-flexContainer": {
            height: "50px",
            justifyContent: scrollButtons ? "space-between" : "center",
            alignItems: scrollButtons ? "center" : "center",
          },
          "& .MuiTab-root": {
            width: "auto",
            minWidth: scrollButtons ? "140px" : "",
            fontSize: scrollButtons ? "14px" : "14px",
          },
          ...sx,
        }}
      >
        {data?.map((ele: any, idx: number) => (
          <Tab
            key={idx}
            label={ele?.label}
            icon={value === idx ? ele?.highlightedIcon : ele?.Icon}
            iconPosition="start"
            className="tab"
            sx={{
              minHeight: "0px",
              display: "flex",
              textTransform: "none",
              color: "#A7A7A7",
            }}
          />
        ))}
      </Tabs>

      {data?.map((elem: any, idx: any) => (
        <TabPanel key={idx} value={value} index={idx}>
          {elem?.children}
        </TabPanel>
      ))}
    </>
  );
};

export default PageTabs;
