import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import {
  CancelButton,
  SaveButton,
  TextField,
  PageLoader,
  AutoCompleteWithCheckBoxes,
} from "../../../../components/basic";
import { appointmentDetailsConst } from "../../../../constants/displayText";
import { useCallback, useEffect, useState } from "react";
import { DialogWrapper } from "../../../../components/shared";
import {
  createVitalsDetails,
  doctorVitalsDetails,
  vitalsByAppointmentDetails,
  vitalsDetails,
} from "../../../../services/appointmentService";
import { AppDispatch } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../redux/slices/snackbar";

const Vitals = ({ appointmentsDetails, appointmentId }: any) => {
  const [addObservations, setAddObservations] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [observationsList, setObservationsList] = useState<any>([]);
  const [finalVitalsList, setFinalVitalsList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [addedObservationsList, setAddedObservationsList] = useState<any>([]);
  const [appointmentVitals, setAppointmentVitals] = useState<any>([]);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const initialDiagnosisInfo = { allergies: "", symptoms: "", findings: "" };

  const [diagnosisInfo, setDiagnosisInfo] = useState<any>(initialDiagnosisInfo);
  const [validationErrors, setValidationErrors] = useState<any>({});

  const dispatch = useDispatch<AppDispatch>();

  const getVitalsOptions = async () => {
    setLoading(true);
    try {
      await vitalsDetails().then((result: any) => {
        const vitalsData = result.data.map((data: any) => ({
          ...data,
          value: null,
        }));
        setOptions(vitalsData);

        setLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setLoading(false);
    }
  };

  const getAppointmentVitals = async () => {
    try {
      await vitalsByAppointmentDetails(appointmentsDetails?.id).then(
        (result: any) => {
          const transformedAppointmentVitals = result?.data?.map(
            (data: any) => ({
              id: data?.vital_id,
              name: data?.vital_name,
              value: data?.vital_value,
              unit: data?.vital_unit,
            })
          );

          setAppointmentVitals(transformedAppointmentVitals);
        }
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getDoctorVitals = async () => {
    try {
      await doctorVitalsDetails(appointmentsDetails.doctor_id).then(
        (result: any) => {
          const doctorVitalsData = result?.data?.map((data: any) => ({
            id: data?.id,
            name: data?.name,
            value: null,
            unit: data?.unit,
          }));

          setObservationsList(doctorVitalsData);
        }
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const [bmiStatus, setBmiStatus] = useState({
    category: "",
    color: "",
  });

  const handleBmiCalculation = useCallback(
    (values: any, index?: number) => {
      let bmiValue: number | string = 0;
      let ibwValue: number | string = 0;
      const height = values.find(
        (ele: { name: string; value: string | number }) =>
          ele.name === "Height" && Boolean(ele.value)
      );
      const weight = values.find(
        (ele: { name: string; value: string | number }) =>
          ele.name === "Weight" && Boolean(ele.value)
      );
      const ibwIndex = values.findIndex(
        (ele: { name: string }) => ele.name === "IBW"
      );
      const bmiIndex = values.findIndex(
        (ele: { name: string }) => ele.name === "BMI"
      );

      if (Boolean(height?.value)) {
        let data = appointmentsDetails.patient_sex === 1 ? 100 : 105;
        ibwValue = (Number(height?.value || 0) - data).toFixed(1);
        setBmiStatus((prevState) => ({
          ...prevState,
          category: "",
          color: "",
        }));
      }
      if (Boolean(height?.value && weight?.value)) {
        bmiValue = (
          Number(weight?.value || 0) /
          Math.pow(Number(height?.value || 0) / 100, 2)
        ).toFixed(1);
        if (Number(bmiValue) < 18.5) {
          setBmiStatus((prevState) => ({
            ...prevState,
            category: "Underweight",
            color: "#ffc44d",
          }));
        } else if (Number(bmiValue) >= 18.5 && Number(bmiValue) <= 24.9) {
          setBmiStatus((prevState) => ({
            ...prevState,
            category: "Normal Weight",
            color: "#0be881",
          }));
        } else if (Number(bmiValue) >= 25 && Number(bmiValue) <= 29.9) {
          setBmiStatus((prevState) => ({
            ...prevState,
            category: "Overweight",
            color: "#ff884d",
          }));
        } else {
          setBmiStatus((prevState) => ({
            ...prevState,
            category: "Obese",
            color: "#ff5e57",
          }));
        }
      }
      if (Boolean(!height?.value)) {
        ibwValue = 0;
      }
      if (Boolean(!height?.value || !weight?.value)) {
        bmiValue = 0;
      }

      if (validationErrors.BMI) {
        setValidationErrors((prev: any) => ({
          ...prev,
          BMI: bmiValue ? "" : "*This is required field",
        }));
      }

      if (validationErrors.IBW) {
        setValidationErrors((prev: any) => ({
          ...prev,
          IBW: ibwValue ? "" : "*This is required field",
        }));
      }

      return {
        ibwValue,
        ibwIndex,
        bmiValue,
        bmiIndex,
      };
    },
    [appointmentsDetails.patient_sex, finalVitalsList]
  );

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    let numericValue = value.replace(/\D/g, "");

    const height = finalVitalsList.find((ele: any) => ele.name === "Height");

    const weight = finalVitalsList.find((ele: any) => ele.name === "Weight");

    if (name === "IBW" && Boolean(height?.value)) {
      return false;
    }

    if (name === "BMI") {
      if (Boolean(height?.value) || Boolean(weight?.value)) {
        return false;
      }
    }

    setFinalVitalsList((prev: any) => {
      const newState = [...prev];
      newState[index as number] = {
        ...newState[index as number],
        value: numericValue,
      };

      if (name === "Height" || name === "Weight") {
        const { bmiIndex, bmiValue, ibwIndex, ibwValue } = handleBmiCalculation(
          newState,
          index
        );

        if (bmiIndex !== -1) {
          newState[bmiIndex as number] = {
            ...newState[bmiIndex as number],
            value: bmiValue,
          };
        }
        if (ibwIndex !== -1) {
          newState[ibwIndex as number] = {
            ...newState[ibwIndex as number],
            value: ibwValue,
          };
        }
      }
      return newState;
    });

    if (validationErrors[name]) {
      setValidationErrors((prev: any) => ({
        ...prev,
        [name]: numericValue ? "" : "*This is required field",
      }));
    }
  };

  const handleAutoCompleteChange = (e: any, newValue: any) => {
    if (newValue === null) {
      return false;
    }
    setAddedObservationsList((prev: any) => newValue);
  };

  function createMergedArray(arrOne: any, arrTwo: any) {
    return arrTwo.map((itemTwo: any) => {
      // Check if there's an object with the same id in arrayOne
      const foundItem = arrOne.find(
        (itemOne: any) => itemOne.id === itemTwo.id
      );

      // If found, return the object from arrayOne; otherwise, return the object from arrayTwo
      return foundItem || itemTwo;
    });
  }

  const handleAddChange = () => {
    const mergedArray = createMergedArray(
      finalVitalsList,
      addedObservationsList
    );

    setFinalVitalsList(mergedArray);
  };

  const finalVitals = async () => {
    try {
      if (appointmentVitals?.length > 0) {
        setAddedObservationsList(appointmentVitals);
        setFinalVitalsList(appointmentVitals);
      } else {
        setAddedObservationsList(observationsList);
        setFinalVitalsList(observationsList);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const createVitals = async () => {
    let errors: any = {};

    finalVitalsList.forEach((observation: any, index: any) => {
      if (!observation.value) {
        errors[observation.name] = "*This is required field";
      }
    });

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    } else {
      setIsButtonLoading(true);
      try {
        const vitalsData = {
          appointment_id: appointmentsDetails.id,
          vitals: finalVitalsList?.map((data: any) => ({
            vital_id: data?.id,
            vital_name: data?.name,
            vital_value: data?.value,
          })),
        };

        await createVitalsDetails(vitalsData).then((result: any) => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage:
                appointmentDetailsConst.DIAGNOSIS_SAVED_SUCCESS as string,
            })
          );
          setIsButtonLoading(false);
        });
      } catch (error: any) {
        setIsButtonLoading(false);
        console.error(
          "An error occurred:",
          error?.response?.data?.error_data?.vitals[0]
        );
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.error_data?.vitals[0],
          })
        );
      }
    }
  };

  useEffect(() => {
    getVitalsOptions();
    getAppointmentVitals();
    getDoctorVitals();
  }, []);

  useEffect(() => {
    finalVitals();
  }, [appointmentVitals, observationsList]);

  useEffect(() => {
    setFinalVitalsList((prev: any) => {
      const newState = [...prev];

      const { bmiIndex, bmiValue, ibwIndex, ibwValue } =
        handleBmiCalculation(newState);

      if (bmiIndex !== -1) {
        newState[bmiIndex as number] = {
          ...newState[bmiIndex as number],
          value: bmiValue,
        };
      }
      if (ibwIndex !== -1) {
        newState[ibwIndex as number] = {
          ...newState[ibwIndex as number],
          value: ibwValue,
        };
      }

      return newState;
    });
  }, [addObservations]);

  const handleTextAreaChange = (e: any) => {
    const { name, value } = e?.target;

    setDiagnosisInfo((prev: any) => ({ ...prev, [name]: value }));
  };

  return (
    <Box>
      {loading ? (
        <PageLoader />
      ) : (
        <Grid sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Typography variant="h2">
            {appointmentDetailsConst.OBSERVATION}
          </Typography>

          <Grid
            item
            xl={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              m: "20px 0px",
            }}
          >
            {finalVitalsList?.map((observation: any, index: number) => {
              return (
                <Grid
                  item
                  key={observation?.id || observation.vital_id}
                  sx={{ mt: "10px" }}
                >
                  <TextField
                    name={observation?.name}
                    label={observation?.name}
                    inputAdornment={observation?.unit?.replace(
                      /<\/?[^>]+(>|$)/g,
                      ""
                    )}
                    value={observation?.value}
                    onChange={(e: any) => handleInputChange(e, index)}
                    helperText={validationErrors[observation?.name]}
                    sx={{
                      width: "200px",
                      mr: 6,
                      mb:
                        observation?.name === "BMI" ||
                        observation?.name === "IBW"
                          ? "2px"
                          : 2,
                    }}
                  />
                  {observation?.name === "BMI" && (
                    <Typography
                      sx={{
                        color: bmiStatus.color,
                        fontSize: "12px",
                        margin:
                          validationErrors[observation?.name] === ""
                            ? "0px"
                            : "5px 0px",
                        fontWeight: 500,
                      }}
                    >
                      {observation?.value ? bmiStatus.category : ""}
                    </Typography>
                  )}
                </Grid>
              );
            })}

            <Button
              variant="text"
              sx={{
                width: "100px",
                height: "50px",
                mt: 2.6,
                textTransform: "none",
              }}
              onClick={() => setAddObservations(true)}
            >
              {appointmentDetailsConst.ADD_NEW}
            </Button>
          </Grid>

          {/* developer comment */}
          <Grid
            sx={{ width: "100%", display: "flex", flexDirection: "column" }}
          >
            {/* <Typography variant="h2" color="initial">
              Diagnosis Information
            </Typography>

            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                m: "20px 0px",
              }}
            >
              <TextArea
                label="Allergies"
                name="allergies"
                value={diagnosisInfo?.allergies}
                onChange={handleTextAreaChange}
                placeholder="Enter Allergies"
              />
              <TextArea
                label="Symptoms"
                name="symptoms"
                value={diagnosisInfo?.symptoms}
                onChange={handleTextAreaChange}
                placeholder="Enter symptoms"
              />
              <TextArea
                label="Findings"
                name="findings"
                value={diagnosisInfo?.findings}
                onChange={handleTextAreaChange}
                placeholder="Enter Findings"
              />
            </Grid> */}
            <Grid item sx={{ display: "flex", gap: 2, m: "20px 0px" }}>
              <SaveButton
                sx={{ width: "100px", height: "42px" }}
                handleClick={createVitals}
                loading={isButtonLoading}
                disabled={finalVitalsList.length > 0 ? false : true}
              />
              <CancelButton sx={{ width: "100px", height: "42px" }} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {addObservations && (
        <DialogWrapper
          open={addObservations}
          title="Add Observations"
          onClose={() => setAddObservations(!addObservations)}
          handleClick={() => {
            setAddObservations(!addObservations);
            handleAddChange();
          }}
          confirmText="Apply"
          fullWidth={false}
        >
          <AutoCompleteWithCheckBoxes
            label="Vitals"
            value={addedObservationsList}
            placeholder="Select Vitals"
            isCheckBoxEnabled={true}
            onChange={(e: any, newValue: any) =>
              handleAutoCompleteChange(e, newValue)
            }
            options={options}
            sx={{
              mb: 3,
              width: {
                xl: "500px",
                md: "500px",
                sm: "250px",
              },
            }}
          />
        </DialogWrapper>
      )}
    </Box>
  );
};

export default Vitals;
