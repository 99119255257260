import { Box, Grid, Typography, debounce } from "@mui/material";
import { DialogWrapper } from "../../../../components/shared";
import { InfiniteScroller } from "../../../../components/basic";
import { useEffect, useRef, useState } from "react";
import {
  createAdjustment,
  getAdjustmentById,
  getAdvanceAndExcess,
  getAllPatientsList,
  updateAdjustment,
} from "../../../../services/paymentService";
import { Select, TextField } from "../../../../components/basic";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { adjustmentErrors } from "../../../../constants/displayText";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";

type AddAndEditModalProps = {
  isOpen: boolean;
  onClose: (isSave: boolean) => void;
  onSave?: (value: any) => void;
  isSaveLoading?: boolean;
  id: null | string | number;
};

const AddAndEditModal = (props: AddAndEditModalProps) => {
  const { isOpen, onClose, id } = props;
  const dispatch = useDispatch();
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [data, setData] = useState<any>({
    patient_id: "",
    adjustment_type: "",
    type: "",
    balance: 0,
    adjustment: "",
  });
  const [patientsList, setPatientsList] = useState<
    { value: string | number; label: string | number }[] | []
  >([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    patient_id: "",
    adjustment_type: "",
    type: "",
    adjustment: "",
  });
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const supplierPagination = useRef(1);
  const searchSupplierPagination = useRef({ search: "", page: 1 });

  const debouncedGetAllSupplierList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {

        return await getAllPatientsList({
          search: currentValue,
          page:
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page
              : 1,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData?.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: { value: string | number; label: string | number }) =>
              !prevOptions.some(
                (prevOption: {
                  value: string | number;
                  label: string | number;
                }) => prevOption.value === option.value
              )
          );
          setPatientsList(uniqueOptions);
          searchSupplierPagination.current.page =
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page + 1
              : 1;
          searchSupplierPagination.current.search = currentValue;
          const hasMore = result.data.total > uniqueOptions?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
        
      } catch (error) {

        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
        
      }
     
    },
    300
  );

  const getAllPatients = async (currentValue: any, prevOptions: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllSupplierList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          supplierPagination.current === Math.ceil(prevOptions.length / 10)
            ? supplierPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          limit: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData?.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: { value: string | number; label: string | number }) =>
              !prevOptions.some(
                (prevOption: {
                  value: string | number;
                  label: string | number;
                }) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = supplierPagination.current;
          supplierPagination.current = currentValue + 1;
          const hasMore = result.data.total > uniqueOptions?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:",error);
      return {
        options:[] ,
        hasMore:false,
      };
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event?.target;

    setData((prevState: any) => ({
      ...prevState,
      [name]: name === "adjustment" ? value.replace(/[+-]/g, "") : value,
    }));

    if (errors[name] && value) {
      setErrors((PrevState) => ({ ...PrevState, [name]: "" }));
    }

    if (name === "adjustment" && value > data.balance) {
      setErrors((PrevState) => ({
        ...PrevState,
        [name]: "The adjustment should not exceed the amount.",
      }));
    }
  };

  const handleClose = (isSave: boolean) => {
    setData({
      patient_id: "",
      adjustment_type: "",
      type: "",
      balance: 0,
      adjustment: "",
    });
    setErrors({
      patient_id: "",
      adjustment_type: "",
      type: "",
      adjustment: "",
    });
    onClose(isSave);
  };

  const handleValidate = () => {
    const requiredKeys = [
      "patient_id",
      "adjustment_type",
      "type",
      "adjustment",
    ];

    const newErrors: { [key: string]: string } = {};
    requiredKeys?.forEach((key: string) => {
      if (!data[key]) {
        newErrors[key] = adjustmentErrors[key];
      } else {
        newErrors[key] = "";
      }
    });

    if (!data.adjustment || !/^\d*$/.test(data.adjustment)) {
      newErrors.adjustment = "Please remove the special symbols";
      setErrors(newErrors);
      return false;
    }

    if (Object.values(newErrors)?.filter((ele) => Boolean(ele))?.length > 0) {
      setErrors(newErrors);
      return false;
    }

    if (Number(data.balance) < Number(data.adjustment)) {
      setErrors((prevstate) => ({
        ...prevstate,
        adjustment: "The adjustment should not exceed the amount.",
      }));
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (handleValidate()) {
      if (id) {
        setIsSaveLoading(true);
        updateAdjustment(id, data)
          .then((res: any) => {
            if (res) {
              dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            }
            setIsSaveLoading(false);
            handleClose(true);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
            if (err?.response?.data?.errors) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
            if (err?.response?.data?.error_data?.balance) {
              setData((prevState: any) => ({
                ...prevState,
                balance: err?.response?.data?.error_data?.balance,
              }));
            }
          });
      } else {
        setIsSaveLoading(true);
        createAdjustment(data)
          .then((res: any) => {
            if (res) {
              dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            }
            setIsSaveLoading(false);
            handleClose(true);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
            if (err?.response?.data?.errors) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }

            if (err?.response?.data?.error_data?.balance) {
              setData((prevState: any) => ({
                ...prevState,
                balance: err?.response?.data?.error_data?.balance,
              }));
            }
          });
      }
    }
  };

  useEffect(() => {
    if (data.patient_id && data.adjustment_type && !id) {
      getAdvanceAndExcess({
        patient_id: data.patient_id,
        type: data.adjustment_type,
      })
        .then((res: any) => {
          if (res.data) {
            console.log("res", res.data);
            setData((prevstate: any) => ({
              ...prevstate,
              balance:
                res?.data[
                  data.adjustment_type === "Advance" ? "advance" : "excess"
                ] || 0,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data.patient_id, data.adjustment_type]);

  useEffect(() => {
    if (id) {
      getAdjustmentById(id)
        .then((res: any) => {
          if (res.data) {
            console.log("res", res.data);
            setPatientsList([
              { value: res?.data?.patient_id, label: res?.data?.name },
            ]);
            setData({
              ...res.data,
              adjustment_type:
                res?.data?.is_advance === 0 ? "Excess" : "Advance",
              adjustment:
                res?.data?.is_advance === 0
                  ? -res?.data?.net_excess
                  : -res?.data?.cash_received,
              balance:
                res?.data?.is_advance == 1
                  ? (Number(res?.data?.current_advance) || 0) -
                    (Number(res?.data?.cash_received) || 0)
                  : (Number(res?.data?.current_excess) || 0) -
                    (Number(res?.data?.net_excess) || 0),
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [id]);

  return (
    <DialogWrapper
      open={isOpen}
      maxWidth="sm"
      onClose={() => handleClose(false)}
      sx={{
        "& .MuiDialogContent-root": {
          overflowY: "unset",
        },
      }}
      handleClick={handleSave}
      title={`${id ? "Update" : "Add"} Adjustment`}
      loading={isSaveLoading}
    >
      <Box>
        <Grid container display="flex" rowGap={2}>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <InfiniteScroller
              loadOptions={getAllPatients}
              options={patientsList}
              handleOnChange={handleChange}
              name={"patient_id"}
              label={"Select Patient"}
              value={data.patient_id}
              width="100%"
              helperText={errors?.patient_id}
              isDisabled={!!id}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Select
              value={data?.adjustment_type}
              onChange={handleChange}
              placeholder={"Select adjustment type"}
              options={commonVariables?.payment_type?.filter(
                (ele: any) => ele.adjustable
              )}
              label="Adjustment Type"
              name={"adjustment_type"}
              width=""
              formControlStyle={{ width: "100%" }}
              helperText={errors.adjustment_type}
              disabled={!!id}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Select
              value={data.type}
              onChange={handleChange}
              placeholder={"Select Payment type"}
              options={commonVariables?.payment_type?.filter(
                (ele: any) => !ele.adjustable
              )}
              label="Payment Type"
              name={"type"}
              width=""
              formControlStyle={{ width: "100%" }}
              helperText={errors?.type}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Typography>Balance: {data.balance}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <TextField
              value={data.adjustment}
              label={"Adjustment"}
              onChange={handleChange}
              name={"adjustment"}
              type="number"
              helperText={errors?.adjustment}
              error={!!errors.adjustment}
            />
          </Grid>
        </Grid>
      </Box>
    </DialogWrapper>
  );
};

export default AddAndEditModal;

