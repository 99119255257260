import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { billInfoConst } from "../../../../constants/displayText";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import { BillCancelImg } from "../../../../assets/images";
import { RouteUrls } from "../../../../constants/routes";

type InfoProps = {
  info: any;
};

const Info = ({ info }: InfoProps) => {
  const {
    UHID,
    doctor,
    mobile,
    address,
    date,
    payee,
    billNo,
    paymentType,
    ReferredBy,
    type,
    OPNO,
    IPNO,
    department,
  } = billInfoConst;

  const patientInformation = [
    {
      name: UHID,
      value: `${info.UHID} - ${info.name}`,
    },
    {
      name: doctor,
      value: info.doctor_name,
    },
    {
      name: mobile,
      value: info.mobile,
    },
    {
      name: address,
      value: `${info?.line_1 === "-" ? " " : info?.line_1 + "\n"}${
        info?.line_2 === "-" ? " " : info?.line_2 + "\n"
      }${info?.area === "-" ? " " : info?.area + "\n"}${
        info?.district ? " " : info?.district + "\n"
      }${info?.state ? " " : info?.state}\n${
        info?.pincode ? " " : info?.pincode
      }`,
    },
  ];

  const billingInformationOne = [
    {
      name: date,
      value: info.date_created ? formatTwelveHoursTime(info.date_created) : "",
    },
    {
      name: payee,
      value: info.payeename,
    },
    {
      name: paymentType,
      value: Number(info.bill_payment_type) === 1 ? "Pay now" : "Credit",
    },
    {
      name: billNo,
      value: info.bill_no,
    },
  ];

  const billingInformationTwo = [
    {
      name: ReferredBy,
      value: info.refdoctor,
    },
    {
      name: type,
      value: info.rdType,
    },
    ...(info.rdType === "OP"
      ? [
          {
            name: OPNO,
            value: info.op_no,
            op_id: info.op_id,
          },
        ]
      : info.rdType === "IP"
      ? [
          {
            name: IPNO,
            value: info.ip_no,
            ip_admission_id: info.ip_admission_id,
          },
        ]
      : []),
    {
      name: department,
      value: info.department,
    },
  ];

  return (
    <Grid
      container
      sx={{
        width: "92vw",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
      }}
    >
      <Grid
        container
        sx={{
          width: { xs: "70vw", md: "30vw" },
          display: "flex",
          flexDirection: "column",
          border: 1,
          borderColor: "greyScale.lighter",
          borderRadius: "5px",
        }}
      >
        <Grid
          sx={{
            height: "50px",
            display: "flex",
            alignItems: "center",
            pl: 2,
            borderBottom: 1,
            borderColor: "greyScale.lighter",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "primary.main",
            }}
          >
            Patient Information
          </Typography>
        </Grid>
        <Grid
          container
          sx={{
            width: "100%",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: { xs: "space-around" },
            }}
          >
            <Grid
              item
              sx={{
                width: {
                  xs: "90%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                m: "15px 0px 10px 0px",
              }}
            >
              {patientInformation.map((row, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      width: {
                        xs: "100%",
                        lg: "100%",
                      },
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      maxHeight: "35px",
                      mb: "23px",
                      color: "textPrimary.main",
                    }}
                    className="row"
                  >
                    <Grid
                      sx={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        {row.name}
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        :
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        width: "70%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        pl: 3,
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "greyScale.main",
                          width: "100%",
                          wordWrap: "break-word",
                        }}
                      >
                        {row.value}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          width: { xs: "70vw", md: "60vw" },
          display: "flex",
          flexDirection: "column",
          border: 1,
          borderColor: "greyScale.lighter",
          borderRadius: "5px",
        }}
      >
        <Grid
          sx={{
            width: "100%",
            height: "50px",
            display: "flex",
            alignItems: "center",
            pl: 2,
            borderBottom: 1,
            borderColor: "greyScale.lighter",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontSize: "12px",
              fontWeight: "600",
              color: "primary.main",
              width: "100%",
            }}
          >
            Billing Information
          </Typography>
          {info?.is_cancel ? (
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Box>
                <img src={BillCancelImg} />
              </Box>
            </Box>
          ) : null}
        </Grid>
        <Grid
          container
          sx={{
            width: "100%",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: { xs: "space-around" },
            }}
          >
            <Grid
              item
              sx={{
                width: {
                  xs: "90%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                m: "15px 0px 10px 0px",
              }}
            >
              {billingInformationOne.map((row, index) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      width: {
                        xs: "100%",
                        lg: "100%",
                      },
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      maxHeight: "35px",
                      mb: "23px",
                      color: "textPrimary.main",
                    }}
                    className="row"
                  >
                    <Grid
                      sx={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        {row.name}
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        :
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        width: "70%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        pl: 3,
                      }}
                    >
                      <Typography variant="h6" sx={{ color: "greyScale.main" }}>
                        {row.value}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: "50%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: { xs: "space-around" },
            }}
          >
            <Grid
              item
              sx={{
                width: {
                  xs: "90%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                m: "15px 0px 10px 0px",
              }}
            >
              {billingInformationTwo.map((row: any, index: number) => {
                return (
                  <Grid
                    key={index}
                    sx={{
                      width: {
                        xs: "100%",
                        lg: "100%",
                      },
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      maxHeight: "35px",
                      mb: "23px",
                      color: "textPrimary.main",
                    }}
                    className="row"
                  >
                    <Grid
                      sx={{
                        width: "30%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        {row.name}
                      </Typography>
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        :
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        width: "70%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        pl: 3,
                      }}
                    >
                      {row.name === "IP No" || row.name === "OP No" ? (
                        <a
                          style={{ textDecoration: "unset", fontSize: "12px" }}
                          rel="noreferrer"
                          target="_blank"
                          href={
                            row.name === "IP No"
                              ? `${RouteUrls.inPatients}${RouteUrls.ipSummary}/${row?.ip_admission_id}`
                              : `${RouteUrls.appointmentUrl}${RouteUrls.appointmentsDetails}/${row?.op_id}`
                          }
                        >
                          {row.value}
                        </a>
                      ) : (
                        <Typography
                          variant="h6"
                          sx={{
                            color:
                              row.name === "OP No"
                                ? "primary.main"
                                : "greyScale.main",
                          }}
                        >
                          {row.value}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Info;
