import { Box } from "@mui/material";
import React from "react";
export type CardPropsTypes = {
  TitleIcon?: React.ElementType;
  HeaderActionIcon?: React.ElementType;
  title?: string;
  titleColor?: string;
  fontSize?: string;
  headerActionTitle?: string;
};
const CardTitle = ({
  title,
  TitleIcon,
  titleColor,
  fontSize,
  headerActionTitle,
  HeaderActionIcon,
}: CardPropsTypes) => {
  const styles = {
    mainBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleBox: {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    titleStyles: {
      textTransform: 'capitalize',
      color: titleColor || 'inherit',
      fontSize: fontSize || '15px',
      fontWeight: '600 !important',
    },
    headerActionIcon: {
      display: 'flex',
      gap: '5px',
      alignItems: 'center',
      cursor: 'pointer',
    },
    headerActionTitle: { fontSize: '13px', color: titleColor },
  };
  return (
    <Box sx={{ display: "flex", p: 0, m: 0, justifyContent: "space-between" }}>
      <Box>
        {HeaderActionIcon && (
          <Box>
            <HeaderActionIcon />
          </Box>
        )}
        <Box
          sx={{
            textTransform: "capitalize",
            color: titleColor,
            fontSize: fontSize || "15px",
            fontWeight: "600 !important",
          }}
        >
          {title}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {TitleIcon && (
          <>
            <Box>
              <TitleIcon />
            </Box>
          </>
        )}
        <Box sx={{ fontSize: "13px", color: titleColor }}>
          {headerActionTitle}
        </Box>
      </Box>
    </Box>
  );
};
export default CardTitle;
