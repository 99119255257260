import { useState, useEffect, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  Typography,
  Grid,
  CircularProgress,
  Switch,
  debounce,
} from "@mui/material";
import {
  Sort,
  CustomButton,
  TextField,
  BrowseButton,
  SaveButton,
  PageLoader,
  Radio,
} from "../../../../components/basic";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import { ActiveStatusColor, statusColor } from "../../../../utils/GeneralUtils";
import {
  gstNoValidator,
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import CustomFilter from "../../../../components/shared/customFilter";
import { displayText } from "../../../../constants/displayText";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import {
  addSuppliers,
  deleteSupplier,
  getAllSupplierList,
  getSupplierListById,
  updateSupplierById,
} from "../../../../services/procurementMasterService";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { setCurrentPage } from "../../../../redux/slices/pagination";
type GridRowData = Record<string, unknown>;

function Suppliers() {
  const { ADD_BUTTON_TITLE, CONFIRMATION_DELETE_DIALOG_TITLE } = displayText;

  // data table constants and states

  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [loading, setLoading] = useState(false);

  const [supplierList, setSupplierList] = useState([]);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<any>([]);

  const [isAddAndEditDialogOpen, setIsAddAndEditDialogOpen] =
    useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isAddAndEditDialogLoading, setIsAddAndEditDialogLoading] =
    useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);

  // confirmation dialog states and constants

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const { procurementMasterSuppliers } = useSelector(
    (state: RootState) => state.pagination
  );
  const [filterData, setFilterData] = useState({ search: "" });
  const initialFormError = {
    name: "",
    phone: "",
    email: "",
    gst_no: "",
    address: "",
  };

  const [supplierFormError, setSupplierFormError] =
    useState<any>(initialFormError);
  type InitialForDataTypes = {
    name: string | null | number;
    phone: string | null | number;
    email: string | null | number;
    gst_no: string | null | number;
    address: string | null | number;
    credit_limit: any;
    tax_type: any;
    active: boolean;
  };
  const initialFormData: InitialForDataTypes = {
    name: "",
    phone: null,
    email: "",
    gst_no: "",
    address: "",
    credit_limit: "",
    tax_type: 1,
    active: true,
  };

  const [supplierFormData, setSupplierFormData] =
    useState<any>(initialFormData);
  const [pageCount, setPageCount] = useState(null);

  const fieldData = {
    name: {
      label: "Name",
      name: "name",
      value: supplierFormData.name,
      isError: Boolean(supplierFormError.name),
      helperText: supplierFormError.name,
    },
    phone: {
      label: "Phone Number",
      name: "phone",
      value: supplierFormData.phone,
      isError: Boolean(supplierFormError.phone),
      helperText: supplierFormError.phone,
    },
    email: {
      label: "Email",
      name: "email",
      value: supplierFormData.email,
      isError: Boolean(supplierFormError.email),
      helperText: supplierFormError.email,
    },
    gst_no: {
      label: "GST No",
      name: "gst_no",
      value: supplierFormData.gst_no,
      isError: Boolean(supplierFormError.gst_no),
      helperText: supplierFormError.gst_no,
    },

    address: {
      label: "Address",
      name: "address",
      value: supplierFormData.address,
      isError: Boolean(supplierFormError.address),
      helperText: supplierFormError.address,
    },
    credit_limit: {
      label: "Credit Limit",
      name: "credit_limit",
      value: supplierFormData.credit_limit,
      isError: Boolean(supplierFormError.credit_limit),
      helperText: supplierFormError.credit_limit,
    },
    tax_type: {
      label: "Tax Type",
      name: "tax_type",
      value: supplierFormData.tax_type,
      isError: Boolean(supplierFormError.tax_type),
      helperText: supplierFormError.tax_type,
    },
    active: {
      label: "Status",
      name: "active",
      value: supplierFormData.active,
      isError: Boolean(supplierFormError.active),
      helperText: supplierFormError.active,
    },
  };

  const [departmentFieldData, setDepartmentFieldData] =
    useState<any>(fieldData);
  const [supplierToBeDeleted, setSupplierToBeDeleted] = useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);

  const deleteSupplierRow = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setSupplierToBeDeleted(row.id);
  };

  // data table columns and actions
  const deleteProductById = async () => {
    if (supplierToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    await deleteSupplier(supplierToBeDeleted as number)
      .then((res: any) => {
        setSupplierToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        debouncedGetAllAppointment({
          page: Number(procurementMasterSuppliers.page) + 1,
          pageSize: procurementMasterSuppliers.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          search: filterData.search,
        });
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        setDeleteBtnLoader(false);
      })
      .catch((err) => {
        setDeleteBtnLoader(false);
        setSupplierToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        const errorMessage = err?.response?.data?.errors
          ? err?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        console.log(err);
      });
  };
  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIdToBeUpdated(row.id);
            openAddAndEditDialog();
          }}
        >
          <EditIcon />
        </div>

        <div
          style={{ cursor: "pointer" }}
          onClick={() => deleteSupplierRow(row)}
        >
          <DeleteIcon />
        </div>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "phone",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("phone")}
        >
          <Typography variant="h5" fontSize={14}>
            Phone Number
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "phone" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "phone" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.phone || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "Email",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("email")}
        >
          <Typography variant="h5" fontSize={14}>
            Email
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "email" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "email" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.email}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "gst_no",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("gst_no")}
        >
          <Typography variant="h5" fontSize={14}>
            GST No
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "gst_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "gst_no" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.gst_no}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "Address",
      flex: 2,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("address")}
        >
          <Typography variant="h5" fontSize={14}>
            Address
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "address" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "address" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.address}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },

    {
      field: "active",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row.active : null;
        const { buttonText, backgroundColor, textColor } =
          ActiveStatusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  // data table functions

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setSupplierFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setSupplierFormError);
        }
        break;
      }
      case "gst_no": {
        if (gstNoValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            gstNoValidator(value, label),
            setSupplierFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setSupplierFormError);
        }
        break;
      }
      default:
        break;
    }
  };
  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateProductsFieldData = () => {
    setDepartmentFieldData((prev: any) => {
      return Object?.keys(prev)?.map((field: any) => {
        return {
          ...field,
          value: supplierFormData[field.name],
          isError: Boolean(supplierFormError[field.name]),
          helperText: supplierFormError[field.name],
        };
      });
    });
  };

  // form API call functions
  const dispatch = useDispatch();
  const createProduct = async () => {
    if (
      !Boolean(supplierFormData.name) ||
      (supplierFormData?.gst_no?.length &&
        supplierFormData?.gst_no?.length !== 15)
    ) {
      validateForm();
    } else {
      const data = {
        name: supplierFormData.name,
        phone: supplierFormData.phone,
        email: supplierFormData.email,
        gst_no: supplierFormData.gst_no,
        address: supplierFormData.address,
        credit_limit: supplierFormData.credit_limit,
        tax_type: supplierFormData.tax_type,
        active: supplierFormData.active === false ? 0 : 1,
      };
      setIsButtonLoading(true);

      await addSuppliers(data)
        .then((res: any) => {
          closeAddAndEditDialog();
          debouncedGetAllAppointment({
            page: Number(procurementMasterSuppliers.page) + 1,
            pageSize: procurementMasterSuppliers.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            search: filterData.search,
          });
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          setIsButtonLoading(false);
        })
        .catch((error: any) => {
          setIsButtonLoading(false);
          const errorMessage = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.error("An error occurred:", error);
        });
    }
  };
  const updateProduct = async () => {
    if (
      !Boolean(supplierFormData.name) ||
      (supplierFormData?.gst_no?.length &&
        supplierFormData?.gst_no?.length !== 15)
    ) {
      validateForm();
    } else {
      const data = {
        name: supplierFormData?.name,
        phone: supplierFormData?.phone,
        email: supplierFormData?.email,
        gst_no: supplierFormData?.gst_no,
        address: supplierFormData?.address,
        credit_limit: supplierFormData?.credit_limit,
        tax_type: supplierFormData?.tax_type,
        active: supplierFormData?.active === false ? 0 : 1,
      };
      setIsButtonLoading(true);
      await updateSupplierById(idToBeUpdated as number, data)
        .then((res: any) => {
          setIsButtonLoading(false);
          closeAddAndEditDialog();
          debouncedGetAllAppointment({
            page: Number(procurementMasterSuppliers.page) + 1,
            pageSize: procurementMasterSuppliers.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            search: filterData.search,
          });
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        })
        .catch((error: any) => {
          setIsButtonLoading(false);
          const errorMessage = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.error("An error occurred:", error);
        });
    }
  };
  const handleConfirmationDelete = async () => {};

  // form fields onchange functions

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "gst_no" && value.length > 15) {
      return; // Do not update the state if the length exceeds 15 characters
    }
    setSupplierFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setSupplierFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };

  useEffect(() => {
    updateProductsFieldData();
  }, [supplierFormError, supplierFormData]);

  // Add And Edit dialog functions

  const openAddAndEditDialog = async () => {
    setIsAddAndEditDialogOpen(true);
  };

  const closeAddAndEditDialog = async () => {
    setIsButtonLoading(false);
    setIsAddAndEditDialogOpen(false);
    setSupplierFormData(initialFormData);
    setIdToBeUpdated(null);
    setSupplierFormError(initialFormError);
  };

  useEffect(() => {
    if (idToBeUpdated) {
      setIsAddAndEditDialogLoading(true);
      getSupplierListById(idToBeUpdated)
        .then((res: any) => {
          const supplierDetail: any = res.data;
          setSupplierFormData((prevState: InitialForDataTypes) => ({
            ...prevState,
            name: supplierDetail.name,
            phone: supplierDetail.phone,
            email: supplierDetail.email,
            gst_no: supplierDetail.gst_no,
            address: supplierDetail.address,
            credit_limit: supplierDetail.credit_limit,
            tax_type: supplierDetail.tax_type ? supplierDetail.tax_type : 1,
            active: supplierDetail.active === 0 ? false : true,
          }));
          setIsAddAndEditDialogLoading(false);
        })
        .catch((error: any) => {
          setIsAddAndEditDialogLoading(false);
          const errorMessage = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.error("An error occurred:", error);
        });
    }
  }, [idToBeUpdated]);
  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };
  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllSupplierList(data)
        .then((res: any) => {
          setSupplierList(res.data.result);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          const errorMessage = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.error("An error occurred:", error);
        });
    }, 300),
    []
  );

  const handleSearchInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;
      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        return newFilter;
      });
    }
    dispatch(setCurrentPage({ key: "procurementMasterSuppliers", value: 0 }));
  };
  useEffect(() => {
    const data = {
      page: Number(procurementMasterSuppliers.page) + 1,
      pageSize: procurementMasterSuppliers.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      search: filterData.search,
    };
    debouncedGetAllAppointment(data);
  }, [
    procurementMasterSuppliers.page,
    procurementMasterSuppliers.pageSize,
    sortedField.order,
    sortedField.field,
    filterData.search,
  ]);
  // custom component and it's function
  const CustomComponent = () => {
    return (
      <Grid
        sx={{
          width: "auto",
          minWidth: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BrowseButton
          selectedItems={filesToBeUploaded}
          onChange={handleFileChange}
        />
        <SaveButton
          buttonText="Upload"
          sx={{ width: "83px", height: "33px" }}
        />
      </Grid>
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFilesToBeUploaded(Array.from(files));
    }
  };

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={CONFIRMATION_DELETE_DIALOG_TITLE}
        handleClick={deleteProductById}
        onClose={closeConfirmationDialog}
        loading={deleteBtnLoader}
      />
      <CustomFilter
        isSearchEnabled={true}
        editButtonTitle={ADD_BUTTON_TITLE}
        searchName="search"
        searchOnChange={handleSearchInputChange}
        searchValue={filterData.search}
        clearSearch={() => {
          setFilterData({ search: "" });
          dispatch(
            setCurrentPage({ key: "procurementMasterSuppliers", value: 0 })
          );
        }}
        onUpdateButtonClick={openAddAndEditDialog}
        // customComponent={<CustomComponent />}
      />
      <DataTable
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={supplierList}
        pageCount={pageCount}
        loading={loading}
        currentPage={"procurementMasterSuppliers"}
      />
      <DialogWrapper
        onClose={closeAddAndEditDialog}
        open={isAddAndEditDialogOpen}
        handleClick={idToBeUpdated === null ? createProduct : updateProduct}
        title={idToBeUpdated === null ? "Add Supplier" : "Edit Supplier"}
        loading={isButtonLoading}
        maxWidth="sm"
      >
        {isAddAndEditDialogLoading ? (
          <PageLoader />
        ) : (
          <>
            <TextField
              value={fieldData.name.value}
              name={fieldData.name.name}
              onChange={handleInputChange}
              placeholder="Enter Name"
              label={fieldData.name.label}
              helperText={fieldData.name.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.name }))
              }
              sx={{
                width: "100%",
              }}
            />
            <TextField
              value={fieldData.phone.value}
              name={fieldData.phone.name}
              onChange={handleInputChange}
              placeholder="Enter Phone Number"
              label={fieldData.phone.label}
              error={fieldData.phone.isError}
              helperText={fieldData.phone.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.phone }))
              }
              sx={{
                width: "100%",
              }}
            />
            <TextField
              value={fieldData.email.value}
              name={fieldData.email.name}
              onChange={handleInputChange}
              placeholder="Enter Email"
              label={fieldData.email.label}
              error={fieldData.email.isError}
              helperText={fieldData.email.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.email }))
              }
              sx={{
                width: "100%",
              }}
            />
            <TextField
              value={fieldData.gst_no.value}
              name={fieldData.gst_no.name}
              onChange={handleInputChange}
              placeholder="Enter Gst No"
              label={fieldData.gst_no.label}
              helperText={fieldData.gst_no.helperText}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.gst_no }))
              }
              sx={{
                width: "100%",
              }}
            />

            <TextField
              value={fieldData.credit_limit.value}
              name={fieldData.credit_limit.name}
              onChange={handleInputChange}
              placeholder="Enter Credit Limit"
              label={fieldData.credit_limit.label}
              error={fieldData.credit_limit.isError}
              helperText={""}
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.credit_limit }))
              }
              sx={{
                width: "100%",
              }}
            />
            <Radio
              label={fieldData.tax_type.label}
              name={fieldData.tax_type.name}
              value={fieldData.tax_type.value}
              onChange={handleInputChange}
              options={[
                { id: 1, value: "CGST" },
                { id: 2, value: "IGST" },
              ]}
            />

            <Grid
              item
              sm={12}
              sx={{
                marginBottom: "10px",
                marginTop: "10px",
                paddingRight: "12px",
              }}
            >
              <Typography variant="p">Address</Typography>
              <textarea
                style={{
                  width: "98%",
                  height: "154px",
                  borderRadius: "8px",
                  padding: "10px",
                  resize: "none",
                  outline: "none",
                  border: "1px solid #d9d9d9",
                  marginTop: "10px",
                  fontFamily: "inter",
                  fontSize: "14px",
                }}
                name={fieldData.address.name}
                value={fieldData.address.value}
                onChange={handleInputChange}
                placeholder="Enter Address"
                spellCheck="false"
              />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "10px",
              }}
            >
              <Typography>{fieldData.active.label}</Typography>
              <Switch
                name={fieldData.active.name}
                checked={fieldData.active.value}
                onChange={handleSwitchChange}
                value={supplierFormData.active}
                disabled={Boolean(!idToBeUpdated)}
              />
            </Grid>
          </>
        )}
      </DialogWrapper>
    </>
  );
}

export default Suppliers;
