import {
  TableCell,
  TableRow,
  Popper,
  Box,
  List,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Select, TextField } from "../../../../components/basic";
import { AutocompleteWithTable } from "../../../../components/basic";
import { DeleteIcon } from "../../../../assets/icons";
import PermissionUtils from "../../../../utils/PermissionUtils";

type ItemRowProps = {
  index: number;
  columns: any;
  allProducts?: any;
  consultantList?: any;
  row: any;
  error?: any;
  onChange: (event: any, index: number) => void;
  onDelete: (index: number, row: any) => void;
  getItemsOptionsByDepartment?: () => void;
  isDeleteDisabled?: boolean;
  isEdit?: boolean;
  itemRef?: any;
};

const styles = {
  serialNoStyle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "11px",
    textAlign: "center",
    letterSpacing: "0px",
  },
  optionStyle: {
    width: "50%",
    fontSize: "13px",
    fontWeight: "600",
    color: "textPrimary.main",
    textAlign: "left",
    p: "0px 10px",
  },
  selectFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
  textFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "0px 5px",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  amountColumnStyle: {
    "& .MuiInputBase-input": {
      fontFamily: "Inter",
      fontSize: "12px !important",
      fontWeight: "600 !important",
      lineHeight: "11px",
      textAlign: "center",
      letterSpacing: "0px",
      "-webkit-text-fill-color": "unset !important",
      color: "#000",
    },
  },
};
const PopperComponent = (props: any) => {
  const { children, ...rest } = props;

  return (
    <Popper
      {...rest}
      placement="bottom-start"
      className="auto-complete-popper"
      sx={{
        width: "700px",
        p: "10px 0px",
        boxSizing: "border-box",
        "& .MuiPaper-root": {
          width: "700px",
          maxHeight: "250px",
          overflow: "hidden",
          borderRadius: "0px 0px 5px 5px",
          boxSizing: "border-box",
        },
        "& .MuiAutocomplete-listbox": {
          overflowX: "hidden",
          maxHeight: "250px",
          p: 0,
        },
      }}
    >
      <Box
        sx={{
          width: "700px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "var(--table-autocomplete-background)",
          p: "0px 10px 0px 0px",
          height: "45px",
          boxSizing: "border-box",
          borderRadius: "5px 5px 0px 0px",
        }}
      >
        <Typography sx={{ ...styles.optionStyle }}>Name</Typography>
        <Typography sx={{ ...styles.optionStyle, width: "20%" }}>
          Type
        </Typography>
        <Typography sx={{ ...styles.optionStyle, width: "20%" }}>
          Description
        </Typography>
        <Typography sx={{ ...styles.optionStyle, width: "20%" }}>
          Price
        </Typography>
      </Box>
      {children}
    </Popper>
  );
};

const ItemRow = (props: ItemRowProps) => {
  const {
    index,
    columns,
    row,
    allProducts,
    consultantList,
    onChange,
    onDelete,
    getItemsOptionsByDepartment,
    error,
    isDeleteDisabled,
    isEdit,
    itemRef,
  } = props;
  const { can } = PermissionUtils();
  const [focusedColumn, setFocusedColumn] = useState<string>("");

  const handleSelectProduct = (_: any, newValue: string) => {
    onChange({ target: { name: "item_id", value: newValue } }, index);
  };

  useEffect(() => {
    if (
      (typeof row.item_id === "string" || typeof row.item_id === "number") &&
      allProducts?.length > 0
    ) {
      const item = allProducts.find((ele: any) => ele.id === row.item_id);

      onChange({ target: { name: "item_id", value: item } }, index);
    }
  }, [row.item_id, allProducts]);

  return (
    <TableRow>
      <TableCell
        sx={{
          borderBottomColor: "var(--table-border)",
          width: `${columns[0].width}%`,
        }}
      >
        <Typography sx={styles.serialNoStyle}>{index + 1}</Typography>
      </TableCell>
      <TableCell
        sx={{
          "&.MuiTableCell-body:has(.Mui-focused)": {
            border: "#FFFFFF !important",
          },
          width: `${columns[1].width}%`,
          border: !!error?.item_id ? "2px solid #d32f2f !important" : "",
          background: isEdit && row.id ? "#F8F8F8" : "#fff",
        }}
      >
        <AutocompleteWithTable
          placeholder="Select Product"
          name="item_id"
          value={row.item_id || null}
          onChange={handleSelectProduct}
          options={allProducts}
          getOptionLabel={(option: any) => option.name}
          isHideClearIcon
          isDisabled={isEdit && row.id}
          inputRef={itemRef}
          renderOption={(props: any, option: any) => {
            return (
              <List
                {...props}
                key={option.ID}
                className="table-body-lists-div"
                sx={{
                  width: "700px",
                  borderRadius: 0,
                  boxSizing: "border-box",
                  m: "0px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "primary.lighter",
                  },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="700px"
                  className="list-row"
                  sx={{
                    boxSizing: "border-box",
                    m: "0px",
                    p: "0px 10px 0px 0px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      width: "50%",
                      textAlign: "left",
                      p: "0px 10px",
                    }}
                  >
                    {option.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      width: "20%",
                      textAlign: "left",
                      p: "0px 10px",
                    }}
                  >
                    {option.type}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      width: "20%",
                      textAlign: "left",
                      p: "0px 10px",
                    }}
                  >
                    {option.description}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      width: "20%",
                      textAlign: "left",
                      p: "0px 10px",
                    }}
                  >
                    {option.price}
                  </Typography>
                </Box>
              </List>
            );
          }}
          sx={{
            width: "12vw",
            height: "100%",
            // ...(isEdit && row.id ? styles.amountColumnStyle : {}),
            "& .MuiFormControl-root": {
              height: "100%",
            },
            "& .MuiInputBase-root": {
              height: "100%",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
            "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: 0,
              },
            "& .MuiInputBase-root .MuiInputBase-input": {
              padding: 0,
              fontSize: "12px",
              fontWeight: "400",
            },
            // "& .MuiAutocomplete-popupIndicator": {
            //   display: focusedColumn === "item_id" ? "inline-flex" : "none",
            // },
            // "&:hover .MuiAutocomplete-popupIndicator": {
            //   display: "inline-flex",
            // },

            "& fieldset": {
              height: "100%",
            },
            "& input": {
              height: "100%",
            },
          }}
          PopperComponent={PopperComponent}
          onBlur={() => setFocusedColumn("")}
          onFocus={() => {
            setFocusedColumn("item_id");
            if (getItemsOptionsByDepartment && allProducts?.length === 0) {
              getItemsOptionsByDepartment();
            }
          }}
        />
      </TableCell>
      <TableCell
        sx={{
          width: `${columns[2].width}%`,
          background: isEdit && row.id ? "#F8F8F8" : "#fff",
        }}
      >
        <Select
          formControlStyle={{
            width: "100%",
            marginLeft: "5px",
            "& .MuiInputBase-formControl": {
              boxShadow: "unset",
            },
          }}
          value={row.consultant ? Number(row.consultant) : null}
          label=""
          width=""
          options={consultantList}
          disabled={isEdit && row.id}
          sx={{
            ...styles.selectFieldStyle,
            // ...(isEdit && row.id ? styles.amountColumnStyle : {}),
            // "& .MuiSelect-icon": {
            //   display: focusedColumn === "consultant" ? "inline-flex" : "none",
            // },
            // "&:hover .MuiSelect-icon": {
            //   display: "inline-flex",
            // },
            "& input": {
              height: "100%",
            },
          }}
          menuStyle={{
            marginLeft: "-5px",
          }}
          name="consultant"
          onChange={(event: any) => onChange(event, index)}
          onBlur={() => setFocusedColumn("")}
          onFocus={() => setFocusedColumn("consultant")}
        />
      </TableCell>
      <TableCell sx={{ width: `${columns[3].width}%` }}>
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": { height: "100%" },
            "& .MuiTextField-root": { height: "100%" },
            "& .MuiInputBase-root": { height: "100%" },
          }}
        >
          <TextField
            fullWidth
            formControlStyle={{
              width: "100%",
              height: "100%",
              marginLeft: "15px",
            }}
            value={row.desc}
            sx={{
              ...styles.textFieldStyle,
              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            name="desc"
            onChange={(event: any) => onChange(event, index)}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          width: `${columns[4].width}%`,
          border: !!error?.qty ? "2px solid #d32f2f !important" : "",
        }}
      >
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": { height: "100%" },
            "& .MuiTextField-root": { height: "100%" },
            "& .MuiInputBase-root": { height: "100%" },
          }}
        >
          <TextField
            type="number"
            fullWidth
            formControlStyle={{
              width: "100%",
              height: "100%",
              marginLeft: "15px",
            }}
            value={row.qty}
            sx={{
              ...styles.textFieldStyle,
              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            name="qty"
            onChange={(event: any) => onChange(event, index)}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          "&.MuiTableCell-body:has(.Mui-focused)": {
            border: "#FFFFFF !important",
          },
          width: `${columns[5].width}%`,
        }}
      >
        <Box display={"flex"} height="100%">
          <Box
            sx={{
              width: "70%",
              "& .MuiGrid-root": { height: "100%" },
              "& .MuiTextField-root": { height: "100%" },
              "& .MuiInputBase-root": { height: "100%" },
              borderWidth:
                focusedColumn === "discount" || error?.discount
                  ? "2px"
                  : "0px 1px 0px 0px",
              borderStyle: "solid",
              borderColor: error?.discount
                ? "#d32f2f"
                : focusedColumn === "discount"
                ? "#207DFF"
                : "var(--table-border)",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              fullWidth
              formControlStyle={{
                width: "100%",
                height: "100%",
              }}
              type="number"
              value={row.discount}
              sx={{
                ...styles.textFieldStyle,
                "& fieldset": {
                  height: "100%",
                },
                "& input": {
                  height: "100%",
                },
              }}
              name="discount"
              onChange={(event: any) => {
                onChange(
                  {
                    target: {
                      name: event?.target?.name,
                      value: Number(event?.target?.value),
                    },
                  },
                  index
                );
              }}
              onFocus={() => setFocusedColumn("discount")}
              onBlur={() => setFocusedColumn("")}
            />
          </Box>
          <Box
            sx={{
              width: "30%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border:
                focusedColumn === "row_discount_type"
                  ? "2px solid #207DFF"
                  : "unset",
              "& .MuiGrid-root": {
                width: "100%",
              },
            }}
          >
            <Select
              formControlStyle={{
                width: "100%",
                height: "100%",
                "& .MuiInputBase-formControl": {
                  boxShadow: "unset",
                },
                "& .MuiInputBase-root": {
                  height: "100%",
                },
                "& .MuiSelect-select": {
                  width: "100%",
                  padding: "0px !important",
                  "& .MuiBox-root": {
                    width: "100%",
                    padding: "0px",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                },
                "& input": {
                  height: "100%",
                },
                "& .MuiOutlinedInput-root": {
                  paddingLeft: "0px !important",
                },
              }}
              value={row.row_discount_type}
              label=""
              width=""
              options={[
                { id: "₹", name: "₹" },
                { id: "%", name: "%" },
              ]}
              sx={{
                ...styles.selectFieldStyle,
                // "& .MuiSelect-icon": {
                //   display:
                //     focusedColumn === "row_discount_type"
                //       ? "inline-flex"
                //       : "none",
                // },
                // "&:hover .MuiSelect-icon": {
                //   display: "inline-flex",
                // },
                "& .MuiOutlinedInput-root": {
                  height: "46px",
                },
              }}
              name="row_discount_type"
              onChange={(event: any) => onChange(event, index)}
              onBlur={() => setFocusedColumn("")}
              onFocus={() => setFocusedColumn("row_discount_type")}
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={{ width: `${columns[6].width}%` }}>
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": { height: "100%" },
            "& .MuiTextField-root": { height: "100%" },
            "& .MuiInputBase-root": { height: "100%" },
          }}
        >
          <TextField
            fullWidth
            type="number"
            formControlStyle={{
              width: "100%",
              height: "100%",
              marginLeft: "15px",
            }}
            value={row.tax}
            sx={{
              ...styles.textFieldStyle,
              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            name="tax"
            onChange={(event: any) => onChange(event, index)}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          width: `${columns[7].width}%`,
          background: !can("billing_items_amount_edit") ? "#F8F8F8" : "#fff",
        }}
      >
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": { height: "100%" },
            "& .MuiTextField-root": { height: "100%" },
            "& .MuiInputBase-root": { height: "100%" },
          }}
        >
          <TextField
            type="number"
            fullWidth
            formControlStyle={{
              width: "100%",
              height: "100%",
              marginLeft: "15px",
            }}
            value={row.price}
            sx={{
              ...styles.textFieldStyle,
              ...(isEdit ? styles.amountColumnStyle : {}),
              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            name="price"
            disabled={isEdit && !can("billing_items_amount_edit")}
            onChange={(event: any) => onChange(event, index)}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          width: `${columns[8].width}%`,
          background: "#F8F8F8",
        }}
      >
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": { height: "100%" },
            "& .MuiTextField-root": { height: "100%" },
            "& .MuiInputBase-root": { height: "100%" },
          }}
        >
          <TextField
            type="number"
            disabled
            fullWidth
            formControlStyle={{
              width: "100%",
              height: "100%",
              marginLeft: "15px",
            }}
            value={row.amount}
            sx={{
              ...styles.textFieldStyle,
              ...styles.amountColumnStyle,
              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            name="amount"
            onChange={(event: any) => onChange(event, index)}
          />
        </Box>
      </TableCell>
      <TableCell sx={{ width: `${columns[9].width}%` }}>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent={"center"}
          width={"100%"}
        >
          <DeleteIcon
            style={{
              width: "15px",
              height: "15px",
              margin: "0 7px 0 -1px",
              cursor: isDeleteDisabled ? "unset" : "pointer",
              opacity: isDeleteDisabled ? "0.5" : "1",
            }}
            onClick={() => (isDeleteDisabled ? null : onDelete(index, row))}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ItemRow;
