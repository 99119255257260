import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAppConfiguration,
  getCommonVariables,
} from "../../services/authService";

type AppConfigState = {
  isTokenEnable: boolean;
  appConfiguration: any;
  commonVariables: any;
  userDetails: any;
  printPdfUrl: string | null;
};

const initialAppConfigState: AppConfigState = {
  isTokenEnable: false,
  appConfiguration: Object(getAppConfiguration()),
  commonVariables: Object(getCommonVariables()),
  userDetails: null,
  printPdfUrl: null,
};

const appConfigSlice = createSlice({
  name: "appconfig",
  initialState: initialAppConfigState,
  reducers: {
    setIsTokenEnable: (state, action: PayloadAction<boolean>) => {
      state.isTokenEnable = action.payload;
    },
    setCommonVariable: (state, action: PayloadAction<any>) => {
      state.commonVariables = action.payload;
    },
    setAppConfiguration: (state, action: PayloadAction<any>) => {
      state.appConfiguration = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.userDetails = action.payload;
    },
    setPrintPdfUrl: (state, { payload }: PayloadAction<string | null>) => {
      state.printPdfUrl = payload;
    },
  },
});

export const {
  setIsTokenEnable,
  setCommonVariable,
  setAppConfiguration,
  setUserDetails,
  setPrintPdfUrl,
} = appConfigSlice.actions;

export default appConfigSlice.reducer;
