import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type patientPaymentConfig = {
  paymentInfo: {};
  paymentDueInfo: [];
};

const initialAppConfigState: patientPaymentConfig = {
  paymentInfo: {},
  paymentDueInfo: [],
};
const paymentConfigSlice = createSlice({
  name: "patientpayment",
  initialState: initialAppConfigState,
  reducers: {
    setPaymentInfo: (state, action: PayloadAction<boolean>) => {
      state.paymentInfo = action.payload;
    },
    setPaymentDueInfo: (state, action: PayloadAction<[]>) => {
      state.paymentDueInfo = action.payload;
    },
  },
});

export const { setPaymentInfo, setPaymentDueInfo } = paymentConfigSlice.actions;

export default paymentConfigSlice.reducer;
