import { Grid } from "@mui/material";
import BillItems from "./BillItems";

const BillsView = () => {
  return (
    <Grid
      sx={{
        height: "auto",
      }}
    >
      <BillItems />
    </Grid>
  );
};

export default BillsView;
