import React, { useEffect, useState } from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DeleteIcon } from "../../../../assets/icons";
import { PageLoader, SaveButton, Sort } from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import {
  monthList,
  stockAdjustmentConst,
} from "../../../../constants/displayText";
import Info from "./Info";
import { useLocation, useParams } from "react-router-dom";
import {
  deleteLabStockAdjustmentById,
  getLabStockAdjustById,
} from "../../../../services/gynecologyService";
import {
  formatDateType,
  formatTwelveHoursTime,
} from "../../../../utils/DateTimeFormatUtils";
import { useDispatch } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";

const BillProducts = () => {
  const {
    supplier,
    batchNo,
    inVoiceNo,
    adjustMentDate,
    expiryDate,
    decQty,
    incQty,
    reason,
    actions,
  } = stockAdjustmentConst;

  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);
  const { dept_id } = useParams();
  const location = useLocation();
  const { product_id } = location.state;
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [info, setInfo] = React.useState<{
    product_name: string | number | null;
    pack_type: string | number | null;
    department: string | number | null;
  }>({
    product_name: "",
    pack_type: "",
    department: "",
  });

  const getStockAdjust = async () => {
    setIsDataTableLoading(true);
    await getLabStockAdjustById(product_id as string, dept_id as string)
      .then((res: any) => {
        const purchaseBill = res.data.bill;
        const department = res.data.department;
        const product = res.data.product;

        setInfo((prevState: any) => ({
          ...prevState,
          product_name: product?.name,
          pack_type: product?.pack_type,
          department: department?.dept_name,
        }));
        const data = purchaseBill.map((res: any) => ({
          id: res.id,
          supplier: res.supplier_name,
          invoice_no: res.invoice_no,
          expiry_date: res.expires_at,
          batch_no: res.batch_no,
          increased_qty: res.increase_qty,
          decreased_qty: res.decrease_qty || 0,
          adjustment_date: res.created_at,
          reason: res.reason,
        }));
        setBillProducts((prevState: any) => [...prevState, ...data]);
        setIsDataTableLoading(false);
      })
      .catch((err) => {
        setIsDataTableLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (product_id && dept_id) {
      getStockAdjust();
    }
  }, [product_id, dept_id]);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });

  type BillProductTypes = {
    id?: string | number | null;
    supplier?: string | number | null;
    invoice_no?: string | number | null;
    batch_no?: string | number | null;
    expiry_date?: string | number | null;
    increased_qty?: string | number | null;
    decreased_qty?: string | number | null;
    adjustment_date?: string | number | null;
    reason?: string | number | null;
  };
  const [billProducts, setBillProducts] = useState<BillProductTypes[] | []>([]);

  const columns: GridColDef[] = [
    {
      field: "supplier",
      flex: 1.5,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={() => handleSorting("supplier")}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {supplier}
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "supplier" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "supplier" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.supplier}</Typography>
      ),
      sortable: false,
    },
    {
      field: "invoice_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={() => handleSorting("invoice_no")}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {inVoiceNo}
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "invoice_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "invoice_no" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.invoice_no}</Typography>
      ),
      sortable: false,
    },
    {
      field: "batch_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={() => handleSorting("batch_no")}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {batchNo}
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "batch_no" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "batch_no" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.batch_no}</Typography>
      ),
      sortable: false,
    },
    {
      field: "expiry_date",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={() => handleSorting("expiry_date")}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {expiryDate}
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "expiry_date" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "expiry_date" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => {
        const list = formatDateType(row?.expiry_date, "MM");
        return (
          <Typography variant="h6">
            {monthList[list as string]}/
            {formatDateType(row?.expiry_date, "YYYY")}
          </Typography>
        );
      },

      sortable: false,
    },
    {
      field: "increased_qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={() => handleSorting("increased_qty")}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {incQty}
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "increased_qty" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "increased_qty" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row?.increased_qty === 0 ? "-" : row?.increased_qty}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "decreased_qty",
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={() => handleSorting("decreased_qty")}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {decQty}
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "decreased_qty" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "decreased_qty" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.decreased_qty}</Typography>
      ),
      sortable: false,
    },
    {
      field: "adjustment_date",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={() => handleSorting("adjustment_date")}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {adjustMentDate}
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "adjustment_date" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "adjustment_date" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {formatTwelveHoursTime(row?.adjustment_date)}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "reason",
      flex: 1.2,

      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          onClick={() => handleSorting("reason")}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {reason}
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "adjustment_date" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "adjustment_date" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.reason}</Typography>
      ),
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {actions}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Grid
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton
            aria-label=""
            onClick={() => deleteStockAdjustment(row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      ),
      sortable: false,
    },
  ];
  const dispatch = useDispatch();
  const deleteStockAdjustment = async (id: number) => {
    deleteLabStockAdjustmentById(id)
      .then((res: any) => {
        getStockAdjust();
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
      })
      .catch((err) => {
        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
        console.log(err);
      });
  };
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  return isDataTableLoading ? (
    <PageLoader />
  ) : (
    <Grid>
      <Info info={info} />
      <Grid sx={{ m: "20px 0px" }}>
        <DataTable
          columns={columns}
          rows={billProducts}
          customizedTable={true}
          pageCount={billProducts?.length}
          currentPage={"procurementStockAdjustmentView"}
        />
      </Grid>
      <Grid
        sx={{
          width: "40vw",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          gap: 2,
          m: "20px 0px",
        }}
      >
        <SaveButton buttonText="Back" sx={{ width: "100px", height: "42px" }} />
      </Grid>
    </Grid>
  );
};

export default BillProducts;
