import ApiUtil from "../utils/ApiUtils";
import {
  laboratoryApiEndPoints,
  pharmacyApiEndPoints,
  procurementsApiEndPoints,
} from "../constants/apiEndPoints";
type AntenatalTypes = {
  page: number | string;
  limit: number | string;
  column: number | string;
  order: number | string;
  patient_id: number | string;
  trimester_week: number | string;
  last_visit_date: number | string;
  search: string;
};
const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);

  let queryString = "?";
  keys.map((key) => {
    if (data[key] && !Array.isArray(data[key])) {
      queryString += `${key}=${data[key]}&`;
    }
    if (Array.isArray(data[key])) {
      data[key].map(
        (ele: any, index: number) => (queryString += `${key}[${index}]=${ele}&`)
      );
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};
const getAllDepartments = async (): Promise<{
  data: { active: number; dept_name: string; id: number; type: string }[];
}> => {
  try {
    return (await ApiUtil.getWithToken(
      procurementsApiEndPoints.getAllDepartments
    )) as {
      data: { active: 1; dept_name: string; id: 1; type: string }[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
    throw error;
  }
};
type DepartmentType = {
  type?: string;
  active?: number;
};
const getAllMasterDepartments = async (
  data?: DepartmentType
): Promise<{
  data: { active: number; dept_name: string; id: number; type: string }[];
}> => {
  try {
    const apiUrl = setQueryParams(
      data,
      procurementsApiEndPoints.getAllMasterDepartments
    );
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: { active: 1; dept_name: string; id: 1; type: string }[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
    throw error;
  }
};
const getAllLabMasterDepartments = async (
  data?: DepartmentType
): Promise<{
  data: { active: number; dept_name: string; id: number; type: string }[];
}> => {
  try {
    const apiUrl = setQueryParams(
      data,
      laboratoryApiEndPoints.getAllMasterDepartments
    );
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: { active: 1; dept_name: string; id: 1; type: string }[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
    throw error;
  }
};
const getAllSuppliers = async (
  params: any
): Promise<{
  data: {
    result: {
      active: number;
      address: string | null;
      email: null | string;
      gst_no: string;
      id: number;
      name: string;
      phone: string | null;
    };
  }[];
}> => {
  try {
    const apiUrl = setQueryParams(
      { ...params, active: 1 },
      procurementsApiEndPoints.getAllSuppliers
    );
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: {
        result: {
          active: number;
          address: string | null;
          email: null | string;
          gst_no: string;
          id: number;
          name: string;
          phone: string | null;
        };
      }[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the Suppliers:", error);
    throw error;
  }
};
const departmentProducts = async (
  data: any
): Promise<{
  data: {
    active: number;
    address: string | null;
    email: null | string;
    gst_no: string;
    id: number;
    name: string;
    phone: string | null;
  }[];
}> => {
  try {
    const apiUrl = setQueryParams(
      data,
      procurementsApiEndPoints.getAllDepartmentProducts
    );
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: {
        active: number;
        address: string | null;
        email: null | string;
        gst_no: string;
        id: number;
        name: string;
        phone: string | null;
      }[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the Suppliers:", error);
    throw error;
  }
};
const getAllPatientsList = async (params: {
  page: number | string | null;
  per_page: number;
  search?: string;
}) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getAllPatientsName
  );

  return await ApiUtil.getWithToken(apiUrl);
};
const getTestAllPatientsList = async (params: {
  page?: number | string | null;
  per_page?: number | string | null;
  search?: string;
}) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getAllPatientsName
  );

  return await ApiUtil.getWithToken(
    // `${procurementsApiEndPoints.getAllPatientsName}?per_page=10&page=${page}`
    apiUrl
  );
};
const getTestAllDoctorsList = async () => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllDoctorsName}`
  );
};
const getPatientById = async (id: number | string | null) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getPatientDetailsById}/${id}`
  );
};
const getAllCommons = async () => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllCommonVariables}`
  );
};
const getAllSymptoms = async () => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllSymptoms}`
  );
};

const addAntenatal = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.createAntenatal}`,
    data
  );
};
const addFollowUp = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.addFollowUp}`,
    data
  );
};
const editAntenatal = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.createAntenatal}/${id}`,
    data
  );
};
const updateFollowUp = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.addFollowUp}/${id}`,
    data
  );
};
const getAllSuggestions = async (trimesterWeek: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllSuggestions}?trimester_week=${trimesterWeek}`
  );
};
const getAllFindings = async (trimesterWeek: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllFindings}?trimester_week=${trimesterWeek}`
  );
};
const getAllScans = async (trimesterWeek: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllScans}?trimester_week=${trimesterWeek}`
  );
};
const getAllTests = async (trimesterWeek: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllTests}?trimester_week=${trimesterWeek}`
  );
};
const getAllFollowupById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllFollowupById}=${id}`
  );
};
const getFollowupById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getFollowupById}/${id}`
  );
};

const getAllAntenatal = async (params: AntenatalTypes) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getAllAntenatalList
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getPrevHistoryById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllPrevHistory}=${id}`
  );
};
const getPatientVitals = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getPatientVitals}=${id}`
  );
};
const getAntenatalById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllAntenatalList}/${id}`
  );
};
type VitalsHistoryTypes = {
  antenatal_card_id: number | string;
  from_date?: number | string;
  vital_ids?: number[] | string[] | [];
};
const getPatientVitalsHistory = async (params: VitalsHistoryTypes) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getPatientVitalsHistory
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const deleteSuggestionsById = async (
  followupsId: number | string,
  suggestionId: number | string
) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsApiEndPoints.deleteSuggestion}/${followupsId}/suggestions/${suggestionId}`
  );
};
const deleteAntenatalById = async (antenatalId: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsApiEndPoints.deleteAntenatal}/${antenatalId}`
  );
};
const getPatientScansById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllPatientScans}=${id}`
  );
};
const getPatientTestsById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getAllPatientTests}=${id}`
  );
};
type PurchaseAdvanceTypes = {
  supplier_id: string | number | null;
  cash_paid: string | number | null;
  created_date: string | number | null;
  bill_payment_type: string | number | null;
  urn_number: string | number | null;
};
const createPurchaseAdvance = async (data: PurchaseAdvanceTypes) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.addPurchaseAdvancePayment}`,
    data
  );
};
type PurchaseBillType = {
  supplier_id?: number | string | null;
  bill_id?: number | string | null;
};
const getPurchaseBillById = async (params: PurchaseBillType) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getPurchasePaymentBill
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getPurchaseBillDetails = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getPurchasePayment}/${id}`
  );
};
const cancelPurchaseBill = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.getPurchasePayment}/${id}/cancel`
  );
};
const deletePurchaseBill = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsApiEndPoints.getPurchasePayment}/${id}`
  );
};
type PurchasePaymentTypes = {
  supplier_id?: string | number | null;
  cash_paid?: string | number | null;
  created_date?: string | number | null;
  bill_payment_type?: string | number | null;
  urn_number?: string | number | null;
  payments?: [
    {
      bill_id?: string | number | null;
      paid_amount?: string | number | null;
      remarks?: string | number | null;
    }
  ];
  used_amount?: string | number | null;
  excess_amount?: string | number | null;
  refunded_excess?: string | number | null;
  net_excess?: string | number | null;
};
const createPurchasePayment = async (data: PurchasePaymentTypes) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.getPurchasePayment}`,
    data
  );
};
const updatePurchasePayment = async (
  id: number | string,
  data: PurchasePaymentTypes
) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.getPurchasePayment}/${id}`,
    data
  );
};
const getPurchasePayment = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getPurchasePayment}/${id}`
  );
};
type PurchaseReturnProductType = {
  supplier_id: number | string | null;
  dept_id?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getPurchaseReturnProduct = async (params: PurchaseReturnProductType) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getPurchaseReturnProduct
  );
  return await ApiUtil.getWithToken(apiUrl);
};
type PurchaseProductType = {
  supplier_id?: string | number | null;
  dept_id?: string | number | null;
  to_dept?: string | number | null;
  invoice_value?: string | number | null;
  invoice_date?: string | number | null;
  ref_no?: string | number | null;
  ref_no_modified?: string | number | null;
  product?: [
    {
      product_id?: string | number | null;
      pack_type?: string | number | null;
      return_qty?: string | number | null;
      free_return_qty?: string | number | null;
      discount?: string | number | null;
      batch_no?: string | number | null;
      expires_at?: string | number | null;
      tax_id?: string | number | null;
      tax_type?: string | number | null;
      p_rate?: string | number | null;
      amount?: string | number | null;
    }
  ];
  bill_amt?: string | number | null;
  total_amt?: string | number | null;
};
const addPurchaseReturnProduct = async (data: PurchaseProductType) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.addPurchaseReturnProduct}`,
    data
  );
};
const getDepartmentSequence = async (department?: string | number) => {
  try {
    return await ApiUtil.getWithToken(
      `${procurementsApiEndPoints.sequenceUrl}/${department}`
    );
  } catch (error) {
    console.log("Error while getting the item name list", error);
    throw error;
  }
};
const updatePurchaseReturnProduct = async (
  id: number | string,
  data: PurchaseProductType
) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.addPurchaseReturnProduct}/${id}`,
    data
  );
};
const getPurchaseReturnById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.addPurchaseReturnProduct}/${id}`
  );
};
const getLatestPatientOpById = async (params: {
  patient_id?: string | number;
  search: string | number;
}) => {
  const apiUrl = setQueryParams(params, pharmacyApiEndPoints.getLatestOp);
  return await ApiUtil.getWithToken(apiUrl);
};
const getLatestPatientIpById = async (params: {
  patient_id?: string | number;
  search: string | number;
}) => {
  const apiUrl = setQueryParams(params, pharmacyApiEndPoints.getLatestIP);

  return await ApiUtil.getWithToken(apiUrl);
};
const getCommonAppConfigurations = async () => {
  try {
    return await ApiUtil.getWithToken(
      procurementsApiEndPoints.commonAppConfiguration
    );
  } catch (error) {
    console.log("Error occurs while getting the  app configuration:", error);
    throw error;
  }
};

type GoodsIssueProductType = {
  department?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getGoodsIssueProduct = async (params: GoodsIssueProductType) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getGoodsIssueProduct
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const deleteGoodsIssueById = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsApiEndPoints.goodsIssueUrl}/${id}`
  );
};
const getGoodsIssueProductById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getGoodsIssueById}/${id}`
  );
};
const getGoodsIssueViewProductById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.getGoodsIssueById}/${id}/view`
  );
};
const getGoodsIssueReturnById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.goodsIssueReturnUrl}/${id}`
  );
};
const updateGoodsIssueReturnById = async (id: string | number, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.goodsIssueReturnUrl}/${id}`,
    data
  );
};
const createGoodsIssue = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.goodsIssueUrl}`,
    data
  );
};
const createStockTransfer = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.stockTransferUrl}`,
    data
  );
};
const createTempGoodsIssue = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.goodsIssueTempUrl}`,
    data
  );
};
const getTempGoodsIssueProductById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.goodsIssueTempUrl}/${id}`
  );
};
const deleteTempGoodsIssueById = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsApiEndPoints.goodsIssueTempUrl}/${id}`
  );
};
const updateTempGoodsIssueReject = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.goodsIssueTempUrl}/${id}/reject`
  );
};

const updateTempGoodsIssueApproval = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.goodsIssueTempUrl}/${id}/approval`
  );
};

const updateGoodsIssueById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.goodsIssueUrl}/${id}`,
    data
  );
};
const updateStockTransferById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.stockTransferUrl}/${id}`,
    data
  );
};
const cancelGoodsIssue = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.goodsIssueUrl}/${id}/cancel`
  );
};
const cancelStockTransfer = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.goodsIssueUrl}/${id}/cancel`
  );
};
const getStockAdjustById = async (
  product_id: string | number,
  dept_id: string | number
) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.stockAdjustmentUrl}/${product_id}/show/${dept_id}`
  );
};
type StockAdjustProductType = {
  department?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getStockAdjustProduct = async (params: StockAdjustProductType) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.stockAdjustmentById
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const deleteStockAdjustmentById = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsApiEndPoints.stockAdjustmentUrl}/${id}`
  );
};
const createStockAdjustment = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.stockAdjustmentUrl}`,
    data
  );
};
type PharmacyType = {
  department?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getPharmacyProducts = async (params: PharmacyType) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getPharmacyProduct
  );
  return await ApiUtil.getWithToken(apiUrl);
};
type GoodsReturnType = {
  to_dept?: number | string | null;
  from_dept?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getGoodsReturnProducts = async (params: GoodsReturnType) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getGoodsIssueReturnProduct
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const createGoodsIssueReturn = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.goodsIssueReturnUrl}`,
    data
  );
};
type LabPurchaseReturnProductType = {
  supplier_id: number | string | null;
  dept_id?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getLabPurchaseReturnProduct = async (
  params: LabPurchaseReturnProductType
) => {
  const apiUrl = setQueryParams(
    params,
    laboratoryApiEndPoints.getPurchaseReturnProduct
  );
  return await ApiUtil.getWithToken(apiUrl);
};
type LabPurchaseProductType = {
  supplier_id?: string | number | null;
  dept_id?: string | number | null;
  to_dept?: string | number | null;
  invoice_value?: string | number | null;
  invoice_date?: string | number | null;
  ref_no?: string | number | null;
  ref_no_modified?: string | number | null;
  product?: [
    {
      product_id?: string | number | null;
      pack_type?: string | number | null;
      return_qty?: string | number | null;
      free_return_qty?: string | number | null;
      discount?: string | number | null;
      batch_no?: string | number | null;
      expires_at?: string | number | null;
      tax_id?: string | number | null;
      tax_type?: string | number | null;
      p_rate?: string | number | null;
      amount?: string | number | null;
    }
  ];
  bill_amt?: string | number | null;
  total_amt?: string | number | null;
};
const addLabPurchaseReturnProduct = async (data: LabPurchaseProductType) => {
  return await ApiUtil.postWithToken(
    `${laboratoryApiEndPoints.labPurchaseReturn}`,
    data
  );
};
const updateLabPurchaseReturnProduct = async (
  id: number | string,
  data: PurchaseProductType
) => {
  return await ApiUtil.putWithToken(
    `${laboratoryApiEndPoints.labPurchaseReturn}/${id}`,
    data
  );
};
const getAllLabSuppliers = async (
  params: any
): Promise<{
  data: {
    result: {
      active: number;
      address: string | null;
      email: null | string;
      gst_no: string;
      id: number;
      name: string;
      phone: string | null;
    };
  }[];
}> => {
  try {
    const apiUrl = setQueryParams(
      params,
      laboratoryApiEndPoints.getAllLabSuppliers
    );
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: {
        result: {
          active: number;
          address: string | null;
          email: null | string;
          gst_no: string;
          id: number;
          name: string;
          phone: string | null;
        };
      }[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the Suppliers:", error);
    throw error;
  }
};
const getLabPurchaseReturnById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${laboratoryApiEndPoints.labPurchaseReturn}/${id}`
  );
};

type LabGoodsIssueProductType = {
  department?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getLabGoodsIssueProduct = async (params: LabGoodsIssueProductType) => {
  const apiUrl = setQueryParams(
    params,
    laboratoryApiEndPoints.getGoodsIssueProduct
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const deleteLabGoodsIssueById = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${laboratoryApiEndPoints.goodsIssueUrl}/${id}`
  );
};
const getLabGoodsIssueProductById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${laboratoryApiEndPoints.getGoodsIssueById}/${id}`
  );
};
const createLabGoodsIssue = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${laboratoryApiEndPoints.goodsIssueUrl}`,
    data
  );
};
const updateLabGoodsIssueById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${laboratoryApiEndPoints.goodsIssueUrl}/${id}`,
    data
  );
};
const cancelLabGoodsIssue = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${laboratoryApiEndPoints.goodsIssueUrl}/${id}/cancel`
  );
};

type LabGoodsReturnType = {
  to_dept?: number | string | null;
  from_dept?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getLabGoodsReturnProducts = async (params: LabGoodsReturnType) => {
  const apiUrl = setQueryParams(
    params,
    laboratoryApiEndPoints.getGoodsIssueReturnProduct
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const createLabGoodsIssueReturn = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${laboratoryApiEndPoints.goodsIssueReturnUrl}`,
    data
  );
};
type LabStockAdjustProductType = {
  department?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getLabStockAdjustProduct = async (params: LabStockAdjustProductType) => {
  const apiUrl = setQueryParams(
    params,
    laboratoryApiEndPoints.stockAdjustmentById
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const deleteLabStockAdjustmentById = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${laboratoryApiEndPoints.stockAdjustmentUrl}/${id}`
  );
};
const createLabStockAdjustment = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${laboratoryApiEndPoints.stockAdjustmentUrl}`,
    data
  );
};
const getLabStockAdjustById = async (
  product_id: string | number,
  dept_id: string | number
) => {
  return await ApiUtil.getWithToken(
    `${laboratoryApiEndPoints.stockAdjustmentUrl}/${product_id}/show/${dept_id}`
  );
};
const deleteLabPurchaseBill = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${laboratoryApiEndPoints.getPurchasePayment}/${id}`
  );
};
type LabPurchaseAdvanceTypes = {
  supplier_id: string | number | null;
  cash_paid: string | number | null;
  created_date: string | number | null;
  bill_payment_type: string | number | null;
  urn_number: string | number | null;
};
const createLabPurchaseAdvance = async (data: LabPurchaseAdvanceTypes) => {
  return await ApiUtil.postWithToken(
    `${laboratoryApiEndPoints.addPurchasePayment}`,
    data
  );
};
const updateLabPurchaseAdvance = async (
  id: number,
  data: LabPurchaseAdvanceTypes
) => {
  return await ApiUtil.postWithToken(
    `${laboratoryApiEndPoints.addPurchasePayment}`,
    data
  );
};
type LabPurchasePaymentTypes = {
  supplier_id?: string | number | null;
  cash_paid?: string | number | null;
  created_date?: string | number | null;
  bill_payment_type?: string | number | null;
  urn_number?: string | number | null;
  payments?: [
    {
      bill_id?: string | number | null;
      paid_amount?: string | number | null;
      remarks?: string | number | null;
    }
  ];
  used_amount?: string | number | null;
  excess_amount?: string | number | null;
  refunded_excess?: string | number | null;
  net_excess?: string | number | null;
};
const createLabPurchasePayment = async (data: LabPurchasePaymentTypes) => {
  return await ApiUtil.postWithToken(
    `${laboratoryApiEndPoints.getPurchasePayment}`,
    data
  );
};
type LabPurchaseBillType = {
  supplier_id?: number | string | null;
  bill_id?: number | string | null;
};
const getLabPurchaseBillById = async (params: LabPurchaseBillType) => {
  const apiUrl = setQueryParams(
    params,
    laboratoryApiEndPoints.getPurchasePaymentBill
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getLabPurchaseBillDetails = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${laboratoryApiEndPoints.getPurchasePayment}/${id}`
  );
};
const cancelLabPurchaseBill = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${laboratoryApiEndPoints.getPurchasePayment}/${id}/cancel`
  );
};
const updateLabPurchasePayment = async (
  id: number | string,
  data: LabPurchaseBillType
) => {
  return await ApiUtil.putWithToken(
    `${laboratoryApiEndPoints.getPurchasePayment}/${id}`,
    data
  );
};
type CustomerType = {
  type?: string;
  active?: number;
};
const getAllMasterCustomer = async (
  data?: CustomerType
): Promise<{
  data: { active: number; dept_name: string; id: number; type: string }[];
}> => {
  try {
    const apiUrl = setQueryParams(
      data,
      procurementsApiEndPoints.getAllCustomerList
    );
    return (await ApiUtil.getWithToken(apiUrl)) as {
      data: { active: number; dept_name: string; id: number; type: string }[];
    };
  } catch (error) {
    console.log("Error Occurs while getting the Customer:", error);
    throw error;
  }
};
type WholeSalesProductType = {
  dept_id?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
};
const getWholeSalesProduct = async (params: WholeSalesProductType) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getWholeSalesProduct
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getWholeSalesById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.wholeSalesUrl}/${id}`
  );
};

type WholeSaleReturnProductType = {
  customer_id?: number | string | null;
  dept_id?: number | string | null;
  selected_batches?: number[] | string[] | [];
  search?: number | string | null;
};
const getWholeSalesReturnProduct = async (
  params: WholeSaleReturnProductType
) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.getWholeSalesReturnProduct
  );
  return await ApiUtil.getWithToken(apiUrl);
};
type WholeSalesPurchaseProductType = {
  dept_id?: string | number | null;
  customer_id?: string | number | null;
  date_created?: string | number | null;
  invoice_no?: string | number | null;
  invoice_no_modified?: string | number | null;
  product: [
    {
      product_id?: string | number | null;
      ref_bill?: string | number | null;
      ref_batch_id?: string | number | null;
      supplier_id?: string | number | null;
      issue_batch_id?: string | number | null;
      pack_type?: string | number | null;
      return_qty?: string | number | null;
      free_return_qty?: string | number | null;
      discount?: string | number | null;
      batch_no?: string | number | null;
      expires_at?: string | number | null;
      tax_id?: string | number | null;
      tax_type?: string | number | null;
      p_rate?: string | number | null;
      mrp?: string | number | null;
      rate?: string | number | null;
      amount?: string | number | null;
    }
  ];
  bill_amt?: string | number | null;
  net_total_amt?: string | number | null;
};
const addWholePurchaseReturnProduct = async (
  data: WholeSalesPurchaseProductType
) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.WholesSalesReturn}`,
    data
  );
};
const updateWholePurchaseReturnProduct = async (
  id: number | string,
  data: WholeSalesPurchaseProductType
) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.WholesSalesReturn}/${id}`,
    data
  );
};
const getWholeSalesReturnById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.WholesSalesReturn}/${id}`
  );
};
const getPayeeList = async () => {
  try {
    return await ApiUtil.getWithToken(pharmacyApiEndPoints.payeeUrl);
  } catch (error) {
    console.log("Error while getting the payee list", error);
    throw error;
  }
};
const getReferralDoctorsList = async () => {
  try {
    return await ApiUtil.getWithToken(pharmacyApiEndPoints.referralDoctorUrl);
  } catch (error) {
    console.log("Error while getting the referral doctors list", error);
    throw error;
  }
};
const getBillTermsOptions = async () => {
  try {
    const result: any = await ApiUtil.getWithToken(
      pharmacyApiEndPoints.getCommonVariablesUrl
    );

    return result.data.bill_payment_type;
  } catch (error) {
    console.log("Error while getting the patient's bill list", error);
    throw error;
  }
};
const addPayee = async (value: { name: string; creditLimit: string }) => {
  try {
    return await ApiUtil.postWithToken(pharmacyApiEndPoints.payeeUrl, value);
  } catch (error) {
    console.log("Error while adding the payee", error);
    throw error;
  }
};
const addReferralDoctor = async (value: {
  name: string;
  email: string;
  mobile: string | number;
  address: string;
}) => {
  try {
    return await ApiUtil.postWithToken(pharmacyApiEndPoints.referralDoctorUrl, {
      ...value,
      active: 1,
    });
  } catch (error) {
    console.log("Error while adding the referral doctors", error);
    throw error;
  }
};
const getExcessAmountByPatientId = async (params: {
  patient_id: number | string | any;
  payee_id: number | string | any;
}) => {
  try {
    const apiUrl = setQueryParams(
      params,
      pharmacyApiEndPoints.advanceAndExcessAmountUrl
    );
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error while getting the excess amount by patient id", error);
    throw error;
  }
};
const getConsultantsList = async () => {
  try {
    return ApiUtil.getWithToken(pharmacyApiEndPoints.getConsultantsListUrl);
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
  }
};
const getCommonVariables = async () => {
  try {
    return await ApiUtil.getWithToken(
      pharmacyApiEndPoints.getCommonVariablesUrl
    );
  } catch (error) {
    console.log("Error while getting the common variables", error);
    throw error;
  }
};
const getItemsByDepartment = async (params: {
  department?: string | number;
  selected_batches?: number[] | string[] | [];
  search?: string | number;
}) => {
  try {
    const apiUrl = setQueryParams(
      params,
      pharmacyApiEndPoints.getPharmacyProductsList
    );
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error while getting the item name list", error);
    throw error;
  }
};
type PharmacyBillTypes = {
  patient_id?: null | number | string;
  payee?: null | number | string;
  invoice_no?: null | number | string;
  invoice_no_modified?: null | number | string;
  bill_payment_type?: null | number | string;
  ip_admission_id?: null | number | string;
  op_id?: null | number | string;
  rdType?: null | number | string;
  department?: null | number | string;
  dept_id?: null | number | string;
  doctor_id?: null | number | string;
  refdoctor?: null | number | string;
  date_created?: null | number | string;
  bill_category?: null | number | string;
  product?: [
    {
      product_id?: null | number | string;
      pack_type?: null | number | string;
      batch_id?: null | number | string;
      qty?: null | number | string;
      mrp?: null | number | string;
      discount?: null | number | string;
      tax_type?: null | number | string;
      tax_id?: null | number | string;
      amount?: null | number | string;
    }
  ];
  disc_val?: null | number | string;
  orig_goods_val?: null | number | string;
  disc_goods_val?: null | number | string;
  tax_split_info?: null | number | string;
  gst_amt?: null | number | string;
  bill_amt?: null | number | string;
  discount_val?: null | number | string;
  discount_type?: null | number | string;
  discount_amt?: null | number | string;
  adj_amt?: null | number | string;
  net_total_amt?: null | number | string;
  creditlimit_amt?: null | number | string;
  print?: null | number | string;
  payments?: [
    {
      type?: null | number | string;
      cash_paid?: null | number | string;
      urn?: null | number | string;
    }
  ];
  cash_received?: null | number | string;
  pay_paid_amt?: null | number | string;
  balance?: null | number | string;
  excess_amount?: null | number | string;
  refunded_excess?: null | number | string;
  net_excess?: null | number | string;
};
const addPharmacyBill = async (data: PharmacyBillTypes) => {
  return await ApiUtil.postWithToken(pharmacyApiEndPoints.pharmacyUrl, data);
};
const updatePharmacyBill = async (
  id: number | string,
  data: PharmacyBillTypes
) => {
  return await ApiUtil.putWithToken(
    `${pharmacyApiEndPoints.pharmacyUrl}/${id}`,
    data
  );
};
const getPharmacyBillById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${pharmacyApiEndPoints.pharmacyUrl}/${id}`
  );
};
const deletePharmacyBill = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${pharmacyApiEndPoints.pharmacyUrl}/${id}`
  );
};
const getAllPharmacyBillDraft = async (params: {
  patient?: number | string | null;
  seq_no?: number | string | null;
  from_date?: number | string | null;
  to_date?: number | string | null;
  search?: number | string | null;
  column?: number | string | null;
  order?: number | string | null;
  page?: number | string | null;
  limit?: number | string | null;
}) => {
  const apiUrl = setQueryParams(params, pharmacyApiEndPoints.billDraftList);
  return await ApiUtil.getWithToken(apiUrl);
};
const getPharmacyBillDraftById = async (params: {
  draft_type?: number | string | null;
  id?: number | string | null;
}) => {
  return await ApiUtil.getWithToken(
    `${pharmacyApiEndPoints.billDraftList}/${params.draft_type}/${params.id}`
  );
};
type PharmacyReturnType = {
  patient_id?: number | string | null;
  department?: number | string | null;
  selected_batches: number[] | string[] | [];
  search?: number | string | null;
  type?: number | string | null;
  days?: number | string | null;
};
const getPharmacyReturnProducts = async (params: PharmacyReturnType) => {
  const apiUrl = setQueryParams(
    params,
    pharmacyApiEndPoints.pharmacyReturnProducts
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getPharmacyReturnBillById = async (id: string | number) => {
  return await ApiUtil.getWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnUrl}/${id}`
  );
};
const cancelPharmacyReturnBill = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnUrl}/${id}/cancel`
  );
};
const cancelPharmacyBill = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${pharmacyApiEndPoints.pharmacyUrl}/${id}/cancel`
  );
};
const addSalesReturn = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnUrl}`,
    data
  );
};
const updateSalesReturn = async (id: number, data: any) => {
  return await ApiUtil.putWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnUrl}/${id}`,
    data
  );
};
const cancelPurchaseBillPayment = async (id: number | string) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.getPurchasePayment}/${id}/cancel`
  );
};
const updatePurchaseAdvance = async (id: number, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.addPurchaseAdvancePayment}/${id}`,
    data
  );
};
const updatePurchasePayments = async (id: number, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.getPurchasePayment}/${id}`,
    data
  );
};
//whole sale adjustment
const getAllWholeAdjustments = async (params: {
  page: number;
  limit: number;
  column: number;
  order: number;
  search: string;
}) => {
  try {
    const apiUrl = setQueryParams(
      params,
      procurementsApiEndPoints.wholeSalesAdjustmentUrl
    );
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};

const createWholeAdjustment = async (data: {
  patient_id: string | number;
  adjustment_type: string | number;
  payment_type: string | number;
  balance: number;
  adjustment: string | number;
}) => {
  try {
    return await ApiUtil.postWithToken(
      procurementsApiEndPoints.wholeSalesAdjustmentUrl,
      data
    );
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};
const updateWholeAdjustment = async (
  id: number | string,
  data: {
    patient_id: string | number;
    adjustment_type: string | number;
    payment_type: string | number;
    balance: number;
    adjustment: string | number;
  }
) => {
  try {
    return await ApiUtil.putWithToken(
      `${procurementsApiEndPoints.wholeSalesAdjustmentUrl}/${id}`,
      data
    );
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};

const getWholeAdjustmentById = async (id: number | string) => {
  try {
    return await ApiUtil.getWithToken(
      `${procurementsApiEndPoints.wholeSalesAdjustmentUrl}/${id}`
    );
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};
const getWholeAdjustmentBalance = async (params: {
  customer_id: number | string;
  adjustment_type: number | string;
}) => {
  try {
    const apiUrl = setQueryParams(
      params,
      `${procurementsApiEndPoints.wholeSalesAdjustmentBalance}`
    );
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error occurs while canceling payment:", error);
    throw error;
  }
};
const createWholeSalesPaymentAdvance = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.wholeSalesAdvance}`,
    data
  );
};
const createWholeSalesPayment = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsApiEndPoints.wholeSalesPayments}`,
    data
  );
};
type WholeSalesPaymentBillType = {
  customer_id?: number | string | null;
  bill_id?: number | string | null;
};
const geWholeSalesPaymentBillById = async (
  params: WholeSalesPaymentBillType
) => {
  const apiUrl = setQueryParams(
    params,
    `${procurementsApiEndPoints.wholeSalesPayments}/payment-bills`
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getWholeSalesPayment = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsApiEndPoints.wholeSalesPayments}/${id}`
  );
};
const updateWholeSalesPayment = async (id: number, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.wholeSalesPayments}/${id}`,
    data
  );
};
const deleteWholeSalesPayment = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsApiEndPoints.wholeSalesPayments}/${id}`
  );
};
const deletePurchasePayments = async (id: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsApiEndPoints.getPurchasePayment}/${id}`
  );
};
const updateWholeSalesAdvance = async (id: number, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsApiEndPoints.wholeSalesAdvance}/${id}`,
    data
  );
};
export {
  updateStockTransferById,
  cancelStockTransfer,
  updateGoodsIssueReturnById,
  getGoodsIssueReturnById,
  updateWholeSalesAdvance,
  deletePurchasePayments,
  deleteWholeSalesPayment,
  updateWholeSalesPayment,
  getWholeSalesPayment,
  geWholeSalesPaymentBillById,
  createWholeSalesPayment,
  createWholeSalesPaymentAdvance,
  getWholeAdjustmentBalance,
  getAllWholeAdjustments,
  getWholeAdjustmentById,
  createWholeAdjustment,
  updateWholeAdjustment,
  getPurchasePayment,
  updatePurchasePayments,
  updatePurchaseAdvance,
  cancelPurchaseBillPayment,
  cancelPharmacyBill,
  addSalesReturn,
  updateSalesReturn,
  cancelPharmacyReturnBill,
  getPharmacyReturnBillById,
  getPharmacyReturnProducts,
  getPharmacyBillDraftById,
  getAllPharmacyBillDraft,
  deletePharmacyBill,
  getPharmacyBillById,
  updatePharmacyBill,
  addPharmacyBill,
  getItemsByDepartment,
  getCommonVariables,
  getConsultantsList,
  getExcessAmountByPatientId,
  addReferralDoctor,
  addPayee,
  getBillTermsOptions,
  getReferralDoctorsList,
  getPayeeList,
  getTestAllPatientsList,
  getWholeSalesById,
  getWholeSalesReturnById,
  updateWholePurchaseReturnProduct,
  addWholePurchaseReturnProduct,
  getWholeSalesReturnProduct,
  getWholeSalesProduct,
  getAllMasterCustomer,
  getTempGoodsIssueProductById,
  createTempGoodsIssue,
  deleteTempGoodsIssueById,
  updateTempGoodsIssueReject,
  updateTempGoodsIssueApproval,
  updateLabPurchasePayment,
  getLabPurchaseBillDetails,
  cancelLabPurchaseBill,
  getLabPurchaseBillById,
  createLabPurchasePayment,
  createLabPurchaseAdvance,
  deleteLabPurchaseBill,
  getLabStockAdjustById,
  getLabStockAdjustProduct,
  deleteLabStockAdjustmentById,
  createLabStockAdjustment,
  getLabGoodsReturnProducts,
  createLabGoodsIssueReturn,
  getLabGoodsIssueProduct,
  deleteLabGoodsIssueById,
  getLabGoodsIssueProductById,
  updateLabGoodsIssueById,
  cancelLabGoodsIssue,
  createLabGoodsIssue,
  getAllLabSuppliers,
  getLabPurchaseReturnById,
  addLabPurchaseReturnProduct,
  updateLabPurchaseReturnProduct,
  getLabPurchaseReturnProduct,
  getGoodsReturnProducts,
  createGoodsIssueReturn,
  getPharmacyProducts,
  createStockAdjustment,
  deleteStockAdjustmentById,
  getStockAdjustProduct,
  getStockAdjustById,
  cancelGoodsIssue,
  createGoodsIssue,
  updateGoodsIssueById,
  getGoodsIssueProductById,
  getGoodsIssueProduct,
  deleteGoodsIssueById,
  getPurchaseReturnById,
  updatePurchaseReturnProduct,
  getDepartmentSequence,
  addPurchaseReturnProduct,
  getPurchaseReturnProduct,
  updatePurchasePayment,
  createPurchasePayment,
  deletePurchaseBill,
  cancelPurchaseBill,
  getPurchaseBillDetails,
  getPurchaseBillById,
  createPurchaseAdvance,
  getPatientTestsById,
  getPatientScansById,
  deleteAntenatalById,
  getPatientVitalsHistory,
  getAntenatalById,
  getPatientVitals,
  getPrevHistoryById,
  getAllAntenatal,
  getFollowupById,
  getAllFollowupById,
  getAllTests,
  getAllScans,
  getAllFindings,
  getAllSuggestions,
  getAllDepartments,
  getAllSuppliers,
  departmentProducts,
  getAllPatientsList,
  getAllCommons,
  getAllSymptoms,
  getPatientById,
  addAntenatal,
  addFollowUp,
  updateFollowUp,
  editAntenatal,
  deleteSuggestionsById,
  getCommonAppConfigurations,
  getAllMasterDepartments,
  getAllLabMasterDepartments,
  getTestAllDoctorsList,
  getLatestPatientOpById,
  getLatestPatientIpById,
  updateLabPurchaseAdvance,
  getGoodsIssueViewProductById,
  createStockTransfer,
};
