import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Typography, Grid } from "@mui/material";
import { SaveButton, CheckBox } from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import {
  getAllTaxInfo,
  updateTaxes,
} from "../../../../services/procurementMasterService";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { procurementConst } from "../../../../constants/displayText";
import { useDispatch } from "react-redux";

function Tax() {
  // data table constants and states
  const dispatch = useDispatch();
  const [isSaveBtnLoader, setIsSaveBtnLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [taxesList, setTaxesList] = useState<any>([]);

  const getAllTaxDetails = async () => {
    setLoading(true);
    try {
      const result: any = await getAllTaxInfo();
      const updatedTaxesList = result?.data?.map((taxInfo: any) => ({
        id: taxInfo?.id,
        s_no: taxInfo?.id, // Adjust this as per your data structure
        tax: `${taxInfo?.tax_name} - ${taxInfo?.tax_rate}%`,
        sub_tax: taxInfo?.sub_taxes
          ?.map(
            (subTax: any) => `${subTax?.sub_tax_name} - ${subTax?.sub_tax_rate}`
          )
          ?.join(", "),
        isChecked: taxInfo?.is_used === 1,
      }));
      setTaxesList(updatedTaxesList);
      setLoading(false);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      console.error("An error occurred:", error);

      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTaxDetails();
  }, []);

  const handleCheckBoxChange = (id: any) => {
    setTaxesList((prev: any) => {
      return prev?.map((item: any) => {
        if (item.id === id) {
          return {
            ...item,
            isChecked: !item.isChecked,
          };
        }
        return item;
      });
    });
  };

  const columns: GridColDef[] = [
    {
      field: "s_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            S No
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5">{row?.s_no}</Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Tax
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5">{row?.tax}</Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "sub_tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Sub Tax
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5">{row?.sub_tax}</Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "used",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Used
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => {
        return (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid style={{ cursor: "pointer" }}>
              <CheckBox
                checked={row.isChecked}
                name={row.tax}
                onChange={() => handleCheckBoxChange(row.id)}
              />
            </Grid>
          </Grid>
        );
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const handleSave = async () => {
    setIsSaveBtnLoader(true);

    try {
      const data = {
        tax: taxesList?.map((taxItem: any) => ({
          id: taxItem.id,
          is_used: taxItem.isChecked ? 1 : 0,
        })),
      };
      await updateTaxes(data).then((result: any) => {
        setIsSaveBtnLoader(false);
        dispatch(setSnackBarSuccess({ snackBarMessage: result.message }));
      });
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      console.error("An error occurred:", error);
      setIsSaveBtnLoader(false);
    }
  };

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataTable
        loading={loading}
        columns={columns}
        rows={taxesList}
        tableOnly={true}
      />
      <SaveButton
        loading={isSaveBtnLoader}
        handleClick={handleSave}
        sx={{ mt: "20px" }}
      />
    </Grid>
  );
}
export default Tax;
