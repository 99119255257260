import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { PageLoader, SaveButton } from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import { stockAdjustmentConst } from "../../../../constants/displayText";
import Info from "./Info";
import { useParams } from "react-router-dom";
import {
  formattedDateWithSlash,
  formatTwelveHoursTime,
} from "../../../../utils/DateTimeFormatUtils";
import { useDispatch } from "react-redux";
import { setSnackBarFailed } from "../../../../redux/slices/snackbar";
import { getPurchaseReturnById } from "../../../../services/gynecologyService";
import Summary from "../Summary";
import { formula } from "../../../../utils/CalculationUtils";
const BillProducts = () => {
  const { productName } = stockAdjustmentConst;
  const dispatch = useDispatch();
  const { calculateNetAmount, calculateRoundedOff, calculateBillAmount } =
    formula.purchaseReturn;
  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);
  const { id } = useParams();
  const [summary, setSummary] = useState({
    net_amount: 0,
    rounded_off: 0,
    bill_amount: 0,
  });
  const [info, setInfo] = React.useState<{
    supplier_name: string | number | null;
    ref_no: string | number | null;
    invoice_value: string | number | null;
    invoice_date: string | number | null;
  }>({
    supplier_name: "",
    ref_no: "",
    invoice_value: "",
    invoice_date: "",
  });
  const getStockAdjust = async () => {
    setIsDataTableLoading(true);
    await getPurchaseReturnById(id as string)
      .then((res: any) => {
        const purchaseBill = res.data.bill;
        // const department = res.data.department;
        const product = res.data.bill_products;
        setInfo((prevState: any) => ({
          ...prevState,
          supplier_name: purchaseBill?.name,
          ref_no: purchaseBill?.ref_no,
          invoice_value: purchaseBill?.invoice_value,
          invoice_date: purchaseBill?.invoice_date
            ? formatTwelveHoursTime(purchaseBill?.invoice_date)
            : "",
        }));
        const data = product.map((res: any) => ({
          id: res.id,
          supplier: res.supplier_name,
          expiry_date: res.expires_at,
          batch_no: res.batch_no,
          product_name: res.product_name,
          return_qty: res.return_qty,
          free: res.free_return_qty,
          discount_type: res?.discount_type,
          discount: res?.discount || 0,
          tax: `${res.taxname}-${res.tax_id}%`,
          ptr: Math.ceil(Number(res.p_rate) / Number(res.pack_type)),
          mrp: res.mrp,
          amount: res.amount,
        }));
        setBillProducts(data);
        const billAmount = calculateBillAmount(data);
        const newRoundedValue = calculateRoundedOff({
          total_amount: billAmount,
        });
        const newNetAmount = calculateNetAmount({
          bill_amount: Number(billAmount),
          rounded_off: newRoundedValue,
        });
        setSummary((prevState) => ({
          ...prevState,
          bill_amount: billAmount ? billAmount : 0,
          rounded_off: newRoundedValue ? newRoundedValue : 0,
          net_amount: newNetAmount ? newNetAmount : 0,
        }));
        setIsDataTableLoading(false);
      })
      .catch((err: any) => {
        setIsDataTableLoading(false);
        dispatch(
          setSnackBarFailed({ snackBarMessage: err?.response?.data?.errors })
        );
        console.log(err);
      });
  };
  useEffect(() => {
    getStockAdjust();
  }, []);

  type BillProductTypes = {
    id?: string | number | null;
    supplier?: string | number | null;
    invoice_no?: string | number | null;
    batch_no?: string | number | null;
    expiry_date?: string | number | null;
    increased_qty?: string | number | null;
    decreased_qty?: string | number | null;
    adjustment_date?: string | number | null;
    reason?: string | number | null;
  };
  const [billProducts, setBillProducts] = useState<BillProductTypes[] | []>([]);

  const columns: GridColDef[] = [
    {
      field: "product_name",
      flex: 1.5,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {productName}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.product_name}</Typography>
      ),
      sortable: false,
    },
    {
      field: "return_qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Return Qty
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.return_qty}</Typography>
      ),
      sortable: false,
    },
    {
      field: "free",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Return Free
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.free}</Typography>
      ),
      sortable: false,
    },
    {
      field: "discount ",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Discount
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => {
        return (
          <Typography variant="h6">{`${row?.discount} ${
            row?.discount_type || ""
          }`}</Typography>
        );
      },

      sortable: false,
    },
    {
      field: "batch_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Batch No
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.batch_no}</Typography>
      ),
      sortable: false,
    },
    {
      field: "expiry_date",
      flex: 1,
      minWidth: 100,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Expiry Date
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row?.expiry_date ? formattedDateWithSlash(row?.expiry_date) : ""}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Tax
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.tax}</Typography>
      ),
      sortable: false,
    },
    {
      field: "ptr",
      flex: 1.2,

      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            PTR/Unit
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row?.ptr ? Number(row?.ptr).toFixed(2) : ""}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "mrp",
      flex: 1.2,

      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            MRP/Unit
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.mrp}</Typography>
      ),
      sortable: false,
    },
    {
      field: "amount",
      flex: 1.2,

      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Amount
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.amount}</Typography>
      ),
      sortable: false,
    },
  ];

  return isDataTableLoading ? (
    <PageLoader />
  ) : (
    <>
      <Grid>
        <Info info={info} />
        <Grid sx={{ m: "20px 0px" }}>
          <DataTable
            columns={columns}
            rows={billProducts}
            customizedTable={true}
            tableOnly={true}
          />
        </Grid>
        <Summary summary={summary} />
        <Grid
          sx={{
            width: "40vw",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            gap: 2,
            m: "20px 0px",
          }}
        >
          <SaveButton
            buttonText="Back"
            sx={{ width: "100px", height: "42px" }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BillProducts;
