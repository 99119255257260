// import { configureStore } from "@reduxjs/toolkit";
// import { reducer } from "../slices";

// const store: any = configureStore({
//   reducer: reducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });
// store.asyncReducers = {} as any;

// store.injectReducer = (key: any, asyncReducer: any) => {
//   store.asyncReducers[key] = asyncReducer;
//   store.replaceReducer(createReducer(store.asyncReducers));
// };

// function createReducer(asyncReducers: any) {
//   return combineReducers({
//     ...reducer,
//     ...asyncReducers,
//   });
// }
// export type RootState = ReturnType<typeof store>;
// export type AppDispatch = typeof store.dispatch;

// export default store;
import { configureStore } from "@reduxjs/toolkit";
import { reducer } from "../slices";

const store = configureStore({
  reducer: reducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
