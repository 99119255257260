import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField } from "../../../components/basic";
import { ExclamationCircle } from "../../../assets/icons";

const PaymentsFooter = ({ data, onChange, isView, refundedError }: any) => {
  const footer = [
    {
      key: "Cash Received",
      value: data?.cash_received,
      isDisabled: true,
      variant: "filled",
    },
    ...(data?.payment_type === "Card"
      ? [
          {
            key: "Convenience Fee	",
            value: data?.convenience_fee,
            isDisabled: true,
            variant: "filled",
          },
        ]
      : []),
    {
      key: "Amount Paid",
      value: data?.used_amount,
      isDisabled: true,
      variant: "filled",
    },
    {
      key: "Excess Amount",
      value: data?.excess_amount,
      isDisabled: true,
      variant: "filled",
    },
    ...(data?.excess_amount &&
    data?.payment_type !== "Advance" &&
    data?.payment_type !== "Excess"
      ? [
          {
            key: "Refunded Amount",
            value: data?.refunded_excess,
            name: "refunded_excess",
            isDisabled: isView,
            variant: "outlined",
            error: refundedError,
          },
        ]
      : []),
    {
      key: "Net Excess",
      value: data?.net_excess,
      isDisabled: true,
      variant: "filled",
    },
  ];

  const styles = {
    textFieldCellStyle: {
      "& input::placeholder": {
        fontSize: "12px",
        color: "textPrimary.main",
      },
      "& .MuiFormLabel-root": {
        color: "greyScale.main",
      },
    },
    textFieldStyle: {
      height: "35px",
      fontSize: "6px",

      "& .MuiOutlinedInput-root": {
        height: "35px",
        paddingLeft: "10px",
        textAlign: "center",
        borderRadius: "3px",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },

      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "primary.main",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
  };

  return (
    <Grid container sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        variant="h3"
        color="initial"
        sx={{ fontWeight: "600", color: "primary.main", m: "5px 0 15px 5px" }}
      >
        Payment Details
      </Typography>
      <Grid
        container
        sx={{
          width: "auto",
          borderRadius: "5px",
          border: "1px solid #E6E6E6",
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "5px",
          marginLeft: "5px",
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-end" },
            pr: { xs: "0px", md: "30px" },
          }}
          xs={12}
          md={6}
        >
          <Grid
            item
            sx={{
              width: {
                xs: "90%",
                sm: "90%",
                md: "80%",
                lg: "70%",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              m: "39px 0px 37px 0px",
            }}
          >
            {footer.map((row, index) => {
              return (
                <Grid
                  sx={{
                    width: {
                      xs: "100%",
                      lg: "100%",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    maxHeight: "35px",
                    mb: "23px",
                    color: "textPrimary.main",
                  }}
                  className="row"
                >
                  <Grid
                    sx={{
                      width: "40%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h4">{row.key}</Typography>
                    <Typography variant="h4">:</Typography>
                  </Grid>
                  <TextField
                    value={row.value}
                    name={row?.name}
                    disabled={row?.isDisabled}
                    onChange={onChange}
                    sx={{
                      ...styles.textFieldStyle,
                      "& .MuiOutlinedInput-notchedOutline": {
                        backgroundColor: row?.isDisabled
                          ? "var(--disabled-background)"
                          : "initial",
                      },
                      "& fieldset": {
                        opacity: 0.5,
                      },
                      width: "153px",
                    }}
                    error={row?.error}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            sx={{
              width: { xs: "90%", sm: "90%", md: "100%", lg: "90%" },
              borderRadius: "5px",
              border: "1px solid #FFE58F",
              background: "#FFFBE6",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              mb: "25px",
              padding: "10px",
            }}
          >
            <ExclamationCircle />
            <Typography
              color="initial"
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "textPrimary.main",
                ml: "5px",
                textAlign: "center",
              }}
            >
              Note: if excess amount is not refunded in full, then balance will
              be added to patient excess.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentsFooter;
