import { Box } from "@mui/material";
import { Select, SelectWithCancelChips } from "../../../../components/basic";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { getPatientVitalsHistory } from "../../../../services/gynecologyService";
import React, { useEffect, useState } from "react";
import _without from "lodash/without";
import { dateFormat } from "../../../../utils/DateTimeFormatUtils";

export default function VitalsTracker({ lastFollowUpDetails }: any) {
  const [selectValue, setSelectValue] = useState({ date: 3 });
  const [chartData, setChartData] = React.useState<any[]>([]);
  const [chartNames, setChartNames] = React.useState<
    { id: number; value: string }[]
  >([]);
  const [chartListData, setChartListData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const stableColors = [
    "#FF5733",
    "#33FFA8",
    "#3358FF",
    "#FF33D1",
    "#FFE333",
    "#FF3333",
    "#33FF33",
    "#3333FF",
    "#8D33FF",
    "#33FFEE",
    "#FF8833",
    "#33FFC8",
    "#3322FF",
    "#FF33C1",
    "#FFF333",
    "#FF33CC",
    "#33FF73",
    "#33CCFF",
    "#B333FF",
    "#33FFAA",
  ];

  const getPatientVitalHistoryDetails = async (id: number | string) => {
    const currentDate = new Date();
    const tempDate = new Date(currentDate);
    if (id === 2) {
      tempDate.setMonth(tempDate.getMonth() - 1);
    }
    if (id === 3) {
      tempDate.setMonth(tempDate.getMonth() - 6);
    }
    const data: any = id === 1 ? currentDate : tempDate;
    const params = {
      antenatal_card_id: 2,
      vital_ids: chartData,
      from_date: dateFormat(data, "YYYY-MM-DD"),
    };

    await getPatientVitalsHistory(params)
      .then((res: any) => {
        const data = res.data;
        const name: any = [];
        const chartDataList: any = [];
        data.forEach(
          (item: {
            date_of_visit: string;
            vital_id: string;
            vital_name: string;
            vital_unit: string;
            vital_value: string;
          }) => {
            const findOption = name.findIndex(
              (ele: { id: string | number; value: string }) =>
                ele.value === item.vital_name
            );
            const existingDateIndex = chartDataList.findIndex(
              (ele: any) => ele.date_of_visit === item.date_of_visit
            );
            if (existingDateIndex === -1) {
              const newItem: { [key: string]: string } = {};
              newItem.date_of_visit = item.date_of_visit;
              newItem[item.vital_name] = item.vital_value
                ? item.vital_value
                : "0";
              newItem.values = item.vital_value;
              chartDataList.push(newItem);
            } else {
              chartDataList[existingDateIndex][item.vital_name] =
                item.vital_value ? item.vital_value : "0";
            }
            if (findOption === -1) {
              name.push({ id: item.vital_id, value: item.vital_name });
            }
          }
        );

        setChartListData(chartDataList);
        setChartNames(name);
        if (chartData.length === 0) {
          const newFormatted: any = [];
          name.map((elem: { id: number; value: string }) =>
            newFormatted.push(elem.id)
          );
          setChartData(newFormatted);
        }
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    setIsLoading(true);
  }, []);
  useEffect(() => {
    getPatientVitalHistoryDetails(selectValue.date);
  }, [selectValue.date]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChartData(event.target.value as string[]);
  };
  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setChartData((current) => _without(current, value));
  };
  const handleSelectOnChange = (e: any) => {
    setSelectValue((prevState) => ({ ...prevState, date: e.target.value }));
  };
  return (
    <Box>
      <SelectWithCancelChips
        options={chartNames}
        value={chartData}
        handleChange={handleChange}
        handleDelete={handleDelete}
        label={"Select Parameters"}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "6px 12px",
        }}
      >
        <Box sx={{ fontSize: "18px", fontWeight: "600" }}>Comparison Trend</Box>
        <Box>
          <Select
            options={[
              { id: 1, value: "Today" },
              { id: 2, value: "Last One Month" },
              { id: 3, value: "Last Six Month" },
            ]}
            width={"135px"}
            defaultValue={1}
            onChange={handleSelectOnChange}
            value={selectValue.date}
            sx={{ borderRadius: "30px" }}
          />
        </Box>
      </Box>
      <Box>
        <LineChart
          width={730}
          height={250}
          data={chartListData}
          layout="horizontal"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="date_of_visit" />
          <YAxis dataKey="values" />
          <Tooltip />
          <Legend iconType="rect" />
          {chartData.map((ele, idx) => (
            <Line
              dataKey={
                chartNames?.find(
                  (elem: { value: string; id: number | string }) =>
                    elem?.id === ele
                )?.value
              }
              stroke={stableColors[idx]}
            />
          ))}
        </LineChart>
      </Box>
    </Box>
  );
}
