import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
export type FollowUpDetailsTypes = {
  id: number | string;
  patient_id: number | string;
  patient_name: number | string;
  antenatal_card_id: number | string;
  date_of_visit: number | string;
  trimester_week: number | string;
  vitals: [
    {
      vital_id: number | string;
      vital_date: number | string;
      vital_name: number | string;
      vital_unit: number | string;
      vital_value: number | string;
    }
  ];
  findings: [
    {
      id: number | string;
      symptom_name: number | string;
    }
  ];
  tests: [
    {
      id: number | string;
      name: number | string;
    }
  ];
  scans: [
    {
      id: number | string;
      name: number | string;
    }
  ];
  notes: number | string;
  next_review_date: number | string;
  trimester: number | string;
  suggestions: [
    {
      id: number | string;
      followup_id: number | string;
      product_id: number | string;
      dosage: number | string;
      units: number | string;
      dosage_time: [number | string, number | string, number | string];
      freq: number | string | null;
      freq_time: number | string | null;
      freq_time_type: number | string | null;
      duration: {
        interval: number | string | null;
        custom_date: number | string | null;
        interval_days: number | string | null;
        specific_days: [];
        duration_count: number | string | null;
      };
      duration_count: number | string | null;
      duration_type: number | string;
      start_date: number | string;
      end_date: number | string | null;
      product: {
        id: number | string;
        name: number | string;
      };
    }
  ];
};
type GynecologySliceState = {
  lastFollowUpId: number | string | null;
  followUpDetails: FollowUpDetailsTypes | any;
  patientId: number | string | null;
  gynecologyId: number | string | null;
  latestAppointId: number | string | null;
  latestTrimesterWeek: number | string | null;
};

const initialState: GynecologySliceState = {
  lastFollowUpId: null,
  followUpDetails: {},
  patientId: null,
  gynecologyId: null,
  latestAppointId: null,
  latestTrimesterWeek: null,
};

const gynecologySlice = createSlice({
  name: "gynecology",
  initialState,
  reducers: {
    setGynecologyId: (state, action: PayloadAction<number | string | null>) => {
      state.gynecologyId = action.payload;
    },
    setFollowUpDetails: (
      state,
      action: PayloadAction<FollowUpDetailsTypes>
    ) => {
      state.followUpDetails = action.payload;
    },
    setPatientId: (state, action: PayloadAction<number | string>) => {
      state.patientId = action.payload;
    },
    setLastFollowUpId: (state, action: PayloadAction<number | string>) => {
      state.lastFollowUpId = action.payload;
    },
    setLatestAppointId: (state, action: PayloadAction<number | string>) => {
      state.latestAppointId = action.payload;
    },
    setLatestTrimesterWeek: (state, action: PayloadAction<number | string>) => {
      state.latestTrimesterWeek = action.payload;
    },
  },
});

export const {
  setGynecologyId,
  setFollowUpDetails,
  setPatientId,
  setLastFollowUpId,
  setLatestAppointId,
  setLatestTrimesterWeek,
} = gynecologySlice.actions;

export default gynecologySlice.reducer;
