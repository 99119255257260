import { TestingDashboard } from "../../assets/images";

const DashBoard = () => {
  return (
    <div>
      <TestingDashboard
        style={{
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
      />
    </div>
  );
};

export default DashBoard;
