import { Box } from '@mui/material';
import ClickableBoxGrid from './headerMastersModal';

const MastersScreen = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
      <ClickableBoxGrid />
    </Box>
  );
};

export default MastersScreen;
