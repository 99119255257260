import React from "react";
import { Grid, Typography } from "@mui/material";
import { indentInfoConst } from "../../../../constants/displayText";
import moment from "moment";

type InfoProps = {
  info: any;
};

const Info = ({ info }: InfoProps) => {
  const { indentNo, date, user } = indentInfoConst;

  const infoData = [
    {
      name: indentNo,
      value: info.indent_no,
    },
    {
      name: date,
      value: info?.created_at
        ? moment(info?.created_at).format("DD/MM/YYYY HH:mm:ss")
        : "",
    },
    {
      name: user,
      value: info?.created_user,
    },
  ];

  return (
    <Grid
      container
      sx={{
        width: "92vw",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pl: 2,
        }}
      >
        <Grid
          item
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Grid
            item
            sx={{
              width: {
                xs: "90%",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "15px 0px 10px 0px",
            }}
          >
            {infoData.map((row, index) => {
              return (
                <Grid
                  key={index}
                  sx={{
                    width: {
                      xs: "100%",
                      lg: "100%",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    maxHeight: "35px",
                    mb: "23px",
                    color: "textPrimary.main",
                  }}
                  className="row"
                >
                  <Grid
                    sx={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      {row.name}
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      pl: 3,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "greyScale.main" }}>
                      {row.value}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Info;
