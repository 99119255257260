interface QueryParamsBuilderTypes {
  id?: string | number;
  roleName?: string;
  baseUrl?: string;
  page?: number;
  size?: number;
  sortBy?: string | undefined;
  sortOrder?: string | undefined;
  doctorId?: string | number;
  purposeId?: string | number;
  patientId?: string | number;
  fromDate?: number | undefined | any;
  toDate?: number | undefined | any;
  opSeqId?: string | undefined | any;
  userId?: string | undefined | any;
  searchValue?: string | number;
  product?:string | number;
}

const QueryParamsBuilder = ({
  id,
  roleName,
  baseUrl,
  page,
  size,
  sortBy,
  sortOrder,
  fromDate,
  toDate,
  opSeqId,
  userId,
  doctorId,
  patientId,
  purposeId,
  searchValue,
  product,
}: QueryParamsBuilderTypes): string => {
  // Base URL
  let apiUrl = id ? `${baseUrl}/${id}` : `${baseUrl}`;

  if (roleName) {
    apiUrl += `/${roleName}`;
  }

  let queryString = `?`;

  if (page) {
    queryString += `page=${page}&`;
  }
  if (size) {
    queryString += `limit=${size}&`;
  }
  if (userId) {
    queryString += `user_id=${userId}&`;
  }
  if (product) {
    queryString += `user_id=${product}&`;
  }
  if (sortBy) {
    queryString += `column=${sortBy}&`;
  }

  if (sortOrder) {
    queryString += `order=${sortOrder}&`;
  }

  if (searchValue) {
    queryString += `search=${searchValue}&`;
  }
  if (fromDate) {
    queryString += `from_date=${fromDate}&`;
  }
  if (toDate) {
    queryString += `to_date=${toDate}&`;
  }
  if (opSeqId) {
    queryString += `op_no=${opSeqId}&`;
  }
  if (doctorId) {
    queryString += `doctor_id=${doctorId}&`;
  }
  if (patientId) {
    queryString += `patient_id=${patientId}&`;
  }
  if (purposeId) {
    queryString += `consultation_id=${purposeId}&`;
  }
  if(product){
    queryString += `product=${product}&`;
    
  }
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  apiUrl += queryString;

  return apiUrl;
};

export default QueryParamsBuilder;
