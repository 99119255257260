import React from "react";
import BookingForm from "./BookingForm";

const BookAppointments = () => {
  return (
    <div style={{marginLeft:"8px"}}>
      <BookingForm />
    </div>
  );
};

export default BookAppointments;
