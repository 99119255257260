import { Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { Sort } from "../../components/basic";

import { DataTable } from "../../components/shared";
import {
  PatientsTypes,
  getAllPatientsList,
} from "../../services/mainCoreService";
import CustomFilter from "../../components/shared/customFilter";
import { useNavigate } from "react-router-dom";
type GridRowData = Record<string, unknown>;
const Accounts = () => {
  const [patientsList, setPatientsList] = useState<PatientsTypes | any>([]);
  const [patientsCount, setPatientsCount] = useState<number | null>();
  const navigate = useNavigate();
  type InputValuesTypes = {
    consultation?: number;
    doctorName?: number;
    patientName?: number;
    opNo?: number;
    fromDate?: "";
    toDate?: "";
  };
  const [loading, setLoading] = useState(false);
  const [inputValues, setInputValues] = useState<InputValuesTypes>({
    consultation: undefined,
    doctorName: undefined,
    patientName: undefined,
    opNo: undefined,
    fromDate: undefined,
    toDate: undefined,
  });
  const rowClicked = (id: number | null) => {
    navigate(`/admin/edit-user/${id}`);
  };
  const columns: GridColDef[] = [
    {
      field: "UHID",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Patient’s UHID
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.patient_uhid}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "name",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {`${row.title}.${row.name}`}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "mobile",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Patient’s Mobile
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.mobile}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "age",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Age
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.age}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "address1",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Address 1
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.line_1}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "address2",
      flex: 1,
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Address 2
          </Typography>
          <Sort />
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.line_2}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
  ];

  const clearFilters = () => {
    setInputValues({
      consultation: undefined,
      doctorName: undefined,
      patientName: undefined,
      opNo: undefined,
      fromDate: undefined,
      toDate: undefined,
    });
  };
  const pageInfo: any = useSelector(
    (state: any) => state.pagination.patientsAccount
  );
  useEffect(() => {
    setLoading(true);
    getAllPatientsList({
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
    })
      .then((res) => {
        setPatientsList(res.data.result);
        setPatientsCount(res.data.totalRecords);
      })
      .catch((err) => console.log(err));
    setLoading(false);
  }, [pageInfo.page, pageInfo.pageSize]);
  return (
    <Box>
      <CustomFilter
        isFilterEnabled={true}
        isSearchEnabled={true}
        addButtonTitle={"Add Patient"}
        editButtonTitle="Master"
        clearFilter={clearFilters}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={patientsList}
        pageCount={patientsCount}
        currentPage={"patientsAccount"}
        onRowClick={(row: any) => rowClicked(row.row.user_id)}
      />
    </Box>
  );
};

export default Accounts;
