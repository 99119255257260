import { TextField, IconButton, Box, useMediaQuery } from "@mui/material";
import { SearchIcon, CancelTextIcon } from "../../../assets/icons";
import { useRef } from "react";
type SearchBarTypes = {
  handleSearchClick?: () => void;
  searchName?: string;
  onChange?: any;
  sx?: any;
  value?: any;
};

const CustomSearchBar = ({
  handleSearchClick,
  onChange,
  searchName,
  value,
  sx,
}: SearchBarTypes) => {
  const windowWidth = useRef(window.innerWidth);
  const isMediumScreen = useMediaQuery(
    "(min-width:251px) and (max-width:550px)"
  );
  const styles = {
    inputStyles: {
      width: isMediumScreen ? `${windowWidth.current - 20}px` : "100%",
    },
  };
  return (
    <Box sx={{ ...sx }}>
      <TextField
        fullWidth
        id="standard-bare"
        variant="outlined"
        onChange={onChange}
        value={value}
        sx={{
          "& input": {
            height: "10px",
            transition: "height 3s ease-in-out !important",
          },
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
            borderColor: "greyScale.lighter",
          },
          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderRadius: "8px",
            borderColor: "greyScale.lighter",
          },
          ...styles.inputStyles,
        }}
        name={searchName}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
          endAdornment: (
            <IconButton onClick={handleSearchClick}>
              <CancelTextIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export default CustomSearchBar;
