import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Grid, Typography } from "@mui/material";
import {
  Select,
  TextField,
  CheckBox,
  DatePickerWithTime,
} from "../../../../components/basic";
import { RupeeIcon } from "../../../../assets/icons";

import { getAllMasterCustomer } from "../../../../services/gynecologyService";

const PaymentsTabs = ({
  tabValue,
  data,
  advanceData,
  onChange,
  cashReceivedError,
  paymentTypeOptions,
  excessAmount,
  onAdvanceChange,
}: any) => {
  const [value, setValue] = useState("1");
  const [advancePaymentOptions, setAdvancePaymentOptions] =
    useState(paymentTypeOptions);
  const [customerList, setCustomerList] = useState([]);

  const getAllMasterCustomerList = async () => {
    try {
      const data = { active: 1 };
      await getAllMasterCustomer(data).then((res: any) => {
        if (res.data) {
          const newCustomerList = res.data?.result.map((ele: any) => ({
            name: ele.name,
            id: ele.id,
            address: ele.address,
            phone: ele.phone,
          }));
          setCustomerList(newCustomerList);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllMasterCustomerList();
  }, []);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    tabValue(newValue);

    if (newValue === "2") {
      setAdvancePaymentOptions(
        paymentTypeOptions.filter(
          (ele: any) => ele.id !== "Advance" && ele.id !== "Excess"
        )
      );
    }
  };

  const styles = {
    selectGrid: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    textFieldGrid: {
      "& input::placeholder": {
        fontSize: "12px",
        color: "textPrimary.main",
      },
      "& .MuiFormLabel-root": {
        color: "greyScale.main",
      },
    },
    textFieldStyle: {
      height: "44px",
      fontSize: "6px",
      "& .MuiOutlinedInput-root": {
        height: "44px",
        borderRadius: "5px",
        paddingLeft: "14.4px",
        boxShadow: "none",
        borderColor: "var(--primary-border-color)",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      onChange({ target: { name, value: newValue.validatedValue[0] } });
    }
  };

  return (
    <Box
      sx={{
        width: "90vw",
        borderRadius: "5px",
        border: "1px solid #e9e9e9",
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "greyScale.lighter" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label="Bill Payments"
              value="1"
              disableRipple
              sx={{
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "capitalize",
                borderRadius: "1px solid green",
              }}
            />
            <Tab
              label="Advance"
              value="2"
              disableRipple
              sx={{
                fontFamily: ["Inter", "sans-serif"].join(","),
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "capitalize",
              }}
            />
          </TabList>
        </Box>
        <TabPanel
          value="1"
          sx={{ position: "relative", p: "0px 24px 24px 24px" }}
        >
          <Grid container columnGap={1} rowGap={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ mt: { xs: "25px" } }}
            >
              {/* <InfiniteScroller
                loadOptions={getAllSupplier}
                options={patientsList}
                handleOnChange={onChange}
                place
                name={"supplier_id"}
                label={"Select Supplier"}
                value={data.supplier_id}
                width="100%"
              /> */}
              <Select
                label={"Select Customer"}
                name="customer_id"
                options={customerList}
                formControlStyle={{ width: "100%" }}
                width=""
                value={data.customer_id}
                onChange={onChange}
              />
              <Box
                sx={{
                  width: "275px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  mt: "6px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "11",
                    fontWeight: "500",
                    color: "primary.main",
                  }}
                >
                  Excess: {excessAmount?.excess || 0}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "11",
                    fontWeight: "500",
                    color: "#207DFF",
                  }}
                >
                  Advance: {excessAmount?.advance || 0}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <Select
                value={data.payee_id}
                placeholder="Select Payee"
                options={payeeOptions}
                label={"Payee"}
                name="payee_id"
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  height: "45px",
                  boxShadow: "none",
                  borderRadius: "5px",
                  borderColor: "var(--primary-border-color)",
                  ...styles.selectGrid,
                }}
                onChange={onChange}
              />
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ mt: { xs: "25px" } }}
            >
              <Box sx={{ ...styles.textFieldGrid, width: "100%" }}>
                <TextField
                  placeholder="Cash Received"
                  label={"Cash Received"}
                  name="cash_received"
                  endPlaceholderIcon={<RupeeIcon />}
                  width=""
                  formControlStyle={{ width: "100%" }}
                  sx={{
                    ...styles.textFieldStyle,
                  }}
                  value={data.cash_received}
                  onChange={onChange}
                  error={cashReceivedError}
                  disabled={
                    data.payment_type === "Advance" ||
                    data.payment_type === "Excess"
                  }
                />
                <Box
                  sx={{
                    width: "100%",
                    height: "20px",
                    mt: "6px",
                    "& .MuiFormControl-root": { height: "100%" },
                    "& .MuiBox-root": {
                      height: "100%",
                    },
                    "& .MuiFormControlLabel-root": {
                      height: "100%",
                    },
                    "& .MuiSvgIcon-root": {
                      width: "20px",
                      height: "20px",
                    },
                    "& .PrivateSwitchBase-input": {
                      height: "inherit",
                    },
                  }}
                >
                  <CheckBox
                    label={`Pay Full Amount (${data.cash_received})`}
                    checked={data?.is_pay_full_amount}
                    name={"is_pay_full_amount"}
                    onChange={(event: any) =>
                      onChange({
                        target: {
                          name: event?.target?.name,
                          value: event?.target?.checked,
                        },
                      })
                    }
                    sx={{
                      fontSize: "12",
                      fontWeight: "400",
                    }}
                    disabled={!data?.cash_received}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              item
              sx={{
                width: { xs: "275px" },
                mt: { xs: "25px" },
              }}
            >
              <DatePickerWithTime
                label="Created Date"
                style={{
                  height: "37px",
                  borderRadius: "none",
                  width: "96%",
                }}
                formControlStyle={{ width: "96%" }}
                value={data?.date_created}
                name="date_created"
                format="DD/MM/YYYY hh:mm:ss A"
                borderColor="var(--primary-border-color)"
                onChange={(e: any, newValue: any) => {
                  handleDateChange(e, newValue, "date_created");
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <Select
                value={data.payment_type}
                name={"payment_type"}
                placeholder="Select Payment Type"
                options={paymentTypeOptions}
                label={"Payment Type"}
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  height: "45px",
                  boxShadow: "none",
                  borderRadius: "5px",
                  borderColor: "var(--primary-border-color)",
                  ...styles.selectGrid,
                }}
                onChange={onChange}
              />
            </Grid>
            {data?.payment_type === "Bank" && (
              <Grid
                item
                xs={12}
                sm={12}
                md={5.8}
                lg={2.8}
                sx={{ ...styles.textFieldGrid, mt: { xs: "25px" } }}
              >
                <TextField
                  placeholder="URN No"
                  label={"URN No"}
                  width=""
                  formControlStyle={{ width: "100%" }}
                  sx={{
                    ...styles.textFieldStyle,
                  }}
                  name="urn_number"
                  value={data.urn_number}
                  onChange={onChange}
                />
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel
          value="2"
          sx={{ position: "relative", p: "0px 24px 24px 24px" }}
        >
          <Grid container columnGap={1} rowGap={1}>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <Select
                label={"Select Customer"}
                name="customer_id"
                options={customerList}
                formControlStyle={{ width: "100%" }}
                width=""
                value={advanceData.customer_id}
                onChange={onAdvanceChange}
              />
              {/* <InfiniteScroller
                loadOptions={getAllSupplier}
                options={patientsList}
                handleOnChange={onAdvanceChange}
                place
                name={"supplier_id"}
                label={"Select Supplier"}
                value={advanceData.supplier_id}
                width="100%"
              /> */}
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <Select
                value={advanceData.payee_id}
                placeholder="Select Payee"
                options={payeeOptions}
                label={"Payee"}
                name="payee_id"
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  height: "45px",
                  boxShadow: "none",
                  borderRadius: "5px",
                  borderColor: "var(--primary-border-color)",
                  ...styles.selectGrid,
                }}
                onChange={onAdvanceChange}
              />
            </Grid> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.textFieldGrid, mt: { xs: "25px" } }}
            >
              <TextField
                placeholder="Cash Received"
                label={"Cash Received"}
                name="cash_received"
                endPlaceholderIcon={<RupeeIcon />}
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  ...styles.textFieldStyle,
                }}
                value={advanceData.cash_received}
                onChange={onAdvanceChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{
                mt: { xs: "25px" },
              }}
            >
              <DatePickerWithTime
                label="Created Date"
                style={{
                  height: "37px",
                  borderRadius: "none",
                  width: "96%",
                }}
                formControlStyle={{ width: "96%" }}
                value={advanceData?.date_created}
                name="date_created"
                format="DD/MM/YYYY hh:mm:ss A"
                borderColor="var(--primary-border-color)"
                onChange={(e: any, newValue: any) => {
                  onAdvanceChange({
                    target: {
                      name: "date_created",
                      value: newValue.validatedValue[0],
                    },
                  });
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5.8}
              lg={2.8}
              sx={{ ...styles.selectGrid, mt: { xs: "25px" } }}
            >
              <Select
                value={advanceData.payment_type}
                name={"payment_type"}
                placeholder="Select Payment Type"
                options={advancePaymentOptions}
                label={"Payment Type"}
                width=""
                formControlStyle={{ width: "100%" }}
                sx={{
                  height: "45px",
                  boxShadow: "none",
                  borderRadius: "5px",
                  borderColor: "var(--primary-border-color)",
                  ...styles.selectGrid,
                }}
                onChange={onAdvanceChange}
              />
            </Grid>
            {advanceData?.payment_type === "Bank" && (
              <Grid
                item
                xs={12}
                sm={12}
                md={5.8}
                lg={2.8}
                sx={{ ...styles.textFieldGrid, mt: { xs: "25px" } }}
              >
                <TextField
                  placeholder="URN No"
                  label={"URN No"}
                  width=""
                  formControlStyle={{ width: "100%" }}
                  sx={{
                    ...styles.textFieldStyle,
                  }}
                  name="urn_number"
                  value={advanceData.urn_number}
                  onChange={onAdvanceChange}
                />
              </Grid>
            )}
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  );
};
export default PaymentsTabs;
