/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, useCallback } from "react";
import { Typography, Box, debounce } from "@mui/material";
import { DataTable, ConfirmationDialog } from "../../../components/shared";
import { DeleteIcon, ViewIcon } from "../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import {
  Sort,
  CustomButton,
  TextField,
  DatePicker,
  AutoComplete,
} from "../../../components/basic";
import { RouteUrls } from "../../../constants/routes";
import { RootState } from "../../../redux/store";
import {
  AppointmentTypes,
  getAllAppointment,
  getAllPatientsList,
  getAllConsultationsList,
  getAllDoctorsListTest,
  deleteAppointment,
} from "../../../services/appointmentService";
import { useNavigate } from "react-router-dom";
import { statusColor, handleFilterCounts } from "../../../utils/GeneralUtils";
import CustomFilter from "../../../components/shared/customFilter";
import { InfiniteScroller } from "../../../components/basic";
import { dateFormat, timeFormat } from "../../../utils/DateTimeFormatUtils";
import PermissionUtils from "../../../utils/PermissionUtils";
import { Link } from "react-router-dom";
import {
  setFilterDataValues,
  setSortField,
  setHandleClearSearch,
  setCurrentPage,
} from "../../../redux/slices/pagination";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../redux/slices/snackbar";

type GridRowData = Record<string, unknown>;

const AppointmentsList = () => {
  const { can } = PermissionUtils();
  const { patientsUrl, historyUrl } = RouteUrls;
  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.appointmentList,
      filterData: state?.pagination?.appointmentList?.filterData,
      count: state?.pagination?.appointmentList?.count,
      sortField: state?.pagination?.appointmentList?.sortedField,
    })
  );

  const dispatch = useDispatch();

  const [appointmentList, setAppointmentList] = useState<AppointmentTypes[]>(
    []
  );
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const isTokenEnable = useSelector(
    (state: any) => state?.appConfiguration?.isTokenEnable
  );
  const [isClearFilter, setIsClearFilter] = useState(false);

  const patientsPagination: any = useRef(1);
  const searchPatientsPagination = useRef({ search: "", page: 1 });
  const [doctorsName, setDoctorsName] = useState<any>([]);
  const [consultationsName, setConsultationsName] = useState<any>([]);
  const [patientsList, setPatientsList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);

  // dialog variables

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isConfirmationDialogButtonLoading,
    setIsConfirmationDialogButtonLoading,
  ] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);

  const navigate = useNavigate();

  const getAllDoctorsData = async () => {
    try {
      await getAllDoctorsListTest().then((result: any) => {
        let data = result?.data;

        if (data?.length > 0) {
          setDoctorsName(data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllConsultationsData = async () => {
    try {
      await getAllConsultationsList().then((result: any) => {
        let data = result.data;

        if (data?.length > 0) {
          setConsultationsName(data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getAllPatientsList({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  const getAllPatientsData = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions.length / 10)
            ? patientsPagination?.current
            : Math?.ceil(prevOptions?.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData?.id,
              label: `${uniqueData?.uhid} - ${uniqueData?.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: any) =>
              !prevOptions?.some(
                (prevOption: any) => prevOption?.value === option?.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination?.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);

    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "appointmentList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "appointmentList",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const viewAppointmentDetails = (row: any) => {
    navigate(
      `${RouteUrls.appointmentUrl}${RouteUrls.appointmentsDetails}/${row?.id}`
    );
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        {/* currently its was not developed */}
        {/* <div style={{ cursor: "pointer" }}>
          <EditIcon />
        </div> */}
        {can("appointments_view") && (
          <div style={{ cursor: "pointer" }}>
            <ViewIcon onClick={() => viewAppointmentDetails(row)} />
          </div>
        )}
        {can("appointments_delete") && (
          <div style={{ cursor: "pointer" }}>
            <DeleteIcon
              onClick={() => {
                setIsConfirmationDialogOpen(true);
                setIdToBeDeleted(row?.id);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "UHID",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("uhid")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient’s UHID
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "uhid" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "uhid" && sortField?.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Link
          to={`${patientsUrl}${historyUrl}/${row?.patient_id}`}
          style={{
            width: "100%",
            height: "100%",
            textDecoration: "none",
            cursor: "pointer",
            color: "inherit",
            outline: "none",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            {row?.uhid}
          </Typography>
        </Link>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "Prescription",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("pres_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Prescription No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "pres_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "pres_no" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.pres_no || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "Patient’s",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("patient_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient’s Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "patient_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "patient_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.patient_name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "opNo",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("op_no")}
        >
          <Typography variant="h5" fontSize={14}>
            OP No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "op_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "op_no" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.op_no}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "mobile",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("patient_mobile")}
        >
          <Typography variant="h5" fontSize={14}>
            Mobile No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "patient_mobile" &&
              sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "patient_mobile" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.patient_mobile}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "purpose",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("purpose_id")}
        >
          <Typography variant="h5" fontSize={14}>
            Purpose
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "purpose_id" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "purpose_id" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.purpose}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "doctor",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("doctor_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Doctor name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "doctor_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "doctor_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.doctor_name}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("appoint_date")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "appoint_date" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "appoint_date" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {dateFormat(row?.appoint_date)}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      field: "Token",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("token_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Token
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "token_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "token_no" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.token_no}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "timing",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("slot_label")}
        >
          <Typography variant="h5" fontSize={14}>
            Timing
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "slot_label" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "slot_label" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.slot_label === "00:00:00" ? "" : timeFormat(row?.slot_label)}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      field: "Status",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("status")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "status" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "status" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row?.status : null;
        const { buttonText, backgroundColor, textColor } =
          statusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const [updatedColumns, setUpdatedColumns] = useState<any>(columns);

  const clearFilters = () => {
    if (
      filterData?.from_date ||
      filterData?.to_date ||
      filterData?.op_seq_no_id ||
      filterData?.doctor_id ||
      filterData?.patient_id ||
      filterData?.purpose_id
    ) {
      dispatch(
        setFilterDataValues({
          key: "appointmentList",
          value: {
            doctor_id: null,
            doctor_value: "",
            purpose_id: null,
            purpose_value: "",
            patient_id: "",
            patient_id_input: "",
            op_seq_no_id: "",
            from_date: new Date(),
            to_date: new Date(),
            search: "",
          },
          name: "clearFilter",
        })
      );
      dispatch(
        setCurrentPage({
          key: "appointmentList",
          value: 0,
        })
      );
      setIsClearFilter(true);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "patient_id" && value) {
      const finalValue = patientsList.filter((obj: any) => {
        if (obj.value === value) {
          return obj;
        }
      });
      dispatch(
        setFilterDataValues({
          key: "appointmentList",
          name: name,
          value: finalValue[0],
        })
      );
      dispatch(
        setCurrentPage({
          key: "appointmentList",
          value: 0,
        })
      );

      return false;
    }

    // if (name !== "search") {
    dispatch(
      setFilterDataValues({
        key: "appointmentList",
        name: name,
        value:
          value === "" || value === undefined || value === null ? "" : value,
      })
    );
    dispatch(
      setCurrentPage({
        key: "appointmentList",
        value: 0,
      })
    );

    // }
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(
        setFilterDataValues({
          key: "appointmentList",
          value: newValue.validatedValue[0],
          name: name,
        })
      );
      dispatch(
        setCurrentPage({
          key: "appointmentList",
          value: 0,
        })
      );
    }
  };

  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    // if (newValue === null) {
    //   return false;
    // }
    dispatch(
      setFilterDataValues({
        key: "appointmentList",
        value: newValue,
        name: name,
      })
    );
    dispatch(
      setCurrentPage({
        key: "appointmentList",
        value: 0,
      })
    );
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newValue: any,
    name: string
  ) => {
    // if (newValue === "") {
    //   return false;
    // }

    dispatch(
      setFilterDataValues({
        key: "appointmentList",
        value: newValue,
        name: name,
      })
    );
  };
  const addColumns = () => {
    const newColumns = [...columns];
    if (!isTokenEnable) {
      const prescriptionIndex = newColumns.findIndex(
        (ele) => ele?.field === "Prescription"
      );
      newColumns?.splice(prescriptionIndex, 1);
      const TokenIndex = newColumns?.findIndex((ele) => ele?.field === "Token");
      newColumns?.splice(TokenIndex, 1);
    } else {
      const timingIndex = newColumns?.findIndex(
        (ele) => ele?.field === "timing"
      );
      newColumns?.splice(timingIndex, 1);
    }
    setUpdatedColumns(newColumns);
  };

  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllAppointment({
        page: data?.page,
        pageSize: data?.pageSize,
        sortBy: data?.sortBy,
        sortOrder: data?.sortOrder,
        fromDate: data?.fromDate,
        toDate: data?.toDate,
        opSeqId: data?.opSeqId,
        doctorId: data?.doctorId,
        patientId: data?.patientId,
        purposeId: data?.purposeId,
        search: data?.search,
      })
        .then((res) => {
          setAppointmentList(res?.data?.result as AppointmentTypes[]);
          setPageCount(res?.data?.total as any);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("error", err);
        });
    }, 300),
    []
  );

  useEffect(() => {
    addColumns();
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortField?.field,
      sortOrder: sortField?.order,
      fromDate: filterData?.from_date,
      toDate: filterData?.to_date,
      opSeqId: filterData?.op_seq_no_id,
      doctorId: filterData?.doctor_id?.id,
      patientId: filterData?.patient_id?.value,
      purposeId: filterData?.purpose_id?.id,
      search: filterData?.search,
    };

    debouncedGetAllAppointment(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortField?.order,
    sortField?.field,
    filterData?.from_date,
    filterData?.to_date,
    filterData?.op_seq_no_id,
    filterData?.doctor_id,
    filterData?.patient_id,
    filterData?.purpose_id,
    filterData?.search,
  ]);

  useEffect(() => {
    getAllConsultationsData();
    getAllDoctorsData();

    if (filterData?.patient_id) {
      setPatientsList([filterData?.patient_id]);
    }
  }, []);

  const appointmentFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 2 },
      children: (
        <AutoComplete
          value={filterData?.purpose_id}
          options={consultationsName}
          inputValue={filterData?.purpose_value}
          name="purpose_id"
          label="Consultation"
          placeholder="Select Consultation"
          onChange={(e: any, newValue: any) =>
            handleAutoCompleteChange(e, newValue, "purpose_id")
          }
          onInputChange={(e: any, newValue: any) =>
            handleAutoCompleteInputChange(e, newValue, "purpose_value")
          }
          disableClearable={false}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <AutoComplete
          value={filterData?.doctor_id}
          inputValue={filterData?.doctor_value}
          options={doctorsName}
          name={"doctor_id"}
          label="Doctor"
          placeholder="Select Doctor"
          onChange={(e: any, newValue: any) =>
            handleAutoCompleteChange(e, newValue, "doctor_id")
          }
          onInputChange={(e: any, newValue: any) =>
            handleAutoCompleteInputChange(e, newValue, "doctor_value")
          }
          disableClearable={false}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <InfiniteScroller
          loadOptions={getAllPatientsData}
          options={patientsList}
          isClearFilter={isClearFilter}
          setIsClearFilter={setIsClearFilter}
          label={"Patient"}
          placeholder={"Select Patient"}
          handleOnChange={handleInputChange}
          name={"patient_id"}
          value={filterData?.patient_id?.value}
          width={"100%"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <TextField
          value={filterData?.op_seq_no_id}
          placeholder={"OP Seq No"}
          label={"OP Seq No"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"op_seq_no_id"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <DatePicker
          label="From Date"
          name="fromDate"
          placeholder="Select Date"
          width="100%"
          maxDate={filterData?.to_date}
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "from_date")
          }
          formControlStyle={{ width: "90%" }}
          format="DD/MM/YYYY"
          value={filterData?.from_date}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <DatePicker
          label="To Date"
          name="toDate"
          placeholder="Select Date"
          width="100%"
          minDate={filterData?.from_date}
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "to_date")
          }
          formControlStyle={{ width: "90%" }}
          format="DD/MM/YYYY"
          value={filterData?.to_date}
        />
      ),
    },
  ];

  // dialog functions

  const handleConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleClearSearch = () => {
    dispatch(
      setHandleClearSearch({
        key: "appointmentList",
        value: "",
        name: "search",
      })
    );
  };

  const handleDelete = async (id: any) => {
    try {
      setLoading(true);
      setIsConfirmationDialogButtonLoading(true);
      await deleteAppointment(id).then((result: any) => {
        const data = {
          page: Number(pageInfo.page) + 1,
          pageSize: pageInfo.pageSize,
          sortBy: sortField?.field,
          sortOrder: sortField?.order,
          fromDate: filterData?.from_date,
          toDate: filterData?.to_date,
          opSeqId: filterData?.op_seq_no_id,
          doctorId: filterData?.doctor_id?.id,
          patientId: filterData?.patient_id?.value,
          purposeId: filterData?.purpose_id?.id,
          search: filterData?.search,
        };

        debouncedGetAllAppointment(data);
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: "Deleted Successfully",
          })
        );
        setLoading(false);

        setIsConfirmationDialogOpen(false);
        setIsConfirmationDialogButtonLoading(false);
        setIdToBeDeleted(null);
      });
    } catch (error: any) {
      const errorData = error?.response?.data?.error_data
        ? error?.response?.data?.error_data
        : null;

      const errorMessage = errorData ? Object.keys(errorData).join(", ") : "";

      setLoading(false);
      setIsConfirmationDialogOpen(false);
      setIsConfirmationDialogButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: errorData
            ? `${error?.response?.data?.errors} ${errorMessage}`
            : `${error?.response?.data?.errors}`,
        })
      );
      console.error("An error occurred:", error);
    }
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };

  return (
    <>
      <Box>
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title="Are you surely want to delete?"
          handleClick={handleConfirmDelete}
          onClose={handleConfirmationDialogClose}
          loading={isConfirmationDialogButtonLoading}
        />
        <CustomFilter
          data={appointmentFilter}
          isFilterEnabled={true}
          isSearchEnabled={true}
          clearFilter={clearFilters}
          searchOnChange={handleInputChange}
          searchName={"search"}
          appliedFilterCount={count}
          clearSearch={handleClearSearch}
          searchValue={filterData?.search}
          addButtonTitle={"Book Appointment"}
          onAddButtonClick={() => navigate("/appointments/booking")}
          {...((can("workhour_list") ||
            can("symptoms_view") ||
            can("print_note_view") ||
            can("pxproducts_view") ||
            can("vaccation_list")) && {
            editButtonTitle: "Master",
            onUpdateButtonClick: () => navigate("/appointments/master"),
          })}
        />
        <DataTable
          loading={loading}
          columns={updatedColumns}
          getRowId={(row: GridRowData) => `${String(row.id)}`}
          rows={appointmentList}
          pageCount={pageCount}
          currentPage={"appointmentList"}
          onAddButtonClick={() => {
            navigate(
              `${RouteUrls.appointmentUrl}${RouteUrls.appointmentsBooking}`
            );
          }}
        />
      </Box>
    </>
  );
};

export default AppointmentsList;
