import React, { useEffect, useState } from "react";
import { Grid, Typography, Button, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../../../../../../../components/shared";
import { PrintIcon } from "../../../../../../../assets/icons";
import { useParams } from "react-router-dom";
import { getAdmissionIpTreatmentsById } from "../../../../../../../services/admissionService";
import moment from "moment";

import { setTreatmentsList } from "../../../../../../../redux/slices/inpatients";
import { RootState } from "../../../../../../../redux/store";
import { useSelector, useDispatch } from "react-redux";

const Treatments = () => {
  const { id }: any = useParams();
  const dispatch = useDispatch();

  const { treatmentsList } = useSelector(
    (state: RootState) => state.inPatients
  );

  // data table variables

  const [initialCount, setInitialCount] = useState(5);
  const [allData, setAllData] = useState<any>([]);
  const [dataGridData, setDataGridData]: any = useState([]);
  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isDataGridExpanded, setIsDataGridExpanded] = useState(false);

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.5,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          S.No
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sNo}
        </Typography>
      ),
      minWidth: 60,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: "type",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Type
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.type}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "items",
      flex: 2,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Items
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.item_name}
        </Typography>
      ),
      minWidth: 300,
      sortable: false,
    },
    {
      field: "quantity",
      flex: 0.5,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Qty
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.quantity}
        </Typography>
      ),
      minWidth: 50,
      sortable: false,
    },
    {
      field: "instructions",
      flex: 2,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Instructions
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.instruction}
        </Typography>
      ),
      minWidth: 240,
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Date
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row?.created_at, ["YYYY-MM-DD HH:mm:ss"]).format(
            "DD/MM/YYYY"
          )}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "ward",
      flex: 2,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Ward
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.floor_name && row?.room_no
            ? `${row?.floor_name} / ${row?.room_no}`
            : null}
        </Typography>
      ),
      minWidth: 360,
      sortable: false,
    },
  ];

  const toggleDataGrid = () => {
    setIsDataGridExpanded((prev) => !prev);
  };

  const getTreatments = async () => {
    try {
      setIsDataTableLoading(true);
      await getAdmissionIpTreatmentsById(id).then((result: any) => {
        const data = result?.data;
        if (data) {
          const modifiedData = data?.map((item: any, index: number) => ({
            ...item,
            sNo: index + 1,
          }));
          dispatch(setTreatmentsList(modifiedData));

          const slicedData = modifiedData.slice(0, initialCount);

          setAllData(modifiedData);
          setDataGridData(slicedData);
        }
        setIsDataTableLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setIsDataTableLoading(false);
    }
  };

  useEffect(() => {
    if (isDataGridExpanded) {
      setDataGridData(allData);
    } else {
      const slicedData = allData.slice(0, 4);
      setDataGridData(slicedData);
    }
  }, [isDataGridExpanded]);

  useEffect(() => {
    if (id && !treatmentsList) {
      setIsDataTableLoading(true);
      setTimeout(getTreatments, 2000);
    } else {
      const slicedData = treatmentsList?.slice(0, initialCount);
      setAllData(treatmentsList);
      setDataGridData(slicedData);
    }
  }, []);

  return (
    <Grid
      sx={{
        width: { xs: "98%", md: "49%" },
        minWidth: { xs: "98%", md: "49%" },
        boxShadow: "3px 5px 15px 0px #23232314",
        borderRadius: "5px",
        height: "auto",
        maxHeight: isDataGridExpanded
          ? `${200 + allData?.length * 50}px`
          : `${200 + initialCount * 50}px`,
        m: "20px 0px",
        border: 1,
        borderColor: "greyScale.lighter",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "12px 16px 0px",
        }}
      >
        <Typography variant="h2" sx={{ color: "primary.main" }}>
          Treatments
        </Typography>
        <Button
          sx={{
            color: "textPrimary.main",
            backgroundColor: "#F4F4F4",
            p: "10px",
            fontWeight: "500",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
          onClick={toggleDataGrid}
        >
          View All
        </Button>
      </Grid>
      <Grid
        sx={{
          p: "12px 16px 18px",
          width: "100%",
        }}
      >
        <DataTable
          loading={isDataTableLoading}
          columns={columns}
          rows={dataGridData}
          getRowId={(row: any) => `${String(row?.sNo)}`}
          tableOnly={true}
        />
      </Grid>
    </Grid>
  );
};

export default Treatments;
