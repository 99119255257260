import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import ItemRow from "./ItemRow";

const ItemRowMemoized = React.memo(ItemRow);

const styles = {
  tableContainerStyle: {
    borderRadius: "5px",
    boxShadow: "none",
    overflow: "hidden",
    width: "100%",
    overflowX: { xs: "scroll", lg: "hidden" },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableStyle: {
    border: "1px solid",
    borderColor: "var(--table-border)",
    width: "100%",
    minWidth: "1200px",
    overflowX: "auto",
    "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
    "& .MuiTableRow-head": {
      backgroundColor: "var(--table-header)",
    },
    " & .MuiTableBody-root": {
      border: 0,
    },
    "& .MuiTableRow-root": {
      height: "45px !important",
      maxHeight: "34px !important",
      minHeight: "0px",
      lineHeight: "0px",
      border: 0,
    },

    "& .MuiTableCell-root": {
      minHeight: "0px",
    },

    "& .MuiTableCell-body": {
      height: "12px !important",
      maxHeight: "32px !important",
      minHeight: "0px",
      lineHeight: "0px",
      p: "0px 0px",
      fontSize: "12px",
      fontWeight: "400",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "var(--table-border)",
      overflow: "hidden",
    },

    "& .MuiTableCell-body:has(.Mui-focused)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },

    "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
      borderColor: "primary.main",
      borderWidth: "2px",
      p: "0px",
      height: "31px",
    },
    "& .deleteIconGrid": {
      cursor: "pointer",
      position: "absolute",
      right: {
        xs: "0px",
        sm: "0px",
        md: "0px",
        lg: "-2vw",
      },
      bottom: "5px",
      mb: "10px",
    },
  },
  header: {
    backgroundColor: "var(--table-header)",
  },
  headerStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
    borderBottom: "1px solid var(--table-border)",
    borderRight: "1px solid var(--table-border)",
    padding: "12px 12px 12px 20px",
    marginLeft: "8px",
  },
};

type TableBodyComponentProps = {
  rows?: any;
  errors?: any;
  columns?: any;
  onChange: (event: any, index: number) => void;
};

type productTableProps = {
  data?: any;
  onChange: (event: any, index: number) => void;
};

const TableHeader = React.memo((props: { columns: any }) => {
  const { columns } = props;

  return (
    <TableHead sx={{ ...styles.header }}>
      <TableRow>
        {columns?.map((column: any) => (
          <TableCell
            align={column.textAlignment}
            sx={{ ...column.headerStyle, width: `${column.width}%` }}
          >
            {column?.header}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

const TableBodyComponent = React.memo((props: TableBodyComponentProps) => {
  const { rows, columns, errors, onChange } = props;
  return (
    <TableBody
      sx={{
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderBottomColor: "#207DFF !important",
        },
      }}
    >
      {rows?.map((row: any, rowIndex: number) => (
        <ItemRowMemoized
          row={row}
          index={rowIndex}
          columns={columns}
          error={errors[rowIndex]}
          onChange={onChange}
        />
      ))}
    </TableBody>
  );
});

const ProductTable = (props: productTableProps) => {
  const { data, onChange } = props;
  const [errors, setErrors] = useState([]);

  const columns = [
    {
      header: "S.No",
      textAlignment: "left",
      width: 10,
      name: "sNo",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Product Name",
      textAlignment: "left",
      width: 25,
      name: "product_id",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Indent",
      textAlignment: "left",
      width: 15,
      name: "indent",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Ptr",
      textAlignment: "left",
      width: 15,
      name: "ptr",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Value",
      textAlignment: "left",
      width: 20,
      name: "value",
      headerStyle: styles.headerStyle,
    },
    {
      header: "Received Qty",
      textAlignment: "left",
      width: 15,
      name: "received_qty",
      headerStyle: styles.headerStyle,
    },
  ];

  return (
    <Box
      style={{
        overflowX: "auto",
        width: "98.5%",
        marginTop: "30px",
      }}
      px={1}
    >
      <TableContainer sx={{ ...styles.tableContainerStyle }}>
        <Table sx={{ ...styles.tableStyle }}>
          <TableHeader columns={columns} />
          <TableBodyComponent
            errors={errors}
            rows={data}
            columns={columns}
            onChange={onChange}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};
export default ProductTable;
