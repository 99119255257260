import React from "react";
import { Grid } from "@mui/material";
import BillProducts from "./BillProducts";
import "./index.css";

const AddAndEdit = () => {
  return (
    <Grid
      className="billing"
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BillProducts />
    </Grid>
  );
};

export default AddAndEdit;
