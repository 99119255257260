import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { SaveButton, PageLoader } from "../../../../components/basic";
import { ConfirmationDialog, DataTable } from "../../../../components/shared";
import { billProductsConst } from "../../../../constants/displayText";
import Info from "./Info";
import Summary from "./Summary";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  approvePurchaseTempBillById,
  cancelPurchaseBillById,
  cancelPurchaseTempBillById,
  getPurchaseBillById,
  getTaxOptions,
} from "../../../../services/procurementService";
import moment from "moment";
import { formula } from "../../../../utils/CalculationUtils";
import { setSnackBarSuccess } from "../../../../redux/slices/snackbar";
import { RouteUrls } from "../../../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Can } from "../../../../utils/PermissionUtils";
type GridRowData = Record<string, unknown>;

const { procurementUrl, purchaseBillUrl } = RouteUrls;
const {
  calculateDiscountAmount,
  calculateAmount,
  calculateRoundedOff,
  calculateSubTaxes,
  calculateTaxAmount,
  calculateTotalDiscountAmount,
} = formula.purchaseBill;

const BillProducts = () => {
  const {
    productName,
    usedQty,
    usedFree,
    packType,
    qty,
    originalQty,
    free,
    discount,
    batchNo,
    expiryDate,
    tax,
    ptr,
    mrp,
    amount,
  } = billProductsConst;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [searchParams] = useSearchParams();
  const purchase_approval: any = searchParams.get("isApprovalList");

  const [billProducts, setBillProducts] = useState<any>([]);
  const [info, setInfo] = React.useState<any>({});

  const columns: GridColDef[] = [
    {
      field: "product_name",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {productName}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.product_name}</Typography>
      ),
      sortable: false,
      minWidth: 150,
    },
    {
      field: "pack_type",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {packType}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.pack_type}</Typography>
      ),
      sortable: false,
      minWidth: 150,
    },
    {
      field: "qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {qty}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.qty}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "org_qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {originalQty}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.original_qty}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "free",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {free}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.free || 0}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    ...(info?.discount_setting === 0 || info?.discount_setting === 2
      ? [
          {
            field: "discount",
            flex: 1,
            renderHeader: () => (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "600",
                    color: "textPrimary.main",
                  }}
                >
                  {discount}
                </Typography>
              </Grid>
            ),
            renderCell: ({ row }: any) => (
              <Typography variant="h6">
                {row.discount || 0.0} {row.discount_type}
              </Typography>
            ),
            sortable: false,
            minWidth: 100,
          },
        ]
      : []),
    {
      field: "batch_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {batchNo}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.batch_no}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "expiry_date",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {expiryDate}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.expiry_date}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {tax}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.tax}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "ptr",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {ptr}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.ptr}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "mrp",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {mrp}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.mrp}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {amount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.amount}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
  ];

  const [billingSummary, setBillingSummary] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isApprovalLoading, setIsApprovalLoading] = useState<boolean>(false);
  const [isRejectLoading, setIsRejectLoading] = useState<boolean>(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<{
    isOpen: boolean;
    content: string;
    type: string | null;
  }>({
    isOpen: false,
    content: "",
    type: null,
  });

  const getTaxOption = async (product: any) => {
    const res: any = await getTaxOptions();

    const tax = res?.data?.find((ele: any) => ele.id === product?.tax_type);

    if (tax) {
      tax.name = `${tax.name}-${tax.tax_rate}%`;
    }

    return tax;
  };

  const handleCancel = () => {
    setIsLoading(true);
    cancelPurchaseBillById(id as string)
      .then((res: any) => {
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: res.message,
          })
        );
        setIsLoading(false);
        navigate(`${procurementUrl}${purchaseBillUrl}`);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleApproval = () => {
    setIsApprovalLoading(true);
    approvePurchaseTempBillById(id as string)
      .then((res: any) => {
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: res.message,
          })
        );
        setIsApprovalLoading(false);
        navigate(`${procurementUrl}${purchaseBillUrl}`);
        setIsOpenConfirmModal({ isOpen: false, content: "", type: null });
      })
      .catch((err) => {
        console.log(err);
        setIsApprovalLoading(false);
      });
  };
  const handleReject = () => {
    setIsRejectLoading(true);
    cancelPurchaseTempBillById(id as string)
      .then((res: any) => {
        dispatch(
          setSnackBarSuccess({
            snackBarMessage: res.message,
          })
        );
        setIsRejectLoading(false);
        setIsOpenConfirmModal({ isOpen: false, content: "", type: null });
        navigate(`${procurementUrl}${purchaseBillUrl}`);
      })
      .catch((err) => {
        console.log(err);
        setIsRejectLoading(false);
      });
  };

  const getPurchaseBillData = async (id: string | number) => {
    try {
      setIsLoading(true);
      const res: any = await getPurchaseBillById(id, purchase_approval);
      if (res.data) {
        const { bill, validation, bill_products } = res.data;
        // const bill_products = purchase_approval
        //   ? res?.data?.billproducts
        //   : res?.data?.bill_products;
        const formattedBillProducts = await Promise.all(
          bill_products?.map(async (ele: any) => {
            console.log(
              "ele",
              ele,
              purchase_approval,
              purchase_approval
                ? `${ele.taxname}-${ele.tax_id}%`
                : await getTaxOption(ele)
            );

            const qty = purchase_approval
              ? ele.original_qty
              : Number(ele.original_qty) / Number(ele.pack_type) || 0;
            const tax = `${ele.taxname}-${ele.tax_id}%`;
            const discount = ele.discount || 0;
            const row_discount_type = ele.discount_type || 0;
            const discount_amount = calculateDiscountAmount({
              qty,
              p_rate: Number(ele.p_rate) || 0,
              discount,
              row_discount_type,
            });
            const amount = calculateAmount({
              qty,
              p_rate: Number(ele.p_rate) || 0,
              discount_amount,
            });
            const tax_amount =
              Number(
                calculateTaxAmount(
                  {
                    tax: { tax_rate: ele.tax_id },
                    qty,
                    p_rate: Number(ele.p_rate) || 0,
                    discount_amount,
                  },
                  Number(bill.tax_settings) === 1 ? "exclusive" : "inclusive"
                )
              ) || 0;
            return {
              ...ele,
              ptr: ele.p_rate,
              qty,
              tax,
              amount,
              discount_amount,
              expiry_date: ele.expires_at
                ? moment(ele.expires_at, "YYYY-MM-DD").format("DD/MM/YYYY")
                : null,
              row_discount_type: ele.discount_type,
              product_name: ele.product_name,
              tax_amount,
            };
          })
        );

        setBillProducts(formattedBillProducts);
        setBillingSummary({
          goods_value: Number(bill.orig_goods_val),
          total_discount_amount:
            calculateTotalDiscountAmount(formattedBillProducts) || 0,
          total_tax: Number(bill.gst_amt)?.toFixed(2),
          bill_total: (
            Number(bill.orig_goods_val) + Number(bill.gst_amt) || 0
          )?.toFixed(2),
          bill_discount: bill.discount_val,
          bill_discount_type: bill.discount_type,
          bill_discount_amount: bill.discount_amt?.toFixed(2),
          rounded_off: calculateRoundedOff({
            bill_total: Number(bill.orig_goods_val) + Number(bill.gst_amt) || 0,
            bill_discount_amount: Number(bill.discount_amt) || 0,
          }),
          adjustment: Number(bill.adj_amt)?.toFixed(2),
          net_amount: Number(bill.bill_amt)?.toFixed(2) || 0,
          sub_taxes:
            calculateSubTaxes(
              formattedBillProducts?.map((ele: any) => ({
                ...ele,
                tax: {
                  tax_rate: ele.tax_id,
                  sub_taxes: purchase_approval ? ele.subtax : ele.sub_tax,
                },
              }))
            ) || 0,
          validation,
        });
        setInfo({
          id: bill.id,
          is_cancel: bill.is_cancel,
          supplier_name: bill.name,
          department: bill.department,
          invoice_no: bill.invoice_no,
          invoice_value: bill.invoice_value,
          invoice_file: bill.invoice_file,
          invoice_date: bill.invoice_date
            ? moment(bill.invoice_date, "YYYY-MM-DD HH:mm:ss").format(
                "DD/MM/YYYY hh:mm:ss A"
              )
            : null,
          payment_due_date: bill.due_date
            ? moment(bill.due_date, "YYYY-MM-DD").format("DD/MM/YYYY")
            : null,
          status: bill.status,
          discount_setting: Number(bill.discount_setting),
        });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchaseBillData(id);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Box>
          <Info info={info} />
          <Box sx={{ m: "20px 0px" }}>
            <DataTable
              columns={columns}
              rows={billProducts}
              getRowId={(row: GridRowData) => `${String(row.id)}`}
              tableOnly={true}
              customizedTable={true}
            />
            <Box display={"flex"} width={"100%"} padding={"10px 0px"}>
              <Grid
                sx={{
                  width: "45%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Grid
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                    Discount Value :
                  </Typography>
                </Grid>
                <Typography
                  sx={{
                    fontSize: "13px",
                    fontWeight: "600",
                    mr: "1%",
                    textAlign: "left",
                  }}
                >
                  {billingSummary?.total_discount_amount?.toFixed(2)}
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: {
                    xs: "65%",
                  },
                }}
              >
                <Grid
                  sx={{
                    marginRight: "10px",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                    Goods Value :
                  </Typography>
                </Grid>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "600", mr: "2%" }}
                >
                  {billingSummary?.goods_value?.toFixed(2)}
                </Typography>
              </Grid>
            </Box>
          </Box>
          <Summary billingSummary={billingSummary} info={info} />
          {purchase_approval && !info.status && (
            <Box>
              {
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {Can("purchasebill_temp_approval") && (
                    <SaveButton
                      sx={{
                        color: "#fff",
                        backgroundColor: "#47a44b",
                        borderColor: "#39843c",
                        boxShadow:
                          "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
                        margin: "0.3125rem 10px",
                        fontSize: ".75rem",
                        fontWeight: 400,
                        lineHeight: 1.428571,
                        cursor: "pointer",
                        borderRadius: "30px",
                        "&:hover": {
                          backgroundColor: "#47a44b",
                          borderColor: "#39843c",
                        },
                      }}
                      loading={isApprovalLoading}
                      disabled={isRejectLoading}
                      buttonText="Approval"
                      handleClick={() =>
                        setIsOpenConfirmModal({
                          isOpen: true,
                          content: "Do you want to approve this bill?",
                          type: "approval",
                        })
                      }
                    />
                  )}

                  {Can("purchasebill_temp_reject") && (
                    <SaveButton
                      sx={{
                        color: "#fff",
                        backgroundColor: "#f44336",
                        borderColor: "#f44336",
                        boxShadow:
                          "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
                        padding: "12px 30px",
                        margin: "0.3125rem 1px",
                        fontSize: ".75rem",
                        fontWeight: 400,
                        lineHeight: 1.428571,
                        cursor: "pointer",
                        borderRadius: "30px",
                        "&:hover": {
                          backgroundColor: "#f44336",
                          borderColor: "#f44336",
                        },
                      }}
                      loading={isRejectLoading}
                      disabled={isApprovalLoading}
                      buttonText="Reject"
                      handleClick={() =>
                        setIsOpenConfirmModal({
                          isOpen: true,
                          content: "Do you want to reject this bill?",
                          type: "reject",
                        })
                      }
                    />
                  )}
                </Box>
              }
            </Box>
          )}
          {!purchase_approval && (
            <>
              {!billingSummary?.validation?.is_adj_done &&
                !billingSummary?.validation?.is_free_return_filed &&
                !billingSummary?.validation?.is_return_filed &&
                !billingSummary?.validation?.is_sale_done &&
                !info?.is_cancel &&
                Can("purchasebill_cancel") && (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        color: "#fff",
                        backgroundColor: "#f08f00",
                        borderColor: "#c27400",
                        boxShadow:
                          "0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12)",
                        padding: "12px 30px",
                        margin: "0.3125rem 1px",
                        fontSize: ".75rem",
                        fontWeight: 400,
                        lineHeight: 1.428571,
                        cursor: "pointer",
                        borderRadius: "30px",
                      }}
                      onClick={handleCancel}
                    >
                      X Cancel
                    </Box>
                  </Box>
                )}
            </>
          )}

          <Box
            sx={{
              width: "40vw",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              gap: 2,
              m: "20px 0px",
            }}
          >
            <SaveButton
              buttonText="Back"
              sx={{ width: "100px", height: "42px" }}
            />
          </Box>
          <ConfirmationDialog
            open={isOpenConfirmModal.isOpen}
            title={isOpenConfirmModal.content}
            confirmText="Okay"
            handleClick={
              isOpenConfirmModal.type === "approval"
                ? handleApproval
                : handleReject
            }
            onClose={() =>
              setIsOpenConfirmModal({ isOpen: false, content: "", type: null })
            }
            loading={isApprovalLoading || isRejectLoading}
          />
        </Box>
      )}
    </>
  );
};

export default BillProducts;
