import { Box } from "@mui/material";
import {
  TrimesterOne,
  TrimesterTwo,
  TrimesterThree,
  CreateFollowUpIcon,
} from "../../../../assets/icons";
import { useNavigate } from "react-router-dom";
type FollowUpTypes = {
  id: number | string;
  date_of_visit: string;
  trimester_week: number | string;
  trimester: string;
};
export default function FollowUpHistory({
  followUpDetail,
  handleFollowUpHistory,
  handleOpenAddFollowUp,
}: {
  followUpDetail: any;
  handleFollowUpHistory: (id: string | number) => void;
  handleOpenAddFollowUp: () => void;
}) {
  const trimesterType: { [key: string]: any } = {
    "First Trimester": () => <TrimesterOne />,
    "Second Trimester": () => <TrimesterTwo />,
    "Third Trimester": () => <TrimesterThree />,
  };
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          marginBottom: "10px",
          cursor: "pointer",
        }}
      >
        <CreateFollowUpIcon
          onClick={() => navigate("/gynecology/add-followup")}
        />
      </Box>
      {followUpDetail.map((ele: FollowUpTypes) => (
        <Box
          sx={{
            display: "flex",
            gap: "23px",
            padding: "9px",
            boxShadow: "0px 2px 4px 2px rgba(35, 35, 35, 0.08)",
            borderRadius: "10px",
            marginBottom: "12px",
            cursor: "pointer",
          }}
          onClick={() => handleFollowUpHistory(ele.id)}
        >
          <Box>{trimesterType[ele.trimester]()}</Box>
          <Box sx={{ marginTop: "10px" }}>
            <Box sx={{ fontWeight: 600, fontSize: "16px" }}>
              {ele.date_of_visit}
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
}
