import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { commonDateFormatter,CurrentDate } from "../../utils/DateTimeFormatUtils";


export interface PaginationValue {
  page: number;
  pageSize: number;
  sortBy?: string | null;
  sortOrder?: string | null;
  filterData?:any;
  count?:number;
  sortedField?:{ order: string | null; field: string | null; }
  
}

export type PageType =
  | "dashboard"
  | "appointmentList"
  | "billingItemsList"
  | "billingItemsMaster"
  | "patientsAccount"
  | "billList"
  | "appointmentMasterSymptomList"
  | "appointmentMasterProductsList"
  | "appointmentMasterConsultationType"
  | "workingHoursList"
  | "vacationList"
  | "printNoteList"
  | "appointmentMasterWorkingHours"
  | "procurementIndentList"
  | "procurementMasterProducts"
  | "procurementMasterSuppliers"
  | "procurementMasterDepartments"
  | "procurementMasterManufacture"
  | "procurementMasterMajorDrugs"
  | "procurementMasterUnits"
  | "procurementMasterDosageForm"
  | "procurementMasterDrugClarification"
  | "pharmacySalesBill"
  | "procurementStockAdjustmentView"
  | "procurementStockAdjustment"
  | "procurementGoodsIssue"
  | "procurementPurchaseBill"
  | "procurementPurchaseOrders"
  | "procurementPurchasePayment"
  | "PurchaseOrders"
  | "goodsIssue"
  | "stockAdjustment"
  | "paymentList"
  | "gynecologyList"
  | "campaignList"
  | "inhouseLeads"
  | "investigationTemplateMaster"
  | "ipAdmissionsList"
  | "procurementMasterCustomers"
  | "pharmacyBillDraft"
  | "b2bAdjustmentList"
  | "billDraft"
  | "labBills"
  | "laboratoryPurchaseBill"
  | "dashboard"
  | "appointmentList"
  | "procurementIndentList"
  | "procurementMasterProducts"
  | "procurementMasterSuppliers"
  | "procurementMasterDepartments"
  | "procurementMasterManufacture"
  | "procurementMasterMajorDrugs"
  | "procurementMasterDosageForm"
  | "procurementMasterUnits"
  | "procurementMasterDrugClarification"
  | "pharmacySalesBill"
  | "procurementStockAdjustmentView"
  | "procurementStockAdjustment"
  | "procurementGoodsIssue"
  | "procurementPurchaseBill"
  | "procurementPurchaseOrders"
  | "procurementPurchasePayment"
  | "PurchaseOrders"
  | "goodsIssue"
  | "stockAdjustment"
  | "paymentList"
  | "adjustmentList"
  | "investigationTemplateMaster"
  | "labBills"
  | "laboratoryMasterProducts"
  | "laboratoryMasterSuppliers"
  | "laboratoryMasterTax"
  | "laboratoryMasterTestProducts"
  | "laboratoryPurchaseBill"
  | "procurementMasterCustomer"
  | "procurementMasterCustomers"
  | "billDraft"
  | "ipAdmissionsList"
  | "procurementMasterCustomer"
  | "pharmacyBillDraft"
  | "b2bAdjustmentList"
  | "ipAdmissionsList"
  | "b2bPayment"
  | "patientsList"
  | "vaccineMasterList"
  | "pregnancyList"
  | "operationTheatreList";

export type IPagination = Record<PageType, PaginationValue>;

interface PageActionPayloadType {
  key: PageType;
  value: number | string | undefined | null;
}

interface FilterDataActionPayloadType {
  key: PageType;
  name: string;
  value: any;
}

interface sortedFieldPayloadType {
  key: PageType;
  value:{ order: string | null; field: string | null; }

}

const appointmentListFilterData = {
  doctor_id: null,
  doctor_value:"",
  purpose_id: null,
  purpose_value:"",
  patient_id: "",
  patient_id_input: "",
  op_seq_no_id: "",
  from_date: new Date(),
  to_date: new Date(),
  search: ""
}

const paymentListFilterData = {
  patient_id: "",
  term: "",
  from_date: commonDateFormatter(new Date(), "DD/MM/YYYY"),
  to_date: commonDateFormatter(new Date(), "DD/MM/YYYY"),
  search: "",
}

const adjustmentList = {
  search:"",
};


const pharmacySalesBillFilterData = {
  department: null,
  uhid: null,
  bill_type: "",
  pay_type: "",
  status: "",
  bill_no: "",
  from_date: commonDateFormatter(new Date(), "DD/MM/YYYY"),
  to_date: commonDateFormatter(new Date(), "DD/MM/YYYY"),
  search: "",
}

const billingItemListFilterData = {
  department: "",
  name: "",
  description: "",
  price: "",
  search: "",
};

const billListFilterData = {
  doctor_id: "",
  department: "",
  uhid: "",
  bill_no: "",
  from_date: new Date(),
  to_date: new Date(),
  item: "",
  terms: "",
  status: "",
  search: "",
}


const procurementPurchaseBillFilterData = {
  supplier_id: "",
  product_name: "",
  bill_no: "",
  from_date: new Date(),
  to_date: new Date(),
  type: "bill_list",
  search: "",
}

const procurementPurchasePayment = {
  search:"",
}

type procurementIndentFilterDataType = {
  indent_no: string;
  from_date: string | Date;
  to_date: string | Date;
  search: string | number | null ;
};

const procurementIndentFilterData : procurementIndentFilterDataType = {
  indent_no: "",
  from_date:new Date(),
  to_date:new Date(),
  search:""
};

type PurchaseOrderFilterDataType = {
  search : string | number | null
}

const PurchaseOrderFilterData : PurchaseOrderFilterDataType = {
  search : ""
}

const goodsIssue = {
  invoice_no: "",
  product: "",
  from_date: CurrentDate,
  to_date:CurrentDate,
  type: "bill_list",
  search: "",
}


const stockAdjustmentFilterData = {
  from_date:new Date(),
  to_date:new Date(),
  invoice_no: "",
  search:""

}

type b2bPaymentFilterDataType = {
  search : string | number | null
}

const b2bPaymentFilterData : b2bPaymentFilterDataType = {
  search : ""
}

type b2bAdjustmentListFilterDataType = {
  search : string | number | null
}


const b2bAdjustmentListFilterData : b2bAdjustmentListFilterDataType = {
  search : ""
}

const procurementStockAdjustmentFilterData  = {
  product: "",
  search:"",
}

const patientsListFilterData = {
  patient_uhid: "",
  search: "",
};


const gynecologyListFilterData = {
  patient_id: "",
  trimester_week: "",
  last_visit_date: new Date(),
  search: "",
}

const ipAdmissionsListFilterData = {
  patient_id: "",
  ip_no: "",
  status: 1,
  from_date:new Date(),
  to_date:new Date(),
  search: "",
}




const initialState: IPagination = {
  dashboard: { page: 0, pageSize: 10 },
  appointmentList: { page: 0, 
    pageSize: 10,
    filterData:appointmentListFilterData,
    count: 0,
    sortedField:{
    order: null,
    field: null,
  }},
  billingItemsList: { page: 0, pageSize: 10,filterData:billingItemListFilterData,count:0,sortedField:{
    order: null,
    field: null,
  } },
  billingItemsMaster: { page: 0, pageSize: 10 },
  patientsAccount: { page: 0, pageSize: 10 },
  billList: { page: 0, pageSize: 10,filterData:billListFilterData,count:0,sortedField:{
    order: null,
    field: null,
  } },
  appointmentMasterSymptomList: { page: 0, pageSize: 10 },
  appointmentMasterProductsList: { page: 0, pageSize: 10 },
  appointmentMasterConsultationType: { page: 0, pageSize: 10 },
  workingHoursList: { page: 0, pageSize: 10 },
  vacationList: { page: 0, pageSize: 10 },
  printNoteList: { page: 0, pageSize: 10 },
  appointmentMasterWorkingHours: { page: 0, pageSize: 10 },
  procurementIndentList: { page: 0, pageSize: 10,filterData:procurementIndentFilterData,count:0,sortedField:{
    order: null,
    field: null,
  }},
  procurementMasterProducts: { page: 0, pageSize: 10 },
  procurementMasterSuppliers: { page: 0, pageSize: 10 },
  procurementMasterDepartments: { page: 0, pageSize: 10 },
  procurementMasterManufacture: { page: 0, pageSize: 10 },
  procurementMasterMajorDrugs: { page: 0, pageSize: 10 },
  procurementMasterDosageForm: { page: 0, pageSize: 10 },
  procurementMasterUnits: { page: 0, pageSize: 10 },
  procurementMasterDrugClarification: { page: 0, pageSize: 10 },
  pharmacySalesBill: { page: 0, pageSize: 10, filterData:pharmacySalesBillFilterData,count:0,sortedField:{
    order: null,
    field: null,
  } },
  procurementStockAdjustmentView: { page: 0, pageSize: 10 },
  procurementStockAdjustment: { page: 0, pageSize: 10,filterData:procurementStockAdjustmentFilterData,count:0,sortedField:{
    order: null,
    field: null,
  }},
  procurementPurchaseBill: { page: 0, pageSize: 10,filterData:procurementPurchaseBillFilterData,count:0,sortedField:{
    order: null,
    field: null,
  }},
  procurementGoodsIssue: { page: 0, pageSize: 10 },
  procurementPurchaseOrders: { page: 0, pageSize: 10 },
  procurementPurchasePayment: { page: 0, pageSize: 10, filterData:procurementPurchasePayment,sortedField:{
    order: null,
    field: null,
  }},
  PurchaseOrders: { page: 0, pageSize: 10,filterData:PurchaseOrderFilterData,count:0,sortedField:{
    order: null,
    field: null,
  }},
  goodsIssue: { page: 0, pageSize: 10,filterData:goodsIssue,count:0,sortedField:{
    order: null,
    field: null,
  }},
  stockAdjustment: { page: 0, pageSize: 10, filterData:stockAdjustmentFilterData,count:0,sortedField:{
    order: null,
    field: null,
  }},
  paymentList: { page: 0, pageSize: 10,filterData:paymentListFilterData,  sortedField:{
    order: null,
    field: null,
  },count:0},
  campaignList: { page: 0, pageSize: 10 },
  inhouseLeads: { page: 0, pageSize: 10 },

  adjustmentList: { page: 0, pageSize: 10,filterData:adjustmentList,sortedField:{
    order: null,
    field: null,
  } },
  gynecologyList: { page: 0, pageSize: 10,filterData:gynecologyListFilterData,sortedField:{
    order: null,
    field: null,
  },count:0},
  investigationTemplateMaster: { page: 0, pageSize: 10 },
  labBills: { page: 0, pageSize: 10 },
  laboratoryMasterProducts: { page: 0, pageSize: 10 },
  laboratoryMasterSuppliers: { page: 0, pageSize: 10 },
  laboratoryMasterTax: { page: 0, pageSize: 10 },
  laboratoryMasterTestProducts: { page: 0, pageSize: 10 },
  procurementMasterCustomer: { page: 0, pageSize: 10 },
  laboratoryPurchaseBill: { page: 0, pageSize: 10 },
  procurementMasterCustomers: { page: 0, pageSize: 10 },
  ipAdmissionsList: { page: 0, pageSize: 10,filterData:ipAdmissionsListFilterData,sortedField:{
    order: null,
    field: null,
  },count:0 },
  pharmacyBillDraft: { page: 0, pageSize: 10 },
  b2bAdjustmentList: { page: 0, pageSize: 10, filterData:b2bAdjustmentListFilterData,sortedField:{
    order: null,
    field: null,
  } },
  billDraft: { page: 0, pageSize: 10 },
  patientsList: { page: 0, pageSize: 10,filterData:patientsListFilterData,sortedField:{
    order: null,
    field: null,
  },count:0},
  b2bPayment:{ page: 0, pageSize: 10, filterData:b2bPaymentFilterData,sortedField:{
    order: null,
    field: null,
  }},
  operationTheatreList: { page: 0, pageSize: 10 },
  vaccineMasterList: { page: 0, pageSize: 10 },
  pregnancyList: { page: 0, pageSize: 10 },


};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setCurrentPage: (
      state,
      { payload }: PayloadAction<PageActionPayloadType>
    ) => {
      state[payload.key].page = payload.value as number;
    },
    setRowsPerPage: (
      state,
      { payload }: PayloadAction<PageActionPayloadType>
    ) => {
      state[payload.key].pageSize = payload.value as number;
    },
    setSortBy: (state, { payload }: PayloadAction<PageActionPayloadType>) => {
      state[payload.key].sortBy = payload.value as string;
    },
    setSortOrder: (
      state,
      { payload }: PayloadAction<PageActionPayloadType>
    ) => {
      state[payload.key].sortOrder = payload.value as string;
    },
    setFilterDataValues:(state,{payload}: PayloadAction<FilterDataActionPayloadType>)=>{

      if( payload.name === "clearFilter"){
      state[payload.key].filterData = {...payload.value};
      state[payload.key].count = 0;

      }else{
        const newFilter =  {...state[payload.key].filterData,[payload.name]: payload.value};
        state[payload.key].filterData =newFilter;
        const excludeKeys = ["purpose_value","doctor_value","search"];
        const newAppliedFilterCount = Object.entries(newFilter)
        .filter(([key]) => !excludeKeys.includes(key))
        .map(([, value]) => value)
        .filter(value => value !== null && value !== undefined && value !== false && !Number.isNaN(value) && value !== '')
        .length;
          if(payload.name === "search"){
            return;
          }        
        state[payload.key].count = newAppliedFilterCount;
      }

  


    },
    setSortField:(state,{payload}:PayloadAction<sortedFieldPayloadType>)=>{
      state[payload.key].sortedField = {...payload.value} 
    },
    setHandleClearSearch:(state,{payload}: PayloadAction<FilterDataActionPayloadType>)=>{
      state[payload.key].filterData = {...state[payload.key].filterData,[payload.name]:payload.value};
    }
  },
});

export const { setCurrentPage, setRowsPerPage, setSortBy, setSortOrder,setSortField,setHandleClearSearch,setFilterDataValues } = paginationSlice.actions;

export default paginationSlice.reducer;
