import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { SelectPlusIcon, SelectedIcon } from "../../../../../assets/icons";
import { DialogWrapper } from "../../../../../components/shared";
import { useEffect, useRef, useState } from "react";

export default function HistoryOfDisease({
  symptomsList,
  isHistoryOfDiseaseOpen,
  handleSubmit,
  currentHistoryOfDisease,
  setIsHistoryOfDiseaseOpen,
}: any) {
  const [historyOfDiseaseData, setHistoryOfDiseaseData] = useState([]);
  const [currentDisease, setCurrentDisease] = useState<any>(null);
  const currentActive: any = useRef();
  const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    currentActive.current = { name: e.target.value, checked: e.target.checked };
    const isAlreadyExist = historyOfDiseaseData.find(
      (ele: any) => ele.relationship === e.target.value
    );
    if (!isAlreadyExist) {
      const newHistoryOfDiseaseData: any = [
        ...historyOfDiseaseData,
        {
          relationship: e.target.value,
          disease: [],
        },
      ];
      setHistoryOfDiseaseData(newHistoryOfDiseaseData);
    }
    const currentDiseaseData = historyOfDiseaseData.find(
      (ele: any) => ele.relationship === currentActive.current?.name
    );
    setCurrentDisease(currentDiseaseData);
  };
  const handleAddDisease = (idx: string | number) => {
    const selectedRelationShipIndex = historyOfDiseaseData.findIndex(
      (ele: any) => ele?.relationship === currentActive.current.name
    );
    if (selectedRelationShipIndex !== -1) {
      const newHistoryOfDiseaseData: any = [...historyOfDiseaseData];
      const selectedRelationShip = {
        ...newHistoryOfDiseaseData[selectedRelationShipIndex],
      };
      const idxIndex = selectedRelationShip.disease.indexOf(idx);
      if (idxIndex !== -1) {
        selectedRelationShip.disease.splice(idxIndex, 1);
      } else {
        selectedRelationShip.disease.push(idx);
      }
      newHistoryOfDiseaseData[selectedRelationShipIndex] = selectedRelationShip;

      setHistoryOfDiseaseData(newHistoryOfDiseaseData);
    }
    const currentDiseaseData = historyOfDiseaseData.find(
      (ele: any) => ele.relationship === currentActive.current?.name
    );
    setCurrentDisease(currentDiseaseData);
  };
  useEffect(() => {
    if (currentHistoryOfDisease.length > 0) {
      setHistoryOfDiseaseData(currentHistoryOfDisease);
    }
    if (currentHistoryOfDisease.length === 0) {
      setHistoryOfDiseaseData([]);
      setCurrentDisease(null);
      currentActive.current = null;
    }
  }, []);
  const handleCancel = () => {
    setIsHistoryOfDiseaseOpen(!isHistoryOfDiseaseOpen);
    setHistoryOfDiseaseData([]);
    // setCurrentDisease(null);
    currentActive.current = null;
  };
  return (
    <>
      <DialogWrapper
        open={isHistoryOfDiseaseOpen}
        title={"History of Disease"}
        confirmText="Save"
        cancelText="Cancel"
        handleClick={() => {
          const currentFiledData = historyOfDiseaseData.filter(
            (ele: { relationship: string; disease: number[] }) =>
              ele.disease.length > 0
          );
          handleSubmit(currentFiledData);
        }}
        onClose={() => handleCancel()}
      >
        <Box>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Relationship
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="relationship"
              onChange={handleCheckBoxChange}
              value={currentActive?.current?.name}
            >
              <FormControlLabel
                value="Father"
                control={<Radio />}
                label="Father"
                sx={{ fontSize: "18px", weight: 500 }}
              />
              <FormControlLabel
                value="Mother"
                control={<Radio />}
                label="Mother"
                sx={{ fontSize: "18px", weight: 500 }}
              />
              <FormControlLabel
                value="Maternal Patient"
                control={<Radio />}
                label="Maternal Patient"
                sx={{ fontSize: "18px", weight: 500 }}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          {currentActive?.current?.name &&
            symptomsList.map((ele: any, idx: number | string) => (
              <>
                {currentDisease && currentDisease?.disease.includes(ele.id) ? (
                  <Button
                    sx={{
                      backgroundColor: "#207DFF",
                      height: "36px",
                      gap: "12px",
                      margin: "5px",
                      color: "#F6F6F6",
                      "&:hover": {
                        backgroundColor: "#207DFF",
                        color: "#F6F6F6",
                      },
                    }}
                    onClick={() => handleAddDisease(ele.id)}
                  >
                    {ele.symptom_name}
                    <SelectedIcon />
                  </Button>
                ) : (
                  <Button
                    sx={{
                      backgroundColor: "#F6F6F6",
                      height: "36px",
                      gap: "12px",
                      margin: "5px",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "#F6F6F6",
                        color: "black",
                      },
                    }}
                    onClick={() => handleAddDisease(ele.id)}
                  >
                    {ele.symptom_name}
                    <SelectPlusIcon />
                  </Button>
                )}
              </>
            ))}
        </Box>
      </DialogWrapper>
    </>
  );
}
