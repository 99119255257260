import React from "react";
import { Grid, Typography } from "@mui/material";
import { billSummaryConst } from "../../../../constants/displayText";

type InfoProps = {
  billingSummary?: any;
  paymentSummary?: any;
  isTemp?: boolean;
};

const Summary = ({ billingSummary, isTemp }: InfoProps) => {
  const { goodsValue, taxAmount, roundedOff, netAmount, SGST, CGST } =
    billSummaryConst;

  const billingSummaryOne = [
    {
      name: goodsValue,
      value: billingSummary?.goods_value,
    },

    {
      name: CGST,
      value: billingSummary?.CGST,
    },
    {
      name: SGST,
      value: billingSummary?.SGST,
    },
  ];

  const billingSummaryTwo = [
    {
      name: taxAmount,
      value: billingSummary?.tax,
    },
    {
      name: roundedOff,
      value: billingSummary?.roundedOff,
    },
    {
      name: netAmount,
      value: billingSummary?.netAmount,
      isHighlight: true,
    },
  ];

  return (
    <Grid container>
      {billingSummary && (
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "column",
            m: "20px 0",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontWeight: "600",
              color: "primary.main",
              m: "20px 0",
            }}
          >
            Billing Details
          </Typography>
          <Grid
            container
            sx={{
              width: "92vw",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              backgroundColor: "#F8F8F8",
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "center", md: "flex-start" },
                justifyContent: { xs: "space-around" },
              }}
            >
              <Grid
                item
                sx={{
                  width: {
                    xs: "50%",
                    sm: "50%",
                    md: "30%",
                    lg: "25%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  m: "51px 0px 45px 0px",
                }}
              >
                {billingSummaryOne?.map((row: any, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        width: {
                          xs: "100%",
                          lg: "100%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Grid
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h4">{row.name}</Typography>
                        <Typography variant="h4">:</Typography>
                      </Grid>
                      <Grid
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>{row.value}</Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                item
                sx={{
                  width: {
                    xs: "50%",
                    sm: "50%",
                    md: "35%",
                    lg: "35%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  m: "51px 0px 45px 0px",
                }}
              >
                {billingSummaryTwo.map((row, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        width: {
                          xs: "100%",
                          lg: "100%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Grid
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: row?.isHighlight ? "700" : "400",
                          }}
                        >
                          {row.name}
                        </Typography>
                        <Typography variant="h4">:</Typography>
                      </Grid>

                      <Typography
                        sx={{
                          width: "20%",
                          textAlign: "right",
                          fontWeight: row?.isHighlight ? "700" : "400",
                        }}
                      >
                        {row.value}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Summary;
