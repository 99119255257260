import { Box, Grid, Typography, debounce } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, TextField } from "../../components/basic";
import { DataTable } from "../../components/shared";
import { GridColDef } from "@mui/x-data-grid";
import { getAllCampaigns } from "../../services/campaignService";
import { CampaignsIconList } from "../../constants/displayText";
type GridRowData = Record<string, unknown>;

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState<any>({ rows: [], pageCount: 0 });
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    campaign: "",
    source: "",
    date: null,
  });

  const columns: GridColDef[] = [
    {
      field: "Campaigns",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Campaigns
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 150,
      sortable: false,
    },
    {
      field: "Mode",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Mode
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14} display={"flex"}>
          <Box sx={{ width: "20px", height: "20px" }}>
            {CampaignsIconList[row.mode]}
          </Box>
          {row.mode}
        </Typography>
      ),
      minWidth: 150,
      sortable: false,
    },
    {
      field: "Date",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.date}
        </Typography>
      ),
      minWidth: 150,
      sortable: false,
    },
    {
      field: "Open Rate",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <>
          <Typography variant="h5" fontSize={14}>
            Open Rate(%)
          </Typography>
        </>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.openRate}
        </Typography>
      ),
      minWidth: 150,
      sortable: false,
    },
  ];

  const getAllCampaignsList = (data: any) => {
    setLoading(true);
    setTimeout(() => {
      getAllCampaigns(data)
        .then((res) => {
          if (res.data) {
            setLoading(false);
            setCampaigns({
              rows: res?.data?.result,
              pageCount: res.data.total,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }, 500);
  };

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFilterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    debouncedGetAllCampaignsList({});
  };

  const debouncedGetAllCampaignsList = useCallback(
    debounce((data) => {
      getAllCampaignsList({});
    }, 300),
    []
  );

  const handleDateChange = (_: any, newValue: any) => {
    setFilterData((prevState) => ({
      ...prevState,
      date: newValue.validatedValue[0],
    }));
    getAllCampaignsList({});
  };

  useEffect(() => {
    getAllCampaignsList({});
  }, []);

  return (
    <Box>
      <Grid container columnGap={2} rowGap={2}>
        <Grid item xs={12} sm={3.8} md={3} xl={2.5}>
          <TextField
            label="Campaigns"
            placeholder="Ex: Kidney Dialysis"
            name="campaign"
            value={filterData.campaign}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={3.8} md={3} xl={2.5}>
          <TextField
            label="Sources"
            placeholder="Ex: Email"
            name="source"
            value={filterData.source}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={3.8} md={3} xl={2.5}>
          <DatePicker
            formControlStyle={{ width: "100%" }}
            placeholder={"Select date"}
            label="Date"
            width="95%"
            name="date"
            value={filterData.date}
            onChange={handleDateChange}
          />
        </Grid>
      </Grid>
      <DataTable
        loading={loading}
        columns={columns}
        rows={campaigns.rows}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        pageCount={campaigns.pageCount}
        currentPage={"campaignList"}
      />
    </Box>
  );
};

export default Campaigns;
