import { useEffect, useRef, useState } from "react";
import { debounce, Grid } from "@mui/material";
import BillProducts from "./BillProducts";
import {
  getAllMasterDepartments,
  getAllSuppliers,
} from "../../../services/gynecologyService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type optionsListTypes = {
  id: number | string | null;
  name: number | string | null;
};
const PurchaseReturn = () => {
  const [suppliersList, setSuppliersList] = useState<optionsListTypes[]>([]);
  const [departmentList, setDepartmentList] = useState<optionsListTypes[]>([]);
  const [issuerList, setIssuerList] = useState<optionsListTypes[]>([]);
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const supplierPagination = useRef(1);
  const searchSupplierPagination = useRef({ search: "", page: 1 });

  const debouncedGetAllSupplierList = debounce(
    async (currentValue: string, prevOptions: any, callback: any) => {
      try {
        return await getAllSuppliers({
          search: currentValue,
          page:
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.result;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: uniqueData.name,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );
          setSuppliersList(uniqueOptions);
          searchSupplierPagination.current.page =
            searchSupplierPagination.current?.search === currentValue
              ? searchSupplierPagination.current?.page + 1
              : 1;
          searchSupplierPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  const getAllSuppliersList = async (currentValue: any, prevOptions: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllSupplierList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          supplierPagination.current === Math.ceil(prevOptions.length / 10)
            ? supplierPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllSuppliers({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.result;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: uniqueData.name,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setSuppliersList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = supplierPagination.current;
          supplierPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;

          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  const getAllDepartmentList = async () => {
    const data = {
      active: 1,
      type: "issuer",
    };
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data.result
          ?.filter((elem: { type: string }) => elem.type !== "B2B")
          .map((ele: { dept_name: string; id: number }) => ({
            id: ele.id,
            name: ele.dept_name,
          }));
        setDepartmentList(formattedValue);
      })
      .catch((err: any) => console.log(err));
  };
  const getAllIssuerList = async () => {
    const data = {
      active: 1,
      type: "receiver",
    };
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res.data.result
          ?.filter((elem: { type: string }) => elem.type !== "B2B")
          .map((ele: { dept_name: string; id: number }) => ({
            id: ele.id,
            name: ele.dept_name,
          }));
        setIssuerList(formattedValue);
      })
      .catch((err: any) => console.log(err));
  };
  useEffect(() => {
    getAllDepartmentList();
    if (appConfiguration.goods_issue_disable) {
      getAllIssuerList();
    }
  }, []);
  return (
    <Grid
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BillProducts
        suppliersList={suppliersList}
        departmentList={departmentList}
        getAllSuppliersList={getAllSuppliersList}
        issuerList={issuerList}
      />
    </Grid>
  );
};

export default PurchaseReturn;
