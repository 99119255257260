import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  debounce,
  Switch,
} from "@mui/material";
import { Sort, TextField } from "../../../components/basic";
import { useCallback, useEffect, useState } from "react";
import {
  ConfirmationDialog,
  CustomFilter,
  DataTable,
  DialogWrapper,
} from "../../../components/shared";
import { DeleteIcon, EditIcon } from "../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  commonText,
} from "../../../constants/displayText";
import { Select } from "../../../components/basic";
import { doctorDetails } from "../../../services/appointmentService";

import { requiredValidator, updateFormDataWithHelperText } from "../../../utils/ValidationUtils";


type GridRowData = Record<string, unknown>;
const VaccineMaster = () => {
  const [vaccinesList, setVaccinesList] = useState<[]>([]);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [activeSort, setActiveSort] = useState({ field: "", order: "" });
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [addVaccine, setAddVaccine] = useState(false);
  const [doctorsName, setDoctorsName] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | number | null>("");
  const [saveBtnLoader, setSaveBtnLoader] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [vaccineToBeDeleted, setVaccineToBeDeleted] = useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const [vaccineId, setVaccineId] = useState<number | any>("");

  const initialVaccineFormError = {
    vaccineName: "",
    purpose: "",
    associatedVaccine: "",
    days: "",
    sequence: "",
    durationType: null,
    duration: "",
    addonDurationType: null,
    addonDuration: "",
    status: "",
  };
  const [vaccineFormError, setVaccineFormError] = useState<any>(
    initialVaccineFormError
  );

  const initialData: any = {
    vaccineName: "",
    purpose: "",
    associatedVaccine: "",
    days: "",
    sequence: "",
    durationType: null,
    duration: "",
    addonDurationType: null,
    addonDuration: "",

    status: true,
  };

  const [vaccineFormData, setVaccineFormData] = useState<string | Date | any>(
    initialData
  );

  const fieldData: any = {
    vaccineName: {
      label: "Vaccine Name",
      name: "vaccineName", // Include the name field
      value: vaccineFormData?.vaccineName,
      isError: vaccineFormError?.vaccineName === "" ? false : true,
      helperText: vaccineFormError?.vaccineName,
    },
    purpose: {
      label: "Purpose",
      name: "purpose",
      value: vaccineFormData?.purpose,
      isError: vaccineFormError?.purpose === "" ? false : true,
      helperText: vaccineFormError?.purpose,
    },
    associatedVaccine: {
      label: "Associated Vaccine",
      name: "associatedVaccine",
      value: vaccineFormData?.associatedVaccine,
      isError: vaccineFormError?.associatedVaccine === "" ? false : true,
      helperText: vaccineFormError?.associatedVaccine,
    },

    days: {
      label: "Days",
      name: "days",
      value: vaccineFormData?.days,
      isError: vaccineFormError?.days === "" ? false : true,
      helperText: vaccineFormError?.days,
    },
    sequence: {
      label: "Sequence",
      name: "sequence",
      value: vaccineFormData?.sequence,
      isError: vaccineFormError?.sequence === "" ? false : true,
      helperText: vaccineFormError?.sequence,
    },
    durationType: {
      label: "Duration Type",
      name: "durationType",
      value: vaccineFormData?.durationType,
      isError: vaccineFormError?.durationType === "" ? false : true,
      helperText: vaccineFormError?.durationType,
    },
    duration: {
      label: "Duration",
      name: "duration",
      value: vaccineFormData?.duration,
      isError: vaccineFormError?.duration === "" ? false : true,
      helperText: vaccineFormError?.duration,
    },
    addonDurationType: {
      label: "Addon Duration Type",
      name: "addonDurationType",
      value: vaccineFormData?.addonDurationType,
      isError: vaccineFormError?.addonDurationType === "" ? false : true,
      helperText: vaccineFormError?.addonDurationType,
    },
    addonDuration: {
      label: "Addon Duration",
      name: "addonDuration",
      value: vaccineFormData?.addonDuration,
      isError: vaccineFormError?.addonDuration === "" ? false : true,
      helperText: vaccineFormError?.addonDuration,
    },

    status: {
      label: "Status",
      name: "status",
      value: vaccineFormData?.status,
      isError: vaccineFormError?.status === "" ? false : true,
      helperText: vaccineFormError?.status,
    },
  };

  const [vaccineFieldData, setVaccineFieldData] = useState(fieldData);

  const handleDialogOpen = () => {
    setVaccineFormData(initialData);
    setAddVaccine(true);
    setIsUpdate(false);
  };
  const handleDialogClose = () => {
    setAddVaccine(false);
    setVaccineFormError(initialVaccineFormError);
  };
  const getAllDoctorsName = async () => {
    try {
      doctorDetails().then((result: any) => {
        let data = result?.data;
        const doctorsList = data?.map((uniqueData: any) => {
          return { id: uniqueData?.id, name: uniqueData?.name };
        });
        setDoctorsName(doctorsList);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleValidation = useCallback(
    (e: any) => {
      const { name, value, label } = e.target;

      switch (name) {
        case "vaccineName":
        case "purpose":
        case "associatedVaccine":
        case "days":
        case "sequence":
        case "durationType":
        case "duration":
        case "addonDurationType":
        case "addonDuration": {
          if (requiredValidator(value, label)) {
            updateFormDataWithHelperText(
              name,
              requiredValidator(value, label),
              setVaccineFormError
            );
          } else {
            updateFormDataWithHelperText(name, "", setVaccineFormError);
          }
          break;
        }

        default:
          break;
      }
    },
    [vaccineFormError, vaccineFormData]
  );

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateBookingFieldData = () => {
    setVaccineFieldData((prevFieldData: any) => {
      return Object?.keys(prevFieldData)?.map((field: any) => {
        return {
          ...field,
          value: vaccineFormData[field.name],
          helperText: vaccineFormError[field.name],
          isError: vaccineFormError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateBookingFieldData();
  }, [vaccineFormError, vaccineFormData]);

  const handleInputChange = useCallback(
    (e: any) => {
      const { name, value } = e.target;

      setVaccineFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
      handleValidation(e);
    },
    [handleValidation]
  );

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setVaccineFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };

  const createVaccine = async () => {
    if (
      vaccineFormData.vaccineName == "" ||
      vaccineFormData.purpose == "" ||
      vaccineFormData.associatedVaccine == "" ||
      vaccineFormData.days == "" ||
      vaccineFormData.sequence == "" ||
      vaccineFormData.durationType == null ||
      (vaccineFormData.durationType !== null &&
        vaccineFormData.duration == "") ||
      (vaccineFormData.durationType !== 1 &&
        vaccineFormData.addonDurationType == null) ||
      (vaccineFormData.addonDurationType !== null &&
        vaccineFormData.addonDuration == "") ||
      vaccineFormData.status == null
    ) {
      validateForm();
    } else {
      try {
        setSaveBtnLoader(true);

        setAddVaccine(false);

        setSaveBtnLoader(false);
      } catch (error: any) {
        console.error("An error occurred:", error);
        setSaveBtnLoader(false);
      }
    }
  };

  const editVaccine = async (row: any) => {
    try {
      setIsUpdate(true);
      setAddVaccine(true);
      setDialogLoading(true);

      setDialogLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setDialogLoading(false);
    }
  };

  const updateVaccine = async () => {
    if (
      vaccineFormData.vaccineName == "" ||
      vaccineFormData.purpose == "" ||
      vaccineFormData.associatedVaccine == "" ||
      vaccineFormData.days == "" ||
      vaccineFormData.sequence == "" ||
      vaccineFormData.durationType == null ||
      (vaccineFormData.durationType !== null &&
        vaccineFormData.duration == "") ||
      (vaccineFormData.durationType !== 1 &&
        vaccineFormData.addonDurationType == null) ||
      (vaccineFormData.addonDurationType !== null &&
        vaccineFormData.addonDuration == "") ||
      vaccineFormData.status == null
    ) {
      validateForm();
    } else {
      try {
        setSaveBtnLoader(true);
      } catch (error) {
        console.error("An error occurred:", error);
        setSaveBtnLoader(false);
      }
    }
  };

 
  const deleteVaccine = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setVaccineToBeDeleted(row.id);
  };
  const handleConfirmDelete = async () => {
    if (vaccineToBeDeleted === null) {
      return false;
    }
    try {
      setDeleteBtnLoader(true);
    } catch (error) {
      setVaccineToBeDeleted(null);
      setIsConfirmationDialogOpen(false);
      setDeleteBtnLoader(false);
      console.error("An error occurred:", error);
    }
  };
  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };
  useEffect(() => {
    getAllDoctorsName();
  }, []);
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (isFieldSort) {
      setSortedField({ field: field, order: "ASC" });
      setActiveSort({ field: field, order: "ASC" });
    }
    if (!isFieldSort) {
      setSortedField({ field: field, order: "DESC" });
      setActiveSort({ field: field, order: "DESC" });
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ cursor: "pointer" }}>
          <IconButton onClick={() => editVaccine(row)}>
            <EditIcon />
          </IconButton>
        </div>

        <div style={{ cursor: "pointer" }}>
          <IconButton onClick={() => deleteVaccine(row)}>
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "vaccineDuration",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("vaccineDuration")}
        >
          <Typography variant="h5" fontSize={14}>
            Vaccine Duration
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "vaccineDuration" &&
              activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "vaccineDuration" &&
              activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.vaccineDuration}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "vaccineName",
      flex: 1,

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("vaccineName")}
        >
          <Typography variant="h5" fontSize={14}>
            Vaccine Name
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "vaccineName" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "vaccineName" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.vaccineName}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },

    {
      field: "purpose",
      flex: 1,

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("purpose")}
        >
          <Typography variant="h5" fontSize={14}>
            Purpose
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "purpose" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "purpose" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.purpose}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "sequence",
      flex: 1,

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("sequence")}
        >
          <Typography variant="h5" fontSize={14}>
            Sequence
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "sequence" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "sequence" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sequence}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "associatedVaccine",
      flex: 1,

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("end_time")}
        >
          <Typography variant="h5" fontSize={14}>
            Associated Vaccine
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "associatedVaccine" &&
              activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "associatedVaccine" &&
              activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.associatedVaccine}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "days",
      flex: 1,

      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("days")}
        >
          <Typography variant="h5" fontSize={14}>
            Days
          </Typography>
          <Sort
            ascendingActive={
              activeSort.field === "days" && activeSort.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              activeSort.field === "days" && activeSort.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.days}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const dispatch = useDispatch();
  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.vaccineMasterList
  );

  useEffect(() => {

  }, [pageInfo, sortedField, searchValue]);
  useEffect(() => {
    if (fieldData?.durationType?.value == 1) {
      setVaccineFormData((prev: any) => ({
        ...prev,
        addonDurationType: null,
        addonDuration: "",
      }));
    }
    if (fieldData?.durationType?.value == 2) {
      setVaccineFormData((prev: any) => ({
        ...prev,
        addonDurationType: 1,
      }));
    }
    if (fieldData?.durationType?.value == 3) {
      setVaccineFormData((prev: any) => ({
        ...prev,
        addonDurationType: 2,
      }));
    }
  }, [vaccineFormData.durationType]);

  const list = [
    {
      vaccineDuration: "Test",
      vaccineName: "Test",
      purpose: "Test",
      sequence: "333",
      associatedVaccine: "UGJ-09",
      days: "12",
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CustomFilter
          isSearchEnabled={true}
          searchOnChange={debounce(
            (e: any) => setSearchValue(e?.target?.value),
            1000
          )}
          searchName={"search"}
          editButtonTitle={commonText.EDIT_BUTTON_TITLE}
          onUpdateButtonClick={() => {
            handleDialogOpen();
          }}
        />
        <DataTable
          columns={columns}
          getRowId={(row: GridRowData) => `${String(row.id)}`}
          rows={list}
          pageCount={list.length}
          currentPage={"vaccineMasterList"}
          loading={loading}
        />
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          title="are you surely want to delete?"
          handleClick={handleConfirmDelete}
          onClose={onClose}
          loading={deleteBtnLoader}
        />
      </Box>
      <Grid>
        <DialogWrapper
          open={addVaccine}
          handleClick={() => {
            isUpdate ? updateVaccine() : createVaccine();
          }}
          title={isUpdate ? "Edit Vaccine" : "Add Vaccine"}
          onClose={() => {
            handleDialogClose();
          }}
          maxWidth="md"
          loading={saveBtnLoader}
        >
          {dialogLoading ? (
            <div
              style={{
                width: "100%",
                margin: "auto",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
                height: "40vh",
                background: "transparent",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Grid sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                <TextField
                  value={fieldData?.vaccineName?.value}
                  onChange={handleInputChange}
                  placeholder="Enter Vaccine Name"
                  label={fieldData?.vaccineName?.label}
                  name={fieldData?.vaccineName?.name}
                  helperText={fieldData?.vaccineName?.helperText}
                  sx={{
                    width: "300px",
                    mr: "25px",
                  }}
                />
                <TextField
                  value={fieldData?.purpose?.value}
                  onChange={handleInputChange}
                  placeholder="Enter Purpose"
                  label={fieldData?.purpose?.label}
                  name={fieldData?.purpose?.name}
                  helperText={fieldData?.purpose?.helperText}
                  sx={{
                    width: "300px",
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                <Select
                  value={fieldData?.associatedVaccine?.value}
                  placeholder="Select Associated Vaccine"
                  onChange={handleInputChange}
                  options={[{ id: 1, value: "one" }]}
                  name={fieldData?.associatedVaccine?.name}
                  label={fieldData?.associatedVaccine?.label}
                  helperText={fieldData?.associatedVaccine?.helperText}
                  sx={{
                    width: {
                      xs: "300px",
                    },
                    mr: "20px",
                  }}
                  width="300px"
                />
                <TextField
                  value={fieldData?.days?.value}
                  onChange={handleInputChange}
                  placeholder="Enter Days"
                  label={fieldData?.days?.label}
                  name={fieldData?.days?.name}
                  helperText={fieldData?.days?.helperText}
                  sx={{
                    width: "300px",
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                <TextField
                  value={fieldData?.sequence?.value}
                  onChange={handleInputChange}
                  placeholder="Enter Sequence Name"
                  label={fieldData?.sequence?.label}
                  name={fieldData?.sequence?.name}
                  helperText={fieldData?.sequence?.helperText}
                  sx={{
                    width: "300px",
                    mr: "25px",
                  }}
                />
              </Grid>
              <Grid sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                <Select
                  value={fieldData?.durationType?.value}
                  placeholder="Select Duration Type"
                  onChange={handleInputChange}
                  options={[
                    { id: 1, value: "Day(s)" },
                    { id: 2, value: "Month(s)" },
                    { id: 3, value: "Year(s)" },
                  ]}
                  name={fieldData?.durationType?.name}
                  label={fieldData?.durationType?.label}
                  helperText={fieldData?.durationType?.helperText}
                  sx={{
                    width: {
                      xs: "300px",
                    },
                    mr: "20px",
                  }}
                  width="300px"
                />

                <TextField
                  value={fieldData?.duration?.value}
                  onChange={handleInputChange}
                  label={fieldData.duration.label}
                  name={fieldData.duration.name}
                  inputAdornment={
                    fieldData?.durationType?.value == 3
                      ? "Year(s)"
                      : fieldData?.durationType?.value == 2
                      ? "Month(s)"
                      : fieldData?.durationType?.value == 1
                      ? "Day(s)"
                      : ""
                  }
                  helperText={fieldData?.duration?.helperText}
                  sx={{
                    width: "300px",
                    mr: "25px",
                  }}
                />
              </Grid>
              {(fieldData?.durationType?.value == 2 ||
                fieldData?.durationType?.value == 3) && (
                <Grid sx={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
                  <Select
                    value={vaccineFormData.addonDurationType}
                    placeholder="Select Addon Duration Type"
                    onChange={handleInputChange}
                    options={[
                      { id: 1, value: "Day(s)" },
                      { id: 2, value: "Month(s)" },
                      //   { id: 3, value: "year(s)" },
                    ]}
                    name={fieldData?.addonDurationType?.name}
                    label={fieldData?.addonDurationType?.label}
                    helperText={fieldData?.addonDurationType?.helperText}
                    sx={{
                      width: {
                        xs: "300px",
                      },
                      mr: "20px",
                    }}
                    width="300px"
                  />
                  <TextField
                    value={fieldData?.addonDuration?.value}
                    onChange={handleInputChange}
                    label={fieldData?.addonDuration?.label}
                    name={fieldData?.addonDuration?.name}
                    inputAdornment={
                      vaccineFormData?.addonDurationType == 2
                        ? "Month(s)"
                        : "Day(s)"
                    }
                    helperText={fieldData?.addonDuration?.helperText}
                    sx={{
                      width: "300px",
                      mr: "25px",
                    }}
                  />
                </Grid>
              )}
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: "5px",
                  mt: "5px",
                }}
              >
                <Typography>{fieldData?.status?.label}</Typography>
                <Switch
                  name={fieldData?.status?.name}
                  checked={vaccineFormData.status}
                  onChange={handleSwitchChange}
                  disabled={isUpdate == false ? true : false}
                />
              </Grid>
            </Box>
          )}
        </DialogWrapper>
      </Grid>
    </>
  );
};

export default VaccineMaster;
