import { Suspense, useEffect } from "react";
import "./App.css";
import { ThemeProvider, CircularProgress } from "@mui/material";
import routes from "./routes";
import theme from "./theme";
import ResponsiveDrawer from "./components/layout";
import { Navigate, Route, Routes } from "react-router-dom";
import PublicRoutes from "./routes/publicRoutes";
import PrivateRoutes from "./routes/privateRoutes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import AppSnackBar from "./components/shared/snackbar";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAllCommons,
  getCommonAppConfigurations,
} from "./services/paymentService";
import {
  setAppConfiguration,
  setCommonVariable,
  setIsTokenEnable,
} from "./redux/slices/appconfiguration";
import { getAppBarTitleFromPath } from "./utils/GeneralUtils";

import { crypt } from "./utils/GeneralUtils";
import { setAuthUser } from "./redux/slices/auth";
import { getUserDetails } from "./services/userService";
import { setSnackBarFailed } from "./redux/slices/snackbar";
import { RouteUrls } from "./constants/routes";

const { printUrl } = RouteUrls;
function App() {
  const { publicRoutes, privateRoutes } = routes() as any;
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const optionalParam = location?.pathname?.split("/").pop();
    const pageTitle = getAppBarTitleFromPath(location.pathname, optionalParam);
    document.title = pageTitle;
  }, [isLoggedIn, location]);

  useEffect(() => {
    if (isLoggedIn) {
      getUserDetails()
        .then((res: any) => {
          const user = res?.data?.user;
          if (user) {
            dispatch(setAuthUser(user));

            const encryptedText = crypt(
              "user_detail_salt",
              JSON.stringify(user)
            );
            localStorage.setItem("userDetails", encryptedText);

            getCommonAppConfigurations()
              .then((res: any) => {
                const data = res.data;
                if (data) {
                  dispatch(setAppConfiguration(data));
                  dispatch(setIsTokenEnable(res.data.token_appointment_enable));
                  const encryptedText = crypt(
                    "app_configuration_salt",
                    JSON.stringify(data)
                  );
                  localStorage.setItem("appConfiguration", encryptedText);
                }
              })
              .catch((err: any) => {
                if (err?.response?.status === 429) {
                  dispatch(
                    setSnackBarFailed({
                      snackBarMessage: err?.response?.data?.message,
                    })
                  );
                } else {
                  dispatch(
                    setSnackBarFailed({
                      snackBarMessage: "something went wrong",
                    })
                  );
                }
              });
            getAllCommons()
              .then((res: any) => {
                const data = res.data;
                if (data) {
                  dispatch(setCommonVariable(data));
                  const encryptedText = crypt(
                    "common_variable_salt",
                    JSON.stringify(data)
                  );
                  localStorage.setItem("commonVariables", encryptedText);
                }
              })
              .catch((err: any) => {
                if (err?.response?.status === 429) {
                  dispatch(
                    setSnackBarFailed({
                      snackBarMessage: err?.response?.data?.message,
                    })
                  );
                } else {
                  dispatch(
                    setSnackBarFailed({
                      snackBarMessage: "something went wrong",
                    })
                  );
                }
              });
          }
        })
        .catch((err) => {
          if (err?.response?.status === 429) {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: err?.response?.data?.message,
              })
            );
          } else {
            dispatch(
              setSnackBarFailed({
                snackBarMessage: "something went wrong",
              })
            );
          }
        });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Suspense
        fallback={
          <div>
            <CircularProgress />
          </div>
        }
      >
        <Routes>
          <Route element={<PublicRoutes isLoggedIn={isLoggedIn} />}>
            {publicRoutes?.map(({ path, Component }: any, index: number) => (
              <Route path={path} element={Component} key={index} />
            ))}
          </Route>
          <Route element={<PrivateRoutes isLoggedIn={isLoggedIn} />}>
            {privateRoutes?.map(({ path, Component }: any, index: number) => (
              <Route
                key={index}
                element={
                  path === printUrl ? null : (
                    <ResponsiveDrawer
                      navigateHook={navigate}
                      locationHook={location}
                    />
                  )
                }
              >
                <Route path={path} element={Component} />
                <Route
                  path="*"
                  element={<Navigate to="/dashboard" replace />}
                />
              </Route>
            ))}
          </Route>
        </Routes>
        <AppSnackBar />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
