import { Grid, Box } from "@mui/material";
import DialogWrapper from "../dialogWrapper";
import { CheckBox, Select, TextField } from "../../basic";
import { sequenceWrapperConst } from "../../../constants/displayText";

type SequenceWrapperProps = {
  open: boolean;
  title: string;
  onClose?: any;
  handleClick?: any;
  isOnce?: {
    isOnce: boolean;
    isOnceSeqValue: string;
  };
  sequenceValue?: any;
  handleChange?: any;
  handleSequenceCheckBoxChange?: any;
  isManual?: {
    is_manual: boolean;
    is_auto_generate: boolean;
    is_once: boolean;
  };
  isOnceValue?: string;
};

export default function SequenceWrapper({
  open,
  title,
  onClose,
  handleClick,
  isOnce,
  sequenceValue,
  handleChange,
  handleSequenceCheckBoxChange,
  isManual,
  isOnceValue,
}: SequenceWrapperProps) {

  const style = {
    checkBox1: {
      display: "flex",
      marginTop: "12px",
      pTag: {
        marginTop: "10px",
      },
      fontSize: "14px",
      fontWeight: 400,
    },
    checkBox2: {
      display: "flex",
      marginTop: "12px",
      pTag: {
        marginTop: "10px",
      },
      fontSize: "14px",
      fontWeight: 400,
    },
    inputMainBox: {
      display: "flex",
      margin: "0px 147px",
      firstInputBox: {
        marginLeft: "70px",
      },
      fontSize: "14px",
      fontWeight: 400,
    },
  };

  return (
    <DialogWrapper
      open={open}
      title={title}
      onClose={onClose}
      handleClick={handleClick}
    >
      <Grid>
        <Grid
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            color: "#000000",
          }}
        >
          {sequenceWrapperConst.changeSetting}
        </Grid>
        <Grid style={style.checkBox2}>
          <CheckBox
            onChange={handleSequenceCheckBoxChange}
            name={"is_manual"}
            value={isManual?.is_manual}
            checked={isManual?.is_manual}
          />
          <p style={style.checkBox2.pTag}>{sequenceWrapperConst.addManually}</p>
        </Grid>
        <Grid style={style.checkBox1}>
          <CheckBox
            onChange={handleSequenceCheckBoxChange}
            name={"is_auto_generate"}
            value={isManual?.is_auto_generate}
            checked={isManual?.is_auto_generate}
          />
          <p style={style.checkBox1.pTag}>
            {sequenceWrapperConst.autoGenerating}
          </p>
        </Grid>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "flex-start",
          }}
        >
          {sequenceValue.map((ele: any, index: number) => (
            <>
              <Box>
                <h6
                  style={{
                    color: "#207DFF",
                    fontSize: "10px",
                    fontWeight: 400,
                    margin: "25px 0px 12px",
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {ele.type}
                  </span>
                </h6>
                <TextField
                  width="100px"
                  sx={{ marginRight: "8px" }}
                  value={ele?.value}
                  name={"value"}
                  onChange={(e: any) => handleChange(e, index)}
                />
              </Box>
              {index !== sequenceValue.length - 1 && (
                <Box>
                  <h6
                    style={{
                      color: "#207DFF",
                      fontSize: "10px",
                      fontWeight: 400,
                      margin: "25px 0px 12px",
                    }}
                  >
                    {sequenceWrapperConst.Symbols}
                  </h6>
                  <Select
                    options={[
                      { id: "Select", name: "Select" },
                      { id: "%", name: "%" },
                      { id: "-", name: "-" },
                      { id: "/", name: "/" },
                    ]}
                    selectedType="string"
                    width="70px"
                    sx={{ marginRight: "12px" }}
                    onChange={(e: any) => handleChange(e, index)}
                    value={ele?.separator}
                    name={"separator"}
                  />
                </Box>
              )}
            </>
          ))}
        </Box>
        {isOnce?.isOnce && (
          <Grid style={style.checkBox2}>
            <CheckBox
              onChange={handleSequenceCheckBoxChange}
              name={"is_once"}
              value={isManual?.is_once}
              checked={isManual?.is_once}
            />
            <p
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                gap: "5px",
              }}
            >
              I want to use {isOnce.isOnceSeqValue} only for this time
            </p>
          </Grid>
        )}
      </Grid>
    </DialogWrapper>
  );
}
