import { useSelector } from "react-redux";
import store, { RootState } from "../redux/store";
export type AuthUserTypes = {
  id: number;
  mobile: string;
  name: string;
  permissions: string[];
  roles: string[];
};
export default function PermissionUtils() {
  const { authUser }: any = useSelector((state: RootState) => state.auth);

  const can = (permission: string): boolean => {
    return authUser?.permissions?.find((p: string) => p === permission)
      ? true
      : false;
  };
  const hasRole = (role: string): boolean => {
    return authUser?.roles?.find((r: string) => r === role) ? true : false;
  };
  const hasAnyRole = (roles: string[]): boolean => {
    return roles.some((r: string) => authUser?.roles?.includes(r));
  };

  const hasAnyPermission = (
    permissions: string[] | any,
    requiredPermission: string[] | any
  ): boolean => {
    return requiredPermission?.some((p: string) => permissions.includes(p))
      ? true
      : false;
  };
  return {
    can,
    hasRole,
    hasAnyRole,
    hasAnyPermission,
  };
}


export const Can = (permission: string): boolean => {
  const { authUser }: any = store.getState().auth;

  return authUser?.permissions?.find((p: string) => p === permission)
    ? true
    : false;
};

export const hasRole = (role: string): boolean => {
  const { authUser }: any = store.getState().auth;
  return authUser?.roles?.find((r: string) => r === role) ? true : false;
};
export const hasAnyRole = (roles: string[]): boolean => {
  const { authUser }: any = store.getState().auth;
  return roles.some((r: string) => authUser?.roles?.includes(r));
};

export const hasAnyPermission = (
  permissions: string[] | any,
  requiredPermission: string[] | any
): boolean => {
  return requiredPermission?.some((p: string) => permissions.includes(p))
    ? true
    : false;
};
