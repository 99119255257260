import moment from "moment";

export function dateFormat(
  dateString: string | Date,
  format: string = "DD-MM-YYYY"
) {
  // Parse the input date string
  const date = new Date(dateString);

  // Extract day, month, and year from the date object
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());

  // Replace placeholders in the format string with actual date values
  const formattedDate = format
    .replace("DD", day)
    .replace("MM", month)
    .replace("YYYY", year);

  return formattedDate;
}

export function timeFormat(time: any, format: string = "hh:mm A") {
  // Extract hours, minutes from the time string
  const [hours, minutes] = time.split(":").map(Number);

  // Determine AM/PM indicator
  const amPM = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  const formattedHours = hours % 12 || 12;

  // Replace placeholders in the format string with actual time values
  const formattedTime = format
    .replace("hh", String(formattedHours).padStart(2, "0"))
    .replace("mm", String(minutes).padStart(2, "0"))
    .replace("A", amPM);

  return formattedTime;
}

export function DateTimeFormat(
  date: Date,
  format: string = "DD/MM/YYYY hh:mm A"
): any {
  const formattedDate = dateFormat(date, format);
  const formattedTime = timeFormat(date, format);
  return `${formattedDate} ${formattedTime}`;
}

export function convertTimeToDate(timeString: any) {
  // Split the time string into hours, minutes, and period (AM/PM)

  const timeParts = timeString.split(/[:\s]/);
  const hours = parseInt(timeParts[0], 10);
  const minutes = parseInt(timeParts[1], 10);
  const period = timeParts[2];

  // Adjust hours for PM period
  let adjustedHours = hours;
  if (period === "PM" && hours !== 12) {
    adjustedHours += 12;
  } else if (period === "AM" && hours === 12) {
    adjustedHours = 0;
  }
  // Create a new Date object with current date and adjusted hours and minutes
  const currentDate = new Date();
  const newDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    adjustedHours,
    minutes
  );

  return newDate;
}

export const convertDateToTime = (date: any) => {
  const padZero = (num: any) => (num < 10 ? "0" + num : num); // Helper function to pad single digit numbers with leading zero
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const meridiem = hours < 12 ? "AM" : "PM"; // Determine AM or PM
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  return `${padZero(formattedHours)}:${padZero(minutes)} ${meridiem}`;
};

export const commonDateFormatter = (
  date: Date,
  format: string = "DD/MM/YYYY hh:mm:ss A"
) => {
  const convertedDate = moment(date, "DD/MM/YYYY hh:mm:ss");
  const formattedDate = convertedDate.format(format);

  return formattedDate;
};

export const formattedDateWithSlash = (date: string) => {
  return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
};
export const formatDateType = (date: Date | string, type: string) => {
  const formateDate = moment(date, "YYYYMMDD").toDate();
  const formattedDate = moment(formateDate).format(type);
  return formattedDate;
};

export const timeWithTwentyFourHoursConverter = (
  originalDateString: string
) => {
  const originalDate = moment(originalDateString, "DD/MM/YYYY hh:mm:ss A");
  const newDateString = originalDate.format("YYYY-MM-DD HH:mm:ss");
  return newDateString;
};

export const formatTwelveHoursTime = (originalDateString: string) => {
  const formattedDate = moment(originalDateString).format(
    "DD/MM/YYYY hh:mm:ss A"
  );
  return formattedDate;
};

export const calculateEddFormat = (inputDate: string) => {
  let date = moment(inputDate, "DD/MM/YYYY")
    .add(1, "years")
    .subtract(3, "months")
    .add(7, "days")
    .format("DD/MM/YYYY");
  return date;
};
export const expDateDifferenceCalculate = (date: any) => {
  const currentDate = moment();
  const givenDate = moment(date);
  const diffInDays = givenDate.diff(currentDate, "days");
  return diffInDays;
};

const date = new Date();
const day = date.getDate().toString().padStart(2, "0");
const month = (date.getMonth() + 1).toString().padStart(2, "0");
const year = date.getFullYear().toString();
export const CurrentDate = `${day}/${month}/${year}`;

