import React from "react";
import { TableCell, TableRow, Box } from "@mui/material";
import { TextField, Select, DatePicker } from "../../../../../components/basic";

const PrescriptionRow = ({
  styles,
  handleDateChange,
  commonVariables,
  index,
  sybTest,
  row,
  columns,
  onChange,
  error,
}: any) => {
  return (
    <>
      <TableRow key={`${sybTest?.bill_item_id} - ${index}`}>
        <TableCell
          sx={{
            borderBottomColor: "var(--table-border)",
            width: `${columns.headers[0].width}%`,
          }}
        >
          <TextField
            fullWidth
            formControlStyle={{
              width: "100%",
              marginLeft: "15px",
              overflow: "hidden",
            }}
            value={row.medicine_name}
            sx={{ ...styles.textFieldStyle }}
            name="medicine_name"
            disabled
            onChange={(e: any) => onChange(e, index)}
          />
        </TableCell>
        <TableCell
          sx={{
            "&.MuiTableCell-body:has(.Mui-focused)": {
              border: "#FFFFFF !important",
            },
            width: `${columns.headers[1].width}%`,
          }}
        >
          <Box display={"flex"}>
            <TextField
              type="number"
              formControlStyle={{ width: "100%" }}
              name="dosage"
              value={row.dosage}
              onChange={(e: any) => onChange(e, index)}
              // onFocus={() => setTestNameAndSeqFocus("sequence")}
              // onBlur={() => setTestNameAndSeqFocus("")}
              sx={{
                ...styles.textFieldStyle,
                "& input": {
                  textAlign: "center !important",
                },
                "&input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
              }}
            />
          </Box>
        </TableCell>
        <TableCell
          style={{
            width: `${columns.headers[2].width}%`,
            border: !!error?.test_dept ? "2px solid #d32f2f !important" : "",
          }}
        >
          <Select
            formControlStyle={{
              width: "100%",
              marginLeft: "5px",
              "& .MuiInputBase-formControl": {
                boxShadow: "unset",
              },
            }}
            value={row.units}
            label=""
            width=""
            options={commonVariables?.units}
            name="units"
            sx={{
              ...styles.selectFieldStyle,
              "& .MuiSelect-icon": {},
              "&:hover .MuiSelect-icon": {
                display: "inline-flex",
              },
            }}
            menuStyle={{
              marginLeft: "-5px",
            }}
            onChange={(e: any) => onChange(e, index)} // onBlur={() => setTestNameAndSeqFocus("")}
            // onFocus={() => setTestNameAndSeqFocus("department")}
          />
        </TableCell>
        <TableCell
          style={{
            width: `${columns.headers[3].width}%`,
            maxWidth: "150px",
          }}
        >
          <Select
            formControlStyle={{
              width: "100%",
              marginLeft: "5px",
              "& .MuiInputBase-formControl": {
                boxShadow: "unset",
              },
            }}
            options={commonVariables?.freq}
            placeholder="select"
            name="freq"
            value={row.freq}
            label=""
            width=""
            sx={{
              ...styles.selectFieldStyle,
              "& .MuiSelect-icon": {},
              "&:hover .MuiSelect-icon": {
                display: "inline-flex",
              },
            }}
            menuStyle={{
              marginLeft: "-5px",
            }}
            onChange={(e: any) => onChange(e, index)} // onBlur={() => setTestNameAndSeqFocus("")}
            // onFocus={() => setTestNameAndSeqFocus("department")}
          />
        </TableCell>
        <TableCell
          style={{
            width: `${columns.headers[4].width}%`,
            border: Boolean(error?.methodology_id)
              ? "2px solid #d32f2f !important"
              : "",
          }}
        >
          <TextField
            fullWidth
            formControlStyle={{
              width: "100%",
              marginLeft: "15px",
            }}
            name="freq_time"
            value={row.freq_time}
            sx={{ ...styles.textFieldStyle }}
            onChange={(e: any) => onChange(e, index)}
          />
        </TableCell>
        <TableCell style={{ width: `${columns.headers[5].width}%` }}>
          <Select
            formControlStyle={{
              width: "100%",
              marginLeft: "5px",
              "& .MuiInputBase-formControl": {
                boxShadow: "unset",
              },
            }}
            options={commonVariables?.freq_time_type}
            placeholder="select"
            name="freq_time_type"
            value={row.freq_time_type}
            label=""
            width=""
            sx={{
              ...styles.selectFieldStyle,
              "& .MuiSelect-icon": {},
              "&:hover .MuiSelect-icon": {
                display: "inline-flex",
              },
            }}
            menuStyle={{
              marginLeft: "-5px",
            }}
            onChange={(e: any) => onChange(e, index)} // onBlur={() => setTestNameAndSeqFocus("")}
            // onFocus={() => setTestNameAndSeqFocus("department")}
          />
        </TableCell>
        <TableCell style={{ width: `${columns.headers[6].width}%` }}>
          <Select
            options={commonVariables?.duration_type}
            placeholder="select"
            name="duration_type"
            value={row.duration_type}
            formControlStyle={{
              width: "100%",
              marginLeft: "5px",
              "& .MuiInputBase-formControl": {
                boxShadow: "unset",
              },
            }}
            label=""
            width=""
            sx={{
              ...styles.selectFieldStyle,
              "& .MuiSelect-icon": {},
              "&:hover .MuiSelect-icon": {
                display: "inline-flex",
              },
            }}
            menuStyle={{
              marginLeft: "-5px",
            }}
            onChange={(e: any) => onChange(e, index)} // onBlur={() => setTestNameAndSeqFocus("")}
            // onFocus={() => setTestNameAndSeqFocus("department")}
          />
        </TableCell>
        <TableCell
          style={{
            width:
              row.duration_type === "specific_days"
                ? `${columns.headers[7].width}%`
                : "10%",
          }}
        >
          {row.duration_type !== "forever" &&
            row.duration_type !== "everyday" &&
            row.duration_type !== "specific_days" &&
            row.duration_type !== "custom_date" && (
              <TextField
                fullWidth
                formControlStyle={{
                  width: "100%",
                  marginLeft: "15px",
                }}
                value={row?.duration?.interval_days}
                sx={{ ...styles.textFieldStyle }}
                name="interval_days"
                onChange={(e: any) => onChange(e, index, true)}
              />
            )}
          {row.duration_type === "specific_days" && (
            <Select
              isMultiSelect={true}
              options={commonVariables?.specific_days}
              placeholder="select"
              name="specific_days"
              value={row.duration?.specific_days || []}
              formControlStyle={{
                width: "100%",
                marginLeft: "5px",
                "& .MuiInputBase-formControl": {
                  boxShadow: "unset",
                },
              }}
              label=""
              width=""
              sx={{
                ...styles.selectFieldStyle,
                "& .MuiSelect-icon": {},
                "&:hover .MuiSelect-icon": {
                  display: "inline-flex",
                },
              }}
              menuStyle={{
                marginLeft: "-5px",
              }}
              onChange={(e: any) => onChange(e, index, true)} // onBlur={() => setTestNameAndSeqFocus("")}
              // onFocus={() => setTestNameAndSeqFocus("department")}
            />
          )}
          {row.duration_type === "custom_date" && (
            <DatePicker
              value={row.duration.custom_date}
              label=""
              name="custom_date"
              onChange={(e: any, newValue: any) =>
                handleDateChange("custom_date", newValue, index)
              }
              height="28px"
              style={{
                boxShadow: "none",
                border: "0px solid rgba(138, 138, 138, 0.32)",
                width: "10vw",
                height: "30px",
                borderRadius: "5px",
                padding: "0px",
              }}
              formControlStyle={{
                width: "100%",
                marginLeft: "5px",
                "& .MuiInputBase-formControl": {
                  boxShadow: "unset",
                },
              }}
            />
          )}
        </TableCell>
        <TableCell style={{ width: `${columns.headers[8].width}%` }}>
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {row.duration_type !== "forever" &&
              row.duration_type !== "custom_date" && (
                <TextField
                  fullWidth
                  formControlStyle={{
                    width: "100%",
                    marginLeft: "15px",
                  }}
                  value={row?.duration?.duration_count}
                  sx={{ ...styles.textFieldStyle }}
                  name="duration_count"
                  onChange={(e: any) => onChange(e, index, true)}
                />
              )}
          </Box>
        </TableCell>
        <TableCell
          style={{
            width: `${columns.headers[9].width}%`,
            border: !!error?.price ? "2px solid #d32f2f" : "",
          }}
        >
          {row.duration_type !== "forever" &&
            row.duration_type !== "custom_date" && (
              <Select
                formControlStyle={{
                  width: "100%",
                  marginLeft: "5px",
                  "& .MuiInputBase-formControl": {
                    boxShadow: "unset",
                  },
                }}
                value={row.duration.interval}
                label=""
                width=""
                options={commonVariables?.interval}
                name="interval"
                sx={{
                  ...styles.selectFieldStyle,
                  "& .MuiSelect-icon": {},
                  "&:hover .MuiSelect-icon": {
                    display: "inline-flex",
                  },
                }}
                menuStyle={{
                  marginLeft: "-5px",
                }}
                onChange={(e: any) => onChange(e, index, true)} // onBlur={() => setTestNameAndSeqFocus("")}
                // onFocus={() => setTestNameAndSeqFocus("department")}
              />
            )}
        </TableCell>
        <TableCell style={{ width: `${columns.headers[10].width}%` }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <DatePicker
              value={row.start_date}
              label=""
              name="start_date"
              onChange={(e: any, newValue: any) =>
                handleDateChange("start_date", newValue, index)
              }
              height="28px"
              style={{
                boxShadow: "none",
                border: "0px solid rgba(138, 138, 138, 0.32)",
                width: "10vw",
                height: "30px",
                borderRadius: "5px",
                padding: "0px",
              }}
              formControlStyle={{
                width: "100%",
                marginLeft: "5px",
                "& .MuiInputBase-formControl": {
                  boxShadow: "unset",
                },
              }}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
export default React.memo(PrescriptionRow);
