import React, { useCallback, useEffect, useState } from "react";
import {
  TableCell,
  TableRow,
  Popper,
  Box,
  List,
  Grid,
  Typography,
  Tooltip,
  CircularProgress,
  debounce,
} from "@mui/material";
import {
  TextField,
  Select,
  DatePicker,
  AutoCompleteWithTable,
} from "../../../../components/basic";
import {
  DeleteIcon,
  ClockHistoryIcon,
  DisabledDelete,
} from "../../../../assets/icons";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import moment from "moment";
import { getAllProcurementProducts } from "../../../../services/procurementService";

type ProductRowProps = {
  styles?: any;
  row?: any;
  billId?: any;
  handleDelete: (index: number, id: number | string) => void;
  allRows?: any;
  summary?: any;
  handleInputChange: any;
  handleDateChange: any;
  handleAutoCompleteChange: any;
  index: number;
  taxesOptions: any;
  error?: any;
  setIsOpenErrorModal?: any;
  setIsOpenPriceMargin?: any;
  purchase_approval?: any;
  infoData?: any;
  billProducts?: any;
};

const ProductRow = React.memo(
  ({
    styles,
    index,
    row,
    billId,
    allRows,
    handleInputChange,
    handleDateChange,
    handleAutoCompleteChange,
    handleDelete,
    taxesOptions,
    error,
    setIsOpenErrorModal,
    setIsOpenPriceMargin,
    purchase_approval,
    infoData,
    billProducts,
  }: ProductRowProps) => {
    const [allProducts, setAllProducts] = useState<any[]>([]);
    const [isProductLoading, setIsProductLoading] = useState(false);

    const PopperMy = function (props: any) {
      const { children, ...rest } = props;
      return (
        <Popper
          {...rest}
          placement="bottom-start"
          className="auto-complete-popper"
          sx={{
            width: "450px",
            p: "10px 0px",
            boxSizing: "border-box",
            "& .MuiPaper-root": {
              width: "450px",
              maxHeight: "250px",
              overflow: "hidden",
              borderRadius: "0px 0px 5px 5px",
              boxSizing: "border-box",
            },
            "& .MuiAutocomplete-listbox": {
              overflowX: "hidden",
              maxHeight: "250px",
              p: 0,
            },
          }}
        >
          <Box
            sx={{
              width: "450px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "var(--table-autocomplete-background)",
              p: "0px 10px 0px 0px",
              height: "45px",
              boxSizing: "border-box",
              borderRadius: "5px 5px 0px 0px",
            }}
          >
            <Typography
              sx={{
                width: "50%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              product Name
            </Typography>
            <Typography
              sx={{
                width: "50%",
                fontSize: "13px",
                fontWeight: "600",
                color: "textPrimary.main",
                textAlign: "left",
                p: "0px 10px",
              }}
            >
              Generic Name
            </Typography>
          </Box>
          {props.children}
        </Popper>
      );
    };

    const { appConfiguration } = useSelector(
      (state: RootState) => state.appConfiguration
    );
    const [isDateSelected, setIsDateSelected] = useState(false);

    const calculateMonthDifference = (date: any) => {
      const today = moment();
      const givenDate = moment(date, "DD/MM/YYYY");

      const monthDifference = today.diff(givenDate, "months");
      return Math.abs(monthDifference);
    };

    const handleBlur = () => {
      setIsDateSelected(true);
    };

    const getAllProcurementProductsData = async (data: any) => {
      const res: any = await getAllProcurementProducts(data);
      if (res.data) {
        setAllProducts(res.data);
      }
      setIsProductLoading(false);
    };

    const debouncedGetAllProcurementList = useCallback(
      debounce((value: string) => {
        const data = {
          department: infoData?.dept_id,
          search: value,
          page: 1,
          limit: 20,
        };

        getAllProcurementProductsData(data);
      }, 500),
      []
    );

    const handleAutoCompleteRowInputChange = (
      e: any,
      newInputValue: any,
      index: number,
      name: string
    ) => {
      handleInputChange(
        {
          target: {
            name,
            value: newInputValue,
          },
        },
        index
      );
      if (newInputValue?.length >= 3) {
        setIsProductLoading(true);
        debouncedGetAllProcurementList(newInputValue);
      }
    };

    useEffect(() => {
      if (isDateSelected) {
        const monthDifference = calculateMonthDifference(row?.expires_at);
        if (monthDifference <= 6) {
          setIsOpenErrorModal({
            isOpen: true,
            content: `This product will expire in ${monthDifference} months.`,
          });
        }
        setIsDateSelected(false);
      }
    }, [row.expires_at]);

    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="left"
          sx={{
            width: "12%",
            "& .MuiAutocomplete-root": {
              height: "31px",
            },
            "& .MuiInputBase-root": {
              height: "32px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
            },
            border: !!error?.product_name ? "2px solid #d32f2f !important" : "",
          }}
        >
          <Tooltip
            title={
              row?.product_name ? (
                <Typography
                  sx={{
                    color: "white",
                    fontSize: "14px",
                  }}
                >
                  {row?.product_name?.name}
                </Typography>
              ) : (
                ""
              )
            }
            arrow
          >
            <Box sx={{ width: "100%" }}>
              <AutoCompleteWithTable
                placeholder="Select Product"
                name="product_name"
                value={row?.product_name}
                onChange={(e: any, newValue: any) =>
                  handleAutoCompleteChange(newValue, index, "product_name")
                }
                options={allProducts}
                renderOption={(props: any, option: any) => (
                  <List
                    {...props}
                    key={option.ID}
                    className="table-body-lists-div"
                    sx={{
                      width: "450px",
                      borderRadius: 0,
                      boxSizing: "border-box",
                      m: "0px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "primary.lighter",
                      },
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width="450px"
                      className="list-row"
                      sx={{
                        boxSizing: "border-box",
                        m: "0px",
                        p: "0px 10px 0px 0px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          width: "50%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {option.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          width: "50%",
                          textAlign: "left",
                          p: "0px 10px",
                        }}
                      >
                        {option.generic_name}
                      </Typography>
                    </Box>
                  </List>
                )}
                isDisabled={row.used_qty > 0 && row.id}
                getOptionLabel={(option: any) => option.name}
                sx={{
                  width: "100%",
                  height: "34px",
                  "& .MuiFormControl-root": {
                    height: "32px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "& .MuiInputBase-root .MuiInputBase-input": {
                    padding: 0,
                    fontSize: "12px",
                    fontWeight: "400",
                  },
                }}
                inputValue={row?.product_value}
                onInputChange={(e: any, newInputValue: any) => {
                  handleAutoCompleteRowInputChange(
                    e,
                    newInputValue,
                    index,
                    "product_value"
                  );
                  // if (newInputValue?.length < 3) {
                  //   setAllProducts([]);
                  // }
                }}
                filterOptions={(options: any) => options}
                PopperComponent={PopperMy}
                isHideClearIcon
                onBlur={() => setAllProducts([])}
                noOptionsText={
                  isProductLoading ? (
                    <div>
                      <CircularProgress
                        style={{ width: "10px", height: "10px" }}
                      />{" "}
                      Loading ...
                    </div>
                  ) : row?.product_value?.length < 3 || !row?.product_value ? (
                    `Please enter ${
                      3 - (row?.product_value?.length || 0)
                    } or more characters`
                  ) : (
                    "No options"
                  )
                }
              />
            </Box>
          </Tooltip>
        </TableCell>
        {billId && (
          <TableCell
            sx={{
              width: "4%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="used_qty"
              value={row.used_qty}
              disabled={true}
              sx={{
                ...styles?.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
        )}
        {billId && !purchase_approval && (
          <TableCell
            sx={{
              width: "4%",
              backgroundColor: "var(--table-cell-disabled-background)",
            }}
          >
            <TextField
              name="used_free"
              value={row.used_free}
              disabled={true}
              sx={{
                ...styles?.textFieldStyle,
                width: "inherit",
              }}
            />
          </TableCell>
        )}
        <TableCell
          sx={{
            width: "4%",
            backgroundColor: "var(--table-cell-disabled-background)",
            border: !!error?.pack_type ? "2px solid #d32f2f !important" : "",
            "& .MuiGrid-root": {
              height: "100%",
            },
            "& .MuiInputBase-root ": {
              height: "100% !important",
              borderRadius: "unset !important",
            },
          }}
        >
          <TextField
            name="pack_type"
            value={row.pack_type}
            disabled={true}
            formControlStyle={{ height: "100%" }}
            sx={{
              ...styles?.textFieldStyle,
              width: "inherit",
              height: "100% !important",
              borderRadius: "unset !important",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            border: !!error?.qty ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="qty"
            value={row.qty}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles?.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            width: "4%",
            backgroundColor: "var(--table-cell-disabled-background)",
            "& .MuiGrid-root": {
              height: "100%",
            },
            "& .MuiInputBase-root ": {
              height: "100% !important",
              borderRadius: "unset !important",
            },
          }}
        >
          <TextField
            name="original_qty"
            value={row.original_qty}
            disabled={true}
            formControlStyle={{ height: "100%" }}
            sx={{
              ...styles?.textFieldStyle,
              width: "inherit",
              height: "100% !important",
              borderRadius: "unset !important",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: !!error?.free ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="free"
            value={row.free}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles?.textFieldStyle,
              width: "inherit",
            }}
          />
        </TableCell>
        {(Boolean(billId)
          ? infoData?.discount_setting !== 1
          : appConfiguration?.purchase_discount !== "overall") && (
          <TableCell
            align="center"
            sx={{
              width: "10%",
              p: "0px 0px",
              border: !!error?.discount ? "2px solid #d32f2f !important" : "",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  width: "60%",
                  "& .MuiFormControl-root": {
                    width: "100%",
                  },
                }}
              >
                <TextField
                  name="discount"
                  value={row.discount}
                  onChange={(e: any) => handleInputChange(e, index)}
                  sx={{
                    ...styles?.textFieldStyle,
                    width: "inherit",
                    "& .MuiInputBase-input": {
                      fontSize: "12px",
                      fontWeight: "400",
                      textAlign: "left",
                      p: "0px 10px",
                    },
                  }}
                />
              </Grid>

              <Grid
                className="select-grid"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "40%",
                  "& .MuiFormControl-root": { width: "100%" },

                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    borderWidth: 0,
                    height: "34px",
                    maxHeight: "34px",
                    p: 0,
                  },

                  "& .MuiInputBase-root": {
                    padding: "0px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  },

                  "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "primary.main",
                      borderWidth: "1px",
                    },

                  "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderWidth: 0,
                    },

                  "& .MuiGrid-root": {
                    width: "100%",
                  },
                }}
              >
                <Select
                  label=""
                  options={[
                    { id: "₹", name: "₹" },
                    { id: "%", name: "%" },
                  ]}
                  placeholder="%"
                  name="row_discount_type"
                  value={row.row_discount_type}
                  onChange={(e: any) => handleInputChange(e, index)}
                  width="inherit"
                  sx={{
                    height: "34px",
                    borderRadius: "0px",
                    "& input": {
                      height: "100%",
                    },
                  }}
                  formControlStyle={{
                    "& .MuiOutlinedInput-input": {
                      height: "100%",
                      "& .MuiBox-root": {
                        height: "100%",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </TableCell>
        )}
        <TableCell
          align="left"
          sx={{
            width: "10%",
            border: !!error?.batch_no ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="batch_no"
            value={row.batch_no}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles?.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "10%",
          }}
        >
          <TextField
            name="barcode"
            value={row.barcode}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles?.textFieldStyle,
              "& .MuiInputBase-input": {
                fontSize: "12px",
                fontWeight: "400",
                textAlign: "left",
                p: "0px 10px",
              },
              width: "inherit",
            }}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            height: "34px",
            p: "0px 0px",
            width: "10%",
            "& .MuiGrid-root": {
              height: "34px",
              width: "100%",
            },
            "& .MuiFormControl-root": {
              height: "34px",
              width: "100%",
              position: "static",
            },
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container ": {
              height: "34px",
              width: "100%",
              border: 0,
              p: "0px 10px",
            },

            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                height: "20px",
                width: "95%",
                p: "0px 10px",
                border: 0,
                m: 0,
                borderWidth: "0px",
                borderRadius: 0,
              },

            "& .rmdp-wrapper": {
              position: "initial",
            },
            border: !!error?.expires_at ? "2px solid #d32f2f !important" : "",
          }}
        >
          <DatePicker
            label=""
            name="expires_at"
            value={row.expires_at}
            onChange={(e: any, newValue: any) =>
              handleDateChange(index, newValue, "expires_at")
            }
            height="32px"
            format="DD-MM-YYYY"
            style={{
              boxShadow: "none",
              border: "0px solid rgba(138, 138, 138, 0.32)",
              width: "100%",
              height: "34px",
              borderRadius: "5px",
              padding: "0px",
            }}
            formControlStyle={{
              "& .icon-tabler": {
                right: "0px !important",
              },
            }}
            onClose={handleBlur}
          />
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            border: !!error?.tax ? "2px solid #d32f2f !important" : "",
          }}
        >
          <Grid
            className="select-grid"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "150px",
              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
                borderWidth: 0,
                height: "34px",
                maxHeight: "34px",
                p: 0,
              },

              "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "primary.main",
                  borderWidth: "1px",
                },

              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderWidth: 0,
                },
            }}
          >
            <Select
              selectedType="object"
              options={taxesOptions}
              name="tax"
              value={row.tax}
              onChange={(e: any) => handleInputChange(e, index)}
              placeholder="Select"
              width="150px"
              sx={{
                width: "150px",
                // height: "34px",
                borderRadius: "0px",
                "& .MuiOutlinedInput-input": {
                  padding: "14px 2px 14px 10px",
                },
                "& input": {
                  // height: "100%",
                },
              }}
              formControlStyle={{
                "& .MuiOutlinedInput-input": {
                  // height: "100%",
                  "& .MuiBox-root": {
                    // height: "100%",
                  },
                },
                "& .MuiInputBase-formControl": {
                  boxShadow: "unset",
                },
              }}
            />
          </Grid>
        </TableCell>
        <TableCell
          align="left"
          sx={{
            width: "6%",
            border: !!error?.p_rate ? "2px solid #d32f2f !important" : "",
          }}
        >
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              "&:has(.Mui-focused) .icon-grid": {
                backgroundColor: "var(--table-cell-background)",
              },
            }}
          >
            <TextField
              width="100px"
              name="p_rate"
              value={row.p_rate}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                ...styles?.textFieldStyle,
                "& .MuiInputBase-input": {
                  p: "0px 10px",
                  fontSize: "12px",
                  fontWeight: "400",
                  textAlign: "left",
                },
              }}
            />
            <Grid
              className="icon-grid"
              sx={{
                width: "33px",
                height: "34px",
                p: "10px",
                cursor: "pointer",
              }}
              onClick={
                row.product_id
                  ? () =>
                      setIsOpenPriceMargin({
                        isOpen: true,
                        product_id: row.product_id,
                      })
                  : undefined
              }
            >
              <ClockHistoryIcon />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            width: "6%",
            border: !!error?.mrp ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            width="100px"
            name="mrp"
            value={row.mrp}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles?.textFieldStyle,
            }}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            backgroundColor: "var(--disabled-background)",
            width: "16%",
            position: "relative",
          }}
        >
          <TextField
            name="amount"
            value={row.amount}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{ ...styles?.textFieldStyle }}
          />

          <Grid className="deleteIconGrid">
            {billProducts?.length === 1 || (row.used_qty > 0 && row.id) ? (
              ""
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => handleDelete(index, row.id)}
              />
            )}

            {/* <TextField
              width="0px"
              name="discountAmount"
              value={row.discount_amount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
            <TextField
              width="0px"
              name="taxAmount"
              value={row.tax_amount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            /> */}
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
