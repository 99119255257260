import DashBoard from "../views/Dashboard";
import React, { lazy, Suspense } from "react";
import { RouteUrls } from "../constants/routes";
import SignIn from "../views/Auth";
import PurchaseBill from "../views/Procurements/PurchaseBill/List";
import AddAndEdit from "../views/Procurements/PurchaseBill/AddAndEdit";
import PurchaseReturn from "../views/Procurements/PurchaseReturn";
import ViewPurchaseReturn from "../views/Procurements/PurchaseReturn/View";
import PurchasePaymentList from "../views/Procurements/PurchasePayments/List";
import LabBills from "../views/Laboratory/LabBills";
import CreatePurchasePayment from "../views/Procurements/PurchasePayments/Payments";
import EditPurchasePayment from "../views/Procurements/PurchasePayments/Payments/ViewAndEdit/PaymentEdit";
import ViewPurchasePayment from "../views/Procurements/PurchasePayments/Payments/ViewAndEdit/PaymentView";
import AdmissionView from "../views/Admission/View";
import { Error, Print } from "../components/shared";
import PatientHistory from "../views/Admission/View/PatientHistory";
import AdmissionList from "../views/Admission/List";
import ProcurementMaster from "../views/Procurements/Master";
import IndentList from "../views/Procurements/Indents/List";
import CreateIndent from "../views/Procurements/Indents/AddAndEdit";
import GoodsIssueList from "../views/Procurements/GoodsIssue/List";

import ViewGoodsIssue from "../views/Procurements/GoodsIssue/View";
import CreateGoodsIssue from "../views/Procurements/GoodsIssue/AddAndEdit";

import ViewStockTransfer from "../views/Procurements/StockTransfer/View";
import CreateStockTransfer from "../views/Procurements/StockTransfer/AddAndEdit";

import GoodsIssueReturn from "../views/Procurements/GoodsIssueReturn";
import StockAdjustmentList from "../views/Procurements/StockAdjustment/List";
import ViewStockAdjustment from "../views/Procurements/StockAdjustment/View";
import CreateStockAdjustment from "../views/Procurements/StockAdjustment/AddAndEdit";
import PurchaseOrdersList from "../views/Procurements/PurchaseOrders";
import SalesBillsList from "../views/Pharmacy/SalesBill/List";

// import CreateSalesBill from "../views/Pharmacy/SalesBill/AddAndEdit";

import SalesReturn from "../views/Pharmacy/SalesReturn/AddAndEdit";
import ViewSalesBill from "../views/Pharmacy/SalesBill/View";
import ViewPurchaseBill from "../views/Procurements/PurchaseBill/View";
import GynecologyList from "../views/Pregnancy/List";
import GynecologyView from "../views/Pregnancy/View";
import AddFollowUp from "../views/Pregnancy/View/AddFollowUp";
import ViewIndents from "../views/Procurements/Indents/View";
import ViewGoodsIssueReturn from "../views/Procurements/GoodsIssueReturn/View";
import PurchaseOrderView from "../views/Procurements/PurchaseOrders/View";
import AddLabBill from "../views/Laboratory/LabBills/AddLabBills";
import ViewLabBill from "../views/Laboratory/LabBills/ViewLabBill";
import LaboratoryMaster from "../views/Laboratory/Master";
import LabPurchaseReturn from "../views/Laboratory/PurchaseReturn";
import LabGoodsIssueList from "../views/Laboratory/GoodsIssue/List";
import LabCreateGoodsIssue from "../views/Laboratory/GoodsIssue/AddAndEdit";
import LabViewGoodsIssue from "../views/Laboratory/GoodsIssue/View";
import LabStockAdjustmentList from "../views/Laboratory/StockAdjustment/List";
import LabViewStockAdjustment from "../views/Laboratory/StockAdjustment/View";
import LabCreateStockAdjustment from "../views/Laboratory/StockAdjustment/AddAndEdit";
import LabGoodsIssueReturn from "../views/Laboratory/GoodsIssueReturn";

import LabPurchasePaymentList from "../views/Laboratory/PurchasePayments/List";
import LabCreatePurchasePayment from "../views/Laboratory/PurchasePayments/Payments";
import LabEditPurchasePayment from "../views/Laboratory/PurchasePayments/Payments/ViewAndEdit/PaymentEdit";
import LabViewPurchasePayment from "../views/Laboratory/PurchasePayments/Payments/ViewAndEdit/PaymentView";

import WholeSalesPaymentList from "../views/Procurements/B2BPayments/List";
import WholeSalesCreatePurchasePayment from "../views/Procurements/B2BPayments/Payments";
import WholeSalesEditPurchasePayment from "../views/Procurements/B2BPayments/Payments/ViewAndEdit/PaymentEdit";
import WholeSalesPurchasePayment from "../views/Procurements/B2BPayments/Payments/ViewAndEdit/PaymentView";

import WholeSalesList from "../views/Procurements/B2B/List";
import WholeSalesAddAndEdit from "../views/Procurements/B2B/AddAndEdit";
import WholeSalesViewPurchaseBill from "../views/Procurements/B2B/View";
import WholeSalesPurchaseReturn from "../views/Procurements/B2BReturn";
import WholeSalesReturnView from "../views/Procurements/B2BReturn/View";
import WholeSalesAdjustmentList from "../views/Procurements/B2BAdjustment/list";
import LabPurchaseBillList from "../views/Laboratory/LabPurchase/List";
import LabPurchaseAddAndEdit from "../views/Laboratory/LabPurchase/AddAndEdit";
import ViewLabPurchaseBill from "../views/Laboratory/LabPurchase/View";
import LabIntentList from "../views/Laboratory/Indents/List";
import CreateLabIndent from "../views/Laboratory/Indents/AddAndEdit";
import ViewLabIndents from "../views/Laboratory/Indents/View";
import LabPurchaseOrdersList from "../views/Laboratory/PurchaseOrders";
import LabPurchaseOrderView from "../views/Laboratory/PurchaseOrders/View";
// import BillDraft from "../views/Billing/Bills/BillDraft";
// import PharmacyBillDraft from "../views/Pharmacy/SalesBill/BillDraft";
// import ViewPharmacyBillDraft from "../views/Pharmacy/SalesBill/BillDraft/View";
// import ViewPharmacyReturnBill from "../views/Pharmacy/SalesReturn/View";
// import PharmacyBillDraftSalesBill from "../views/Pharmacy/SalesBill/BillDraft/AddAndEdit";
import BookAppointments from "../views/Appointments/BookAppointments";
import AppointmentsList from "../views/Appointments/AppointmentsList/AppointmentsList";
import MastersScreen from "../views/Appointments/Masters/mastersScreen";
import AppointmentsDetails from "../views/Appointments/AppointmentsDetails";
import ViewReport from "../views/Appointments/AppointmentsDetails/MedicalRecords/viewreport";
import BillingItems from "../views/Billing/Items";
import BillingAddItems from "../views/Billing/Items/AddItems";
import BillsList from "../views/Billing/Bills/List";
import BillsAddAndEdit from "../views/Billing/Bills/AddAndEdit";
import BillsView from "../views/Billing/Bills/View";
import BillingItemsMaster from "../views/Billing/Items/ItemsMaster";
import PaymentsList from "../views/Payments/PaymentList";
import PaymentView from "../views/Payments/Payments/ViewAndEdit/PaymentView";
import PaymentsEdit from "../views/Payments/Payments/ViewAndEdit/PaymentEdit";
import Payments from "../views/Payments/Payments";
import Accounts from "../views/Accounts/Accounts";
import EditUser from "../views/Accounts/EditUser";
import PermissionUtils from "../utils/PermissionUtils";
import AdjustmentList from "../views/Payments/Adjustments/list";
import BillDraftView from "../views/Billing/Bills/BillDraft/View";
import Campaigns from "../views/Campaigns/CampaignList";
import InhouseLeads from "../views/Campaigns/InhouseLeads";
import ColdLeads from "../views/Campaigns/ColdLeads";
import PatientsList from "../views/Patients/List";
import PatientsHistory from "../views/Patients/History";
import VaccinesList from "../views/Vaccines/Vaccines";
import VaccinesSearch from "../views/Vaccines/Search";
import VaccineMaster from "../views/Vaccines/Master";
import AddAndEditOperationTheatre from "../views/Admission/View/PatientHistory/OperationTheatre/AddAndEditOperationTheatre";
import OtJob from "../views/Admission/View/PatientHistory/OperationTheatre/OtJob";
import AddPurchaseOrder from "../views/Procurements/PurchaseOrders/Add";

const PharmacyBillDraftSalesBill = React.lazy(
  () => import("../views/Pharmacy/SalesBill/BillDraft/AddAndEdit")
);
const CreateSalesBill = React.lazy(
  () => import("../views/Pharmacy/SalesBill/AddAndEdit")
);
const BillDraft = React.lazy(() => import("../views/Billing/Bills/BillDraft"));
const PharmacyBillDraft = React.lazy(
  () => import("../views/Pharmacy/SalesBill/BillDraft")
);
const ViewPharmacyReturnBill = React.lazy(
  () => import("../views/Pharmacy/SalesReturn/View")
);
const ViewPharmacyBillDraft = React.lazy(
  () => import("../views/Pharmacy/SalesBill/BillDraft/View")
);
const Routes = () => {
  const { can } = PermissionUtils();
  const {
    accountsUrl,
    masterUrl,
    signInUrl,
    createUrl,
    viewUrl,
    adjustmentUrl,
    editUrl,
    labMasterUrl,
    purchaseBillUrl,
    labPurchaseReturnUrl,
    purchasePaymentListUrl,
    purchaseReturnUrl,
    goodsIssueReturnUrl,
    indentsListUrl,
    goodsIssueUrl,
    stockAdjustmentUrl,
    purchaseOrdersUrl,
    salesBillListUrl,
    salesReturnUrl,
    view,
    labBillsUrl,
    billDraft,
    addFollowUpUrl,
    labPurchaseUrl,
    labIndentsListUrl,
    labPurchaseOrderUrl,
    labGoodsIssue,
    labIssueStockAdjustments,
    labReturnLabGoodsIssue,
    labPurchasePayments,
    wholeSalesUrl,
    wholeSalesReturn,
    gynecologyUrl,
    wholeSalesPayments,
    appointmentUrl,
    dashboardUrl,
    billingUrl,
    appointmentsBooking,
    listUrl,
    appointmentsDetails,
    rescheduleAppointment,
    reviewAppointment,
    viewRecords,
    laboratoryUrl,
    report,
    itemsUrl,
    addItemUrl,
    ItemsMasterUrl,
    edit,
    billingListUrl,
    paymentUrl,
    procurementUrl,
    pharmacyUrl,
    editUserUrl,
    campaignUrl,
    inhouseLeadsUrl,
    coldLeadsUrl,
    billDraftUrl,
    inPatientsUrl,
    ipSummary,
    dischargeSummary,
    add,
    appointmentsList,
    paymentAdjustmentUrl,
    historyUrl,
    patientUrl,
    vaccinesUrl,
    searchUrl,
    operationTheatreUrl,
    otJobUrl,
    stockTransferUrl,
    overviewUrl,
    printUrl,
  } = RouteUrls;

  const privateRoutes = [
    {
      path: dashboardUrl,
      Component: <DashBoard />,
    },
    {
      path: `${appointmentUrl}${appointmentsBooking}`,
      Component: <BookAppointments />,
    },
    {
      path: `${appointmentUrl}${appointmentsList}`,
      Component: can("appointments_list") ? <AppointmentsList /> : <Error />,
    },
    {
      path: `${appointmentUrl}${masterUrl}`,
      Component:
        can("workhour_list") ||
        can("symptoms_view") ||
        can("print_note_view") ||
        can("pxproducts_view") ||
        can("vaccation_list") ? (
          <MastersScreen />
        ) : (
          <Error />
        ),
    },
    {
      path: `${appointmentUrl}${appointmentsDetails}/:appointmentId`,
      Component: can("appointments_view") ? <AppointmentsDetails /> : <Error />,
    },
    {
      path: accountsUrl,
      Component: <Accounts />,
    },
    {
      path: `${accountsUrl}${editUserUrl}`,
      Component: <EditUser />,
    },
    {
      path: `${appointmentUrl}${rescheduleAppointment}/:appointmentId`,
      Component: <BookAppointments />,
    },
    {
      path: `${appointmentUrl}${reviewAppointment}/:reviewAppointmentId`,
      Component: <BookAppointments />,
    },
    {
      path: `${appointmentUrl}${viewRecords}${report}/:appointmentId`,
      Component: <ViewReport />,
    },
    {
      path: `${billingUrl}${itemsUrl}`,
      Component: can("billing_items_list") ? <BillingItems /> : <Error />,
    },
    {
      path: `${billingUrl}${addItemUrl}`,
      Component: can("billing_items_add") ? <BillingAddItems /> : <Error />,
    },
    {
      path: `${billingUrl}${addItemUrl}/:id`,
      Component: can("billing_items_edit") ? <BillingAddItems /> : <Error />,
    },
    {
      path: `${billingUrl}${billingListUrl}`,
      Component: can("billing_view") ? <BillsList /> : <Error />,
    },
    {
      path: `${billingUrl}${createUrl}`,
      Component: can("billing_add") ? <BillsAddAndEdit /> : <Error />,
    },
    {
      path: `${billingUrl}${listUrl}/:id`,
      Component: can("billing_edit") ? <BillsAddAndEdit /> : <Error />,
    },
    {
      path: `${billingUrl}${viewUrl}/:id`,
      Component: can("billing_view") ? <BillsView /> : <Error />,
    },
    {
      path: `${billingUrl}${ItemsMasterUrl}`,
      Component:
        can("billing_items_Master") ||
        can("items_group_list") ||
        can("methodology_list") ||
        can("lab_result_list") ? (
          <BillingItemsMaster />
        ) : (
          <Error />
        ),
    },
    {
      path: `${billingUrl}${billDraftUrl}`,
      Component: can("billing_draft") ? <BillDraft /> : <Error />,
    },
    {
      path: `${billingUrl}${billDraftUrl}${viewUrl}/:id`,
      Component: can("billing_draft") ? <BillDraftView /> : <Error />,
    },
    {
      path: `${paymentUrl}${createUrl}`,
      Component: can("payment_add") ? <Payments /> : <Error />,
    },
    {
      path: `${paymentUrl}${listUrl}`,
      Component: can("payment_view") ? <PaymentsList /> : <Error />,
    },
    {
      path: `${paymentUrl}${viewUrl}/:id`,
      Component: can("payment_view") ? <PaymentView /> : <Error />,
    },
    {
      path: `${paymentUrl}${edit}/:id`,
      Component: can("payment_edit") ? <PaymentsEdit /> : <Error />,
    },
    {
      path: `${paymentUrl}${paymentAdjustmentUrl}`,
      Component: can("adjustment_view") ? <AdjustmentList /> : <Error />,
    },
    {
      path: `${procurementUrl}${purchaseBillUrl}${createUrl}`,
      Component: can("purchasebill_add") ? <AddAndEdit /> : <Error />,
    },
    {
      path: `${procurementUrl}${purchaseBillUrl}${editUrl}/:id`,
      Component: can("purchasebill_edit") ? <AddAndEdit /> : <Error />,
    },
    {
      path: `${procurementUrl}${purchaseBillUrl}${viewUrl}/:id`,
      Component:
        can("purchasebill_view") || can("purchasebill_temp_is_draft") ? (
          <ViewPurchaseBill />
        ) : (
          <Error />
        ),
    },
    {
      path: `${procurementUrl}${purchaseBillUrl}`,
      Component:
        can("purchasebill_view") || can("purchasebill_temp_is_draft") ? (
          <PurchaseBill />
        ) : (
          <Error />
        ),
    },
    {
      path: `${procurementUrl}${purchasePaymentListUrl}`,
      Component: can("purchasebill_pay_view") ? (
        <PurchasePaymentList />
      ) : (
        <Error />
      ),
    },
    {
      path: `${procurementUrl}${purchasePaymentListUrl}${createUrl}`,
      Component: <CreatePurchasePayment />,
    },
    {
      path: `${procurementUrl}${purchasePaymentListUrl}${editUrl}/:id`,
      Component: <EditPurchasePayment />,
    },
    {
      path: `${procurementUrl}${purchasePaymentListUrl}${viewUrl}/:id`,
      Component: <ViewPurchasePayment />,
    },
    //
    {
      path: `${procurementUrl}${purchaseReturnUrl}`,
      Component: can("purchase_return_add") ? <PurchaseReturn /> : <Error />,
    },
    {
      path: `${procurementUrl}${purchaseReturnUrl}/:id`,
      Component: <PurchaseReturn />,
    },
    {
      path: `${procurementUrl}${purchaseReturnUrl}${viewUrl}/:id`,
      Component: <ViewPurchaseReturn />,
    },
    {
      path: `${procurementUrl}${masterUrl}`,
      Component:
        can("products_list") || can("suppliers_list") || can("gst_list") ? (
          <ProcurementMaster />
        ) : (
          <Error />
        ),
    },
    {
      path: `${procurementUrl}${goodsIssueReturnUrl}`,
      Component: can("goods_issue_return_add") ? (
        <GoodsIssueReturn />
      ) : (
        <Error />
      ),
    },
    {
      path: `${procurementUrl}${goodsIssueReturnUrl}/:id`,
      Component: can("goods_issue_return_add") ? (
        <GoodsIssueReturn />
      ) : (
        <Error />
      ),
    },
    {
      path: `${procurementUrl}${goodsIssueReturnUrl}${editUrl}/:id`,
      Component: <GoodsIssueReturn />,
    },
    {
      path: `${procurementUrl}${goodsIssueReturnUrl}${viewUrl}/:id`,
      Component:
        can("goods_issue_view") || can("goods_issue_temp_is_draft") ? (
          <ViewGoodsIssueReturn />
        ) : (
          <Error />
        ),
    },
    {
      path: `${procurementUrl}${indentsListUrl}`,
      Component: can("indents_view") ? <IndentList /> : <Error />,
    },
    {
      path: `${procurementUrl}${indentsListUrl}${createUrl}`,
      Component: <CreateIndent />,
    },
    {
      path: `${procurementUrl}${indentsListUrl}${editUrl}/:id`,
      Component: <CreateIndent />,
    },
    {
      path: `${procurementUrl}${indentsListUrl}${viewUrl}/:id`,
      Component: can("indents_view") ? <ViewIndents /> : <Error />,
    },
    {
      path: `${procurementUrl}${goodsIssueUrl}`,
      Component: can("goods_issue_view") ? <GoodsIssueList /> : <Error />,
    },
    {
      path: `${procurementUrl}${goodsIssueUrl}${createUrl}`,
      Component: <CreateGoodsIssue />,
    },
    {
      path: `${procurementUrl}${goodsIssueUrl}${editUrl}/:id`,
      Component: <CreateGoodsIssue />,
    },
    {
      path: `${procurementUrl}${goodsIssueUrl}/:bill_type${viewUrl}/:id`,
      Component:
        can("goods_issue_view") || can("goods_issue_temp_is_draft") ? (
          <ViewGoodsIssue />
        ) : (
          <Error />
        ),
    },
    {
      path: `${procurementUrl}${stockTransferUrl}${viewUrl}/:id`,
      Component: <ViewStockTransfer />,
    },
    {
      path: `${procurementUrl}${stockTransferUrl}`,
      Component: can("goods_issue_transfer_add") ? (
        <CreateStockTransfer />
      ) : (
        <Error />
      ),
    },
    {
      path: `${procurementUrl}${stockTransferUrl}/:id`,
      Component: <CreateStockTransfer />,
    },

    {
      path: `${procurementUrl}${stockAdjustmentUrl}`,
      Component: can("goods_issue_stock_adj_view") ? (
        <StockAdjustmentList />
      ) : (
        <Error />
      ),
    },
    {
      path: `${procurementUrl}${stockAdjustmentUrl}${createUrl}`,
      Component: <CreateStockAdjustment />,
    },
    {
      path: `${procurementUrl}${stockAdjustmentUrl}${viewUrl}/:dept_id`,
      Component: <ViewStockAdjustment />,
    },
    {
      path: `${procurementUrl}${purchaseOrdersUrl}`,
      Component: can("purchase_order_view") ? (
        <PurchaseOrdersList />
      ) : (
        <Error />
      ),
    },
    {
      path: `${procurementUrl}${purchaseOrdersUrl}${viewUrl}/:id`,
      Component: can("purchase_order_view") ? <PurchaseOrderView /> : <Error />,
    },
    {
      path: `${procurementUrl}${purchaseOrdersUrl}${overviewUrl}`,
      Component: can("purchase_order_add") ? <AddPurchaseOrder /> : <Error />,
    },
    {
      path: `${pharmacyUrl}${salesBillListUrl}`,
      Component: can("salesbill_view") ? <SalesBillsList /> : <Error />,
    },
    {
      path: `${pharmacyUrl}${salesBillListUrl}${createUrl}`,
      Component: can("salesbill_add") ? <CreateSalesBill /> : <Error />,
    },
    // {
    //   path: `${pharmacyUrl}${salesBillListUrl}${createUrl}`,
    //   Component: <CreateSalesBill />,
    // },
    {
      path: `${pharmacyUrl}${salesBillListUrl}${editUrl}/:id`,
      Component: <CreateSalesBill />,
    },
    {
      path: `${pharmacyUrl}${billDraft}`,
      Component: <PharmacyBillDraft />,
    },
    {
      path: `${pharmacyUrl}${billDraft}${viewUrl}/:id`,
      Component: <ViewPharmacyBillDraft />,
    },
    {
      path: `${pharmacyUrl}${billDraft}${createUrl}/:id`,
      Component: <PharmacyBillDraftSalesBill />,
    },
    {
      path: `${pharmacyUrl}${salesBillListUrl}${viewUrl}/:id`,
      Component: can("salesbill_view") ? <ViewSalesBill /> : <Error />,
    },
    {
      path: `${pharmacyUrl}${salesReturnUrl}`,
      Component: can("sales_return_view") ? <SalesReturn /> : <Error />,
    },
    {
      path: `${pharmacyUrl}${salesReturnUrl}/:id`,
      Component: can("sales_return_view") ? <SalesReturn /> : <Error />,
    },
    {
      path: `${pharmacyUrl}${salesReturnUrl}${viewUrl}/:id`,
      Component: can("sales_return_view") ? (
        <ViewPharmacyReturnBill />
      ) : (
        <Error />
      ),
    },
    {
      path: `${gynecologyUrl}${listUrl}`,
      Component: can("gynecology_view") ? <GynecologyList /> : <Error />,
    },
    {
      path: `${gynecologyUrl}${view}/:id`,
      Component: can("gynecology_view") ? <GynecologyView /> : <Error />,
    },
    {
      path: `${gynecologyUrl}${addFollowUpUrl}`,
      Component: <AddFollowUp />,
    },
    {
      path: `${gynecologyUrl}${addFollowUpUrl}/:id`,
      Component: <AddFollowUp />,
    },
    {
      path: `${laboratoryUrl}${labBillsUrl}`,
      Component: can("lab_bills_list") ? <LabBills /> : <Error />,
    },
    {
      path: `${laboratoryUrl}${labBillsUrl}${createUrl}/:id`,
      Component: can("labreport_add") ? <AddLabBill /> : <Error />,
    },
    {
      path: `${laboratoryUrl}${labBillsUrl}/:id`,
      Component: can("labreport_edit") ? <AddLabBill /> : <Error />,
    },
    {
      path: `${laboratoryUrl}${labBillsUrl}${viewUrl}/:id`,
      Component: can("labreport_view") ? <ViewLabBill /> : <Error />,
    },
    {
      path: `${laboratoryUrl}${labMasterUrl}`,
      Component:
        can("lab_products_list") ||
        can("lab_suppliers_list") ||
        can("lab_tax_list") ||
        can("lab_test_products_list") ? (
          <LaboratoryMaster />
        ) : (
          <Error />
        ),
    },
    {
      path: `${laboratoryUrl}${labPurchaseReturnUrl}`,
      Component: can("lab_purchase_return_add") ? (
        <LabPurchaseReturn />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labPurchaseReturnUrl}/:id`,
      Component: can("lab_purchase_return_edit") ? (
        <LabPurchaseReturn />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labGoodsIssue}`,
      Component: can("lab_goods_issue_view") ? (
        <LabGoodsIssueList />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labGoodsIssue}${createUrl}`,
      Component: can("lab_goods_issue_add") ? (
        <LabCreateGoodsIssue />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labGoodsIssue}${editUrl}/:id`,
      Component: can("lab_goods_issue_edit") ? (
        <LabCreateGoodsIssue />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labGoodsIssue}${viewUrl}/:id`,
      Component: can("lab_goods_issue_view") ? (
        <LabViewGoodsIssue />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labIssueStockAdjustments}`,
      Component: can("lab_goods_issue_stock_adj_view") ? (
        <LabStockAdjustmentList />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labIssueStockAdjustments}${createUrl}`,
      Component: can("lab_goods_issue_stock_adj_add") ? (
        <LabCreateStockAdjustment />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labIssueStockAdjustments}${viewUrl}/:dept_id`,
      Component: can("lab_goods_issue_stock_adj_view") ? (
        <LabViewStockAdjustment />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labReturnLabGoodsIssue}`,
      Component: can("lab_goods_issue_return_add") ? (
        <LabGoodsIssueReturn />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labReturnLabGoodsIssue}/:id`,
      Component: can("lab_goods_issue_return_edit") ? (
        <LabGoodsIssueReturn />
      ) : (
        <Error />
      ),
    },

    {
      path: `${laboratoryUrl}${labPurchasePayments}`,
      Component: can("lab_purchasebill_pay_view") ? (
        <LabPurchasePaymentList />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labPurchasePayments}${createUrl}`,
      Component: can("lab_purchasebill_pay_add") ? (
        <LabCreatePurchasePayment />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labPurchasePayments}${editUrl}/:id`,
      Component: can("lab_purchasebill_pay_edit") ? (
        <LabEditPurchasePayment />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labPurchasePayments}${viewUrl}/:id`,
      Component: can("lab_purchasebill_pay_view") ? (
        <LabViewPurchasePayment />
      ) : (
        <Error />
      ),
    },
    {
      path: `${inPatientsUrl}${listUrl}`,
      Component: <AdmissionList />,
    },
    {
      path: `${inPatientsUrl}${add}`,
      Component: can("ip_add") ? <AdmissionView /> : <Error />,
    },
    {
      path: `${inPatientsUrl}${edit}/:id`,
      Component: can("ip_edit") ? <AdmissionView /> : <Error />,
    },
    {
      path: `${inPatientsUrl}${ipSummary}/:id`,
      Component: can("ip_view") ? <PatientHistory /> : <Error />,
    },
    {
      path: printUrl,
      Component: <Print />,
    },
    {
      path: `${inPatientsUrl}${operationTheatreUrl}${add}/:admissionId`,
      Component: <AddAndEditOperationTheatre />,
    },
    {
      path: `${inPatientsUrl}${operationTheatreUrl}${editUrl}/:otId`,
      Component: <AddAndEditOperationTheatre />,
    },
    {
      path: `${inPatientsUrl}${operationTheatreUrl}${viewUrl}/:otId`,
      Component: <AddAndEditOperationTheatre />,
    },
    {
      path: `${inPatientsUrl}${operationTheatreUrl}${otJobUrl}/:otJobId`,
      Component: <OtJob />,
    },
    {
      path: `${procurementUrl}${wholeSalesUrl}`,
      Component: can("whole_sales_view") ? <WholeSalesList /> : <Error />,
    },

    {
      path: `${procurementUrl}${wholeSalesUrl}${createUrl}`,
      Component: <WholeSalesAddAndEdit />,
    },
    {
      path: `${procurementUrl}${wholeSalesUrl}${editUrl}/:id`,
      Component: <WholeSalesAddAndEdit />,
    },
    {
      path: `${procurementUrl}${wholeSalesUrl}${viewUrl}/:id`,
      Component: <WholeSalesViewPurchaseBill />,
    },
    {
      path: `${procurementUrl}${wholeSalesReturn}`,
      Component: can("whole_sales_return_add") ? (
        <WholeSalesPurchaseReturn />
      ) : (
        <Error />
      ),
    },
    {
      path: `${procurementUrl}${wholeSalesReturn}${editUrl}/:id`,
      Component: <WholeSalesPurchaseReturn />,
    },
    {
      path: `${procurementUrl}${wholeSalesReturn}${viewUrl}/:id`,
      Component: <WholeSalesReturnView />,
    },
    {
      path: `${laboratoryUrl}${labPurchaseUrl}`,
      Component: can("lab_purchasebill_view") ? (
        <LabPurchaseBillList />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labPurchaseUrl}${createUrl}`,
      Component: can("lab_purchasebill_add") ? (
        <LabPurchaseAddAndEdit />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labPurchaseUrl}${editUrl}/:id`,
      Component: can("lab_purchasebill_edit") ? (
        <LabPurchaseAddAndEdit />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labPurchaseUrl}${viewUrl}/:id`,
      Component: can("lab_purchasebill_view") ? (
        <ViewLabPurchaseBill />
      ) : (
        <Error />
      ),
    },
    {
      path: `${laboratoryUrl}${labIndentsListUrl}`,
      Component: can("lab_indents_view") ? <LabIntentList /> : <Error />,
    },
    {
      path: `${laboratoryUrl}${labIndentsListUrl}${createUrl}`,
      Component: can("lab_indents_add") ? <CreateLabIndent /> : <Error />,
    },
    {
      path: `${laboratoryUrl}${labIndentsListUrl}${editUrl}/:id`,
      Component: can("lab_indents_edit") ? <CreateLabIndent /> : <Error />,
    },
    {
      path: `${laboratoryUrl}${labIndentsListUrl}${viewUrl}/:id`,
      Component: can("lab_indents_view") ? <ViewLabIndents /> : <Error />,
    },
    {
      path: `${laboratoryUrl}${labPurchaseOrderUrl}`,
      Component: <LabPurchaseOrdersList />,
    },
    {
      path: `${laboratoryUrl}${labPurchaseOrderUrl}${viewUrl}/:id`,
      Component: <LabPurchaseOrderView />,
    },
    {
      path: `${procurementUrl}${adjustmentUrl}`,
      Component: can("adjustment_view") ? (
        <WholeSalesAdjustmentList />
      ) : (
        <Error />
      ),
    },
    {
      path: `${procurementUrl}${wholeSalesPayments}`,
      Component: can("whole_sales_pay_view") ? (
        <WholeSalesPaymentList />
      ) : (
        <Error />
      ),
    },
    {
      path: `${procurementUrl}${wholeSalesPayments}${createUrl}`,
      Component: <WholeSalesCreatePurchasePayment />,
    },
    {
      path: `${procurementUrl}${wholeSalesPayments}${editUrl}/:id`,
      Component: <WholeSalesEditPurchasePayment />,
    },
    {
      path: `${procurementUrl}${wholeSalesPayments}${viewUrl}/:id`,
      Component: <WholeSalesPurchasePayment />,
    },
    {
      path: `${campaignUrl}${listUrl}`,
      Component: <Campaigns />,
    },
    {
      path: `${campaignUrl}${inhouseLeadsUrl}`,
      Component: <InhouseLeads />,
    },
    {
      path: `${campaignUrl}${coldLeadsUrl}`,
      Component: <ColdLeads />,
    },
    {
      path: `${patientUrl}${listUrl}`,
      Component: can("patients_account") ? <PatientsList /> : <Error />,
    },
    {
      path: `${patientUrl}${historyUrl}/:id`,
      Component: can("patients_account") ? <PatientsHistory /> : <Error />,
    },
    {
      path: `${vaccinesUrl}${listUrl}`,
      Component: <VaccinesList />,
    },
    {
      path: `${vaccinesUrl}${searchUrl}`,
      Component: <VaccinesSearch />,
    },
    {
      path: `${vaccinesUrl}${masterUrl}`,
      Component: <VaccineMaster />,
    },
  ];

  const publicRoutes = [
    { path: signInUrl, Component: <SignIn /> },
    // {
    //   path: organizations,
    //   Component: <Organization />,
    // },
  ];

  return { privateRoutes, publicRoutes };
};

export default Routes;
