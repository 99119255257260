import React from "react";
import { Grid } from "@mui/material";
import BillProducts from "./BillProducts";

const AddPurchaseOrder = () => {
  return (
    <Grid
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        p: "0 24px 0 20px",
      }}
    >
      <BillProducts />
    </Grid>
  );
};

export default AddPurchaseOrder;
