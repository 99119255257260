import { ChatbotIcon, EmailIcon, SMSIcon, WhatsappIcon } from "../assets/icons";

export const styleText = {
  ROW: "row",
  DEFAULT_SELECT_WIDTH: "427px",
  DEFAULT_TEXTFIELD_WIDTH: "427px",
};
export const chemicalReagentsTestTypesOptions = [
  { id: 0, name: "New" },
  { id: 1, name: "Retest" },
];
export const discountSettings: { [key: number]: string } = {
  0: "itemwise",
  1: "overall",
  2: "both",
};
export const rdTypes = ["IP", "OP", "Counter Sales"];
export const muiProps = {
  DATE_FORMAT: "DD/MM/YYYY",
  RADIO_NAME: "radio-buttons-group",
  RADIO_ARIA_LABELLEDBY: "demo-radio-buttons-group-label",
};
export const labPurchaseReturnConst = {
  productName: "Product Name",
  maxQty: "Max.Qty",
  fQty: "F.Qty",
  maxBox: "Max.Box",
  fBox: "F.Box",
  retBox: "Ret.Box",
  retQty: "Ret.Qty ",
  fRetBox: "F.Ret.Box",
  fRetQty: "F.Ret.Qty",
  disc: "Disc %",
  batNo: "Bat.No",
  expDate: "Exp.Date",
  tax: "Tax %",
  ptr: "PTR",
  amount: "Amount",
};
export const indentStatusOptions = [
  { id: 2, name: "Canceled" },
  { id: 3, name: "Ordered", disabled: true },
  { id: 4, name: "Discontinued" },
  { id: 5, name: "Received" },
];

export const appointmentDetailsConst = {
  APPOINTMENT_CONFIRMED: "Appointment Confirmed",
  APPOINTMENT_CANCELLED: "Appointment Cancelled",
  APPOINTMENT_APPOINTMENT: "Appointment Rescheduled",
  REVIEW_APPOINTMENT_CONFIRMED: "Review Appointment Confirmed",
  ADDITIONAL_DETAILS: "Additional Details",
  REFERRAL_DOCTOR: "Referral Doctor",
  NEXT_REVIEW_DATE: "Next Review Date",
  DIAGNOSIS_INFORMATION: "Diagnosis Information",
  NOTES: "Notes",
  ADD_BILL: " +Add Bill",
  PRESCRIPTIONS: "Prescriptions",
  VITALS: "Vitals",
  DIAGNOSIS_SAVED_SUCCESS: "Diagnosis Saved Successfully",
  INVESTIGATIONS: "Investigations",
  MEDICAL_RECORDS: "Medical Records",
  PATIENT_ARRIVED: "Patient Arrived",
  CONSULTING_IN: "Consulting In",
  IN_PROGRESS: "In Progress",
  PATIENT_OUT: "Patient Out",
  UPCOMING: "Upcoming",
  OBSERVATION: "Observations",
  ADD_NEW: "+Add New",
  REPORT_CREATED_SUCCESS: "Report Created Successfully",
  REPORT_UPDATED_SUCCESS: "Report Updated Successfully",
  REPORT_DELETED_SUCCESS: "Report Deleted Successfully",
};

export const appointmentsMasterConst = {
  START_TIME: "Start Time",
  END_TIME: "End Time",
  TIME_FORMAT: "dd/MM/YYYY hh:mm A",
  INTERVAL: "Interval",
  ACTIONS: "Actions",
  DAY_NAME: "Day Name",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  STATUS: "Status",
  EDIT_CONSULTATION: "Edit Consultation",
  CONSULTATIONS: "Consultations",
  ADD_CONSULTATION: "Add Consultation Type",
  SELECT_DOCTOR: "Select Doctor",
  DOCTOR_NAME: "Doctor Name",
  PRINT_NOTE_MASTER: "Print Note Master",
  ADD_PRINT_NOTE: "Add Print Note",
  EDIT_PRINT_NOTE: "Edit Print Note",
  SELECT_SECTION: "Select Section",
  SECTION: "Section",
  CONTENT: "Content",
  TYPE: "Type",
  PRODUCT_LIST: "Product List",
  ADD_PRODUCT: "Add Product",
  EDIT_PRODUCT: "Edit Product",
  NAME: "Name",
  GENERIC_NAME: "Generic Name",
  DOSAGE: "Dosage",
  SELECT_DOSAGE: "Select Dosage",
  PRESCRIPTION: "Prescription",
  ADD_VACATION: "Add Vacation",
  EDIT_VACATION: "Edit Vacation",
  VACATIONS: "Vacations",
  ADD_WORKING_HOURS: "Add Working Hours",
  EDIT_WORKING_HOURS: "Edit Working Hours",
  WORKING_HOURS: "Working Hours",
  SYMPTOMS_MASTER: "Symptoms Master",
  ADD_SYMPTOMS: "Add Symptoms",
  EDIT_SYMPTOMS: "Edit Symptoms",
  SYMPTOM_NAME: "Symptom Name",
  DELETED_SUCCESSFULLY: "Deleted Successfully",
  ADDED_SUCCESSFULLY: "Added Successfully",
  UPDATED_SUCCESSFULLY: "Updated Successfully",
  ADD_ATLEAST_ONE_SLOT: " Please add Atleast one slot",
};

export const commonText = {
  DELETE: "Delete",
  EDIT: "Edit",
  EDIT_BUTTON_TITLE: "+Add",
};

export const errorMessage = {
  ERROR_FETCHING_PRODUCTS: "Error Fetching Product List",
  ERROR_FETCHING_VACATIONS: "Error Fetching Vacation List",
  ERROR_FETCHING_CONSULTATIONS: "Error Fetching Consultation List",
  ERROR_FETCHING_WORKINGHOURS: "Error Fetching Working Hours List",
  ERROR_FETCHING_PRINTNOTE: "Error Fetching Print Note List",
  ERROR_FETCHING_SYMPTOMS: "Error Fetching Symptoms List",
  SERVER_RESPONSE_ERROR: "Server responded with:",
  NO_SERVER_RESPONSE: "Request made but no response received: ",
  FALLBACK_ERROR: "Error setting up the request",
  ERROR_FETCHING_DOCTORS: "Error Fetching Doctors",
  SESSION_TIMEOUT: "Session Timeout!",
  UNAUTHORIZED: "Unauthorized Access",
  SERVER_ERROR: "Something went wrong!",
  ERROR_FETCHING_STOCK_ADJUSTMENT: "Error Fetching Stock AdjustmentList List",
  ERROR_FETCHING_PURCHASE_ORDERS: "Error Fetching Purchase Orders List",
  ERROR_FETCHING_PURCHASE_BILL: "Error Fetching Purchase Bill List",
  ERROR_FETCHING_INDENT: "Error Fetching Indent List",
  ERROR_FETCHING_GOODS_ISSUE: "Error Fetching Goods Issue List",
};

export const appointmentDetailsPrescriptionsConst = {
  symptoms: "Symptoms",
  medicineName: "Medicine Name",
  type: "Type",
  mafen: "Dosage",
  units: "Units",
  timeType: "Time Type",
  freq: "Freq",
  time: "Time",
  duration: "Duration Type",
  durationTime: "Duration Count",
  days: "Interval",
  startDate: "Start Date",
  ADDED_SUCCESSFULLY: "Prescription Added Successfully",
  UPDATED_SUCCESSFULLY: "Prescription Updated Successfully",
  PRESCRIPTION_DELETED_SUCCESSFULLY: "Prescription Deleted Successfully",
  PRESCRIPTION_PRODUCT_DELETED_SUCCESSFULLY:
    "Prescription Deleted Successfully",
};

export const appointmentDetailsInvestigationsConst = {
  testName: "Test Name",
  subName: "Sub Name",
  investigation: "Investigation",
  units: "Units",
  referenceRange: "Reference Range",
  criticalValue: "Critical Value",
  actions: "Actions",
  ADDED_SUCCESSFULLY: "Investigation Added Successfully",
  UPDATED_SUCCESSFULLY: "Investigation Updated Successfully",
  DELETED_SUCCESSFULLY: "Investigation Deleted Successfully",
};

export const investigationTemplateDialogConst = {
  title: "Investigation Template",
  LESS: "Less",
  READMORE: "Readmore",
  CONFIRMBUTTONTEXT: "Apply",
  CANCELBUTTONTEXT: "Cancel",
  INVESTIGATIONTEMPLATELABEL: "Investigation Template",
  CLEARALLTEXT: "Clear all",
};
export const addItemsConst = {
  TITLE: "Add Item",
  DEPARTMENTLABEL: "Department",
  DEPARTMENTPLACEHOLDER: "Select Department",
  ITEMTYPLABEL: "Item Type",
  GROUPNAMELABEL: "Group Name",
  GROUPNAMEPLACEHOLDER: "Search Group",
  TESTNAMEANDSEQLABEL: "Item name",
  DESCRIPTIONLABEL: "Description",
  PRICELABEL: "Price",
  CONSULTANTLABEL: "Consultant",
  CONSULTANTPLACEHOLDER: "Select Consultant",
  ITEMSLABEL: "Items",
  ITEMSPLACEHOLDER: "Search Items",
  LABTESTLABEL: "Lab Test",
  LABOUTSOURCELABEL: "Lab Out Source",
  STATUSLABEL: "Status",
  CATEGORYLABEL: "Category",
  CATEGORYPLACEHOLDER: "Select Category",
};

export const billInfoConst = {
  UHID: "UHID / Patient",
  doctor: "Doctor",
  mobile: "Mobile",
  address: "Address",
  date: "Date",
  payee: "Payee",
  paymentType: "Payment type",
  billNo: "Bill No",
  ReferredBy: "Referred by",
  type: "Type",
  OPNO: "OP No",
  IPNO: "IP No",
  department: "Department",
};

export const billItemConst = {
  SNO: "S.No",
  itemName: "Item Name",
  consultant: "Consultant",
  description: "Description",
  quantity: "Quantity",
  discount: "Discount",
  tax: "Tax(%)",
  rate: "Rate",
  amount: "Amount",
  receiptNo: "Receipt No",
  paymentMethod: "Payment Method",
  date: "Date",
  remarks: "Remarks",
};
type ErrorMessages = {
  [key: string]: string;
};
export const errorMessages: ErrorMessages = {
  name: "The name field is required.",
  dept_id: "The department field is required.",
  group_id: "The group name field is required.",
  price: "The price field is required.",
  package_tests: "The package tests field is required.",
  type: "The Type field is required.",
  test_name: "The test name field is required.",
  test_dept: "The test dept field is required.",
  methodology_id: "The methodology field is required.",
  is_lab_test: "The Category field is required.",
};

export const patientBillErrorMessages: any = {
  patient_id: "The patient field is required.",
  // "mobile": "The mobile field is required.",
  rdType: "The type field is required.",
  doctor_id: "The doctor field is required.",
  bill_no: "The bill no field is required.",
  dept_id: "The department field is required.",
  date_created: "The date field is required.",
  payee: "The payee field is required.",
};

export const loginPageConst = {
  SIGN_IN: "Sign In",
  STAY_UPDATED_HEALTH: "Stay updated on your health care field",
  EMAIL: "Email",
  PASSWORD: "Password",
  REMEMBER_ME: "Remember me",
  LOGIN: "Login",
  SETUP_PASSWORD: "Set up password to login",
  MOBILE_REQUIRED: "The mobile field is required.",
  PASSWORD_REQUIRED: "The password field is required.",
  INCORRECT_PASSWORD: "Password entered is incorrect",
  PASSWORD_MUST_ENTERED: "Password must be entered",

  LOGIN_SUCCESSFULLY: "Login successful",
  INVALID_USER_PASSWORD: "Invalid user or password",
  VALID_EMAIL_ENTER: "Enter a valid email address",
  INVALID_EMAIL: "Invalid email address",
  PHONE_MUST_ENTERED: "Phone number must be entered",
  PASSWORD_INVALID: "Password entered is incorrect",
  PHONE_INVALID: "Phone number entered is incorrect",
  PHONE_NUMBER_REQUIRED: "Phone number is required",
  PHONE_NUMBER: "Phone number",
  DONT_USE_ALPHABETS: "Don't use alphabets",
  SUCCESSFULLY_LOGIN: "Successfully logged !",
};
export const sequenceWrapperConst = {
  changeSetting:
    "Your sequence order numbers are set on auto-generate mode to save your time. Are you sure about changing this setting?",
  autoGenerating: "Continue auto-generating sequence numbers",
  custom: "Custom",
  sequence: "Sequence",
  Symbols: "Symbols",
  addManually: "I will add them manually each time ?",
  thisTimeOnlyOne: "I want to use",
  thisTimeOnlyTwo: "only for this time",
};

export const displayConst = {
  patientCreated: "Patient Created Successfully!",
  patientUpdated: "Patient Updated Successfully!",
};

export const purchaseReturnSummaryConst = {
  goodsValue: "Goods Value",
  taxAmount: "Tax Amount",
  roundedOff: "Rounded Off",
  billAmount: "Bill Amount",
};

export const goodsIssueSummaryConst = {
  goodsValue: "Goods Value",
  billAmount: "Bill Amount",
  roundedOff: "Rounded Off",
  taxAmount: "Tax Amount",
};

export const displayText = {
  SAVE: "Save",
  CANCEL: "Cancel",
  DELETE: "Delete",
  ADD: "Add",
  EDIT: "Edit",
  ADD_BUTTON_TITLE: "+Add",
  EDIT_BUTTON_TITLE: "+Edit",
  LOGO_TITLE: "Dr.Carrot",
  CONFIRMATION_DELETE_DIALOG_TITLE: "Are you surely want to delete?",
};

export const billProductsConst = {
  productName: "Product Name",
  usedQty: "U.Qty",
  usedFree: "U.Free",
  packType: "Pack",
  qty: "QTY",
  originalQty: "Org.Qty",
  free: "Free",
  discount: "Discount",
  batchNo: "Batch No",
  expiryDate: "Expiry Date",
  tax: "Tax(%)",
  ptr: "PTR",
  mrp: "M.R.P",
  amount: "Amount",
  barCode: "Bar Code",
};
export const purchaseBillInfoConst = {
  supplierName: "Supplier Name",
  department: "Department",
  invoiceNo: "Invoice No",
  invoiceValue: "Invoice Value",
  invoiceDate: "Invoice Date",
  billSequenceNo: "Bill Sequence No",
  invoiceDueDate: "Payment Due Period",
  poNo: "PO No",
};
export const billSummaryConst = {
  goodsValue: "Goods Value",
  CGST: "CGST",
  SGST: "SGST",
  GSTAmount: "GST Amount",
  taxAmount: "Tax Amount",
  billTotal: "Bill Total",
  discount: "Discount",
  roundedOff: "Rounded Off",
  adjustment: "Adjustment",
  netAmount: "Net Amount",
  excess: "Excess",
  advance: "Advance",
  type: "Type",
  cashPaid: "Cash Paid",
  amountPaid: "Amount Paid",
  balance: "Balance",
  excessAmount: "Excess Amount",
  refundedAmount: "Refunded Amount",
  netExcess: "Net Excess",
  due: "Over All Due",
  creditLimit: "Credit Limit",
  taxTotal: "Tax Total",
  total: "Total",
  billAmount: "Bill Amount",
};
export const purchaseReturnConst = {
  productName: "Product Name",
  stock: "Stock",
  free: "Free",
  retQty: "Ret.Qty",
  fRetQty: "F.Ret.Qty",
  discount: "Discount(%)",
  batchNo: "Batch No",
  expiryDate: "Expiry Date",
  tax: "Tax(%)",
  ptr: "PTR/Unit",
  amount: "Amount",
};

export const goodsIssueReturnInfoConst = {
  issuerName: "Issuer Name",
  receiverName: "Receiver Name",
  invoiceNo: "Invoice No",
  billNo: "Bill No",
};
export const goodsIssueReturnConst = {
  productName: "Product Name",
  batchNo: "Batch No",
  expiryDate: "Expiry Date",
  stock: "Stock.Qty",
  retQty: "Ret.Qty",
  mrp: "MRP/Unit",
  discount: "Discount(%)",
  tax: "Tax(%)",
  amount: "Amount",
};
export const indentsConst = {
  productName: "Product Name",
  requirement: "Requirement",
  department: "Department",
  supplier: "Supplier",
  indent: "Indent",
  status: "Status",
  currentStock: "Current Stock",
  reOrderQuantity: "ReOrder Quantity",
  issuedQty: "Issued Quantity",
};
export const indentInfoConst = {
  indentNo: "Indent No",
  department: "Department",
  date: "Date",
  user: "User",
};
export const goodsIssueInfoConst = {
  issuer: "Issuer",
  receiver: "Receiver",
  invoiceNo: "Invoice No",
  date: "Date",
  barcode: "Bar Code",
};
export const goodsIssueConst = {
  productName: "Product Name",
  pack: "Pack",
  batchNo: "Batch No",
  expiryDate: "Expiry Date",
  usedQty: "Used Qty",
  qty: "Qty",
  mrp: "MRP/Unit",
  discount: "Dis(%)",
  tax: "Tax(%)",
  amount: "Amount",
};
export const stockAdjustmentInfoConst = {
  productName: "Product Name",
  packType: "Pack type",
  department: "Department",
  billNo: "Bill No",
};

export const stockAdjustmentConst = {
  productName: "Product Name",
  batchNo: "Batch No",
  stock: "Stock",
  decQty: "Dec.Qty",
  incQty: "Inc.Qty",
  price: "Price",
  discount: "Dis(%)",
  tax: "Tax(%)",
  incAmount: "Inc.Amount",
  decAmount: "Dec.Amount",
  reason: "Reason",
  supplier: "supplier",
  inVoiceNo: "Invoice No",
  adjustMentDate: "Adjustment Date",
  actions: "Action",
  expiryDate: "Expiry Date",
};

export const salesBillConst = {
  sNo: "S.No",
  productName: "Product Name",
  pack: "Pack",
  batchNo: "Batch No",
  expiryDate: "Expiry Date",
  qty: "Qty",
  mrp: "MRP/Unit",
  discount: "Dis(%)",
  tax: "Tax(%)",
  amount: "Amount",
  receiptNo: "Receipt No",
  paymentMethod: "Payment Method",
  date: "Date",
  remarks: "Remarks",
};

export const salesBillInfoConst = {
  UHID: "UHID",
  mobile: "Mobile",
  patientName: "Patient Name",
  address: "Address",
  payee: "Payee",
  invoiceNo: "Invoice No",
  paymentType: "Payment type",
  type: "Type",
  IPNO: "IP No",
  OPNO: "OP No",
  department: "Department",
  doctor: "Doctor Name",
  referredBy: "Referred by*",
  date: "Bill Date",
  category: "Bill Category",
  days: "Days",
};

export const salesBillSummaryConst = {
  goodsValue: "Goods Value",
  taxAmount: "Tax Amount",
  billTotal: "Bill Total",
  discount: "Discount",
  roundedOff: "Rounded Off",
  adjustment: "Adjustment",
  netAmount: "Net Amount",
  excess: "Excess",
  advance: "Advance",
  overAllDue: "Over All Due",
  creditLimit: "Credit Limit",
  paymentType: "Payment",
  amountPaid: "Amount Paid",
  balance: "Balance",
  excessAmount: "Excess Amount",
  refundedAmount: "Refunded Amount",
  netExcess: "Net Excess",
};

export const salesReturnConst = {
  productName: "Product Name",
  billNo: "Bill No",
  billDiscount: "Bill Discount",
  salesDate: "Sale.Date",
  expiryDate: "Expiry Date",
  salesQty: "Sale.Qty",
  salesPrice: "Sale.Price",
  salesDiscount: "Sale.Discount",
  retQty: "Ret.Qty",
  retPrice: "Ret.Price",
};
export const errorNaming: { [key: string]: string } = {
  patient_id: "Patient",
  doctor_id: "Doctor",
  bill_no: "Bill No",
  mobile: "Mobile",
  payee: "Payee",
  bill_payment_type: "Payment Type",
  customer_id: "Customer",
  uhid: "UHID",
  dept_id: "Department",
};
export const purchasePaymentConst = {
  supplierName: "Supplier Name",
  invoiceNo: "Invoice No",
  refNo: "Ref No",
  billAmount: "Bill Amount",
  paidAmount: "Paid Amount",
  due: "Due Amount",
};

export const purchasePaymentInfoConst = {
  supplierName: "Supplier Name",
  cashPaid: "Cash Paid",
  date: "Date",
  type: "Type",
};
export const inputEndValue: any = {
  weight: "kg",
  bp: "hg",
  pulse: "bpm",
  oxygen: "bpm",
  height: "cm",
};

export const trueFalseValue = [
  {
    id: 2,
    name: "No",
    value: "No",
  },
  { id: 1, name: "Yes", value: "Yes" },
];

export const genderValue = [
  {
    id: 1,
    value: "Male",
  },
  { id: 2, value: "Female" },
];

export const CampaignsIconList: any = {
  WhatsApp: <WhatsappIcon height={"15px"} width={"15px"} />,
  Chatbot: <ChatbotIcon height={"15px"} width={"15px"} />,
  SMS: <SMSIcon height={"15px"} width={"15px"} />,
  Email: <EmailIcon height={"15px"} width={"15px"} />,
};

export const ipAdmissionConst = {
  DELETED_SUCCESSFULLY: "Deleted Successfully",
  ADDED_SUCCESSFULLY: "Added Successfully",
  UPDATED_SUCCESSFULLY: "Updated Successfully",
};

export const monthList: { [key: string]: string } = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};

export const paymentViewInfoConst = {
  uhid: "UHID",
  payee: "Payee",
  cashReceived: "Cash Received",
  createdDate: "Created Date",
  type: "Type",
  excess: "Excess",
  advance: "Advance",
  department: "Department",
  patientName: "Patient Name",
  invoiceNumber: "Invoice No",
  billDate: "Bill Date",
  billAmount: "Bill Amount",
  discount: "Discount",
  previouslyPaid: "Previously Paid",
  paidAmount: "Paid Amount",
  amountDue: "Amount Due",
  remarks: "Remarks",
  returnText: "Return",
  convenienceFee: "Convenience Fee",
  urnNumber: "Urn Number",
};

export const purchaseOrderConst = {
  poNo: "PO No",
  supplier: "Supplier",
  date: "Date",
  status: "Status",
};

export const indentStatusBackgroundColors: {
  [key: string]: { background: string; color: string };
} = {
  Generated: { background: "#ECF4FF", color: "#207DFF" },
  Approved: { background: "#CCFBF1", color: "#006B63" },
  Canceled: { background: "#FDE6EB", color: "#AC054A" },
  Issued: { background: "#fbee80", color: "#212529" },
  Received: { background: "#98edf8", color: "#212529" },
};

export const procurementConst = {
  DELETED_SUCCESSFULLY: "Deleted Successfully",
  ADDED_SUCCESSFULLY: "Added Successfully",
  UPDATED_SUCCESSFULLY: "Updated Successfully",
  TAX_UPDATED_SUCCESSFULLY: "Tax Updated Successfully",
  DEPARTMENT_ADDED_SUCCESSFULLY: "Department Added Successfully",
  DEPARTMENT_DELETED_SUCCESSFULLY: "Department Deleted Successfully",
  DEPARTMENT_UPDATED_SUCCESSFULLY: "Department Updated Successfully",
  MANUFACTURE_ADDED_SUCCESSFULLY: "Manufacture Added Successfully",
  MANUFACTURE_UPDATED_SUCCESSFULLY: "Manufacture Updated Successfully",
  MANUFACTURE_DELETED_SUCCESSFULLY: "Manufacture Deleted Successfully",
  MAJOR_DRUG_ADDED_SUCCESSFULLY: "MajorDrug Added Successfully",
  MAJOR_DRUG_UPDATED_SUCCESSFULLY: "MajorDrug Updated Successfully",
  MAJOR_DRUG_DELETED_SUCCESSFULLY: "MajorDrug Deleted Successfully",
  DOSAGE_FORM_ADDED_SUCCESSFULLY: "DosageForm Added Successfully",
  DOSAGE_FORM_UPDATED_SUCCESSFULLY: "DosageForm Updated Successfully",
  DOSAGE_FORM_DELETED_SUCCESSFULLY: "DosageForm Deleted Successfully",
  UNITS_ADDED_SUCCESSFULLY: "Units Added Successfully",
  UNITS_UPDATED_SUCCESSFULLY: "Units Updated Successfully",
  UNITS_DELETED_SUCCESSFULLY: "Units Deleted Successfully",
};

const date = new Date();
const day = date.getDate().toString().padStart(2, "0");
const month = (date.getMonth() + 1).toString().padStart(2, "0");
const year = date.getFullYear().toString();
export const CurrentDate = `${day}/${month}/${year}`;

export const adjustmentErrors: any = {
  patient_id: "Please select patient_id",
  adjustment_type: "Please select adjustment_type",
  payment_type: "Please select payment_type",
  adjustment: "Please add adjustment ",
};

export const billDraftViewsConst = {
  date: "Date",
  item: "Item",
  description: "Description",
  quantity: "Quantity",
  rate: "Rate",
  consultant: "Consultant",
  billNo: "Bill No",
};

export const purchaseProductConst = {
  indentNo: "Indent No",
  productName: "Product Name",
  department: "Department",
  requirement: "Requirement",
  supplier: "Supplier",
  quantity: "quantity",
  status: "Status",
  stockQty: "Stock Quantity",
  actions: "Actions",
};
export const paymentType = {
  0: "Pay Now",
  1: "Credit",
};

export const poProductModalConst = {
  product: "Product",
  orderQty: "Order Quantity",
  receivedQty: "Received Quantity",
};
