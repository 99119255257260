import { useState, useCallback, ChangeEvent, useEffect, useMemo } from "react";
import {
  CircularProgress,
  Grid,
  SelectChangeEvent,
  debounce,
} from "@mui/material";
import { CheckBox, Radio, DatePicker, Select, TextField } from "../../basic";
import { SettingsIcon } from "../../../assets/icons";
import {
  ageAndDateValidator,
  dateValidator,
  emailValidator,
  mobileNumberValidator,
} from "../../../utils/ValidationUtils";
import { DialogWrapper } from "..";
import {
  createPatientData,
  getPatientDetails,
  updatePatientDetails,
  getSequenceNo,
  updateSequenceNo,
  getUserDetailsByMobile,
} from "../../../services/mainCoreService";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { displayConst } from "../../../constants/displayText";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../utils/ValidationUtils";
import SequenceWrapper from "../sequenceWrapper";
import { RootState } from "../../../redux/store";
import moment from "moment";
import dayjs from "dayjs";

type FromDialogProps = {
  open: boolean;
  onClose: () => void;
  patientId?: string | null | number;
};

const AddPatientDialog = ({ open, onClose, patientId }: FromDialogProps) => {
  const styles = useMemo(
    () => ({
      textFieldStyle: {
        width: {
          xs: "200px",
          xl: "275px",
        },
        mr: "20px",
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "5px",
        },
      },
      selectStyle: {
        width: {
          xs: "200px",
          xl: "275px",
        },
        height: "45px",
        boxShadow: "none",
        borderRadius: "5px",
      },
      autocompleteStyle: {
        width: {
          xs: "275px",
        },
        height: "45px",
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
          padding: "5px 0px",
          boxShadow: "none",
          borderRadius: "5px",
        },
      },
      autocompleteWithCheckboxStyle: {
        width: {
          xs: "275px",
        },
        height: "45px",
      },
      datePickerWithTimeStyle: {
        width: {
          xs: "200px",
          xl: "275px",
        },
        mr: "20px",
        "& .MuiOutlinedInput-root": {
          width: {
            xs: "200px",
            xl: "275px",
          },
          borderRadius: "5px",
          paddingLeft: "15px",
        },
      },
    }),
    []
  );

  const { commonVariables, appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);

  const [isDialogButtonLoading, setIsDialogButtonLoading] = useState(false);

  const dispatch = useDispatch();

  const languageOptions = [
    { id: "ta", name: "Tamil" },
    {
      id: "en",
      name: "English",
    },
  ];

  const [titleOptions, setTitleOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [bloodGroupsOptions, setBloodGroupsOptions] = useState([]);
  const [relationsTypeOptions, setRelationsTypeOptions] = useState([]);

  // sequence number variables
  const [customSequence, setCustomSequence] = useState(true);

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

  const [sequenceId, setSequenceId] = useState(null);

  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);

  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const [isManualAdded, setIsManualAdded] = useState(false);

  const [seqIsOnce, setSeqIsOnce] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;

  // sequence number generator functions

  const getSequenceDetails = async () => {
    try {
      await getSequenceNo("Patient").then((result: any) => {
        let data = result?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setFormData((prevBookingData: any) => ({
            ...prevBookingData,
            patientSeq: data,
          }));
        }

        if (isSequenceNumberChanged) {
          setFormData((prevBookingData: any) => ({
            ...prevBookingData,
            patientSeq: data,
          }));
        }

        if (result?.data?.length > 0) {
          setSequenceId(result?.data[0]?.sequence?.id);
          const newSequenceData =
            result?.data[0]?.sequence?.sequence_preferences?.map(
              (ele: {
                id: number;
                type: string;
                value: string;
                separator: string;
              }) => ({
                id: ele?.id,
                type: ele?.type,
                value: ele?.value,
                separator: ele?.separator,
              })
            );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual:
              result?.data[0]?.sequence?.is_manual === 1 ? true : false,
            is_auto_generate:
              result?.data[0]?.sequence?.is_manual !== 1 ? true : false,
          }));
          setSeqIsOnce((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(result?.data[0]?.sequence?.is_manual === 1));
        }
      });
    } catch (error: any) {
      console.error("An error occurred:", error);
    }
  };

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== formData?.patientSeq) {
      setSeqIsOnce((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: formData?.patientSeq,
      }));
      setIsSequenceWrapperOpen(true);
    }
  };

  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value !== "Select" ? value : null,
      };
      return newState;
    });
  };

  function onCloseSequenceDialogBox() {
    setIsSequenceWrapperOpen(false);
  }

  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      try {
        const data = {
          seq_preference: [...sequenceData],
          is_manual: isManual?.is_manual ? 1 : 0,
        };
        await updateSequenceNo(Number(sequenceId), data).then((res: any) => {
          if (res) {
            isSequenceNumberChanged = true;
            getSequenceDetails();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        });
      } catch (error: any) {
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };

  const initialFormError = {
    id: "",
    title: "",
    name: "",
    userMobile: "",
    email: "",
    patientSeq: "",
    patientSeqModified: "",
    dob: "",
    age: "",
    sex: "",
    relationType: "",
    relationName: "",
    language: "",
    aadhaarNumber: "",
    bloodGroup: "",
    isUseAccountAddress: "",
    lineOne: "",
    lineTwo: "",
    pinCode: "",
    area: "",
    district: "",
    state: "",
    husbandMobile: "",
    husbandAadhaarNumber: "",
    isVaccineCreated: "",
  };

  const [formError, setFormError] = useState<any>(initialFormError);

  const initialData = {
    id: "",
    title: "Mr",
    name: "",
    userMobile: "",
    email: "",
    patientSeq: "",
    patientSeqModified: 0,
    dob: null,
    age: null,
    sex: 1,
    relationType: "",
    relationName: "",
    language: "",
    aadhaarNumber: "",
    bloodGroup: "",
    isUseAccountAddress: false,
    lineOne: "",
    lineTwo: "",
    pinCode: "",
    area: "",
    district: "",
    state: "",
    husbandMobile: "",
    husbandAadhaarNumber: "",
    isVaccineCreated: false,
  };

  const [formData, setFormData] = useState<any>(initialData);

  const fieldData: any = {
    title: {
      label: "Title",
      name: "title",
      placeholder: "Select Title",
      value: formData.title,
      inputValue: formData?.title,
      isError: Boolean(formError.title),
      helperText: formError.title,
    },
    name: {
      label: "Patient Name",
      name: "name",
      placeholder: "Enter Patient Name",
      value: formData.name,
      isError: Boolean(formError.name),
      helperText: formError.name,
    },
    userMobile: {
      label: "Mobile Number",
      name: "userMobile",
      placeholder: "Enter Mobile Number",
      value: formData.userMobile,
      inputValue: formData?.userMobile,
      isError: Boolean(formError.userMobile),
      helperText: formError.userMobile,
    },
    email: {
      label: "Email",
      name: "email",
      placeholder: "Enter Email",
      value: formData.email,
      isError: Boolean(formError.email),
      helperText: formError.email,
    },
    patientSeq: {
      label: "Patient Id",
      name: "patientSeq",
      placeholder: "",
      value: formData.patientSeq,
      isError: Boolean(formError.patientSeq),
      helperText: formError.patientSeq,
    },
    dob: {
      label: "DOB",
      name: "dob",
      placeholder: "Choose DOB",
      value: formData.dob,
      isError: Boolean(formError.dob),
      helperText: formError.dob,
    },
    age: {
      label: "Age",
      name: "age",
      placeholder: "Enter Age",
      value: formData.age,
      isError: Boolean(formError.age),
      helperText: formError.age,
    },
    sex: {
      label: "Gender",
      name: "sex",
      placeholder: "",
      value: formData.sex,
      isError: Boolean(formError.sex),
      helperText: formError.sex,
    },
    relationType: {
      label: "Relation Type",
      name: "relationType",
      placeholder: "Enter Relation Type",
      value: formData.relationType,
      isError: Boolean(formError.relationType),
      helperText: formError.relationType,
    },
    relationName: {
      label: "Relation Name",
      name: "relationName",
      placeholder: "Enter Relation Name",
      value: formData.relationName,
      isError: Boolean(formError.relationName),
      helperText: formError.relationName,
    },
    language: {
      label: "Language",
      name: "language",
      placeholder: "Select Language",
      value: formData.language,
      isError: Boolean(formError.language),
      helperText: formError.language,
    },
    aadhaarNumber: {
      label: "Aadhaar Number",
      name: "aadhaarNumber",
      placeholder: "Enter Aadhaar Number",
      value: formData.aadhaarNumber,
      isError: Boolean(formError.aadhaarNumber),
      helperText: formError.aadhaarNumber,
    },
    bloodGroup: {
      label: "Blood Group",
      name: "bloodGroup",
      placeholder: "Select Blood Group",
      value: formData.bloodGroup,
      isError: Boolean(formError.bloodGroup),
      helperText: formError.bloodGroup,
    },
    isUseAccountAddress: {
      label: "Use Account Address",
      name: "isUseAccountAddress",
      placeholder: "",
      value: formData.isUseAccountAddress,
      isError: Boolean(formError.isUseAccountAddress),
      helperText: formError.isUseAccountAddress,
    },
    lineOne: {
      label: "Address Line One",
      name: "lineOne",
      placeholder: "Enter Address",
      value: formData.lineOne,
      isError: Boolean(formError.lineOne),
      helperText: formError.lineOne,
    },
    lineTwo: {
      label: "Address Line Two",
      name: "lineTwo",
      placeholder: "Enter Address",
      value: formData.lineTwo,
      isError: Boolean(formError.lineTwo),
      helperText: formError.lineTwo,
    },
    pinCode: {
      label: "Pin Code",
      name: "pinCode",
      placeholder: "Enter Pin Code",
      value: formData.pinCode,
      isError: Boolean(formError.pinCode),
      helperText: formError.pinCode,
    },
    area: {
      label: "Area",
      name: "area",
      placeholder: "Enter Area",
      value: formData.area,
      isError: Boolean(formError.area),
      helperText: formError.area,
    },
    district: {
      label: "District",
      name: "district",
      placeholder: "Enter District",
      value: formData.district,
      isError: Boolean(formError.district),
      helperText: formError.district,
    },
    state: {
      label: "State",
      name: "state",
      placeholder: "Enter State",
      value: formData.state,
      isError: Boolean(formError.state),
      helperText: formError.state,
    },
    husbandMobile: {
      label: "Husband Mobile",
      name: "husbandMobile",
      placeholder: "Enter Husband Mobile",
      value: formData.husbandMobile,
      isError: Boolean(formError.husbandMobile),
      helperText: formError.husbandMobile,
    },
    husbandAadhaarNumber: {
      label: "Husband Aadhaar Number",
      name: "husbandAadhaarNumber",
      placeholder: "Enter Husband Aadhaar Number",
      value: formData.husbandAadhaarNumber,
      isError: Boolean(formError.husbandAadhaarNumber),
      helperText: formError.husbandAadhaarNumber,
    },
    isVaccineCreated: {
      label: "Create Vaccination Chart",
      name: "isVaccineCreated",
      placeholder: "",
      value: formData.isVaccineCreated,
      isError: Boolean(formError.isVaccineCreated),
      helperText: formError.isVaccineCreated,
    },
  };

  const [formFieldData, setFormFieldData] = useState(fieldData);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "title":
      case "name":
      case "age":
      case "sex":
      case "patientSeq": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "userMobile": {
        if (mobileNumberValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            mobileNumberValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "email": {
        if (value !== "") {
          if (emailValidator(value, label)) {
            updateFormDataWithHelperText(
              name,
              emailValidator(value, label),
              setFormError
            );
          } else {
            updateFormDataWithHelperText(name, "", setFormError);
          }
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateFormFieldData = () => {
    setFormFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: formData[field.name],
          helperText: formError[field.name],
          isError: Boolean(formError[field.name]),
        };
      });
    });
  };

  useEffect(() => {
    updateFormFieldData();
  }, [formError, formData]);

  const handleInputsChange = (e: any) => {
    const { name, value } = e.target;

    let numericValue = value;

    if (name === "userMobile" || name === "husbandMobile") {
      numericValue = value.replace(/[^\d.]/g, "").slice(0, 10);
    }

    if (name === "aadhaarNumber" || name === "husbandAadhaarNumber") {
      numericValue = value.replace(/[^\d.]/g, "").slice(0, 12);
    }

    if (name === "age") {
      numericValue = value.replace(/[^\d.]/g, "").slice(0, 3);
    }

    if (name === "pinCode") {
      numericValue = value.replace(/[^\d.]/g, "").slice(0, 6);
    }

    if (name === "title") {
      setFormData((prev: any) => ({
        ...prev,
        [name]: numericValue,
        sex:
          value === "Mr"
            ? 1
            : value === "Miss" || value === "Mrs"
            ? 2
            : prev.sex,
      }));
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: numericValue,
    }));

    if (
      name === "userMobile" &&
      formData?.isUseAccountAddress === true &&
      !mobileNumberValidator(numericValue, "Primary Contact Number")
    ) {
      getPatientAddress(numericValue);
    }

    handleValidation({
      target: {
        name: name,
        value: numericValue,
      },
    });
  };

  const handleCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;

    setFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));

    if (
      name === "isUseAccountAddress" &&
      !mobileNumberValidator(formData?.userMobile, "Primary Contact Number") &&
      checked
    ) {
      getPatientAddress(formData?.userMobile);
    }
  };

  const handleDateChange = (newValue: any, name: string) => {
    const currentDate = dayjs();
    const selectedDate = dayjs(newValue);
    const age = currentDate.diff(selectedDate, "year");

    if (name === "dob") {
      setFormData((prev: any) => ({
        ...prev,
        [name]: newValue.toString(),
        age: age,
      }));
    }

    handleValidation({
      target: {
        name: name,
        value: newValue.toString(),
      },
    });

    handleValidation({
      target: {
        name: "age",
        value: age,
      },
    });
  };

  const getParticularPatientDetails = async () => {
    try {
      setIsDialogLoading(true);

      if (patientId) {
        await getPatientDetails(patientId).then((result: any) => {
          const data = result?.data;

          const modifiedData = {
            id: "",
            title: data?.title,
            name: data?.name,
            userMobile: data?.mobile,
            email: data?.email,
            patientSeq: data?.patient_id,
            patientSeqModified: 0,
            dob: data?.dob,
            age: data?.age,
            sex: data?.sex,
            relationType: data?.relation_type,
            relationName: data?.relation_name,
            language: data?.language,
            aadhaarNumber: data?.aadhar_number,
            bloodGroup: data?.blood_group,
            isUseAccountAddress: true,
            lineOne: data?.line_1,
            lineTwo: data?.line_2,
            pinCode: data?.pincode,
            area: data?.area,
            district: data?.district,
            state: data?.state,
            husbandMobile: data?.husband_mobile,
            husbandAadhaarNumber: data?.husband_aadhar_number,
            isVaccineCreated: data?.is_vacc_created,
          };

          setFormData(modifiedData);
          setInitialSequenceNumber(data?.patient_id);

          isCreatePrescription = false;
          isSequenceNumberChanged = false;
          getSequenceDetails();

          setIsDialogLoading(false);
        });
      } else {
        isCreatePrescription = true;
        isSequenceNumberChanged = false;
        setFormData(initialData);
        getSequenceDetails();
        setIsDialogLoading(false);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setIsDialogLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getParticularPatientDetails();
    }
  }, [patientId, open]);

  const getPatientAddress = async (mobileNumber: number | string) => {
    try {
      await getUserDetailsByMobile(mobileNumber).then((result: any) => {
        let data = result?.data;
        const modifiedData = {
          lineOne: data?.line_1,
          lineTwo: data?.line_2,
          pinCode: data?.pincode,
          area: data?.area,
          district: data?.district,
          state: data?.state,
        };

        setFormData((prev: any) => ({
          ...prev,
          ...modifiedData,
        }));
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const createPatient = async () => {
    if (
      !Boolean(formData.title) ||
      !Boolean(formData.name) ||
      !Boolean(formData.userMobile) ||
      !Boolean(formData.patientSeq) ||
      !Boolean(formData.age) ||
      !Boolean(formData.sex)
    ) {
      validateForm();
    } else {
      setIsDialogButtonLoading(true);

      try {
        const data = {
          user_mobile: formData.userMobile,
          mobile: formData.userMobile,
          title: formData.title,
          name: formData.name,
          age: Number(formData.age),
          sex: Number(formData.sex),
          dob: formData?.dob
            ? moment(formData.dob, ["DD-MM-YYYY"]).format("DD/MM/YYYY")
            : null,
          blood_group: "",
          email: formData.email,
          patient_seq: formData.patientSeq,
          patient_seq_modified:
            formData.patientSeq === initialSequenceNumber ? 0 : 1,
          language: formData.language,
          relation_type: formData.relationType,
          relation_name: formData.relationName,
          line_1: formData.lineOne,
          line_2: formData.lineTwo,
          pincode: formData.pinCode,
          area: formData.area,
          district: formData.district,
          state: formData.state,
          is_vacc_created: formData.isVaccineCreated ? 1 : 0,
          file: "",
          husband_aadhar_number: formData.husbandAadhaarNumber,
          husband_mobile: formData.husbandMobile,
        };

        await createPatientData(data).then((res) => {
          if (res) {
            onClose();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: displayConst.patientCreated,
              })
            );
            setIsDialogButtonLoading(false);
            setFormError(initialFormError);
            setFormData(initialData);
          }
        });
      } catch (error: any) {
        setIsDialogButtonLoading(false);
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.errors,
          })
        );
      }
    }
  };

  const updatePatient = async () => {
    if (!patientId) {
      return false;
    }

    if (
      !Boolean(formData.title) ||
      !Boolean(formData.name) ||
      !Boolean(formData.userMobile) ||
      !Boolean(formData.patientSeq) ||
      !Boolean(formData.age) ||
      !Boolean(formData.sex)
    ) {
      validateForm();
    } else {
      setIsDialogButtonLoading(true);
      try {
        const data = {
          user_mobile: formData.userMobile,
          title: formData.title,
          name: formData.name,
          age: Number(formData.age),
          sex: Number(formData.sex),
          dob: formData?.dob
            ? moment(formData.dob, ["DD-MM-YYYY"]).format("DD/MM/YYYY")
            : null,
          mobile: formData.userMobile,
          blood_group: formData.bloodGroup,
          email: formData.email,
          patient_seq: formData.patientSeq,
          patient_seq_modified:
            formData.patientSeq === initialSequenceNumber ? 0 : 1,
          language: formData.language,
          relation_type: formData.relationType,
          relation_name: formData.relationName,
          line_1: formData.lineOne,
          line_2: formData.lineTwo,
          pincode: formData.pinCode,
          area: formData.area,
          district: formData.district,
          state: formData.state,
          is_vacc_created: formData.isVaccineCreated ? 1 : 0,
          file: "",
          husband_aadhar_number: formData.husbandAadhaarNumber,
          husband_mobile: formData.husbandMobile,
        };

        await updatePatientDetails(patientId, data).then((res) => {
          if (res) {
            setIsDialogButtonLoading(false);
            onClose();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: displayConst?.patientUpdated,
              })
            );
            setFormError(initialFormError);
          }
        });
      } catch (error: any) {
        setIsDialogButtonLoading(false);
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const getCommonVariablesDetails = async () => {
    if (commonVariables) {
      setTitleOptions(commonVariables?.titles);
      setGenderOptions(commonVariables?.gender);
      setBloodGroupsOptions(commonVariables?.blood_groups);
      setRelationsTypeOptions(commonVariables?.relations_type);
    }
  };

  useEffect(() => {
    if (open) {
      getCommonVariablesDetails();
    }
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [commonVariables, appConfiguration, open]);

  return (
    <>
      <SequenceWrapper
        open={isSequenceWrapperOpen}
        title="Appointments"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
      <DialogWrapper
        onClose={() => {
          onClose();
          setFormError(initialFormError);
        }}
        open={open}
        handleClick={patientId ? updatePatient : createPatient}
        title={patientId ? "Edit Patient" : "Create Patient"}
        loading={isDialogButtonLoading}
        maxWidth="md"
      >
        {isDialogLoading ? (
          <Grid
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              justifyContent: "center",
              height: "50vh",
              background: "transparent",
            }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            gap="20px"
          >
            <Grid
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Select
                label={fieldData.title.label}
                name={fieldData.title.name}
                value={fieldData.title.value}
                options={titleOptions}
                onChange={handleInputsChange}
                helperText={fieldData.title.helperText}
                sx={{
                  width: {
                    xs: "80px",
                    sm: "90px",
                    md: "130px",
                  },
                }}
              />
              <TextField
                label={fieldData.name.label}
                name={fieldData.name.name}
                value={fieldData.name.value}
                onChange={handleInputsChange}
                helperText={fieldData.name.helperText}
                sx={{
                  width: {
                    xs: "210px",
                    sm: "175px",
                    md: "250px",
                  },
                }}
              />
            </Grid>
            <TextField
              label={fieldData.userMobile.label}
              name={fieldData.userMobile.name}
              value={fieldData.userMobile.value}
              onChange={handleInputsChange}
              helperText={fieldData.userMobile.helperText}
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
              }}
            />
            <TextField
              label={fieldData.email.label}
              name={fieldData.email.name}
              value={fieldData.email.value}
              onChange={handleInputsChange}
              helperText={fieldData.email.helperText}
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
              }}
            />
            {customSequence && (
              <TextField
                label={fieldData.patientSeq.label}
                name={fieldData.patientSeq.name}
                value={fieldData.patientSeq.value}
                onChange={handleInputsChange}
                onBlur={handleConfirmSequenceNoChange}
                helperText={fieldData.patientSeq.helperText}
                endPlaceholderIcon={
                  <SettingsIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsSequenceWrapperOpen(true);
                    }}
                  />
                }
                sx={{
                  width: {
                    xs: "300px",
                    sm: "275px",
                    md: "400px",
                  },
                }}
              />
            )}
            <TextField
              label={fieldData.aadhaarNumber.label}
              name={fieldData.aadhaarNumber.name}
              value={fieldData.aadhaarNumber.value}
              onChange={handleInputsChange}
              helperText={fieldData.aadhaarNumber.helperText}
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
              }}
            />
            <Select
              label={fieldData.bloodGroup.label}
              name={fieldData.bloodGroup.name}
              value={fieldData.bloodGroup.value}
              onChange={handleInputsChange}
              options={bloodGroupsOptions}
              helperText={fieldData.bloodGroup.helperText}
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
              }}
            />
            <Grid
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DatePicker
                label={fieldData.dob.label}
                name={fieldData.dob.name}
                value={fieldData.dob.value}
                onChange={(newValue: any) => handleDateChange(newValue, "dob")}
                format={"DD-MM-YYYY"}
                helperText={fieldData.dob.helperText}
                width="160px"
              />
              <TextField
                label={fieldData.age.label}
                name={fieldData.age.name}
                value={fieldData.age.value}
                onChange={handleInputsChange}
                helperText={fieldData.age.helperText}
                sx={{
                  width: {
                    xs: "90px",
                    sm: "90px",
                    md: "180px",
                  },
                }}
              />
            </Grid>
            <Grid
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <Radio
                label={fieldData.sex.label}
                name={fieldData.sex.name}
                value={fieldData.sex.value}
                onChange={handleInputsChange}
                options={genderOptions}
              />
            </Grid>
            <Grid
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Select
                label={fieldData.relationType.label}
                name={fieldData.relationType.name}
                value={fieldData.relationType.value}
                options={relationsTypeOptions}
                onChange={handleInputsChange}
                error={fieldData.relationType.isError}
                helperText={fieldData.relationType.helperText}
                sx={{
                  width: {
                    xs: "140px",
                    sm: "130px",
                    md: "180px",
                  },
                }}
              />
              <TextField
                label={fieldData.relationName.label}
                name={fieldData.relationName.name}
                value={fieldData.relationName.value}
                onChange={handleInputsChange}
                error={fieldData.relationName.isError}
                helperText={fieldData.relationName.helperText}
                sx={{
                  width: {
                    xs: "140px",
                    sm: "130px",
                    md: "180px",
                  },
                }}
              />
            </Grid>
            <Select
              label={fieldData.language.label}
              name={fieldData.language.name}
              value={fieldData.language.value}
              onChange={handleInputsChange}
              options={languageOptions}
              error={fieldData.language.isError}
              helperText={fieldData.language.helperText}
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
              }}
            />
            <TextField
              label={fieldData.husbandMobile.label}
              name={fieldData.husbandMobile.name}
              value={fieldData.husbandMobile.value}
              onChange={handleInputsChange}
              helperText={fieldData.husbandMobile.helperText}
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
              }}
            />
            <TextField
              label={fieldData.husbandAadhaarNumber.label}
              name={fieldData.husbandAadhaarNumber.name}
              value={fieldData.husbandAadhaarNumber.value}
              onChange={handleInputsChange}
              helperText={fieldData.husbandAadhaarNumber.helperText}
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
              }}
            />
            <Grid
              sx={{
                width: "100%",
              }}
            >
              <CheckBox
                label={fieldData.isUseAccountAddress.label}
                name={fieldData.isUseAccountAddress.name}
                checked={fieldData.isUseAccountAddress.value}
                formFlexDirection="rowReverse"
                onChange={handleCheckBoxChange}
              />
            </Grid>
            <TextField
              label={fieldData.lineOne.label}
              name={fieldData.lineOne.name}
              value={fieldData.lineOne.value}
              onChange={handleInputsChange}
              error={fieldData.lineOne.isError}
              helperText={fieldData.lineOne.helperText}
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
              }}
            />
            <TextField
              label={fieldData.lineTwo.label}
              name={fieldData.lineTwo.name}
              value={fieldData.lineTwo.value}
              onChange={handleInputsChange}
              error={fieldData.lineTwo.isError}
              helperText={fieldData.lineTwo.helperText}
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
              }}
            />
            <Grid
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                label={fieldData.pinCode.label}
                name={fieldData.pinCode.name}
                value={fieldData.pinCode.value}
                onChange={handleInputsChange}
                error={fieldData.pinCode.isError}
                helperText={fieldData.pinCode.helperText}
                sx={{
                  width: {
                    xs: "130px",
                    sm: "125px",
                    md: "180px",
                  },
                }}
              />
              <TextField
                label={fieldData.area.label}
                name={fieldData.area.name}
                value={fieldData.area.value}
                onChange={handleInputsChange}
                error={fieldData.area.isError}
                helperText={fieldData.area.helperText}
                sx={{
                  width: {
                    xs: "130px",
                    sm: "125px",
                    md: "180px",
                  },
                }}
              />
            </Grid>
            <Grid
              sx={{
                width: {
                  xs: "300px",
                  sm: "275px",
                  md: "400px",
                },
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                label={fieldData.district.label}
                name={fieldData.district.name}
                value={fieldData.district.value}
                onChange={handleInputsChange}
                error={fieldData.district.isError}
                helperText={fieldData.district.helperText}
                sx={{
                  width: {
                    xs: "130px",
                    sm: "125px",
                    md: "180px",
                  },
                }}
              />
              <TextField
                label={fieldData.state.label}
                name={fieldData.state.name}
                value={fieldData.state.value}
                onChange={handleInputsChange}
                error={fieldData.state.isError}
                helperText={fieldData.state.helperText}
                sx={{
                  width: {
                    xs: "130px",
                    sm: "125px",
                    md: "180px",
                  },
                }}
              />
            </Grid>
            <Grid
              sx={{
                width: "100%",
              }}
            >
              <CheckBox
                label={fieldData.isVaccineCreated.label}
                name={fieldData.isVaccineCreated.name}
                checked={fieldData.isVaccineCreated.value}
                formFlexDirection="flexReverse"
                onChange={handleCheckBoxChange}
              />
            </Grid>
          </Grid>
        )}
      </DialogWrapper>
    </>
  );
};

export default AddPatientDialog;
