import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import { formula } from "../../../utils/CalculationUtils";
import {
  Button,
  SaveButton,
  CancelButton,
  PageLoader,
} from "../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import Summary from "./Summary";
import { purchaseReturnConst } from "../../../constants/displayText";
import {
  addWholePurchaseReturnProduct,
  getWholeSalesReturnById,
  getWholeSalesReturnProduct,
  updateWholePurchaseReturnProduct,
} from "../../../services/gynecologyService";
import {
  timeWithTwentyFourHoursConverter,
  formatTwelveHoursTime,
  formattedDateWithSlash,
} from "../../../utils/DateTimeFormatUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../redux/slices/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../redux/store";
import SequenceWrapper from "../../../components/shared/sequenceWrapper";
import {
  getDepartmentSequence,
  updatedSequence,
} from "../../../services/mainCoreService";

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right";
  sx?: {};
};

const BillProducts = ({ departmentList, customerList }: any) => {
  const {
    productName,
    stock,
    free,
    retQty,
    fRetQty,
    discount,
    batchNo,
    expiryDate,
    tax,
    ptr,
    amount,
  } = purchaseReturnConst;

  const {
    calculateDiscountAmount,
    calculateTaxAmount,
    calculateAmount,
    calculateBillAmount,
  } = formula.purchaseReturn;
  const [productRow, setProductRow] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [loading, setLoading] = useState(false);
  const [seqIsOnce, setSeqIsOne] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });
  const [sequenceDialogBox, setSequenceDialogBox] = useState(false);
  const [isManualAdded, setIsManualAdded] = useState(false);

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;
  
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: 1,
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },
    }),
    []
  );

  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: productName, alignment: "left" },
    { TableCellName: stock, alignment: "right" },
    { TableCellName: free, alignment: "right" },
    { TableCellName: retQty, alignment: "right" },
    { TableCellName: fRetQty, alignment: "right" },
    { TableCellName: discount, alignment: "right" },
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: expiryDate, alignment: "left" },
    { TableCellName: tax, alignment: "right" },
    { TableCellName: ptr, alignment: "right" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];
  const [autoGeneratedSeqNo, setAutoGeneratedSeqNo] = useState("");
  const [sequenceNo, setSequenceNo] = useState("");
  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });

  const initialBillProducts = {
    id: null,
    product_name: null,
    autoComplete_input_value: null,
    stock: null,
    free: null,
    ret_qty: null,
    f_ret_qty: null,
    discount: "",
    batch_no: "",
    expiry_date: "",
    tax: "",
    ptr: "",
    amount: null,
    discount_amount: null,
    tax_amount: null,
  };
  const [isSubmitLoaded, setIsSubmitLoaded] = useState(false);
  const [billProducts, setBillProducts] = useState<any>([initialBillProducts]);

  const [summary, setSummary] = useState({
    net_amount: 0,
    rounded_off: 0,
    bill_amount: 0,
  });

  const initialInfo = {
    dept_id: null,
    customer_id: null,
    address: "",
    mobile_no: "",
    date_created: new Date(),
    invoice_no: null,
    invoice_no_modified: null,
    disabled: false,
  };

  const [info, setInfo] = useState<any>(initialInfo);
  const [batchIdNo, setBatchIdNo] = useState<any[]>([]);
  const [formError, setFormError] = useState<
    {
      ret_qty: boolean;
      free_ret_qty: boolean;
    }[]
  >([
    {
      ret_qty: false,
      free_ret_qty: false,
    },
  ]);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [customSequence,setCustomSequence] = useState(true);
  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
  string | number | undefined
>("");
const [errors, setErrors] = useState<any>([]);

  function onCloseSequenceDialogBox() {
    setSequenceDialogBox(false);
  }
  const handleSequenceOnChange = (e: any, index: number) => {
    const { name, value } = e.target;
    setSequenceData((prevState: any) => {
      const newState = [...prevState];
      newState[index] = {
        ...newState[index],
        [name]: value === "Select" ? "" : value,
      };
      return newState;
    });
  };
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number | string) => {
      const { name, value } = e.target;

      let numericValue = value.replace(/\D/g, "");
      setCurrentIndex(index as any);
      setBillProducts((prevState: any) => {
        const newState = [...prevState];

        newState[index as number] = {
          ...newState[index as number],
          [name]: numericValue,
        };
        handleValidation(
          { target: { name: name, value: newState[index as number][name] } },
          newState[index as number],
          index
        );
        newState[index as number] = {
          ...newState[index as number],
          amount: handleCalculateAmount(
            newState[index as number],
            newState[index as number]?.product_name
          ),
        };

        return newState;
      });
    },
    []
  );
  const handleValidation = (
    e: any,
    updatedValue: any,
    idx: number | string
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "ret_qty": {
        if (value > Number(updatedValue.stock)) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["ret_qty"] = true;
            return newState;
          });
        } else if (
          value >
          Number(updatedValue.stock | 0) - Number(updatedValue.f_ret_qty | 0)
        ) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["ret_qty"] = true;
            return newState;
          });
        } else {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["ret_qty"] = false;
            return newState;
          });
        }
        break;
      }
      case "f_ret_qty": {
        if (value > Number(updatedValue.free)) {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["free_ret_qty"] = true;
            return newState;
          });
        } else {
          setFormError((prevState) => {
            const newState = [...prevState];
            newState[idx as number]["free_ret_qty"] = false;
            return newState;
          });
        }
        break;
      }
      default:
        break;
    }
  };

  const handleCalculation = (name: string, productDetail: any) => {
    let num: string | number = 0;
    switch (name) {
      case "stock": {
        if (appConfiguration?.goods_issue_disable) {
          num = productDetail?.original_qty
            ? (
                parseInt(productDetail?.original_qty) +
                parseInt(
                  productDetail?.adj_qty < 0 ? productDetail?.adj_qty : 0
                ) -
                parseInt(productDetail?.return_qty) -
                parseInt(productDetail?.sale_qty)
              ).toString()
            : "";
        } else {
          num = productDetail?.original_qty
            ? (
                parseInt(productDetail?.original_qty) +
                parseInt(productDetail?.free || 0) +
                parseInt(
                  productDetail?.adj_qty < 0 ? productDetail.adj_qty : 0
                ) -
                parseInt(productDetail?.return_qty) -
                parseInt(productDetail?.sale_qty) -
                parseInt(productDetail?.free_return_qty || 0)
              ).toString()
            : "";
        }
        break;
      }
      case "free": {
        num =
          parseInt(productDetail?.free == null ? 0 : productDetail?.free) -
          parseInt(
            productDetail?.free_return_qty == null
              ? 0
              : productDetail?.free_return_qty
          );
        break;
      }
      case "p_rate": {
        num = productDetail?.p_rate
          ? Number(
              productDetail?.p_rate /
                parseInt(
                  productDetail?.pack_type ? productDetail?.pack_type : 1
                )
            ).toFixed(2)
          : "";
        break;
      }
      default: {
        break;
      }
    }
    return num;
  };

  const handleAutoCompleteChange = useCallback(
    (newValue: any, idx: number | string, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevState: any) => {
        let newState = [...prevState];
        if (newState.length > 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            stock: handleCalculation("stock", newValue),
            free: handleCalculation("free", newValue),
            rate: newValue.rate,
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            // `${newValue.batch_no}#${newValue.original_qty}`
            batch_no: newValue.batch_no,
            amount: 0,
            discount: `${newValue.discount || 0}%`,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
          };
        }
        if (newState.length === 1) {
          newState[idx as number] = {
            ...newState[idx as number],
            [name]: newValue,
            stock: handleCalculation("stock", newValue),
            free: handleCalculation("free", newValue),
            rate: newValue.rate,
            expiry_date: formattedDateWithSlash(newValue.expires_at),
            batch_no: newValue.batch_no,
            amount: 0,
            discount: `${newValue.discount || 0}%`,
            tax: `${newValue.taxname}-${newValue.tax_id}%`,
          };
        }
        setBatchIdNo((prev) => [...prev, newValue?.batch_id]);
        return newState;
      });
    },
    [appConfiguration]
  );
  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProducts,
        id: prev.length,
      },
    ]);
    if (billProducts.length > 0) {
      setFormError((prev) => [
        ...prev,
        { ret_qty: false, free_ret_qty: false },
      ]);
    }
  }, []);

  const handleDelete = (id: number) => {
    const newBillProducts: any = [...billProducts];
    newBillProducts.splice(id, 1);
    setBillProducts(newBillProducts);
    if (billProducts.length > 0) {
      const newErrorValue: any = [...formError];
      newErrorValue.splice(id, 1);
      setFormError(newErrorValue);
    }
  };
  const handleCalculateAmount = (product: any, productDetail: any) => {
    let amount_value: string | number = 0;
    let disc_value: string | number = 0;
    let tax_value: string | number = 0;
    const per_rate = Number(productDetail?.rate);

    const isInclusive =
      productDetail?.tax_settings === 0 ? "inclusive" : "exclusive";
    if (isNaN(product?.ret_qty) || isNaN(per_rate)) {
      amount_value = 0;
    } else {
      amount_value = (product?.ret_qty * per_rate).toFixed(2);
    }
    if (isNaN(productDetail?.discount) || isNaN(productDetail?.amount_value)) {
      disc_value = 0;
    } else {
      disc_value = (
        (productDetail?.discount / 100) *
        Number(amount_value)
      ).toFixed(2);
    }
    if (isNaN(productDetail?.discount) || isNaN(amount_value as number)) {
      disc_value = 0;
    } else {
      disc_value = Number(productDetail?.discount);
    }
    amount_value = Number(amount_value) - Number(disc_value);

    let tax_per = productDetail?.tax_id;
    if (isNaN(tax_per)) {
      tax_value = 0;
    } else {
      if (isInclusive === "exclusive") {
        tax_value = (Number(amount_value) * tax_per) / 100;
      } else {
        tax_value = 0;
      }
    }
    amount_value = (
      amount_value ? Number(amount_value) + Number(tax_value) : 0
    ).toFixed(2);
    return amount_value;
  };
  const updateBillData = () => {
    const billAmount = calculateBillAmount(billProducts);
    const decimalPart = Math.round((billAmount % 1) * 100);
    const roundedValue: any =
      Math.trunc(billAmount) + (decimalPart >= 50 ? 1 : 0);
    const difference = roundedValue - billAmount;
    setSummary((prevState) => ({
      ...prevState,
      bill_amount: isNaN(billAmount) ? 0 : billAmount,
      rounded_off: isNaN(difference) ? 0 : difference,
      net_amount: isNaN(roundedValue) ? 0 : roundedValue,
    }));
    setInfo((prev: any) => ({
      ...prev,
      invoice_value: roundedValue || "0.00",
    }));
  };
  const validateForm = (details: any) => {
    let validate = true;

    if (details.supplier_name === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Supplier not to be empty!" })
      );
      validate = false;
    }
    if (details.department === "") {
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "department or supplier not to be empty!",
        })
      );
      validate = false;
    }
    if (details.invoice_date === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Invoice Date not to be empty!" })
      );
      validate = false;
    }
    if (details.invoice_value === "") {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Invoice value not to be empty!" })
      );
      validate = false;
    }
    if (
      Boolean(formError.find((ele) => ele.free_ret_qty)) ||
      Boolean(formError.find((ele) => ele.ret_qty))
    ) {
      dispatch(
        setSnackBarFailed({ snackBarMessage: "Please check Table Values!" })
      );
      validate = false;
    }
    // if (
    //   billProducts.filter(
    //     (ele: { f_ret_qty: string; ret_qty: string }) =>
    //       Boolean(ele.ret_qty === "") && Boolean(ele.f_ret_qty === "")
    //   )
    // ) {
    //   dispatch(
    //     setSnackBarFailed({ snackBarMessage: "Quantity not to be Empty!" })
    //   );
    //   validate = false;
    // }
    return validate;
  };

  const handleSubmit = async () => {
    if (validateForm(info)) {
      setIsSubmitLoaded(true);
      const productDetails: any =
        billProducts.length > 0
          ? billProducts.map((obj: any) => {
              let newObj = {};
              const {
                product_id,
                discount,
                expires_at,
                tax_id,
                tax_type,
                id,
                bill_id,
                supplier_id,
                issue_batch_id,
                p_rate,
                mrp,
                rate,
                batch_no,
                batch_id,
                pack_type,
              } = obj.product_name;
              newObj = {
                product_id,
                ref_bill: bill_id,
                ref_batch_id: batch_id,
                return_qty: obj.ret_qty,
                free_return_qty: obj.f_ret_qty,
                discount,
                batch_no,
                expires_at,
                tax_id,
                tax_type,
                p_rate,
                pack_type,
                amount: obj.amount,
                supplier_id,
                issue_batch_id,
                mrp,
                rate,
              };
              // if (id) {
              //   const { id } = obj.product_name;
              //   newObj = { ...newObj, id, batch_no: batch_id };
              // }
              // if (!id) {
              //   newObj = { ...newObj, id, batch_no: batch_id };
              // }
              return newObj;
            })
          : 0;
      const data: any = {
        dept_id: info.dept_id,
        customer_id: info.customer_id,
        date_created: info.date_created
          ? timeWithTwentyFourHoursConverter(info.date_created)
          : "",
        invoice_no: info.invoice_no,
        invoice_no_modified: info.invoice_no === initialSequenceNumber ? "0" : "1",
        product: [...productDetails],
        bill_amt: summary.bill_amount,
        net_total_amt: summary.net_amount,
      };
      if (id) {
        updateWholePurchaseReturnProduct(id as string, data as any)
          .then((res: any) => {
            navigate("/procurement/whole_sales");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            dispatch(
              setSnackBarFailed({ snackBarMessage: err.response.data.errors })
            );
            setIsSubmitLoaded(false);
            console.log(err);
          });
      } else {
        await addWholePurchaseReturnProduct(data as any)
          .then((res: any) => {
            navigate("/procurement/whole_sales");
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setIsSubmitLoaded(false);
          })
          .catch((err: any) => {
            dispatch(
              setSnackBarFailed({ snackBarMessage: err.response.data.errors })
            );
            setIsSubmitLoaded(false);
            console.log(err);
          });
      }
    }
  };
  const updateRows = (currentIndex: number) => {
    const amount = calculateAmount(billProducts, currentIndex);
    const discountAmount = calculateDiscountAmount(billProducts, currentIndex);
    const taxAmount = calculateTaxAmount(billProducts, currentIndex);

    if (
      amount !== +billProducts[currentIndex]?.amount ||
      taxAmount !== billProducts[currentIndex]?.tax_amount ||
      discountAmount !== billProducts[currentIndex]?.discount_amount
    ) {
      setBillProducts((prev: any) =>
        prev.map((billProduct: any, i: number) => {
          return i === currentIndex
            ? {
                ...billProduct,
                amount: amount,
                discount_amount: discountAmount,
                tax_amount: taxAmount,
              }
            : billProduct;
        })
      );
    }
  };
  useEffect(() => {
    updateRows(currentIndex);
  }, []);

  useEffect(() => {
    updateBillData();
  }, [billProducts]);


  const handleSequence = () => {
    getDepartmentSequence("B2B Return")
      .then((res : any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfo((prevState: any) => ({
            ...prevState,
            invoice_no:data,
          }));

          setErrors((prevState: any) => ({
            ...prevState,
            invoice_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setInfo((prevState: any) => ({
            ...prevState,
            invoice_no:data,
          }));
          setErrors((prevState: any) => ({
            ...prevState,
            invoice_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setAutoGeneratedSeqNo(res?.data[0]?.sequence_no);
          setSequenceNo(res.data[0].sequence.id);
          const newSequenceData = res.data[0].sequence.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res.data[0].sequence.is_manual === 1 ? true : false,
            is_auto_generate:
              res.data[0].sequence.is_manual !== 1 ? true : false,
          }));
          setSeqIsOne((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res.data[0].sequence.is_manual === 1));
        }
      })
      .catch((err: any) => console.log(err));
  };
  const handleSequenceSubmit = async () => {
    if (isManual.is_once) {
      onCloseSequenceDialogBox();
    } else {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceNo), data)
        .then((res) => {
          if (res) {
            isSequenceNumberChanged = true;
            handleSequence();
            onCloseSequenceDialogBox();
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Sequence Updated SuccessFully",
              })
            );
          }
        })
        .catch((err: any) => {
          console.log(err);
          dispatch(
            setSnackBarFailed({ snackBarMessage: err.response.data.errors.message })
          );
        });
    }
  };
  useEffect(() => {
    if (info.dept_id && info.customer_id && !id && batchIdNo) {
      const data: any = {
        customer_id: info.customer_id,
        dept_id: info.dept_id,
        selected_batches: batchIdNo,
      };
      getWholeSalesReturnProduct(data)
        .then((res: any) => setProductRow(res.data))
        .catch((err: any) => console.log(err));
    }
  }, [info.dept_id, info.customer_id, batchIdNo]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getWholeSalesReturnById(id as string)
        .then((res : any) => {
          const bill = res.data.bill;
          const billProducts = res.data.bill_products;
          setInfo((prevState: any) => ({
            ...prevState,
            bill_no: bill?.id,
            dept_id: bill?.dept_id,
            customer_id: bill?.customer_id,
            date_created: formatTwelveHoursTime(bill?.date_created),
            invoice_value: bill?.invoice_value,
            supplier_name: bill?.supplier_id,
            mobile_no: bill?.phone,
            address: bill?.address,
            disabled: true,
            invoice_no:bill?.invoice_no
          }));
          setInitialSequenceNumber(bill?.invoice_no);

          const newData = billProducts.map((ele: any) => ({
            product_id: ele.product_id,
            product_name: ele,
            stock_qty: ele.stock_qty,
            isId: id,
            stock: ele.stock,
            free: ele.free_stock,
            rate: ele.rate,
            ret_qty: ele.return_qty,
            f_ret_qty: ele.free_return_qty,
            discount: ele.discount,
            batch_no: ele.batch_no,
            tax: `${ele.tax_name}-${ele.tax_id}%`,
            expiry_date: formattedDateWithSlash(ele.expires_at),

            amount: Number(ele.amount) || 0,
          }));
          const newError = newData.map((ele: any) => ({
            ret_qty: false,
            free_ret_qty: false,
          }));
          setLoading(false);
          setFormError(newError);
          setBillProducts(newData);
          isCreatePrescription = false;
          isSequenceNumberChanged = false;
          handleSequence();

        })
        .catch((err) => console.log(err));
    }else{
      isCreatePrescription = true;
      isSequenceNumberChanged = false;
      handleSequence();
    }
    if(appConfiguration?.custom_sequence === false){
      setCustomSequence((prev)=> prev = false);
    }
  }, [id, appConfiguration]);

  const handleConfirmSequenceNoChange = () => {
    if (initialSequenceNumber !== info.invoice_no) {
      setSeqIsOne((prevState: any) => ({
        ...prevState,
        isOnce: true,
        isOnceSeqValue: info.invoice_no,
      }));
      setSequenceDialogBox(true);
    }
  };

  const handleSequenceCheckBoxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsManual((prevState: any) => {
      if (name === "is_manual") {
        return {
          ...prevState,
          is_manual: true,
          is_auto_generate: false,
          is_once: false,
        };
      }
      if (name === "is_auto_generate") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: true,
          is_once: false,
        };
      }
      if (name === "is_once") {
        return {
          ...prevState,
          is_manual: false,
          is_auto_generate: false,
          is_once: true,
        };
      }
    });
  };
  useEffect(() => {
    handleSequence();
  }, []);
  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <Grid>
        <Info
          info={info}
          setInfo={setInfo}
          departmentList={departmentList}
          customerList={customerList}
          isGoodsIssue={appConfiguration?.goods_issue_disable}
          setSequenceDialogBox={setSequenceDialogBox}
          setIsManual={setIsManual}
          isManualAdded={isManualAdded}
          isManual={isManual}
          customSequence={customSequence}
          handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
          errors={errors}
        />
        <Grid
          sx={{
            width: {
              xs: "92vw",
            },
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            overflowX: { md: "scroll", lg: "hidden" },
          }}
        >
          <TableContainer
            className="table-container"
            component={Paper}
            sx={{
              ...styles.tableContainerStyle,
            }}
          >
            <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {TableHeaderCells.map((cell, index) => {
                    return (
                      <TableCell
                        align={cell.alignment}
                        sx={cell.sx}
                        key={index}
                      >
                        {cell.TableCellName}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  height: "auto",
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "120vw",
                    lg: "90vw",
                  },
                }}
              >
                {billProducts.map((row: any, index: number) => {
                  return (
                    <ProductRow
                      key={row.id}
                      index={index}
                      row={row}
                      styles={styles}
                      allRows={productRow}
                      formError={formError}
                      handleInputChange={handleInputChange}
                      handleDelete={handleDelete}
                      handleAutoCompleteChange={handleAutoCompleteChange}
                    />
                  );
                })}
              </TableBody>
            </Table>
            <Grid
              sx={{
                width: {
                  xs: "400vw",
                  sm: "200vw",
                  md: "120vw",
                  lg: "90vw",
                },
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "0px 0px 5px 5px",
                border: 1,
                borderColor: "var(--table-border)",
                borderTop: 0,
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: {
                    xs: "50%",
                  },
                }}
              >
                <Button
                  variant="text"
                  buttonText="+ Add Product"
                  handleClick={handleClick}
                  sx={{
                    borderRadius: "0px",
                    width: "auto",
                    height: "auto",
                    fontWeight: 400,
                    fontSize: "13px",
                    border: 0,
                    color: "primary.main",
                    "&:hover": {
                      backgroundColor: "initial",
                      color: "primary.main",
                      borderColor: "initial",
                    },
                    "&.Mui-focusVisible": {
                      border: 1,
                      borderColor: "primary.main",
                      borderRadius: "5px",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </TableContainer>
        </Grid>
        <Summary summary={summary} />
        <Grid
          sx={{ display: "flex", flexDirection: "row", m: "35px 0 45px 0" }}
        >
          <SaveButton
            handleClick={() => handleSubmit()}
            loading={isSubmitLoaded}
            sx={{ width: "100px", height: "42px", mr: "20px" }}
          />
          <CancelButton sx={{ width: "100px", height: "42px" }} />
        </Grid>
      </Grid>
      )}
      <SequenceWrapper
        open={sequenceDialogBox}
        title="B2B"
        onClose={onCloseSequenceDialogBox}
        handleChange={handleSequenceOnChange}
        sequenceValue={sequenceData}
        isOnce={seqIsOnce}
        handleClick={handleSequenceSubmit}
        handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
        isManual={isManual}
      />
    </>
  );
};

export default BillProducts;
