import { useEffect, useState } from "react";
import { getPatientTestsById } from "../../../../../services/gynecologyService";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { CircularProgress } from "@mui/material";
import { formatDateType } from "../../../../../utils/DateTimeFormatUtils";

export default function TestPage() {
  const { gynecologyId } = useSelector((state: RootState) => state.gynecology);
  const [isLoading, setIsLoading] = useState(false);
  const [isPreparationClicked, setIsPreparationClicked] = useState<
    null | number
  >(null);
  const [testList, setTestList] = useState({
    completed: [],
    recommendedNotCompleted: [],
  });

  useEffect(() => {
    setIsLoading(true);
    if (gynecologyId !== null) {
      getPatientTestsById(gynecologyId as number).then((res: any) => {
        setTestList(res.data);
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            height: "50vh",
            background: "transparent",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        testList?.recommendedNotCompleted?.map(
          (
            ele: {
              id: number | string;
              item_name: string;
              preparation: string;
              date_of_visit: string;
              op_id: string | number | null;
            },
            idx: number
          ) =>
            ele.item_name && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "18px",
                  border: "1px solid #EDEDED",
                  padding: "14px",
                  margin: "10px",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#FFAA39",
                    borderRadius: "6px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontSize: "15px",
                      fontWeight: 700,
                      marginTop: "4px",
                    }}
                  >
                    {formatDateType(ele.date_of_visit, "DD")}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontSize: "15px",
                      fontWeight: 700,
                    }}
                  >
                    {formatDateType(ele.date_of_visit, "MM")}
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: "16px",
                      marginBottom: "5px",
                    }}
                  >
                    {ele.item_name}
                  </div>
                  <div
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      if (isPreparationClicked === idx) {
                        setIsPreparationClicked(null);
                      } else {
                        setIsPreparationClicked(idx as number);
                      }
                    }}
                  >
                    Test Preparation
                  </div>
                  <div
                    style={{
                      display: isPreparationClicked === idx ? "" : "none",
                    }}
                  >
                    <ul>
                      <li>
                        Fasting is required about 10 - 12 hours before the
                        sample collection.
                      </li>
                      <li>
                        Fasting is required about 10 - 12 hours before the
                        sample collection.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )
        )
      )}
    </>
  );
}
