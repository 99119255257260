import React, { useState } from "react";
import MainListItems from "../navLinks/ListItem";
import { Logo } from "../../../assets/images";
import {
  Typography,
  Box,
  Drawer,
  List,
  Divider,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { displayText } from "../../../constants/displayText";

import "./index.css";

type NavProps = {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
};

const defaultDrawerWidth = 65;
const expandedDrawerWidth = 259;

const Nav = ({
  mobileOpen,
  handleDrawerToggle,
  navigateHook,
  locationHook,
}: any) => {
  const [drawerWidth, setDrawerWidth] = useState(70);
  const [isMouseExit, setIsMouseExit] = useState(true);

  const handleMouseEnter = () => {
    setDrawerWidth(expandedDrawerWidth);
    setIsMouseExit(false);
  };

  const handleMouseLeave = () => {
    setDrawerWidth(defaultDrawerWidth);
    setIsMouseExit(true);
  };

  const drawer = (
    <>
      <List
        sx={{
          height: "32px",
          display: "flex",
          alignItems: "center",
          m: isMouseExit ? "9px 15px 0px 15px" : "9px 15px 0px 15px",
          p: "8px 0 15px 0",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <ListItemIcon>
          <Logo width="36px" height="35.799px" />
        </ListItemIcon>
        <ListItemText>
          <Typography
            sx={{
              color: "#1E1E1E",
              fontSize: "25px",
              fontWeight: "600",
              lineHeight: "normal",
              letterSpacing: "0.28px",
            }}
          >
            {displayText.LOGO_TITLE}
          </Typography>
        </ListItemText>
      </List>
      <List
        sx={{
          overflowY: isMouseExit ? "hidden" : "scroll",
          overflowX: "hidden",
        }}
        className="layout-list"
      >
        <MainListItems
          handleMouseLeave={handleMouseLeave}
          isMouseExit={isMouseExit}
          navigateHook={navigateHook}
          locationHook={locationHook}
          // setMobileOpen={setMobileOpen}
        />
      </List>
    </>
  );
  return (
    <Box
      component="nav"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
      aria-label="mailbox folders"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: expandedDrawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: "none", // Remove border
            boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
            borderTopRightRadius: "15px", // Add border radius to top-right corner
            borderBottomRightRadius: "15px", // Add border radius to bottom-right corner
            overflow: "hidden",
            transition: "width 0.4s",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Nav;
