import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./styles.css";
import { getCookie } from "../../../utils/GeneralUtils";
import { useLocation } from "react-router-dom";

const getToken = (): {
  access_token: string | null;
  token_type: string;
  refresh_token: string | null;
} => {
  const localStorageAccessToken: any = getCookie("accessToken");
  const localStorageRefreshToken: any = getCookie("refreshToken");
  const accessToken = localStorageAccessToken ?? null;
  const refreshToken = localStorageRefreshToken ?? null;
  if (accessToken != null && refreshToken != null) {
    return {
      access_token: JSON.parse(accessToken),
      token_type: "Bearer",
      refresh_token: JSON.parse(refreshToken),
    };
  } else {
    return {
      access_token: null,
      token_type: "Bearer",
      refresh_token: null,
    };
  }
};

const Print = () => {
  const { printPdfUrl }: any = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const [blobUrl, setBlobUrl]: any = useState(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const pdfUrl: any = queryParams.get('url');

  useEffect(() => {
    const credentials = getToken();

    const fetchPdf = async () => {
      const response = await fetch(pdfUrl, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${credentials?.access_token}`,
        },
      });

      if (response) {
        const blob = await response.blob();
        const url: any = URL.createObjectURL(blob);
        setBlobUrl(url);
      } else {
        console.error("Failed to fetch PDF:");
      }
    };

    fetchPdf();

    return () => {
      if (blobUrl) {
        URL.revokeObjectURL(blobUrl);
      }
    };
  }, [printPdfUrl]);

  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
    >
      <Grid className="pdf-viewer-grid">
        {blobUrl ? (
          <Viewer
            theme={{
              theme: "dark",
            }}
            fileUrl={blobUrl}
            plugins={[defaultLayoutPluginInstance]}
          />
        ) : (
          <p>Loading PDF...</p>
        )}
      </Grid>
    </Worker>
  );
};

export default Print;
