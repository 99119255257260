import { FindingsPregnant } from "../../../../../assets/icons";
export type FollowUpDetailsType = {
  id: number | string;
  patient_id: number | string;
  patient_name: number | string;
  antenatal_card_id: number | string;
  date_of_visit: number | string;
  trimester_week: number | string;
  vitals: [
    {
      vital_id?: string;
      vital_date?: string;
      vital_name?: string;
      vital_unit?: string;
      vital_value?: number | string;
    }
  ];
  findings: [{ id: number; symptom_name: string }];
  tests: [];
  scans: [];
  notes: null;
  next_review_date: null;
  trimester: string;
  suggestions: [];
};
export default function index({
  lastFollowUpDetails,
}: {
  lastFollowUpDetails: FollowUpDetailsType;
}) {
  return (
    <div>
      {lastFollowUpDetails.findings?.map(
        (ele: { id: string | number; symptom_name: string }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              border: "1px solid #EDEDED",
              borderRadius: "7px",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px",
              padding: "11px 12px 12px",
              margin: "12px 12px",
            }}
          >
            <div>
              <FindingsPregnant />
            </div>
            <div style={{ fontSize: "16px", fontWeight: 500, margin: "8px" }}>
              {ele.symptom_name}
            </div>
          </div>
        )
      )}
    </div>
  );
}
