import { useState, useEffect, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { Typography, CircularProgress, Grid, debounce } from "@mui/material";
import {
  Sort,
  CustomButton,
  TextField,
  PageLoader,
} from "../../../../components/basic";
import {
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import { DataTable } from "../../../../components/shared";
import { ActiveStatusColor, statusColor } from "../../../../utils/GeneralUtils";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  discountValidator,
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import { displayText } from "../../../../constants/displayText";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import AddAndEditProduct from "./AddAndEdit";
import {
  addDosageForm,
  addDrugClassification,
  addMajorDrug,
  addManufacture,
  addProducts,
  addUnits,
  deleteProduct,
  getAllDosageForms,
  getAllDrugClassification,
  getAllMajorDrug,
  getAllManufacture,
  getAllMasterDosage,
  getAllMasterDrugClassification,
  getAllMasterMajorDrug,
  getAllMasterManufacture,
  getAllMasterTax,
  getAllMasterUnits,
  getAllProductList,
  getAllUnit,
  getProductById,
  getUpdateExistProductById,
  updateProductById,
} from "../../../../services/procurementMasterService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { getAllMasterDepartments } from "../../../../services/gynecologyService";
import { setCurrentPage } from "../../../../redux/slices/pagination";
type GridRowData = Record<string, unknown>;

function Products() {
  const { ADD, EDIT, ADD_BUTTON_TITLE, CONFIRMATION_DELETE_DIALOG_TITLE } =
    displayText;
  const [isAddNewDrugCompositionClicked, setIsAddNewDrugCompositionClicked] =
    useState(false);
  const [currentMaster, setCurrentMaster] = useState("");
  // Products list Data table states and constants
  const dispatch = useDispatch();
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [loading, setLoading] = useState(false);
  const { procurementMasterProducts } = useSelector(
    (state: RootState) => state.pagination
  );
  const [appliedFilterCount, setAppliedFilterCount] = useState(0);
  const [filterData, setFilterData] = useState({
    name: "",
    code: "",
    major_drugs: "",
    search: "",
    generic_name: "",
    rack_position: "",
  });
  const [addInfoData, setAddInfoData] = useState<any>({
    name: "",
    short_name: "",
  });

  const [addInfoDataFormError, setAddInfoDataFormError] = useState<any>({
    name: "",
    short_name: "",
  });
  const addInfoFieldData = {
    name: {
      label: "Name",
      name: "name",
      value: addInfoData.name,
      isError: Boolean(addInfoDataFormError.name),
      helperText: addInfoDataFormError.name,
    },
    shortName: {
      label: "Short Name",
      name: "short_name",
      value: addInfoData.short_name,
      isError: Boolean(addInfoDataFormError.short_name),
      helperText: addInfoDataFormError.short_name,
    },
  };

  const [productList, setProductList] = useState([]);
  const [pageCount, setPageCount] = useState();
  // Add And Edit dialog states and constants
  const [dosageList, setDosageList] = useState([]);
  const [majorDrugList, setMajorDrugList] = useState<any>([]);
  const [manufactureList, setManufactureList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const [drugClassificationList, setDrugClassificationList] = useState([]);
  const [isAddAndEditDialogOpen, setIsAddAndEditDialogOpen] =
    useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isUpdateExistButtonLoading, setIsUpdateExistButtonLoading] =
    useState<boolean>(false);
  const [isAddNewLoading, setIsAddNewLoading] = useState<boolean>(false);
  const [isDialogLoading, setIsDialogLoading] = useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);
  // confirmation dialog states and constants

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [productToBeDeleted, setProductToBeDeleted] = useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const [scheduleTypeOptions, setScheduleTypeOptions] = useState<any>([]);
  // const searchMajorDrugsPagination = useRef({ search: "", page: 1 });
  // const MajorDrugsPagination: any = useRef(1);
  // const [majorDrugsDefaultOptions, setMajorDrugsDefaultOptions] = useState<any>(
  //   []
  // );

  // form states and constants

  const initialFormError = {
    name: "",
    generic_name: "",
    hsn_code: "",
    prescription: "",
    major_drug: "",
    drug_classification: "",
    dosage_form: "",
    unit: "",
    schedule_type: "",
    manufacturer: "",
    tax: "",
    discount: "",
    pack_type: "",
    rackposition: "",
    department: "",
    re_order_qty: "",
    max_qty: "",
    min_qty: "",
  };

  const [productFormError, setProductFormError] =
    useState<any>(initialFormError);
  type ProductTypes = {
    name: string | number | null;
    generic_name: string | number | null;
    hsn_code: string | number | null;
    prescription: string | number | null;
    major_drug: string | number | null;
    major_drug_input: any;
    drug_classification: string | number | null;
    drug_classification_input: any;
    dosage_form: string | number | null;
    dosage_form_input: any;
    unit: string | number | null;
    unit_input: any;
    schedule_type: string | number | null;
    manufacturer: string | number | null;
    manufacturer_input: any;
    tax: string | number | null;
    discount: string | number | null;
    pack_type: string | number | null;
    rackposition: string | number | null;
    tax_id: string | number | null;
    p_rate: string | number | null;
    mrp: string | number | null;
    exist_pack_type: string | number | null;
    product_departments: [
      {
        is_indent: any;
        dept_id: string | number | null;
        re_order_qty: string | number | null;
        max_qty: string | number | null;
        min_qty: string | number | null;
      }
    ];
    active: boolean | null;
  };
  const initialData: ProductTypes = {
    name: "",
    generic_name: "",
    hsn_code: "",
    prescription: null,
    major_drug: null,
    major_drug_input: "",
    drug_classification: null,
    drug_classification_input: "",
    dosage_form: null,
    dosage_form_input: "",
    unit: null,
    unit_input: "",
    schedule_type: null,
    manufacturer: null,
    manufacturer_input: "",
    tax: null,
    discount: "",
    pack_type: "",
    rackposition: "",
    tax_id: "",
    p_rate: "",
    mrp: "",
    exist_pack_type: "",
    product_departments: [
      {
        is_indent: false,
        dept_id: null,
        re_order_qty: "",
        max_qty: "",
        min_qty: "",
      },
    ],
    active: true,
  };

  const [productFormData, setProductFormData] = useState<any>(initialData);
  const fieldData: any = {
    name: {
      label: "Product Name",
      name: "name",
      value: productFormData.name,
      isError: Boolean(productFormError.name),
      helperText: productFormError.name,
    },
    generic_name: {
      label: "Generic Name",
      name: "generic_name",
      value: productFormData.generic_name,
      isError: Boolean(productFormError.generic_name),
      helperText: productFormError.generic_name,
    },
    hsn_code: {
      label: "HSN Code",
      name: "hsn_code",
      value: productFormData.hsn_code,
      isError: Boolean(productFormError.hsn_code),
      helperText: productFormError.hsn_code,
    },
    prescription: {
      label: "Prescription",
      name: "prescription",
      value: productFormData.prescription,
      isError: Boolean(productFormError.prescription),
      helperText: productFormError.prescription,
    },
    major_drug: {
      label: "Major Drug",
      name: "major_drug",
      value: productFormData.major_drug,
      isError: Boolean(productFormError.major_drug),
      helperText: productFormError.major_drug,
      options: majorDrugList,
      major_drug_input: productFormData.major_drug_input,
      // defaultOptions: majorDrugsDefaultOptions,
    },
    drug_classification: {
      label: "Drug Classification",
      name: "drug_classification",
      value: productFormData.drug_classification,
      isError: Boolean(productFormError.drug_classification),
      helperText: productFormError.drug_classification,
      options: drugClassificationList,
      drug_classification_input: productFormData.drug_classification_input,
    },
    dosage_form: {
      label: "Dosage Form",
      name: "dosage_form",
      value: productFormData.dosage_form,
      isError: Boolean(productFormError.dosage_form),
      helperText: productFormError.dosage_form,
      placeholder: "Select Dosage Form",
      options: dosageList,
      dosage_form_input: productFormData.dosage_form_input,
    },
    unit: {
      label: "Unit",
      name: "unit",
      value: productFormData.unit,
      isError: Boolean(productFormError.unit),
      helperText: productFormError.unit,
      placeholder: "Select Unit",
      options: unitsList,
      unit_input: productFormData.unit_input,
    },
    schedule_type: {
      label: "Schedule Type",
      name: "schedule_type",
      value: productFormData.schedule_type,
      isError: Boolean(productFormError.schedule_type),
      helperText: productFormError.schedule_type,
      placeholder: "Select Schedule Type",
      options: scheduleTypeOptions,
    },
    manufacturer: {
      label: "Manufacturer",
      name: "manufacturer",
      value: productFormData.manufacturer,
      isError: Boolean(productFormError.manufacturer),
      helperText: productFormError.manufacturer,
      options: manufactureList,
      manufacturer_input: productFormData.manufacturer_input,
    },
    tax: {
      label: "Tax",
      name: "tax",
      value: productFormData.tax,
      isError: Boolean(productFormError.tax),
      helperText: productFormError.tax,
      placeholder: "Select Tax",
      options: taxList,
    },
    tax_id: {
      label: "Tax",
      name: "tax_id",
      value: productFormData.tax_id,
      isError: Boolean(productFormError.tax_id),
      helperText: productFormError.tax_id,
      placeholder: "Select Tax",
      options: taxList,
    },
    discount: {
      label: "Discount",
      name: "discount",
      value: productFormData.discount,
      isError: Boolean(productFormError.discount),
      helperText: productFormError.discount,
    },
    pack_type: {
      label: "Pack Type",
      name: "pack_type",
      value: productFormData.pack_type,
      isError: Boolean(productFormError.pack_type),
      helperText: productFormError.pack_type,
    },
    pack_rate: {
      label: "Pack Rate",
      name: "p_rate",
      value: productFormData.p_rate,
      isError: Boolean(productFormError.p_rate),
      helperText: productFormError.p_rate,
    },
    mrp: {
      label: "MRP",
      name: "mrp",
      value: productFormData.mrp,
      isError: Boolean(productFormError.mrp),
      helperText: productFormError.mrp,
    },
    exist_pack_type: {
      label: "Pack Type",
      name: "exist_pack_type",
      value: productFormData.exist_pack_type,
      isError: Boolean(productFormError.exist_pack_type),
      helperText: productFormError.exist_pack_type,
    },
    rackposition: {
      label: "Rack Position",
      name: "rackposition",
      value: productFormData.rackposition,
      isError: Boolean(productFormError.rackposition),
      helperText: productFormError.rackposition,
    },
    status: {
      label: "Status",
      name: "active",
      value: productFormData.active,
    },
  };

  const [productFieldData, setProductFieldData] = useState(fieldData);

  // Products list Data table functions

  const handleFilterInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;

      setFilterData((prevState: any) => {
        const newFilter = { ...prevState, [name]: value };
        const appliedFilterCount = Object.entries(newFilter).filter(
          ([key, val]) => key !== "search" && Boolean(val)
        ).length;

        setAppliedFilterCount(appliedFilterCount);
        return newFilter;
      });
    }
    dispatch(setCurrentPage({ key: "procurementMasterProducts", value: 0 }));
  };
  const handleAddItemChange = (e: any) => {
    const { name, value } = e.target;
    setAddInfoData((prevState: any) => ({ ...prevState, [name]: value }));
    handleMasterValidation(e);
  };
  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    dispatch(setCurrentPage({ key: "procurementMasterProducts", value: 0 }));
  };
  const deleteProductRow = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setProductToBeDeleted(row.id);
  };
  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIdToBeUpdated(row.id);
            setIsAddAndEditDialogOpen(true);
          }}
        >
          <EditIcon />
        </div>
        {row.is_gynecology == 0 && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => deleteProductRow(row)}
          >
            <DeleteIcon />
          </div>
        )}
      </div>
    );
  };

  const clearFilters = () => {
    if (filterData.name || filterData.code || filterData.major_drugs) {
      setFilterData({
        ...filterData,
        name: "",
        code: "",
        major_drugs: "",
        // search: "",
        generic_name: "",
        rack_position: "",
      });
      setAppliedFilterCount(0);
    }
  };

  const deleteProductById = async () => {
    if (productToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    await deleteProduct(productToBeDeleted as number)
      .then((res: any) => {
        debouncedGetAllAppointment({
          page: Number(procurementMasterProducts.page) + 1,
          pageSize: procurementMasterProducts.pageSize,
          column: sortedField.field,
          order: sortedField.order,
          search: filterData.search,
          name: filterData.name,
          code: filterData.code,
          generic_name: filterData.generic_name,
          rack_position: filterData.rack_position,
          major_drug: filterData.major_drugs,
        });
        setProductToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        setDeleteBtnLoader(false);
      })
      .catch((err: any) => {
        setDeleteBtnLoader(false);
        setProductToBeDeleted(null);
        setIsConfirmationDialogOpen(false);
        dispatch(
          setSnackBarFailed({ snackBarMessage: err?.response?.data?.errors })
        );
        console.log(err);
      });
  };
  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "generic_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("generic_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Generic Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "generic_name" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "generic_name" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.generic_name || ""}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "manufacturer",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("manufacturer")}
        >
          <Typography variant="h5" fontSize={14}>
            Manufacturer
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "manufacturer" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "manufacturer" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.manufacturer}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "rackposition",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("rackposition")}
        >
          <Typography variant="h5" fontSize={14}>
            Rack Position
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "rackposition" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "rackposition" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.rackposition}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "pack_type",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("pack_type")}
        >
          <Typography variant="h5" fontSize={14}>
            Pack Type
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "pack_type" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "pack_type" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.pack_type}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },

    {
      field: "active",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row.active : null;
        const { buttonText, backgroundColor, textColor } =
          ActiveStatusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    {
      field: "total_stock_qty",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("total_stock_qty")}
        >
          <Typography variant="h5" fontSize={14}>
            Stock
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "total_stock_qty" &&
              sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "total_stock_qty" &&
              sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.total_stock_qty}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];
  const productFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <TextField
          value={filterData.name}
          placeholder={"Enter Name"}
          label={"Name"}
          formControlStyle={{ width: "100%" }}
          onChange={handleFilterInputChange}
          name={"name"}
        />
      ),
    },

    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <TextField
          value={filterData.code}
          placeholder={"Enter HSN Code"}
          label={"HSN Code"}
          formControlStyle={{ width: "100%" }}
          onChange={handleFilterInputChange}
          name={"code"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <TextField
          value={filterData.major_drugs}
          placeholder={"Enter Major Drugs"}
          label={"Major Drugs"}
          formControlStyle={{ width: "100%" }}
          onChange={handleFilterInputChange}
          name={"major_drugs"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <TextField
          value={filterData.rack_position}
          placeholder={"Enter Rack Position"}
          label={"Rack Position"}
          formControlStyle={{ width: "100%" }}
          onChange={handleFilterInputChange}
          name={"rack_position"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 2.5, lg: 1.9, xl: 1.8 },
      children: (
        <TextField
          value={filterData.generic_name}
          placeholder={"Enter Generic Name"}
          label={"Generic Name"}
          formControlStyle={{ width: "100%" }}
          onChange={handleFilterInputChange}
          name={"generic_name"}
        />
      ),
    },
  ];
  const onCloseDrugComp = () => {
    setAddInfoData((prevState: any) => ({
      ...prevState,
      name: "",
      short_name: "",
    }));
    setAddInfoDataFormError((prevState: any) => ({
      ...prevState,
      name: "",
      short_name: "",
    }));
    setIsAddNewLoading(false);
    setIsAddNewDrugCompositionClicked(!isAddNewDrugCompositionClicked);
  };
  const handleClearSearch = () => {
    setFilterData((prevState: any) => {
      const newFilter = { ...prevState, search: "" };
      return newFilter;
    });
    dispatch(setCurrentPage({ key: "procurementMasterProducts", value: 0 }));
  };
  const getDrugClassificationList = async () => {
    await getAllDrugClassification()
      .then((res: any) => {
        const formattedData = res?.data?.map(
          (ele: { id: number; name: string }) => ({
            id: ele.id,
            name: ele.name,
          })
        );
        setDrugClassificationList(formattedData);
      })
      .catch((error: any) => {
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.errors,
          })
        );
        console.error("An error occurred:", error);
      });
  };
  const handleAddItem = (name: any) => {
    setCurrentMaster(name);
    setIsAddNewDrugCompositionClicked(true);
  };
  const handleRenderFunction = (data: {
    name: string;
    active: number;
    short_name?: string;
  }) => {
    try {
      let returnFunc;
      if (currentMaster === "Major Drugs") {
        returnFunc = addMajorDrug(data);
        returnFunc.then((res: any) => {
          console.log("returnFunc", res);
          getMajorDrugList();
          setProductFormData((prev: any) => ({
            ...prev,
            major_drug: res?.data?.id,
            major_drug_input: res?.data?.name,
          }));
        });
      }
      if (currentMaster === "Dosage Forms") {
        data["short_name"] = addInfoData.short_name;
        returnFunc = addDosageForm(data);
        returnFunc.then((res: any) => {
          console.log("returnFunc", res);
          getDosageFormsList();
          setProductFormData((prev: any) => ({
            ...prev,
            dosage_form: res?.data?.id,
            dosage_form_input: res?.data?.name,
          }));
        });
      }
      if (currentMaster === "Units") {
        returnFunc = addUnits(data);
        returnFunc.then((res: any) => {
          console.log("returnFunc", res);
          getUnitList();
          setProductFormData((prev: any) => ({
            ...prev,
            unit: res?.data?.id,
            unit_input: res?.data?.name,
          }));
        });
      }
      if (currentMaster === "Manufacturer") {
        returnFunc = addManufacture(data);
        returnFunc.then((res: any) => {
          console.log("returnFunc", res);
          getManufactureList();
          setProductFormData((prev: any) => ({
            ...prev,
            manufacturer: res?.data?.id,
            manufacturer_input: res?.data?.name,
          }));
        });
      }
      if (currentMaster === "Drug Classification") {
        returnFunc = addDrugClassification(data);
        returnFunc.then((res: any) => {
          console.log("returnFunc", res);
          getDrugClassificationList();
          setProductFormData((prev: any) => ({
            ...prev,
            drug_classification: res?.data?.id,
            drug_classification_input: res?.data?.name,
          }));
        });
      }
      return returnFunc;
    } catch (error: any) {
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.errors,
        })
      );
      console.error("An error occurred:", error);
    }
  };
  // const handleDefaultSelect = (data: { id: number; name: string }) => {
  //   const formattedValue = {
  //     id: data.id,
  //     name: data.name,
  //   };
  //   let formattedList: any = [];
  //   if (currentMaster === "Major Drugs") {
  //     setProductFormData((prev: any) => ({
  //       ...prev,
  //       major_drug: formattedValue,
  //     }));
  //     handleValidation({ target: fieldData["major_drug"] });
  //     formattedList = [...majorDrugList, formattedValue];
  //     setMajorDrugList(formattedList);
  //   }
  //   if (currentMaster === "Dosage Forms") {
  //     setProductFormData((prev: any) => ({
  //       ...prev,
  //       dosage_form: formattedValue,
  //     }));
  //     handleValidation({ target: fieldData["dosage_form"] });
  //     formattedList = [...dosageList, formattedValue];
  //     setDosageList(formattedList);
  //   }
  //   if (currentMaster === "Units") {
  //     setProductFormData((prev: any) => ({
  //       ...prev,
  //       unit: formattedValue,
  //     }));
  //     handleValidation({ target: fieldData["unit"] });

  //     formattedList = [...unitsList, formattedValue];
  //     setUnitsList(formattedList);
  //   }
  //   if (currentMaster === "Manufacturer") {
  //     setProductFormData((prev: any) => ({
  //       ...prev,
  //       manufacturer: formattedValue,
  //     }));
  //     handleValidation({ target: fieldData["manufacturer"] });
  //     formattedList = [...manufactureList, formattedValue];
  //     setManufactureList(formattedList);
  //   }
  //   if (currentMaster === "Drug Classification") {
  //     setProductFormData((prev: any) => ({
  //       ...prev,
  //       drug_classification: formattedValue,
  //     }));
  //     handleValidation({ target: fieldData["drug_classification"] });
  //     formattedList = [...drugClassificationList, formattedValue];
  //     setDrugClassificationList(formattedList);
  //   }
  // };
  const handleSubmitAddItem = async () => {
    if (
      addInfoData.name === "" ||
      (currentMaster == "Dosage Forms" && addInfoData.short_name === "")
    ) {
      validateMasterForm();
      return false;
    } else {
      try {
        setIsAddNewLoading(true);
        const data = {
          name: addInfoData.name,
          active: 1,
        };
        const renderFunc = handleRenderFunction(data);
        if (renderFunc) {
          const res: any = await renderFunc;
          // handleDefaultSelect(res?.data);
          onCloseDrugComp();
          setIsAddNewLoading(false);
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        }
      } catch (error: any) {
        dispatch(
          setSnackBarFailed({ snackBarMessage: error?.response?.data?.errors })
        );
        console.error("Error in handleSubmitAddItem:", error);
        setIsAddNewLoading(false);
      }
    }
  };
  const getMasterTaxList = async () => {
    await getAllMasterTax()
      .then((res: any) => {
        const formattedData = res?.data?.map(
          (ele: {
            id: number;
            tax_name: string;
            tax_rate: string | number;
          }) => ({
            id: ele.id,
            name: `${ele.tax_name} - ${ele.tax_rate}%`,
          })
        );
        setTaxList(formattedData);
      })
      .catch((error: any) => {
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.errors,
          })
        );
        console.error("An error occurred:", error);
      });
  };
  const getManufactureList = async () => {
    await getAllManufacture()
      .then((res: any) => {
        const formattedData = res?.data?.map(
          (ele: { id: number; name: string }) => ({
            id: ele.id,
            name: ele.name,
          })
        );
        setManufactureList(formattedData);
      })
      .catch((error: any) => {
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.errors,
          })
        );
        console.error("An error occurred:", error);
      });
  };
  const getMajorDrugList = async () => {
    await getAllMajorDrug()
      .then((res: any) => {
        const formattedData = res?.data?.map(
          (ele: { id: number; name: string }) => ({
            id: ele.id,
            name: ele.name,
          })
        );
        setMajorDrugList(formattedData);
      })
      .catch((err: any) => console.log(err));
  };
  const getDosageFormsList = async () => {
    await getAllDosageForms()
      .then((res: any) => {
        const formattedData = res?.data?.map(
          (ele: { id: number; name: string }) => ({
            id: ele.id,
            name: ele.name,
          })
        );
        setDosageList(formattedData);
      })
      .catch((err: any) => console.log(err));
  };
  const getUnitList = async () => {
    await getAllUnit()
      .then((res: any) => {
        const formattedData = res?.data?.map(
          (ele: { id: number; name: string }) => ({
            id: ele.id,
            name: ele.name,
          })
        );
        setUnitsList(formattedData);
      })
      .catch((err: any) => console.log(err));
  };
  const [departmentList, setDepartmentList] = useState([]);
  const getAllDepartmentList = async () => {
    const data = { active: 1 };
    await getAllMasterDepartments(data)
      .then((res: any) => {
        const formattedValue = res?.data?.result?.map(
          (ele: { dept_name: string; id: number }) => ({
            id: ele.id,
            name: ele.dept_name,
          })
        );
        setDepartmentList(formattedValue);
      })
      .catch((err: any) => console.log(err));
  };

  useEffect(() => {
    getDrugClassificationList();
    getMasterTaxList();
    getManufactureList();
    getMajorDrugList();
    getDosageFormsList();
    getUnitList();
    getAllDepartmentList();
  }, []);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name":
      case "generic_name":
      case "pack_type": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setProductFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setProductFormError);
        }
        break;
      }
      case "discount": {
        if (discountValidator(value)) {
          updateFormDataWithHelperText(
            name,
            discountValidator(value),
            setProductFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setProductFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateProductsFieldData = () => {
    setProductFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          helperText: productFormError[field.name],
          value: productFormData[field.name],
          isError: productFormError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateProductsFieldData();
  }, [productFormError, productFormData]);

  // form fields onchange functions

  const handleInputChange = (e: any, index?: number | string) => {
    const { name, value } = e.target;
    if (
      name === "re_order_qty" ||
      name === "max_qty" ||
      name === "min_qty" ||
      name === "dept_id"
    ) {
      setProductFormData((prev: any) => ({
        ...prev,
        product_departments: prev.product_departments.map(
          (department: any, i: number) => {
            if (i === index) {
              return {
                ...department,
                [name]: value,
              };
            }
            return department;
          }
        ),
      }));
      handleValidation(e);
    } else {
      setProductFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
      handleValidation(e);
    }
  };

  const handleSelectChange = (e: any, id: number) => {
    const { name, value } = e.target;

    if (name === "dept_id") {
      setProductFormData((prev: any) => ({
        ...prev,
        product_departments: prev?.product_departments?.map(
          (item: any, index: number) => {
            if (index === id) {
              return {
                ...item,
                [name]: value,
              };
            }
            return item;
          }
        ),
      }));
    } else {
      setProductFormData((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    }

    handleValidation(e);
  };

  const handleCheckBoxChange = (e: any, id: number) => {
    const { name, checked } = e.target;

    setProductFormData((prev: any) => ({
      ...prev,
      product_departments: prev?.product_departments?.map(
        (item: any, index: number) => {
          if (index === id) {
            return {
              ...item,
              [name]: checked,
            };
          }
          return item;
        }
      ),
    }));

    handleValidation(e);
  };
  const updateExistPackType = async () => {
    const data = {
      pack_type: productFormData?.exist_pack_type,
      p_rate: productFormData?.p_rate,
      mrp: productFormData?.mrp,
      tax: productFormData?.tax_id,
    };
    try {
      setIsUpdateExistButtonLoading(true);
      await getUpdateExistProductById(idToBeUpdated as number, data).then(
        (res: any) => {
          closeAddAndEditDialog();
          setIsUpdateExistButtonLoading(false);
          debouncedGetAllAppointment({
            page: Number(procurementMasterProducts.page) + 1,
            pageSize: procurementMasterProducts.pageSize,
            column: sortedField.field,
            order: sortedField.order,
            search: filterData.search,
            name: filterData.name,
            code: filterData.code,
            generic_name: filterData.generic_name,
            rack_position: filterData.rack_position,
            major_drug: filterData.major_drugs,
          });
        }
      );
    } catch (error: any) {
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      console.error("An error occurred:", error);
      setIsUpdateExistButtonLoading(false);
    }
  };

  useEffect(() => {
    if (idToBeUpdated) {
      setIsDialogLoading(true);
      getProductById(idToBeUpdated)
        .then((res: any) => {
          const productDetail: any = res?.data;

          const depDetails = productDetail?.product_departments?.map(
            (item: any, index: number) => ({
              is_indent: item?.is_indent == 1 ? true : false,
              dept_id: item?.dept_id,
              re_order_qty: item?.re_order_qty,
              max_qty: item?.max_qty,
              min_qty: item?.min_qty,
            })
          );
          setProductFormData((prevState: ProductTypes) => ({
            ...prevState,
            name: productDetail.name,
            generic_name: productDetail.generic_name,
            hsn_code: productDetail.hsn_code,
            prescription: productDetail.prescription,
            major_drug: productDetail?.major_drug?.id
              ? productDetail?.major_drug?.id
              : null,
            major_drug_input: productDetail?.major_drug?.name
              ? productDetail?.major_drug?.name
              : "",
            drug_classification: productDetail?.drug_classification?.id
              ? productDetail?.drug_classification?.id
              : null,
            drug_classification_input: productDetail?.drug_classification?.name
              ? productDetail?.drug_classification?.name
              : "",
            dosage_form: productDetail?.dosage_form?.id
              ? productDetail?.dosage_form?.id
              : null,
            dosage_form_input: productDetail?.dosage_form?.name
              ? productDetail?.dosage_form?.name
              : "",
            unit: productDetail?.units?.id ? productDetail?.units?.id : null,
            unit_input: productDetail?.units?.name
              ? productDetail?.units?.name
              : "",
            schedule_type: productDetail?.schedule_type,
            manufacturer: productDetail?.manufacture?.id
              ? productDetail?.manufacture?.id
              : null,
            manufacturer_input: productDetail?.manufacture?.name
              ? productDetail?.manufacture?.name
              : "",
            tax: productDetail.tax,
            discount: productDetail.discount,
            pack_type: productDetail.pack_type,
            rackposition: productDetail.rackposition,
            exist_pack_type: productDetail.pack_type,
            p_rate: productDetail.p_rate,
            tax_id: productDetail.tax_id,
            mrp: productDetail.mrp,
            product_departments:
              depDetails.length !== 0
                ? depDetails
                : initialData.product_departments,
            active: productDetail.active === 1 ? true : false,
          }));
          setIsDialogLoading(false);
        })
        .catch((err: any) => {
          setIsDialogLoading(false);
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.log(err);
        });
    }
  }, [idToBeUpdated]);

  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    setProductFormData((prev: any) => ({
      ...prev,
      [name]: newValue,
    }));

    handleValidation(e);
  };

  const handleAutoCompleteInputChange = (newInputValue: any, name: string) => {
    if (newInputValue == "") {
      return false;
    }
    setProductFormData((prev: any) => ({
      ...prev,
      [name]: newInputValue,
    }));
  };
  const handleBackspace = (inputFieldName: string) => (event: any) => {
    if (
      event.key === "Backspace" &&
      productFormData[inputFieldName].length === 1
    ) {
      setProductFormData((prev: any) => ({
        ...prev,
        [inputFieldName]: "",
      }));
    }
  };

  useEffect(() => {
    const handleBackspaceForMajorDrug = handleBackspace("major_drug_input");
    window.addEventListener("keydown", handleBackspaceForMajorDrug);
    return () => {
      window.removeEventListener("keydown", handleBackspaceForMajorDrug);
    };
  }, [productFormData.major_drug_input]);

  useEffect(() => {
    const handleBackspaceForDrugClassification = handleBackspace(
      "drug_classification_input"
    );
    window.addEventListener("keydown", handleBackspaceForDrugClassification);
    return () => {
      window.removeEventListener(
        "keydown",
        handleBackspaceForDrugClassification
      );
    };
  }, [productFormData.drug_classification_input]);

  useEffect(() => {
    const handleBackspaceForDosageForm = handleBackspace("dosage_form_input");
    window.addEventListener("keydown", handleBackspaceForDosageForm);
    return () => {
      window.removeEventListener("keydown", handleBackspaceForDosageForm);
    };
  }, [productFormData.dosage_form_input]);

  useEffect(() => {
    const handleBackspaceForUnit = handleBackspace("unit_input");
    window.addEventListener("keydown", handleBackspaceForUnit);
    return () => {
      window.removeEventListener("keydown", handleBackspaceForUnit);
    };
  }, [productFormData.unit_input]);

  useEffect(() => {
    const handleBackspaceForManufacturer =
      handleBackspace("manufacturer_input");
    window.addEventListener("keydown", handleBackspaceForManufacturer);
    return () => {
      window.removeEventListener("keydown", handleBackspaceForManufacturer);
    };
  }, [productFormData.manufacturer_input]);
  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setProductFormData((prev: any) => ({
      ...prev,
      active: checked,
    }));
  };

  const handleAddDepartment = async () => {
    setProductFormData((prev: any) => ({
      ...prev,
      product_departments: [
        ...prev.product_departments,
        {
          dept_id: null,
          re_order_qty: "",
          max_qty: "",
          min_qty: "",
        },
      ],
    }));
  };
  const handleDeleteDepartment = async (id: number) => {
    const newState = { ...productFormData };
    newState.product_departments.splice(id, 1);
    setProductFormData(newState);
  };

  const createProduct = async () => {
    const departmentsDetails = productFormData?.product_departments?.filter(
      (item: any) => item?.dept_id !== null
    );
    const data = {
      ...productFormData,
      // id:6741,
      active: productFormData.active == true ? 1 : 0,
      major_drug: productFormData?.major_drug?.id
        ? productFormData?.major_drug?.id
        : productFormData?.major_drug,
      schedule_type: productFormData?.schedule_type
        ? productFormData?.schedule_type
        : null,
      drug_classification: productFormData?.drug_classification?.id
        ? productFormData?.drug_classification?.id
        : productFormData?.drug_classification,
      dosage_form: productFormData?.dosage_form?.id
        ? productFormData?.dosage_form?.id
        : productFormData?.dosage_form,
      unit: productFormData?.unit?.id
        ? productFormData?.unit?.id
        : productFormData?.unit,
      manufacturer: productFormData?.manufacturer?.id
        ? productFormData?.manufacturer?.id
        : productFormData?.manufacturer,
      product_departments: departmentsDetails?.length
        ? departmentsDetails?.map((item: any) => ({
            dept_id: item?.dept_id,
            is_indent: item?.is_indent ? 1 : 0,
            re_order_qty: item?.re_order_qty,
            max_qty: item?.max_qty,
            min_qty: item?.min_qty,
          }))
        : [],
    };
    const editData = {
      ...productFormData,
      // id:6741,
      active: productFormData.active == true ? 1 : 0,
      major_drug: productFormData?.major_drug?.id
        ? productFormData?.major_drug?.id
        : productFormData?.major_drug,
      schedule_type: productFormData?.schedule_type
        ? productFormData?.schedule_type
        : null,
      drug_classification: productFormData?.drug_classification?.id
        ? productFormData?.drug_classification?.id
        : productFormData?.drug_classification,
      dosage_form: productFormData?.dosage_form?.id
        ? productFormData?.dosage_form?.id
        : productFormData?.dosage_form,
      unit: productFormData?.unit?.id
        ? productFormData?.unit?.id
        : productFormData?.unit,
      manufacturer: productFormData?.manufacturer?.id
        ? productFormData?.manufacturer?.id
        : productFormData?.manufacturer,
      product_departments: departmentsDetails?.length
        ? departmentsDetails?.map((item: any) => ({
            dept_id: item?.dept_id,
            is_indent: item?.is_indent ? 1 : 0,
            re_order_qty: item?.re_order_qty,
            max_qty: item?.max_qty,
            min_qty: item?.min_qty,
          }))
        : [],
    };
    if (
      productFormData.name === "" ||
      productFormData.generic_name === "" ||
      productFormData.pack_type === "" ||
      (productFormData.discount !== "" &&
        (isNaN(Number(productFormData.discount)) ||
          Number(productFormData.discount) > 100))
    ) {
      validateForm();
    } else {
      if (idToBeUpdated) {
        setIsButtonLoading(true);
        await updateProductById(idToBeUpdated as number, editData)
          .then((res: any) => {
            closeAddAndEditDialog();
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            debouncedGetAllAppointment({
              page: Number(procurementMasterProducts.page) + 1,
              pageSize: procurementMasterProducts.pageSize,
              column: sortedField.field,
              order: sortedField.order,
              search: filterData.search,
              name: filterData.name,
              code: filterData.code,
              generic_name: filterData.generic_name,
              rack_position: filterData.rack_position,
              major_drug: filterData.major_drugs,
            });
            setIsButtonLoading(false);
          })
          .catch((err: any) => {
            const errorMessage = err?.response?.data?.errors
              ? err?.response?.data?.errors
              : "Unknown error occurred";
            dispatch(
              setSnackBarFailed({
                snackBarMessage: `${errorMessage}`,
              })
            );
            setIsButtonLoading(false);
          });
      } else {
        // data["active"] = 1;
        delete data.tax_id;
        delete data.p_rate;
        delete data.mrp;
        delete data.exist_pack_type;
        data["active"] = 1;

        setIsButtonLoading(true);

        await addProducts(data)
          .then((res: any) => {
            closeAddAndEditDialog();
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            debouncedGetAllAppointment({
              page: Number(procurementMasterProducts.page) + 1,
              pageSize: procurementMasterProducts.pageSize,
              column: sortedField.field,
              order: sortedField.order,
              search: filterData.search,
              name: filterData.name,
              code: filterData.code,
              generic_name: filterData.generic_name,
              rack_position: filterData.rack_position,
              major_drug: filterData.major_drugs,
            });
            setIsButtonLoading(false);
          })
          .catch((err: any) => {
            const errorMessage = err?.response?.data?.errors
              ? err?.response?.data?.errors
              : "Unknown error occurred";
            dispatch(
              setSnackBarFailed({
                snackBarMessage: `${errorMessage}`,
              })
            );
            setIsButtonLoading(false);
          });
      }
    }
  };
  const handleConfirmationDelete = async () => {};

  const openAddAndEditDialog = async () => {
    setIsAddAndEditDialogOpen(true);
  };

  const closeAddAndEditDialog = async () => {
    setIsButtonLoading(false);
    setIsAddAndEditDialogOpen(false);
    setProductFormData(initialData);
    setIdToBeUpdated(null);
    setProductFormError(initialFormError);
  };

  const debouncedGetAllAppointment = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllProductList(data)
        .then((res: any) => {
          setProductList(res?.data?.result);
          setPageCount(res?.data?.total as any);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          const errorMessage = err?.response?.data?.errors
            ? err?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data = {
      page: Number(procurementMasterProducts.page) + 1,
      pageSize: procurementMasterProducts.pageSize,
      column: sortedField.field,
      order: sortedField.order,
      search: filterData.search,
      name: filterData.name,
      code: filterData.code,
      generic_name: filterData.generic_name,
      rack_position: filterData.rack_position,
      major_drug: filterData.major_drugs,
    };
    debouncedGetAllAppointment(data);
  }, [
    procurementMasterProducts.page,
    procurementMasterProducts.pageSize,
    sortedField.order,
    sortedField.field,
    filterData.name,
    filterData.code,
    filterData.generic_name,
    filterData.rack_position,
    filterData.search,
    filterData.major_drugs,
  ]);

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleMasterValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name":
      case "short_name": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setAddInfoDataFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setAddInfoDataFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateMasterForm = () => {
    for (const fieldName in addInfoFieldData) {
      if ((addInfoFieldData as any)[fieldName].name) {
        handleMasterValidation({
          target: (addInfoFieldData as any)[fieldName],
        });
      }
    }
  };

  const updateMasterFieldData = () => {
    setProductFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          helperText: addInfoDataFormError[field.name],
          value: addInfoData[field.name],
          isError: addInfoDataFormError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateMasterFieldData();
  }, [addInfoDataFormError, addInfoData]);
  const { commonVariables, appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const getCommonVariablesDetails = async () => {
    if (commonVariables) {
      setScheduleTypeOptions(commonVariables?.schedule_type);
    }
  };

  useEffect(() => {
    getCommonVariablesDetails();
  }, [commonVariables, appConfiguration]);

  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={CONFIRMATION_DELETE_DIALOG_TITLE}
        handleClick={deleteProductById}
        onClose={closeConfirmationDialog}
        loading={deleteBtnLoader}
      />
      <CustomFilter
        data={productFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        editButtonTitle={ADD_BUTTON_TITLE}
        searchValue={filterData.search}
        onUpdateButtonClick={openAddAndEditDialog}
        clearFilter={clearFilters}
        searchOnChange={handleFilterInputChange}
        appliedFilterCount={appliedFilterCount}
        searchName={"search"}
        clearSearch={handleClearSearch}
      />
      <DataTable
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={productList}
        pageCount={pageCount || 0}
        loading={loading}
        currentPage={"procurementMasterProducts"}
      />
      <DialogWrapper
        onClose={closeAddAndEditDialog}
        open={isAddAndEditDialogOpen}
        title={idToBeUpdated === null ? "Add Product" : "Edit Product"}
        // loading={isButtonLoading}
        maxWidth="md"
      >
        {isDialogLoading ? (
          <PageLoader />
        ) : (
          <AddAndEditProduct
            fieldData={fieldData}
            handleInputChange={handleInputChange}
            handleValidation={handleValidation}
            handleAutoCompleteChange={handleAutoCompleteChange}
            handleAutoCompleteInputChange={handleAutoCompleteInputChange}
            handleSelectChange={handleSelectChange}
            productFormData={productFormData}
            handleDeleteDepartment={handleDeleteDepartment}
            handleAddDepartment={handleAddDepartment}
            handleSwitchChange={handleSwitchChange}
            idToBeUpdated={idToBeUpdated}
            handleAddItem={handleAddItem}
            createProduct={createProduct}
            updateExistPackType={updateExistPackType}
            departmentList={departmentList}
            isButtonLoading={isButtonLoading}
            isUpdateExistButtonLoading={isUpdateExistButtonLoading}
            handleCheckBoxChange={handleCheckBoxChange}
            closeAddAndEditDialog={closeAddAndEditDialog}
            // getAllMajorDrugsData={getAllMajorDrugsData}
          />
        )}
      </DialogWrapper>
      <DialogWrapper
        open={isAddNewDrugCompositionClicked}
        onClose={() => onCloseDrugComp()}
        handleClick={handleSubmitAddItem}
        title={`Add ${currentMaster}`}
        maxWidth="xs"
        loading={isAddNewLoading}
      >
        <Grid container>
          <Grid item xs={12}>
            <TextField
              value={addInfoFieldData.name.value}
              name={addInfoFieldData.name.name}
              onChange={handleAddItemChange}
              label={addInfoFieldData.name.label}
              helperText={addInfoFieldData.name.helperText}
              sx={{
                width: " 100%",
              }}
            />
          </Grid>
          {currentMaster === "Dosage Forms" && (
            <Grid item xs={12}>
              <TextField
                value={addInfoFieldData.shortName.value}
                name={addInfoFieldData.shortName.name}
                onChange={handleAddItemChange}
                label={addInfoFieldData.shortName.label}
                helperText={addInfoFieldData.shortName.helperText}
                sx={{
                  width: " 100%",
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogWrapper>
    </>
  );
}
export default Products;
