import {
  pharmacyApiEndPoints,
  procurementsApiEndPoints,
} from "../constants/apiEndPoints";
import { ApiUtils } from "../utils";
const notIncludeItems: { [key: string]: string } = {
  product_id: "product_id",
};
const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);
  let queryString = "?";
  keys.map((key) => {
    if (data[key] && !Array.isArray(data[key])) {
      queryString += `${key}=${data[key]}&`;
    }
    if (Array.isArray(data[key])) {
      if (key !== "selected_batches" && !notIncludeItems[key]) {
        data[key].map(
          (ele: any, index: number) =>
            (queryString += `selected_batches[${index}][${key}]=${ele}&`)
        );
      } else {
        data[key].map(
          (ele: any, index: number) =>
            (queryString += `${key}[${index}]=${ele}&`)
        );
      }
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};
const getPayeeList = async () => {
  try {
    return await ApiUtils.getWithToken(pharmacyApiEndPoints.payeeUrl);
  } catch (error) {
    console.log("Error while getting the payee list", error);
    throw error;
  }
};
const getReferralDoctorsList = async () => {
  try {
    return await ApiUtils.getWithToken(pharmacyApiEndPoints.referralDoctorUrl);
  } catch (error) {
    console.log("Error while getting the referral doctors list", error);
    throw error;
  }
};
const getBillTermsOptions = async () => {
  try {
    const result: any = await ApiUtils.getWithToken(
      pharmacyApiEndPoints.getCommonVariablesUrl
    );

    return result.data.bill_payment_type;
  } catch (error) {
    console.log("Error while getting the patient's bill list", error);
    throw error;
  }
};
const addPayee = async (value: { name: string; creditLimit: string }) => {
  try {
    return await ApiUtils.postWithToken(pharmacyApiEndPoints.payeeUrl, value);
  } catch (error) {
    console.log("Error while adding the payee", error);
    throw error;
  }
};
const addReferralDoctor = async (value: {
  name: string;
  email: string;
  mobile: string | number;
  address: string;
}) => {
  try {
    return await ApiUtils.postWithToken(
      pharmacyApiEndPoints.referralDoctorUrl,
      {
        ...value,
        active: 1,
      }
    );
  } catch (error) {
    console.log("Error while adding the referral doctors", error);
    throw error;
  }
};
const getExcessAmountByPatientId = async (params: {
  patient_id: number | string | any;
  payee_id: number | string | any;
}) => {
  try {
    const apiUrl = setQueryParams(
      params,
      pharmacyApiEndPoints.advanceAndExcessAmountUrl
    );
    return await ApiUtils.getWithToken(apiUrl);
    // return {
    //   data: {
    //     excess: 10,
    //     advance: 10,
    //     creditlimit: 0,
    //     overalldue: 0,
    //     net_total: 0,
    //   },
    // };
  } catch (error) {
    console.log("Error while getting the excess amount by patient id", error);
    throw error;
  }
};
const getConsultantsList = async () => {
  try {
    return ApiUtils.getWithToken(pharmacyApiEndPoints.getConsultantsListUrl);
  } catch (error) {
    console.log("Error Occurs while getting the departments:", error);
  }
};
const getCommonVariables = async () => {
  try {
    return await ApiUtils.getWithToken(
      pharmacyApiEndPoints.getCommonVariablesUrl
    );
  } catch (error) {
    console.log("Error while getting the common variables", error);
    throw error;
  }
};
const getItemsByDepartment = async (params: {
  department?: string | number;
  selected_batches?: number[] | string[] | [];
  search?: string | number;
  product_id?: number[] | string[] | [];
}) => {
  try {
    const apiUrl = setQueryParams(
      params,
      pharmacyApiEndPoints.getPharmacyProductsList
    );
    return await ApiUtils.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error while getting the item name list", error);
    throw error;
  }
};
type PharmacyBillTypes = {
  patient_id?: null | number | string;
  payee?: null | number | string;
  invoice_no?: null | number | string;
  invoice_no_modified?: null | number | string;
  bill_payment_type?: null | number | string;
  ip_admission_id?: null | number | string;
  op_id?: null | number | string;
  rdType?: null | number | string;
  department?: null | number | string;
  dept_id?: null | number | string;
  doctor_id?: null | number | string;
  refdoctor?: null | number | string;
  date_created?: null | number | string;
  bill_category?: null | number | string;
  product?: [
    {
      product_id?: null | number | string;
      pack_type?: null | number | string;
      batch_id?: null | number | string;
      qty?: null | number | string;
      mrp?: null | number | string;
      discount?: null | number | string;
      tax_type?: null | number | string;
      tax_id?: null | number | string;
      amount?: null | number | string;
    }
  ];
  disc_val?: null | number | string;
  orig_goods_val?: null | number | string;
  disc_goods_val?: null | number | string;
  tax_split_info?: null | number | string;
  gst_amt?: null | number | string;
  bill_amt?: null | number | string;
  discount_val?: null | number | string;
  discount_type?: null | number | string;
  discount_amt?: null | number | string;
  adj_amt?: null | number | string;
  net_total_amt?: null | number | string;
  creditlimit_amt?: null | number | string;
  print?: null | number | string;
  payments?: [
    {
      type?: null | number | string;
      cash_paid?: null | number | string;
      urn?: null | number | string;
    }
  ];
  cash_received?: null | number | string;
  pay_paid_amt?: null | number | string;
  balance?: null | number | string;
  excess_amount?: null | number | string;
  refunded_excess?: null | number | string;
  net_excess?: null | number | string;
};
const addPharmacyBill = async (data: PharmacyBillTypes) => {
  return await ApiUtils.postWithToken(pharmacyApiEndPoints.pharmacyUrl, data);
};
const updatePharmacyBill = async (
  id: number | string,
  data: PharmacyBillTypes
) => {
  return await ApiUtils.putWithToken(
    `${pharmacyApiEndPoints.pharmacyUrl}/${id}`,
    data
  );
};
const getPharmacyBillById = async (id: string | number) => {
  return await ApiUtils.getWithToken(
    `${pharmacyApiEndPoints.pharmacyUrl}/${id}`
  );
};
const deletePharmacyBill = async (id: number | string) => {
  return await ApiUtils.deleteWithToken(
    `${pharmacyApiEndPoints.pharmacyUrl}/${id}`
  );
};
const getAllPharmacyBillDraft = async (params: {
  patient?: number | string | null;
  seq_no?: number | string | null;
  from_date?: number | string | null;
  to_date?: number | string | null;
  search?: number | string | null;
  column?: number | string | null;
  order?: number | string | null;
  page?: number | string | null;
  limit?: number | string | null;
}) => {
  const apiUrl = setQueryParams(params, pharmacyApiEndPoints.billDraftList);
  return await ApiUtils.getWithToken(apiUrl);
};
const getPharmacyBillDraftById = async (params: {
  draft_type?: number | string | null;
  id?: number | string | null;
}) => {
  return await ApiUtils.getWithToken(
    `${pharmacyApiEndPoints.billDraftList}/${params.draft_type}/${params.id}`
  );
};
type PharmacyReturnType = {
  patient_id?: number | string | null;
  department?: number | string | null;
  batch: number[] | string[] | [];
  search?: number | string | null;
  type?: number | string | null;
  days?: number | string | null;
  bill_id?: number[] | string[] | [];
};
const getPharmacyReturnProducts = async (params: PharmacyReturnType) => {
  const apiUrl = setQueryParams(
    params,
    pharmacyApiEndPoints.pharmacyReturnProducts
  );
  return await ApiUtils.getWithToken(apiUrl);
};
const getPharmacyReturnBillById = async (id: string | number) => {
  return await ApiUtils.getWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnUrl}/${id}`
  );
};
const cancelPharmacyReturnBill = async (id: number | string) => {
  return await ApiUtils.putWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnUrl}/${id}/cancel`
  );
};
const cancelPharmacyBill = async (id: number | string) => {
  return await ApiUtils.putWithToken(
    `${pharmacyApiEndPoints.pharmacyUrl}/${id}/cancel`
  );
};
const addSalesReturn = async (data: any) => {
  return await ApiUtils.postWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnUrl}`,
    data
  );
};
const updateSalesReturn = async (id: number, data: any) => {
  return await ApiUtils.putWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnUrl}/${id}`,
    data
  );
};
const getLatestPatientOpById = async (params: {
  patient_id?: string | number;
  search: string | number;
}) => {
  const apiUrl = setQueryParams(params, pharmacyApiEndPoints.getLatestOp);
  return await ApiUtils.getWithToken(apiUrl);
};
const getLatestPatientIpById = async (params: {
  patient_id?: string | number;
  search: string | number;
}) => {
  const apiUrl = setQueryParams(params, pharmacyApiEndPoints.getLatestIP);

  return await ApiUtils.getWithToken(apiUrl);
};
const getAllSalesList = async (params: {
  department: number | string | null;
  uhid: number | string | null;
  bill_type: number | string | null;
  pay_type: number | string | null;
  status: number | string | null;
  bill_no: number | string | null;
  from_date: number | string | null;
  to_date: number | string | null;
  search: number | string | null;
  column: number | string | null;
  order: number | string | null;
  page: number | string | null;
  limit: number | string | null;
}) => {
  const apiUrl = setQueryParams(
    params,
    procurementsApiEndPoints.pharmacySalesBill
  );
  return await ApiUtils.getWithToken(apiUrl);
};
const getPharmacyProductStockById = async (id: number | string) => {
  try {
    return await ApiUtils.getWithToken(
      `${pharmacyApiEndPoints.stockProductUrl}/${id}`
    );
  } catch (error) {
    console.log(
      "Error occurs while getting the get Pharmacy Product Stock By Id:",
      error
    );
    throw error;
  }
};
const getBillCollection = async (
  date: string
): Promise<{
  data: {
    bill_collection_data: {
      first_shift_amt: number;
      second_shift_amt: number;
      third_shift_amt: number;
      user_name: string;
    }[];
    first_shift_amt_total: number;
    second_shift_amt_total: number;
    third_shift_amt_total: number;
  };
}> => {
  try {
    const apiUrl = setQueryParams(
      { date },
      `${pharmacyApiEndPoints.pharmacyUrl}/bill-collection`
    );
    return (await ApiUtils.getWithToken(apiUrl)) as {
      data: {
        bill_collection_data: {
          first_shift_amt: number;
          second_shift_amt: number;
          third_shift_amt: number;
          user_name: string;
        }[];
        first_shift_amt_total: number;
        second_shift_amt_total: number;
        third_shift_amt_total: number;
      };
    };
  } catch (error) {
    console.log("Error while getting the patient's bill list", error);
    throw error;
  }
};
const deletePharmacyReturnProduct = async (id: number) => {
  return await ApiUtils.deleteWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnUrl}/${id}`
  );
};
const updatePharmacyReturnBillDetails = async (
  id: number,
  data: {
    bill_amt: number | string | null;
    discount_val: number | string | null;
    discount_type: number | string | null;
    discount_amt: number | string | null;
    net_total_amt: number | string | null;
  }
) => {
  return await ApiUtils.putWithToken(
    `${pharmacyApiEndPoints.pharmacyReturnBillDetails}/${id}`,
    data
  );
};
const getPharmacyPatientBillNo = async (params: {
  patient_id: number;
  department?: number;
}) => {
  try {
    const apiUrl = setQueryParams(
      params,
      pharmacyApiEndPoints.getPatientBillNo
    );
    return await ApiUtils.getWithToken(apiUrl);
  } catch (error) {
    console.log(
      "Error occurs while getting the get Pharmacy Product Stock By Id:",
      error
    );
    throw error;
  }
};
export {
  getPharmacyPatientBillNo,
  updatePharmacyReturnBillDetails,
  getBillCollection,
  deletePharmacyReturnProduct,
  getPharmacyProductStockById,
  getAllSalesList,
  getLatestPatientOpById,
  getLatestPatientIpById,
  cancelPharmacyBill,
  addSalesReturn,
  updateSalesReturn,
  cancelPharmacyReturnBill,
  getPharmacyReturnBillById,
  getPharmacyReturnProducts,
  getPharmacyBillDraftById,
  getAllPharmacyBillDraft,
  deletePharmacyBill,
  getPharmacyBillById,
  updatePharmacyBill,
  addPharmacyBill,
  getItemsByDepartment,
  getCommonVariables,
  getConsultantsList,
  getExcessAmountByPatientId,
  addReferralDoctor,
  addPayee,
  getBillTermsOptions,
  getReferralDoctorsList,
  getPayeeList,
};
