import * as React from "react";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@mui/material";
import { styleText, muiProps } from "../../../constants/displayText";

type RadioProps = {
  label?: string;
  value?: string | number;
  options: any;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  formFlexDirection?: string;
  isRadioFlexDirectionRow?: boolean;
  radioGroupDirection?: any;
  helperText?: string;
  onBlur?: any;
  isDisabled?: boolean;
};

function RadioButtonsGroup({
  label,
  value,
  options,
  onChange,
  error,
  name,
  formFlexDirection = styleText.ROW,
  isRadioFlexDirectionRow = true,
  radioGroupDirection = "row",
  helperText,
  onBlur,
  isDisabled,
}: RadioProps) {
  const styles = {
    box: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "space-between",
      gap: 0.9,
    },
    formLabel: {
      fontWeight: "400",
      fontSize: "12px",
      color: "greyScale.main",
      display: "flex",
    },
    formControlLabel: {
      color: "textPrimary.main",
      fontSize: "14px",
      fontWeight: "400",
    },
  };
  return (
    <>
      <FormControl
        error={error}
        sx={{ display: "flex", flexDirection: { radioGroupDirection } }}
      >
        <Box
          sx={{
            ...styles.box,
          }}
        >
          {label && (
            <Typography sx={{ ...styles.formLabel }}>{label}</Typography>
          )}
          <RadioGroup
            aria-labelledby={muiProps.RADIO_ARIA_LABELLEDBY}
            name={muiProps.RADIO_NAME}
            value={value}
            onChange={onChange}
            row={isRadioFlexDirectionRow}
          >
            {options.map((option: any, index: number) => (
              <FormControlLabel
                key={index}
                value={typeof option === "object" ? option.id : option}
                control={<Radio name={name} />}
                label={option.value ? option.value : option}
                sx={{ ...styles.formControlLabel }}
                disabled={isDisabled}
              />
            ))}
          </RadioGroup>
        </Box>
        {helperText !== undefined && (
          <FormHelperText
            error={error}
            sx={{
              ml: 2,
              fontSize: "11px",
              minHeight: "20px",
              height: "auto",
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
}

export default RadioButtonsGroup;
