import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { purchaseOrderConst } from "../../../../constants/displayText";
import { Select } from "../../../../components/basic";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type InfoProps = {
  info: any;
  onChange: (event: any) => void;
  isStatusDisabled?: boolean;
};

const Info = ({ info, onChange, isStatusDisabled }: InfoProps) => {
  const { poNo, supplier, date, status } = purchaseOrderConst;
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );

  const infoData = [
    {
      name: poNo,
      value: info.po_no,
    },
    {
      name: supplier,
      value: info.supplier,
    },
    {
      name: date,
      value: info.date,
    },
    {
      name: status,
      value: info.status,
    },
  ];

  return (
    <Box ml={2}>
      <Grid container rowGap={1} columnGap={1}>
        {infoData.map((ele) => (
          <Grid item xl={2.8} alignItems={"center"} display={"flex"}>
            <Grid container alignItems={"center"} display={"flex"}>
              <Grid item xl={4}>
                <Typography>{ele.name}:</Typography>
              </Grid>
              {ele.name === status ? (
                <Grid item xl={8}>
                  <Select
                    label=""
                    name="status"
                    options={commonVariables?.purchase_order_status || []}
                    value={ele.value}
                    formControlStyle={{ width: "100%" }}
                    width=""
                    onChange={onChange}
                    disabled={isStatusDisabled}
                  />
                </Grid>
              ) : (
                <Grid item xl={8}>
                  <Typography color={"#9E9E9E"}>{ele.value}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Info;
