import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

type ipState = {
  vitalsList: Array<any> | null;
  prescriptionsList: Array<any> | null;
  investigationsList: Array<any> | null;
  treatmentsList: Array<any> | null;
  dischargeSummaryList: Array<any> | null;
  roomTransferHistoryList: Array<any> | null;
  operationTheatreList: Array<any> | null;
};

const initialState: ipState = {
  vitalsList: null,
  prescriptionsList: null,
  investigationsList: null,
  treatmentsList: null,
  dischargeSummaryList: null,
  roomTransferHistoryList: null,
  operationTheatreList: null,
};

const inPatientsSlice = createSlice({
  name: "inPatients",
  initialState,
  reducers: {
    setVitalsList: (state, action: PayloadAction<any>) => {
      state.vitalsList = action.payload;
    },
    setPrescriptionsList: (state, action: PayloadAction<any>) => {
      state.prescriptionsList = action.payload;
    },
    setInvestigationsList: (state, action: PayloadAction<any>) => {
      state.investigationsList = action.payload;
    },
    setTreatmentsList: (state, action: PayloadAction<any>) => {
      state.treatmentsList = action.payload;
    },
    setDischargeSummaryList: (state, action: PayloadAction<any>) => {
      state.dischargeSummaryList = action.payload;
    },
    setRoomTransferHistoryList: (state, action: PayloadAction<any>) => {
      state.roomTransferHistoryList = action.payload;
    },
    setOperationTheatreList: (state, action: PayloadAction<any>) => {
      state.operationTheatreList = action.payload;
    },
  },
});

export const {
  setVitalsList,
  setPrescriptionsList,
  setInvestigationsList,
  setTreatmentsList,
  setDischargeSummaryList,
  setRoomTransferHistoryList,
  setOperationTheatreList,
} = inPatientsSlice.actions;

export default inPatientsSlice.reducer;
