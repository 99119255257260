import { Box, Typography, debounce } from "@mui/material";
import CustomFilter from "../../../../components/shared/customFilter";
import { ConfirmationDialog, DataTable } from "../../../../components/shared";
import { useCallback, useEffect, useState } from "react";
import { Sort } from "../../../../components/basic";
import { EditIcon, DeleteIcon, ViewIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import {
  createInvestigationTemplate,
  deleteInvestigationTemplateById,
  getAllInvestigationTemplate,
  updateInvestigationTemplateDetailsById,
} from "../../../../services/appointmentService";
import AddAndEditModal from "./AddAndEditModal";
import { setSnackBarSuccess } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { setCurrentPage } from "../../../../redux/slices/pagination";
type GridRowData = Record<string, unknown>;

const InvestigationTemplateMaster = () => {
  const dispatch = useDispatch();
  const [templateList, setTemplateList] = useState<{
    rows: any | [];
    pageCount: number;
  }>({
    rows: [],
    pageCount: 1,
  });
  const [search, setSearch] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [openModal, setOpenModal] = useState({
    isOpen: false,
    isView: false,
    id: null,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState<{
    isOpen: boolean;
    row: any;
  }>({
    isOpen: false,
    row: null,
  });
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setSearch(value);
    dispatch(setCurrentPage({ key: "investigationTemplateMaster", value: 0 }));
  };

  const getTemplateListData = (data: any) => {
    setIsLoading(true);
    getAllInvestigationTemplate(data)
      .then((res) => {
        if (res.data) {
          setTemplateList({
            rows: res?.data?.rows,
            pageCount: res?.data?.total,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const debouncedGetTemplateList = useCallback(
    debounce((data: any) => {
      getTemplateListData(data);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedGetTemplateList({ search });
  }, [search]);

  useEffect(() => {
    getTemplateListData({ search: "" });
  }, []);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  const handleConfirmDelete = () => {
    if (openDeleteModal.row) {
      setDeleteBtnLoader(true);
      deleteInvestigationTemplateById(openDeleteModal?.row?.id)
        .then(() => {
          setDeleteBtnLoader(false);
          getTemplateListData({
            department: 1,
            limit: 20,
            search: "",
          });
          setOpenDeleteModal({ isOpen: false, row: null });
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: "Template deleted successfully!",
            })
          );
        })
        .catch(() => {
          setDeleteBtnLoader(false);
        });
    }
  };

  const handleSave = (data: any) => {
    setIsSaveLoading(true);
    if (openModal.id) {
      updateInvestigationTemplateDetailsById(openModal.id, data)
        .then(() => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: "Template created successfully",
            })
          );
          setIsSaveLoading(false);
          setOpenModal({ isOpen: false, isView: false, id: null });
        })
        .catch(() => setIsSaveLoading(false));
    } else {
      createInvestigationTemplate(data)
        .then(() => {
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: "Template updated successfully",
            })
          );
          setIsSaveLoading(false);
          setOpenModal({ isOpen: false, isView: false, id: null });
          getTemplateListData({ search: "" });
        })
        .catch(() => setIsSaveLoading(false));
    }
  };

  const TableActions = ({ row }: any) => {
    return (
      <Box display={"flex"}>
        <Box
          sx={{ width: "30px", height: "30px", cursor: "pointer" }}
          onClick={() =>
            setOpenModal((prevState: any) => ({
              ...prevState,
              isOpen: true,
              id: row.id,
            }))
          }
        >
          <EditIcon />
        </Box>
        <Box
          sx={{ width: "30px", height: "30px", cursor: "pointer" }}
          onClick={() =>
            setOpenModal((prevState: any) => ({
              isView: true,
              isOpen: true,
              id: row.id,
            }))
          }
        >
          <ViewIcon />
        </Box>
        <Box
          sx={{ width: "30px", height: "30px", cursor: "pointer" }}
          onClick={() =>
            setOpenDeleteModal({
              isOpen: true,
              row: row,
            })
          }
        >
          <DeleteIcon />
        </Box>
      </Box>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "Name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient’s Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 180,
      sortable: false,
    },
    {
      field: "actions",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "75%",
          }}
        >
          <TableActions row={row} />
        </Box>
      ),
      minWidth: 140,
      sortable: false,
    },
  ];

  return (
    <Box>
      <CustomFilter
        isFilterEnabled={false}
        isSearchEnabled={true}
        addButtonTitle={"+ Add"}
        searchOnChange={handleInputChange}
        searchName={"search"}
        onAddButtonClick={() =>
          setOpenModal((prevState: any) => ({
            ...prevState,
            isOpen: true,
            id: null,
          }))
        }
        clearSearch={() => setSearch("")}
        searchValue={search}
      />
      <DataTable
        loading={isLoading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={templateList?.rows}
        pageCount={templateList?.pageCount}
        currentPage={"investigationTemplateMaster"}
      />
      {openModal.isOpen && (
        <AddAndEditModal
          isOpen={openModal.isOpen}
          id={openModal.id}
          isView={openModal.isView}
          isSaveLoading={isSaveLoading}
          onClose={() =>
            setOpenModal({ isOpen: false, id: null, isView: false })
          }
          onSave={handleSave}
        />
      )}
      {openDeleteModal.isOpen && (
        <ConfirmationDialog
          open={openDeleteModal.isOpen}
          title="are you surely want to delete?"
          handleClick={handleConfirmDelete}
          onClose={() => setOpenDeleteModal({ isOpen: false, row: null })}
          loading={deleteBtnLoader}
        />
      )}
    </Box>
  );
};

export default InvestigationTemplateMaster;
