import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { CancelButton, PageLoader } from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import { billProductsConst } from "../../../../constants/displayText";
import Info from "./Info";
import Summary from "./Summary";
import { useParams } from "react-router-dom";
import { getTaxOptions } from "../../../../services/procurementService";
import moment from "moment";
import { formula } from "../../../../utils/CalculationUtils";
import { RouteUrls } from "../../../../constants/routes";
import { getWholeSalesById } from "../../../../services/gynecologyService";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
const { procurementUrl, wholeSalesUrl } = RouteUrls;
const {
  calculateDiscountAmount,
  calculateAmount,
  calculateRoundedOff,
  calculateSubTaxes,
  calculateTaxAmount,
} = formula.purchaseBill;

const BillProducts = () => {
  const { productName, mrp, amount } = billProductsConst;
  const { id } = useParams();
  const [billProducts, setBillProducts] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: "id",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            ID
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.id}</Typography>
      ),
      sortable: false,
      minWidth: 10,
    },
    {
      field: "product_name",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {productName}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.product_name}</Typography>
      ),
      sortable: false,
      minWidth: 150,
    },
    {
      field: "pack_type",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Pack
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.pack_type}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "batch_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Batch No
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.batch_no}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "expires_at",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Exp.Date
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.expires_at}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            QTY
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.qty}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "free_return_qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Free Qty
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.free_return_qty}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "p_rate",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            PTR
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.p_rate}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },
    {
      field: "mrp",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {mrp}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.mrp}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "rate",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Rate
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.rate}</Typography>
      ),
      sortable: false,
      minWidth: 130,
    },

    {
      field: "discount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Disc %
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row?.discount}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            Tax %
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{`${row.tax_name}-${row.tax_rate}%`}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {amount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.amount}</Typography>
      ),
      sortable: false,
      minWidth: 100,
    },
  ];

  const [info, setInfo] = React.useState<any>({});

  const [billingSummary, setBillingSummary] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getTaxOption = async (product: any) => {
    const res: any = await getTaxOptions();

    const tax = res?.data?.find((ele: any) => ele.id === product.tax_type);

    if (tax) {
      tax.name = `${tax.name}-${tax.tax_rate}%`;
    }

    return tax;
  };

  const getPurchaseBillData = async (id: string | number) => {
    try {
      setIsLoading(true);
      await getWholeSalesById(id)
        .then(async (res: any) => {
          const { bill, bill_products, validation } = res.data;
          const data = await Promise.all(
            bill_products?.map(async (ele: any) => {
              const qty = Number(ele.original_qty) / Number(ele.pack_type) || 0;
              const tax = await getTaxOption(ele);
              const discount = ele.discount || 0;
              const row_discount_type = ele.discount_type || 0;
              const discount_amount = calculateDiscountAmount({
                qty,
                p_rate: Number(ele.p_rate) || 0,
                discount,
                row_discount_type,
              });
              const amount = calculateAmount({
                qty,
                p_rate: Number(ele.p_rate) || 0,
                discount_amount,
              });

              const tax_amount =
                Number(
                  calculateTaxAmount(
                    {
                      tax,
                      qty,
                      p_rate: Number(ele.p_rate) || 0,
                      discount_amount,
                    },
                    ele?.tax_settings
                      ? ele?.tax_settings === 0
                        ? "exclusive"
                        : "inclusive"
                      : "exclusive"
                  )
                ) || 0;
              return {
                ...ele,
                ptr: ele.p_rate,
                id: ele.id,
                qty,
                tax,
                amount,
                free_return_qty: ele.free_return_qty ? ele.free_return_qty : 0,
                pack_type: ele.pack_type,
                batch_no: ele.batch_no,
                p_rate: ele.p_rate,
                mrp: ele.mrp,
                rate: ele.rate,
                discount,
                expiry_date: ele.expires_at
                  ? moment(ele.expires_at, "YYYY-MM-DD").format("DD/MM/YYYY")
                  : null,
                row_discount_type: ele.discount_type,
                product_name: ele.product_name,
                tax_amount,
              };
            })
          );

          setBillingSummary({
            goods_value: Number(bill?.orig_goods_val),
            total_tax: Number(bill?.gst_amt),
            bill_total: Number(bill?.bill_amt) || 0,
            bill_discount: bill?.discount_val,
            bill_discount_type: bill?.discount_type,
            bill_discount_amount: bill?.discount_amt,
            rounded_off: calculateRoundedOff({
              bill_total: Number(bill?.bill_amt) || 0,
              bill_discount_amount: bill?.discount_amt || 0,
            }),
            adjustment: Number(bill?.adj_amt),
            net_amount: Number(bill?.net_total_amt)?.toFixed(2) || 0,
            sub_taxes: calculateSubTaxes(data) || 0,
            validation,
          });
          setInfo({
            id: bill?.id,
            is_cancel: bill?.is_cancel,
            dept_name: bill?.dept_name,
            customer_name: bill?.customer_name,
            invoice_no: bill?.invoice_no,
            invoice_value: bill?.invoice_value,
            date_created: bill?.date_created
              ? formatTwelveHoursTime(bill?.date_created)
              : bill?.date_created,

            phone: bill?.phone,
            address: bill?.address,
          });
          setBillProducts(data);
        })
        .catch((err) => console.log(err));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchaseBillData(id);
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Box>
          <Info info={info} />
          <Box sx={{ m: "20px 0px" }}>
            <DataTable
              columns={columns}
              rows={billProducts}
              tableOnly={true}
              customizedTable={true}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "0px 0px 5px 5px",
                border: 1,
                borderColor: "var(--table-border)",
                borderTop: 0,
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: {
                    xs: "35%",
                  },
                }}
              >
                <Grid
                  sx={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid
                    sx={{
                      width: "60%",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      Discount Value :
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      mr: "1%",
                      textAlign: "left",
                    }}
                  >
                    {/* {Number(billingSummary?.disc_val || 0).toFixed(2)} */}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: {
                    xs: "65%",
                  },
                }}
              >
                <Grid
                  sx={{
                    width: "20%",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                    Goods Value :
                  </Typography>
                </Grid>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "600", mr: "2%" }}
                >
                  {Number(billingSummary?.goods_value || 0).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Summary billingSummary={billingSummary} />
          <CancelButton buttonText="Back" />
        </Box>
      )}
    </>
  );
};

export default BillProducts;
