import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CancelButton,
  SaveButton,
  Select,
  TextField,
} from "../../../../components/basic";
import {
  PlusIcon,
  DeleteIcon,
  ExclamationCircle,
} from "../../../../assets/icons";
import { paymentOptions } from "../../../../constants/dropdownOptions";
import { useNavigate } from "react-router-dom";
import { RouteUrls } from "../../../../constants/routes";
import { getCardOptionDetails } from "../../../../services/billingService";

type CommonComponentProps = {
  label: string;
  children?: React.ReactNode;
  value: string | number | React.ReactNode;
  isDarkLabel?: boolean;
  isLeft?: boolean;
};

type SummaryProps = {
  data: any;
  error?: any;
  onChange: (event: any) => void;
  onSave: () => void;
  billingPrintFormatOptions?: any;
  isEdit?: boolean;
  isSaveLoading?: boolean;
  isSaveAndPrintLoading?: boolean;
};

const styles = {
  titleStyle: {
    fontFamily: "Inter",
    fontSize: "15px",
    fontWeight: 600,
    lineHeight: "18px",
    textAlign: "left",
    letterSpacing: "0em",
    color: "var(--primary-main)",
    marginBottom: "15px",
  },
  boxStyle: {
    borderRadius: "5px",
    padding: "30px",
    border: "1px solid #D1D1D1",
  },
  labelStyle: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "12px",
    textAlign: "left",
    letterSpacing: "0px",
    color: "var(--text-primary)",
  },
  noteStyle: {
    padding: "10px 20px",
    border: "1px solid #FFE58F",
    background: "#FFFBE6",
    borderRadius: "5px",
    margin: "25px",
    display: "flex",
    alignItems: "center",
  },
  noteTextStyle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "24px",
    textAlign: "left",
    letterSpacing: "0em",
  },
};

const CommonComponent = (props: CommonComponentProps) => {
  const { label, children, value, isDarkLabel, isLeft } = props;
  return (
    <Grid item xs={12} md={12} xl={12}>
      <Box width={"100%"} display={"flex"} alignItems={"center"}>
        <Box width={"20%"}>
          <Typography
            sx={{ ...styles.labelStyle, fontWeight: isDarkLabel ? 600 : 400 }}
          >
            {label}
          </Typography>
        </Box>
        <Box width={"5%"}>
          <Typography sx={styles.labelStyle}>{label ? ":" : ""}</Typography>
        </Box>
        {!isLeft && <Box width={"25%"}>{children}</Box>}
        <Box width={isLeft ? "75%" : "50%"}>
          <Typography
            sx={{ ...styles.labelStyle, fontWeight: isDarkLabel ? 600 : 400 }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const Summary = (props: SummaryProps) => {
  const navigate = useNavigate();
  const {
    data,
    error,
    billingPrintFormatOptions,
    isEdit,
    isSaveLoading,
    isSaveAndPrintLoading,
    onChange,
    onSave,
  } = props;
  const [typeOptions, setTypeOptions] = useState<
    | {
        id: string;
        name: string;
        disabled: boolean;
      }[]
    | []
  >([]);

  const calculateConvenienceFee = (value: any, percentage: number) => {
    if (value && percentage) {
      const convenience_fee = (Number(value) * percentage) / 100;
      return convenience_fee?.toFixed(2) || 0;
    }
    return 0;
  };
  const handleChangeType = (event: any, index: number) => {
    const { name, value } = event.target;

    if (data.payments.length > 0) {
      const payments = [...data.payments];

      // add convenience_fee_percentage  and pay term id
      if (name === "type") {
        const option = typeOptions.find((ele) => ele.id === value) as any;

        if (option?.convenience_fee_percentage || option?.pay_term_id) {
          payments[index].convenience_fee_percentage =
            option.convenience_fee_percentage;
          payments[index].pay_term_id = option.pay_term_id;
        } else if (
          payments[index].convenience_fee_percentage ||
          option?.pay_term_id
        ) {
          delete payments[index]?.convenience_fee_percentage;
          delete payments[index]?.pay_term_id;
        }
      }

      // calculate convenience_fee

      if (
        payments[index]?.convenience_fee_percentage &&
        payments[index].pay_term_id &&
        name === "value"
      ) {
        payments[index].convenience_fee = calculateConvenienceFee(
          value,
          payments[index]?.convenience_fee_percentage
        );
      }
      payments[index] = { ...payments[index], [name]: value };
      onChange({ target: { name: "payments", value: payments } });
    }
  };

  const handleAddType = (index: number) => {
    const typeOptionCount = typeOptions?.filter((ele) => !ele.disabled);
    if (
      data.payments.length > 0 &&
      data.payments.length < typeOptionCount?.length
    ) {
      const newTypeOptions = getTypeOptions(index + 1) as any;

      const type = newTypeOptions.find((ele: any) => !ele.disabled);
      const newOption = {
        type: type?.id,
        value: "",
      } as any;

      if (type.convenience_fee_percentage || type.pay_term_id) {
        newOption.convenience_fee_percentage = type?.convenience_fee_percentage;
        newOption.pay_term_id = type?.pay_term_id;
      }

      const payments = [...data.payments, newOption];
      onChange({ target: { name: "payments", value: payments } });
    }
  };

  const handleDeleteType = (index: number) => {
    if (data.payments.length > 0) {
      const payments = [...data.payments];
      payments.splice(index, 1);

      onChange({ target: { name: "payments", value: payments } });
    }
  };

  const getTypeOptions = (currentIndex: number) => {
    const newTypeOptions = [...typeOptions];
    const deleteIds: any = [];
    if (data.payments.length > 0) {
      data.payments.forEach((ele: any) => {
        typeOptions.forEach((option: any, index: number) => {
          if (
            ele.type === option.id &&
            data.payments[currentIndex]?.type !== option.id
          ) {
            deleteIds.push(option.id);
          }
        });
      });

      if (deleteIds.length > 0) {
        const againNewTypeOptions = newTypeOptions.filter(
          (ele) => !deleteIds.includes(ele.id)
        );
        return againNewTypeOptions;
      }

      return newTypeOptions;
    }
  };

  useEffect(() => {
    getCardOptionDetails().then((res: any) => {
      let extraOptions = [];
      if (res.data) {
        extraOptions = res?.data?.map(
          (ele: { id: number | string; name: string | number }) => ({
            ...ele,
            id: ele.name,
            pay_term_id: ele.id,
          })
        );
        // setCardDetails(res.data);
      }
      const newTypeOptions = paymentOptions.map((option) => {
        const newOption = { ...option } as any;

        if (option.id === "Advance" || option.id === "Excess") {
          newOption.disabled = !Boolean(
            data[option.id === "Advance" ? "advance" : "excess"]
          );
        }
        return newOption;
      });
      setTypeOptions([...newTypeOptions, ...extraOptions]);
    });
  }, [data.excess, data.advance]);
  return (
    <Box width={"98.5%"} mx="10px">
      <Box my="25px">
        <Typography sx={styles.titleStyle}>Billing Details</Typography>
        <Box sx={styles.boxStyle}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={12} md={12} xl={6}>
              <CommonComponent
                label="Tax Total"
                value={data.tax_amt || 0.0}
                isLeft
              />
            </Grid>
            <Grid item xs={12} md={12} xl={6}>
              <Grid container rowSpacing={2}>
                <CommonComponent label="Total" value={data.total_amt} />
                <CommonComponent
                  label="Bill Total"
                  value={data.bill_amt}
                  isDarkLabel
                />
                <CommonComponent
                  label="Discount"
                  value={data.discount_amt}
                  children={
                    <Box display={"flex"}>
                      <div
                        style={{
                          width: "40%",
                          minWidth: "75px",
                          height: "32px",
                        }}
                      >
                        <TextField
                          fullWidth
                          formControlStyle={{
                            height: "45px",
                            width: "100%",
                            "& .MuiTextField-root": {
                              boxShadow: "unset !important",
                            },
                          }}
                          value={data.discount_val}
                          name="discount_val"
                          sx={{
                            "& fieldset": {
                              borderRadius: "5px 0 0 5px",
                            },
                            "& .Mui-focused fieldset": {
                              borderRadius: "5px !important",
                            },
                          }}
                          error={!!error?.discount_amt}
                          onChange={onChange}
                        />
                      </div>
                      <div
                        style={{
                          width: "40%",
                          minWidth: "25px",
                        }}
                      >
                        <Select
                          value={data.discount_type}
                          label=""
                          width=""
                          options={[
                            { id: "₹", name: "₹" },
                            { id: "%", name: "%" },
                          ]}
                          sx={{
                            "& fieldset": {
                              borderRadius: "0 5px 5px 0",
                              borderLeftWidth: "0px",
                            },
                            "&.Mui-focused fieldset": {
                              borderRadius: "5px !important",
                              borderLeftWidth: "2px",
                            },
                          }}
                          formControlStyle={{
                            width: "100%",
                            ".MuiOutlinedInput-root": {
                              boxShadow: "unset",
                            },
                          }}
                          name="discount_type"
                          onChange={onChange}
                        />
                      </div>
                    </Box>
                  }
                />
                <CommonComponent label="Rounded Off" value={data.roundOff} />
                <CommonComponent
                  label="Adjustment"
                  value={
                    <TextField
                      fullWidth
                      formControlStyle={{
                        height: "45px",
                        width: "80%",
                      }}
                      value={data.adj_amt}
                      name="adj_amt"
                      onChange={onChange}
                    />
                  }
                />
                <CommonComponent
                  label="Net Amount"
                  value={data.net_total_amt}
                  isDarkLabel
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mb={3}>
        <Typography sx={styles.titleStyle}>Payment Method</Typography>
        <Box sx={styles.boxStyle}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xl={6}>
              <Grid container rowSpacing={2}>
                <CommonComponent
                  label="Excess"
                  isLeft
                  value={
                    <TextField
                      disabled
                      value={data.excess}
                      name="excess"
                      width="180px"
                      sx={{
                        "& .MuiInputBase-root": {
                          background: "#F6F6F6",
                          fontWeight: 600,
                        },
                      }}
                    />
                  }
                />
                <CommonComponent
                  label="Advance"
                  isLeft
                  value={
                    <TextField
                      disabled
                      value={data.advance}
                      name="advance"
                      width="180px"
                      sx={{
                        "& .MuiInputBase-root": {
                          background: "#F6F6F6",
                          fontWeight: 600,
                        },
                      }}
                      error={!!error?.advance}
                    />
                  }
                />
                <CommonComponent
                  label="Over All Due"
                  isLeft
                  value={
                    <TextField
                      value={data.overAllDue}
                      name="overAllDue"
                      disabled
                      width="180px"
                      sx={{
                        "& .MuiInputBase-root": {
                          background: "#F6F6F6",
                          fontWeight: 600,
                        },
                      }}
                    />
                  }
                />
                <CommonComponent
                  label="Credit Limit"
                  isLeft
                  value={
                    <TextField
                      disabled
                      value={data.creditLimit}
                      name="creditLimit"
                      width="180px"
                      sx={{
                        "& .MuiInputBase-root": {
                          background: "#F6F6F6",
                          fontWeight: 600,
                        },
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
            {!isEdit && (
              <Grid item xl={6}>
                <Grid container rowSpacing={2} alignItems={"center"}>
                  {data?.payments?.length > 0 &&
                    data.payments.map((payment: any, index: number) => (
                      <>
                        <CommonComponent
                          label={index === 0 ? "Payment" : ""}
                          value={
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              sx={{
                                "& .MuiGrid-root": {
                                  width: "100%",
                                },
                              }}
                              width={"100%"}
                            >
                              {payment?.convenience_fee_percentage > 0 ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    width:
                                      (index !== 0 &&
                                        index + 1 === data.payments.length) ||
                                      index === 0
                                        ? "90%"
                                        : "83%",
                                  }}
                                >
                                  <Box width={"70%"}>
                                    <TextField
                                      fullWidth
                                      formControlStyle={{
                                        height: "45px",
                                        width: "95%",
                                      }}
                                      sx={{ width: "100%" }}
                                      value={payment.value}
                                      name="value"
                                      onChange={(event: any) =>
                                        handleChangeType(event, index)
                                      }
                                      error={
                                        error?.payments?.length > 0
                                          ? !!error?.payments[0]?.value
                                          : payment?.type === "advance"
                                          ? payment?.value > data?.advance
                                          : payment?.type === "excess"
                                          ? payment?.value > data?.excess
                                          : false
                                      }
                                    />
                                  </Box>
                                  <Box width={"30%"}>
                                    <TextField
                                      fullWidth
                                      formControlStyle={{
                                        height: "45px",
                                        width: "90%",
                                      }}
                                      sx={{
                                        width: "100%",
                                        "& .MuiInputBase-root": {
                                          background: "#F6F6F6",
                                          fontWeight: 600,
                                        },
                                      }}
                                      value={payment?.convenience_fee}
                                      disabled
                                    />
                                  </Box>
                                </Box>
                              ) : (
                                <TextField
                                  fullWidth
                                  formControlStyle={{
                                    height: "45px",
                                    width:
                                      payment?.type === "Bank" ? "80%" : "95%",
                                  }}
                                  sx={{ width: "100%" }}
                                  value={payment.value}
                                  name="value"
                                  onChange={(event: any) =>
                                    handleChangeType(event, index)
                                  }
                                  error={
                                    error?.payments?.length > 0
                                      ? !!error?.payments[0]?.value
                                      : payment.type === "advance"
                                      ? payment.value > data?.advance
                                      : payment.type === "excess"
                                      ? payment.value > data?.excess
                                      : false
                                  }
                                />
                              )}
                              {payment?.type !== "Bank" && (
                                <Box
                                  display={"flex"}
                                  alignItems={"center"}
                                  width={"20%"}
                                >
                                  {((index === 0 &&
                                    data?.payments?.length === 0) ||
                                    index + 1 === data?.payments?.length) && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "50%",
                                      }}
                                    >
                                      <PlusIcon
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                        onClick={() => handleAddType(index)}
                                      />
                                    </Box>
                                  )}
                                  {index !== 0 &&
                                    index + 1 === data?.payments?.length && (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "50%",
                                        }}
                                      >
                                        <DeleteIcon
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleDeleteType(index)
                                          }
                                        />
                                      </Box>
                                    )}
                                </Box>
                              )}
                            </Box>
                          }
                          children={
                            <Select
                              value={payment.type}
                              label=""
                              width=""
                              options={getTypeOptions(index)}
                              disabled={
                                !!payment.type &&
                                data.payments.length > 1 &&
                                data.payments.length !== index + 1
                              }
                              formControlStyle={{ width: "90%" }}
                              name="type"
                              onChange={(event: any) =>
                                handleChangeType(event, index)
                              }
                            />
                          }
                        />
                        {payment.type === "Bank" && (
                          <CommonComponent
                            label=""
                            value={
                              <Box
                                display={"flex"}
                                alignItems={"center"}
                                width={"100%"}
                                sx={{
                                  "& .MuiGrid-root": {
                                    width: "100%",
                                  },
                                }}
                              >
                                <TextField
                                  fullWidth
                                  formControlStyle={{
                                    height: "45px",
                                    width:
                                      (index !== 0 &&
                                        index + 1 === data?.payments?.length) ||
                                      index === 0
                                        ? "90%"
                                        : "83%",
                                  }}
                                  placeholder="URN..."
                                  value={payment.URN}
                                  name="URN"
                                  onChange={(event: any) =>
                                    handleChangeType(event, index)
                                  }
                                />
                                {index !== 0 &&
                                index + 1 === data?.payments?.length ? (
                                  <Box display={"flex"} alignItems={"center"}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <PlusIcon
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                          cursor: "pointer",
                                          marginRight: "10px",
                                        }}
                                        onClick={() => handleAddType(index)}
                                      />
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <DeleteIcon
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => handleDeleteType(index)}
                                      />
                                    </Box>
                                  </Box>
                                ) : index === 0 &&
                                  data.payments.length === 1 ? (
                                  <Box
                                    sx={{
                                      width: "10%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <PlusIcon
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleAddType(index)}
                                    />
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleDeleteType(index)}
                                    />
                                  </Box>
                                )}
                              </Box>
                            }
                          />
                        )}
                      </>
                    ))}
                  {/* <CommonComponent
                  label="Cash Paid"
                  value={
                    <TextField
                      fullWidth
                      formControlStyle={{
                        height: "45px",
                        width: "80%",
                      }}
                      value={data.cashPaid}
                      name="cashPaid"
                      onChange={onChange}
                    />
                  }
                /> */}
                  <CommonComponent
                    label="Amount Paid"
                    value={
                      <TextField
                        fullWidth
                        formControlStyle={{
                          height: "45px",
                          width: "80%",
                        }}
                        value={data.pay_paid_amt}
                        name="pay_paid_amt"
                        onChange={onChange}
                        disabled
                        sx={{
                          "& .MuiInputBase-root": {
                            background: "#F6F6F6",
                            fontWeight: 600,
                          },
                        }}
                      />
                    }
                  />
                  <CommonComponent
                    label="Balance"
                    value={
                      <TextField
                        fullWidth
                        formControlStyle={{
                          height: "45px",
                          width: "80%",
                        }}
                        value={data.balance}
                        name="balance"
                        onChange={onChange}
                        disabled
                        sx={{
                          "& .MuiInputBase-root": {
                            background: "#F6F6F6",
                            fontWeight: 600,
                          },
                        }}
                      />
                    }
                  />
                  <CommonComponent
                    label="Excess Amount"
                    value={
                      <TextField
                        fullWidth
                        formControlStyle={{
                          height: "45px",
                          width: "80%",
                        }}
                        value={data.excess_amount}
                        name="excess_amount"
                        disabled
                        sx={{
                          "& .MuiInputBase-root": {
                            background: "#F6F6F6",
                            fontWeight: 600,
                          },
                        }}
                      />
                    }
                  />
                  <CommonComponent
                    label="Refunded Amount"
                    value={
                      <TextField
                        fullWidth
                        formControlStyle={{
                          height: "45px",
                          width: "80%",
                        }}
                        value={data.refunded_excess}
                        name="refunded_excess"
                        onChange={onChange}
                        error={!!error?.refunded_excess}
                      />
                    }
                  />
                  <CommonComponent
                    label="Net Excess"
                    value={
                      <TextField
                        fullWidth
                        formControlStyle={{
                          height: "45px",
                          width: "80%",
                        }}
                        value={data.net_excess}
                        name="net_excess"
                        onChange={onChange}
                        disabled
                        sx={{
                          "& .MuiInputBase-root": {
                            background: "#F6F6F6",
                            fontWeight: 600,
                          },
                        }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          {!isEdit && (
            <Grid container display={"flex"} alignItems={"flex-end"}>
              <Grid xs={6} md={6} xl={6}>
                <Select
                  value={data.print}
                  label="Select Print Size"
                  width="180px"
                  options={billingPrintFormatOptions}
                  name="print"
                  onChange={onChange}
                  error={!!error.print}
                  helperText={error.print}
                />
              </Grid>
            </Grid>
          )}
          {isEdit && (
            <Box>
              <Box
                sx={{
                  height: "0.5px",
                  width: "100%",
                  backgroundColor: "#D1D1D1",
                }}
                mt={2}
              />
              <Box display={"flex"} justifyContent={"space-around"} mt={2}>
                <Typography sx={styles.labelStyle}>
                  Total Paid : {data.paid_amt || 0.0}
                </Typography>
                <Typography sx={styles.labelStyle}>
                  Balance : {data.balance || 0.0}
                </Typography>
              </Box>
            </Box>
          )}
          <Grid
            container
            width="100%"
            display="flex"
            alignItems={"center"}
            mt={isEdit ? 5 : "unset"}
          >
            <Grid item xs={12} md={12} xl={6}>
              <SaveButton
                buttonText="Save"
                sx={{
                  margin: "10px",
                  height: "42px",
                  width: "auto",
                  padding: "0 20px",
                }}
                loading={isSaveLoading}
                disabled={isSaveAndPrintLoading}
                handleClick={onSave}
              />
              {/* <SaveButton
                buttonText="Save & Print"
                sx={{
                  margin: "10px",
                  height: "42px",
                  width: "auto",
                  padding: "0 20px",
                }}
                loading={isSaveAndPrintLoading}
                disabled={isSaveLoading}
              /> */}
              <CancelButton
                buttonText="Cancel"
                sx={{
                  margin: "10px",
                  height: "42px",
                  width: "auto",
                  padding: "0 20px",
                }}
                isDisabled={isSaveLoading || isSaveAndPrintLoading}
                handleClick={() =>
                  navigate(`${RouteUrls.billingUrl}${RouteUrls.billingListUrl}`)
                }
              />
            </Grid>
            {!isEdit && (
              <Grid item xs={12} md={12} xl={6}>
                <Box sx={styles.noteStyle}>
                  <ExclamationCircle style={{ margin: "0 10px" }} />
                  <Typography sx={styles.noteTextStyle}>
                    Note: if excess amount is not refunded in full, then balance
                    will be added to patient excess.
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Summary;
