import { Box, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  CancelButton,
  DatePickerWithTime,
  PageLoader,
  SaveButton,
  Select,
  TextField,
} from "../../../components/basic";
import { LabReportImg, LaboratoryTestTubeImg } from "../../../assets/images";
import CommonEditableTable from "./CommonEditableDataTable";
import React, { useCallback, useEffect, useState } from "react";
import ChemicalReagentModal from "./ChemicalReagentModal";
import { RouteUrls } from "../../../constants/routes";
import {
  createLabBill,
  getLabReportDetailsByBillId,
  getLabReportDetailsByRecordId,
  getLaboratoryProductOptions,
  updateLabBill,
} from "../../../services/laboratoryService";
import { setSnackBarSuccess } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import moment from "moment";

const { laboratoryUrl, labBillsUrl } = RouteUrls;
const CommonEditableTableMemoized = React.memo(CommonEditableTable);
const styles = {
  reportDetailStyle: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    height: "35px",
  },
  labelStyle: {
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "15.73px",
    textAlign: "left",
    color: "textPrimary.main",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  valueStyle: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "15.73px",
    textAlign: "left",
    color: "textPrimary.main",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  headerStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
    borderBottom: "1px solid var(--table-border)",
    borderRight: "1px solid var(--table-border)",
    padding: "12px 12px 12px 20px",
    marginLeft: "8px",
  },
  textFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "0px 5px",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  selectFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
  tableContentStyle: {
    padding: "12px 12px 12px 20px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "11px",
    textAlign: "left",
    color: "textPrimary.main",
  },
};

const AddLabBill = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [billDetails, setBillDetails] = useState<any>({});
  const [labBills, setLabBills] = useState<any>([]);
  const [isOpenModal, setIsOpenModal] = useState<any>({
    isOpen: false,
    row: null,
    index: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  // const [resultOptions, setResultOptions] = useState([]);

  const handleChangeTestDetails = useCallback((event: any, index: number) => {
    const { name, value } = event.target;

    setLabBills((prevState: any) => {
      const newState = [...prevState];
      newState[index][name] = value;
      return newState;
    });
  }, []);

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      setBillDetails((prevState: any) => {
        const newBillDetails = {
          ...prevState,
          [name]: newValue.validatedValue[0],
        };
        return newBillDetails;
      });
    }
  };

  const columns = {
    headers: [
      {
        header: "S.No",
        textAlignment: "left",
        width: 5,
        name: "s.no",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={{ padding: "12px 12px 12px 20px", textAlign: "center" }}>
            <Typography>
              {index + 1 > 9 ? index + 1 : `0${index + 1}`}
            </Typography>
          </Box>
        ),
      },
      {
        header: "Heading",
        textAlignment: "left",
        width: 15,
        name: "name",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            <Typography>{row.name}</Typography>
          </Box>
        ),
      },
      {
        header: "Test Name",
        textAlignment: "left",
        width: 20,
        name: "test_name",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            <Typography>{row.test_name}</Typography>
          </Box>
        ),
      },
      {
        header: "Results",
        textAlignment: "left",
        width: 10,
        name: "result",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box
            sx={{
              height: "100%",
              "& .MuiGrid-root": {
                height: "100%",
              },
              "& .MuiFormControl-root": {
                height: "100%",
              },
              "& .MuiInputBase-root": {
                height: "100%",
              },
            }}
          >
            <Select
              formControlStyle={{
                width: "100%",
                height: "100%",
                marginLeft: "5px",
                "& .MuiInputBase-formControl": {
                  boxShadow: "unset",
                },
                "& .MuiOutlinedInput-input": {
                  height: "100%",
                  "& .MuiBox-root": {
                    height: "100%",
                  },
                },
              }}
              value={Number(row.result)}
              label=""
              width=""
              options={row.lab_results || []}
              sx={{
                ...styles.selectFieldStyle,
                "&:hover .MuiSelect-icon": {
                  display: "inline-flex",
                },
                "& input": {
                  height: "100%",
                },
              }}
              menuStyle={{
                marginLeft: "-5px",
              }}
              name={"result"}
              onChange={(event) => {
                handleChangeTestDetails(event, index);
              }}
            />
          </Box>
        ),
      },
      {
        header: "Reading Value",
        textAlignment: "left",
        width: 15,
        name: "reading_value",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box
            sx={{
              height: "100%",
              "& .MuiGrid-root": {
                height: "100%",
              },
              "& .MuiFormControl-root": {
                height: "100%",
              },
              "& .MuiInputBase-root": {
                height: "100%",
              },
            }}
          >
            <TextField
              fullWidth
              formControlStyle={{
                width: "100%",
                height: "100%",
                marginLeft: "15px",
              }}
              value={row.reading_value}
              sx={{
                ...styles.textFieldStyle,
                "& input": {
                  textAlign: "left !important",
                  paddingLeft: "20px",
                  height: "100%",
                },
              }}
              name="reading_value"
              onChange={(event: any) => handleChangeTestDetails(event, index)}
            />
          </Box>
        ),
      },
      {
        header: "Units",
        textAlignment: "left",
        width: 10,
        name: "units",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            <Typography>{row.unit}</Typography>
          </Box>
        ),
      },
      {
        header: "Reference Range",
        textAlignment: "left",
        width: 20,
        name: "ref_range",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box sx={styles.tableContentStyle}>
            {(isEdit
              ? row.lab_test_reference_ranges
              : row.reference_ranges
            )?.map((refRange: any, idx: number) => (
              <Typography key={idx}>
                {refRange?.age_group?.age_group_name}: {refRange?.min_value}{" "}
                {refRange?.range_type}
                {refRange?.max_value}
              </Typography>
            ))}
          </Box>
        ),
      },
      {
        header: "Action",
        textAlignment: "left",
        width: 5,
        name: "",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "20px",
                height: "20px",
                cursor: "pointer",
              }}
              onClick={() => setIsOpenModal({ isOpen: true, row, index })}
            >
              <img
                style={{ width: "100%", height: "100%" }}
                src={LaboratoryTestTubeImg}
              />
            </Box>
          </Box>
        ),
      },
    ],
  };

  const handleSave = useCallback((bill: any, reports: any) => {
    if (params.id) {
      setIsSaveLoading(true);
      if (params["*"]?.includes("create")) {
        createLabBill({
          patient_id: bill.patient_id,
          bill_id: bill.id,
          department_id: bill.dept_id,
          sample_date: bill.sample_date
            ? moment(bill.sample_date, "DD/MM/YYYY hh:mm:ss A").format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : null,
          report_date: bill.report_date
            ? moment(bill.report_date, "DD/MM/YYYY hh:mm:ss A").format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : null,
          date_created: bill.date_created,
          report: reports.map((ele: any) => ({
            lab_test_id: ele.id,
            result: ele.result,
            reading_value: ele.reading_value,
            product: ele.product || [],
          })),
        })
          .then((res) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Report created successfully",
              })
            );
            setIsSaveLoading(false);
            navigate(`${laboratoryUrl}${labBillsUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      } else {
        updateLabBill(params.id, {
          patient_id: bill.patient_id,
          bill_id: bill.bill_id,
          department_id: bill.dept_id,
          date_created: bill.date_created,
          sample_date: bill.sample_date
            ? moment(bill.sample_date, "DD/MM/YYYY hh:mm:ss A").format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : null,
          report_date: bill.report_date
            ? moment(bill.report_date, "DD/MM/YYYY hh:mm:ss A").format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : null,
          report: reports,
          product: bill.product,
        })
          .then((res) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: "Report updated successfully",
              })
            );
            setIsSaveLoading(false);
            navigate(`${laboratoryUrl}${labBillsUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      }
    }
  }, []);

  const handleSaveChemicalReagent = (data: any, index: number) => {
    setLabBills((prevState: any) => {
      const newState = [...prevState];
      newState[index].product = data;
      return newState;
    });
    setIsOpenModal({ isOpen: false, row: null, index: null });
  };

  const handleChemicalReagentProductChange = (data: any, index: number) => {
    setLabBills((prevState: any) => {
      const newState = [...prevState];
      newState[index].product = data;
      return newState;
    });
  };

  const getFormattedProducts = async (
    product: any,
    test_id: number | string
  ) => {
    const res: any = await getLaboratoryProductOptions({ search: "", test_id });
    if (res.data) {
      if (product) {
        if (typeof product === "string") {
          const products = product.split(",");
          if (res?.data?.length > 0 && products.length > 0) {
            const newChemicalReagent = products
              ?.map((product: any) => {
                const option = res?.data?.find(
                  (ele: any) => ele.id === Number(product)
                );

                if (option) {
                  return {
                    product_test_id: test_id,
                    product: option.product_id,
                    batch_id: option.batch_id,
                    product_id: option,
                    batch_no: option?.batch_no,
                    qty: option?.stock_qty,
                    is_retest: 0,
                  };
                }
                return null;
              })
              ?.filter((ele: any) => Boolean(ele));

            return newChemicalReagent;
          } else {
            return [
              {
                product_id: null,
                batch_no: "",
                qty: "",
                is_retest: "",
              },
            ];
          }
        } else {
          const products = product?.map((ele: any) => ({
            ...ele,
            sales_id: ele.id,
            product_test_id: ele?.lab_test_id,
            product: ele.product_id,
            batch_id: ele.batch_id,
            product_id:
              typeof ele?.product_id === "number" ||
              typeof ele.product_id === "string"
                ? {
                    product_id: ele.product_id,
                    batch_id: ele.batch_id,
                    batch_no: ele.batch_no,
                    stock_qty: ele.qty,
                    product_name: ele.product_name,
                    id: ele.product_id,
                  }
                : ele.product_id,
          }));

          return products;
        }
      } else {
        return [
          {
            product_id: null,
            batch_no: "",
            qty: "",
            is_retest: "",
          },
        ];
      }
    }
  };

  const getLabReportDetails = async (id: number | string) => {
    try {
      setIsLoading(true);
      const res: any = await getLabReportDetailsByBillId(id);

      if (res.data) {
        const { patient_tests } = res.data;
        setBillDetails({
          ...res.data.bill,
          sample_date:
            patient_tests?.length > 0
              ? patient_tests[0]?.sample_date
              : new Date(),
          report_date:
            patient_tests?.length > 0
              ? patient_tests[0]?.date_created
              : new Date(),
        });
        const formattedLabBills = await Promise.all(
          patient_tests.map(async (ele: any) => ({
            ...ele,
            product: await getFormattedProducts(ele.product, ele?.id),
          }))
        );
        setLabBills(formattedLabBills);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getLabReportEditDetails = async (id: number | string) => {
    try {
      setIsLoading(true);

      const res: any = await getLabReportDetailsByRecordId(id);

      if (res.data) {
        const { patient_tests } = res.data;
        setBillDetails({
          ...res.data.bill,
          date_created: res.data?.bill?.date_created,
          sample_date:
            patient_tests?.length > 0 ? patient_tests[0]?.sample_date : null,
          report_date:
            patient_tests?.length > 0 ? patient_tests[0]?.date_created : null,
        });
        const formattedLabBills = await Promise.all(
          patient_tests.map(async (ele: any) => ({
            ...ele,
            report_id: ele.id,
            product: await getFormattedProducts(ele.product, ele?.lab_test_id),
          }))
        );
        setLabBills(formattedLabBills);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  // const getLabResultOptions = useCallback(() => {
  //   getLabResults()
  //     .then((res) => {
  //       if (res.data) {
  //         setResultOptions(res.data);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  useEffect(() => {
    if (params.id) {
      if (params["*"]?.includes("create")) {
        getLabReportDetails(params.id);
      } else {
        getLabReportEditDetails(params.id);
        setIsEdit(true);
      }
    }
    // getLabResultOptions();
  }, [params.id]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Box>
          <Grid container columnGap={3} rowGap={3} mb={3}>
            <Grid item xs={12} md={5.8} xl={5.8}>
              <Box
                style={{
                  width: "100%",
                  boxShadow: "3px 5px 15px 0px #23232314",
                  borderRadius: "10px",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    padding: "10px 20px",
                    borderBottom: "1px solid #D9D9D9",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      lineHeight: "18.15px",
                      textAlign: "left",
                      color: "primary.main",
                    }}
                  >
                    Report Details
                  </Typography>
                </Box>
                <Grid container p="15px 20px">
                  <Grid item xs={12} sm={6} md={6} xl={6}>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>Name</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.patients_details?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>UHID</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.patients_details?.patient_id}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>Doctor</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.doctor_name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>Mobile</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.patients_details?.mobile}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} xl={6}>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>
                          Bill Date
                        </Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.date_created}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>Bill No</Typography>
                      </Box>
                      <Box width={"60%"}>
                        <Typography sx={styles.valueStyle}>
                          : {billDetails?.bill_no}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>
                          Sample Date
                        </Typography>
                      </Box>
                      <Box width={"60%"}>
                        <DatePickerWithTime
                          style={{
                            height: "20px",
                            borderRadius: "none",
                            width: "90%",
                          }}
                          value={billDetails?.sample_date}
                          format="DD/MM/YYYY hh:mm:ss A"
                          onChange={(e: any, newValue: any) =>
                            handleDateChange(e, newValue, "sample_date")
                          }
                        />
                      </Box>
                    </Box>
                    <Box sx={styles.reportDetailStyle}>
                      <Box width={"40%"}>
                        <Typography sx={styles.labelStyle}>
                          Report Date
                        </Typography>
                      </Box>
                      <Box width={"60%"}>
                        <DatePickerWithTime
                          style={{
                            height: "20px",
                            borderRadius: "none",
                            width: "90%",
                          }}
                          format="DD/MM/YYYY hh:mm:ss A"
                          value={billDetails?.report_date}
                          onChange={(e: any, newValue: any) =>
                            handleDateChange(e, newValue, "report_date")
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={5.8} xl={5.8}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={LabReportImg}
                />
              </Box>
            </Grid>
          </Grid>
          <CommonEditableTableMemoized
            columns={columns}
            rows={labBills}
            tableWidth={"100%"}
            minTableWidth={"100%"}
          />
          <Box mt={5}>
            <SaveButton
              buttonText={"Save"}
              sx={{ width: "100px", height: "42px", mr: "20px" }}
              loading={isSaveLoading}
              handleClick={() => handleSave(billDetails, labBills)}
            />
            <CancelButton
              isDisabled={isSaveLoading}
              buttonText={"Cancel"}
              sx={{ width: "100px", height: "42px" }}
              handleClick={() => navigate(`${laboratoryUrl}${labBillsUrl}`)}
            />
          </Box>
          {isOpenModal.isOpen && (
            <ChemicalReagentModal
              isOpen={isOpenModal.isOpen}
              row={isOpenModal.row}
              isEdit={isEdit}
              index={isOpenModal.index}
              onClose={() =>
                setIsOpenModal({ isOpen: false, row: null, index: null })
              }
              onSave={handleSaveChemicalReagent}
              onProductsChange={handleChemicalReagentProductChange}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default AddLabBill;
