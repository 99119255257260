import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  CancelButton,
  SaveButton,
  DatePickerWithTime,
  AutoComplete,
  AutoCompleteWithCheckBoxes,
} from "../../../../../components/basic";
import { GridColDef } from "@mui/x-data-grid";
import {
  DataTable,
  ConfirmationDialog,
} from "../../../../../components/shared";
import { DeleteIcon } from "../../../../../assets/icons";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../../utils/ValidationUtils";
import {
  getAdmissionIpInvestigationsById,
  createAdmissionIpInvestigationById,
  deleteAdmissionIpInvestigationById,
  getAllRooms,
  getAllBillingItems,
  getConsultantsList,
} from "../../../../../services/admissionService";
import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../../redux/slices/snackbar";
import { ipAdmissionConst } from "../../../../../constants/displayText";
import PermissionUtils from "../../../../../utils/PermissionUtils";
import { useDispatch, useSelector } from "react-redux";
import { setInvestigationsList } from "../../../../../redux/slices/inpatients";
import { RootState } from "../../../../../redux/store";

type InvestigationsSummaryProps = {
  ipAdmissionDetails?: any;
};

const Investigations = ({ ipAdmissionDetails }: InvestigationsSummaryProps) => {
  const styles = useMemo(
    () => ({
      textFieldStyle: {
        width: {
          xs: "200px",
          xl: "275px",
        },
        mr: "20px",
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "5px",
        },
      },
      selectStyle: {
        width: {
          xs: "200px",
          xl: "275px",
        },
        height: "45px",
        boxShadow: "none",
        borderRadius: "5px",
      },
      autocompleteStyle: {
        width: {
          xs: "275px",
        },
        height: "45px",
        "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
          padding: "5px 0px",
          boxShadow: "none",
          borderRadius: "5px",
        },
      },
      autocompleteWithCheckboxStyle: {
        width: {
          xs: "275px",
        },
        height: "45px",
      },
      datePickerWithTimeStyle: {
        width: {
          xs: "200px",
          xl: "275px",
        },
        mr: "20px",
        "& .MuiOutlinedInput-root": {
          width: {
            xs: "200px",
            xl: "275px",
          },
          borderRadius: "5px",
          paddingLeft: "15px",
        },
      },
    }),
    []
  );

  const { ADDED_SUCCESSFULLY, DELETED_SUCCESSFULLY } = ipAdmissionConst;
  const { can } = PermissionUtils();

  const dispatch = useDispatch();
  const { investigationsList } = useSelector(
    (state: RootState) => state.inPatients
  );
  // common variables

  const [admissionId, setAdmissionId] = useState<any>(ipAdmissionDetails?.id);
  const [idToBeDeleted, setIdToBeDeleted] = useState<any>(null);

  // dialog and loader variables

  const [isDataTableLoading, setIsDataTableLoading] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [
    isConfirmationDialogButtonLoading,
    setIsConfirmationDialogButtonLoading,
  ] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isSaveButtonLoading, setIsSaveButtonLoading] =
    useState<boolean>(false);

  //field variables

  const [roomOptions, setRoomOptions] = useState<any>([]);
  const [itemOptions, setItemOptions] = useState<any>([]);
  const [consultantOptions, setConsultantOptions] = useState<any>([]);

  const initialFormError = {
    ward: "",
    consultant: "",
    items: "",
    date: "",
  };

  const [formError, setFormError] = useState<any>(initialFormError);

  const initialFormData = {
    ward: ipAdmissionDetails?.bed,
    wardInput: `${ipAdmissionDetails?.floor_name} / ${ipAdmissionDetails?.room_no}`,
    consultant: null,
    consultantInput: "",
    items: [],
    itemsInput: "",
    date: null,
  };

  const [formData, setFormData] = useState<any>(initialFormData);

  const fieldData: any = {
    ward: {
      label: "Ward",
      name: "ward",
      placeholder: "Select Ward",
      value: formData.ward,
      inputValue: formData?.wardInput,
      isError: formError.ward === "" ? false : true,
      helperText: formError.ward,
    },
    consultant: {
      label: "Doctor",
      name: "consultant",
      placeholder: "Select Doctor",
      value: formData.consultant,
      inputValue: formData?.consultantInput,
      isError: formError.consultant === "" ? false : true,
      helperText: formError.consultant,
    },
    items: {
      label: "Items",
      name: "items",
      placeholder: "Select Items",
      value: formData.items,
      inputValue: formData?.itemsInput,
      isError: formError.items === "" ? false : true,
      helperText: formError.items,
    },
    date: {
      label: "Date",
      name: "date",
      placeholder: "Choose Date",
      value: formData.date,
      isError: formError.date === "" ? false : true,
      helperText: formError.date,
    },
  };

  const [formFieldData, setFormFieldData] = useState(fieldData);

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "ward":
      case "consultant":
      case "date": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      case "items": {
        const newValue = value
          .flatMap((item: any) =>
            item.lab_tests.map((test: any) => test.test_name)
          )
          .join(", ");

        if (requiredValidator(newValue, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(newValue, label),
            setFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setFormError);
        }
        break;
      }
      default:
        break;
    }
  };

  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };

  const updateFormFieldData = () => {
    setFormFieldData((prev: any) => {
      return Object.keys(prev).map((field: any) => {
        return {
          ...field,
          value: formData[field.name],
          helperText: formError[field.name],
          isError: formError[field.name] === "" ? false : true,
        };
      });
    });
  };

  useEffect(() => {
    updateFormFieldData();
  }, [formError, formData]);

  const TableActions = ({ row }: any) => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <IconButton
          onClick={() => {
            setIsConfirmationDialogOpen(true);
            setIdToBeDeleted(row?.id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "sNo",
      flex: 0.4,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          S.No
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.sNo}
        </Typography>
      ),
      minWidth: 40,
      sortable: false,
    },
    {
      field: "doctor",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Doctor
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.doctor_name}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "itemsTests",
      flex: 1,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Items/Tests
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.items}
        </Typography>
      ),
      minWidth: 200,
      sortable: false,
    },
    {
      field: "ward",
      flex: 2,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Ward
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {`${row?.floor_name} /${row?.room_no}`}
        </Typography>
      ),
      minWidth: 300,
      sortable: false,
    },
    {
      field: "date",
      flex: 0.8,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Date
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.date_created}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    {
      field: "generatedBy",
      flex: 0.8,
      renderHeader: () => (
        <Typography variant="h5" fontSize={14}>
          Generated By
        </Typography>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.created_user}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },
    ...(can("ip_investigations_delete")
      ? [
          {
            field: "actions",
            flex: 0.5,
            renderHeader: () => (
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  height: "51px",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </Grid>
            ),
            renderCell: ({ row }: any) => {
              return <TableActions row={row} />;
            },
            minWidth: 50,
            sortable: false,
          },
        ]
      : []),
  ];

  // field onchange functions

  const handleAutoCompleteChange = (e: any, newValue: any, name: string) => {
    if (newValue === null) {
      return false;
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: newValue,
    }));

    handleValidation({
      target: {
        name: name,
        value: newValue,
      },
    });
  };

  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: any,
    name: string
  ) => {
    if (
      newInputValue === "" &&
      name === "wardInput" &&
      formData.ward !== null
    ) {
      return false;
    }

    setFormData((prev: any) => ({
      ...prev,
      [name]: newInputValue,
    }));
  };

  const handleDateChange = (newValue: any, name: string) => {
    setFormData((prev: any) => ({
      ...prev,
      [name]: newValue.toString(),
    }));

    handleValidation({
      target: {
        name: name,
        value: newValue.toString(),
      },
    });
  };

  const resetFormData = () => {
    setFormData(initialFormData);
  };

  // api call functions to get select options

  const getAllRoomsList = async () => {
    try {
      await getAllRooms().then((result: any) => {
        let data = result?.data?.result;

        const modifiedData = data?.map((item: any) => {
          return {
            id: item?.id,
            name: `${item?.floor_name} / ${item?.room_no}`,
          };
        });

        setRoomOptions(modifiedData);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllBillingItemsList = async () => {
    try {
      await getAllBillingItems().then((result: any) => {
        let data = result?.data;

        setItemOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const getAllConsultantsList = async () => {
    try {
      await getConsultantsList().then((result: any) => {
        let data = result?.data;

        setConsultantOptions(data);
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // crud operation api calls functions

  const getAdmissionIpInvestigations = async () => {
    if (!admissionId) {
      return false;
    }
    try {
      setIsPageLoading(true);
      setIsDataTableLoading(true);
      await getAdmissionIpInvestigationsById(admissionId).then(
        (result: any) => {
          let data = result?.data;

          if (data) {
            const modifiedData = data?.map((item: any, index: number) => ({
              ...item,
              sNo: index + 1,
            }));

            dispatch(setInvestigationsList(modifiedData));
          }
          setIsPageLoading(false);
          setIsDataTableLoading(false);
        }
      );
    } catch (error) {
      setIsPageLoading(false);
      setIsDataTableLoading(false);
      console.error("An error occurred:", error);
    }
  };

  const createAdmissionIpInvestigation = async () => {
    if (
      formData.ward === null ||
      formData.consultant === null ||
      formData.items.length < 1 ||
      formData.date === null
    ) {
      validateForm();
    } else {
      try {
        setIsSaveButtonLoading(true);

        const items = formData?.items?.map((item: any) => {
          return item.id;
        });

        const data = {
          ip_admission_id: admissionId,
          consultant_id: formData.consultant.id,
          ward_id: formData.ward.id ? formData.ward.id : formData.ward,
          date_created: formData.date,
          items: { ...items },
        };

        await createAdmissionIpInvestigationById(data).then((result: any) => {
          setFormData(initialFormData);
          setIsSaveButtonLoading(false);
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ADDED_SUCCESSFULLY,
            })
          );
          getAdmissionIpInvestigations();
        });
      } catch (error: any) {
        setIsSaveButtonLoading(false);
        console.error("An error occurred:", error);
        dispatch(
          setSnackBarFailed({
            snackBarMessage: error?.response?.data?.message,
          })
        );
      }
    }
  };

  const handleDelete = async (id: any) => {
    try {
      setIsConfirmationDialogButtonLoading(true);
      await deleteAdmissionIpInvestigationById(id).then((result: any) => {
        getAdmissionIpInvestigations();

        dispatch(
          setSnackBarSuccess({
            snackBarMessage: DELETED_SUCCESSFULLY,
          })
        );

        setIsConfirmationDialogOpen(false);
        setIsConfirmationDialogButtonLoading(false);
        setIdToBeDeleted(null);
      });
    } catch (error) {
      setIsConfirmationDialogOpen(false);
      setIsConfirmationDialogButtonLoading(false);
      setIdToBeDeleted(null);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: "something went wrong",
        })
      );
      console.error("An error occurred:", error);
    }
  };

  // dialog functions

  const onConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (idToBeDeleted === null) {
      return false;
    } else {
      handleDelete(idToBeDeleted);
    }
  };

  useEffect(() => {
    getAllRoomsList();
    getAllBillingItemsList();
    getAllConsultantsList();
  }, []);

  useEffect(() => {
    if (!investigationsList) {
      getAdmissionIpInvestigations();
    }
  }, [admissionId]);

  return isPageLoading ? (
    <Grid
      sx={{
        width: "95vw",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "40vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onConfirmationDialogClose}
        loading={isConfirmationDialogButtonLoading}
      />
      {can("ip_investigations_add") ? (
        <>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Grid item xs={12}>
              <Typography variant="h2">Add Investigation</Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: "flex",
                flexWrap: "wrap",
                m: "20px 0px",
                gap: 2,
              }}
            >
              <AutoComplete
                label={fieldData.ward.label}
                value={fieldData.ward.value}
                inputValue={fieldData.ward.inputValue}
                placeholder={fieldData.ward.placeholder}
                name={fieldData.ward.name}
                disabled={can("ip_investigations_ward_change") ? false : true}
                onChange={(e: any, newValue: any) =>
                  handleAutoCompleteChange(e, newValue, fieldData.ward.name)
                }
                onInputChange={(e: any, newValue: any) =>
                  handleAutoCompleteInputChange(e, newValue, "wardInput")
                }
                options={roomOptions}
                error={fieldData.ward.isError}
                helperText={fieldData.ward.helperText}
                width="275px"
                sx={{ ...styles.autocompleteStyle }}
              />
              <AutoComplete
                label={fieldData.consultant.label}
                value={fieldData.consultant.value}
                inputValue={fieldData.consultant.inputValue}
                placeholder={fieldData.consultant.placeholder}
                name={fieldData.consultant.name}
                onChange={(e: any, newValue: any) =>
                  handleAutoCompleteChange(
                    e,
                    newValue,
                    fieldData.consultant.name
                  )
                }
                onInputChange={(e: any, newValue: any) =>
                  handleAutoCompleteInputChange(e, newValue, "consultantInput")
                }
                options={consultantOptions}
                error={fieldData.consultant.isError}
                helperText={fieldData.consultant.helperText}
                width="275px"
                sx={{ ...styles.autocompleteStyle }}
              />
              <AutoCompleteWithCheckBoxes
                label={fieldData.items.label}
                value={fieldData.items.value}
                placeholder={fieldData.items.placeholder}
                name={fieldData.items.name}
                onChange={(e: any, newValue: any) =>
                  handleAutoCompleteChange(e, newValue, fieldData.items.name)
                }
                options={itemOptions}
                error={fieldData.items.isError}
                helperText={fieldData.items.helperText}
                sx={{ ...styles.autocompleteWithCheckboxStyle }}
              />
              <DatePickerWithTime
                label={fieldData.date.label}
                value={fieldData.date.value}
                placeholder={fieldData.date.placeholder}
                name={fieldData.date.name}
                onChange={(newValue: any) => handleDateChange(newValue, "date")}
                error={fieldData.date.isError}
                helperText={fieldData.date.helperText}
                style={{
                  width: "275px",
                  height: "36px",
                  boxShadow: "none",
                  borderRadius: "5px",
                }}
              />
            </Grid>
            <Grid item sx={{ display: "flex", gap: "2", mt: 2 }}>
              <SaveButton
                loading={isSaveButtonLoading}
                buttonText={"Add"}
                handleClick={createAdmissionIpInvestigation}
                sx={{ width: "88px", height: "38px" }}
              />
              <CancelButton
                buttonText={"Reset"}
                handleClick={resetFormData}
                sx={{ width: "88px", height: "38px", ml: 3 }}
              />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3, color: "#D9D9D9" }} />
        </>
      ) : (
        <></>
      )}
      <Grid item sx={{ m: "20px 20px 50px 0px" }}>
        <Typography variant="h2" sx={{ m: "20px 0px" }}>
          Added Investigation
        </Typography>

        <Box>
          <DataTable
            loading={isDataTableLoading}
            columns={columns}
            rows={investigationsList || []}
            getRowId={(row: any) => `${String(row.sNo)}`}
            tableOnly={true}
            customizedTable={true}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Investigations;
