import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { TextField, Select } from "../../../../components/basic";
import { salesBillSummaryConst } from "../../../../constants/displayText";

type SummaryType = {
  total_discount_amount: number | null;
  goods_value: number | null;
  tax_amount: number | null;
  bill_total: number | null;
  discount: number | null;
  bill_discount_type: number | null;
  bill_discount_amount: number | null;
  rounded_off: number | null;
  adjustment: number | null;
  net_amount: number | null;
  excess: number | null;
  advance: number | null;
  over_all_due: number | null;
  credit_limit: number | null;
  payment_method: number | null;
  amount_paid: number | null;
  balance: number | null;
  excess_amount: number | null;
  refunded_amount: number | null;
  amount: number | null;
  net_excess: number | null;
};

type SummaryProps = {
  summary: any;
  handleSaveData?: () => void;
  setSummary: React.Dispatch<React.SetStateAction<any>>;
  handleInputChange: (e: any) => void;
  summaryError?: any;
};

const Summary = React.memo(
  ({
    summary,
    setSummary,
    handleSaveData,
    handleInputChange,
    summaryError,
  }: SummaryProps) => {
    const { billTotal, discount, roundedOff, netAmount } =
      salesBillSummaryConst;

    const summaryOne = [
      {
        key: "",
        value: "",
      },
    ];
    const summaryTwo = [
      {
        key: billTotal,
        name: "bill_total",
        value: summary.bill_total
          ? Number(summary.bill_total || 0).toFixed(2)
          : 0,
        isHighlight: true,
      },
      {
        key: discount,
        name: "discount",
        value: summary.discount,
        isSelectField: true,
      },
      {
        key: roundedOff,
        name: "rounded_off",
        value: summary.rounded_off,
      },
      {
        key: netAmount,
        name: "net_amount",
        value: summary.net_amount,
        isHighlight: true,
      },
      {
        key: "Refunded Amount",
        name: "refunded_amount",
        value: summary.refunded_amount,
        isInputField: true,
      },
    ];

    const styles = {
      textFieldStyle: {
        height: "35px",
        fontSize: "6px",
        "& .MuiOutlinedInput-root": {
          height: "35px",
          paddingLeft: "10px",
          textAlign: "center",
          borderRadius: "5px",
          boxShadow: "none",
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      selectStyle: {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    return (
      <Grid container>
        <Grid
          container
          sx={{
            width: { xs: "90vw", sm: "80vw", md: "90vw" },
            display: "flex",
            flexDirection: "column",
            m: "20px 0",
          }}
        >
          <Typography
            variant="h3"
            color="initial"
            sx={{
              fontWeight: "600",
              color: "primary.main",
              m: "39px 0 15px 0",
            }}
          >
            Billing Details
          </Typography>
          <Grid
            container
            sx={{
              width: { xs: "90vw", sm: "80vw", md: "90vw" },
              borderRadius: "5px",
              border: "1px solid #D1D1D1",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "center", md: "flex-start" },
                justifyContent: { xs: "space-around" },
              }}
            >
              <Grid
                item
                sx={{
                  width: {
                    xs: "70%",
                    sm: "70%",
                    md: "45%",
                    lg: "35%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  m: "51px 0px 45px 0px",
                }}
              >
                {summaryOne.map((row, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        width: {
                          xs: "100%",
                          lg: "100%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Grid
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="h4">{row.key}</Typography>
                      </Grid>
                      <Typography>{row.value}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
              <Grid
                item
                sx={{
                  width: {
                    xs: "70%",
                    sm: "70%",
                    md: "45%",
                    lg: "35%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  m: "51px 0px 45px 0px",
                }}
              >
                {summaryTwo.map((row, index) => {
                  return (
                    <Grid
                      key={index}
                      sx={{
                        width: {
                          xs: "100%",
                          lg: "100%",
                        },
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        maxHeight: "35px",
                        mb: "23px",
                        color: "textPrimary.main",
                      }}
                      className="row"
                    >
                      <Grid
                        sx={{
                          width: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: row?.isHighlight ? "primary.main" : null,
                          }}
                        >
                          {row.key}
                        </Typography>
                        <Typography variant="h4">:</Typography>
                      </Grid>
                      {row?.isSelectField && (
                        <>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100px",
                              height: "35px",
                            }}
                          >
                            <TextField
                              name="bill_discount_amount"
                              onChange={(e) => handleInputChange(e)}
                              value={summary.bill_discount_amount}
                              height="35px"
                              sx={{
                                ...styles.textFieldStyle,
                                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                  {
                                    borderColor: "var(--primary-border-color)",
                                    borderRadius: "5px 0px 0px 5px",
                                  },
                                width: "60px",
                              }}
                              // error={summaryError.refunded_amount}
                              error={summaryError.discount}
                            />
                            <Grid
                              sx={{
                                height: "35px",
                                "& .MuiInputBase-root": {
                                  padding: "0px",
                                },
                              }}
                            >
                              <Select
                                label=""
                                options={[
                                  { id: "%", name: "%" },
                                  { id: "₹", name: "₹" },
                                ]}
                                placeholder="%"
                                name="bill_discount_type"
                                onChange={(e) => handleInputChange(e)}
                                value={summary.bill_discount_type}
                                width="40px"
                                sx={{
                                  height: "35px",
                                  borderRadius: "0px 5px 5px 0px",
                                  borderColor: "var(--primary-border-color)",
                                  boxShadow: "none",
                                  ...styles.selectStyle,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {row?.isInputField ? (
                        <TextField
                          name="refunded_amount"
                          value={summary.refunded_amount}
                          onChange={(e) => handleInputChange(e)}
                          sx={{
                            ...styles.textFieldStyle,
                            width: "inherit",
                          }}
                          error={summaryError.refunded_amount}
                        />
                      ) : (
                        <Typography sx={{ width: "20%", textAlign: "right" }}>
                          {row.value}
                        </Typography>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

export default Summary;
