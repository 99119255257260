import React from "react";
import { Button, ButtonProps } from "@mui/material";

interface CustomButtonProps extends ButtonProps {
  backgroundColor?: string;
  buttonText?: string;
  textColor?: string;
  fontSize?: string;
  fontWeight?: string | number;
  textTransform?: string | any;
  border?: string | any;
  handleClick?: () => void | any;
  sx?: {};
}

const CustomButton: React.FC<CustomButtonProps> = ({
  backgroundColor,
  buttonText,
  textColor,
  fontSize,
  fontWeight,
  textTransform,
  border,
  sx,
  ...rest
}) => {
  return (
    <Button
      {...rest}
      style={{
        backgroundColor: backgroundColor || "transparent",
        border: border || "none",
        color: textColor || "inherit",
        fontWeight: fontWeight,
        fontSize: fontSize,
        textTransform: textTransform,
        ...sx,
      }}
    >
      {buttonText}
    </Button>
  );
};

export default CustomButton;
