import React from "react";
import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../../assets/icons";
import { Select, TextField } from "../../../../components/basic";

import { goodsIssueInfoConst } from "../../../../constants/displayText";

type InfoProps = {
  info: {
    issuer: string;
    receiver: string;
    bill_no: number;
    barcode: string | number | null;
    disabled: boolean;
  };
  setInfo: React.Dispatch<any>;
  receiverList: [] | any;
  issuerList: [] | any;
  handleBarcodeOnChange?: any;
  setIsSequenceWrapperOpen:React.Dispatch<React.SetStateAction<boolean>>;
  setIsManual:React.Dispatch<React.SetStateAction<any>>;
  isManualAdded:any;
  isManual:any;
  errors:any
  handleConfirmSequenceNoChange:()=>void;
  customSequence?:boolean;

};

const Info = React.memo(
  ({
    info,
    setInfo,
    receiverList,
    issuerList,
    handleBarcodeOnChange,
    setIsSequenceWrapperOpen,
    setIsManual,
    isManualAdded,
    isManual,
    errors,
    handleConfirmSequenceNoChange,
    customSequence
  }: InfoProps) => {
    const { issuer, receiver, invoiceNo, barcode } = goodsIssueInfoConst;

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      if (name === "barcode") {
        handleBarcodeOnChange(value);
      }
      setInfo((prev: any) => ({
        ...prev,
        [name]: value,
      }));
    };

    const styles = {
      selectGrid: {
        width: "288px",
        height: "45px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      textFieldStyle: {
        width: "288px",

        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 4,
        }}
      >
        <Select
          value={info.issuer}
          onChange={(e) => handleInputChange(e)}
          placeholder="Select issuer"
          options={issuerList}
          label={issuer}
          name="issuer"
          width={"288px"}
          sx={{
            ...styles.selectGrid,
          }}
          disabled={info.disabled}
        />

        <Select
          value={info.receiver}
          onChange={handleInputChange}
          placeholder="Select Receiver"
          options={receiverList}
          label={receiver}
          name="receiver"
          width={"288px"}
          sx={{
            ...styles.selectGrid,
          }}
          disabled={info.disabled}
        />

        {customSequence ? (<TextField
          value={info.bill_no}
          label={invoiceNo}
          onChange={handleInputChange}
          helperText={errors?.bill_no}
          onBlur={handleConfirmSequenceNoChange}
          endPlaceholderIcon={<SettingsIcon
            style={{ cursor: "pointer" }} 
            onClick={() => {
              setIsSequenceWrapperOpen(true);
            }}
          />}
          name="bill_no"
          sx={{
            ...styles.textFieldStyle,
          }}
        />) : null}
        <TextField
          value={info.barcode}
          label={barcode}
          onChange={handleInputChange}
          name="barcode"
          sx={{
            ...styles.textFieldStyle,
          }}
        />
      </Grid>
    );
  }
);

export default Info;
