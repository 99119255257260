import axios from "axios";
import { appointmentEndPoints } from "../constants/apiEndPoints";
import QueryParamsBuilder from "../helper/QueryParamsBuilder";
import ApiUtils from "../utils/ApiUtils";
import moment from "moment";
import { getCookie } from "../utils/GeneralUtils";
export type AppointmentTypes = {
  id: number;
  appoint_date: string;
  doctor_name: string;
  patient_id: string;
  op_no: string;
  patient_name: string;
  patient_sys_id: 1;
  patient_mobile: string;
  purpose: string;
  slot_label: string;
  status: 1;
  token_no: string;
  pres_no: null;
};

const {
  createAppointment,
  getAllDoctorsName,
  getAllConsultationsName,
  getAllPatientsName,
  getSlots,
  getDoctorsAvailableDays,
  checkAppointmentStatus,
  getTokens,
  cancelAppointment,
  appointmentDetails,
  rescheduleAppointment,
  trackingStatus,
  reviewAppointment,
  pxProductsList,
  productsList,
  prescriptionDetail,
  symptomsList,
  prescriptionProductDelete,
  prescriptionProductUpdate,
  prescriptionCreate,
  prescriptionDelete,
  investigationList,
  testList,
  investigationTestCreate,
  investigationTestUpdate,
  investigationDelete,
  investigationTestDelete,
  invoiceNumber,
  getAllVitals,
  getDoctorVitalsDetails,
  createVitals,
  getVitalsByAppointment,
  getMedicalRecordsByAppointment,
  createMedicalRecords,
  deleteMedicalRecords,
  viewMedicalRecords,
  updateMedicalRecords,
  getMedicalReportsByAppointment,
  labTestUrl,
  getAllReferDoctorsName,
  sequenceUpdateUrl,
  getAllUnitsList,
  getPatientsLimit,
  getPrintNotesOpTypes,
  deleteAppointmentById,
} = appointmentEndPoints;

let templateList = [
  {
    id: 1,
    name: "Pregnancy Test",
    labTestes: [],
  },
  {
    id: 2,
    name: "Coomb’s Test",
    labTestes: [],
  },
  {
    id: 3,
    name: "Blood Test",
    labTestes: [],
  },
  {
    id: 4,
    name: "Malaria Test",
    labTestes: [],
  },
  {
    id: 5,
    name: "Covid",
    labTestes: [],
  },
  {
    id: 6,
    name: "Viral Fever",
    labTestes: [],
  },
  {
    id: 7,
    name: "Covid - 19",
    labTestes: [],
  },
];
const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);

  let queryString = "?";
  keys.map((key) => {
    if (data[key] && !Array.isArray(data[key])) {
      queryString += `${key}=${data[key]}&`;
    }
    if (Array.isArray(data[key])) {
      data[key].map(
        (ele: any, index: number) => (queryString += `${key}[${index}]=${ele}&`)
      );
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};

const getAllAppointment = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  doctorId,
  purposeId,
  patientId,
  fromDate,
  toDate,
  opSeqId,
  search,
}: any): Promise<{
  data: { result: AppointmentTypes[]; total: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: appointmentEndPoints.getAllAppointments,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    fromDate: fromDate
      ? moment(fromDate, "DD-MM-YYYY").format("YYYY-MM-DD")
      : null,
    toDate: toDate ? moment(toDate, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
    opSeqId,
    doctorId,
    patientId,
    purposeId: purposeId,
    searchValue: search,
  });
  return (await ApiUtils.getWithToken(apiUrl)) as {
    data: { result: AppointmentTypes[]; total: number | null };
  };
};

const appointmentInfo = async (appointmentId: string | number) => {
  return await ApiUtils.getWithToken(`${appointmentDetails}/${appointmentId}`);
};

const doctorDetails = async () => {
  return await ApiUtils.getWithToken(getAllDoctorsName);
};

const getReferDoctorDetails = async () => {
  return await ApiUtils.getWithToken(getAllReferDoctorsName);
};
const consultationDetails = async () => {
  return await ApiUtils.getWithToken(getAllConsultationsName);
};

const PatientsDetails = async () => {
  return await ApiUtils.getWithToken(getAllPatientsName);
};

const doctorsAvailableDaysDetails = async (doctor_id: number | string) => {
  return await ApiUtils.getWithToken(`${getDoctorsAvailableDays}/${doctor_id}`);
};
const createAppointmentDetails = async (bookingFormData: {} | any) => {
  return await ApiUtils.postWithToken(createAppointment, bookingFormData);
};
const cancelAppointmentDetails = async (data: {} | any) => {
  return await ApiUtils.postWithToken(cancelAppointment, data);
};
const rescheduleAppointmentDetails = async (bookingFormData: {} | any) => {
  return await ApiUtils.postWithToken(rescheduleAppointment, bookingFormData);
};

const slotsDetails = async (slotsPostData: {} | any) => {
  return await ApiUtils.postWithToken(getSlots, slotsPostData);
};

const getAllPatientsList = async (params: {
  page: number | string | null;
  per_page: number;
  search?: string;
}) => {
  const apiUrl = setQueryParams(
    params,
    appointmentEndPoints.getAllPatientsName
  );
  return await ApiUtils.getWithToken(apiUrl);
};
const getAllDoctorsListTest = async () => {
  return await ApiUtils.getWithToken(appointmentEndPoints.getAllDoctorsName);
};

const getAllDoctorsList = async (params: {
  page: number | string | null;
  per_page: number;
  search?: string;
}) => {
  const apiUrl = setQueryParams(params, appointmentEndPoints.getAllDoctorsName);

  return await ApiUtils.getWithToken(appointmentEndPoints.getAllDoctorsName);
};
const getAllConsultationsList = async () => {
  return await ApiUtils.getWithToken(
    `${appointmentEndPoints.getAllConsultationsName}`
  );
};
const AppointmentStatusDetails = async (
  doctor_id: string | number,
  patient_id: string | number,
  appoint_date: string | number
) => {
  return await ApiUtils.getWithToken(
    `${checkAppointmentStatus}?patient_id=${patient_id}&doctor_id=${doctor_id}&appoint_date=${appoint_date}`
  );
};
const setEndPointUrl = (url: string, list?: any) => {
  let formattedUrl: string = url;
  for (const [key, value] of Object.entries(list)) {
    if (value) {
      formattedUrl += `/${value}`;
    }
  }
  return formattedUrl;
};
const tokenDetails = async (
  doctor_id: number | string,
  appoint_date: string | number
) => {
  const apiUrl = setEndPointUrl(getTokens, {
    doctor_id: doctor_id,
    appoint_date: appoint_date,
  });
  return await ApiUtils.getWithToken(apiUrl);
};

const trackingStatusDetails = async (type: {} | any) => {
  return await ApiUtils.postWithToken(trackingStatus, type);
};
const reviewAppointmentDetails = async (bookingFormData: {} | any) => {
  return await ApiUtils.postWithToken(reviewAppointment, bookingFormData);
};
const getSymptomsList = async () => {
  return await ApiUtils.getWithToken(symptomsList);
};

const getProductsList = async (search: any) => {
  const apiUrl = setQueryParams(search, productsList);

  return await ApiUtils.getWithToken(apiUrl);
};

const getPxProductsList = async (search: any) => {
  const apiUrl = setQueryParams(search, pxProductsList);

  return await ApiUtils.getWithToken(apiUrl);
};

const prescriptionInfo = async (appointmentId: string | number) => {
  return await ApiUtils.getWithToken(`${prescriptionDetail}/${appointmentId}`);
};

const createPrescriptionProduct = async (body: any) => {
  return await ApiUtils.postWithToken(`${prescriptionCreate}`, body);
};

const deletePrescriptionProduct = async (id: number) => {
  return await ApiUtils.deleteWithToken(`${prescriptionProductDelete}/${id}`);
};

const deletePrescription = async (id: number) => {
  return await ApiUtils.deleteWithToken(`${prescriptionDelete}/${id}`);
};

const updatePrescriptionProduct = async (id: number, body: any) => {
  return await ApiUtils.putWithToken(
    `${prescriptionProductUpdate}/${id}`,
    body
  );
};

const getInvestigationList = async (appointmentId: string | number) => {
  return await ApiUtils.getWithToken(
    `${investigationList}?appointment_id=${appointmentId}`
  );
};

const getTestList = async () => {
  return await ApiUtils.getWithToken(testList);
};

const createInvestigationTest = async (data: any) => {
  return await ApiUtils.postWithToken(investigationTestCreate, data);
};

const updateInvestigationTest = async (id: number, data: any) => {
  return await ApiUtils.putWithToken(`${investigationTestUpdate}/${id}`, data);
};

const deleteInvestigation = async (id: number) => {
  return await ApiUtils.deleteWithToken(`${investigationDelete}/${id}`);
};

const deleteInvestigationTest = async (id: number) => {
  return await ApiUtils.deleteWithToken(`${investigationTestDelete}/${id}`);
};

const getInvoiceNumber = async (departmentName: string) => {
  return await ApiUtils.getWithToken(`${invoiceNumber}/${departmentName}`);
};

const vitalsDetails = async () => {
  return await ApiUtils.getWithToken(getAllVitals);
};
const doctorVitalsDetails = async (doctor_id: any) => {
  return await ApiUtils.getWithToken(`${getDoctorVitalsDetails}${doctor_id}`);
};
const createVitalsDetails = async (vitalsData: {} | any) => {
  return await ApiUtils.postWithToken(createVitals, vitalsData);
};
const vitalsByAppointmentDetails = async (appointmentId: any) => {
  return await ApiUtils.getWithToken(
    `${getVitalsByAppointment}/${appointmentId}`
  );
};
const medicalRecordsByAppointmentDetails = async (appointmentId: any) => {
  return await ApiUtils.getWithToken(
    `${getMedicalRecordsByAppointment}?appoint_id=${appointmentId}`
  );
};
const medicalReportsByAppointmentDetails = async (appointmentId: any) => {
  return await ApiUtils.getWithToken(
    `${getMedicalReportsByAppointment}${appointmentId}`
  );
};
const createMedicalRecordsDetails = async (
  MedicalRecordsData: any,
  type: number | string
) => {
  if (type === 1) {
    const localStorageAccessToken: any = getCookie("accessToken");

    if (localStorageAccessToken) {
      const access_token = JSON.parse(localStorageAccessToken);
      return await axios.post(createMedicalRecords, MedicalRecordsData, {
        headers: {
          zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
      });
    }
  } else {
    return await ApiUtils.postWithToken(
      createMedicalRecords,
      MedicalRecordsData
    );
  }
};
const deleteMedicalRecordsDetails = async (medicalRecordId: any) => {
  return await ApiUtils.deleteWithToken(
    `${deleteMedicalRecords}/${medicalRecordId}`
  );
};

const viewMedicalRecordsDetails = async (medicalRecordId: any) => {
  return await ApiUtils.getWithToken(
    `${viewMedicalRecords}/${medicalRecordId}`
  );
};

const updateMedicalRecordsDetails = async (
  data: {} | any,
  medicalRecordId: number
) => {
  return await ApiUtils.putWithToken(
    `${updateMedicalRecords}/${medicalRecordId}`,
    data
  );
};

// Investigation template apis

const getAllInvestigationTemplate = async (params: {
  search: string;
  column: string;
  order: string;
  page: number;
  limit: number;
}) => {
  try {
    // const apiUrl = setQueryParams(params, '');
    return await { data: { rows: templateList, total: templateList.length } };
  } catch (error) {
    console.log("Error while getting the investigation templates:", error);
    throw error;
  }
};

const getInvestigationTemplateDetailsById = async (id: number | string) => {
  try {
    const template = templateList.find((ele) => ele.id === id);
    return { data: template };
  } catch (error) {
    console.log(
      "Error while getting the investigation template detail by id:",
      error
    );
    throw error;
  }
};

const createInvestigationTemplate = async (data: any) => {
  try {
    const newTemplateList = [...templateList];
    newTemplateList.push({
      id: newTemplateList.length + 1,
      ...data,
    });
    templateList = newTemplateList;
    return newTemplateList;
  } catch (error) {
    console.log(
      "Error while getting the investigation template detail by id:",
      error
    );
    throw error;
  }
};

const updateInvestigationTemplateDetailsById = async (
  id: number | string,
  data: any
) => {
  try {
    const template = templateList.map((ele) => {
      if (ele.id === id) {
        return {
          ...ele,
          ...data,
        };
      }
      return ele;
    });
    templateList = template;
    return templateList;
  } catch (error) {
    console.log(
      "Error while getting the investigation template detail by id:",
      error
    );
    throw error;
  }
};

const deleteInvestigationTemplateById = async (id: number | string) => {
  try {
    const newTemplateList = templateList.filter((ele) => ele.id !== id);
    templateList = newTemplateList;
    return;
  } catch (error) {
    console.log("Error while deleting the investigation templates:", error);
    throw error;
  }
};

const getLabTestList = async (params: {
  department: number;
  search: string;
  page: number | string;
  limit: number | string;
}) => {
  try {
    const apiUrl = setQueryParams(params, labTestUrl);
    return await ApiUtils.getWithToken(apiUrl);
  } catch (error) {
    console.log("Error while getting the investigation templates:", error);
    throw error;
  }
};
const getDepartmentSequence = async (department: string | number) => {
  try {
    return await ApiUtils.getWithToken(
      `${appointmentEndPoints.sequenceUrl}/${department}`
    );
  } catch (error) {
    console.log("Error while getting the item name list", error);
    throw error;
  }
};
const getCommonAppConfigurations = async () => {
  try {
    return await ApiUtils.getWithToken(
      appointmentEndPoints.commonAppConfiguration
    );
  } catch (error) {
    console.log("Error occurs while getting the  app configuration:", error);
    throw error;
  }
};
const getAllCommons = async () => {
  return await ApiUtils.getWithToken(
    `${appointmentEndPoints.getAllCommonVariables}`
  );
};
const updatedSequence = async (sequenceId: number, data: any) => {
  return await ApiUtils.putWithToken(
    `${sequenceUpdateUrl}/${sequenceId}`,
    data
  );
};

const getAllUnits = async () => {
  return await ApiUtils.getWithToken(`${getAllUnitsList}`);
};

const getPatientsLimitInfo = async () => {
  return await ApiUtils.getWithToken(`${getPatientsLimit}`);
};

const getAllPrintNotesOpTypes = async () => {
  return await ApiUtils.getWithToken(`${getPrintNotesOpTypes}`);
};

const deleteAppointment = async (id: number) => {
  return await ApiUtils.deleteWithToken(`${deleteAppointmentById}/${id}`);
};

export {
  updatedSequence,
  getReferDoctorDetails,
  getAllCommons,
  getCommonAppConfigurations,
  getDepartmentSequence,
  getAllAppointment,
  appointmentInfo,
  doctorDetails,
  consultationDetails,
  PatientsDetails,
  doctorsAvailableDaysDetails,
  createAppointmentDetails,
  slotsDetails,
  cancelAppointmentDetails,
  rescheduleAppointmentDetails,
  AppointmentStatusDetails,
  tokenDetails,
  trackingStatusDetails,
  reviewAppointmentDetails,
  getAllPatientsList,
  getAllDoctorsList,
  getAllConsultationsList,
  prescriptionInfo,
  getSymptomsList,
  getProductsList,
  createPrescriptionProduct,
  deletePrescription,
  deletePrescriptionProduct,
  updatePrescriptionProduct,
  getInvestigationList,
  getTestList,
  createInvestigationTest,
  updateInvestigationTest,
  deleteInvestigation,
  deleteInvestigationTest,
  getInvoiceNumber,
  vitalsDetails,
  doctorVitalsDetails,
  createVitalsDetails,
  vitalsByAppointmentDetails,
  medicalRecordsByAppointmentDetails,
  createMedicalRecordsDetails,
  deleteMedicalRecordsDetails,
  viewMedicalRecordsDetails,
  updateMedicalRecordsDetails,
  medicalReportsByAppointmentDetails,
  getAllInvestigationTemplate,
  deleteInvestigationTemplateById,
  getInvestigationTemplateDetailsById,
  createInvestigationTemplate,
  updateInvestigationTemplateDetailsById,
  getLabTestList,
  getPxProductsList,
  getAllUnits,
  getAllDoctorsListTest,
  getPatientsLimitInfo,
  getAllPrintNotesOpTypes,
  deleteAppointment,
};
