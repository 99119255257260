import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { MasterScreenPic } from "../../../assets/images";

const Empty = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Box
            sx={{
              border: "1px solid #F2F2F2",
              borderRadius: "5px",
              height: "75vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <MasterScreenPic />

            <Typography variant="h3">Add Some Master Data</Typography>
            <Typography variant="h6" color="greyScale.main">
              Let Create Master Data on your System, so you can Add you data
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Empty;
