import React from "react";
import { TableCell, TableRow, Grid } from "@mui/material";
import { TextField, AutoComplete } from "../../../../components/basic";
import { DeleteIcon, DisabledDelete } from "../../../../assets/icons";

type ProductRowProps = {
  styles?: any;
  row?: any;
  handleDelete: (id: number) => void;
  handleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => void;
  handleAutoCompleteChange: (
    e: any,
    newValue: any,
    id: Number,
    name: string
  ) => false | void;
  handleAutoCompleteInputChange: (
    e: any,
    newInputValue: any,
    id: Number,
    name: string
  ) => false | undefined;
  index: number;
  allProducts?: any;
  billProducts?: any;
  error?: { [key: string]: string };
  isEdit?: boolean;
};

const ProductRow = React.memo(
  ({
    styles,
    row,
    handleInputChange,
    handleAutoCompleteChange,
    handleAutoCompleteInputChange,
    handleDelete,
    index,
    allProducts,
    billProducts,
    error,
    isEdit,
  }: ProductRowProps) => {
    return (
      <TableRow
        sx={{
          "& td, & th": { border: 0 },
          width: "90vw",
        }}
      >
        <TableCell
          align="center"
          sx={{
            width: "12%",
            height: "34px",
            "& .MuiAutocomplete-root": {
              height: "32px",
            },
            "& .MuiFormLabel-root": {
              display: "none",
            },
            "& .MuiInputBase-root": {
              height: "32px",
              p: "0px",
              pr: "0px",
            },
            "& .MuiOutlinedInput-root": {
              p: "0px",
              pr: "0px",
              boxShadow: "none",
            },
            border: !!error?.product_name ? "2px solid #d32f2f !important" : "",
          }}
        >
          <AutoComplete
            placeholder="select"
            value={row.product_name}
            inputValue={row.product_name_input_value}
            name="product_name"
            onChange={(e: any, newValue: any) => {
              handleAutoCompleteChange(e, newValue, index, "product_name");
            }}
            onInputChange={(e: any, newInputValue: any) => {
              handleAutoCompleteInputChange(
                e,
                newInputValue,
                index,
                "product_name_input_value"
              );
            }}
            options={allProducts}
            sx={{
              width: "12vw",
              height: "34px",
              "& .MuiFormControl-root": {
                height: "34px",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "& .MuiInputBase-root .MuiInputBase-input": {
                padding: 0,
                fontSize: "12px",
                fontWeight: "400",
              },

              fontFamily: ["Inter", "sans-serif"].join(","),

              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "greyScale.light",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.main",
              },
              "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
                borderColor: "greyScale.lighter",
              },
              "&.MuiAutocomplete-root .MuiOutlinedInput-root": {
                padding: "6.5px",
                boxShadow: "none",
                borderRadius: "8px",
              },
              "& .MuiAutocomplete-endAdornment": {
                pt: "3px",
                display: !row.id ? "inline-flex" : "none",
              },
              "&:hover .MuiAutocomplete-endAdornment": {
                display: !row.id ? "inline-flex" : "none",
              },
            }}
            disabled={row.id}
          />
        </TableCell>
        <TableCell
          className="last-cell"
          align="right"
          sx={{
            width: "10%",
            position: "relative",
            border: !!error?.requirement ? "2px solid #d32f2f !important" : "",
          }}
        >
          <TextField
            name="requirement"
            value={row.requirement}
            onChange={(e: any) => handleInputChange(e, index)}
            sx={{
              ...styles.textFieldStyle,
            }}
            error={!!error?.requirement}
          />

          <Grid className="deleteIconGrid">
            {billProducts?.length === 1 ? (
              <DisabledDelete className="delete-icon" />
            ) : (
              <DeleteIcon
                className="delete-icon"
                onClick={() => handleDelete(row.id)}
              />
            )}

            <TextField
              width="0px"
              name="discountAmount"
              value={row.discountAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
            <TextField
              width="0px"
              name="taxAmount"
              value={row.taxAmount}
              onChange={(e: any) => handleInputChange(e, index)}
              sx={{
                display: "none",
                transform: "translateX(100vw)",
              }}
            />
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
);

export default ProductRow;
