import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import BillProducts from "./BillProducts";
import {
  getAllMasterCustomer,
  getAllMasterDepartments,
} from "../../../services/gynecologyService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

const PurchaseReturn = () => {
  const [customerList, setCustomerList] = useState<any>([]);
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [departmentList, setDepartmentList] = useState([]);
  const getAllDepartmentList = async () => {
    try {
      const data = { active: 1, type: "issuer" };
      await getAllMasterDepartments(data).then((res: any) => {
        if (res.data) {
          const newDepartmentList = res.data?.result.map((ele: any) => ({
            name: ele.dept_name,
            id: ele.id,
          }));
          setDepartmentList(newDepartmentList);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getAllMasterCustomerList = async () => {
    try {
      const data = { active: 1 };
      await getAllMasterCustomer(data).then((res: any) => {
        if (res.data) {
          const newCustomerList = res.data?.result.map((ele: any) => ({
            name: ele.name,
            id: ele.id,
            address: ele.address,
            phone: ele.phone,
          }));
          setCustomerList(newCustomerList);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllDepartmentList();
    getAllMasterCustomerList();
  }, [appConfiguration]);
  return (
    <Grid
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BillProducts
        departmentList={departmentList}
        customerList={customerList}
      />
    </Grid>
  );
};

export default PurchaseReturn;
