import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { purchaseBillInfoConst } from "../../../../constants/displayText";

type InfoProps = {
  info: any;
};

const Info = ({ info }: InfoProps) => {
  const { invoiceNo } = purchaseBillInfoConst;

  const infoData = [
    {
      name: "Customer",
      value: info.customer_name,
    },
    {
      name: "Mobile No",
      value: info.phone,
    },
    {
      name: "Address",
      value: info.address,
    },
    {
      name: invoiceNo,
      value: info.invoice_no,
    },
    {
      name: "Date",
      value: info.date_created,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: "100%",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          pl: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            sx={{
              width: {
                xs: "90%",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              m: "15px 0px 10px 0px",
            }}
          >
            {infoData.map((row, index) => {
              return (
                <Grid
                  sm={12}
                  xs={6}
                  md={4}
                  xl={4}
                  key={index}
                  sx={{
                    width: {
                      xs: "100%",
                      lg: "100%",
                    },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    maxHeight: "35px",
                    mb: "23px",
                    color: "textPrimary.main",
                  }}
                  className="row"
                >
                  <Grid
                    sx={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      {row.name}
                    </Typography>
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      :
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      width: "70%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      pl: 3,
                    }}
                  >
                    <Typography variant="h6" sx={{ color: "greyScale.main" }}>
                      {row.value}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Info;
