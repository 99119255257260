import React, { useState, useMemo, useEffect } from "react";
import { Grid, Typography, Box, Avatar, CircularProgress } from "@mui/material";
import RecentVisits from "./RecentVisits";
import Prescriptions from "./Prescriptions";
import Investigations from "./Investigations";
import Vitals from "./Vitals";
import Treatments from "./Treatments";
import MedicalRecords from "./MedicalRecords";
import { useParams } from "react-router-dom";
import PermissionUtils from "../../../../../utils/PermissionUtils";

const AppointmentHistory = () => {
  const { can } = PermissionUtils();

  return (
    <Grid
      sx={{
        width: "100%",
        maxWidth: "100%",
        height: "auto",
        display: "flex",
        flexWrap: "wrap",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
      }}
    >
      <Vitals />
      {can("appointments_view") && can("prescription_view") && (
        <Prescriptions />
      )}
      {can("appointments_view") && can("op_investigation_view") && (
        <Investigations />
      )}
      {can("appointments_view") && can("medirecord_view") && <MedicalRecords />}
    </Grid>
  );
};

export default AppointmentHistory;
