import React from "react";
import { Grid } from "@mui/material";
import { SettingsIcon } from "../../../assets/icons";
import {
  Select,
  DatePickerWithTime,
  TextField,
} from "../../../components/basic";
import { purchaseBillInfoConst } from "../../../constants/displayText";

type InfoType = {
  dept_id: string | null | number;
  customer_id: string | null | number;
  invoice_no: string | null | number;
  address: string | null | number;
  mobile_no: string | null | number;
  date_created: string | null | number;
  invoice_no_modified: string | null | number;
  disabled: boolean;
};

type InfoProps = {
  info: InfoType;
  setInfo: any;
  departmentList: any;
  customerList: any;
  isGoodsIssue: any;
  setSequenceDialogBox: any;
  setIsManual: any;
  isManualAdded: any;
  isManual: any;
  customSequence?:boolean;
  handleConfirmSequenceNoChange:()=> void,
  errors?:any;
};

const Info = React.memo(
  ({
    info,
    setInfo,
    departmentList,
    customerList,
    isGoodsIssue,
    setSequenceDialogBox,
    setIsManual,
    isManualAdded,
    isManual,
    customSequence,handleConfirmSequenceNoChange,errors
  }: InfoProps) => {
    const { invoiceDate, billSequenceNo } = purchaseBillInfoConst;

    const handleInputChange = (e: any) => {
      const { name, value } = e.target;
      setInfo((prev: any) => ({
        ...prev,
        [name]: value,
      }));
      if (name === "customer_id") {
        const findCustomer = customerList.find(
          (ele: { id: number }) => ele.id === value
        );
        setInfo((prevState: any) => ({
          ...prevState,
          address: findCustomer?.address || "",
          mobile_no: findCustomer?.phone || "",
        }));
      }
    };

    const handleDateChange = (newValue: any) => {
      setInfo((prev: any) => ({
        ...prev,
        invoice_date: newValue.toString(),
      }));
    };

    const styles = {
      selectGrid: {
        width: "200px",
        height: "45px",
        boxShadow: "none",
        borderRadius: "5px",
        borderColor: "var(--primary-border-color)",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },

      textFieldStyle: {
        width: "200px",
        "& .MuiOutlinedInput-root": {
          height: "45px",
          borderRadius: "5px",
          paddingLeft: "8px",
          boxShadow: "none",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "var(--primary-border-color)",
        },
      },
    };

    return (
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          m: "0px 0px 30px 0px",
          width: "90vw",
          minHeight: "100px",
          height: "auto",
          gap: 5,
        }}
      >
        <Select
          value={info.dept_id}
          onChange={(e) => handleInputChange(e)}
          placeholder={"Issuer"}
          options={departmentList}
          label={"Issuer"}
          name="dept_id"
          width="200px"
          sx={{
            ...styles.selectGrid,
          }}
          disabled={info.disabled}
        />

        <Select
          value={info.customer_id}
          onChange={handleInputChange}
          placeholder={"Select Customer"}
          options={customerList}
          label={"Customer"}
          name="customer_id"
          width="200px"
          sx={{
            ...styles.selectGrid,
          }}
          disabled={info.disabled}
        />

        <TextField
          value={info.mobile_no}
          label={"Mobile No"}
          name="mobile_no"
          disabled
          sx={{
            ...styles.textFieldStyle,
            backgroundColor: "var(--disabled-background)",
          }}
        />
        <TextField
          value={info.address}
          label={"Address"}
          name="address"
          disabled
          sx={{
            ...styles.textFieldStyle,
            backgroundColor: "var(--disabled-background)",
          }}
        />
        <Grid
          sx={{
            "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container  >div >div .rmdp-input":
              {
                boxSizing: "border-box",
                width: "200px",
              },
          }}
        >
          <DatePickerWithTime
            value={info.date_created}
            label={invoiceDate}
            onChange={handleDateChange}
            borderColor="var(--primary-border-color)"
            style={{
              boxShadow: "none",
              height: "45px",
              borderRadius: "5px",
            }}
            name="date_created"
          />
        </Grid>
        {customSequence ? (<TextField
          value={info.invoice_no}
          helperText={errors?.invoice_no}
          label={billSequenceNo}
          onBlur={handleConfirmSequenceNoChange}
          onChange={handleInputChange}
          endPlaceholderIcon={
              <SettingsIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSequenceDialogBox(true);
                }}
              />
         
          }
          name="invoice_no"
          sx={{
            ...styles.textFieldStyle,
          }}
        />) : null}
      </Grid>
    );
  }
);

export default Info;
