import { Outlet, Navigate } from "react-router-dom";

type PrivateRoutesTypes = {
  isLoggedIn?: boolean;
};

const PrivateRoutes = ({ isLoggedIn }: PrivateRoutesTypes) => {
  return isLoggedIn ? <Outlet /> : <Navigate to="/" />;
  
};

export default PrivateRoutes;
