import { Box, Grid, Typography, debounce } from "@mui/material";
import { DialogWrapper } from "../../../../components/shared";
import { useEffect, useRef, useState } from "react";
import {
  createWholeAdjustment,
  getWholeAdjustmentById,
  updateWholeAdjustment,
  getAllMasterCustomer,
  getWholeAdjustmentBalance,
} from "../../../../services/gynecologyService";
import { Select, TextField } from "../../../../components/basic";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { errorNaming } from "../../../../constants/displayText";

type AddAndEditModalProps = {
  isOpen: boolean;
  onClose: (isSave: boolean) => void;
  onSave?: (value: any) => void;
  isSaveLoading?: boolean;
  id: null | string | number;
};

const AddAndEditModal = (props: AddAndEditModalProps) => {
  const { isOpen, onClose, id } = props;
  const dispatch = useDispatch();
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [data, setData] = useState<any>({
    customer_id: "",
    adjustment_type: "",
    type: "",
    balance: 0,
    adjustment: "",
  });
  const [customerList, setCustomerList] = useState([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    patient_id: "",
    adjustment_type: "",
    type: "",
    adjustment: "",
  });
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const getAllMasterCustomerList = async () => {
    try {
      const data = { active: 1 };
      await getAllMasterCustomer(data).then((res: any) => {
        if (res.data) {
          const newCustomerList = res.data?.result.map((ele: any) => ({
            name: ele.name,
            id: ele.id,
            address: ele.address,
            phone: ele.phone,
          }));
          setCustomerList(newCustomerList);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event?.target;

    setData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

    if (errors[name] && value) {
      setErrors((PrevState) => ({ ...PrevState, [name]: "" }));
    }

    if (name === "adjustment" && value > data.balance) {
      setErrors((PrevState) => ({
        ...PrevState,
        [name]: "The adjustment should not exceed the amount.",
      }));
    }
  };

  const handleClose = () => {
    setData({
      customer_id: "",
      adjustment_type: "",
      type: "",
      balance: 0,
      adjustment: "",
    });
    setErrors({
      customer_id: "",
      adjustment_type: "",
      type: "",
      adjustment: "",
    });
    onClose(false);
  };

  const handleValidate = () => {
    const requiredKeys = [
      "customer_id",
      "adjustment_type",
      "type",
      "adjustment",
    ];

    const newErrors: { [key: string]: string } = {};
    requiredKeys?.forEach((key: string) => {
      if (!data[key]) {
        newErrors[key] = errorNaming[key];
      } else {
        newErrors[key] = "";
      }
    });

    if (Object.values(newErrors)?.filter((ele) => Boolean(ele))?.length > 0) {
      setErrors(newErrors);
      return false;
    }

    if (Number(data.balance) < Number(data.adjustment)) {
      setErrors((prevstate) => ({
        ...prevstate,
        adjustment: "The adjustment should not exceed the amount.",
      }));
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (handleValidate()) {
      if (id) {
        updateWholeAdjustment(id, data as any)
          .then((res: any) => {
            if (res) {
              dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            }
            setIsSaveLoading(false);
            onClose(true);
          })
          .catch((err: any) => {
            console.log(err);
            setIsSaveLoading(false);
            if (err?.response?.data?.errors) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
          });
      } else {
        setIsSaveLoading(true);
        createWholeAdjustment(data as any)
          .then((res: any) => {
            if (res) {
              dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            }
            setIsSaveLoading(false);
            onClose(true);
          })
          .catch((err: any) => {
            console.log(err);
            setIsSaveLoading(false);
            if (err?.response?.data?.errors) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err?.response?.data?.errors,
                })
              );
            }
          });
      }
    }
  };
  console.log(data);
  useEffect(() => {
    if (data.customer_id && data.adjustment_type && !id) {
      getWholeAdjustmentBalance({
        customer_id: data.customer_id,
        adjustment_type: data.adjustment_type,
      })
        .then((res: any) => {
          if (res.data) {
            console.log("res", res.data);
            setData((prevstate: any) => ({
              ...prevstate,
              balance: res?.data?.balance || 0,
            }));
          }
          if (!res.data) {
            setData((prevstate: any) => ({
              ...prevstate,
              balance: 0,
            }));
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [data.customer_id, data.adjustment_type]);

  useEffect(() => {
    if (id) {
      getWholeAdjustmentById(id)
        .then((res: any) => {
          if (res.data) {
            console.log("res", res.data);
            // setPatientsList([
            //   { value: res?.data?.patient_id, label: res?.data?.name },
            // ]);
            setData({
              ...res.data,
              adjustment_type:
                res?.data?.is_advance === 0 ? "Excess" : "Advance",
              adjustment:
                res?.data?.is_advance === 0
                  ? res?.data?.net_excess
                  : res?.data?.cash_received,
              balance:
                (res?.data?.is_advance === 0
                  ? Number(res?.data?.advance) || 0
                  : Number(res?.data?.excess) || 0) +
                (res?.data?.is_advance === 0
                  ? Number(res?.data?.net_excess) || 0
                  : Number(res?.data?.cash_received) || 0),
            });
          }
        })
        .catch((err: any) => {
          console.log("err", err);
        });
    }
    getAllMasterCustomerList();
  }, [id]);
  return (
    <DialogWrapper
      open={isOpen}
      maxWidth="sm"
      onClose={handleClose}
      sx={{
        "& .MuiDialogContent-root": {
          overflowY: "unset",
        },
      }}
      handleClick={handleSave}
      title={`${id ? "Update" : "Add"} Adjustment`}
      loading={isSaveLoading}
    >
      <Box>
        <Grid container display="flex" rowGap={2}>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Select
              label={"Select Customer"}
              name="customer_id"
              options={customerList}
              formControlStyle={{ width: "100%" }}
              width=""
              value={data.customer_id}
              onChange={handleChange}
              error={!!errors?.customer_id}
              helperText={errors?.customer_id}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Select
              value={data?.adjustment_type}
              onChange={handleChange}
              placeholder={"Select adjustment type"}
              options={commonVariables?.payment_type?.filter(
                (ele: any) => ele.adjustable
              )}
              label="Adjustment Type"
              name={"adjustment_type"}
              width=""
              formControlStyle={{ width: "100%" }}
              helperText={errors.adjustment_type}
              disabled={!!id}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Select
              value={data.type}
              onChange={handleChange}
              placeholder={"Select Payment type"}
              options={commonVariables?.payment_type?.filter(
                (ele: any) => !ele.adjustable
              )}
              label="Payment Type"
              name={"type"}
              width=""
              formControlStyle={{ width: "100%" }}
              helperText={errors?.type}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Typography>Balance: {data.balance}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <TextField
              value={data.adjustment}
              label={"Adjustment"}
              onChange={handleChange}
              name={"adjustment"}
              type="number"
              helperText={errors?.adjustment}
              error={!!errors.adjustment}
            />
          </Grid>
        </Grid>
      </Box>
    </DialogWrapper>
  );
};

export default AddAndEditModal;
