import React from "react";
import { Grid } from "@mui/material";
import BillProducts from "./BillProducts";

const CreateLabIndent = () => {
  return (
    <Grid
      sx={{
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <BillProducts />
    </Grid>
  );
};

export default CreateLabIndent;
