import { TableCell, TableRow, Box, Typography } from "@mui/material";
import { TextField } from "../../../../components/basic";

type ItemRowProps = {
  index: number;
  columns: any;
  row: any;
  error?: any;
  onChange: (event: any, index: number) => void;
};

const styles = {
  serialNoStyle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "11px",
    textAlign: "center",
    letterSpacing: "0px",
  },
  optionStyle: {
    width: "125px",
    fontSize: "13px",
    fontWeight: "600",
    color: "textPrimary.main",
    textAlign: "left",
    pl: "5%",
  },
  selectFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
  textFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "0px 5px",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  amountColumnStyle: {
    "& .MuiInputBase-input": {
      fontFamily: "Inter",
      fontSize: "12px !important",
      fontWeight: "600 !important",
      lineHeight: "11px",
      textAlign: "center",
      letterSpacing: "0px",
      "-webkit-text-fill-color": "unset !important",
      color: "#000",
    },
  },
};

const ItemRow = (props: ItemRowProps) => {
  const { index, columns, row, error, onChange } = props;

  return (
    <TableRow>
      <TableCell
        sx={{
          borderBottomColor: "var(--table-border)",
          width: `${columns[0].width}%`,
        }}
      >
        <Typography sx={styles.serialNoStyle}>{index + 1}</Typography>
      </TableCell>
      <TableCell
        sx={{
          width: `${columns[1].width}%`,
          background: "#fff",
        }}
      >
        <Typography color={"#9E9E9E"} p={2}>{row?.product?.name}</Typography>
      </TableCell>
      <TableCell sx={{ width: `${columns[2].width}%` }}>
        <Typography p="10px" color={"#9E9E9E"}>
          {row.indent_qty}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          width: `${columns[3].width}%`,
          border: !!error?.ptr ? "2px solid #d32f2f !important" : "",
        }}
      >
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": { height: "100%" },
            "& .MuiTextField-root": { height: "100%" },
            "& .MuiInputBase-root": { height: "100%" },
          }}
        >
          <TextField
            type="number"
            fullWidth
            formControlStyle={{
              width: "100%",
              height: "100%",
              marginLeft: "15px",
            }}
            value={row.ptr || 0}
            sx={{
              ...styles.textFieldStyle,
              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            name="ptr"
            onChange={(event: any) => onChange(event, index)}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          "&.MuiTableCell-body:has(.Mui-focused)": {
            border: "#FFFFFF !important",
          },
          width: `${columns[4].width}%`,
        }}
      >
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": { height: "100%" },
            "& .MuiTextField-root": { height: "100%" },
            "& .MuiInputBase-root": { height: "100%" },
          }}
        >
          <TextField
            type="number"
            fullWidth
            disabled
            formControlStyle={{
              width: "100%",
              height: "100%",
              marginLeft: "15px",
            }}
            value={row.value || 0}
            sx={{
              ...styles.textFieldStyle,
              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            name="value"
          />
        </Box>
      </TableCell>
      <TableCell sx={{ width: `${columns[5].width}%` }}>
        <Typography p="10px" color={"#9E9E9E"}>
          {row.recived_qty}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ItemRow;
