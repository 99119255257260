import { Box, Grid, Typography } from "@mui/material";
import { DialogWrapper } from "../../../../components/shared";
import { Select, TextField } from "../../../../components/basic";
import { DeleteIcon } from "../../../../assets/icons";
import { useEffect, useState } from "react";
import { conditionOptions } from "../../../../constants/dropdownOptions";
import { getAgeGroupOptions } from "../../../../services/billingService";

type RangeModalProps = {
  isOpen: boolean;
  data: any;
  onClose: () => void;
  onSave: (data: any) => void;
};

const RangeModal = (props: RangeModalProps) => {
  const { isOpen, data, onClose, onSave } = props;
  const [rows, setRows] = useState<any[]>([
    {
      age_group_id: "",
      min_value: "",
      range_type: "",
      max_value: "",
      health_condition: "",
    },
  ]);
  const [ageGroupOptions, setAgeGroupOptions] = useState([]);

  const handleAddRangeItem = () => {
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows.push({
        age_group_id: "",
        min_value: "",
        range_type: "",
        max_value: "",
        health_condition: "",
      });
      return newRows;
    });
  };
  const handleDelete = (index: number) => {
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows.splice(index, 1);
      return newRows;
    });
  };

  const handleChange = (event: any, index: number) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    if (["min_value", "max_value"].includes(name)) {
      newRows[index][name] = Number(value);
    } else {
      newRows[index][name] = value;
    }

    if (name === "range_type" && value !== "-" && newRows[index]?.min_value) {
      newRows[index].min_value = 0;
    }
    setRows(newRows);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      setRows(JSON.parse(JSON.stringify(data)));
    }
  }, [data]);

  useEffect(() => {
    getAgeGroupOptions()
      .then((res: any) => {
        if (res.data) {
          setAgeGroupOptions(res.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <DialogWrapper
      open={isOpen}
      onClose={() => {
        setRows([]);
        onClose();
      }}
      handleClick={() => {
        onSave(rows);
        onClose();
      }}
      title="Add Range"
      confirmText="Save"
      cancelText="Cancel"
    >
      {rows?.map((row: any, index: any) => (
        <Grid
          container
          width="100%"
          columns={12}
          rowSpacing={3}
          p={1}
          alignItems={"center"}
          columnGap={2}
        >
          <Grid item xs={12} sm={12} md={3}>
            <Select
              formControlStyle={{
                width: "100%",
              }}
              label="Category"
              options={ageGroupOptions}
              width=""
              value={row.age_group_id}
              name="age_group_id"
              onChange={(event: any) => handleChange(event, index)}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={1}>
            <TextField
              formControlStyle={{ width: "100%" }}
              label="From"
              name="min_value"
              value={row.min_value}
              onChange={(event: any) => handleChange(event, index)}
              sx={{ background: row?.range_type !== "-" ? "#F8F8F8" : "unset" }}
              disabled={row?.range_type !== "-"}
            />
          </Grid>
          <Grid item xs={3.5} sm={3.5} md={3}>
            <Select
              formControlStyle={{
                width: "100%",
              }}
              label="Conditions"
              value={row.range_type}
              options={conditionOptions}
              width=""
              name="range_type"
              onChange={(event: any) => handleChange(event, index)}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={1}>
            <TextField
              formControlStyle={{ width: "100%" }}
              label="To"
              name="max_value"
              value={row.max_value}
              onChange={(event: any) => handleChange(event, index)}
            />
          </Grid>
          <Grid item xs={10.5} sm={10.5} md={2}>
            <TextField
              formControlStyle={{ width: "100%" }}
              label="Control"
              name="health_condition"
              value={row.health_condition}
              onChange={(event: any) => handleChange(event, index)}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={0.5}
            height="60px"
            display="flex"
            alignItems="flex-end"
            justifyContent="center"
          >
            <DeleteIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(index)}
            />
          </Grid>
        </Grid>
      ))}
      <Box mt={3}>
        <Typography
          style={{
            color: "#3A8DFF",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "26px",
            cursor: "pointer",
            width: "fit-content",
            marginLeft: "10px",
          }}
          onClick={handleAddRangeItem}
        >
          + Add items
        </Typography>
      </Box>
    </DialogWrapper>
  );
};

export default RangeModal;
