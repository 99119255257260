import React, { useEffect, useState } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { CancelButton, SaveButton } from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import { goodsIssueConst } from "../../../../constants/displayText";
import Info from "./Info";
import Summary from "./Summary";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  cancelLabGoodsIssue,
  getLabGoodsIssueProductById,
} from "../../../../services/laboratoryService";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import PermissionUtils from "../../../../utils/PermissionUtils";

const BillProducts = () => {
  const { productName, batchNo, expiryDate, qty, mrp, discount, tax, amount } =
    goodsIssueConst;
  const { id } = useParams();
  const [info, setInfo] = React.useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { can } = PermissionUtils();

  const [billingSummary, setBillingSummary] = React.useState({});
  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);
  const [isBillCancelClicked, setIsBillCancelClicked] = useState(false);
  const [isShowCancelBill, setIsCancelBill] = useState(false);
  useEffect(() => {
    if (id) {
      setIsDataTableLoading(true);
      getLabGoodsIssueProductById(id)
        .then((res: any) => {
          const purchaseBill = res.data.bill;
          const billProducts = res.data.bill_products;
          const validation = res.data.validation;
          const checkCancelBill = {
            is_cancel: purchaseBill.is_cancel,
            is_return: purchaseBill.is_return,
            is_return_filed: validation.is_return_filed,
            is_transfer_done: validation.is_transfer_done,
            is_sale_done: validation.is_sale_done,
            is_adj_done: validation.is_adj_done,
          };
          for (const [key, value] of Object.entries(checkCancelBill)) {
            if (value === 0) {
              setIsCancelBill(true);
            }
          }

          setInfo((prevState) => ({
            ...prevState,
            invoice_no: purchaseBill.invoice_no,
            issuer: purchaseBill.from_dept_name,
            receiver: purchaseBill.to_dept_name,
            date: formatTwelveHoursTime(purchaseBill.created_at),
          }));
          const data = billProducts.map((res: any) => ({
            id: res.id,
            product_name: res.product_name,
            batch_no: res.batch_no,
            expiry_date: res.expires_at
              ? res.expires_at.split("-").reverse().join("/")
              : "",
            qty: res.original_qty,
            mrp: res.mrp,
            discount: res.discount || 0,
            tax: `${res.tax_id}%`,
            amount: res.amount,
          }));
          setBillProducts((prevState: any) => [...prevState, ...data]);
          setBillingSummary((prevState: any) => ({
            ...prevState,
            goods_value: purchaseBill.orig_goods_val,
            tax: purchaseBill.gst_amt,
            roundedOff: purchaseBill.roundedOff || 0.0,
            netAmount: purchaseBill.bill_amt,
          }));
          setIsDataTableLoading(false);
        })
        .catch((err) => {
          setIsDataTableLoading(false);
          console.log(err);
        });
    }
  }, [id]);

  const [billProducts, setBillProducts] = useState<any>([]);
  const handleCancelBill = async () => {
    setIsBillCancelClicked(true);
    await cancelLabGoodsIssue(id as string)
      .then((res: any) => {
        setIsBillCancelClicked(false);

        navigate("/laboratory/lab-goods-issue");
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
      })
      .catch((err) => {
        setIsBillCancelClicked(false);

        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
        console.log(err);
      });
  };
  const columns: GridColDef[] = [
    {
      field: "product_name",
      flex: 2,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {productName}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.product_name}</Typography>
      ),
      sortable: false,
    },
    {
      field: "batch_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {batchNo}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.batch_no}</Typography>
      ),
      sortable: false,
    },
    {
      field: "expiry_date",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {expiryDate}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.expiry_date}</Typography>
      ),
      sortable: false,
    },
    {
      field: "qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {qty}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.qty}</Typography>
      ),
      sortable: false,
    },
    {
      field: "mrp",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {mrp}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.mrp}</Typography>
      ),
      sortable: false,
    },
    {
      field: "discount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {discount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.discount}</Typography>
      ),
      sortable: false,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {tax}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.tax}</Typography>
      ),
      sortable: false,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
          >
            {amount}
          </Typography>
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.amount}</Typography>
      ),
      sortable: false,
    },
  ];

  return isDataTableLoading ? (
    <Grid
      sx={{
        width: "100%",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        height: "20vh",
        background: "transparent",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid>
      <Info info={info} />
      <Grid sx={{ m: "20px 0px" }}>
        <DataTable
          columns={columns}
          rows={billProducts}
          tableOnly={true}
          customizedTable={true}
        />
      </Grid>
      <Summary billingSummary={billingSummary} />
      <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
        <CancelButton
          buttonText="Back"
          sx={{ width: "100px", height: "42px" }}
          handleClick={() => navigate("/laboratory/lab-goods-issue")}
        />
        {!isShowCancelBill && can("lab_goods_issue_cancel") && (
          <SaveButton
            buttonText="Cancel Goods Issue"
            sx={{ width: "200px", height: "42px" }}
            handleClick={() => handleCancelBill()}
            loading={isBillCancelClicked}
          />
        )}
      </div>
    </Grid>
  );
};

export default BillProducts;
