import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getAuthUserDetails, loginCheck } from "../../services/authService";

interface AuthState {
  isLoggedIn: boolean;
  authUser: unknown;
  organizationList: { tenants: []; phoneNumber: string | number | null };
}

const initialState: AuthState = {
  isLoggedIn: Boolean(loginCheck()),
  authUser: Object(getAuthUserDetails()),
  organizationList: { tenants: [], phoneNumber: "" },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthUser: (state, { payload }: PayloadAction<string | null>) => ({
      ...state,
      authUser: payload,
    }),
    setIsLoggedIn: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isLoggedIn: payload,
    }),
    setOrganizationList: (
      state,
      { payload }: PayloadAction<{ tenants: []; phoneNumber: "" }>
    ) => ({
      ...state,
      organizationList: payload,
    }),
  },
});

export const { setAuthUser, setIsLoggedIn, setOrganizationList } =
  authSlice.actions;

export default authSlice.reducer;
