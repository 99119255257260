import { ApiUtils } from "../utils";

const campaignsList = [
  {
    id: 1,
    name: "Kidney Dialysis",
    mode: "WhatsApp",
    date: "25/06/2024",
    openRate: 59.5,
  },
  {
    id: 2,
    name: "Immunization collateral",
    mode: "Chatbot",
    date: "28/06/2024",
    openRate: 83.3,
  },
  {
    id: 3,
    name: "Stent Register Templates",
    mode: "SMS",
    date: "30/06/2024",
    openRate: 75.5,
  },
  {
    id: 4,
    name: "Kidney Dialysis",
    mode: "Email",
    date: "15/06/2024",
    openRate: 62.2,
  },
  {
    id: 5,
    name: "Immunization collateral",
    mode: "Chatbot",
    date: "15/06/2024",
    openRate: 59.5,
  },
  {
    id: 6,
    name: "Stent Register Templates",
    mode: "WhatsApp",
    date: "25/06/2024",
    openRate: 59.5,
  },
  {
    id: 7,
    name: "Immunization collateral",
    mode: "Email",
    date: "25/06/2024",
    openRate: 59.5,
  },
  {
    id: 8,
    name: "Kidney Dialysis",
    mode: "SMS",
    date: "25/06/2024",
    openRate: 59.5,
  },
];
const inHouseLeadsList = [
  {
    id: 1,
    sNo: 1,
    doctor_name: "S.M Praveen Kumar.,M.B.B.S",
    patient_name: "G.V Swasthika",
    mobile: "9854653221",
    item_name: "Thyroid Profile (T3, T4, Tsh )",
    due_date: "25/06/2024",
    follow_up_date: "25/12/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Answered",
    appointment_status: "Booked",
  },
  {
    id: 2,
    sNo: 2,
    doctor_name: "S.M Krishnamoorthy.,M.B.B.S",
    patient_name: "M Pradeep Kumar",
    mobile: "9854653221",
    item_name: "Hepatitis N  (2nd Dose)",
    due_date: "25/06/2024",
    follow_up_date: "",
    tele_caller: "S Ganesh kumar",
    call_status: "Do Not Disturb",
    appointment_status: "",
  },
  {
    id: 3,
    sNo: 3,
    doctor_name: "K. Subramani.,M.B.B.S",
    patient_name: "R Saswanthika",
    mobile: "9854653221",
    item_name: "Ultrasound scans",
    due_date: "24/06/2024",
    follow_up_date: "",
    tele_caller: "S Preethika Devi",
    call_status: "Not Reachable",
    appointment_status: "Follow Up",
  },
  {
    id: 4,
    sNo: 4,
    doctor_name: "S.M Krishnamoorthy.,M.B.B.S",
    patient_name: "Kishor Kumar S",
    mobile: "9854653221",
    item_name: "Cytocarb 150mg Injection",
    due_date: "30/05/2024",
    follow_up_date: "24/05/2024",
    tele_caller: "Praveen Kumar",
    call_status: "",
    appointment_status: "Booked",
  },
  {
    id: 5,
    sNo: 5,
    doctor_name: "S.M Praveen Kumar.,M.B.B.S",
    patient_name: "G.V Swasthika",
    mobile: "9854653221",
    item_name: "Thyroid Profile (T3, T4, Tsh )",
    due_date: "25/06/2024",
    follow_up_date: "21/04/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Not Reachable",
    appointment_status: "Booked",
  },
  {
    id: 6,
    sNo: 6,
    doctor_name: "S.M Krishnamoorthy.,M.B.B.S",
    patient_name: "M Pradeep Kumar",
    mobile: "9854653221",
    item_name: "Hepatitis N  (2nd Dose)",
    due_date: "25/06/2024",
    follow_up_date: "",
    tele_caller: "Praveen Kumar",
    call_status: "",
    appointment_status: "",
  },
  {
    id: 7,
    sNo: 7,
    doctor_name: "K. Subramani.,M.B.B.S",
    patient_name: "R Saswanthika",
    mobile: "9854653221",
    item_name: "Ultrasound scans",
    due_date: "24/06/2024",
    follow_up_date: "18/05/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Do Not Disturb",
    appointment_status: "Booked",
  },
  {
    id: 8,
    sNo: 8,
    doctor_name: "S.M Krishnamoorthy.,M.B.B.S",
    patient_name: "Kishor Kumar S",
    mobile: "9854653221",
    item_name: "Cytocarb 150mg Injection",
    due_date: "30/05/2024",
    follow_up_date: "20/05/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Answered",
    appointment_status: "",
  },
  {
    id: 9,
    sNo: 9,
    doctor_name: "S.M Praveen Kumar.,M.B.B.S",
    patient_name: "G.V Swasthika",
    mobile: "9854653221",
    item_name: "Thyroid Profile (T3, T4, Tsh )",
    due_date: "25/06/2024",
    follow_up_date: "23/05/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Not Reachable",
    appointment_status: "Follow Up",
  },
];
const coldLeadsList = [
  {
    id: 1,
    sNo: 1,
    prospect: "S.M Praveen Kumar",
    mobile: "9854653221",
    campaign: "Kidney Dialysis",
    mode: "WhatsApp",
    follow_up_date: "25/12/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Answered",
    appointment_status: "Booked",
  },
  {
    id: 2,
    sNo: 2,
    prospect: "S.M Krishnamoorthy",
    patient_name: "M Pradeep Kumar",
    mobile: "9854653221",
    campaign: "Stent Registers",
    mode: "SMS",
    follow_up_date: "",
    tele_caller: "S Ganesh kumar",
    call_status: "Do Not Disturb",
    appointment_status: "",
  },
  {
    id: 3,
    sNo: 3,
    prospect: "K. Subramani",
    patient_name: "R Saswanthika",
    mobile: "9854653221",
    campaign: "Immunization ",
    mode: "Email",
    follow_up_date: "",
    tele_caller: "S Preethika Devi",
    call_status: "Not Reachable",
    appointment_status: "Follow Up",
  },
  {
    id: 4,
    sNo: 4,
    prospect: "S.M Krishnamoorthy",
    patient_name: "Kishor Kumar S",
    mobile: "9854653221",
    campaign: "Stent Registers",
    mode: "Chatbot",
    follow_up_date: "24/05/2024",
    tele_caller: "Praveen Kumar",
    call_status: "",
    appointment_status: "Booked",
  },
  {
    id: 5,
    sNo: 5,
    prospect: "S.M Praveen Kumar",
    patient_name: "G.V Swasthika",
    mobile: "9854653221",
    campaign: "Kidney Dialysis",
    mode: "WhatsApp",
    follow_up_date: "21/04/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Not Reachable",
    appointment_status: "Booked",
  },
  {
    id: 6,
    sNo: 6,
    prospect: "S.M Krishnamoorthy",
    patient_name: "M Pradeep Kumar",
    mobile: "9854653221",
    campaign: "Immunization ",
    mode: "Chatbot",
    follow_up_date: "",
    tele_caller: "Praveen Kumar",
    call_status: "",
    appointment_status: "",
  },
  {
    id: 7,
    sNo: 7,
    prospect: "K. Subramani",
    patient_name: "R Saswanthika",
    mobile: "9854653221",
    campaign: "Stent Registers",
    mode: "SMS",
    follow_up_date: "18/05/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Do Not Disturb",
    appointment_status: "Booked",
  },
  {
    id: 8,
    sNo: 8,
    prospect: "S.M Krishnamoorthy",
    patient_name: "Kishor Kumar S",
    mobile: "9854653221",
    campaign: "Immunization ",
    mode: "Email",
    follow_up_date: "20/05/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Answered",
    appointment_status: "",
  },
  {
    id: 9,
    sNo: 9,
    prospect: "S.M Praveen Kumar",
    patient_name: "G.V Swasthika",
    mobile: "9854653221",
    campaign: "Kidney Dialysis",
    mode: "SMS",
    follow_up_date: "23/05/2024",
    tele_caller: "Praveen Kumar",
    call_status: "Not Reachable",
    appointment_status: "Follow Up",
  },
];
const getAllCampaigns = async (params: any) => {
  try {
    return { data: { result: campaignsList, total: campaignsList.length } };
    // return await ApiUtils.getWithToken('')
  } catch (error) {
    console.log("Error occurs while getting the campaign list:", error);
    throw error;
  }
};

const getAllInHouseLeads = async (params: any) => {
  try {
    return {
      data: { result: inHouseLeadsList, total: inHouseLeadsList.length },
    };
    // return await ApiUtils.getWithToken('')
  } catch (error) {
    console.log("Error occurs while getting the inhouse leads list:", error);
    throw error;
  }
};

const getAllColdLeads = async (params: any) => {
  try {
    return {
      data: { result: coldLeadsList, total: coldLeadsList.length },
    };
    // return await ApiUtils.getWithToken('')
  } catch (error) {
    console.log("Error occurs while getting the cold leads list:", error);
    throw error;
  }
};

export { getAllCampaigns, getAllInHouseLeads, getAllColdLeads };
