import React, { useState } from "react";
import { Box, Grid, Paper } from "@mui/material";
import { Label } from "../../../components/basic";
import { MasterImg } from "../../../assets/images";
import WorkingHours from "./WorkingHours";
import Empty from "./Empty";
import VacationList from "./Vacations";
import ConsultationList from "./ConsultationType";
import ProductList from "./ProductsList";
import PrintNoteMasterList from "./PrintNoteMaster";
import SymptomsMasterList from "./SymptomsMaster";
import { MasterHighlightedIcon, MasterIcon } from "../../../assets/icons";
import InvestigationTemplateMaster from "./InvestigationTemplateMaster";
import PermissionUtils from "../../../utils/PermissionUtils";

interface BoxData {
  id: number;
  icon: React.ReactNode;
  content: string;
}
const boxesData: BoxData[] = [
  { id: 1, icon: <MasterImg />, content: "Working Hours" },
  { id: 2, icon: <MasterImg />, content: "Vacation Days" },
  { id: 3, icon: <MasterImg />, content: "Consultation Type" },
  { id: 4, icon: <MasterImg />, content: "Product List" },
  { id: 5, icon: <MasterImg />, content: "Print Note Master" },
  { id: 6, icon: <MasterImg />, content: "Symptoms Master" },
  { id: 7, icon: <MasterImg />, content: "Investigation Template" },
];

const ClickableBoxGrid: React.FC = () => {
  const { can } = PermissionUtils();
  const [selectedBox, setSelectedBox] = useState<number | null>(0);

  const handleBoxClick = (boxId: number) => {
    setSelectedBox(boxId);
  };

  return (
    <>
      <Grid container spacing={2}>
        {boxesData.map((box) => (
          <Grid
            item
            key={box.id}
            xs={6}
            sm={4}
            md={2}
            xl={2}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", lg: "start" },
              alignItems: "center",
            }}
          >
            <Paper
              onClick={() => handleBoxClick(box.id)}
              elevation={3}
              sx={{
                height: "80px",
                width: "190px",
                display: "flex",
                m1: 1,
                cursor: "pointer",
                borderRadius: "5px",
                boxShadow: "none",
                border: "1px solid #B9B9B9",
                background: "rgba(255, 255, 255, 0.43)",
                ...(selectedBox === box.id && {
                  color: "#207DFF",
                  border: "1.5px solid rgba(32, 125, 255, 1)",
                  background: "rgba(236, 244, 255, 0.43)",
                }),
              }}
            >
              <Box sx={{ p: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    pb: 1,
                  }}
                >
                  {selectedBox === box.id ? (
                    <MasterHighlightedIcon />
                  ) : (
                    <MasterIcon />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Label
                    label={box.content}
                    variant="h4"
                    sx={{
                      color:
                        selectedBox === box.id
                          ? "primary.main"
                          : "greyScale.main",
                    }}
                  />
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {selectedBox === 0 && <Empty />}
      {selectedBox === 1 && can("workhour_list") && <WorkingHours />}
      {selectedBox === 2 && can("vaccation_list") && <VacationList />}
      {selectedBox === 3 && <ConsultationList />}
      {selectedBox === 4 && can("pxproducts_view") && <ProductList />}
      {selectedBox === 5 && can("print_note_view") && <PrintNoteMasterList />}
      {selectedBox === 6 && can("symptoms_view") && <SymptomsMasterList />}
      {selectedBox === 7 && <InvestigationTemplateMaster />}
    </>
  );
};

export default ClickableBoxGrid;
