import { Grid } from "@mui/material";
import { TextField } from "../../../../components/basic";
import { DialogWrapper } from "../../../../components/shared";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { createItemGroupMaster } from "../../../../services/billingService";

type MasterAddAndEditDialogProps = {
  isOpen: boolean;
  onClose: (value?: boolean, data?: any) => void;
};

const GroupNameAddModal = (props: MasterAddAndEditDialogProps) => {
  const { isOpen, onClose } = props;
  const dispatch = useDispatch();
  const [masterData, setMasterData] = useState<{
    id: number | string;
    name: string;
    active: number;
  }>({ id: 0, name: "", active: 1 });
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setMasterData((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const handleClose = (isSave?: boolean, data?: any) => {
    setMasterData({ id: 0, name: "", active: 1 });
    onClose(isSave, data);
  };

  const createMasterData = () => {
    setLoading(true);
    createItemGroupMaster(masterData)
      .then((res: any) => {
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        handleClose(true, res.data);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log("err", err);
        if (err?.response?.data?.errors) {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: err?.response?.data?.errors,
            })
          );
        }
      });
  };

  const handleSave = () => {
    if (masterData.name) {
      createMasterData();
    }
  };

  return (
    <DialogWrapper
      open={isOpen}
      onClose={() => handleClose()}
      title={"Add"}
      maxWidth="xs"
      confirmText="Save"
      cancelText="Cancel"
      handleClick={handleSave}
      loading={loading}
    >
      <Grid container spacing={1}>
        <Grid item xl={12}>
          <TextField
            formControlStyle={{ width: "100%" }}
            value={masterData?.name || ""}
            fullWidth
            name="name"
            label="Name"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

export default GroupNameAddModal;
