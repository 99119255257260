import { useCallback, useEffect, useRef, useState } from "react";
import { Typography, Box, debounce, Menu, MenuItem } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable } from "../../../../components/shared";
import {
  DeleteIcon,
  EditIcon,
  ViewIcon,
  PaymentRupeeIcon,
  KebabMenuIcon,
} from "../../../../assets/icons";
import {
  Select,
  TextField,
  DatePicker,
  Sort,
  DeleteDialog,
} from "../../../../components/basic";
import { RouteUrls } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import { InfiniteScroller } from "../../../../components/basic";
import {
  getAllPatientsList,
  getBillingItemsList,
  getDepartmentList,
  getPatientBillList,
  deletePatientBillById,
} from "../../../../services/billingService";
import ListFooter from "./ListFooter";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { billStatusOptions } from "../../../../constants/dropdownOptions";
import { setCurrentPage } from "../../../../redux/slices/pagination";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";
import { handleFilterCounts } from "../../../../utils/GeneralUtils";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { Link } from "react-router-dom";
import {setFilterDataValues,setSortField,setHandleClearSearch} from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const BillsList = () => {
  const { patientsUrl, historyUrl } = RouteUrls;

  const { can } = PermissionUtils();
  const dispatch = useDispatch();


  const {billList,filterData, count ,sortField} = useSelector((state: RootState) => ({
    billList: state?.pagination?.billList,
    filterData: state?.pagination?.billList?.filterData,
    count: state?.pagination?.billList?.count,
    sortField : state?.pagination?.billList?.sortedField
  }));
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [patientBillList, setPatientBillList] = useState<any[]>([]);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const patientsPagination: any = useRef(1);
  const searchPatientsPagination = useRef({ search: "", page: 1 });
  const billsPagination: any = useRef(1);
  const searchBillsPagination = useRef({ search: "", page: 1 });
  const [isLoading, setIsLoading] = useState(false);
  const [patientsList, setPatientsList] = useState<any>([]);
  const [itemList, setItemList] = useState<any>([]);
  const [activeSort, setActiveSort] = useState({ field: "", order: "" });
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [billTermsOptions, setBillTermsOptions] = useState(false);
  const [isClearFilter, setIsClearFilter] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<{
    isOpen: boolean;
    selectedRow: any;
  }>({
    isOpen: false,
    selectedRow: null,
  });

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {

      return await getAllPatientsList({
        search: currentValue,
        page:searchPatientsPagination.current?.search === currentValue ? searchPatientsPagination.current?.page : 1,
        per_page: 10,
      }).then((result: any) => {
        let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
          return {
            value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
          };
        });
        const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
            !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
            )
        );
        setPatientsList(uniqueOptions);
        searchPatientsPagination.current.page = searchPatientsPagination.current?.search === currentValue ? searchPatientsPagination.current?.page + 1: 1;
        searchPatientsPagination.current.search = currentValue;
        const hasMore = result?.data?.total > uniqueOptions?.length;
        callback({
          options: uniqueOptions,
          hasMore,
        });
      });
        
      } catch (error) {

        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
        
      }
      
    },
    300
  );

  const getAllPatientsData = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page = patientsPagination.current === Math.ceil(prevOptions.length / 10)
            ? patientsPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination.current;
          patientsPagination.current = currentValue + 1;
          const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;

          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:",error);
      return {
        options:[] ,
        hasMore:false,
      };
    }
  };

  const debouncedGetAllItemList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {

        return await getBillingItemsList({
          search: currentValue,
          page:searchBillsPagination.current?.search === currentValue ? searchBillsPagination.current?.page : 1, 
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.result;
          const formattedSuppliersList = data.map((uniqueData: any) => {
            return { value: uniqueData.id, label: uniqueData.name };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );
          setItemList(uniqueOptions);
          searchBillsPagination.current.page = searchBillsPagination.current?.search === currentValue ? searchBillsPagination.current?.page + 1: 1;
          searchBillsPagination.current.search = currentValue;
          const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
        
      } catch (error) {

        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
        
      }
     
    },
    300
  );
  const getAllItemsData = async (currentValue?: any, prevOptions?: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllItemList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page = billsPagination.current === Math.ceil(prevOptions.length / 10)
            ? billsPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getBillingItemsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.result;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return { value: uniqueData.id, label: uniqueData.name };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: any) =>
              !prevOptions.some(
                (prevOption: any) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setItemList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = billsPagination.current;
          billsPagination.current = currentValue + 1;
          const hasMore = result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:",error);
      return {
        options:[] ,
        hasMore:false,
      };
    }
  };
  const getAllDepartmentList = async () => {
    await getDepartmentList()
      .then((res: any) => {
        if (res) {
          setDepartmentList(res.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllDepartmentList();
    if (filterData?.uhid) {

      setPatientsList([filterData?.uhid]);
    }
    if (filterData?.item) {

      setItemList([filterData?.item]);
    }
  }, []);

  useEffect(() => {
    if (commonVariables?.bill_payment_type) {
      setBillTermsOptions(commonVariables?.bill_payment_type);
    }
  }, [commonVariables]);

  const debouncedGetPatientBillList = useCallback(
    debounce((data: any) => {
      getPatientBillList(data)
        .then((res: any) => {
          setIsLoading(false);
          if (res.data) {
            const newPatientBillList = [...res.data?.result];

            if (can("billing_list_total")) {
              newPatientBillList.push({
                sum_balance: res.data?.sum_balance || "0.00",
                sum_net_total_amt: res.data?.sum_net_total_amt || "0.00",
                sum_paid_amt: res.data?.sum_paid_amt || "0.00",
              });
            }
            setPatientBillList(newPatientBillList);
            setPageCount(res.data.total);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }, 300),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    const data = {
      doctor_id: filterData.doctor_id,
      department: filterData.department,
      uhid: filterData.uhid?.value,
      bill_no: filterData.bill_no,
      item: filterData.item?.value,
      terms: filterData.terms?.toString(),
      status: filterData.status,
      from_date: filterData.from_date
        ? commonDateFormatter(filterData.from_date, "YYYY-MM-DD")
        : null,
      to_date: filterData.to_date
        ? commonDateFormatter(filterData.to_date, "YYYY-MM-DD")
        : null,
      search: filterData.search,
      column: sortField?.field,
      order: sortField?.order,
      page: billList?.page + 1,
      limit: billList?.pageSize,
    };

    debouncedGetPatientBillList(data);
  }, [billList, activeSort, filterData]);

  const {
    billingUrl,
    listUrl,
    createUrl,
    billDraftUrl,
    viewUrl,
    editUrl,
    paymentUrl,
  } = RouteUrls;

  const navigate = useNavigate();

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(setFilterDataValues({key:"billList",name:name,value:newValue.validatedValue[0]}));

      dispatch(setCurrentPage({ key: "billList", value: 0 }));
    }
  };
  const TableActions = ({ row }: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <Box>
        <KebabMenuIcon
          style={{ width: "15px", height: "15px", cursor: "pointer" }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event: any) => setAnchorEl(event.currentTarget)}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {!row.is_cancel && (
            <MenuItem
              onClick={() => {
                handleClose();
              }}
              sx={{
                "& .MuiPopover-paper .MuiMenu-paper": {
                  boxShadow: "0px 5px 10px 0px rgba(35, 35, 35, 0.08)",
                },
                paddingLeft: "5px !important",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                onClick={() => {
                  navigate(
                    `${paymentUrl}${createUrl}?patient=${row?.patient_id}&patient_name=${row.uhid} - ${row.name}&payee=${row?.payee}&bill_no=${row?.id}&type=patient_bill&sub_type=${row?.department}`
                  );
                }}
              >
                <PaymentRupeeIcon
                  style={{
                    margin: "0 10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#232323",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "10px",
                  }}
                >
                  Add Payment
                </Typography>
              </Box>
            </MenuItem>
          )}
          {!row.is_cancel && can("billing_edit") && (
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(`${billingUrl}${listUrl}/${row.id}`);
              }}
              sx={{
                "& .MuiPopover-paper .MuiMenu-paper": {
                  boxShadow: "0px 5px 10px 0px rgba(35, 35, 35, 0.08)",
                },
                paddingLeft: "5px !important",
              }}
            >
              <Box display="flex" alignItems="center" flexDirection="row">
                <EditIcon
                  style={{
                    margin: "0 10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#232323",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "10px",
                  }}
                >
                  Edit bill
                </Typography>
              </Box>
            </MenuItem>
          )}
          {can("billing_view") && (
            <MenuItem
              onClick={() => {
                handleClose();
                navigate(`${billingUrl}${viewUrl}/${row.id}`);
              }}
              sx={{
                "& .MuiPopover-paper .MuiMenu-paper": {
                  boxShadow: "0px 5px 10px 0px rgba(35, 35, 35, 0.08)",
                },
                paddingLeft: "5px !important",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                onClick={() => {
                  // navigate(`${billingUrl}${billingAddItemUrl}/${row.id}`)
                }}
              >
                <ViewIcon
                  style={{
                    margin: "0 10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#232323",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "10px",
                  }}
                >
                  View bill
                </Typography>
              </Box>
            </MenuItem>
          )}
          {can("billing_delete") && (
            <MenuItem
              onClick={() => {
                handleClose();
                setIsDeleteDialogOpen({ isOpen: true, selectedRow: row });
              }}
              sx={{
                "& .MuiPopover-paper .MuiMenu-paper": {
                  boxShadow: "0px 5px 10px 0px rgba(35, 35, 35, 0.08)",
                },
                paddingLeft: "5px !important",
              }}
            >
              <Box display="flex" alignItems="center" flexDirection="row">
                <DeleteIcon
                  style={{
                    margin: "0 10px",
                  }}
                />
                <Typography
                  style={{
                    color: "#232323",
                    fontSize: "13px",
                    fontWeight: 400,
                    lineHeight: "10px",
                  }}
                >
                  Delete Bill
                </Typography>
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>
    );
  };

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (isFieldSort) {
      dispatch(setSortField({key:"billList", value:{field: field, order:"ASC" }}));

    }
    if (!isFieldSort) {
      dispatch(setSortField({key:"billList", value:{field: field, order:"DESC" }}));

    }
  };

  const columns: GridColDef[] = [
    {
      field: "bill-no",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("bill_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill-No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "bill_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "bill_no" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.bill_no}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "uhid",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("uhid")}
        >
          <Typography variant="h5" fontSize={14}>
            UHID
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "uhid" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "uhid" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Link
          to={`${patientsUrl}${historyUrl}/${row?.patient_id}`}
          style={{
            width: "100%",
            height: "100%",
            textDecoration: "none",
            cursor: "pointer",
            color: "inherit",
            outline: "none",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            {row?.uhid}
          </Typography>
        </Link>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Patient Name
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "doctor",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("doctor_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Doctor
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "doctor_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "doctor_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          textAlign={row.sum_net_total_amt ? "right" : "unset"}
          width={row.sum_net_total_amt ? "100%" : "auto"}
          fontWeight={row.sum_net_total_amt ? "bold !important" : 500}
        >
          {row.sum_net_total_amt ? "Total:" : row.doctor_name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "net_total_amt",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("net_total_amt")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill Amt
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "net_total_amt" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "net_total_amt" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={row.sum_net_total_amt ? "bold !important" : 500}
        >
          {row.sum_net_total_amt
            ? Number(row.sum_net_total_amt)?.toFixed(2)
            : row.net_total_amt}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "paid_amt",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("paid_amt")}
        >
          <Typography variant="h5" fontSize={14}>
            Paid Amt
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "paid_amt" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "paid_amt" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={row.sum_paid_amt ? "bold !important" : 500}
        >
          {row.sum_paid_amt
            ? Number(row.sum_paid_amt)?.toFixed(2)
            : row.paid_amt}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "balance",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("balance")}
        >
          <Typography variant="h5" fontSize={14}>
            Balance
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "balance" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "balance" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography
          variant="h5"
          fontSize={14}
          fontWeight={row.sum_balance ? "bold !important" : 500}
        >
          {row.sum_balance ? Number(row.sum_balance)?.toFixed(2) : row.balance}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "Status",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("status")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "status" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "status" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.status}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "pay_type",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography variant="h5" fontSize={14}>
            Pay Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "type" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "type" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.type}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "bill_payment_type",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("bill_payment_type")}
        >
          <Typography variant="h5" fontSize={14}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "bill_payment_type" &&
              sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "bill_payment_type" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {!row.sum_balance
            ? Number(row.bill_payment_type) === 0
              ? "Pay now"
              : "Credit"
            : ""}
        </Typography>
      ),
      minWidth: 90,
      sortable: false,
    },
    {
      field: "bill_date",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("date_created")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "date_created" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "date_created" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.date_created}
        </Typography>
      ),
      minWidth: 170,
      sortable: false,
    },
    {
      field: "user",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_user" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_user" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.created_user}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    ...(can("billing_edit") || can("billing_view") || can("billing_delete")
      ? [
          {
            field: "actions",
            flex: 1,
            renderHeader: () => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  height: "51px",
                  cursor: "pointer",
                }}
              >
                <Typography variant="h5" fontSize={14}>
                  Actions
                </Typography>
              </div>
            ),
            renderCell: ({ row }: any) => {
              if (row.sum_net_total_amt) {
                return null;
              }
              return <TableActions row={row} />;
            },
            minWidth: 80,
            sortable: false,
          },
        ]
      : []),
  ];
  const clearFilters = () => {
    if (
      filterData.from_date ||
      filterData.to_date ||
      filterData.bill_no ||
      filterData.doctor_id ||
      filterData.uhid ||
      filterData.item ||
      filterData.terms ||
      filterData.status ||
      filterData.department
    ) {
    
    dispatch(setFilterDataValues({key:"billList",name:"clearFilter",value:    {
      doctor_id: "",
      department: "",
      uhid: "",
      bill_no: "",
      from_date: new Date(),
      to_date: new Date(),
      item: "",
      terms: "",
      status: "",
      search: "",
    }}));

      setIsClearFilter(true);
    }
  };
  const handleInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;

      if (name === "uhid" && value) {

        const finalValue = patientsList.filter((obj: any) => {
          if (obj.value === value) {
            return obj;
  
          }
        });
        dispatch(setFilterDataValues({
          key: "billList",
          name: name,
          value: finalValue[0],
        }));
  
        return;
  
      }

      if(name === "item" && value){

        console.log("value",value,itemList);

        const finalValueItems = itemList.filter((obj: any) => {

          if (obj.value === value) {

            return obj;
  
          }
        });
        dispatch(setFilterDataValues({
          key: "billList",
          name: name,
          value: finalValueItems[0],
        }));
  
        return;

      }

    dispatch(setFilterDataValues({key:"billList",name:name,value:value}));


      dispatch(setCurrentPage({ key: "billList", value: 0 }));
    }
  };

  const handleDeleteConfirm = () => {
    if (isDeleteDialogOpen?.selectedRow?.id) {
      deletePatientBillById(isDeleteDialogOpen?.selectedRow?.id)
        .then((res: any) => {
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
          const data = {
            doctor_id: filterData.doctor_id,
            department: filterData.department,
            uhid: filterData.uhid?.value,
            bill_no: filterData.bill_no,
            item: filterData.item,
            terms: filterData.terms,
            status: filterData.status,
            from_date: filterData.from_date
              ? commonDateFormatter(filterData.from_date, "DD-MM-YYYY")
              : null,
            to_date: filterData.to_date
              ? commonDateFormatter(filterData.to_date, "DD-MM-YYYY")
              : null,
            search: filterData.search,
            column: sortField?.field,
            order: sortField?.order,
            page: billList?.page + 1,
            limit: billList?.pageSize,
          };
          setIsLoading(true);
          debouncedGetPatientBillList(data);
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null });
        })
        .catch((err) => {
          if (err?.response?.data?.errors) {
            const errorData = err?.response?.data?.error_data
              ? Object.keys(err?.response?.data?.error_data).join(", ")
              : "";
            dispatch(
              setSnackBarFailed({
                snackBarMessage: `${err?.response?.data?.errors}${errorData}`,
              })
            );
          }
        });
    }
  };

  const billListFilter = [
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <InfiniteScroller
          loadOptions={getAllPatientsData}
          options={patientsList}
          handleOnChange={handleInputChange}
          name={"uhid"}
          label={"Select Patient"}
          value={filterData.uhid?.value}
          width="100%"
          isClearFilter={isClearFilter}
          setIsClearFilter={setIsClearFilter}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <InfiniteScroller
          loadOptions={getAllItemsData}
          options={itemList}
          handleOnChange={handleInputChange}
          name={"item"}
          label={"Select Items"}
          value={filterData.item?.value}
          width="100%"
          isClearFilter={isClearFilter}
          setIsClearFilter={setIsClearFilter}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Select
          value={filterData.department}
          placeholder="Select Department"
          options={departmentList || []}
          label={"Select Department"}
          name="department"
          width=""
          onChange={handleInputChange}
          formControlStyle={{ width: "100%", paddingBottom: "2px" }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Select
          value={filterData.terms}
          placeholder="Select Terms"
          options={billTermsOptions || []}
          label={"Select Terms"}
          name="terms"
          width=""
          onChange={handleInputChange}
          formControlStyle={{ width: "100%", paddingBottom: "2px" }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <Select
          value={filterData.status}
          placeholder="Status"
          options={billStatusOptions || []}
          label={"Status"}
          name="status"
          width=""
          onChange={handleInputChange}
          formControlStyle={{ width: "100%", paddingBottom: "2px" }}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <TextField
          value={filterData.bill_no}
          placeholder={"Bill No"}
          label={"Bill No"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"bill_no"}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <DatePicker
          label="From Date"
          name="fromDate"
          placeholder="Select Date"
          width="100%"
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "from_date")
          }
          formControlStyle={{ width: "90%" }}
          format="DD/MM/YYYY"
          value={filterData.from_date}
          maxDate={new Date()}
        />
      ),
    },
    {
      gridProps: { xs: 12, sm: 6, md: 3, lg: 2, xl: 2 },
      children: (
        <DatePicker
          label="To Date"
          name="toDate"
          placeholder="Select Date"
          width="100%"
          onChange={(e: any, newValue: any) =>
            handleDateChange(e, newValue, "to_date")
          }
          formControlStyle={{ width: "90%" }}
          format="DD/MM/YYYY"
          maxDate={new Date()}
          value={filterData.to_date}
        />
      ),
    },
  ];

  return (
    <Box>
      <CustomFilter
        data={billListFilter}
        editButtonTitle={can("billing_add") ? "+ Add" : null}
        addButtonTitle="Bill Draft"
        isFilterEnabled={true}
        isSearchEnabled={true}
        onUpdateButtonClick={() => navigate(`${billingUrl}${createUrl}`)}
        appliedFilterCount={count}
        clearFilter={clearFilters}
        searchName="search"
        searchValue={filterData.search}
        searchOnChange={handleInputChange}
        onAddButtonClick={() => navigate(`${billingUrl}${billDraftUrl}`)}
        clearSearch={() =>   dispatch(setHandleClearSearch({key:"billList",value:"",name:"search"})) }
      />
      <DataTable
        loading={isLoading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={patientBillList}
        pageCount={pageCount}
        currentPage={"billList"}
      />
      <ListFooter />
      <DeleteDialog
        open={isDeleteDialogOpen.isOpen}
        onClose={() =>
          setIsDeleteDialogOpen({ isOpen: false, selectedRow: null })
        }
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  );
};

export default BillsList;
