import {
  TableCell,
  TableRow,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { TextField } from "../../../../components/basic";
import { DeleteIcon } from "../../../../assets/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Can } from "../../../../utils/PermissionUtils";

type ItemRowProps = {
  index: number;
  columns: any;
  row: any;
  error?: any;
  onChange: (event: any, index: number) => void;
  setIsOpenConfirmModal?: any;
  hasDelete?: boolean;
};

const styles = {
  serialNoStyle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "11px",
    textAlign: "center",
    letterSpacing: "0px",
  },
  optionStyle: {
    width: "125px",
    fontSize: "13px",
    fontWeight: "600",
    color: "textPrimary.main",
    textAlign: "left",
    pl: "5%",
  },
  selectFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
  textFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "0px 5px",
      fontSize: "12px",
      fontWeight: "400",
      textAlign: "left",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  amountColumnStyle: {
    "& .MuiInputBase-input": {
      fontFamily: "Inter",
      fontSize: "12px !important",
      fontWeight: "600 !important",
      lineHeight: "11px",
      textAlign: "center",
      letterSpacing: "0px",
      "-webkit-text-fill-color": "unset !important",
      color: "#000",
    },
  },
};

const ItemRow = (props: ItemRowProps) => {
  const {
    index,
    columns,
    row,
    error,
    onChange,
    setIsOpenConfirmModal,
    hasDelete,
  } = props;
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [productStatus, setProductStatus] = useState([]);

  const getStatus = (status: number | string) => {
    const currentStatus = productStatus?.find(
      (ele: any) => ele?.id === status
    ) as any;

    return currentStatus?.value || "";
  };

  useEffect(() => {
    if (commonVariables?.purchase_order_product_status) {
      setProductStatus(commonVariables?.purchase_order_product_status);
    }
  }, [commonVariables]);

  return (
    <TableRow>
      <TableCell
        sx={{
          borderBottomColor: "var(--table-border)",
          width: `${columns[0].width}%`,
        }}
      >
        <Typography sx={styles.serialNoStyle}>{index + 1}</Typography>
      </TableCell>
      <TableCell
        sx={{
          width: `${columns[1].width}%`,
          background: "#fff",
        }}
      >
        <Typography color={"#9E9E9E"} ml="10px">
          {row?.product?.name}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: `${columns[2].width}%` }}>
        <Typography p="10px" color={"#9E9E9E"}>
          {row?.order_qty}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          width: `${columns[3].width}%`,
          border: !!error?.ptr ? "2px solid #d32f2f !important" : "",
        }}
      >
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": { height: "100%" },
            "& .MuiTextField-root": { height: "100%" },
            "& .MuiInputBase-root": { height: "100%" },
          }}
        >
          <TextField
            type="number"
            fullWidth
            formControlStyle={{
              width: "100%",
              height: "100%",
              marginLeft: "15px",
            }}
            value={row?.ptr || 0}
            sx={{
              ...styles.textFieldStyle,
              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            name="ptr"
            onChange={(event: any) => onChange(event, index)}
          />
        </Box>
      </TableCell>
      <TableCell
        sx={{
          "&.MuiTableCell-body:has(.Mui-focused)": {
            border: "#FFFFFF !important",
          },
          width: `${columns[4].width}%`,
        }}
      >
        <Box
          sx={{
            height: "100%",
            "& .MuiGrid-root": { height: "100%" },
            "& .MuiTextField-root": { height: "100%" },
            "& .MuiInputBase-root": { height: "100%" },
          }}
        >
          <TextField
            type="number"
            fullWidth
            disabled
            formControlStyle={{
              width: "100%",
              height: "100%",
              marginLeft: "15px",
            }}
            value={row?.value || 0}
            sx={{
              ...styles.textFieldStyle,
              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            name="value"
          />
        </Box>
      </TableCell>
      <TableCell sx={{ width: `${columns[5].width}%` }}>
        <Typography p="10px" color={"#9E9E9E"}>
          {row?.received_qty}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: `${columns[5].width}%` }}>
        <Typography p="10px" color={"#9E9E9E"}>
          {getStatus(row?.status)}
        </Typography>
      </TableCell>
      {Can("purchase_order_delete") && (
        <TableCell align="center">
          <IconButton
            aria-label=""
            onClick={
              hasDelete
                ? () => setIsOpenConfirmModal({ isOpen: true, data: row })
                : undefined
            }
            sx={{
              opacity: hasDelete ? 1 : 0.2,
              cursor: hasDelete ? "pointer" : "unset",
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
};

export default ItemRow;
