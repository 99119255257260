import { Box, List, Popper, Typography } from "@mui/material";
import { ConfirmationDialog, DialogWrapper } from "../../../components/shared";
import CommonEditableTable from "./CommonEditableDataTable";
import { AutoCompleteWithTable, Select } from "../../../components/basic";
import { useEffect, useState } from "react";
import {
  cancelLabReportItemById,
  deleteLabReportItemById,
  getLaboratoryProductOptions,
} from "../../../services/laboratoryService";
import { chemicalReagentsTestTypesOptions } from "../../../constants/displayText";
import { DeleteIcon, ProhibitionIcon } from "../../../assets/icons";
import { setSnackBarSuccess } from "../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";

type ChemicalReagentModalProps = {
  isOpen: boolean;
  row: any;
  index: any;
  isEdit?: boolean;
  isView?: boolean;
  onClose?: () => void;
  onSave?: (data: any, index: number) => void;
  onProductsChange?: (data: any, index: number) => void;
};

const styles = {
  headerStyle: {
    color: "#000",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "15px",
    borderBottom: "1px solid var(--table-border)",
    borderRight: "1px solid var(--table-border)",
    padding: "12px 12px 12px 20px",
    marginLeft: "8px",
  },
  optionStyle: {
    width: "50%",
    fontSize: "13px",
    fontWeight: "600",
    color: "textPrimary.main",
    textAlign: "left",
    p: "0px 10px",
  },
  selectFieldStyle: {
    "&>*": {
      border: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
};

const ChemicalReagentModal = (props: ChemicalReagentModalProps) => {
  const {
    isOpen,
    row,
    index,
    isEdit,
    isView,
    onClose,
    onSave,
    onProductsChange,
  } = props;
  const dispatch = useDispatch();

  const [chemicalReagents, setChemicalReagents] = useState<any>([
    {
      product_id: null,
      batch_no: "",
      qty: "",
      is_retest: "",
    },
  ]);
  const [laboratoryProductOptions, setLaboratoryProductOptions] = useState([]);
  const [isOpenDelete, setIsOpenDelete] = useState<{
    isOpen: boolean;
    index: any | null;
  }>({
    isOpen: false,
    index: null,
  });
  const [isOpenCancel, setIsOpenCancel] = useState<{
    isOpen: boolean;
    row: any;
  }>({
    isOpen: false,
    row: null,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);

  const PopperComponent = (props: any) => {
    const { children, ...rest } = props;

    return (
      <Popper
        {...rest}
        placement="bottom-start"
        className="auto-complete-popper"
        sx={{
          width: "700px",
          p: "10px 0px",
          boxSizing: "border-box",
          "& .MuiPaper-root": {
            width: "700px",
            maxHeight: "250px",
            overflow: "hidden",
            borderRadius: "0px 0px 5px 5px",
            boxSizing: "border-box",
          },
          "& .MuiAutocomplete-listbox": {
            overflowX: "hidden",
            maxHeight: "250px",
            p: 0,
          },
        }}
      >
        <Box
          sx={{
            width: "700px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "var(--table-autocomplete-background)",
            p: "0px 10px 0px 0px",
            height: "45px",
            boxSizing: "border-box",
            borderRadius: "5px 5px 0px 0px",
          }}
        >
          <Typography sx={{ ...styles.optionStyle, width: "50%" }}>
            Product Name
          </Typography>
          <Typography sx={{ ...styles.optionStyle, width: "25%" }}>
            Batch No
          </Typography>
          <Typography sx={{ ...styles.optionStyle, width: "25%" }}>
            Quantity
          </Typography>
        </Box>
        {children}
      </Popper>
    );
  };

  const handleSelectProduct = (event: any, newValue: any, index: number) => {
    setChemicalReagents((prevState: any) => {
      const newState = [...prevState];
      newState[index].product_id = newValue;
      newState[index].product = newValue?.id;
      newState[index].batch_no = newValue?.batch_no;
      newState[index].batch_id = newValue?.batch_id;
      newState[index].qty = newValue?.stock_qty;
      return newState;
    });
  };

  const handleSelect = (event: any, index: number) => {
    const { name, value } = event.target;
    setChemicalReagents((prevState: any) => {
      const newState = [...prevState];

      if (newState[index]) {
        newState[index][name] = value;
      }

      return newState;
    });
  };

  const handleConfirmDelete = () => {
    setIsDeleteLoading(true);
    if (isOpenDelete?.index && chemicalReagents[isOpenDelete?.index]?.id) {
      deleteLabReportItemById(chemicalReagents[index]?.id)
        .then(() => {
          setChemicalReagents((prevState: any) => {
            const newState = [...prevState];
            newState.splice(isOpenDelete.index, 1);
            if (onProductsChange) {
              onProductsChange(newState, index);
            }
            return newState;
          });
          setIsDeleteLoading(false);
          dispatch(
            setSnackBarSuccess({ snackBarMessage: "Item deleted successfully" })
          );
          setIsOpenDelete({ isOpen: false, index: null });
        })
        .catch((err) => {
          console.log("err", err);
          setIsDeleteLoading(false);
        });
    } else {
      setChemicalReagents((prevState: any) => {
        const newState = [...prevState];
        newState.splice(isOpenDelete.index, 1);
        return newState;
      });
      setIsDeleteLoading(false);
      setIsOpenDelete({ isOpen: false, index: null });
    }
  };

  const handleConfirmCancel = () => {
    setIsCancelLoading(true);
    cancelLabReportItemById(isOpenCancel?.row?.id)
      .then(() => {
        setChemicalReagents((prevState: any) => {
          if (isOpenCancel.row !== null) {
            const newState = prevState?.map((ele: any) => {
              if (ele.id === isOpenCancel?.row?.id) {
                return {
                  ...ele,
                  is_cancel: 1,
                };
              }
              return ele;
            });

            if (onProductsChange) {
              onProductsChange(newState, index);
            }
            return newState;
          }
          return prevState;
        });
        setIsCancelLoading(false);
        dispatch(
          setSnackBarSuccess({ snackBarMessage: "Item cancelled successfully" })
        );
        setIsOpenCancel({ isOpen: false, row: null });
      })
      .catch((err) => {
        console.log("err", err);
        setIsCancelLoading(false);
      });
  };

  const columns = {
    headers: [
      {
        header: "Chemical Regent",
        textAlignment: "left",
        width: 15,
        name: "product_id",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <AutoCompleteWithTable
            placeholder="Select Product"
            name="product_id"
            value={row?.product_id || null}
            onChange={(event: any, newValue: any) =>
              handleSelectProduct(event, newValue, index)
            }
            options={laboratoryProductOptions}
            getOptionLabel={(option: any) => option.product_name}
            renderOption={(props: any, option: any) => {
              return (
                <List
                  {...props}
                  key={option.ID}
                  className="table-body-lists-div"
                  sx={{
                    width: "700px",
                    borderRadius: 0,
                    boxSizing: "border-box",
                    m: "0px",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "primary.lighter",
                    },
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="700px"
                    className="list-row"
                    sx={{
                      boxSizing: "border-box",
                      m: "0px",
                      p: "0px 10px 0px 0px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        width: "50%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option?.product_name}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "25%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option?.batch_no}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        width: "25%",
                        textAlign: "left",
                        p: "0px 10px",
                      }}
                    >
                      {option?.stock_qty}
                    </Typography>
                  </Box>
                </List>
              );
            }}
            sx={{
              width: "100%",
              height: "100%",
              "& .MuiFormControl-root": {
                height: "100%",
              },
              "& .MuiInputBase-root": {
                height: "100%",
              },

              "& .MuiOutlinedInput-notchedOutline": {
                border: 0,
              },
              "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  border: 0,
                },
              "& .MuiInputBase-root .MuiInputBase-input": {
                padding: 0,
                fontSize: "12px",
                fontWeight: "400",
              },
              "&:hover .MuiAutocomplete-popupIndicator": {
                display: "inline-flex",
              },

              "& fieldset": {
                height: "100%",
              },
              "& input": {
                height: "100%",
              },
            }}
            isDisabled={row.is_cancel}
            PopperComponent={PopperComponent}
          />
        ),
      },
      {
        header: "Batch No",
        textAlignment: "left",
        width: 15,
        name: "batch_no",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box
            sx={{
              background: "#F6F6F6",
              height: "100%",
              opacity: row.is_cancel ? 0.5 : 1,
            }}
          >
            <Typography sx={{ padding: "12px 12px 12px 20px" }}>
              {row.batch_no}
            </Typography>
          </Box>
        ),
      },
      {
        header: "Stock Qty",
        textAlignment: "left",
        width: 15,
        name: "qty",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box
            sx={{
              background: "#F6F6F6",
              height: "100%",
              opacity: row.is_cancel ? 0.5 : 1,
            }}
          >
            <Typography sx={{ padding: "12px 12px 12px 20px" }}>
              {row.qty}
            </Typography>
          </Box>
        ),
      },
      {
        header: "Test Type",
        textAlignment: "left",
        width: 15,
        name: "is_retest",
        headerStyle: styles.headerStyle,
        field: ({ row, index }: { row: any; index: number }) => (
          <Box>
            <Select
              formControlStyle={{
                width: "100%",
                marginLeft: "5px",
                "& .MuiInputBase-formControl": {
                  boxShadow: "unset",
                },
              }}
              value={row.is_retest}
              label=""
              width=""
              options={chemicalReagentsTestTypesOptions || []}
              sx={{
                ...styles.selectFieldStyle,
                "&:hover .MuiSelect-icon": {
                  display: "inline-flex",
                },
              }}
              menuStyle={{
                marginLeft: "-5px",
              }}
              name="is_retest"
              onChange={(event: any) => handleSelect(event, index)}
              disabled={row.is_cancel}
            />
          </Box>
        ),
      },
      ...(isView
        ? []
        : [
            {
              header: "",
              textAlignment: "left",
              width: 10,
              name: "",
              headerStyle: styles.headerStyle,
              field: ({ row, index }: { row: any; index: number }) => (
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {isEdit && row?.id && (
                    <ProhibitionIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        cursor:
                          chemicalReagents.length > 1 && !row.is_cancel
                            ? "pointer"
                            : "unset",
                        marginRight: "10px",
                        opacity:
                          chemicalReagents.length > 1 && !row.is_cancel
                            ? 1
                            : 0.5,
                      }}
                      onClick={() =>
                        chemicalReagents?.length > 1 && !row.is_cancel
                          ? setIsOpenCancel({ isOpen: true, row })
                          : undefined
                      }
                    />
                  )}
                  <DeleteIcon
                    style={{
                      cursor:
                        chemicalReagents.length > 1 && !row.is_cancel
                          ? "pointer"
                          : "unset",
                      opacity:
                        chemicalReagents.length > 1 && !row.is_cancel ? 1 : 0.5,
                    }}
                    onClick={() =>
                      chemicalReagents?.length > 1 && !row.is_cancel
                        ? setIsOpenDelete({ isOpen: true, index })
                        : undefined
                    }
                  />
                </Box>
              ),
            },
          ]),
    ],
  };

  const handleAddItem = () => {
    setChemicalReagents((prevState: any) => {
      const newState = [...prevState];
      const newItem: any = {
        product_id: null,
        batch_no: "",
        qty: "",
        is_retest: "",
        product_test_id: row?.id,
      };

      if (isEdit) {
        row.product_test_id = row?.lab_test_id;
        newItem.sales_id = null;
        newItem.lab_test_id = row.lab_test_id;
      }
      return [...newState, newItem];
    });
  };

  useEffect(() => {
    getLaboratoryProductOptions({
      search: "",
      test_id: isEdit ? row.lab_test_id : row.id,
    })
      .then((res: any) => {
        if (res.data) {
          setLaboratoryProductOptions(res.data);
        }
      })
      .catch((err) => console.log(err));

    setChemicalReagents(
      row?.product || [
        {
          product_id: null,
          batch_no: "",
          qty: "",
          is_retest: "",
        },
      ]
    );
  }, [row]);

  return (
    <DialogWrapper
      open={isOpen}
      onClose={onClose}
      handleClick={
        isView
          ? null
          : () => {
              if (onSave) {
                onSave(chemicalReagents, index);
              }
            }
      }
      title="Chemical Reagent"
      confirmText={isView ? "" : "Save"}
      cancelText={isView ? "" : "Cancel"}
    >
      <CommonEditableTable
        columns={columns}
        rows={chemicalReagents}
        tableWidth={"100%"}
        minTableWidth={"100%"}
      />
      {!isView && (
        <Typography
          sx={{
            padding: "10px",
            color: "#3A8DFF",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "26px",
            textAlign: "left",
            cursor: "pointer",
            width: "fit-content",
          }}
          onClick={handleAddItem}
        >
          + Add Item
        </Typography>
      )}
      <ConfirmationDialog
        open={isOpenDelete.isOpen}
        title="are you surely want to delete"
        handleClick={handleConfirmDelete}
        onClose={() => setIsOpenDelete({ isOpen: false, index: null })}
        loading={isDeleteLoading}
      />
      <ConfirmationDialog
        open={isOpenCancel.isOpen}
        title="are you surely want to cancel"
        handleClick={handleConfirmCancel}
        onClose={() => setIsOpenCancel({ isOpen: false, row: null })}
        loading={isCancelLoading}
      />
    </DialogWrapper>
  );
};

export default ChemicalReagentModal;
