import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { PrintingLightIcon } from "../../../../assets/icons";
import {
  Label,
  Select,
  SaveButton,
  PageLoader,
  CancelButton,
} from "../../../../components/basic";
import { DataTable } from "../../../../components/shared";
import Info from "./Info";
import Summary from "./Summary";
import { useNavigate, useParams } from "react-router-dom";
import { formula } from "../../../../utils/CalculationUtils";
import {
  cancelPharmacyBill,
  getPharmacyBillById,
  getPatientById,
} from "../../../../services/gynecologyService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { formatTwelveHoursTime } from "../../../../utils/DateTimeFormatUtils";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import PermissionUtils from "../../../../utils/PermissionUtils";
import { getExcessAmountByPatientId } from "../../../../services/pharmacyService";
import { getPrintUrl } from "../../../../utils/GeneralUtils";
import { RouteUrls } from "../../../../constants/routes";

const { printUrl } = RouteUrls;

const { calculateRoundedOff, calculateSubTaxes } = formula?.purchaseBill;

const BillItems = () => {
  const { id } = useParams();
  const { can } = PermissionUtils();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const billColumns: GridColDef[] = [
    {
      field: "product_name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Product Name"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6" sx={{ textWrap: "wrap" }}>
          {row.product_name == null ? "" : row.product_name}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "pack",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Pack"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.pack_type}</Typography>
      ),
      sortable: false,
    },
    {
      field: "batch_no",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Batch.No"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.batch_no}</Typography>
      ),
      sortable: false,
    },
    {
      field: "expires_at",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Exp.Date"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.expires_at == null
            ? ""
            : row.expires_at.split("-").reverse().join("/")}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "qty",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Qty"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.qty == null ? "" : row.qty}</Typography>
      ),
      sortable: false,
    },
    {
      field: "mrp",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"MRP/Unit"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.mrp == null ? "" : row.mrp}</Typography>
      ),
      sortable: false,
    },
    {
      field: "discount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Disc %"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.discount == null ? "" : row.discount}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "tax",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Tax %"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.tax_id == null ? "" : row.tax_id}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"amount"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.amount == null ? "" : row.amount}
        </Typography>
      ),
      sortable: false,
    },
  ];

  const paymentColumns: GridColDef[] = [
    {
      field: "receipt_no",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Receipt No."}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.receipt_no == null ? "" : row.receipt_no}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "payment_method",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Payment Method"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.payment_method == null ? "" : row.payment_method}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "date",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Date"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">{row.date == null ? "" : row.date}</Typography>
      ),
      sortable: false,
    },
    {
      field: "remarks",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Remarks"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.remarks == null ? "" : row.remarks}
        </Typography>
      ),
      sortable: false,
    },
    {
      field: "amount",
      flex: 1,
      renderHeader: () => (
        <Grid
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Label
            sx={{
              fontSize: "13px",
              fontWeight: "600",
              color: "textPrimary.main",
            }}
            label={"Amount"}
          />
        </Grid>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h6">
          {row.amount == null ? "" : row.amount}
        </Typography>
      ),
      sortable: false,
    },
  ];

  const initialInfo: any = {
    uhid: "",
    doctor: "",
    mobile: "",
    address: "",
    date: "",
    payee: "",
    paymentType: "",
    billNo: "",
    referredBy: "",
    type: "",
    OPNo: "",
    department: "",
  };

  const printOptions = [
    { id: "full", name: "A4" },
    { id: "half", name: "A5" },
    // { id: "dot_matrix", name: "Dot Matrix" },
  ];

  const [info, setInfo] = React.useState(initialInfo);

  const initialBillingSummary: any = {
    tax: "",
    total: "",
    billTotal: "",
    discount: "",
    roundedOff: "",
    adjustment: "",
    netAmount: "",
  };

  const [billingSummary, setBillingSummary] = React.useState(
    initialBillingSummary
  );
  const initialPaymentSummary: any = {
    excess: "",
    advance: "",
    overAllDue: "",
    creditLimit: "",
  };

  const [paymentSummary, setPaymentSummary] = React.useState(
    initialPaymentSummary
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isBillCancelClicked, setIsBillCancelClicked] = useState(false);
  const [isCancelBill, setIsCancelBill] = useState(false);
  const [billsList, setBillsList] = useState([]);
  const [paymentList, setPaymentList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const printMethod = useRef<any>([{ id: "", name: "", disabled: false }]);
  useEffect(() => {
    if (info.patient_id) {
      getPatientById(info.patient_id)
        .then((res: any) => {
          if (res.data) {
            const patientData = res.data;
            setInfo((prevState: any) => ({
              ...prevState,
              name: patientData.name,
              mobile: patientData.mobile,
              uhid: patientData.patient_id,
              gender: patientData.sex === 1 ? "Female" : "Male",
              address: `${
                patientData.line_1 === "-" ? "" : patientData.line_1 + "\n"
              }${patientData.line_2 === "-" ? "" : patientData.line_2 + "\n"}${
                patientData.area === "-" ? "" : patientData.area + "\n"
              }${patientData.district ? "" : patientData.district + "\n"}${
                patientData.state ? "" : patientData.state
              }\n${patientData.pincode ? "" : patientData.pincode}`,
            }));
          }
        })
        .catch((err) => console.log("error", err));
    }
  }, [info.patient_id]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getPharmacyBillById(id)
        .then((res: any) => {
          if (res.data) {
            const {
              bill,
              bill_items,
              is_return_field,
              payment,
              print_template,
            } = res.data;
            setIsCancelBill(
              Boolean(
                bill.is_cancel === 1 ||
                  bill.is_paid === 1 ||
                  is_return_field?.length > 0
              )
            );
            setInfo({
              ...bill,
              rdType: bill.ip_no ? "IP" : bill.op_no ? "OP" : "Counter sales",
            });
            const formattedProducts = bill_items.map((ele: any) => ({
              id: ele.id,
              product_name: ele.product_name,
              product_id: ele.product_id,
              pack_type: ele.pack_type,
              batch_no: ele.batch_no,
              expires_at: ele.expires_at
                ? ele.expires_at.split("-").reverse().join("/")
                : "",
              qty: ele.qty,
              mrp: ele.mrp / ele.pack_type,
              discount: ele.discount,
              tax: ele.tax_id,
              tax_id: `${ele.taxname}-${ele.tax_id}%`,
              amount: ele.amount,
              item_id: {
                id: ele.id,
                tax_name: ele.taxname,
                sub_taxes: ele.salessub_taxes,
                tax_id: ele.tax_id,
              },
            }));
            setBillsList(formattedProducts);

            const {
              CGST,
              SGST,
              bill_amount,
              goods_value,
              tax_amount,
              total_discount_amount,
            } = calculateBillData(formattedProducts);
            const roundedOff =
              calculateRoundedOff({
                bill_total: bill?.bill_amt,
                bill_discount_amount: bill?.discount_amt,
              }) || 0;
            setBillingSummary((prevState: any) => {
              const newState = { ...prevState };
              newState.cgst = CGST || 0;
              newState.sgst = SGST || 0;
              newState.total_discount_amount = total_discount_amount || 0;
              newState.tax_amount = tax_amount || 0;
              newState.bill_amt = bill.bill_amt || 0;
              newState.paid_amt = bill.net_total_amt || 0;
              newState.discount = Number(bill.discount_amt).toFixed(2) || 0;
              newState.adjustment = bill.adj_amt || 0;
              newState.roundedOff = roundedOff || 0;
              newState.disc_goods_val = goods_value || 0;
              newState.disc_val = bill.disc_val;
              return newState;
            });
            const paymentsList = payment?.map((ele: any) => ({
              receipt_no: ele.receipt_no,
              payment_method: ele.type,
              date: formatTwelveHoursTime(ele.created_at),
              remarks: ele.remarks,
              amount: ele.paid_amt,
            }));
            setPaymentList(paymentsList);
            if (bill.patient_id) {
              getExcessAmountByPatientId({ patient_id: bill.patient_id } as any)
                .then((res: any) => {
                  if (res.data) {
                    setPaymentSummary((prevState: any) => ({
                      advance: res?.data?.advance,
                      creditLimit: res?.data?.creditlimit,
                      excess: res?.data?.excess,
                      overAllDue: res?.data?.overalldue,
                      total_paid: bill.paid_amt,
                      balance: bill?.balance || "0.00",
                      refund: bill?.refund,
                      bill_user: bill?.user_name,
                    }));
                  }
                })
                .catch((err) => console.log("error", err));
            }
            printMethod.current = printOptions.map((ele) => ({
              ...ele,
              // disabled: !print_template.paper_size[ele.name],
            }));
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [id]);

  const handleCancelBill = async () => {
    setIsBillCancelClicked(true);
    await cancelPharmacyBill(id as string)
      .then((res: any) => {
        setIsBillCancelClicked(false);

        navigate("/pharmacy/sales-bill");
        dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
      })
      .catch((err) => {
        setIsBillCancelClicked(false);

        dispatch(
          setSnackBarFailed({ snackBarMessage: err.response.data.errors })
        );
        console.log(err);
      });
  };

  const calculateTaxValue = (data: any) => {
    let tax_value: number | string = 0;
    if (isNaN(data.amount) || isNaN(data.tax)) {
      tax_value = 0;
    } else {
      const tax = (Number(data.amount) / (1 + data.tax / 100)).toFixed(2);
      if (appConfiguration?.sales_tax === "exclusive") {
        tax_value = (Number(data.amount) * data.tax) / 100;
      } else {
        tax_value = ((data.tax / 100) * Number(tax)).toFixed(2);
      }
    }
    return tax_value;
  };
  const calculateDiscountAmount = (
    amount: number,
    discount: number,
    discount_type: number | string,
    isOnlyDiscount?: boolean
  ) => {
    if (amount && discount && discount_type) {
      if (discount_type === "₹") {
        return (Number(amount) - Number(discount))?.toFixed(2);
      } else {
        const discountAmount = ((discount / 100) * Number(amount)).toFixed(2);
        const discountedAmount = (
          Number(amount) - Number(discountAmount)
        )?.toFixed(2);
        return isOnlyDiscount ? discountAmount : discountedAmount;
      }
    }

    return 0;
  };
  const calculateProductAmount = (rows: any, name: string) => {
    const updatedValue = rows?.reduce(
      (acc: any, current: any) => Number(acc) + Number(current[name]),
      0
    );
    return updatedValue || 0;
  };
  const calculateGoodsValue = (
    totalAmount: number | string,
    totalTax: number | string
  ) => {
    let goodsValue: number | string = 0;
    if (appConfiguration?.sales_tax === "exclusive") {
      goodsValue = Number(totalAmount);
    } else {
      goodsValue = (Number(totalAmount) - Number(totalTax)).toFixed(2);
    }
    return goodsValue;
  };
  const calculateBillData = (allProducts: any) => {
    const newValues =
      allProducts?.length > 0 &&
      allProducts.map((ele: any) => {
        const newNum = ele.qty * ele.mrp;
        const newTaxValue = calculateTaxValue(ele);
        const newDiscountedAmount = calculateDiscountAmount(
          newNum || 0,
          ele?.discount || 0,
          "%",
          true
        );
        return {
          newNum: newNum,
          newTaxValue: newTaxValue,
          newDiscountedAmount: newDiscountedAmount,
          tax: {
            id: ele.item_id?.id,
            name: ele.tax,
            tax_name: ele.item_id?.tax_name,
            tax_rate: ele.item_id?.tax_id,
            sub_taxes: ele.item_id?.sub_taxes,
          },
          tax_amount: newTaxValue,
        };
      });
    const newAmount = calculateProductAmount(allProducts, "amount");
    const value = calculateSubTaxes(newValues) || 0;
    const newTaxAmount = calculateProductAmount(newValues, "newTaxValue");
    return {
      goods_value: calculateGoodsValue(newAmount, newTaxAmount) || 0,
      tax_amount: newTaxAmount,
      bill_amount: calculateProductAmount(newValues, "newNum"),
      CGST: value.CGST,
      SGST: value.SGST,
      total_discount_amount: calculateProductAmount(
        newValues,
        "newDiscountedAmount"
      ).toFixed(2),
    };
  };

  const handlePrintChange = (event: any) => {
    const { name, value } = event?.target;
    setBillingSummary((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    const pdfUrl = getPrintUrl(
      `pharmacy/print_sales_bill/${id}/${value}`
    );
    const queryParams = new URLSearchParams({
      url: pdfUrl,
    }).toString();
    window.open(`${printUrl}?${queryParams}`, "_blank");
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <Grid>
          <Info
            info={info}
            isTokenEnable={appConfiguration.token_appointment_enable}
          />
          <Grid sx={{ m: "20px 0px" }}>
            <DataTable
              columns={billColumns}
              rows={billsList}
              tableOnly={true}
              customizedTable={true}
            />
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "0px 0px 5px 5px",
                border: 1,
                borderColor: "var(--table-border)",
                borderTop: 0,
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: {
                    xs: "35%",
                  },
                }}
              >
                <Grid
                  sx={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid
                    sx={{
                      width: "60%",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      Discount Value :
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "600",
                      mr: "1%",
                      textAlign: "left",
                    }}
                  >
                    {Number(billingSummary?.disc_val).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  width: {
                    xs: "65%",
                  },
                }}
              >
                <Grid
                  sx={{
                    width: "20%",
                  }}
                >
                  <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                    Goods Value :
                  </Typography>
                </Grid>
                <Typography
                  sx={{ fontSize: "13px", fontWeight: "600", mr: "2%" }}
                >
                  {Number(billingSummary?.disc_goods_val || 0).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Summary billingSummary={billingSummary} />
          <Grid sx={{ display: "flex", flexDirection: "column", m: "20px 0" }}>
            <Typography
              variant="h3"
              sx={{
                color: "primary.main",
                m: "20px 0",
              }}
            >
              Payment Method
            </Typography>
            <DataTable
              columns={paymentColumns}
              rows={paymentList}
              getRowId={(row: any) => `${String(row.id)}`}
              tableOnly={true}
              customizedTable={true}
            />
          </Grid>
          <Summary paymentSummary={paymentSummary} />
          <Grid
            sx={{
              width: "40vw",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              gap: 2,
              m: "20px 0px",
            }}
          >
            <CancelButton
              buttonText="Back"
              sx={{ width: "100px", height: "42px" }}
              handleClick={() => navigate("/pharmacy/sales-bill")}
            />
            {!isCancelBill && can("salesbill_cancel") && (
              <SaveButton
                handleClick={() => handleCancelBill()}
                loading={isBillCancelClicked}
                buttonText="Cancel"
                sx={{
                  width: "100px",
                  height: "42px",
                  backgroundColor: "red",
                  "&:hover": {
                    backgroundColor: "red !important",
                  },
                }}
              />
            )}
            {can("print_sales_bill") && (
              <Select
                label="Select Print Size"
                placeholder={<PrintingLightIcon />}
                name="print"
                sx={{ width: "306px" }}
                width="306px"
                options={printMethod.current || []}
                value={billingSummary?.print}
                onChange={handlePrintChange}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BillItems;
