import { Box, Grid } from "@mui/material";
import Header from "./header";
import Nav from "./nav";
import { Outlet } from "react-router-dom";
import { setMobileOpen } from "../../redux/slices/layout";

import Toolbar from "@mui/material/Toolbar";

import { RootState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";

export default function ResponsiveDrawer({ navigateHook, locationHook }: any) {
  const mobileOpen = useSelector((state: RootState) => state.layout.mobileOpen);
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    dispatch(setMobileOpen(!mobileOpen));
  };

  return (
    <Grid className="/App" sx={{ m: { sm: "25px 5px 0 80px", xs: "5px" } }}>
      <Box sx={{ display: "flex" }}>
        <Header navigateHook={navigateHook} locationHook={locationHook} />
        <Nav
          handleDrawerToggle={handleDrawerToggle}
          mobileOpen={mobileOpen}
          navigateHook={navigateHook}
          locationHook={locationHook}
        />
        <Box
          sx={{
            flexGrow: 1,
            p: 1,
            position: "absolute",
          }}
        >
          <Toolbar />
        </Box>
      </Box>
      <Box sx={{ pt: 8 }}>
        <Outlet />
      </Box>
    </Grid>
  );
}
