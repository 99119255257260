import QueryParamsBuilder from "../helper/QueryParamsBuilder";
import {
  appointmentMastersUrl,
  appointmentGeneralUrl,
} from "../constants/apiEndPoints";
import ApiUtils from "../utils/ApiUtils";
import { AnyAaaaRecord } from "dns";

export type WorkingHoursTypes = {
  id: number;
  doctor_id: number;
  day_name: string;
  start_time: string;
  end_time: string;
  interval: number;
  doctor_name: string;
};

export type CreateWorkingHoursTypes = {
  doctor_id: number;
  day_name: string;
  start_time: string;
  end_time: string;
  interval: number;
};

const {
  workingHoursList,
  workingHoursCreate,
  workingHoursEdit,
  workingHoursUpdate,
  workingHoursDelete,
  vacationsList,
  vacationsCreate,
  vacationsEdit,
  vacationsUpdate,
  vacationsDelete,
  printNotesList,
  printNotesCreate,
  printNotesEdit,
  printNotesUpdate,
  printNotesDelete,
  consultationsList,
  consultationCreate,
  consultationEdit,
  consultationUpdate,
  consultationDelete,
  productsList,
  productCreate,
  productEdit,
  productUpdate,
  productDelete,
  symptomsMasterList,
  symptomsMasterCreate,
  symptomsMasterEdit,
  symptomsMasterUpdate,
  symptomsMasterDelete,
} = appointmentMastersUrl;

const { dosageFormList, doctorsList } = appointmentGeneralUrl;

const getWorkingHoursList = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{
  data: { result: []; totalRecords: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: workingHoursList,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue,
  });

  return (await ApiUtils.getWithToken(apiUrl)) as {
    data: { result: []; totalRecords: number | null };
  };
};

const createWorkingHours = async (
  workingHoursData: CreateWorkingHoursTypes[]
): Promise<{ data: WorkingHoursTypes }> => {
  return (await ApiUtils.postWithToken(
    workingHoursCreate,
    workingHoursData
  )) as { data: WorkingHoursTypes };
};

const editWorkingHours = async (id: number) => {
  return await ApiUtils.getWithToken(`${workingHoursEdit}/${id}`);
};

const updateWorkingHours = async (id: number, data: any) => {
  return await ApiUtils.putWithToken(`${workingHoursCreate}/${id}`, data);
};

const deleteWorkingHours = async (doctorId: number) => {
  return await ApiUtils.deleteWithToken(
    `${workingHoursDelete}/${doctorId}`
  );
};

const deleteDoctorsWorkingHoursById = async (WorkingHoursId: number | string) => {
  return await ApiUtils.deleteWithToken(
    `${workingHoursCreate}/${WorkingHoursId}`
  );
};

export type ConsultationTypes = {
  id: number;
  name: string;
  start: string;
  end: string;
  active: number;
};

export type CreateConsultationTypes = {
  name: string;
  start: string;
  end: string;
  active: number;
};

const getConsultationsList = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any) => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: consultationsList,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue,
  });
  return await ApiUtils.getWithToken(apiUrl);
};

const createConsultation = async (
  consultationData: CreateConsultationTypes
) => {
  return await ApiUtils.postWithToken(consultationCreate, consultationData);
};

const editConsultation = async (id: number) => {
  return await ApiUtils.getWithToken(`${consultationEdit}/${id}`);
};

const updateConsultation = async (id: number, consultationData: any) => {
  return await ApiUtils.putWithToken(
    `${consultationUpdate}/${id}`,
    consultationData
  );
};

const deleteConsultation = async (id: number) => {
  return await ApiUtils.deleteWithToken(`${consultationDelete}/${id}`);
};

export type ProductListTypes = {
  name: string;
  generic_name: string;
  id: number;
  prescription: string;
  dosage_form: string;
  active: number;
};

export type CreateProductListTypes = {
  name: string;
  generic_name: string;
  prescription: string;
  dosage_form: string;
  active: number;
};

const getProductsList = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{ data: ProductListTypes[] }> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: productsList,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue,
  });

  return await ApiUtils.getWithToken(apiUrl) as { data: ProductListTypes[] };
};

const createProduct = async (productListData: any): Promise<{ data: any }> => {
  return await ApiUtils.postWithToken(productCreate, productListData) as { data: any };
};

const editProduct = async (id: number) => {
  return await ApiUtils.getWithToken(`${productEdit}/${id}`);
};

const updateProduct = async (id: number, productListData: any) => {
  return await ApiUtils.putWithToken(`${productUpdate}/${id}`, productListData);
};

const deleteProduct = async (id: number) => {
  await ApiUtils.deleteWithToken(`${productDelete}/${id}`);
};

export type PrintNoteMasterTypes = {
  id: number;
  type: string;
  section: string;
  content: string;
};

export type SymptomsMasterTypes = {
  active: number;
  symptom_name: string | undefined;
  id: number;
};

export type CreateSymptomsMasterTypes = {
  active: number;
  symptom_name: string | undefined;
};

const getSymptomsList = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any) => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: symptomsMasterList,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue,
  });

  return await ApiUtils.getWithToken(apiUrl);
};

const createSymptom = async (symptomsMasterData: CreateSymptomsMasterTypes) => {
  return await ApiUtils.postWithToken(symptomsMasterCreate, symptomsMasterData);
};

const editSymptom = async (id: number) => {
  return await ApiUtils.getWithToken(`${symptomsMasterEdit}/${id}`);
};

const updateSymptom = async (id: number, data: any) => {
  return await ApiUtils.putWithToken(`${symptomsMasterUpdate}/${id}`, data);
};

const deleteSymptom = async (id: number) => {
  return await ApiUtils.deleteWithToken(`${symptomsMasterDelete}/${id}`);
};
const getAllVacations = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: vacationsList,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue,
  });
  return (await ApiUtils.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};

const createVacationsDetails = async (createVacationsData: {} | any) => {
  return await ApiUtils.postWithToken(vacationsCreate, createVacationsData);
};

const deleteVacationsDetails = async (VacationId: number) => {
  return await ApiUtils.deleteWithToken(`${vacationsDelete}/${VacationId}`);
};
const editVacationsDetails = async (VacationId: number) => {
  return await ApiUtils.getWithToken(`${vacationsEdit}/${VacationId}`);
};

const updateVacationsDetails = async (
  VacationId: number | string,
  data: any
) => {
  return await ApiUtils.putWithToken(`${vacationsUpdate}/${VacationId}`, data);
};

const getAllPrintNoteMaster = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: printNotesList,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue,
  });
  return (await ApiUtils.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};

const createPrintNoteMaster = async (printNoteMasterData: {} | any) => {
  return await ApiUtils.postWithToken(printNotesCreate, printNoteMasterData);
};
const editPrintNoteMaster = async (printNoteId: number) => {
  return await ApiUtils.getWithToken(`${printNotesEdit}/${printNoteId}`);
};

const updatePrintNoteMaster = async (
  data: {} | any,
  printNoteId: number | string
) => {
  return await ApiUtils.putWithToken(
    `${printNotesUpdate}/${printNoteId}`,
    data
  );
};

const deletePrintNoteMaster = async (printNoteId: number) => {
  return await ApiUtils.deleteWithToken(`${printNotesDelete}/${printNoteId}`);
};

// general services

const getDosageFormList = async () => {
  return await ApiUtils.getWithToken(dosageFormList);
};

const getDoctorsList = async () => {
  return await ApiUtils.getWithToken(doctorsList);
};

export {
  getWorkingHoursList,
  createWorkingHours,
  editWorkingHours,
  updateWorkingHours,
  deleteWorkingHours,
  deleteDoctorsWorkingHoursById,
  getConsultationsList,
  createConsultation,
  editConsultation,
  updateConsultation,
  deleteConsultation,
  getProductsList,
  createProduct,
  editProduct,
  updateProduct,
  deleteProduct,
  getAllPrintNoteMaster,
  createPrintNoteMaster,
  editPrintNoteMaster,
  updatePrintNoteMaster,
  deletePrintNoteMaster,
  getSymptomsList,
  createSymptom,
  editSymptom,
  updateSymptom,
  deleteSymptom,
  getAllVacations,
  createVacationsDetails,
  deleteVacationsDetails,
  editVacationsDetails,
  updateVacationsDetails,
  getDosageFormList,
  getDoctorsList,
};
