import {
  Select,
  ListItemText,
  FormControl,
  MenuItem,
  Checkbox,
  Chip,
  InputLabel,
} from "@mui/material";
import { CardCancelSmallIcon } from "../../../assets/icons";

const useStyles = {
  redBackground: {
    backgroundColor: "#C00",
    padding: 10,
  },
  whiteBackground: {
    backgroundColor: "#FFF",
  },
  formControl: {
    margin: 1,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: "#F4F4F4",
    fontSize: "14px",
    fontWeight: 500,
    borderRadius: "5px",
  },
  noLabel: {
    marginTop: 3,
  },
};

const MultipleSelectDemo = ({
  options,
  handleChange,
  handleDelete,
  value,
  label,
  sx,
}: any) => {
  return (
    <div>
      <FormControl sx={useStyles.formControl}>
        <InputLabel id="demo-mutiple-chip-checkbox-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          multiple
          value={value}
          label={label}
          sx={{
            "& .MuiSelect-multiple": { padding: value?.length > 0 ? 0 : "" },
            ...sx,
          }}
          onChange={handleChange}
          renderValue={(selected) => (
            <div style={useStyles.chips as any}>
              {(selected as string[]).map((value) => (
                <Chip
                  key={value}
                  label={
                    options?.find(
                      (ele: { value: string; id: number | string }) =>
                        ele?.id === value
                    )?.value
                  }
                  clickable
                  deleteIcon={
                    <CardCancelSmallIcon
                      onMouseDown={(event) => event.stopPropagation()}
                      style={{
                        width: "7px !important",
                        height: "7px !important",
                      }}
                    />
                  }
                  sx={useStyles.chip}
                  onDelete={(e) => handleDelete(e, value)}
                  onClick={() => console.log("clicked chip")}
                />
              ))}
            </div>
          )}
        >
          {options.map((name: any, index: number) => (
            <MenuItem key={index} value={name?.id}>
              <Checkbox checked={value?.includes(name?.id)} />
              <ListItemText primary={name?.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelectDemo;
