import { Box, Grid, Typography, debounce } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AutoComplete,
  DatePicker,
  Radio,
  Select,
  TextField,
} from "../../../../components/basic";
import { EditIcon, PlusIcon, SettingsIcon } from "../../../../assets/icons";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Card, DialogWrapper } from "../../../../components/shared";
import { SequenceWrapper } from "../../../../components/shared";
import AddReferralDoctorModal from "./AddReferralDoctorModal";
import { InfiniteScroller } from "../../../../components/basic";
import {
  getAllPatientsList,
  getPayeeList,
  addPayee,
  getPaymentTypes,
  getReferralDoctorsList,
  addReferralDoctor,
  getIpList,
  getOpList,
  getDepartments,
  getBillTermsOptions,
} from "../../../../services/billingService";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";

import {
  setSnackBarSuccess,
  setSnackBarFailed,
} from "../../../../redux/slices/snackbar";

type HeaderFormProps = {
  data: any;
  consultantList: any;
  onChange: (event: any) => void;
  onEditPatient: (value: any) => void;
  onCreatePatient: () => void;
  error?: any;
  billCategoryOptions?: any;
  departmentOptions?: any;
  setSequenceDialogBox: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirmSequenceNoChange: () => void;
  setDepartmentOptions: React.Dispatch<React.SetStateAction<any>>;
  initialPatientList?: any;
};

type CommonLabelAndComponentProps = {
  label: string;
  children: React.ReactNode;
  isEdit?: boolean;
};

type AddPayeeModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onSave: (Value: any) => void;
};

type SequenceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (Value: any) => void;
};

const styles = {
  titleBoxStyle: {
    border: "1px solid #EFEFEF",
    padding: "20px 20px",
    borderRadius: "5px 5px 0px 0px",
  },
  boxTitleStyle: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "15px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "var(--primary-main)",
  },
  labelStyle: {
    color: "#232323",
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    textTransform: "capitalize",
  },
  cardStyle: {
    borderRadius: "0px 0px 5px 5px",
    border: "1px solid #EFEFEF",
    background: "#FFF",
    boxShadow: "none",
    padding: "10px",
    borderTop: "unset",
  },
  iconBoxStyle: {
    width: "20px",
    height: "20px",
    borderRadius: " 5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F9F9F9",
    cursor: "pointer",
    "& g": {
      stroke: "#232323",
    },
    "& rect": {
      fill: "#F9F9F9",
    },
    "& path": {
      fill: "#232323",
    },
  },
  valueStyle: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "var(--gray-main)",
  },
};

const AddPayeeModal = (props: AddPayeeModalProps) => {
  const { isOpen, isLoading, onClose, onSave } = props;
  const [data, setData] = useState({ name: "", credit_limit: "" });

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    if (data.name && data.credit_limit) {
      await onSave(data);
      setData({ name: "", credit_limit: "" });
    } else {
      onClose();
    }
    setData({ name: "", credit_limit: "" });
  };
  return (
    <DialogWrapper
      open={isOpen}
      onClose={() => {
        setData({ name: "", credit_limit: "" });
        onClose();
      }}
      handleClick={() => {
        handleSave();
      }}
      title="Add Payee"
      confirmText="Save"
      cancelText="Cancel"
      maxWidth="xs"
      loading={isLoading}
    >
      <Grid container rowSpacing={2}>
        <Grid item xl={12}>
          <TextField
            label="Name"
            value={data.name}
            fullWidth
            formControlStyle={{
              width: "100%",
            }}
            sx={{
              "& .MuiInputBase-input": {
                height: "100%",
              },
            }}
            name="name"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xl={12}>
          <TextField
            label="Credit Limit"
            value={data.credit_limit}
            fullWidth
            formControlStyle={{
              width: "100%",
            }}
            sx={{
              "& .MuiInputBase-input": {
                height: "100%",
              },
            }}
            name="credit_limit"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </DialogWrapper>
  );
};

const CommonLabelAndComponent = (props: CommonLabelAndComponentProps) => {
  const { label, children, isEdit } = props;

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      alignItems={"center"}
      pt={1}
      height={isEdit ? "57px" : "unset"}
    >
      <Grid container columns={12} alignItems={"center"}>
        <Grid item xs={3.5} sm={3.5} md={3.5}>
          <Box display={"flex"}>
            <Typography width={"95%"} sx={styles.labelStyle}>
              {label}
            </Typography>
            {isEdit && (
              <Typography
                width={"5%"}
                sx={styles.labelStyle}
                mr={2}
                textAlign={"left"}
              >
                :
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={8.5} sm={8.5} md={8.5}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

const HeaderForm = (props: HeaderFormProps) => {
  const { id } = useParams();
  const { appConfiguration } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const {
    data,
    consultantList,
    error,
    billCategoryOptions,
    onChange,
    onEditPatient,
    onCreatePatient,
    departmentOptions,
    setDepartmentOptions,
    setSequenceDialogBox,
    handleConfirmSequenceNoChange,
    initialPatientList,
  } = props;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const patient_id = searchParams.get("patient_id");
  const patient_name = searchParams.get("patient_name");

  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenModals, setIsOpenModals] = useState<{
    isOpenPayeeModal: boolean;
    isOpenSequenceModal: boolean;
    isOpenAddReferralDoctorModal: boolean;
  }>({
    isOpenPayeeModal: false,
    isOpenSequenceModal: false,
    isOpenAddReferralDoctorModal: false,
  });
  const [payeeOptions, setPayeeOptions] = useState<any>([]);
  const [billPaymentTypeOptions, setBillPaymentTypeOptions] = useState([]);
  const [ipOptions, setIpOptions] = useState([]);
  const [opOptions, setOpOptions] = useState([]);
  const [referredDoctorsOptions, setReferredDoctorsOptions] = useState<any[]>(
    []
  );
  const [loading, setLoading] = useState<{
    isPayeeLoading: boolean;
    isReferralLoading: boolean;
  }>({
    isPayeeLoading: false,
    isReferralLoading: false,
  });
  const [patientsList, setPatientsList] = useState<any>(
    initialPatientList || []
  );
  const searchPatientsPagination = useRef({ search: "", page: 1 });

  const [customSequence, setCustomSequence] = useState(true);

  const [errors, setErrors] = useState<any>([]);

  const patientsPagination: any = useRef(1);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      setIsEdit(true);
    }
  }, [id]);

  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);

  const debouncedGetAllPatientsList = debounce(
    async (currentValue, prevOptions, callback) => {
      try {
        return await getAllPatientsList({
          search: currentValue,
          page:
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page
              : 1,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;
          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              ...uniqueData,
              value: uniqueData.id,
              label: `${uniqueData.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList.filter(
            (option: { value: number | string; label: number | string }) =>
              !prevOptions.some(
                (prevOption: {
                  value: number | string;
                  label: number | string;
                }) => prevOption.value === option.value
              )
          );
          setPatientsList(uniqueOptions);
          searchPatientsPagination.current.page =
            searchPatientsPagination.current?.search === currentValue
              ? searchPatientsPagination.current?.page + 1
              : 1;
          searchPatientsPagination.current.search = currentValue;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;
          callback({
            options: uniqueOptions,
            hasMore,
          });
        });
      } catch (error) {
        console.log("error:", error);
        callback({
          options: [],
          hasMore: false,
        });
      }
    },
    300
  );

  const getAllPatients = async (currentValue: any, prevOptions: any) => {
    try {
      if (currentValue) {
        return new Promise((resolve) => {
          debouncedGetAllPatientsList(
            currentValue,
            prevOptions,
            (response: {
              options: { value: string | number; label: string | number }[];
              hasMore: boolean;
            }) => {
              resolve(response);
            }
          );
        });
      } else {
        const page =
          patientsPagination.current === Math.ceil(prevOptions.length / 10)
            ? patientsPagination.current
            : Math.ceil(prevOptions.length / 10) + 1;
        return await getAllPatientsList({
          search: currentValue,
          page: page,
          per_page: 10,
        }).then((result: any) => {
          let data = result?.data?.data;

          const formattedSuppliersList = data?.map((uniqueData: any) => {
            return {
              ...uniqueData,
              value: uniqueData.id,
              label: `${uniqueData?.uhid} - ${uniqueData.name}`,
            };
          });
          const uniqueOptions = formattedSuppliersList?.filter(
            (option: { value: number | string; label: number | string }) =>
              !prevOptions.some(
                (prevOption: {
                  value: number | string;
                  label: number | string;
                }) => prevOption.value === option.value
              )
          );

          if (uniqueOptions?.length > 0) {
            setPatientsList((prevState: any) => [
              ...prevState,
              ...uniqueOptions,
            ]);
          }
          const currentValue = patientsPagination.current;
          patientsPagination.current = currentValue + 1;
          const hasMore =
            result?.data?.total > [...prevOptions, ...uniqueOptions]?.length;

          return {
            options: uniqueOptions,
            hasMore,
          };
        });
      }
    } catch (error) {
      console.log("error:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  const getPayeeOptions = () => {
    getPayeeList()
      .then((res: any) => {
        if (res.data) {
          setPayeeOptions([{ id: 1, name: "Self" }, ...res.data?.result]);
        }
      })
      .catch((err) => console.log("err", err));
  };

  useEffect(() => {
    if (commonVariables?.bill_payment_type) {
      setBillPaymentTypeOptions(commonVariables?.bill_payment_type);
    }
  }, [commonVariables]);

  const getReferralDoctorOptions = () => {
    getReferralDoctorsList()
      .then((res: any) => {
        if (res.data) {
          setReferredDoctorsOptions(res.data?.result);
        }
      })
      .catch((err) => console.log("err", err));
  };

  const getIpOptions = (patient_id: string | number, search: string) => {
    if (patient_id || search) {
      getIpList(patient_id, search)
        .then((res: any) => {
          if (res.data) {
            setIpOptions(res.data);
            if (location?.state?.draft_type === "IP" && res?.data?.length > 0) {
              onChange({
                target: { name: "ip_admission_id", value: res?.data[0] },
              });
            }
          }
        })
        .catch((err) => console.log("err", err));
    }
  };

  const getOpOptions = (patient_id: string | number, search: string) => {
    if (patient_id || search) {
      getOpList(patient_id, search)
        .then((res: any) => {
          if (res.data) {
            setOpOptions(res.data);
            if (location?.state?.draft_type === "OP" && res?.data?.length > 0) {
              onChange({
                target: { name: "op_no", value: res?.data[0] },
              });
            }
          }
        })
        .catch((err) => console.log("err", err));
    }
  };

  const getDepartmentOptions = () => {
    getDepartments()
      .then((res: any) => {
        if (res.data) {
          setDepartmentOptions(res.data);
          if (res.data.length === 1) {
            onChange({ target: { name: "dept_id", value: res?.data[0]?.id } });
          }
        }
      })
      .catch((err) => console.log("err", err));
  };

  const handleAddPayee = async (value: any) => {
    setLoading(
      (prev: { isPayeeLoading: boolean; isReferralLoading: boolean }) => ({
        ...prev,
        isPayeeLoading: true,
      })
    );
    await addPayee(value)
      .then(() => {
        getPayeeOptions();
        setIsOpenModals((prevState: any) => ({
          ...prevState,
          isOpenPayeeModal: false,
        }));
        setLoading(
          (prev: { isPayeeLoading: boolean; isReferralLoading: boolean }) => ({
            ...prev,
            isPayeeLoading: false,
          })
        );
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(
          (prev: { isPayeeLoading: boolean; isReferralLoading: boolean }) => ({
            ...prev,
            isPayeeLoading: false,
          })
        );
      });
  };

  const handleAddReferralDoctor = (value: any) => {
    setLoading(
      (prev: { isPayeeLoading: boolean; isReferralLoading: boolean }) => ({
        ...prev,
        isReferralLoading: true,
      })
    );
    addReferralDoctor(value)
      .then(() => {
        getReferralDoctorOptions();
        setIsOpenModals((prevState: any) => ({
          ...prevState,
          isOpenAddReferralDoctorModal: false,
        }));
        setLoading(
          (prev: { isPayeeLoading: boolean; isReferralLoading: boolean }) => ({
            ...prev,
            isReferralLoading: false,
          })
        );
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(
          (prev: { isPayeeLoading: boolean; isReferralLoading: boolean }) => ({
            ...prev,
            isReferralLoading: false,
          })
        );
      });
  };

  const handleIpInputChange = (e: any, newInputValue: any) => {
    if (newInputValue) {
      onChange({
        target: {
          name: "ip_no_value",
          value: newInputValue,
        },
      });

      debouncedGetIpOptions(data.patient_id, newInputValue);
    }
  };
  const handleOpInputChange = (e: any, newInputValue: any) => {
    if (e?.target?.value) {
      onChange({
        target: {
          name: "op_no_value",
          value: e?.target?.value,
        },
      });

      debouncedGetOpOptions(data.patient_id, e?.target?.value);
    }
  };

  const handlePatientChange = (e: any) => {
    const { name, value } = e?.target;
    const patientDetails = patientsList?.find(
      (ele: any) => ele.value === value
    );
    const addressParts = [
      patientDetails?.line_1 && patientDetails?.line_1 !== "-"
        ? patientDetails.line_1
        : "",
      patientDetails?.line_2 && patientDetails?.line_2 !== "-"
        ? patientDetails.line_2
        : "",
      patientDetails?.area && patientDetails?.area !== "-"
        ? patientDetails.area
        : "",
      patientDetails?.district ? patientDetails?.district : "",
      patientDetails?.state ? patientDetails?.state : "",
      patientDetails?.pincode ? patientDetails?.pincode : "",
    ];

    const address = addressParts.filter((part) => part).join("\n");

    onChange({
      target: {
        name,
        value: {
          id: value,
          mobile: patientDetails?.mobile || "",
          address: patientDetails ? address : "",
        },
      },
    });
  };

  const debouncedGetIpOptions = useCallback(
    debounce((patient_id: string | number, ip_no_value: string) => {
      getIpOptions(patient_id, ip_no_value);
    }, 300),
    []
  );

  const debouncedGetOpOptions = useCallback(
    debounce((patient_id: string | number, op_no_value: string) => {
      getOpOptions(patient_id, op_no_value);
    }, 300),
    []
  );

  useEffect(() => {
    debouncedGetIpOptions(data.patient_id, data.ip_no_value);
    debouncedGetOpOptions(data.patient_id, data.op_no_value);
  }, [data.patient_id]);

  useEffect(() => {
    getPayeeOptions();
    // getPaymentTypeOptions();
    getReferralDoctorOptions();
    getIpOptions(data.patient_id, data.ip_no_value);
    getOpOptions(data.patient_id, data.op_no_value);
    getDepartmentOptions();
    // getAllPatients();
  }, []);

  useEffect(() => {
    if (
      typeof data.ip_admission_id === "number" &&
      data.ip_admission_id &&
      ipOptions.length > 0
    ) {
      const ip_admission_id: any = ipOptions.find(
        (ele: any) => ele.id === data.ip_admission_id
      );

      onChange({ target: { name: "ip_admission_id", value: ip_admission_id } });
    }
    if (
      (typeof data.op_no === "number" || data.op_id) &&
      data.op_no &&
      opOptions.length > 0
    ) {
      const ip_admission_id: any = opOptions.find(
        (ele: any) => ele.id === data.op_no || ele.id === data.op_id
      );

      onChange({ target: { name: "op_no", value: ip_admission_id } });
    }
  }, [data.ip_admission_id, data.op_no, ipOptions, opOptions]);

  useEffect(() => {
    if (patient_id && patient_name) {
      setPatientsList([
        {
          value: patient_id,
          label: patient_name,
        },
      ]);
    }

    if (location?.state?.patient_id) {
      setPatientsList([
        {
          value: location?.state?.patient_id,
          label: `${location?.state?.uhid} - ${location?.state?.patient_name}`,
        },
      ]);
    }
  }, [patient_id, patient_name, location]);

  return (
    <Box width={"100%"}>
      <Grid container columns={12} p={1} columnGap={2} rowGap={5}>
        <Grid item xs={12} md={12} xl={3.9}>
          <Box sx={styles.titleBoxStyle}>
            <Typography sx={styles.boxTitleStyle}>
              Patient Information
            </Typography>
          </Box>
          <Box width={"100%"}>
            <Box sx={styles.cardStyle}>
              <Box ml="10px">
                <CommonLabelAndComponent
                  label="UHID / Patient"
                  isEdit={isEdit}
                  children={
                    isEdit ? (
                      <Typography sx={styles.valueStyle}>
                        {isEdit ? data.name : data.patient_id}
                      </Typography>
                    ) : (
                      <Box display={"flex"} alignItems={"center"}>
                        <Box width={"90%"}>
                          <InfiniteScroller
                            loadOptions={getAllPatients}
                            options={patientsList}
                            defaultOptions={initialPatientList}
                            name={"patient_id"}
                            value={data.patient_id}
                            width="97%"
                            label=""
                            handleOnChange={handlePatientChange}
                            error={!!error?.patient_id}
                            // helperText={error?.patient_id}
                          />
                        </Box>
                        {/* <Box
                        width={"10%"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        pl={"10px"}
                      > */}
                        <Box sx={styles.iconBoxStyle}>
                          {data.patient_id ? (
                            <EditIcon onClick={onEditPatient} />
                          ) : (
                            <PlusIcon onClick={onCreatePatient} />
                          )}
                        </Box>
                        {/* </Box> */}
                      </Box>
                    )
                  }
                />
                <CommonLabelAndComponent
                  label="Mobile"
                  isEdit={isEdit}
                  children={
                    isEdit ? (
                      <Typography sx={styles.valueStyle}>
                        {data.mobile}
                      </Typography>
                    ) : (
                      <TextField
                        value={data.mobile}
                        type="number"
                        fullWidth
                        formControlStyle={{
                          width: "100%",
                        }}
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "100%",
                          },
                        }}
                        name="mobile"
                        onChange={onChange}
                        error={!!error?.mobile}
                        // helperText={error?.mobile}
                      />
                    )
                  }
                />
                <CommonLabelAndComponent
                  label="Address"
                  isEdit={isEdit}
                  children={
                    isEdit ? (
                      <Typography sx={styles.valueStyle}>
                        {data.address}
                      </Typography>
                    ) : (
                      <textarea
                        style={{
                          width: "100%",
                          height: "59px",
                          borderRadius: "8px",
                          resize: "none",
                          outline: "none",
                          border: "1px solid #EFEFEF",
                          fontFamily: "inter",
                          fontSize: "12px",
                          marginTop: "3px",
                          padding: "15px",
                        }}
                        spellCheck="false"
                        value={data.address}
                        name="address"
                        onChange={onChange}
                      />
                    )
                  }
                />
                <CommonLabelAndComponent
                  label="Doctor"
                  isEdit={isEdit}
                  children={
                    // isEdit ? (
                    //   <Typography sx={styles.valueStyle}>
                    //     {consultantList.length > 0
                    //       ? consultantList.find(
                    //           (ele: any) => ele.id === data.doctor_id
                    //         )?.name
                    //       : ""}
                    //   </Typography>
                    // ) : (
                    <Select
                      value={data.doctor_id || ""}
                      label=""
                      placeholder={"Select Doctor"}
                      name="doctor_id"
                      options={consultantList}
                      formControlStyle={{ width: "100%" }}
                      sx={{ height: "100%" }}
                      width=""
                      onChange={(event: any) =>
                        onChange({
                          target: {
                            name: event?.target?.name,
                            value: Number(event?.target?.value),
                          },
                        })
                      }
                      error={!!error?.doctor_id}
                      // helperText={error?.doctor_id}
                    />
                    // )
                  }
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} xl={7.9}>
          <Box sx={styles.titleBoxStyle}>
            <Typography sx={styles.boxTitleStyle}>
              Billing Information
            </Typography>
          </Box>
          <Box width={"100%"}>
            <Box sx={styles.cardStyle}>
              <Grid
                container
                columns={12}
                sx={{
                  // paddingBottom: isEdit ? "unset" : "50px",
                  "@media screen and (max-width: 1535px)": {
                    paddingBottom: "0px",
                  },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={5}
                  sx={{
                    marginRight: "80px",
                    "@media screen and (max-width: 1535px)": {
                      marginRight: "0px",
                    },
                  }}
                >
                  <CommonLabelAndComponent
                    label="Date"
                    children={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          "& .MuiGrid-root": {
                            width: "100%",
                            "@media screen and (max-width: 1535px)": {
                              width: "100%",
                            },
                          },
                        }}
                      >
                        <DatePicker
                          value={data.date_created}
                          placeholder="Select Date"
                          formControlStyle={{ width: "100%" }}
                          style={{ marginRight: "16px", width: "100%" }}
                          onChange={(value: any) => {
                            onChange({
                              target: {
                                value: value?.toString(),
                                name: "date_created",
                              },
                            });
                          }}
                          format="DD/MM/YYYY hh:mm:ss A"
                          name="date_created"
                          error={!!error?.date_created}
                          // helperText={error?.date_created}
                        />
                      </Box>
                    }
                  />
                  <CommonLabelAndComponent
                    label="Payee"
                    children={
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        sx={{
                          "& .MuiGrid-root": {
                            width: "90%",
                          },
                        }}
                      >
                        <Select
                          value={data.payee || ""}
                          placeholder="Payee"
                          label=""
                          name="payee"
                          options={payeeOptions}
                          formControlStyle={{ width: "95%" }}
                          sx={{ height: "100%" }}
                          width=""
                          onChange={onChange}
                          error={!!error?.payee}
                          // helperText={error?.payee}
                        />
                        <Box
                          sx={styles.iconBoxStyle}
                          onClick={() => {
                            setIsOpenModals((prevState) => ({
                              ...prevState,
                              isOpenPayeeModal: true,
                            }));
                          }}
                        >
                          <PlusIcon />
                        </Box>
                      </Box>
                    }
                  />
                  {customSequence ? (
                    <CommonLabelAndComponent
                      label="Bill No"
                      children={
                        <TextField
                          placeholder="Bill No"
                          value={data.bill_no}
                          fullWidth
                          formControlStyle={{ width: "100%" }}
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "100%",
                            },
                          }}
                          name="bill_no"
                          onBlur={handleConfirmSequenceNoChange}
                          onChange={onChange}
                          endPlaceholderIcon={
                            <SettingsIcon
                              width="20px"
                              height="20px"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSequenceDialogBox(true);
                              }}
                            />
                          }
                          error={!!error?.bill_no}
                          // helperText={error?.bill_no}
                        />
                      }
                    />
                  ) : null}
                  <CommonLabelAndComponent
                    label="Referred by"
                    children={
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        sx={{
                          "& .MuiGrid-root": {
                            width: "90%",
                          },
                        }}
                      >
                        <Select
                          value={data.refdoctor || ""}
                          label=""
                          placeholder={"Doctor"}
                          name="refdoctor"
                          options={referredDoctorsOptions}
                          formControlStyle={{ width: "95%" }}
                          sx={{ height: "100%" }}
                          width=""
                          onChange={onChange}
                        />
                        <Box
                          sx={styles.iconBoxStyle}
                          onClick={() => {
                            setIsOpenModals((prevState) => ({
                              ...prevState,
                              isOpenAddReferralDoctorModal: true,
                            }));
                          }}
                        >
                          <PlusIcon />
                        </Box>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12} xl={5.5}>
                  <CommonLabelAndComponent
                    label="Bill Payment Type"
                    children={
                      <Select
                        value={data.bill_payment_type}
                        label=""
                        placeholder={"Select Type"}
                        name="bill_payment_type"
                        options={billPaymentTypeOptions}
                        formControlStyle={{ width: "100%" }}
                        sx={{ height: "100%" }}
                        width=""
                        onChange={onChange}
                        error={!!error?.bill_payment_type}
                        // helperText={error?.bill_payment_type}
                      />
                    }
                  />
                  <CommonLabelAndComponent
                    label="Type"
                    children={
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          value={data.rdType || ""}
                          label=""
                          name="rdType"
                          options={["IP", "OP", "Counter Sales"]}
                          onChange={onChange}
                          error={!!error?.rdType}
                          // helperText={error?.rdType}
                        />
                      </div>
                    }
                  />
                  {data.rdType === "IP" && (
                    <CommonLabelAndComponent
                      label={"IP No"}
                      children={
                        <AutoComplete
                          value={data.ip_admission_id}
                          inputValue={data.ip_no_value}
                          options={ipOptions}
                          name="ip_admission_id"
                          label=""
                          onChange={(event: any, value: any) => {
                            onChange({
                              target: {
                                name: "ip_admission_id",
                                value,
                              },
                            });
                            const existingPatient = patientsList.find(
                              (ele: any) => ele.value === value?.patient_id
                            );

                            if (!existingPatient) {
                              setPatientsList((prevState: any) => [
                                ...prevState,
                                {
                                  value: value?.patient_id,
                                  label: value?.patient_name,
                                },
                              ]);
                            }
                          }}
                          onInputChange={handleIpInputChange}
                          error={!!error?.ip_admission_id}
                          // helperText={error?.ip_admission_id}
                        />
                        // <Select
                        //   value={data.ip_admission_id || ""}
                        //   label=""
                        //   placeholder={"Select IP"}
                        //   name="ip_admission_id"
                        //   options={ipOptions}
                        //   formControlStyle={{ width: "100%" }}
                        //   sx={{ height: "100%" }}
                        //   width=""
                        //   disabled={isEdit}
                        //   onChange={(event: any) => {
                        //     onChange({
                        //       target: {
                        //         name: "ip_admission_id",
                        //         value: event?.target?.value,
                        //       },
                        //     });
                        //   }}
                        //   error={!!error?.ip_admission_id}
                        //   helperText={error?.ip_admission_id}
                        // />
                      }
                    />
                  )}
                  {data.rdType === "OP" && (
                    <CommonLabelAndComponent
                      label={"OP No"}
                      children={
                        <AutoComplete
                          value={data.op_no}
                          inputValue={data.op_no_value}
                          options={opOptions}
                          name="op_no"
                          label=""
                          onChange={(event: any, value: any) => {
                            onChange({
                              target: {
                                name: "op_no",
                                value,
                              },
                            });

                            const existingPatient = patientsList.find(
                              (ele: any) => ele.value === value?.patient_id
                            );

                            if (!existingPatient) {
                              setPatientsList((prevState: any) => [
                                ...prevState,
                                {
                                  value: value?.patient_id,
                                  label: value?.patient_name,
                                },
                              ]);
                            }
                          }}
                          onInputChange={handleOpInputChange}
                          error={!!error?.op_no}
                          // helperText={error?.op_no}
                          disabled={data.rdType === "Counter Sales"}
                        />
                        // <Select
                        //   value={data.op_no || ""}
                        //   label=""
                        //   placeholder={"Select OP"}
                        //   name="op_no"
                        //   options={opOptions}
                        //   formControlStyle={{ width: "100%" }}
                        //   sx={{ height: "100%" }}
                        //   width=""
                        //   disabled={isEdit}
                        //   onChange={(event: any) => {
                        //     onChange({
                        //       target: {
                        //         name: "op_no",
                        //         value: event?.target?.value,
                        //       },
                        //     });
                        //   }}
                        //   error={!!error?.op_no}
                        //   helperText={error?.op_no}
                        // />
                      }
                    />
                  )}
                  <CommonLabelAndComponent
                    label="Department"
                    children={
                      <Select
                        value={data.dept_id || ""}
                        label=""
                        placeholder={"Select Department"}
                        name="dept_id"
                        options={departmentOptions}
                        formControlStyle={{ width: "100%" }}
                        sx={{ height: "100%" }}
                        width=""
                        disabled={isEdit}
                        onChange={(event) => {
                          onChange({
                            ...event,
                            target: {
                              ...event.target,
                              department: departmentOptions.find(
                                (ele: any) => ele.id === event.target.value
                              ),
                            },
                          });
                        }}
                        error={!!error?.dept_id}
                        // helperText={error?.dept_id}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <AddPayeeModal
        isOpen={isOpenModals?.isOpenPayeeModal}
        onClose={() =>
          setIsOpenModals((prevState: any) => ({
            ...prevState,
            isOpenPayeeModal: false,
          }))
        }
        onSave={handleAddPayee}
        isLoading={loading.isPayeeLoading}
      />
      <AddReferralDoctorModal
        isOpen={isOpenModals?.isOpenAddReferralDoctorModal}
        onClose={() =>
          setIsOpenModals((prevState: any) => ({
            ...prevState,
            isOpenAddReferralDoctorModal: false,
          }))
        }
        onSave={handleAddReferralDoctor}
        isLoading={loading.isReferralLoading}
      />
    </Box>
  );
};

export default HeaderForm;
