import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  debounce,
} from "@mui/material";
import {
  Button,
  SaveButton,
  CancelButton,
  PageLoader,
} from "../../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import { indentsConst } from "../../../../constants/displayText";
import { setSnackBarSuccess,setSnackBarFailed } from "../../../../redux/slices/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { RouteUrls } from "../../../../constants/routes";
import { useDispatch,useSelector} from "react-redux";
import { RootState } from "../../../../redux/store";
import { ConfirmationDialog } from "../../../../components/shared";
import {
  createIndentsById,
  deleteIndentById,
  getAllLaboratoryProducts,
  getIndentById,
  updateIndentsById,
} from "../../../../services/laboratoryService";
import {
  getDepartmentSequence
} from "../../../../services/laboratoryService";
import {SequenceWrapper } from "../../../../components/shared";
import { updatedSequence } from "../../../../services/procurementMasterService";


const { laboratoryUrl, labIndentsListUrl } = RouteUrls;

type TableHeaderCell = {
  TableCellName: string;
  alignment: "left" | "right";
  sx?: {};
};

type InfoType = {
  indent_no: number | null;
  department: number | null;
  indent_no_modified: number | null;
  department_name?: string;
  date_created?: string;
};

const BillProducts = React.memo(() => {
  const { productName, requirement } = indentsConst;
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: {
          xs: "92vw",
        },
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },
        "& .MuiTableCell-root": {
          minHeight: "0px",
        },
        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },
        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },
        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },

      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",
        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },

      selectStyle: {
        "& .MuiFormControl-root": { width: "100%" },

        "& .MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderWidth: 0,
          height: "34px",
          maxHeight: "34px",
          p: 0,
        },

        "& .MuiInputBase-root": {
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },

        "& .Mui-focused:has(.MuiSelect-iconOpen) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "primary.main",
            borderWidth: "1px",
          },

        "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderWidth: 0,
        },
      },
    }),
    []
  );

  const TableHeaderCells: TableHeaderCell[] = [
    { TableCellName: productName, alignment: "left" },
    {
      TableCellName: requirement,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  const initialBillProduct = {
    product_id: null,
    product_name: null,
    product_name_input_value: "",
    requirement: "",
  };

  const [billProducts, setBillProducts] = useState<any>([initialBillProduct]);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [infoError, setInfoError] = useState<any>({});
  const [productErrors, setProductErrors] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<{
    isOpen: boolean;
    id: number | string | null;
    index: number | null;
  }>({ isOpen: false, id: null, index: null });
  const [isDeleteButtonLoading, setIsDeleteButtonLoading] =
    useState<boolean>(false);

    const { appConfiguration } = useSelector(
      (state: RootState) => state.appConfiguration
    );
  const [customSequence,setCustomSequence] = useState(true);

  const initialInfo = {
    indent_no: null,
    department: null,
    indent_no_modified: 0,
    department_name: "",
  };

  const [info, setInfo] = useState<InfoType | any>(initialInfo);


  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
  string | number | undefined
>("");

const [isSequenceWrapperOpen, setIsSequenceWrapperOpen] = useState(false);

const [sequenceId, setSequenceId] = useState(null);

const [sequenceData, setSequenceData] = useState<any>([
  { id: null, type: "", value: "", separator: "" },
]);

const [isManual, setIsManual] = useState({
  is_manual: false,
  is_auto_generate: false,
  is_once: false,
});

const [isManualAdded, setIsManualAdded] = useState(false);

const [seqIsOnce, setSeqIsOnce] = useState({
  isOnce: false,
  isOnceSeqValue: "",
});

// sequence number generator functions

let isCreatePrescription = true;
let isSequenceNumberChanged = false;

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const { name, value } = e.target;

      setBillProducts((prev: any) =>
        prev.map((billProduct: any, i: number) => {
          return index === i ? { ...billProduct, [name]: value } : billProduct;
        })
      );

      if (infoError[name] && value) {
        setInfoError((prev: any) => ({
          ...prev,
          [name]: value ? "" : prev[name],
        }));
      }
    },
    []
  );

  const handleAutoCompleteChange = useCallback(
    (e: any, newValue: any, index: Number, name: string) => {
      if (newValue === null) {
        return false;
      }

      setBillProducts((prev: any) =>
        prev.map((row: any, i: number) => {
          return i === index
            ? {
                ...row,
                [name]: newValue,
                product_id:
                  name === "product_name" ? newValue?.id : row.product_id,
              }
            : row;
        })
      );
      if (
        productErrors.length > 0 &&
        productErrors[index as number] &&
        newValue
      ) {
        setProductErrors((prev: any) => {
          const newState = [...prev];
          newState[index as number][name] = newValue ? "" : prev[name];
          return newState;
        });
      }
    },
    []
  );


  const handleAutoCompleteInputChange = (
    e: any,
    newInputValue: any,
    index: Number,
    name: string
  ) => {
    if (newInputValue === "") {
      return false;
    }

    if (name === "product_name_input_value") {
      const data = {
        department: info.department,
        search: newInputValue,
        page: 1,
        limit: 20,
      };
    }

    setBillProducts((prev: any) =>
      prev.map((row: any, i: number) => {
        return i === index ? { ...row, [name]: newInputValue } : row;
      })
    );
  };

  const handleClick = useCallback(() => {
    setBillProducts((prev: any) => [
      ...prev,
      {
        ...initialBillProduct,
      },
    ]);
  }, []);

  const handleConfirmDelete = useCallback(() => {
    if (isOpenDeleteModal.id) {
      setIsDeleteButtonLoading(true);
      deleteIndentById(isOpenDeleteModal.id)
        .then((res:any) => {
          setIsDeleteButtonLoading(false);
          setBillProducts((prev: any) =>
            prev.filter(
              (billProduct: any, index: number) =>
                index !== isOpenDeleteModal?.index
            )
          );
          setIsOpenDeleteModal({ isOpen: false, id: null, index: null });
          dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
        })
        .catch((err) => {
          console.log(err);
          setIsDeleteButtonLoading(false);
        });
    } else {
      setBillProducts((prev: any) =>
        prev.filter(
          (billProduct: any, index: number) => index !== isOpenDeleteModal.index
        )
      );
      setIsOpenDeleteModal({ isOpen: false, id: null, index: null });
    }
  }, [isOpenDeleteModal]);

  const getAllLaboratoryProductsData = async (data: any) => {
    const res : any = await getAllLaboratoryProducts(data);
    if (res.data) {
      setAllProducts(res.data?.result);
    }
  };

  const debouncedGetAllProcurementProductsData = useCallback(
    debounce((data) => {
      getAllLaboratoryProductsData(data);
    }, 500),
    []
  );

  const handleValidation = () => {
    const requireInfoFields = ["indent_no", "department"];

    const errors: { [key: string]: string } = {};
    requireInfoFields.forEach((key: string) => {
      if (!info[key]) {
        errors[key] = "required field";
      }
    });

    const requiredProductField = ["requirement", "product_id"];

    const productsErrors: { [key: string]: string }[] = [];

    billProducts.forEach((ele: any) => {
      const newError: { [key: string]: string } = {};

      requiredProductField.forEach((key) => {
        if (!ele[key]) {
          newError[key] = "required field";
        }
      });

      productsErrors.push(newError);
    });

    let productsHasError = false;

    if (productsErrors?.length > 0) {
      productsErrors?.forEach((item: any) => {
        if (Object.keys(item).length > 0) {
          productsHasError = true;
        }
      });
    }

    if (Object.keys(errors)?.length > 0) {
      setInfoError(errors);
      return false;
    }

    if (productsHasError) {
      setProductErrors(productsErrors);
      return false;
    }

    return true;
  };

  const handleSave = () => {
    if (handleValidation()) {
      setIsSaveLoading(true);
      if (id) {
        updateIndentsById(id, {
          dept_id: info.department,
          indent_no: info.indent_no,
          indent_no_modified: Number(info.indent_no) === Number(initialSequenceNumber)? 0 : 1,
          product: billProducts,
        })
          .then((res : any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: res.message,
              })
            );
            setIsSaveLoading(false);
            navigate(`${laboratoryUrl}${labIndentsListUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      } else {
        createIndentsById({
          dept_id: info.department,
          indent_no: info.indent_no,
          indent_no_modified: info.indent_no_modified === initialSequenceNumber ? 0 : 1,
          product: billProducts,
        })
          .then((res : any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: res.message,
              })
            );
            setIsSaveLoading(false);
            navigate(`${laboratoryUrl}${labIndentsListUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      }
    }
  };



const getSequenceDetails = async () => {
  try {
    await getDepartmentSequence("Lab Indent").then((result: any) => {
      let data = result?.data[0]?.sequence_no;

      if (isCreatePrescription && !isSequenceNumberChanged) {
        setInitialSequenceNumber(data);
        setInfo((prevState: any) => ({
          ...prevState,
          indent_no: data,
        }));

        setInfoError((prevState: any) => ({
          ...prevState,
          indent_no: "",
        }));
      }

      if (isSequenceNumberChanged) {
     
        setInfo((prevState: any) => ({
          ...prevState,
          indent_no: data,
        }));
        setInfoError((prevState: any) => ({
          ...prevState,
          indent_no: "",
        }));
      }

      if (result?.data?.length > 0) {
        setSequenceId(result.data[0].sequence.id);
        const newSequenceData =
          result?.data[0]?.sequence?.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
        setSequenceData(newSequenceData);
        setIsManual((prevState) => ({
          ...prevState,
          is_manual:
            result?.data[0]?.sequence?.is_manual === 1 ? true : false,
          is_auto_generate:
            result?.data[0]?.sequence?.is_manual !== 1 ? true : false,
        }));
        setSeqIsOnce((prevState) => ({
          ...prevState,
          isOnce: false,
          isOnceSeqValue: "",
        }));
        setIsManualAdded(Boolean(result?.data[0]?.sequence?.is_manual === 1));
      }
     
    });
  } catch (error: any) {
 
    console.error("An error occurred:", error);
  }
};

const handleConfirmSequenceNoChange = () => {
  if (initialSequenceNumber !== info.indent_no) {
    setSeqIsOnce((prevState: any) => ({
      ...prevState,
      isOnce: true,
      isOnceSeqValue: info.indent_no,
    }));
    setIsSequenceWrapperOpen(true);
  }
};

const handleSequenceOnChange = (e: any, index: number) => {
  const { name, value } = e.target;
  setSequenceData((prevState: any) => {
    const newState = [...prevState];
    newState[index] = {
      ...newState[index],
      [name]: value !== "Select" ? value : null,
    };
    return newState;
  });
};

const onCloseSequenceDialogBox = () => {
  setIsSequenceWrapperOpen(false);
};

const handleSequenceSubmit = async () => {
  if (isManual.is_once) {
    onCloseSequenceDialogBox();
  } else {
    try {
      const data = {
        seq_preference: [...sequenceData],
        is_manual: isManual.is_manual ? 1 : 0,
      };
      await updatedSequence(Number(sequenceId), data).then((res: any) => {
        if (res) {
          isSequenceNumberChanged = true;
          getSequenceDetails();
          onCloseSequenceDialogBox();
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: "Sequence Updated SuccessFully",
            })
          );
        }
      });
    } catch (error: any) {
      console.error("An error occurred:", error);
      dispatch(
        setSnackBarFailed({
          snackBarMessage: error?.response?.data?.errors.message,
        })
      );
    }
  }
};

const handleSequenceCheckBoxChange = (e: any) => {
  const { name, checked } = e.target;
  setIsManual((prevState: any) => {
    if (name === "is_manual") {
      return {
        ...prevState,
        is_manual: true,
        is_auto_generate: false,
        is_once: false,
      };
    }
    if (name === "is_auto_generate") {
      return {
        ...prevState,
        is_manual: false,
        is_auto_generate: true,
        is_once: false,
      };
    }
    if (name === "is_once") {
      return {
        ...prevState,
        is_manual: false,
        is_auto_generate: false,
        is_once: true,
      };
    }
  });
};


  useEffect(() => {
    if (info.department) {
      const data = {
        department: info.department,
        page: 1,
        limit: 20,
      };
      getAllLaboratoryProductsData(data);
    }
  }, [info.department]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getIndentById(id)
        .then((res : any) => {
          if (res.data) {
            const { bill } = res.data;
            setInfo({
              indent_no: bill?.indent_no,
              department:
                bill?.indent_items?.length > 0
                  ? bill?.indent_items[0]?.dept_id
                  : null,
              department_name:
                bill?.indent_items?.length > 0
                  ? bill?.indent_items[0]?.department?.dept_name
                  : "",
              indent_no_modified: 0,
              date_created: bill?.date_created,
            });
        setInitialSequenceNumber(bill?.indent_no);
            const productList: any = [];
            setBillProducts(() =>
              bill?.indent_items?.map((ele: {
                id: number;
                product_id: number;
                product: { name: string };
                requirement: string;
              }) => {
                productList.push(ele.product);
                return {
                  id: ele.id,
                  product_id: ele.product_id,
                  product_name: ele.product,
                  product_name_input_value: ele?.product?.name,
                  requirement: ele.requirement,
                };
              })
            );

            setAllProducts(productList);
            setIsLoading(false);
            isCreatePrescription = false;
            isSequenceNumberChanged = false;
            getSequenceDetails();
          }
        })
        .catch((err) => {
          console.log("err", err);
          setIsLoading(false);
        });
    }else{
      isCreatePrescription = true;
      isSequenceNumberChanged = false;
      getSequenceDetails();
    }
  }, [id]);

  useEffect(()=>{
    if(appConfiguration?.custom_sequence === false){
      setCustomSequence((prev)=> prev = false);
    }
  },[appConfiguration]);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        <Grid sx={{ width: "92vw" }}>
          <Info
            info={info}
            setInfo={setInfo}
            errors={infoError}
            isEdit={!!id}
            setIsSequenceWrapperOpen={setIsSequenceWrapperOpen}
            isManualAdded={isManualAdded}
            isManual={isManual}
            setIsManual={setIsManual}
            handleConfirmSequenceNoChange={handleConfirmSequenceNoChange}
            customSequence={customSequence}
          />
          <Grid
            sx={{
              width: {
                xs: "92vw",
              },
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells.map((cell, index) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    height: "auto",
                    width: {
                      xs: "400vw",
                      sm: "200vw",
                      md: "120vw",
                      lg: "90vw",
                    },
                  }}
                >
                  {billProducts?.map((row: any, index: number) => {
                    return (
                      <ProductRow
                        key={row.id}
                        index={index}
                        row={row}
                        error={productErrors[index] || {}}
                        styles={styles}
                        handleInputChange={handleInputChange}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        handleAutoCompleteInputChange={
                          handleAutoCompleteInputChange
                        }
                        handleDelete={(id: number | string) =>
                          setIsOpenDeleteModal({ isOpen: true, id, index })
                        }
                        allProducts={allProducts}
                        billProducts={billProducts}
                        isEdit={!!id}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <Grid
                sx={{
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "120vw",
                    lg: "90vw",
                  },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "0px 0px 5px 5px",
                  border: 1,
                  borderColor: "var(--table-border)",
                  borderTop: 0,
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: {
                      xs: "78%",
                    },
                  }}
                >
                  <Button
                    variant="text"
                    buttonText="+ Add Product"
                    handleClick={handleClick}
                    sx={{
                      borderRadius: "0px",
                      width: "auto",
                      height: "auto",
                      fontWeight: 400,
                      fontSize: "13px",
                      border: 0,
                      color: "primary.main",
                      "&:hover": {
                        backgroundColor: "initial",
                        color: "primary.main",
                        borderColor: "initial",
                      },
                      "&.Mui-focusVisible": {
                        border: 1,
                        borderColor: "primary.main",
                        borderRadius: "5px",
                      },
                    }}
                  />
                 
                </Grid>
              </Grid>
            </TableContainer>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              m: "35px 0 45px 0",
            }}
          >
            <SaveButton
              loading={isSaveLoading}
              handleClick={handleSave}
              sx={{ width: "100px", height: "42px", mr: "20px" }}
            />
            <CancelButton sx={{ width: "100px", height: "42px" }} />
          </Grid>
          <ConfirmationDialog
            open={isOpenDeleteModal.isOpen}
            title="are you surely want to delete"
            handleClick={handleConfirmDelete}
            onClose={() =>
              setIsOpenDeleteModal({ isOpen: false, id: null, index: null })
            }
            loading={isDeleteButtonLoading}
          />
            <SequenceWrapper
            open={isSequenceWrapperOpen}
            title="Lab Indent"
            onClose={onCloseSequenceDialogBox}
            handleChange={handleSequenceOnChange}
            sequenceValue={sequenceData}
            isOnce={seqIsOnce}
            handleClick={handleSequenceSubmit}
            handleSequenceCheckBoxChange={handleSequenceCheckBoxChange}
            isManual={isManual}
          />
        </Grid>
      )}
    </>
  );
});

export default BillProducts;
