/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { Typography, Box, IconButton, Grid, debounce } from "@mui/material";
import { DataTable, ConfirmationDialog } from "../../../../components/shared";
import { DeleteIcon, EditIcon, ViewIcon } from "../../../../assets/icons";
import { GridColDef } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import {
  Sort,
  TextField,
  DatePicker,
  Select,
} from "../../../../components/basic";
import { RouteUrls } from "../../../../constants/routes";
import { RootState } from "../../../../redux/store";
import { useNavigate } from "react-router-dom";
import CustomFilter from "../../../../components/shared/customFilter";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { errorMessage, CurrentDate } from "../../../../constants/displayText";
import { getAllGoodsIssue } from "../../../../services/procurementService";
import moment from "moment";
import {
  deleteGoodsIssueById,
  deleteTempGoodsIssueById,
} from "../../../../services/gynecologyService";
import { setGoodsIssueType } from "../../../../redux/slices/procurement";
import {
  setFilterDataValues,
  setSortField,
  setHandleClearSearch,
  setCurrentPage,
} from "../../../../redux/slices/pagination";

type GridRowData = Record<string, unknown>;

const GoodsIssueList = () => {
  const style = {
    selectStyle: {
      width: {
        xs: "90vw",
        sm: "45vw",
        md: "30vw",
        lg: "13vw",
        xl: "13vw",
      },
      height: "45px",
      boxShadow: "none",
      borderRadius: "5px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
    DatePickerStyle: {
      "& .MuiGrid-root > .MuiFormControl-root > .rmdp-container >div >div .rmdp-input":
        {
          boxSizing: "border-box",
          width: {
            xs: "90vw",
            sm: "45vw",
            md: "30vw",
            lg: "13vw",
            xl: "13vw",
          },
        },
    },
    textFieldStyle: {
      width: {
        xs: "90vw",
        sm: "45vw",
        md: "30vw",
        lg: "13vw",
        xl: "13vw",
      },
      "& .MuiOutlinedInput-root": {
        height: "45px",
        borderRadius: "5px",
        paddingLeft: "8px",
        boxShadow: "none",
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--primary-border-color)",
      },
    },
  };

  const dispatch = useDispatch();
  const { pageInfo, filterData, count, sortField } = useSelector(
    (state: RootState) => ({
      pageInfo: state?.pagination?.goodsIssue,
      filterData: state?.pagination?.goodsIssue?.filterData,
      count: state?.pagination?.goodsIssue?.count,
      sortField: state?.pagination?.goodsIssue?.sortedField,
    })
  );

  const [goodsIssueList, setGoodsIssueList] = useState<[]>([]);

  const [pageCount, setPageCount] = useState<number | null>(null);
  const [isFieldSort, setIsFieldSort] = useState(false);

  const [loading, setLoading] = useState(false);
  const [ascendingOrderClicked, setAscendingOrderClicked] = useState(false);
  const [descendingOrderClicked, setDescendingOrderClicked] = useState(false);
  const initialFilterData = {
    invoice_no: "",
    product: "",
    from_date: CurrentDate,
    to_date: CurrentDate,
    type: "bill_list",
    search: "",
  };

  const {
    procurementUrl,
    goodsIssueUrl,
    createUrl,
    editUrl,
    viewUrl,
    goodsIssueReturnUrl,
    stockTransferUrl,
  } = RouteUrls;
  const navigate = useNavigate();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [goodsIssueToBeDeleted, setGoodsIssueToBeDeleted] = useState<any>(null);
  const [isApproval, setIsApproval] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortField?.field === field) {
      dispatch(
        setSortField({
          key: "goodsIssue",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
    if (sortField?.field !== field) {
      dispatch(
        setSortField({
          key: "goodsIssue",
          value: { field: field, order: isFieldSort ? "ASC" : "DESC" },
        })
      );
    }
  };

  const deleteGoodsRow = (row: any) => {
    setIsConfirmationDialogOpen(true);
    setGoodsIssueToBeDeleted(row.id);
    setIsApproval(row.bill_type === "approval_list");
  };

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {row.is_return === 1 ? (
          <div>
            {" "}
            <IconButton
              aria-label=""
              onClick={() => {
                navigate(
                  `${procurementUrl}${goodsIssueReturnUrl}${editUrl}/${row.id}`
                );
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        ) : row.is_cancel === 1 ? (
          <div></div>
        ) : filterData.type === "approval_list" ? (
          <div></div>
        ) : row.is_transfer === 1 ? (
          <IconButton
            aria-label=""
            onClick={() => {
              navigate(`${procurementUrl}${stockTransferUrl}/${row.id}`);
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <IconButton
            aria-label=""
            onClick={() => {
              navigate(`${procurementUrl}${goodsIssueUrl}${editUrl}/${row.id}`);
            }}
          >
            <EditIcon />
          </IconButton>
        )}

        <IconButton
          aria-label=""
          onClick={() => {
            row.is_return
              ? navigate(
                  `${procurementUrl}${goodsIssueReturnUrl}${viewUrl}/${row.id}`
                )
              : row.is_transfer === 1
              ? navigate(
                  `${procurementUrl}${stockTransferUrl}${viewUrl}/${row.id}`
                )
              : navigate(
                  `${procurementUrl}${goodsIssueUrl}/${filterData.type}${viewUrl}/${row.id}`
                );
            dispatch(setGoodsIssueType(row.bill_type));
          }}
        >
          <ViewIcon />
        </IconButton>
        {row.is_cancel !== 1 && (
          <IconButton aria-label="" onClick={() => deleteGoodsRow(row)}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    );
  };
  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };
  const deleteGoodsIssue = async () => {
    if (goodsIssueToBeDeleted === null) {
      return false;
    }
    setDeleteBtnLoader(true);
    isApproval
      ? await deleteTempGoodsIssueById(goodsIssueToBeDeleted as number)
          .then((res: any) => {
            debouncedGetAllPurchaseBill({
              page: pageInfo.page || 1,
              pageSize: pageInfo.pageSize || 10,
            });
            setGoodsIssueToBeDeleted(null);
            setIsConfirmationDialogOpen(false);
            setIsApproval(false);
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setDeleteBtnLoader(false);
          })
          .catch((err: any) => {
            setDeleteBtnLoader(false);
            setGoodsIssueToBeDeleted(null);
            setIsConfirmationDialogOpen(false);
            setIsApproval(false);
            console.log(err);
            if (Array.isArray(err.response.data?.errors?.message)) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: Array.isArray(
                    err.response.data.errors.message
                  )
                    ? err.response.data.errors.message[0]?.table
                    : err.response.data.errors,
                })
              );
            } else if (err.response.data.message) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err.response.data.message,
                })
              );
            } else {
              dispatch(
                setSnackBarFailed({ snackBarMessage: "Something went wrong!" })
              );
            }
          })
      : await deleteGoodsIssueById(goodsIssueToBeDeleted as number)
          .then((res: any) => {
            debouncedGetAllPurchaseBill({
              page: pageInfo.page || 1,
              pageSize: pageInfo.pageSize || 10,
            });
            setGoodsIssueToBeDeleted(null);
            setIsConfirmationDialogOpen(false);
            dispatch(setSnackBarSuccess({ snackBarMessage: res.message }));
            setDeleteBtnLoader(false);
            setIsApproval(false);
          })
          .catch((err: any) => {
            setDeleteBtnLoader(false);
            setGoodsIssueToBeDeleted(null);
            setIsConfirmationDialogOpen(false);
            setIsApproval(false);
            console.log(err);
            if (Array.isArray(err.response.data?.errors?.message)) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: Array.isArray(
                    err.response.data.errors.message
                  )
                    ? err.response.data.errors.message[0]?.table
                    : err.response.data.errors,
                })
              );
            } else if (err.response.data.message) {
              dispatch(
                setSnackBarFailed({
                  snackBarMessage: err.response.data.message,
                })
              );
            } else {
              dispatch(
                setSnackBarFailed({ snackBarMessage: "Something went wrong!" })
              );
            }
          });
  };
  const columns: GridColDef[] = [
    {
      field: "invoice_no",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("invoice_no")}
        >
          <Typography variant="h5" fontSize={14}>
            Invoice No
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "invoice_no" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "invoice_no" && sortField?.order === "DESC"
                ? true
                : false
            }
            ascendingOnClick={() =>
              setAscendingOrderClicked(!descendingOrderClicked)
            }
            descendingOnClick={() =>
              setDescendingOrderClicked(!ascendingOrderClicked)
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.invoice_no}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "created_at",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_at")}
        >
          <Typography variant="h5" fontSize={14}>
            Date
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_at" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_at" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {moment(row?.created_at).format("DD/MM/YYYY hh:mm:ss A")}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "from_dept_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("from_dept_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Issuer
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "from_dept_name" &&
              sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "from_dept_name" &&
              sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.from_dept_name}
        </Typography>
      ),
      minWidth: 140,
      sortable: false,
    },
    {
      field: "to_dept_name",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("to_dept_name")}
        >
          <Typography variant="h5" fontSize={14}>
            Receiver
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "to_dept_name" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "to_dept_name" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.to_dept_name}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "bill_amt",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("bill_amt")}
        >
          <Typography variant="h5" fontSize={14}>
            Bill Amount
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "bill_amt" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "bill_amt" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.bill_amt}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "created_user",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("created_user")}
        >
          <Typography variant="h5" fontSize={14}>
            User
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "created_user" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "created_user" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.created_user}
        </Typography>
      ),
      minWidth: 120,
      sortable: false,
    },
    {
      field: "type",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("type")}
        >
          <Typography variant="h5" fontSize={14}>
            Type
          </Typography>
          <Sort
            ascendingActive={
              sortField?.field === "type" && sortField?.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortField?.field === "type" && sortField?.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.type}
        </Typography>
      ),
      minWidth: 160,
      sortable: false,
    },

    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const clearFilters = () => {
    if (
      filterData.invoice_no ||
      filterData.product ||
      filterData.from_date ||
      filterData.to_date ||
      filterData.type
    ) {
      dispatch(
        setFilterDataValues({
          key: "goodsIssue",
          name: "clearFilter",
          value: initialFilterData,
        })
      );
    }
  };

  const handleInputChange = (e: any) => {
    if (e) {
      const { name, value } = e.target;
      dispatch(
        setFilterDataValues({ key: "goodsIssue", value: value, name: name })
      );
      dispatch(setCurrentPage({ key: "goodsIssue", value: 0 }));
    }
  };

  const handleDateChange = (e: any, newValue: any, name: any) => {
    if (name && newValue) {
      dispatch(
        setFilterDataValues({
          key: "goodsIssue",
          value: newValue.validatedValue[0],
          name: name,
        })
      );
      dispatch(setCurrentPage({ key: "goodsIssue", value: 0 }));
    }
  };

  const appointmentFilter = [
    {
      children: (
        <TextField
          value={filterData.invoice_no}
          placeholder={"Enter Invoice No"}
          label={"Invoice No"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"invoice_no"}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
    {
      children: (
        <TextField
          value={filterData.product}
          placeholder={"Enter Product Name"}
          label={"Product Name"}
          formControlStyle={{ width: "100%" }}
          onChange={handleInputChange}
          name={"product"}
          sx={{
            ...style.textFieldStyle,
          }}
        />
      ),
    },
    {
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="From Date"
            name="from_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "from_date")
            }
            format="DD/MM/YYYY"
            value={filterData.from_date}
            style={{ boxShadow: "none", height: "45px", borderRadius: "5px" }}
            borderColor="var(--primary-border-color)"
          />
        </Grid>
      ),
    },
    {
      children: (
        <Grid
          sx={{
            ...style.DatePickerStyle,
          }}
        >
          <DatePicker
            label="To Date"
            name="to_date"
            placeholder="Select Date"
            onChange={(e: any, newValue: any) =>
              handleDateChange(e, newValue, "to_date")
            }
            format="DD/MM/YYYY"
            value={filterData.to_date}
            style={{ boxShadow: "none", height: "45px", borderRadius: "5px" }}
            borderColor="var(--primary-border-color)"
          />{" "}
        </Grid>
      ),
    },
    {
      children: (
        <Select
          placeholder="Bill Type"
          label="Bill Type"
          name="type"
          value={filterData.type}
          sx={{ ...style.selectStyle }}
          width="255px"
          onChange={handleInputChange}
          options={[
            { id: "bill_list", name: "Bill List" },
            {
              id: "approval_list",
              name: "Approval List",
            },
          ]}
        />
      ),
    },
  ];

  const debouncedGetAllPurchaseBill = useCallback(
    debounce((data: any) => {
      setLoading(true);
      getAllGoodsIssue({
        page: data.page,
        limit: data.pageSize,
        column: data.sortBy,
        order: data.sortOrder,
        from_date: data.fromDate
          ? data.fromDate.split("/").reverse().join("-")
          : "",
        to_date: data.toDate ? data.toDate.split("/").reverse().join("-") : "",
        type: data.type,
        invoice_no: data.invoice_no,
        product: data.product,
        search: data.search,
      })
        .then((res: any) => {
          setGoodsIssueList(res.data.result as []);
          setPageCount(res.data.total as any);
          setLoading(false);
        })
        .catch((err) => {
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage.ERROR_FETCHING_GOODS_ISSUE}`,
            })
          );
          setLoading(false);
          console.log("error getAppointmentList", err);
        });
    }, 300),
    []
  );
  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortField?.field,
      sortOrder: sortField?.order,
      fromDate: filterData.from_date,
      toDate: filterData.to_date,
      invoice_no: filterData.invoice_no,
      product: filterData.product,
      type: filterData.type,
      search: filterData.search,
    };
    debouncedGetAllPurchaseBill(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortField?.order,
    sortField?.field,
    filterData.from_date,
    filterData.to_date,
    filterData.from_date,
    filterData.to_date,
    filterData.product,
    filterData.invoice_no,
    filterData.type,
    filterData.search,
  ]);
  return (
    <Box>
      <CustomFilter
        data={appointmentFilter}
        isFilterEnabled={true}
        isSearchEnabled={true}
        editButtonTitle="+ ADD"
        clearFilter={clearFilters}
        searchOnChange={handleInputChange}
        searchName={"search"}
        searchValue={filterData.search}
        onUpdateButtonClick={() =>
          navigate(`${procurementUrl}${goodsIssueUrl}${createUrl}`)
        }
        appliedFilterCount={count}
        clearSearch={() =>
          dispatch(
            setHandleClearSearch({
              key: "goodsIssue",
              value: "",
              name: "search",
            })
          )
        }
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={goodsIssueList}
        pageCount={pageCount}
        currentPage={"goodsIssue"}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you surely want to delete?"
        handleClick={deleteGoodsIssue}
        onClose={onClose}
        loading={deleteBtnLoader}
      />
    </Box>
  );
};

export default GoodsIssueList;
