import { Grid, TableCell, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

type ProductRowProps = {
  styles?: any;
  row?: any;
  index?: number;
};

const ProductRow = React.memo(({ styles, row, index }: ProductRowProps) => {
  const { commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [indentStatusOptions, setIndentStatusOptions] = useState([]);

  const getIndentState = (status: number | string) => {
    const currentStatus = indentStatusOptions?.find(
      (ele: any) => ele.id === status
    ) as any;

    return currentStatus?.value || "";
  };

  useEffect(() => {
    if (commonVariables?.indent_product_status) {
      setIndentStatusOptions(commonVariables?.indent_product_status);
    }
  }, [commonVariables]);

  return (
    <TableRow
      sx={{
        "& td, & th": { border: 0 },
        width: "90vw",
      }}
    >
      <TableCell
        className="last-cell"
        align="center"
        sx={{
          width: "15%",
          position: "relative",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            ml: "10px",
          }}
        >
          <Typography
            sx={{
              ...styles.textStyle,
            }}
          >
            {row?.product_name}
          </Typography>
        </Grid>
      </TableCell>
      <TableCell
        className="last-cell"
        align="center"
        sx={{
          width: "15%",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            ...styles.textStyle,
          }}
        >
          {row?.department_name}
        </Typography>
      </TableCell>
      <TableCell
        className="last-cell"
        align="center"
        sx={{
          width: "10%",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            ...styles.textStyle,
          }}
        >
          {row?.requirement}
        </Typography>
      </TableCell>
      <TableCell
        className="last-cell"
        align="center"
        sx={{
          width: "10%",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            ...styles.textStyle,
          }}
        >
          {row?.issued_qty}
        </Typography>
      </TableCell>
      <TableCell
        className="last-cell"
        align="center"
        sx={{
          width: "10%",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            ...styles.textStyle,
          }}
        >
          {getIndentState(row.status)}
        </Typography>
      </TableCell>
    </TableRow>
  );
});

export default ProductRow;
