import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
} from "@mui/material";
import { Button, PageLoader } from "../../../../components/basic";
import Info from "./Info";
import ProductRow from "./ProductRow";
import Summary from "./Summary";
import { billProductsConst } from "../../../../constants/displayText";
import {
  formattedDateWithSlash,
  timeWithTwentyFourHoursConverter,
} from "../../../../utils/DateTimeFormatUtils";
import { formula } from "../../../../utils/CalculationUtils";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import { useNavigate, useParams } from "react-router-dom";
import {
  addWholeSalesPurchaseBillById,
  updateWholeSalesPurchaseBillById,
  getTaxOptions,
  getWholeSalesAdvanceAndExcessByCustomer,
} from "../../../../services/procurementService";
import { getWholeSalesById } from "../../../../services/gynecologyService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { DeleteAlertIcon } from "../../../../assets/icons";
import moment from "moment";
import { RouteUrls } from "../../../../constants/routes";
import PriceMarginModal from "./PriceMarginModal";
import { getWholeSalesProduct } from "../../../../services/gynecologyService";
import { getDepartmentSequence } from "../../../../services/mainCoreService";

const { procurementUrl, wholeSalesUrl } = RouteUrls;
type BillProduct = {
  rate: any;
  id: number | null;
  product_id: number | null;
  pack_type: string;
  qty: number | null;
  free: number | null;
  discount: number | null;
  row_discount_type: string | null;
  batch_no: string | null;
  barcode: string | null;
  tax_type: number | null;
  p_rate: number | null;
  mrp: number | null;
  amount: number | null;
  product_name: any;
  autocomplete_input_value: string | number | null;
  expires_at: Date | string | null;
  discount_amount: number | null;
  tax_amount: number | null;
  used_qty: number | string;
  used_free: number | string;
  original_qty: number | null;
};

export default function BillProducts() {
  const {
    productName,
    usedQty,
    usedFree,
    packType,
    qty,
    free,
    batchNo,
    expiryDate,
    tax,
    ptr,
    mrp,
    amount,
  } = billProductsConst;
  const navigate = useNavigate();

  const {
    calculateTotalDiscountAmount,
    calculateDiscountAmount,
    calculateGoodsValue,
    calculateSubTaxes,
    calculateAmount,
    calculateBillTotal,
    calculateBillDataDiscountAmount,
    calculateRoundedOff,
    calculateNetAmount,
    calculateTotalTaxAmount,
  } = formula.purchaseBill;

  const dispatch = useDispatch();

  const styles = useMemo(
    () => ({
      tableContainerStyle: {
        borderRadius: "5px",
        boxShadow: "none",
        overflow: "hidden",
        width: "92vw",
        overflowX: { xs: "scroll", lg: "hidden" },
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },

      tableStyle: {
        borderRadius: "5px",
        border: "1px solid",
        borderColor: "var(--table-border)",
        width: {
          xs: "400vw",
          sm: "200vw",
          md: "120vw",
          lg: "90vw",
        },
        "& .MuiTableHead-root": { border: 0, borderRadius: "5px" },
        "& .MuiTableRow-head": {
          backgroundColor: "var(--table-header)",
        },
        " & .MuiTableBody-root": {
          border: 0,
        },
        "& .MuiTableRow-root": {
          height: "34px !important",
          maxHeight: "34px !important",
          minHeight: "0px",
          lineHeight: "0px",
          border: 0,
        },

        "& .MuiTableCell-root": {
          minHeight: "0px",
        },

        "& .MuiTableCell-head": {
          height: "43px",
          maxHeight: "43px",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 10px",
          fontSize: "13px",
          fontWeight: "600",
          borderBottom: 0,
          borderRight: 1,
          borderRadius: "5px",
          borderColor: "var(--table-border)",
        },

        "& .MuiTableCell-body": {
          height: "32px !important",
          maxHeight: "32px !important",
          minHeight: "0px",
          lineHeight: "0px",
          p: "0px 0px",
          fontSize: "12px",
          fontWeight: "400",
          border: 1,
          borderColor: "var(--table-border)",
        },

        "& .MuiTableCell-body:has(.Mui-focused)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "31px",
        },

        "& .MuiTableCell-body:has(.css-1t4mgmb-MuiGrid-root)": {
          borderColor: "primary.main",
          borderWidth: "2px",
          p: "0px",
          height: "30px",
        },
        "& .deleteIconGrid": {
          cursor: "pointer",
          position: "absolute",
          right: {
            xs: "-10vw",
            sm: "-10vw",
            md: "-6vw",
            lg: "-2vw",
          },

          bottom: "5px",
        },
        "& .delete-icon": {
          mr: {
            xs: "10px",
            sm: "40px",
            md: "20px",
            lg: "0px",
          },
          mb: {
            xs: "5px",
          },
        },
      },
      dialogTitle: { p: "20px 24px" },
      textFieldStyle: {
        height: "34px",
        width: "auto",
        fontSize: "6px",

        "&>*": {
          border: 0,
        },
        "& .MuiOutlinedInput-root": {
          height: "34px",
          borderRadius: "0px",
          paddingLeft: "0px",
        },
        "& .MuiOutlinedInput-root.Mui-focused": {
          borderColor: "primary.main",
        },
        "& .MuiInputBase-input": {
          padding: "0px 10px",
          fontSize: "12px",
          fontWeight: "400",
          textAlign: "right",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "0px",
          height: "32px",
          maxHeight: "32px",
          top: 0,
        },

        "& input::placeholder": {
          fontSize: "12px",
        },
      },
    }),
    []
  );

  const { id } = useParams();
  const { appConfiguration, commonVariables } = useSelector(
    (state: RootState) => state.appConfiguration
  );
  const [batchIdNo, setBatchIdNo] = useState<number[] | []>([]);

  const TableHeaderCells: any = [
    { TableCellName: productName, alignment: "left" },

    { TableCellName: packType, alignment: "right" },
    { TableCellName: batchNo, alignment: "left" },
    { TableCellName: expiryDate, alignment: "left" },
    ...(id ? [{ TableCellName: usedQty, alignment: "right" }] : []),
    ...(id ? [{ TableCellName: usedFree, alignment: "right" }] : []),
    { TableCellName: qty, alignment: "right" },
    { TableCellName: free, alignment: "right" },
    { TableCellName: ptr, alignment: "left" },
    { TableCellName: mrp, alignment: "right" },
    { TableCellName: "Rate", alignment: "left" },
    ...(appConfiguration?.purchase_discount !== "overall" ||
    appConfiguration?.purchase_discount === "both"
      ? [{ TableCellName: "Discount (%)", alignment: "left" }]
      : []),
    { TableCellName: tax, alignment: "left" },
    {
      TableCellName: amount,
      alignment: "right",
      sx: { border: 0, borderRadius: "5px" },
    },
  ];

  const [taxOptions, setTaxOptions] = useState<any>([]);
  const [allProducts, setAllProducts] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const initialBillProduct = {
    id: 0,
    product_id: null,
    pack_type: "",
    qty: null,
    free: null,
    discount: "",
    row_discount_type: "₹",
    batch_no: "",
    barcode: "",
    tax_type: null,
    expires_at: null,
    p_rate: null,
    mrp: null,
    amount: null,
    pack: "",
    product_name: null,
    autocomplete_input_value: "",
    used_qty: "",
    used_free: "",
    original_qty: null,
    discount_amount: null,
    tax_amount: null,
  };

  const [billProducts, setBillProducts] = useState<BillProduct[]>([
    initialBillProduct as any,
  ]);
  const [isOpenPriceMargin, setIsOpenPriceMargin] = useState({
    isOpen: false,
    product_id: null,
  });
  const initialSummary = {
    total_discount_amount: 0,
    goods_value: 0,
    disc_goods_val: 0,
    CGST: 0,
    SGST: 0,
    total_tax: 0,
    bill_total: 0,
    bill_discount: null,
    bill_discount_type: "₹",
    bill_discount_amount: 0,
    rounded_off: null,
    adjustment: null,
    net_amount: 0,
    payment_method: "Cash",
    cash_paid: null,
    amount_paid: null,
    balance: null,
    refunded_amount: null,
    excess_amount: null,
    net_excess: null,
  };

  const [summary, setSummary] = useState<any>(initialSummary);

  const initialInfoError = {
    dept_id: "",
    customer_id: "",
    date_created: "",
    invoice_no: "",
    invoice_no_modified: "",
  };

  const [infoError, setInfoError] = useState<any>(initialInfoError);
  const [productError, setProductErrors] = useState<any>([]);
  const [summaryError, setSummaryErrors] = useState<any>({});

  const initialInfoData = {
    dept_id: null,
    customer_id: null,
    address: "",
    mobile_no: "",
    date_created: new Date(),
    invoice_no: null,
    invoice_no_modified: null,
    payment: 0,
  };

  const [infoData, setInfoData] = useState<any>(initialInfoData);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState<{
    isOpen: boolean;
    content: string;
  }>({
    isOpen: false,
    content: "",
  });

  const [customSequence, setCustomSequence] = useState(true);
  const [autoGeneratedSeqNo, setAutoGeneratedSeqNo] = useState("");
  const [sequenceNo, setSequenceNo] = useState("");
  const [sequenceData, setSequenceData] = useState<any>([
    { id: null, type: "", value: "", separator: "" },
  ]);
  const [isManual, setIsManual] = useState({
    is_manual: false,
    is_auto_generate: false,
    is_once: false,
  });
  const [isManualAdded, setIsManualAdded] = useState(false);

  const [seqIsOnce, setSeqIsOne] = useState({
    isOnce: false,
    isOnceSeqValue: "",
  });

  const [initialSequenceNumber, setInitialSequenceNumber] = useState<
    string | number | undefined
  >("");

  const fieldData = {
    dept_id: {
      label: "Department",
      name: "dept_id",
      value: infoData.dept_id,
      placeholder: "Select Department",
      isError: infoError.dept_id === "" ? false : true,
      helperText: infoError.dept_id,
      isDisabled: id ? true : false,
    },
    customer_id: {
      label: "Customer",
      name: "customer_id",
      value: infoData.customer_id,
      placeholder: "Select Customer",
      isError: infoError.customer_id === "" ? false : true,
      helperText: infoError.customer_id,
      isDisabled: id ? true : false,
    },
    mobile_no: {
      label: "Mobile No",
      name: "mobile_no",
      value: infoData.mobile_no,
      isError: infoError.mobile_no === "" ? false : true,
      helperText: infoError.mobile_no,
      isDisables: false,
    },
    address: {
      label: "Address",
      name: "address",
      value: infoData.address,
      isError: infoError.address === "" ? false : true,
      helperText: infoError.address,
      isDisables: false,
    },
    date_created: {
      label: "Invoice Date",
      name: "date_created",
      value: infoData.date_created,
      isError: infoError.date_created === "" ? false : true,
      helperText: infoError.date_created,
      isDisables: false,
    },
    invoice_no: {
      label: "Invoice No",
      name: "invoice_no",
      value: infoData.invoice_no,
      placeholder: "Enter Invoice No",
      isError: infoError.invoice_no === "" ? false : true,
      helperText: infoError.invoice_no,
      isDisables: false,
    },
    payment: {
      label: "Payment",
      name: "payment",
      value: infoData.payment,
      placeholder: "Select Payment",
      isError: infoError.payment === "" ? false : true,
      helperText: infoError.payment,
      isDisabled: id ? true : false,
    },
  };

  //form validation functions
  const [formError, setFormError] = useState<
    {
      qty: boolean;
      free: boolean;
    }[]
  >([
    {
      qty: false,
      free: false,
    },
  ]);
  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "dept_id":
      case "customer_id": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setInfoError
          );
        } else {
          updateFormDataWithHelperText(name, "", setInfoError);
        }
        break;
      }
      default:
        break;
    }
  };
  const handleInputValueValidation = (
    e: any,
    updatedValue: any,
    idx: number | string
  ) => {
    const { name, value } = e.target;

    switch (name) {
      case "qty": {
        if (value > Number(updatedValue.stock_qty)) {
          setFormError((prevState: any) => {
            const newState = [...prevState];
            newState[idx as number]["qty"] = true;
            return newState;
          });
        } else if (
          value >
          Number(updatedValue.stock_qty | 0) - Number(updatedValue.free | 0)
        ) {
          setFormError((prevState: any) => {
            const newState = [...prevState];
            newState[idx as number]["qty"] = true;
            return newState;
          });
        } else if (
          Number(updatedValue.stock_qty) >= Number(value + updatedValue.free) &&
          formError[idx as number]["free"]
        ) {
          setFormError((prevState: any) => {
            const newState = [...prevState];
            newState[idx as number]["free"] = false;
            return newState;
          });
        } else {
          setFormError((prevState: any) => {
            const newState = [...prevState];
            newState[idx as number]["qty"] = false;
            return newState;
          });
        }
        break;
      }
      case "free": {
        if (
          value >
          Number(updatedValue.stock_qty || 0) - Number(updatedValue.qty || 0)
        ) {
          setFormError((prevState: any) => {
            const newState = [...prevState];
            newState[idx as number]["free"] = true;
            return newState;
          });
        } else if (
          Number(updatedValue.stock_qty) >= Number(value + updatedValue.qty) &&
          formError[idx as number]["qty"]
        ) {
          setFormError((prevState: any) => {
            const newState = [...prevState];
            newState[idx as number]["qty"] = false;
            return newState;
          });
        } else {
          setFormError((prevState: any) => {
            const newState = [...prevState];
            newState[idx as number]["free"] = false;
            return newState;
          });
        }
        break;
      }
      default:
        break;
    }
  };
  const handleInputChange = useCallback(
    (e: any, index: number, row?: any) => {
      const { name, value } = e.target;

      let numericValue = value;

      if (
        name !== "batch_no" &&
        name !== "row_discount_type" &&
        name !== "tax"
      ) {
        numericValue = +e?.target?.value?.replace(/[^\d.]/g, "");
      }
      setBillProducts((prev: any) => {
        const newBillProducts = prev.map((billProduct: any, i: any) => {
          const newBillProduct: any = { ...billProduct };
          let disc_value: number | string = 0;
          if (i === index) {
            newBillProduct[name] = numericValue;
            handleInputValueValidation(e, newBillProduct, index);
            if (isNaN(newBillProduct.rate) || isNaN(newBillProduct.qty)) {
              newBillProduct.amount = 0;
            } else {
              newBillProduct.amount = (
                newBillProduct.qty * newBillProduct.rate
              ).toFixed(2);
            }
            if (
              isNaN(newBillProduct.discount) ||
              isNaN(newBillProduct.amount)
            ) {
              disc_value = 0;
            } else {
              disc_value = (
                (Number(newBillProduct.discount) / 100) *
                newBillProduct.amount
              ).toFixed(2);
            }
            newBillProduct.amount =
              newBillProduct.amount - Number(disc_value || 0);
            newBillProduct.discount_amount = Number(disc_value || 0);
            var price_exc_tax_amount = 0;
            if (isNaN(newBillProduct?.product_name?.tax_id)) {
              newBillProduct.tax_amount = "";
            } else {
              price_exc_tax_amount =
                newBillProduct.amount /
                (1 + newBillProduct?.product_name?.tax_id / 100);

              if (appConfiguration?.B2B_tax === "exclusive") {
                newBillProduct.tax_amount =
                  (newBillProduct.amount *
                    newBillProduct?.product_name?.tax_id) /
                  100;
              } else {
                newBillProduct.tax_amount =
                  (price_exc_tax_amount *
                    newBillProduct?.product_name?.tax_id) /
                  100;
              }
            }
          }
          return newBillProduct;
        });
        setSummary((prev: any) => {
          const newState = { ...prev };
          const { CGST, SGST } = calculateSubTaxes(newBillProducts) || 0;
          newState.CGST = CGST;
          newState.SGST = SGST;
          newState.total_discount_amount =
            calculateTotalDiscountAmount(newBillProducts) || 0;
          newState.goods_value =
            calculateGoodsValue(newBillProducts, appConfiguration?.B2B_tax) ||
            0;
          newState.disc_goods_val =
            calculateGoodsValue(newBillProducts, appConfiguration?.B2B_tax) ||
            0;
          newState.total_tax = calculateTotalTaxAmount(newBillProducts) || 0;
          newState.bill_total = calculateBillTotal(newState);
          newState.bill_discount_amount =
            calculateBillDataDiscountAmount(newState);
          newState.rounded_off = calculateRoundedOff(newState);
          newState.net_amount = calculateNetAmount(newState);
          newState.amount_paid = 0;
          newState.balance = calculateNetAmount(newState);
          newState.excess_amount = 0;
          newState.refunded_amount = 0;
          newState.net_excess = 0;
          return newState;
        });
        return newBillProducts;
      });
    },
    [appConfiguration]
  );

  const handleChangeSummary = (e: any) => {
    const { name, value } = e.target;
    let numericValue = value;
    if (name !== "payment_method" && name !== "bill_discount_type") {
      numericValue = value.replace(/\D/g, "");
    }

    setSummary((prev: any) => {
      const newState = { ...prev };
      newState[name] = numericValue;
      if (
        name === "adjustment" ||
        name === "bill_discount" ||
        name === "bill_discount_type"
      ) {
        newState.bill_discount_amount =
          calculateBillDataDiscountAmount(newState);
        newState.rounded_off = calculateRoundedOff(newState);
        newState.net_amount = calculateNetAmount(newState);
        newState.amount_paid = 0.0;
        newState.balance = calculateNetAmount(newState);
        newState.excess_amount = 0;
        newState.refunded_amount = 0.0;
        newState.net_excess = 0;
      }

      if (name === "cash_paid") {
        newState.balance =
          newState.net_amount <= numericValue
            ? 0
            : newState.net_amount - numericValue;
        newState.amount_paid =
          newState.net_amount > numericValue
            ? numericValue
            : newState.net_amount;

        if (numericValue > newState.net_amount) {
          newState.excess_amount = numericValue - newState.net_amount;
          newState.balance = 0;
          newState.net_excess =
            newState.excess_amount - newState.refunded_amount;
        } else {
          newState.excess_amount = 0;
          newState.net_excess = 0;
        }
      }

      if (name === "cash_paid" || name === "payment_method") {
        if (
          newState.payment_method === "Excess" &&
          newState.cash_paid > newState.excess
        ) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            cash_paid: "Paid amount is not exceeded than the excess",
          }));
        } else if (
          newState.payment_method === "Advance" &&
          newState.cash_paid > newState.advance
        ) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            cash_paid: "Paid amount is not exceeded than the Advance",
          }));
        } else {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            cash_paid: "",
          }));
        }

        if (newState.refunded_amount > newState.excess_amount) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "refunded amount is greater than excess amount",
          }));
        } else {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "",
          }));
        }
      }

      if (name === "refunded_amount") {
        newState.refunded_amount = numericValue;
        newState.net_excess = newState.excess_amount - newState.refunded_amount;

        if (numericValue > newState.excess_amount) {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "refunded amount is greater than excess amount",
          }));
        } else {
          setSummaryErrors((prevState: any) => ({
            ...prevState,
            refunded_amount: "",
          }));
        }
      }

      return {
        ...newState,
      };
    });
  };
  useEffect(() => {
    if (infoData.dept_id) {
      const data: any = {
        dept_id: infoData.dept_id,
        selected_batches: batchIdNo,
      };
      getWholeSalesProduct(data)
        .then((res: any) => setAllProducts(res.data))
        .catch((err) => console.log(err));
    }
  }, [infoData.dept_id]);

  useEffect(() => {
    if (appConfiguration?.custom_sequence === false) {
      setCustomSequence((prev) => (prev = false));
    }
  }, [appConfiguration]);

  const handleDateChange = useCallback(
    (index: any, newValue: any, name: any) => {
      setBillProducts((prevBillProduct: any) =>
        prevBillProduct.map((row: any, i: number) =>
          i === index ? { ...row, [name]: newValue.validatedValue[0] } : row
        )
      );
    },
    []
  );

  const handleAutoCompleteChange = useCallback(
    (newValue: any, index: number, name: string) => {
      if (newValue === null) {
        return false;
      }
      setBillProducts((prevState: any) => {
        let newState = [...prevState];

        if (newState.length > 1) {
          newState[index as number] = {
            ...newState[index as number],
            [name]: newValue,
            mrp: newValue.mrp,
            p_rate: newValue.p_rate,
            product_id: newValue.product_id,
            expires_at: newValue.expires_at
              ? formattedDateWithSlash(newValue?.expires_at)
              : "",
            batch_no: `${newValue.batch_no}#${newValue.stock_qty}`,
            bill_batch_id: newValue.batch_id,
            amount: 0,
            // discount: `${newValue.discount || ""}`,
            row_discount_type: newValue.discount_type,
            tax_data: `${newValue.taxname}-${newValue.tax_id}%`,
            stock_qty: newValue.stock_qty,
            rate: newValue.rate,
            tax: {
              id: newValue.id,
              name: newValue.tax,
              tax_name: newValue.taxname,
              tax_rate: newValue.tax_id,
              sub_taxes: newValue.subtax,
            },
            pack_type: newValue.pack_type,
          };
        }
        if (newState.length === 1) {
          newState[index as number] = {
            ...newState[index as number],
            [name]: newValue,
            pack_type: newValue.pack_type,
            row_discount_type: newValue.discount_type,
            expires_at: newValue?.expires_at
              ? formattedDateWithSlash(newValue?.expires_at)
              : "",
            product_id: newValue.product_id,
            p_rate: newValue.p_rate,
            mrp: newValue.mrp,
            stock_qty: newValue.stock_qty,
            bill_batch_id: newValue.batch_id,
            batch_no: `${newValue.batch_no}#${newValue.stock_qty}`,
            amount: 0,
            rate: newValue.rate,
            // discount: `${newValue.discount || ""}`,
            tax_data: `${newValue.taxname}-${newValue.tax_id}%`,
            tax: {
              id: newValue.id,
              name: newValue.tax,
              tax_name: newValue.taxname,
              tax_rate: newValue.tax_id,
              sub_taxes: newValue.subtax,
            },
          };
        }
        setBatchIdNo((prev) => [...prev, newValue?.batch_id]);
        return newState;
      });
      setFormError((prev) => [...prev, { qty: false, free: false }]);
    },
    []
  );

  const handleClick = useCallback(() => {
    setBillProducts((prevBillProduct: any) => [
      ...prevBillProduct,
      {
        ...initialBillProduct,
        id: prevBillProduct.length,
      },
    ]);
    if (billProducts.length > 0) {
      setFormError((prev: any) => [...prev, { qty: false, free: false }]);
    }
  }, []);

  const handleDelete = useCallback(
    (id: number, batchNo?: string) => {
      const newBillProducts: any = [...billProducts];
      newBillProducts.splice(id, 1);
      setBillProducts(newBillProducts);
      if (billProducts.length > 0) {
        const newErrorValue: any = [...formError];
        newErrorValue.splice(id, 1);
        setFormError(newErrorValue);
      }
      if (batchNo) {
        const newBatchIds = [...batchIdNo];
        const findBatchIndex = batchIdNo.findIndex(
          (ele) => ele === Number(batchNo)
        );
        newBatchIds.splice(findBatchIndex, 1);
        setBatchIdNo(newBatchIds);
      }
    },
    [batchIdNo, billProducts, formError]
  );

  const handleOverAllValidation = (info: any, products: any, summary: any) => {
    const infoRequiredFields = [
      "supplier_id",
      "invoice_no",
      "customer_id",
      "invoice_date",
    ];
    infoRequiredFields.push(appConfiguration?.B2B_tax ? "to_dept" : "dept_id");
    const productsRequiredFields = ["product_id", "product_name", "qty"];
    const infoErrors = {} as any;
    const productsError = [] as any;

    infoRequiredFields.forEach((key) => {
      if (!info[key]) {
        infoErrors[key] = "required field";
      }
    });

    products.forEach((ele: any) => {
      const productError: { [key: string]: string } = {};
      productsRequiredFields.forEach((key) => {
        if (!ele[key]) {
          productError[key] = "required field";
        }
      });
      productsError.push(productError);
    });

    let productsHasError = false;

    if (productsError?.length > 0) {
      productsError?.forEach((item: any) => {
        if (Object.keys(item).length > 0) {
          productsHasError = true;
        }
      });
    }

    if (Object.keys(infoErrors)?.length > 0 && productsHasError) {
      setInfoError(infoErrors);
      setProductErrors(productsError);
      return false;
    }

    if (
      summary.payment_method === "Excess" &&
      summary.cash_paid > summary.excess
    ) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        cash_paid: "The paid amount cannot exceed the excess amount",
      }));
      setIsOpenErrorModal({
        isOpen: true,
        content: "The paid amount cannot exceed the excess amount",
      });
      return false;
    } else if (
      summary.payment_method === "Advance" &&
      summary.cash_paid > summary.advance
    ) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        cash_paid: "The paid amount cannot exceed the advance amount",
      }));
      setIsOpenErrorModal({
        isOpen: true,
        content: "The paid amount cannot exceed the advance amount",
      });
      return false;
    }

    if (summary?.refunded_amount > summary.excess_amount) {
      setSummaryErrors((prevState: any) => ({
        ...prevState,
        refunded_amount: "refunded amount is greater than excess amount",
      }));
      setIsOpenErrorModal({
        isOpen: true,
        content: "The refunded amount cannot exceed the excess amount",
      });
      return false;
    }

    return true;
  };
  console.log(summary);

  const handleSaveData = useCallback(() => {
    if (handleOverAllValidation(infoData, billProducts, summary)) {
      setIsSaveLoading(true);
      const formattedProducts = billProducts.map((ele) => ({
        product_id: ele.product_id,
        batch_no: ele.product_name.batch_no,
        expires_at: ele.product_name.expires_at,
        pack_type: ele.product_name.pack_type,
        qty: ele.qty,
        free_qty: ele.free,
        p_rate: ele.p_rate,
        mrp: ele.mrp,
        supplier_id: ele.product_name.supplier_id,
        batch_id: ele.product_name.batch_id,
        rate: ele.rate,
        discount: ele.discount,
        tax_id: ele.product_name.tax_id,
        tax_type: ele.product_name.tax_type,
        amount: ele.product_name.amount,
      }));
      const createOrUpdateData = {
        dept_id: infoData.dept_id,
        customer_id: infoData.customer_id,
        date_created: infoData.date_created
          ? timeWithTwentyFourHoursConverter(infoData.date_created)
          : "",
        invoice_no: infoData.invoice_no,
        invoice_no_modified: "0",
        bill_payment_type: infoData.payment,
        product: [...formattedProducts],
        disc_val: summary.disc_val,
        orig_goods_val: summary.goods_value,
        disc_goods_val: summary.disc_goods_val,
        tax_split_info: JSON.stringify({
          CGST: summary.CGST,
          SGST: summary.SGST,
        }),
        print: "half",
        gst_amt: summary.total_tax,
        bill_amt: summary.bill_total || "",
        discount_val: summary.discount_val || "",
        discount_type: summary.discount_type,
        discount_amt: summary.discount_amt,
        adj_amt: summary.adj_amt,
        net_total_amt: summary.net_amount,
        creditlimit_amt: summary.creditlimit,
        payments: [
          {
            type: summary.payment_method,
            cash_paid: summary.cash_paid,
          },
        ],
        cash_received: summary.cash_paid,
        pay_paid_amt: summary.amount_paid,
        balance: summary.balance,
        excess_amount: summary.excess_amount,
        refunded_excess: summary.refunded_amount,
        net_excess: summary.net_excess,
      };
      if (id) {
        updateWholeSalesPurchaseBillById(id, createOrUpdateData)
          .then((res: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: res.message,
              })
            );
            setIsSaveLoading(false);
            navigate(`${procurementUrl}${wholeSalesUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      } else {
        addWholeSalesPurchaseBillById(createOrUpdateData)
          .then((res: any) => {
            dispatch(
              setSnackBarSuccess({
                snackBarMessage: res.message,
              })
            );
            setIsSaveLoading(false);
            navigate(`${procurementUrl}${wholeSalesUrl}`);
          })
          .catch((err) => {
            console.log(err);
            setIsSaveLoading(false);
          });
      }
    }
  }, [billProducts, summary, infoData]);
  console.log(billProducts);
  useEffect(() => {
    getTaxOptions().then((res: any) => {
      if (res.data) {
        setTaxOptions(
          res.data?.map((ele: any) => ({
            ...ele,
            name: `${ele.name}-${ele.tax_rate}%`,
          }))
        );
      }
    });
    // getExcessAmountByPatientId()
    //   .then((res) => {
    //     if (res.data) {
    //       setSummary((prevState: any) => ({
    //         ...prevState,
    //         excess: res?.data?.excess,
    //         advance: res?.data?.advance,
    //       }));
    //     }
    //   })
    //   .catch((err) => console.log(err));
  }, []);

  const calculateUsedQty = (product: any) => {
    if (product.adj_qty < 0) {
      const usedQty =
        (product.sale_qty +
          product.return_qty +
          product.free_return_qty -
          product.adj_qty) /
        product.pack_type;

      return Math.ceil(usedQty) || 0;
    } else {
      const usedQty =
        (product.sale_qty + product.return_qty + product.free_return_qty) /
        product.pack_type;
      return Math.ceil(usedQty) || 0;
    }
  };

  const getTaxOption = async (product: any) => {
    const res: any = await getTaxOptions();

    const tax = res?.data?.find((ele: any) => ele.id === product.tax_type);

    if (tax) {
      tax.name = `${tax.name}-${tax.tax_rate}%`;
    }

    return tax;
  };
  useEffect(() => {
    if (infoData.customer_id) {
      getWholeSalesAdvanceAndExcessByCustomer(infoData.customer_id as number)
        .then(
          (res: {
            data: {
              advance: number;
              creditlimit: number;
              excess: number;
              overalldue: number;
            };
          }) => {
            setSummary((prevState: any) => ({
              ...prevState,
              excess: res?.data?.excess,
              advance: res?.data?.advance,
              creditlimit: res?.data?.creditlimit,
              overalldue: res?.data?.overalldue,
            }));
          }
        )
        .catch((err) => dispatch(setSnackBarFailed({ snackBarMessage: err })));
    }
  }, [infoData.customer_id]);
  const calculateRowAmount = (product: any) => {
    const qty = Number(product.original_qty) / Number(product.pack_type) || 0;
    const p_rate = Number(product.p_rate) || 0;
    const discount = product.discount || 0;
    const row_discount_type = product.discount_type || 0;
    const discount_amount = calculateDiscountAmount({
      qty,
      p_rate,
      discount,
      row_discount_type,
    });

    const amount = calculateAmount({ qty, p_rate, discount_amount });

    return amount.toFixed(2) || 0;
  };

  let isCreatePrescription = true;
  let isSequenceNumberChanged = false;
  const handleSequence = () => {
    getDepartmentSequence("B2B")
      .then((res: any) => {
        let data = res?.data[0]?.sequence_no;

        if (isCreatePrescription && !isSequenceNumberChanged) {
          setInitialSequenceNumber(data);
          setInfoData((prevState: any) => ({
            ...prevState,
            invoice_no: res?.data[0]?.sequence_no,
          }));

          setInfoError((prevState: any) => ({
            ...prevState,
            invoice_no: "",
          }));
        }

        if (isSequenceNumberChanged) {
          setInfoData((prevState: any) => ({
            ...prevState,
            invoice_no: res?.data[0]?.sequence_no,
          }));
          setInfoError((prevState: any) => ({
            ...prevState,
            invoice_no: "",
          }));
        }
        if (res?.data?.length > 0) {
          setAutoGeneratedSeqNo(res?.data[0]?.sequence_no);
          setSequenceNo(res.data[0].sequence.id);
          const newSequenceData = res.data[0].sequence.sequence_preferences.map(
            (ele: {
              id: number;
              type: string;
              value: string;
              separator: string;
            }) => ({
              id: ele.id,
              type: ele.type,
              value: ele.value,
              separator: ele.separator,
            })
          );
          setSequenceData(newSequenceData);
          setIsManual((prevState) => ({
            ...prevState,
            is_manual: res.data[0].sequence.is_manual === 1 ? true : false,
            is_auto_generate:
              res.data[0].sequence.is_manual !== 1 ? true : false,
          }));
          setSeqIsOne((prevState) => ({
            ...prevState,
            isOnce: false,
            isOnceSeqValue: "",
          }));
          setIsManualAdded(Boolean(res.data[0].sequence.is_manual === 1));
        }
      })
      .catch((err) => console.log(err));
  };

  const getPurchaseBillData = async (id: string | number) => {
    try {
      setIsLoading(true);
      const res: any = await getWholeSalesById(id);
      if (res.data) {
        const { bill, bill_products } = res.data;
        const formattedBillProducts = await Promise.all(
          bill_products.map(async (ele: any) => {
            return {
              qty: Number(ele.original_qty) / Number(ele.pack_type) || 0,
              used_qty: calculateUsedQty(ele),
              used_free: Number(ele.free_return_qty) / Number(ele.pack_type),
              // tax: await getTaxOption(ele),
              amount: ele.amount,
              expires_at: ele.expires_at
                ? formattedDateWithSlash(ele.expires_at)
                : null,
              row_discount_type: ele.discount_type,
              discount: ele.discount || "",
              product_name: {
                ...ele,
              },
              pack_type: ele.pack_type,
              isEdit: true,
              p_rate: ele.p_rate,
              mrp: ele.mrp,
              rate: ele.rate,
              tax_data: `${ele.tax_name}-${ele.tax_id}%`,
              tax: {
                id: ele.id,
                name: ele.tax_rate,
                tax_name: ele.tax_name,
                tax_rate: ele.tax_id,
                sub_taxes: ele.subtax,
              },
              batch_no: `${ele.batch_no}#${ele.pur_stock + ele.return_qty}`,
              tax_amount: ele.tax_amount,
            };
          })
        );
        setBillProducts(formattedBillProducts);
        setInfoData({
          dept_id: bill.dept_id,
          customer_id: bill.customer_id,
          invoice_no: bill.invoice_no,
          mobile_no: bill.phone,
          address: bill.address,
          date_created: bill.date_created
            ? moment(bill.date_created, "YYYY-MM-DD HH:mm:ss").format(
                "DD/MM/YYYY hh:mm:ss A"
              )
            : null,
          payment: bill.payment,
        });
        const { CGST, SGST } = calculateSubTaxes(formattedBillProducts) || 0;
        setSummary((prevState: any) => ({
          excess: prevState.excess,
          advance: prevState.advance,
          CGST: CGST,
          SGST: SGST,
          total_discount_amount: Number(bill.disc_val) || 0,
          goods_value: Number(bill.orig_goods_val),
          disc_goods_val: Number(bill.disc_goods_val),
          total_tax: Number(bill.gst_amt),
          bill_total: Number(bill.orig_goods_val) + Number(bill.gst_amt) || 0,
          bill_discount: bill.discount_val,
          bill_discount_type: bill.discount_type,
          bill_discount_amount: bill.discount_amt,
          rounded_off: calculateRoundedOff({
            bill_total: Number(bill.orig_goods_val) + bill.gst_amt || 0,
            bill_discount_amount: bill.discount_amt || 0,
          }),
          adjustment: Number(bill.adj_amt),
          net_amount: Number(bill.bill_amt)?.toFixed(2) || 0,
          payment_method: bill.type,
          cash_paid: bill.cash_paid,
          amount_paid: bill.pay_paid_amt,
          balance: Number(bill.balance),
          refunded_amount: bill.refunded_excess,
          excess_amount: bill.excess_amount,
          net_excess: bill.net_excess,
        }));

        isCreatePrescription = false;
        isSequenceNumberChanged = false;
        setIsLoading(false);
      }
      handleSequence();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getPurchaseBillData(id);
    } else {
      isCreatePrescription = true;
      isSequenceNumberChanged = false;
      handleSequence();
    }
  }, [id]);

  return (
    <>
      {isLoading ? (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <PageLoader />
        </Box>
      ) : (
        <Grid sx={{ width: "92vw" }}>
          <Info
            error={infoError}
            setInfoData={setInfoData}
            setInfoError={setInfoError}
            fieldData={fieldData}
            infoData={infoData}
            handleValidation={handleValidation}
            isEdit={!!id}
            customSequence={customSequence}
            handleSequence={handleSequence}
            setSequenceData={setSequenceData}
            sequenceData={sequenceData}
            autoGeneratedSeqNo={autoGeneratedSeqNo}
            setIsManual={setIsManual}
            isManual={isManual}
            setSeqIsOne={setSeqIsOne}
            sequenceNo={sequenceNo}
            isSequenceNumberChanged={isSequenceNumberChanged}
            seqIsOnce={seqIsOnce}
            commonVariables={commonVariables}
          />
          <Grid
            sx={{
              width: "92vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              overflowX: { md: "scroll", lg: "hidden" },
            }}
          >
            <TableContainer
              className="table-container"
              component={Paper}
              sx={{
                ...styles.tableContainerStyle,
              }}
            >
              <Table sx={{ ...styles.tableStyle }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {TableHeaderCells.map((cell: any, index: number) => {
                      return (
                        <TableCell
                          align={cell.alignment}
                          sx={cell.sx}
                          key={index}
                        >
                          {cell.TableCellName}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>

                <TableBody sx={{ height: "auto", width: "90vw" }}>
                  {billProducts.map((row: any, index: number) => {
                    return (
                      <ProductRow
                        key={row.id}
                        index={index}
                        row={row}
                        error={formError?.length > 0 ? formError[index] : null}
                        billId={id}
                        styles={styles}
                        allRows={allProducts}
                        handleInputChange={handleInputChange}
                        handleDateChange={handleDateChange}
                        handleDelete={handleDelete}
                        handleAutoCompleteChange={handleAutoCompleteChange}
                        taxesOptions={taxOptions}
                        setIsOpenErrorModal={setIsOpenErrorModal}
                        setIsOpenPriceMargin={setIsOpenPriceMargin}
                        productLength={billProducts.length}
                      />
                    );
                  })}
                </TableBody>
              </Table>
              <Grid
                sx={{
                  width: {
                    xs: "400vw",
                    sm: "200vw",
                    md: "120vw",
                    lg: "90vw",
                  },
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: "0px 0px 5px 5px",
                  border: 1,
                  borderColor: "var(--table-border)",
                  borderTop: 0,
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: {
                      xs: "35%",
                    },
                  }}
                >
                  <Button
                    variant="text"
                    buttonText="+ Add Product"
                    handleClick={handleClick}
                    sx={{
                      borderRadius: "0px",
                      width: "auto",
                      height: "auto",
                      fontWeight: 400,
                      fontSize: "13px",
                      border: 0,
                      color: "primary.main",
                      "&:hover": {
                        backgroundColor: "initial",
                        color: "primary.main",
                        borderColor: "initial",
                      },
                      "&.Mui-focusVisible": {
                        border: 1,
                        borderColor: "primary.main",
                        borderRadius: "5px",
                      },
                    }}
                  />
                  <Grid
                    sx={{
                      width: "45%",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Grid
                      sx={{
                        width: "60%",
                      }}
                    >
                      <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                        Discount Value :
                      </Typography>
                    </Grid>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: "600",
                        mr: "1%",
                        textAlign: "left",
                      }}
                    >
                      {summary?.total_discount_amount?.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: {
                      xs: "65%",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      width: "20%",
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
                      Goods Value :
                    </Typography>
                  </Grid>
                  <Typography
                    sx={{ fontSize: "13px", fontWeight: "600", mr: "2%" }}
                  >
                    {summary?.goods_value?.toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </TableContainer>
          </Grid>
          <Summary
            isSaveLoading={isSaveLoading}
            isEdit={!!id}
            summary={summary}
            summaryError={summaryError}
            handleInputChange={handleChangeSummary}
            handleSaveData={handleSaveData}
          />
          <Dialog
            open={isOpenErrorModal.isOpen}
            keepMounted
            onClose={() => setIsOpenErrorModal({ isOpen: false, content: "" })}
            aria-describedby="alert-dialog-slide-description"
            sx={{
              "& .MuiPaper-root": {
                position: "absolute",
                top: "0px",
                height: "145px",
              },
            }}
          >
            <DialogContent
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                p: "10px 20px",
              }}
            >
              <DeleteAlertIcon width="19px" height="19px" />
              <DialogTitle
                id="alert-dialog-title"
                sx={{ ...styles.dialogTitle }}
              >
                {isOpenErrorModal.content}
              </DialogTitle>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                buttonText="Okay"
                sx={{
                  borderRadius: "0px",
                  width: "75px",
                  height: "35px",
                  fontWeight: 400,
                  fontSize: "13px",
                  border: 0,
                  color: "#fff",
                  "&:hover": {
                    color: "#fff",
                  },
                  "&.Mui-focusVisible": {
                    border: 1,
                    borderColor: "primary.main",
                    borderRadius: "5px",
                  },
                }}
                handleClick={() =>
                  setIsOpenErrorModal({ isOpen: false, content: "" })
                }
              />
            </DialogActions>
          </Dialog>
        </Grid>
      )}
      <PriceMarginModal
        isOpen={isOpenPriceMargin.isOpen}
        onClose={() =>
          setIsOpenPriceMargin({ isOpen: false, product_id: null })
        }
        customer_id={infoData.customer_id as any}
        product_id={isOpenPriceMargin.product_id as any}
      />
    </>
  );
}
