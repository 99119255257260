import ApiUtil from "../utils/ApiUtils";
import {
  labMasterEndPoints,
  procurementsMasterApiEndPoints,
} from "../constants/apiEndPoints";
import QueryParamsBuilder from "../helper/QueryParamsBuilder";

const {
  getAllTaxDetails,
  updateTaxesDetails,
  getAllDepartmentsDetails,
  deleteDepartmentDetails,
  createDepartmentDetails,
  editDepartmentsDetails,
  updateDepartmentsDetails,
  getAllManufacturesDetails,
  editManufacturesDetails,
  updateManufacturesDetails,
  deleteManufacturesDetails,
  createManufacturesDetails,
  getAllMajorDrugsDetails,
  createMajorDrugsDetails,
  deleteMajorDrugsDetails,
  updateMajorDrugsDetails,
  editMajorDrugsDetails,
  getAllDosageFormsDetails,
  createDosageFormDetails,
  deleteDosageFormDetails,
  updateDosageFormDetails,
  editDosageFormDetails,
  getAllUnitsDetails,
  createUnitsDetails,
  deleteUnitsDetails,
  updateUnitsDetails,
  editUnitsDetails,
  customerMasterUrl,
} = procurementsMasterApiEndPoints;
const setQueryParams = (data: any, baseUrl: string) => {
  const keys = Object.keys(data);

  let queryString = "?";
  keys.map((key) => {
    if (data[key] && !Array.isArray(data[key])) {
      queryString += `${key}=${data[key]}&`;
    }
    if (Array.isArray(data[key])) {
      data[key].map(
        (ele: any, index: number) => (queryString += `${key}[${index}]=${ele}&`)
      );
    }
  });
  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  const apiUrl = baseUrl + queryString;

  return apiUrl;
};

type ClassificationListTypes = {
  search?: string | number | null;
  column?: string | number | null;
  order?: string | number | null;
  page?: string | number | null;
  limit?: string | number | null;
};
type ProductListTypes = {
  search?: string | number | null;
  column?: string | number | null;
  order?: string | number | null;
  page?: string | number | null;
  limit?: string | number | null;
  name?: string | number | null;
  code?: string | number | null;
  generic_name?: string | number | null;
  rack_position?: string | number | null;
  major_drug?: string | number | null;
};
const getAllDrugClassificationList = async (
  params: ClassificationListTypes
) => {
  const apiUrl = setQueryParams(
    params,
    procurementsMasterApiEndPoints.getAllDrugClassificationList
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getDrugClassificationById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getDrugClassificationById}/${id}`
  );
};

const addDrugClassification = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsMasterApiEndPoints.addDrugClassification}`,
    data
  );
};
const updateDrugClassification = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsMasterApiEndPoints.editDrugClassification}/${id}`,
    data
  );
};
const deleteDrugClassification = async (drugId: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsMasterApiEndPoints.deleteDrugClassification}/${drugId}`
  );
};
const getAllProductList = async (params: ProductListTypes) => {
  const apiUrl = setQueryParams(
    params,
    procurementsMasterApiEndPoints.getAllProductList
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const deleteProduct = async (drugId: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsMasterApiEndPoints.deleteProductById}/${drugId}`
  );
};
const getAllMasterDrugClassification = async (data: { active?: number }) => {
  const apiUrl = setQueryParams(
    data,
    procurementsMasterApiEndPoints.getAllMasterDrugClassification
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getAllMasterDosage = async (data: { active?: number }) => {
  const apiUrl = setQueryParams(
    data,
    procurementsMasterApiEndPoints.getAllMasterMajorDrug
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getAllMasterMajorDrug = async (data: { active?: number }) => {
  const apiUrl = setQueryParams(
    data,
    procurementsMasterApiEndPoints.getAllMasterMajorDrug
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getAllMasterManufacture = async (data: { active?: number }) => {
  const apiUrl = setQueryParams(
    data,
    procurementsMasterApiEndPoints.getAllMasterManufacture
  );
  return await ApiUtil.getWithToken(apiUrl);
};

const getAllMasterTax = async () => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getAllMasterTax}`
  );
};
const getAllMasterUnits = async (data: { active?: number }) => {
  const apiUrl = setQueryParams(
    data,
    procurementsMasterApiEndPoints.getAllMasterUnits
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const addMajorDrug = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsMasterApiEndPoints.addMajorDrugs}`,
    data
  );
};
const addDosageForm = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsMasterApiEndPoints.addDosageForm}`,
    data
  );
};
const addManufacture = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsMasterApiEndPoints.addManufacture}`,
    data
  );
};
const addUnits = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsMasterApiEndPoints.addUnits}`,
    data
  );
};
const addProducts = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsMasterApiEndPoints.addProducts}`,
    data
  );
};
const getProductById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getProductById}/${id}`
  );
};
const getUpdateExistProductById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsMasterApiEndPoints.addExistPackType}/${id}/update-exist`,
    data
  );
};
const updateProductById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsMasterApiEndPoints.addExistPackType}/${id}`,
    data
  );
};
const getAllSupplierList = async (params: ClassificationListTypes) => {
  const apiUrl = setQueryParams(
    params,
    procurementsMasterApiEndPoints.getAllSupplierList
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getSupplierListById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getAllSupplierList}/${id}`
  );
};
const addSuppliers = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsMasterApiEndPoints.getAllSupplierList}`,
    data
  );
};
const updateSupplierById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsMasterApiEndPoints.getAllSupplierList}/${id}`,
    data
  );
};
const deleteSupplier = async (supId: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsMasterApiEndPoints.getAllSupplierList}/${supId}`
  );
};
const getAllTaxInfo = async () => {
  return await ApiUtil.getWithToken(getAllTaxDetails);
};
const updateTaxes = async (data: any) => {
  return await ApiUtil.putWithToken(`${updateTaxesDetails}`, data);
};

// Departments Service

const getAllDepartments = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: getAllDepartmentsDetails,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue: searchValue,
  });
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
const createDepartments = async (createDepartmentsData: {} | any) => {
  return await ApiUtil.postWithToken(
    createDepartmentDetails,
    createDepartmentsData
  );
};
const editDepartments = async (DepartmentId: number) => {
  return await ApiUtil.getWithToken(
    `${editDepartmentsDetails}/${DepartmentId}`
  );
};

const updateDepartments = async (DepartmentId: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${updateDepartmentsDetails}/${DepartmentId}`,
    data
  );
};
const deleteDepartments = async (DepartmentId: number) => {
  return await ApiUtil.deleteWithToken(
    `${deleteDepartmentDetails}/${DepartmentId}`
  );
};

// Manufactures Service

const getAllManufactures = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: getAllManufacturesDetails,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue: searchValue,
  });
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
const createManufactures = async (createManufacturesData: {} | any) => {
  return await ApiUtil.postWithToken(
    createManufacturesDetails,
    createManufacturesData
  );
};
const editManufactures = async (DepartmentId: number) => {
  return await ApiUtil.getWithToken(
    `${editManufacturesDetails}/${DepartmentId}`
  );
};

const updateManufactures = async (DepartmentId: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${updateManufacturesDetails}/${DepartmentId}`,
    data
  );
};
const deleteManufactures = async (DepartmentId: number) => {
  return await ApiUtil.deleteWithToken(
    `${deleteManufacturesDetails}/${DepartmentId}`
  );
};
// MajorDrugs Service
const getAllMajorDrugs = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: getAllMajorDrugsDetails,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue: searchValue,
  });
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
const createMajorDrugs = async (createMajorDrugsData: {} | any) => {
  return await ApiUtil.postWithToken(
    createMajorDrugsDetails,
    createMajorDrugsData
  );
};
const editMajorDrugs = async (MajorDrugsId: number) => {
  return await ApiUtil.getWithToken(`${editMajorDrugsDetails}/${MajorDrugsId}`);
};

const updateMajorDrugs = async (MajorDrugsId: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${updateMajorDrugsDetails}/${MajorDrugsId}`,
    data
  );
};
const deleteMajorDrugs = async (MajorDrugsId: number) => {
  return await ApiUtil.deleteWithToken(
    `${deleteMajorDrugsDetails}/${MajorDrugsId}`
  );
};

// DosageForm Service

const getAllDosageForm = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: getAllDosageFormsDetails,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue: searchValue,
  });
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
const createDosageForm = async (createDosageFormData: {} | any) => {
  return await ApiUtil.postWithToken(
    createDosageFormDetails,
    createDosageFormData
  );
};
const editDosageForm = async (DosageFormId: number) => {
  return await ApiUtil.getWithToken(`${editDosageFormDetails}/${DosageFormId}`);
};

const updateDosageForm = async (DosageFormId: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${updateDosageFormDetails}/${DosageFormId}`,
    data
  );
};
const deleteDosageForm = async (DosageFormId: number) => {
  return await ApiUtil.deleteWithToken(
    `${deleteDosageFormDetails}/${DosageFormId}`
  );
};

// Units Service

const getAllUnits = async ({
  page,
  pageSize,
  sortBy,
  sortOrder,
  searchValue,
}: any): Promise<{
  data: { result: []; total: number | null };
}> => {
  const apiUrl = QueryParamsBuilder({
    baseUrl: getAllUnitsDetails,
    page,
    size: pageSize,
    sortBy,
    sortOrder,
    searchValue: searchValue,
  });
  return (await ApiUtil.getWithToken(apiUrl)) as {
    data: { result: []; total: number | null };
  };
};
const createUnits = async (createUnitsData: {} | any) => {
  return await ApiUtil.postWithToken(createUnitsDetails, createUnitsData);
};
const editUnits = async (UnitId: number) => {
  return await ApiUtil.getWithToken(`${editUnitsDetails}/${UnitId}`);
};

const updateUnits = async (UnitId: number | string, data: any) => {
  return await ApiUtil.putWithToken(`${updateUnitsDetails}/${UnitId}`, data);
};
const deleteUnits = async (UnitId: number) => {
  return await ApiUtil.deleteWithToken(`${deleteUnitsDetails}/${UnitId}`);
};

//Lab
type LabProductListTypes = {
  search?: string | number | null;
  column?: string | number | null;
  order?: string | number | null;
  page?: string | number | null;
  limit?: string | number | null;
  name?: string | number | null;
  chemical?: string | number | null;
  no_of_tests?: string | number | null;
};

const getAllLabProductList = async (params: LabProductListTypes) => {
  const apiUrl = setQueryParams(params, labMasterEndPoints.getLabProducts);
  return await ApiUtil.getWithToken(apiUrl);
};
const addLabProducts = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${labMasterEndPoints.getLabProducts}`,
    data
  );
};
const getLabProductById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${labMasterEndPoints.getLabProducts}/${id}`
  );
};
const deleteLabProduct = async (drugId: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${labMasterEndPoints.getLabProducts}/${drugId}`
  );
};
const updateLabProductById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${labMasterEndPoints.getLabProducts}/${id}`,
    data
  );
};

const getAllLabSupplierList = async (params: ClassificationListTypes) => {
  const apiUrl = setQueryParams(params, labMasterEndPoints.getSuppliers);
  return await ApiUtil.getWithToken(apiUrl);
};
const getLabSupplierListById = async (id: number | string) => {
  return await ApiUtil.getWithToken(`${labMasterEndPoints.getSuppliers}/${id}`);
};
const addLabSuppliers = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${labMasterEndPoints.getSuppliers}`,
    data
  );
};
const updateLabSupplierById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${labMasterEndPoints.getSuppliers}/${id}`,
    data
  );
};
const deleteLabSupplier = async (supId: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${labMasterEndPoints.getSuppliers}/${supId}`
  );
};
const getAllLaboratoryTaxInfo = async () => {
  return await ApiUtil.getWithToken(labMasterEndPoints.getAllTaxDetails);
};
const updateLaboratoryTaxes = async (data: any) => {
  return await ApiUtil.putWithToken(
    `${labMasterEndPoints.updateTaxesDetails}`,
    data
  );
};
const getAllMasterTestProducts = async () => {
  return await ApiUtil.getWithToken(
    labMasterEndPoints.getAllMasterTestProducts
  );
};
const getAllMasterProductList = async (params: ClassificationListTypes) => {
  const apiUrl = setQueryParams(
    params,
    labMasterEndPoints.getAllMasterProductLists
  );

  return await ApiUtil.getWithToken(apiUrl);
};

type LabTestProductListTypes = {
  search?: string | number | null;
  column?: string | number | null;
  order?: string | number | null;
  page?: string | number | null;
  limit?: string | number | null;
  test_name?: string | number | null;
};

const getAllLabTestProductList = async (params: LabTestProductListTypes) => {
  const apiUrl = setQueryParams(params, labMasterEndPoints.getTestProducts);
  return await ApiUtil.getWithToken(apiUrl);
};

const addMasterTestProducts = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${labMasterEndPoints.getTestProducts}`,
    data
  );
};
const updateMasterTestProductsById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${labMasterEndPoints.getTestProducts}/${id}`,
    data
  );
};
const deleteMasterTestProducts = async (supId: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${labMasterEndPoints.getTestProducts}/${supId}`
  );
};
const getMasterTestProductsById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${labMasterEndPoints.getTestProducts}/${id}`
  );
};
const updatedSequence = async (sequenceId: number, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsMasterApiEndPoints.sequenceUpdateUrl}/${sequenceId}`,
    data
  );
};
const getAllCustomerList = async (params: ClassificationListTypes) => {
  const apiUrl = setQueryParams(
    params,
    procurementsMasterApiEndPoints.getAllCustomerList
  );
  return await ApiUtil.getWithToken(apiUrl);
};
const getCustomerListById = async (id: number | string) => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getAllCustomerList}/${id}`
  );
};
const addCustomers = async (data: any) => {
  return await ApiUtil.postWithToken(
    `${procurementsMasterApiEndPoints.getAllCustomerList}`,
    data
  );
};
const updateCustomerById = async (id: number | string, data: any) => {
  return await ApiUtil.putWithToken(
    `${procurementsMasterApiEndPoints.getAllCustomerList}/${id}`,
    data
  );
};
const deleteCustomer = async (supId: number | string) => {
  return await ApiUtil.deleteWithToken(
    `${procurementsMasterApiEndPoints.getAllCustomerList}/${supId}`
  );
};
const getAllCustomers = async (params: {
  search?: string;
  column: string;
  order: string;
  page: number;
  limit: number;
}) => {
  try {
    const apiUrl = setQueryParams(params, customerMasterUrl);
    return await ApiUtil.getWithToken(apiUrl);
  } catch (error) {
    console.log("error occurs while getting the customers list: ", error);
    throw error;
  }
};

const createCustomerMaster = async (data: any) => {
  try {
    return await ApiUtil.postWithToken(customerMasterUrl, data);
  } catch (error) {
    console.log("error occurs while creating the customers list: ", error);
    throw error;
  }
};

const updateCustomerMaster = async (id: number | string, data: any) => {
  try {
    return await ApiUtil.putWithToken(`${customerMasterUrl}/${id}`, data);
  } catch (error) {
    console.log("error occurs while updating the customers list: ", error);
    throw error;
  }
};

const getCustomerMasterById = async (id: number | string) => {
  try {
    return await ApiUtil.getWithToken(`${customerMasterUrl}/${id}`);
  } catch (error) {
    console.log("error occurs while getting the customers list: ", error);
    throw error;
  }
};

const deleteCustomerMasterById = async (id: number | string) => {
  try {
    return await ApiUtil.deleteWithToken(`${customerMasterUrl}/${id}`);
  } catch (error) {
    console.log("error occurs while creating the customers list: ", error);
    throw error;
  }
};

const getAllDrugClassification = async () => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getAllDrugClassificationUrl}`
  );
};
const getAllManufacture = async () => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getAllManufactureUrl}`
  );
};
const getAllMajorDrug = async () => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getAllMajorDrugsUrl}`
  );
};
const getAllDosageForms = async () => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getAllDosageFormsUrl}`
  );
};
const getAllUnit = async () => {
  return await ApiUtil.getWithToken(
    `${procurementsMasterApiEndPoints.getAllUnitsUrl}`
  );
};
export {
  getAllCustomerList,
  deleteCustomer,
  updateCustomerById,
  addCustomers,
  getCustomerListById,
  updatedSequence,
  getMasterTestProductsById,
  addMasterTestProducts,
  updateMasterTestProductsById,
  deleteMasterTestProducts,
  getAllMasterTestProducts,
  getAllLabTestProductList,
  updateLaboratoryTaxes,
  getAllLaboratoryTaxInfo,
  getAllLabSupplierList,
  updateLabSupplierById,
  getLabSupplierListById,
  addLabSuppliers,
  deleteLabSupplier,
  updateLabProductById,
  deleteLabProduct,
  getLabProductById,
  addLabProducts,
  getAllLabProductList,
  getAllTaxInfo,
  updateTaxes,
  getAllDepartments,
  deleteDepartments,
  createDepartments,
  editDepartments,
  updateDepartments,
  getAllManufactures,
  createManufactures,
  editManufactures,
  updateManufactures,
  deleteManufactures,
  getAllMajorDrugs,
  createMajorDrugs,
  editMajorDrugs,
  updateMajorDrugs,
  deleteMajorDrugs,
  getAllDosageForm,
  createDosageForm,
  editDosageForm,
  updateDosageForm,
  deleteDosageForm,
  getAllUnits,
  createUnits,
  editUnits,
  updateUnits,
  deleteUnits,
  deleteSupplier,
  updateSupplierById,
  addSuppliers,
  getSupplierListById,
  getAllSupplierList,
  updateProductById,
  getUpdateExistProductById,
  getProductById,
  addDosageForm,
  addManufacture,
  addUnits,
  addProducts,
  addMajorDrug,
  getAllMasterDosage,
  getAllMasterMajorDrug,
  getAllMasterManufacture,
  deleteProduct,
  getAllMasterTax,
  getAllMasterUnits,
  getAllDrugClassificationList,
  deleteDrugClassification,
  getDrugClassificationById,
  updateDrugClassification,
  addDrugClassification,
  getAllMasterDrugClassification,
  getAllProductList,
  getAllCustomers,
  getCustomerMasterById,
  createCustomerMaster,
  updateCustomerMaster,
  deleteCustomerMasterById,
  getAllMasterProductList,
  getAllDrugClassification,
  getAllManufacture,
  getAllMajorDrug,
  getAllDosageForms,
  getAllUnit,
};
