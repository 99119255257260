import { Box, Grid } from "@mui/material";
import { TextField } from "../../../../../components/basic";
import React from "react";
import { formattedDateWithSlash } from "../../../../../utils/DateTimeFormatUtils";
type FollowUpTypes = {
  trimester_week: number | string;
  antenatal_card_id: number | string;
  findings: string;
  id: number | string;
  scans: string;
  tests: string;
  trimester: string;
  date_of_visit: string;
  suggestions: [{ followup_id: number; product_name: string }];
  notes: string;
  vitals: [
    {
      vital_date: string;
      vital_id: number | string;
      vital_name: string;
      vital_unit: string;
      vital_value: string;
    }
  ];
};
type VitalsTypes = {
  vital_date: string;
  vital_id: number | string;
  vital_name: string;
  vital_unit: string;
  vital_value: string;
};
export default function PreviousHistory({
  prevHistoryList,
}: {
  prevHistoryList: any;
}) {
  const periodDataList = [
    {
      name: "lmp",
      value: formattedDateWithSlash(prevHistoryList?.lmp),
      label: "LMP",
    },
    {
      name: "edd",
      value: formattedDateWithSlash(prevHistoryList?.edd),
      label: "EDD",
    },
    {
      name: "rch_id",
      value: prevHistoryList?.rch_id,
      label: "RCH ID",
    },
    {
      name: "Obstetric",
      value: prevHistoryList?.obstetric[0],
      label: "Obstetric",
    },
  ];
  const trimesterType: { [key: string]: string } = {
    "Third Trimester": "III",
    "Second Trimester": "II",
    "First Trimester": "I",
  };
  const followUpCardStyle: {
    [key: string]: {
      width: string;
      backgroundColor: string;
      borderRadius: string;
      borderBottom: string;
      marginTop: string;
      marginBottom: string;
      cursor: string;
      headingText: {
        fontSize: string;
        fontWeight: number;
        color: string;
      };
    };
  } = {
    "Third Trimester": {
      width: "100%",
      backgroundColor: "#FFF7EC",
      borderRadius: "12px",
      borderBottom: "6px solid #FFE6C4",
      marginTop: "15px",
      marginBottom: "15px",
      cursor: "pointer",
      headingText: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#F99A19",
      },
    },
    "Second Trimester": {
      width: "100%",
      backgroundColor: "#ECF4FFCC",
      borderRadius: "12px",
      borderBottom: "6px solid #B9D5FF",
      marginTop: "15px",
      marginBottom: "15px",
      cursor: "pointer",
      headingText: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#4170F7",
      },
    },
    "First Trimester": {
      width: "100%",
      backgroundColor: "#F8ECFF",
      borderRadius: "12px",
      borderBottom: "6px solid #EDCEFF",
      marginTop: "15px",
      marginBottom: "5px",
      cursor: "pointer",
      headingText: {
        fontSize: "15px",
        fontWeight: 600,
        color: "#C361FC",
      },
    },
  };
  return (
    <div>
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          color: "#207DFF",
          backgroundColor: "#F8F8F8",
          padding: "10px",
          width: "101%",
          marginLeft: "-22px",
          paddingLeft: "23px",
        }}
      >
        Pregnancy Information
      </Box>
      <Grid container gap={"12px"} sx={{ marginTop: "10px" }}>
        {periodDataList.map(
          (ele: {
            name: string;
            value: string | number | null | any;

            label: string;
          }) => (
            <Grid xs={2.8}>
              <TextField
                disabled
                value={
                  ele.name === "Obstetric"
                    ? `G${ele.value?.g}P${ele.value?.p}A${ele.value?.a}L${ele.value?.l}`
                    : ele.value
                }
                name={ele.name}
                label={ele.label}
              />
            </Grid>
          )
        )}
        {prevHistoryList.followups.map((ele: FollowUpTypes) => (
          <div style={{ width: "100%" }}>
            <div>
              <p
                style={{
                  margin: 0,
                  ...followUpCardStyle[ele.trimester].headingText,
                }}
              >
                {`Trimester - ${trimesterType[ele.trimester]} (Week - ${
                  ele.trimester_week
                })-${formattedDateWithSlash(ele.date_of_visit)}`}
              </p>
            </div>
            <div
              style={{
                ...followUpCardStyle[ele.trimester],
                padding: "14px 0px 14px 14px",
              }}
            >
              <Grid container>
                <Grid
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "6px",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Grid xs={4} sx={{ fontWeight: 600, fontSize: "12px" }}>
                    Findings:
                  </Grid>
                  <Grid xs={8}>
                    <p
                      style={{
                        fontSize: "11px",
                        fontWeight: 400,
                        margin: 0,
                      }}
                    >
                      {ele.findings}
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "6px",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Grid xs={4} sx={{ fontWeight: 600, fontSize: "12px" }}>
                    Suggestions:
                  </Grid>
                  <Grid xs={8}>
                    <p
                      style={{
                        fontSize: "11px",
                        fontWeight: 400,
                        margin: 0,
                      }}
                    >
                      {ele?.suggestions
                        ?.map((elem) => {
                          return elem.product_name ? elem.product_name : null;
                        })
                        .join(", ")}
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "6px",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Grid xs={4} sx={{ fontWeight: 600, fontSize: "12px" }}>
                    Tests:
                  </Grid>
                  <Grid xs={8}>
                    <p
                      style={{
                        fontSize: "11px",
                        fontWeight: 400,
                        margin: 0,
                      }}
                    >
                      {ele.tests}
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "6px",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Grid xs={4} sx={{ fontWeight: 600, fontSize: "12px" }}>
                    Scans:
                  </Grid>
                  <Grid xs={8}>
                    <p
                      style={{
                        fontSize: "11px",
                        fontWeight: 400,
                        margin: 0,
                      }}
                    >
                      {ele.scans}
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "6px",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Grid xs={4} sx={{ fontWeight: 600, fontSize: "12px" }}>
                    Notes:
                  </Grid>
                  <Grid xs={8}>
                    {" "}
                    <p
                      style={{
                        fontSize: "11px",
                        fontWeight: 400,
                        margin: 0,
                      }}
                    >
                      {ele.notes}
                    </p>
                  </Grid>
                </Grid>

                {ele.vitals.map((elem: VitalsTypes) => (
                  <Grid
                    xs={4}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "6px",
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <React.Fragment key={elem.vital_id}>
                      <Grid xs={4} sx={{ fontWeight: 600, fontSize: "12px" }}>
                        {elem.vital_name}:
                      </Grid>
                      <Grid xs={8} sx={{ fontSize: "12px", fontWeight: 400 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                          }}
                        >
                          {elem.vital_value}
                          <p
                            style={{ margin: 0 }}
                            dangerouslySetInnerHTML={{
                              __html: elem.vital_unit,
                            }}
                          ></p>
                        </div>
                      </Grid>
                    </React.Fragment>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        ))}
      </Grid>
    </div>
  );
}
