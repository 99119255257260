import { useEffect, useState } from "react";
import { DatePicker } from "../../../../components/basic";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getBillCollection } from "../../../../services/billingService";
import { commonDateFormatter } from "../../../../utils/DateTimeFormatUtils";

export default function ListFooter() {
  const [billDate, setBillDate] = useState(new Date());
  const [data, setData] = useState({
    bill_collection_data: [],
    first_shift_amt_total: 0,
    second_shift_amt_total: 0,
    third_shift_amt_total: 0,
  });

  useEffect(() => {
    if (billDate) {
      getBillCollection(commonDateFormatter(billDate, "YYYY-MM-DD"))
        .then((res: any) => {
          if (res.data) {
            setData(res.data.result);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [billDate]);

  return (
    <div style={{ margin: "20px 0px 0px 1px" }}>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "20px",
          fontSize: "14px",
        }}
      >
        <Grid item xs={12} lg={2} sm={3} md={2} xl={1}>
          <p
            style={{
              color: "#232323",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              textTransform: "capitalize",
            }}
          >
            Bill Collected On:
          </p>
        </Grid>
        <Grid item xs={12} lg={2} sm={4} md={6} xl={2}>
          <DatePicker
            name="billDate"
            width="100%"
            onChange={(e: any, newValue: any) =>
              setBillDate(newValue.validatedValue[0])
            }
            formControlStyle={{ width: "90%" }}
            format="DD/MM/YYYY"
            value={billDate}
            maxDate={commonDateFormatter(new Date(), "DD/MM/YYYY")}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "12px" }}>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
              "& th": { fontSize: "14px", fontWeight: "500" },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell align="center">12.00 midnight - 9.00 am</TableCell>
                <TableCell align="center">9.00 am - 9.00 pm</TableCell>
                <TableCell align="center">9.00 pm - 12.00 midnight</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& th,& td": { fontSize: "13px !important", fontWeight: 500 },
              }}
            >
              {data.bill_collection_data &&
              data.bill_collection_data?.length > 0 ? (
                <>
                  {data.bill_collection_data.map((row: any) => (
                    <TableRow
                      key={row.user_name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.user_name}
                      </TableCell>
                      <TableCell align="center">
                        {row.first_shift_amt}
                      </TableCell>
                      <TableCell align="center">
                        {row.second_shift_amt}
                      </TableCell>
                      <TableCell align="center">
                        {row.third_shift_amt}
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              ) : (
                <TableRow>
                  <TableCell component="th" scope="row"></TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="left">
                    No Transaction for this Date
                  </TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell align="left">Total</TableCell>
                <TableCell align="center">
                  {data.first_shift_amt_total}
                </TableCell>
                <TableCell align="center">
                  {data.second_shift_amt_total}
                </TableCell>
                <TableCell align="center">
                  {data.third_shift_amt_total}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}
