export const RouteUrls = {
  loginUrl: "/",
  dashboardUrl: "/dashboard",
  appointmentUrl: "/appointments",
  appointmentsDetails: "/details",
  appointmentsBooking: "/booking",
  rescheduleAppointment: "/reschedule",
  reviewAppointment: "/review",
  appointmentsToken: "/appointments-token",
  viewRecords: "/view_records",
  report: "/report",
  patientUrl: "/patients",
  signInUrl: "/",
  billingUrl: "/billing",
  billingItemsUrl: "/items",
  billingListUrl: "/lists",
  billingAddItemUrl: "/add-item",
  billingItemsMasterUrl: "/items-master",
  billDraftUrl: "/bill-draft",
  paymentUrl: "/payment",
  vaccineUrl: "/vaccine",
  laboratoryUrl: "/laboratory",
  inPatientsUrl: "/inpatients",
  accountsUrl: "/accounts",
  editUserUrl: "/edit-user",
  pharmacyUrl: "/pharmacy",
  masterUrl: "/master",
  create: "/create",
  appointmentsList: "/list",
  procurementUrl: "/procurement",
  purchaseBillUrl: "/purchase-bill",
  purchasePaymentListUrl: "/purchase-payment",
  purchaseReturnUrl: "/purchase-return",
  goodsIssueReturnUrl: "/goods-issue-return",
  indentsListUrl: "/indents",
  salesBillListUrl: "/sales-bill",
  salesReturnUrl: "/sales-return",
  goodsIssueUrl: "/goods-issue",
  stockAdjustmentUrl: "/stock-adjustment",
  purchaseOrdersUrl: "/purchase-orders",
  patientHistoryUrl: "/patient-history",
  patientAccountUrl: "/patient-account",
  adminUrl: "/admin",
  listUrl: "/list",
  viewUrl: "/view",
  edit: "/edit",
  createUrl: "/create",
  itemsUrl: "/items",
  addItemUrl: "/add-item",
  ItemsMasterUrl: "/items-master",
  gynecologyUrl: "/gynecology",
  view: "/view",
  labBillsUrl: "/lab-bills",
  list: "/list",
  inPatients: "/inpatients",
  add: "/add",
  ipSummary: "/ip-summary",
  dischargeSummary: "/discharge-summary",
  organizations: "/organizations",
  campaignUrl: "/campaign",
  inhouseLeadsUrl: "/inhouse-leads",
  coldLeadsUrl: "/cold-leads",
  addUrl: "/add",
  editUrl: "/edit",
  labMasterUrl: "/lab-master",
  addFollowUpUrl: "/add-followup",
  labPurchaseReturnUrl: "/lab-purchase-return",
  labGoodsIssue: "/lab-goods-issue",
  labReturnLabGoodsIssue: "/return-lab-goods-issue",
  labIssueStockAdjustments: "/lab-issue-stock-adjustments",
  labPurchasePayments: "/lab_purchase_payments",
  wholeSalesUrl: "/whole_sales",
  historyUrl: "/history",
  wholeSalesReturn: "/return_whole_sales",
  labPurchaseUrl: "/purchase",
  labIndentsListUrl: "/lab-indents",
  labPurchaseOrderUrl: "/lab-purchase-orders",
  adjustmentUrl: "/whole_sales_adjustment",
  wholeSalesPayments: "/whole_sales_payments",
  billDraft: "/bill-draft",
  payments: "/payment",
  paymentsListUrl: "/list",
  paymentAdjustmentUrl: "/adjustment",
  salesBillUrl: "/pharmacy/sales-bill",
  opUrl: "/OP",
  patientsUrl: "/patients",
  vaccinesUrl: "/vaccines",
  searchUrl: "/search",
  operationTheatreUrl: "/operation-theatre",
  otJobUrl: "/ot-job",
  stockTransferUrl: "/stock-transfer",
  doctorVisitsUrl: "/doctor-visit",
  printUrl: "/print",
  medicalRecordsUrl: "/records",
  appointmentsUrl: "/appointments",
  appointmentsDetailsUrl: "/details",
  ipSummaryUrl: "/ip-summary",
  overviewUrl: "/overview",
};
