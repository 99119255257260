import { useState, useEffect, useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  Typography,
  Grid,
  CircularProgress,
  Switch,
  debounce,
  IconButton,
} from "@mui/material";
import {
  TextField,
  Sort,
  CustomButton,
  BrowseButton,
  SaveButton,
  PageLoader,
} from "../../../../components/basic";
import {
  DataTable,
  DialogWrapper,
  ConfirmationDialog,
} from "../../../../components/shared";
import CustomFilter from "../../../../components/shared/customFilter";
import { statusColor, ActiveStatusColor } from "../../../../utils/GeneralUtils";
import {
  requiredValidator,
  updateFormDataWithHelperText,
} from "../../../../utils/ValidationUtils";
import {
  displayText,
  procurementConst,
} from "../../../../constants/displayText";
import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import {
  createManufactures,
  deleteManufactures,
  editManufactures,
  getAllManufactures,
  updateManufactures,
} from "../../../../services/procurementMasterService";
import {
  setSnackBarFailed,
  setSnackBarSuccess,
} from "../../../../redux/slices/snackbar";
import { setCurrentPage } from "../../../../redux/slices/pagination";
type GridRowData = Record<string, unknown>;

function Manufacture() {
  const { ADD, EDIT, ADD_BUTTON_TITLE, CONFIRMATION_DELETE_DIALOG_TITLE } =
    displayText;

  // data table constants and states
  const [isFieldSort, setIsFieldSort] = useState(false);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState<number | string>("");
  const [manufacturesList, setManufacturesList] = useState([]);
  const [loading, setLoading] = useState<boolean>();
  const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);
  const [filesToBeUploaded, setFilesToBeUploaded] = useState<any>([]);
  const dispatch = useDispatch();
  const pageInfo = useSelector(
    (state: RootState) => state?.pagination?.procurementMasterManufacture
  );

  // Add And Edit dialog states and constants

  const [isAddAndEditDialogOpen, setIsAddAndEditDialogOpen] =
    useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const [isAddAndEditDialogLoading, setIsAddAndEditDialogLoading] =
    useState<boolean>(false);
  const [idToBeUpdated, setIdToBeUpdated] = useState<number | null>(null);

  // confirmation dialog states and constants

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [manufactureToBeDeleted, setManufactureToBeDeleted] =
    useState<any>(null);

  // form states and constants

  const initialFormError = {
    name: "",
  };

  const [manufactureFormError, setManufactureFormError] =
    useState<any>(initialFormError);

  const initialFormData = {
    name: "",
    active: true,
  };

  const [manufactureFormData, setManufactureFormData] =
    useState<any>(initialFormData);

  const fieldData = {
    name: {
      label: "Name",
      name: "name",
      value: manufactureFormData.name,
      isError: Boolean(manufactureFormError.name),
      helperText: manufactureFormError.name,
    },

    active: {
      label: "Status",
      name: "active",
      value: manufactureFormData.active,
      isError: Boolean(manufactureFormError.active),
      helperText: manufactureFormError.active,
    },
  };

  const [manufactureFieldData, setManufactureFieldData] =
    useState<any>(fieldData);

  // data table columns and actions

  const TableActions = ({ row }: any) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <IconButton onClick={() => editManufacture(row.id)}>
          <EditIcon />
        </IconButton>

        <IconButton onClick={() => deleteManufacture(row.id)}>
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("name")}
        >
          <Typography variant="h5" fontSize={14}>
            Name
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "name" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "name" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row.name}
        </Typography>
      ),
      minWidth: 130,
      sortable: false,
    },
    {
      field: "active",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
          onClick={() => handleSorting("active")}
        >
          <Typography variant="h5" fontSize={14}>
            Status
          </Typography>
          <Sort
            ascendingActive={
              sortedField.field === "active" && sortedField.order === "ASC"
                ? true
                : false
            }
            descendingActive={
              sortedField.field === "active" && sortedField.order === "DESC"
                ? true
                : false
            }
          />
        </div>
      ),
      renderCell: ({ row }: any) => {
        const statusValue = row ? row.active : null;
        const { buttonText, backgroundColor, textColor } =
          ActiveStatusColor[statusValue] || {};

        return (
          <Typography variant="h5" fontSize={14}>
            <CustomButton
              buttonText={buttonText}
              backgroundColor={backgroundColor}
              textColor={textColor}
              fontSize={"10px"}
              fontWeight={500}
              textTransform={"capitalize"}
            />
          </Typography>
        );
      },
      minWidth: 120,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  // data table functions

  const handleSorting = (field: string) => {
    setIsFieldSort(!isFieldSort);
    if (sortedField.field === field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
    if (sortedField.field !== field) {
      setSortedField({ field: field, order: isFieldSort ? "ASC" : "DESC" });
    }
  };

  //form validation functions

  const handleValidation = (e: any) => {
    const { name, value, label } = e.target;

    switch (name) {
      case "name": {
        if (requiredValidator(value, label)) {
          updateFormDataWithHelperText(
            name,
            requiredValidator(value, label),
            setManufactureFormError
          );
        } else {
          updateFormDataWithHelperText(name, "", setManufactureFormError);
        }
        break;
      }
      default:
        break;
    }
  };
  const validateForm = () => {
    for (const fieldName in fieldData) {
      if ((fieldData as any)[fieldName].name) {
        handleValidation({ target: (fieldData as any)[fieldName] });
      }
    }
  };
  const updateManufactureFieldData = () => {
    setManufactureFieldData((prev: any) => {
      return Object?.keys(prev)?.map((field: any) => {
        return {
          ...field,
          value: manufactureFormData[field.name],
          isError: Boolean(manufactureFormError[field.name]),
          helperText: manufactureFormError[field.name],
        };
      });
    });
  };

  // form API call functions

  const getManufacturesList = useCallback(
    debounce(async (data: any) => {
      setLoading(true);

      getAllManufactures(data)
        .then((result: any) => {
          let data = result?.data?.result;
          setManufacturesList(data);
          setPageCount(result?.data?.total as any);
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          const errorMessage = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : "Unknown error occurred";
          dispatch(
            setSnackBarFailed({
              snackBarMessage: `${errorMessage}`,
            })
          );
          console.error("An error occurred:", error);
        });
    }, 300),
    []
  );

  useEffect(() => {
    const data = {
      page: Number(pageInfo.page) + 1,
      pageSize: pageInfo.pageSize,
      sortBy: sortedField.field,
      sortOrder: sortedField.order,
      searchValue: searchValue,
    };
    getManufacturesList(data);
  }, [
    pageInfo.page,
    pageInfo.pageSize,
    sortedField.field,
    sortedField.order,
    searchValue,
  ]);
  const deleteManufacture = (manufactureId: number) => {
    setIsConfirmationDialogOpen(true);
    setManufactureToBeDeleted(manufactureId);
  };
  const createManufacture = async () => {
    if (!Boolean(manufactureFormData.name)) {
      validateForm();
    } else {
      try {
        setIsSaveBtnLoading(true);
        const data = {
          name: manufactureFormData.name,
          active: 1,
        };

        await createManufactures(data).then((result: any) => {
          closeAddAndEditDialog();
          dispatch(setSnackBarSuccess({ snackBarMessage: result.message }));
          getManufacturesList({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
          setIsSaveBtnLoading(false);
        });
      } catch (error: any) {
        closeAddAndEditDialog();
        const errorMessage = error?.response?.data?.errors
          ? error?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        setIsSaveBtnLoading(false);
        console.error("An error occurred:", error);
      }
    }
  };
  const editManufacture = async (id: number) => {
    try {
      if (id === null) {
        return false;
      }
      setIsAddAndEditDialogLoading(true);
      openAddAndEditDialog();
      await editManufactures(id).then((result: any) => {
        setManufactureFormData({
          name: result?.data?.name,
          active: result?.data?.active,
        });
        setIdToBeUpdated(id);
        setIsAddAndEditDialogLoading(false);
      });
    } catch (error: any) {
      setIsAddAndEditDialogLoading(false);
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      console.error("An error occurred:", error);
    }
  };
  const updateManufacture = async () => {
    if (manufactureFormData.name === "") {
      validateForm();
    } else {
      try {
        if (idToBeUpdated === null) {
          return false;
        }
        setIsSaveBtnLoading(true);
        const data = {
          name: manufactureFormData.name,
          active: manufactureFormData.active ? 1 : 0,
        };
        await updateManufactures(idToBeUpdated, data).then((result: any) => {
          closeAddAndEditDialog();
          dispatch(setSnackBarSuccess({ snackBarMessage: result.message }));
          setIsSaveBtnLoading(false);
          getManufacturesList({
            page: Number(pageInfo.page) + 1,
            pageSize: pageInfo.pageSize,
            sortBy: sortedField.field,
            sortOrder: sortedField.order,
            searchValue: searchValue,
          });
        });
      } catch (error: any) {
        closeAddAndEditDialog();
        const errorMessage = error?.response?.data?.errors
          ? error?.response?.data?.errors
          : "Unknown error occurred";
        dispatch(
          setSnackBarFailed({
            snackBarMessage: `${errorMessage}`,
          })
        );
        setIsSaveBtnLoading(false);
        console.error("An error occurred:", error);
      }
    }
  };
  const handleConfirmationDelete = async () => {
    if (manufactureToBeDeleted === null) {
      return false;
    }
    try {
      setIsButtonLoading(true);
      await deleteManufactures(manufactureToBeDeleted).then((result: any) => {
        dispatch(setSnackBarSuccess({ snackBarMessage: result.message }));
        closeConfirmationDialog();
        getManufacturesList({
          page: Number(pageInfo.page) + 1,
          pageSize: pageInfo.pageSize,
          sortBy: sortedField.field,
          sortOrder: sortedField.order,
          searchValue: searchValue,
        });
      });
      setIsButtonLoading(false);
    } catch (error: any) {
      setIsButtonLoading(false);
      const errorMessage = error?.response?.data?.errors
        ? error?.response?.data?.errors
        : "Unknown error occurred";
      dispatch(
        setSnackBarFailed({
          snackBarMessage: `${errorMessage}`,
        })
      );
      closeConfirmationDialog();
      console.error("An error occurred:", error);
    }
  };

  // form fields onchange functions

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setManufactureFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    handleValidation(e);
  };

  const handleSwitchChange = async (e: any) => {
    const { name, checked } = e.target;
    setManufactureFormData((prevBookingData: any) => ({
      ...prevBookingData,
      [name]: checked,
    }));
  };

  useEffect(() => {
    updateManufactureFieldData();
  }, [manufactureFormError, manufactureFormData]);

  // Add And Edit dialog functions

  const openAddAndEditDialog = async () => {
    setIsAddAndEditDialogOpen(true);
  };

  const closeAddAndEditDialog = async () => {
    setIsButtonLoading(false);
    setIsAddAndEditDialogOpen(false);
    setManufactureFormData(initialFormData);
    setIdToBeUpdated(null);
    setManufactureFormError(initialFormError);
  };

  // confirmation dialog functions

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  // custom component and it's function
  const CustomComponent = () => {
    return (
      <Grid
        sx={{
          width: "auto",
          minWidth: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BrowseButton
          selectedItems={filesToBeUploaded}
          onChange={handleFileChange}
        />
        <SaveButton
          buttonText="Upload"
          sx={{ width: "83px", height: "33px" }}
        />
      </Grid>
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      setFilesToBeUploaded(Array.from(files));
    }
  };
  const handleSearchOnChange = (e: any) => {
    setSearchValue(e.target.value);
    dispatch(setCurrentPage({ key: "procurementMasterManufacture", value: 0 }));
  };
  return (
    <>
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title={CONFIRMATION_DELETE_DIALOG_TITLE}
        handleClick={handleConfirmationDelete}
        onClose={closeConfirmationDialog}
        loading={isButtonLoading}
      />
      <CustomFilter
        isSearchEnabled={true}
        editButtonTitle={ADD_BUTTON_TITLE}
        searchName={"search"}
        searchValue={searchValue}
        searchOnChange={handleSearchOnChange}
        clearSearch={() => {
          setSearchValue("");
          dispatch(
            setCurrentPage({ key: "procurementMasterManufacture", value: 0 })
          );
        }}
        onUpdateButtonClick={openAddAndEditDialog}
        // customComponent={<CustomComponent />}
      />
      <DataTable
        loading={loading}
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row.id)}`}
        rows={manufacturesList}
        pageCount={pageCount}
        currentPage={"procurementMasterManufacture"}
      />
      <DialogWrapper
        onClose={closeAddAndEditDialog}
        open={isAddAndEditDialogOpen}
        handleClick={
          idToBeUpdated === null ? createManufacture : updateManufacture
        }
        title={idToBeUpdated === null ? ADD : EDIT}
        loading={isSaveBtnLoading}
        maxWidth="sm"
      >
        {isAddAndEditDialogLoading ? (
          <PageLoader />
        ) : (
          <>
            <TextField
              value={fieldData.name.value}
              name={fieldData.name.name}
              onChange={handleInputChange}
              label={fieldData.name.label}
              helperText={fieldData.name.helperText}
              placeholder="Enter Name"
              onBlur={(e: any) =>
                handleValidation((e = { target: fieldData.name }))
              }
              sx={{
                width: "100%",
              }}
            />

            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "5px",
                mt: "5px",
              }}
            >
              <Typography>{fieldData.active.label}</Typography>
              <Switch
                name={fieldData.active.name}
                checked={fieldData.active.value}
                onChange={handleSwitchChange}
                disabled={idToBeUpdated == null ? true : false}
              />
            </Grid>
          </>
        )}
      </DialogWrapper>
    </>
  );
}

export default Manufacture;
