import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { SaveButton } from "../../../../../components/basic";
import { useEffect, useState } from "react";
import {
  ConfirmationDialog,
  DataTable,
} from "../../../../../components/shared";
import {
  OTJobIcon,
  AnaesthesiaIcon,
  OtEditIcon,
  OtViewIcon,
  DeleteIcon,
} from "../../../../../assets/icons";
import { GridColDef, GridMoreVertIcon } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { setSnackBarSuccess } from "../../../../../redux/slices/snackbar";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { RouteUrls } from "../../../../../constants/routes";
import {
  deleteOperationTheatreById,
  getAllOtById,
} from "../../../../../services/admissionService";
import { ipAdmissionConst } from "../../../../../constants/displayText";
import PermissionUtils from "../../../../../utils/PermissionUtils";
import { setOperationTheatreList } from "../../../../../redux/slices/inpatients";

type GridRowData = Record<string, unknown>;

const OperationTheatre = ({ ipAdmissionDetails }: any) => {
  const { can } = PermissionUtils();
  const dispatch = useDispatch();

  const { operationTheatreList } = useSelector(
    (state: RootState) => state.inPatients
  );

  const [admissionId, setAdmissionId] = useState<any>(ipAdmissionDetails?.id);
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [sortedField, setSortedField] = useState<{
    order: string | null;
    field: string | null;
  }>({
    order: null,
    field: null,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string | number | null>("");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState<boolean>(false);
  const [operationTheatreToBeDeleted, setOperationTheatreToBeDeleted] =
    useState<any>(null);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const navigate = useNavigate();

  const TableActions = ({ row }: any) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls={open ? "long-menu" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <GridMoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right", // Adjust as needed
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right", // Adjust as needed
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "19ch",
              marginRight: "30px",
            },
          }}
          sx={{ gap: 2 }}
        >
          {can("ip_ot_job_add") && (
            <Link
              to={`${RouteUrls.inPatients}${RouteUrls.operationTheatreUrl}${RouteUrls.otJobUrl}/${row?.id}`}
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <MenuItem sx={{ gap: 2 }}>
                <OTJobIcon />
                <Typography variant="h6" color="#111827">
                  OT Job
                </Typography>
              </MenuItem>
            </Link>
          )}
          <Link
            to={""}
            style={{
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <MenuItem onClick={handleClose} sx={{ gap: 2 }}>
              <AnaesthesiaIcon />
              <Typography variant="h6" color="#111827">
                Anaesthesia
              </Typography>
            </MenuItem>
          </Link>
          {can("ip_surgery_edit") && (
            <Link
              to={`${RouteUrls.inPatients}${RouteUrls.operationTheatreUrl}${RouteUrls.editUrl}/${row?.id}`}
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <MenuItem onClick={handleClose} sx={{ gap: 2 }}>
                <OtEditIcon />{" "}
                <Typography variant="h6" color="#111827">
                  Edit
                </Typography>
              </MenuItem>
            </Link>
          )}{" "}
          {can("ip_surgery_list") && (
            <Link
              to={`${RouteUrls.inPatients}${RouteUrls.operationTheatreUrl}${RouteUrls.viewUrl}/${row?.id}`}
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              <MenuItem onClick={handleClose} sx={{ gap: 2 }}>
                <OtViewIcon />
                <Typography variant="h6" color="#111827">
                  View
                </Typography>
              </MenuItem>
            </Link>
          )}
          {can("ip_surgery_delete") && (
            <MenuItem
              onClick={() => {
                setIsConfirmationDialogOpen(true);
                deleteOperationTheatre(row?.id);
              }}
              sx={{ gap: 2 }}
            >
              <DeleteIcon />{" "}
              <Typography variant="h6" color="#FF1E00">
                Delete
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "doctorName",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Doctor Name
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.doctor_name}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "surgery",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Surgery
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.surgery_name}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "operationDate",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Operation Date
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.schedule_start_date &&
            moment(row?.schedule_start_date, ["YYYY-MM-DD HH:mm:ss"])?.format(
              "DD/MM/YYYY hh:mm:ss A"
            )}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "operationTheatre",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Operation Theatre
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.operation_theatre}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "procedureType",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Procedure Type
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => (
        <Typography variant="h5" fontSize={14}>
          {row?.procedure_type}
        </Typography>
      ),
      minWidth: 100,
      sortable: false,
    },
    {
      field: "actions",
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            height: "51px",
            cursor: "pointer",
          }}
        >
          <Typography variant="h5" fontSize={14}>
            Actions
          </Typography>
        </div>
      ),
      renderCell: ({ row }: any) => {
        return <TableActions row={row} />;
      },
      minWidth: 120,
      sortable: false,
    },
  ];

  const getOperationTheatreList = async () => {
    if (!admissionId) {
      return false;
    }
    try {
      setLoading(true);
      await getAllOtById(admissionId).then((res: any) => {
        const data = res?.data;
        if (data) {
          dispatch(setOperationTheatreList(data));
          setPageCount(res?.data?.total as any);
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("An error occurred:", error);

      setLoading(false);
    }
  };

  const deleteOperationTheatre = (id: any) => {
    setIsConfirmationDialogOpen(true);
    setOperationTheatreToBeDeleted(id);
  };

  const handleConfirmDelete = async () => {
    if (operationTheatreToBeDeleted === null) {
      return false;
    }
    try {
      setDeleteBtnLoader(true);
      await deleteOperationTheatreById(operationTheatreToBeDeleted).then(
        (result: any) => {
          getOperationTheatreList();
          setIsConfirmationDialogOpen(false);
          dispatch(
            setSnackBarSuccess({
              snackBarMessage: ipAdmissionConst.DELETED_SUCCESSFULLY as string,
            })
          );
          setDeleteBtnLoader(false);
        }
      );
    } catch (error) {
      setOperationTheatreToBeDeleted(null);
      setIsConfirmationDialogOpen(false);
      setDeleteBtnLoader(false);
      console.error("An error occurred:", error);
    }
  };

  const onClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  useEffect(() => {
    if (!operationTheatreList) {
      getOperationTheatreList();
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={"18px"} fontWeight={500}>
          OT List
        </Typography>
        {can("ip_surgery_add") && (
          <SaveButton
            buttonText="+ Add"
            sx={{ width: "84px", height: "42px" }}
            handleClick={() =>
              navigate(
                `${RouteUrls.inPatients}${RouteUrls.operationTheatreUrl}${RouteUrls.addUrl}/${admissionId}`
              )
            }
          />
        )}
      </Box>
      <DataTable
        columns={columns}
        getRowId={(row: GridRowData) => `${String(row?.id)}`}
        rows={operationTheatreList || []}
        pageCount={pageCount}
        loading={loading}
        isTableWithOutPagination={true}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="are you surely want to delete?"
        handleClick={handleConfirmDelete}
        onClose={onClose}
        loading={deleteBtnLoader}
      />
    </Box>
  );
};

export default OperationTheatre;
