const calculateSubTaxes = (array: any) => {
  // adding new key value pair to each objects of array based on sub_taxes array
  array.forEach((item: any) => {
    for (const key in item) {
      if (key.endsWith("price")) {
        delete item[key];
      }
    }
    // Extract the sub_taxes array
    const subTaxes = item?.tax?.sub_taxes;

    // Loop through each sub tax
    subTaxes?.forEach((subTax: any, index: number) => {
      // Create the new key
      const key = `${subTax.sub_tax_name}_${subTax.sub_tax_rate}%_price`;

      // Calculate the value
      const subTaxRate = subTax?.sub_tax_rate || 0;
      const taxRate = item?.tax?.tax_rate || 0;
      const taxAmount = item?.tax_amount || 0;

      const value = (subTaxRate / taxRate) * taxAmount;

      // Add the new key-value pair to the object
      item[key] = value || 0;
    });
  });

  // getting only those specific sub_tasks key value pairs
  const subTaxesData = array?.map((item: any) => {
    // Filter out key-value pairs where key ends with "price"
    const filteredPairs = Object?.entries(item)?.filter(([key, value]) =>
      key.endsWith("price")
    );

    // Convert the filtered key-value pairs back into an object
    const priceObj = Object?.fromEntries(filteredPairs);

    return priceObj;
  });

  const totals: any = {};
  // sum all the unique sub tasks key value pair in each objects of array
  subTaxesData?.forEach((obj: any) => {
    // Loop through each key-value pair in the object
    Object.entries(obj)?.forEach(([key, value]) => {
      // Add the value to the total corresponding to the key
      totals[key] = (totals[key] || 0) + value;
    });
  });

  // getting CGST from "CGST_9%_price" and adding that as new key value pair with the key "sub_tax_name"
  const subTaxesArray: any = [];
  for (const key in totals) {
    if (totals?.hasOwnProperty(key)) {
      const subTaxName = key?.split("_")[0];
      const existingObj = subTaxesArray?.find(
        (obj: any) => obj.sub_tax_name === subTaxName
      );
      if (existingObj) {
        existingObj[key] = totals[key];
      } else {
        const newSubTaxObj = {
          sub_tax_name: subTaxName,
          [key]: totals[key],
        };
        subTaxesArray.push(newSubTaxObj);
      }
    }
  }

  // getting % only from  CGST_9%_price
  const transformedData = subTaxesArray?.map((item: any) => {
    const newObj: any = { sub_tax_name: item.sub_tax_name };
    Object.keys(item)?.forEach((key) => {
      if (key !== "sub_tax_name") {
        const newKey = key
          ?.replace(`${item?.sub_tax_name}_`, "")
          ?.replace("_price", "");

        newObj[newKey] = item[key];
      }
    });
    return newObj;
  });

  // modifying like CGST : "3%-1000, 9%-2888"
  const result: any = {};
  transformedData?.forEach((obj: any) => {
    const subTaxName = obj["sub_tax_name"];
    delete obj["sub_tax_name"];
    result[subTaxName] = Object.entries(obj)
      .map(([key, value]) => `${key}-${Number(value)?.toFixed(2)}`)
      .join(", ");
  });

  return result;
};

export const formula: any = {
  purchaseBill: {
    calculateAmount: (object: any, id: number) => {
      if (object.discount_amount) {
        return object?.qty * object?.p_rate - object.discount_amount;
      }
      return object?.qty * object?.p_rate;
    },
    calculateDiscountAmount: (object: any, id: number) => {
      const discountAmount =
        object?.row_discount_type === "₹"
          ? object?.discount
          : +(object?.qty * object?.p_rate * object?.discount) / 100;

      return discountAmount || 0;
    },
    calculateTaxAmount: (object: any, tax_type?: string) => {
      const taxAmount =
        object?.tax === null
          ? 0
          : Number(
              ((object?.qty * object?.p_rate - object?.discount_amount) *
                object?.tax?.tax_rate) /
                100
            );
      const withoutTaxamount =
        (object?.qty * object?.p_rate - object?.discount_amount) /
        (1 + object?.tax?.tax_rate / 100);

      if (tax_type === "exclusive") {
        return taxAmount.toFixed(2) || 0;
      } else {
        const tax_value = (withoutTaxamount * object?.tax?.tax_rate) / 100;
        return tax_value.toFixed(2) || 0;
      }
    },
    calculateSubTaxes: (array: any) => calculateSubTaxes(array),
    calculateTotalDiscountAmount: (array: any) => {
      const totalDiscountAmount = array
        ?.map((row: any) => row.discount_amount)
        .reduce(
          (accumulator: any, currentValue: number) =>
            accumulator + Number(currentValue),
          0
        );
      return totalDiscountAmount;
    },
    calculateGoodsValue: (array: any, tax_type: string) => {
      const goodsValue = array
        ?.map((row: any) => row.amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      // const TotalDiscount = array
      //   ?.map((row: any) => row.discount_amount)
      //   .reduce(
      //     (accumulator: any, currentValue: any) =>
      //       accumulator + Number(currentValue),
      //     0
      //   );

      // const goodsValue = totalAmount - TotalDiscount;
      if (tax_type === "exclusive") {
        return goodsValue || 0;
      } else {
        const taxAmount = array
          ?.map((row: any) => row.tax_amount)
          .reduce(
            (accumulator: any, currentValue: any) =>
              accumulator + Number(currentValue),
            0
          );

        const goods_value = goodsValue - taxAmount;
        return goods_value || 0;
      }
    },
    calculateTotalTaxAmount: (array: any) => {
      const taxAmount = array
        ?.map((row: any) => row.tax_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      return taxAmount?.toFixed(2) || 0;
    },
    calculateBillTotal: (summary: any) => {
      const billTotal = Number(summary.goods_value) + Number(summary.total_tax);

      return billTotal.toFixed(2) || 0;
    },
    calculateBillDataDiscountAmount: (summary: any) => {
      const discountAmount =
        summary?.bill_discount_type === "₹"
          ? summary?.bill_discount
          : (summary.bill_total * summary.bill_discount) / 100;
      return Number(discountAmount)?.toFixed(2) || 0;
    },
    calculateRoundedOff: (summary: any) => {
      const total = summary.bill_total - summary.bill_discount_amount;
      const roundedOff = Math.round(total) - total;

      return roundedOff?.toFixed(2) || 0;
    },
    calculateNetAmount: (summary: any) => {
      if (summary.bill_total) {
        const total =
          Number(summary.bill_total) - Number(summary.bill_discount_amount);
        const netAmount =
          total + Number(summary.adjustment) + Number(summary.rounded_off);
        return netAmount?.toFixed(2) || 0;
      }

      return 0;
    },
    calculateBalance: (summary: any) => {
      const balance = summary.net_amount - summary.cash_paid;

      return balance;
    },
    calculatePaidAmount: (summary: any) => {
      const totalPayments = summary?.payments
        ?.map((ele: any) => ele.value)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );
      const paidAmount =
        totalPayments > summary?.net_total_amt
          ? summary?.net_total_amt
          : totalPayments;

      if (paidAmount) {
        return paidAmount;
      }

      return 0;
    },
  },
  purchaseReturn: {
    calculateAmount: (array: any, id: number) => {
      return array[id]?.ret_qty * array[id]?.ptr;
    },
    calculateBillAmount: (array: any) => {
      return array
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue.amount),
          0
        )
        .toFixed(2);
    },
    calculateDiscountAmount: (array: any, id: number) => {
      return (array[id]?.ret_qty * array[id]?.ptr * array[id]?.discount) / 100;
    },
    calculateTaxAmount: (array: any, id: number) => {
      return (
        ((array[id]?.ret_qty * array[id]?.ptr - array[id]?.discount_amount) *
          array[id]?.tax) /
        100
      );
    },
    calculateTotalDiscountAmount: (array: any) => {
      const totalDiscountAmount = array
        ?.map((row: any) => row.discount_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );
      return totalDiscountAmount;
    },
    calculateGoodsValue: (array: any) => {
      const totalAmount = array
        ?.map((row: any) => row.amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      const TotalDiscount = array
        ?.map((row: any) => row.discount_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      const goodsValue = totalAmount - TotalDiscount;

      return goodsValue;
    },
    calculateCGSTAndSGST: (array: any) => {
      const CGST = array
        ?.map((row: any) => row.tax_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      return CGST / 2;
    },
    calculateTotalTaxAmount: (array: any) => {
      const gst = array
        ?.map((row: any) => row.tax_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      return gst;
    },
    calculateRoundedOff: (summary: any) => {
      const total = summary.total_amount;
      const roundedOff = Math.round(total) - total;
      return roundedOff;
    },
    calculateNetAmount: (summary: any) => {
      const netAmount = summary.bill_amount + summary.rounded_off;
      return netAmount?.toFixed(2) || 0;
    },
  },
  goodsIssue: {
    calculateAmount: (array: any, id: number) => {
      return array[id]?.qty * array[id]?.mrp;
    },
    calculateDiscountAmount: (array: any, id: number) => {
      return (array[id]?.qty * array[id]?.mrp * array[id]?.discount) / 100;
    },
    calculateTaxAmount: (array: any, id: number) => {
      return (
        ((array[id]?.qty * array[id]?.mrp - array[id]?.discount_amount) *
          array[id]?.tax) /
        100
      );
    },
    calculateTotalDiscountAmount: (array: any) => {
      const totalDiscountAmount = array
        ?.map((row: any) => row.discount_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );
      return totalDiscountAmount;
    },
    calculateGoodsValue: (array: any) => {
      const totalAmount = array
        ?.map((row: any) => row.amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      const TotalDiscount = array
        ?.map((row: any) => row.discount_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      const goodsValue = totalAmount - TotalDiscount;

      return goodsValue;
    },
    calculateCGSTAndSGST: (array: any) => {
      const CGST = array
        ?.map((row: any) => row.tax_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      return CGST / 2;
    },
    calculateTotalTaxAmount: (array: any) => {
      const gst = array
        ?.map((row: any) => row.tax_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      return gst;
    },
    calculateRoundedOff: (summary: any) => {
      const goodsValue = Number(summary.goods_value);
      const taxAmount = Number(summary.tax_amount);
      const discount = Number(summary.discount || 0);
      const total = discount
        ? goodsValue + taxAmount - discount
        : goodsValue + taxAmount;
      const roundedOff = Math.round(total) - total;
      return roundedOff;
    },
    calculateNetAmount: (summary: any) => {
      const goodsValue = Number(summary.goods_value);
      const taxAmount = Number(summary.tax_amount);
      const discount = Number(summary.discount || 0);
      const roundedOff = Number(summary.rounded_off || 0);

      const total = discount
        ? goodsValue + taxAmount - discount
        : goodsValue + taxAmount;
      const netAmount = total + roundedOff;
      return netAmount;
    },
  },
  goodsIssueReturn: {
    calculateAmount: (array: any, id: number) => {
      return array[id]?.ret_qty * array[id]?.mrp;
    },
    calculateDiscountAmount: (array: any, id: number) => {
      return (array[id]?.ret_qty * array[id]?.mrp * array[id]?.discount) / 100;
    },
    calculateTaxAmount: (array: any, id: number) => {
      return (
        ((array[id]?.ret_qty * array[id]?.mrp - array[id]?.discount_amount) *
          array[id]?.tax) /
        100
      );
    },
    calculateTotalDiscountAmount: (array: any) => {
      const totalDiscountAmount = array
        ?.map((row: any) => row.discount_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );
      return totalDiscountAmount;
    },
    calculateGoodsValue: (array: any) => {
      const totalAmount = array
        ?.map((row: any) => row.amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      const TotalDiscount = array
        ?.map((row: any) => row.discount_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      const goodsValue = totalAmount - TotalDiscount;

      return goodsValue;
    },
    calculateCGSTAndSGST: (array: any) => {
      const CGST = array
        ?.map((row: any) => row.tax_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      return CGST / 2;
    },
    calculateTotalTaxAmount: (array: any) => {
      const gst = array
        ?.map((row: any) => row.tax_amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      return gst;
    },
    calculateRoundedOff: (summary: any) => {
      const total = summary.bill_amount;
      const roundedOff = Math.round(total) - total;

      return roundedOff;
    },
    calculateNetAmount: (summary: any) => {
      const total = summary.bill_amount;
      const netAmount = total + summary.rounded_off;
      return netAmount;
    },
    calculateTotalAmount: (productData: any) => {
      const taxAmount = productData
        ?.map((row: any) => row.amount)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      return taxAmount;
    },
  },
  B2B: {
    calculateTaxAmount: (productData: any) => {
      const taxAmount = productData
        ?.map((row: any) => row.tax_value)
        .reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + Number(currentValue),
          0
        );

      return taxAmount;
    },
  },
  payments: {
    calculateDiscountAmount: (object: any) => {
      if (object?.discount_value) {
        const discountAmount =
          object?.discount_type === "₹"
            ? Number(object?.discount_value)
            : +(object?.amount_due * object?.discount_value) / 100;
        return discountAmount?.toFixed(2) || 0;
      }
      return 0;
    },
    calculateAmountDue: (object: any) => {
      if (object.discount || object.tds) {
      }

      const amountDue =
        (object?.amount_due ? object?.amount_due : object?.balance) -
        (object?.discount_amount ? object?.discount_amount : 0) -
        (object?.tds ? object?.tds : 0);
      return amountDue?.toFixed(2) || 0;
    },
    calculateConvenienceFee: (object: any, percentage: number) => {
      if (object?.cash_received && percentage) {
        const convenience_fee =
          (Number(object?.cash_received) * percentage) / 100;
        return convenience_fee?.toFixed(2) || 0;
      }
      return 0;
    },
  },
};
